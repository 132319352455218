import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row, Button,Input} from 'reactstrap';
import { useBranches } from '../../../hooks/branches/useBranches';
import { ModalBranch } from '../../../components/branches/ModalBranch';
import { TableStriped } from '../../../components/tables/TableStriped';
import Breadcrumb from '../../../layout//breadcrumb'

export const IndexBranch = () => {

    const {
        branches, states, municipalities, commercialLines,
        method, handleInputChange, validaciones,
        id_commercial_line, code, name, number_phone, email, id_state, id_municipality,
        address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma,
        imageLogo, imageFirm, imageBackground,roleUser_responsible,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleChangeState,
        previewLogo, previewFirm, previewBackground, imageHandler,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchBranch, navigateExamsMaquila, navigateSettingsInvoice
    } = useBranches();

    return (

        <Fragment>
            {/* <Breadcrumb parent="Dashboard" title="Listado de sucursales" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                   Listado de Sucursales
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    {/* <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button> */}
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchBranch} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["visibleTable", "code", "email","id_commercial_line", "name_long_commercial_line", "number_phone", "id_section", "id_state", "id_municipality", "address", "colony", "cp", "url", "logo", "name_responsible", "certificate", "image_firm", "name_state", "name_municipality", "imgLogo", "imgFirma", "imgBackground", "background_image","roleUser_responsible"]}
                                        responsive={false}
                                        methodsActions={true}
                                        cabeceras={["Sucursal", "Razón social", "Rfc", "Solo toma de muestra", "Estatus"]}
                                        items={branches}
                                        {...{ handleUpdate }}
                                        orderTable={true}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                        methodsAbsolutes={
                                            [
                                                {
                                                    type: 'linkId',
                                                    method: navigateExamsMaquila,
                                                    icon: "icofont icofont-laboratory",
                                                    backgroundColor: "#6c6c6c",
                                                    color: "#fff",
                                                    name: "navigateConfigMaquila",
                                                    tooltip: "Configurar maquila interna"
                                                },
                                                {
                                                    type: 'linkId',
                                                    method: navigateSettingsInvoice,
                                                    icon: "fa fa-file-pdf-o",
                                                    backgroundColor: "#6a3a2a",
                                                    color: "#fff",
                                                    name: "navigateSettingsInvoice",
                                                    tooltip: "Configurar folios de facturación"
                                                }
                                            ]
                                        }
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalBranch
                {
                ...{
                    modal, toggle, modalTitle, method, loading,
                    states, municipalities, commercialLines,
                    handleInputChange, validaciones,
                    id_commercial_line, name, code, number_phone, email, id_state, id_municipality,
                    address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma,
                    imageLogo, imageFirm, imageBackground,
                    saveCreate, saveDelete, saveUpdate,
                    handleChangeState,
                    previewLogo, previewFirm, previewBackground, imageHandler,roleUser_responsible
                }
                }
            />
        </Fragment>
    );
}
