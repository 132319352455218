import React from 'react';
import { Modal,ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { FormMicTypeSample } from './FormMicTypeSample';
import { DeleteMicTypeSample } from './DeleteMicTypeSample';

export const ModalMicTypeSample = (props) => {
    const{
        modal, modalTitle, toggle, method, loading,
        name, abbreviation, handleInputChange, 
        validaciones, saveCreate, saveUpdate, saveDelete 
    }=props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ?<FormMicTypeSample
                    button = {ModalButtonSave}
                    {...{toggle, loading, handleInputChange, validaciones, methodAction: saveCreate,}}
                />
                :
                (method === "update")
                ?
                <FormMicTypeSample
                    button={ModalButtonUpdate}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, abbreviation }}
                />
                :
                <DeleteMicTypeSample
                    {...{toggle, loading, saveDelete, name, abbreviation}}
                />
            }
        </Modal>
    )
}