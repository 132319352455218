import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteBranch = (props) => {

    const { toggle, loading, saveDelete, name, code } = props;

    return (
        <Form role="form" onSubmit={ saveDelete }>
            <ModalBody>
                <h6>Estas a punto de eliminar la sucursal:</h6>
                <i>{name+' '+code} </i>
                <br />
                <b>¿Estás seguro de hacerlo?</b>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar
                </Button>
                <Button  className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
