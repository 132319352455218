import React from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap'

export const FormPrecodedText = (props) => {
    const { toggle, loading, id_type_precoded_text, text, typePrecodedTexts, handleInputChange, validaciones, methodAction, button } = props;
    const { id_type_precoded_text: validacionIdTypePrecodedText, text: validacionText } = validaciones;
    return (
        <Form role="" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-1"></FormGroup>
                    <FormGroup className="mb-3 col-10">
                        <Label className="text-sm">Tipo de texto precodificado</Label>
                        <Input
                            className="form-control form-control-sm input-air-primary" 
                            type="select"
                            name="id_type_precoded_text"
                            onChange={handleInputChange}
                            value={id_type_precoded_text}
                            required={true}
                            invalid={validacionIdTypePrecodedText}
                        >
                            <option value={0}>Selecciona el tipo de texto precodificado</option>
                            {
                                typePrecodedTexts.length > 0 &&
                                typePrecodedTexts.map((u, key) => {
                                    return <option
                                        key={key}
                                        value={u.id_type_precoded_text}
                                    >
                                        {u.name}
                                    </option>
                                })
                            }
                        </Input>
                        <FormFeedback>Debes seleccionar una opción</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-1"></FormGroup>
                    <FormGroup className="mb-3 col-10">
                        <Label className="text-sm">Texto</Label>
                        <Input
                            //placeholder="Texto"
                            className="form-control form-control-sm input-air-primary" 
                            type="text"
                            name="text"
                            value={text}
                            onChange={handleInputChange}
                            required={true}
                            autoComplete="off"
                            invalid={validacionText}
                        />
                        <FormFeedback>El texto debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
