import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, Input, CardHeader } from 'reactstrap';
import './styleDashboard.css';
//import { useDashboardCard } from '../../../hooks/dashboard/useDashboardCard';



export const IndexDashboardInicadores = () => {

    // const {

    //     loading, patientTotal, patientCompany, orderCancell, orderTotal

    // } = useDashboardCard();


    return (
        <Fragment>
            <Container fluid={true} >

                {/* <Col sm="12">
                    <Row>
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <Card className='cardDashboard shadow  ' style={{ height: "160px" }}>
                                <Row>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="8"> <br /> <br />
                                        <label className='labelIndications f-14'>Número de pacientes</label> <br />
                                        <label className='labelIndications f-22' >Particulares</label> <br /> <br />
                                        {
                                            patientTotal === 0 ?
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{patientTotal}</span><span className="animate-circle f-26"></span></div>
                                                </div>
                                                :
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-primary">{patientTotal}</span><span className="animate-circle f-26"></span></div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm="3"> <br /> <br />
                                        <img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/User.svg")} style={{ width: "50%", filter: "sepia(1)" }} alt="" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <Card className='cardDashboard shadow ' style={{ height: "160px" }}>
                                <Row>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="8"> <br /> <br />
                                        <label className='labelIndications f-14'>Número de pacientes</label> <br />
                                        <label className='labelIndications f-22' >Empresas</label> <br /> <br />
                                        {
                                            patientCompany === 0 ?
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{"0"}</span><span className="animate-circle"></span></div>
                                                </div>
                                                :
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-secondary">{patientCompany}</span><span className="animate-circle"></span></div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm="3"> <br /> <br />
                                        <img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/Company.svg")} style={{ width: "50%", filter: "grayscale(1)" }} alt="" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <Card className='cardDashboard shadow ' style={{ height: "160px" }}>
                                <Row>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="8"> <br /> <br />
                                        <label className='labelIndications f-14'>Número de ordenes de trabajo</label> <br />
                                        <label className='labelIndications f-22' >Canceladas</label> <br /> <br />
                                        {
                                            orderCancell === 0 ?
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-danger" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-danger">{"0"}</span><span className="animate-circle"></span></div>
                                                </div>
                                                :
                                                <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                    <div className="progress-gradient-primary" role="progressbar" style={{ width: "90%", height: "6px" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-secondary">{orderCancell}</span><span className="animate-circle"></span></div>
                                                </div>
                                        }
                                    </Col>
                                    <Col sm="3"> <br /> <br />
                                        <img className="imgU animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/dashboard/Cancellations.svg")} style={{ width: "50%" }} alt="" />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Col> */}









            </Container>

        </Fragment>


    )
}
