import React from 'react'
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button, } from 'reactstrap';
import Select from 'react-select';

export const FormRoom = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction,
         id_departament, name, code, button, departaments,handleSelectValues,
    } = props;
   // ////console.log(id_departament);
    const {
        id_departament_valid, name_valid, code_valid,
    } = validaciones;
    //const txtsection = localStorage.getItem('TextAS');
    //////console.log(departaments,"llwlwl");
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Departamento <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                className="form-control form-control-sm input-air-primary" 
                                type="select"
                                name="id_departament"
                                value={id_departament}
                                onChange={handleInputChange}
                                options={departaments}
                                invalid={id_departament_valid}
                                //noOptionsMessage={()=>"Sin opciones"}
                            >
                                <option defaultValue={0}>Selecciona una opción</option>
                                {
                                    departaments.map(element=>{
                                        return(
                                            <option key={element.id_departament} value={element.id_departament}>{element.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary" 
                                //placeholder="Nombre"
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label className="col-form-label">Código <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary" 
                                //placeholder="Código"
                                name="code"
                                onChange={handleInputChange}
                                value={code}
                                autoComplete="off"
                                invalid={code_valid}
                            />
                            <FormFeedback>Minimo 1 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} disabled={loading}> 
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
