import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { ModalButtonDelete, ModalButtonCancel } from '../../constant';

export const DeleteSection = (props) => {

    const {
        toggle, loading, name, abbreviation, saveDelete
    } = props;
    const txtsection = localStorage.getItem('TextAS');
    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar la siguiente {txtsection}:</p>                
                <b>{ name+' '+abbreviation }</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="danger" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    { ModalButtonDelete }
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    { ModalButtonCancel }
                </Button>
            </ModalFooter>
        </Form>
    )
}
