import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormSeller = (props) => {

    const {
        toggle,loading, name,paternal_surname,maternal_surname,number_phone,email,handleInputChange,methodAction,button,validaciones

    } = props;

        const {
            name_valid,
            paternal_surname_valid,
            maternal_surname_valid,
            
        } = validaciones;
   

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label className="col-form-label" >Nombre del vendedor <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleInputChange}
                            invalid={name_valid}
                            className="form-control form-control-sm input-air-primary" 
                            autoComplete="nope"

                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6 ">
                        <Label className="text-sm">Apellido Paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="text"
                            name="paternal_surname"
                            value={paternal_surname}
                            onChange={handleInputChange}
                            invalid={paternal_surname_valid}
                            className="form-control form-control-sm input-air-primary" 
                            autoComplete="nope"

                        />
                        <FormFeedback>El apellido debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Apellido Materno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="text"
                            name="maternal_surname"
                            value={maternal_surname}
                            onChange={handleInputChange}
                            invalid={maternal_surname_valid}
                            className="form-control form-control-sm input-air-primary" 
                            autoComplete="nope"
                        />
                        <FormFeedback>El apellido debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Télefono <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
                        <Input
                            type="text"
                            name="number_phone"
                            value={number_phone}
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary" 
                            autoComplete="nope"
                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm">Correo electrónico<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="text"
                            name="email"
                            value={email}
                            //autoComplete="off"
                            onChange={handleInputChange}
                            autoComplete="nope"

                            
                            className="form-control form-control-sm input-air-primary" 
                        />
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline color="primary"
                    type="submit"
                    size="sm"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button 
                size="sm" 
                className="btn-air-light" 
                outline color="danger" 
                type="button" 
                onClick={toggle}
                >
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
