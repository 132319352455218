import React, { Fragment } from 'react';
// import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink,CardHeader  } from 'reactstrap';
import { RibbonProductionCost, ButtonGoBack } from '../../../../constant';
import { useHistory } from 'react-router-dom';
// import { useExamProductionCost } from '../../../../hooks/exams/useExamProductionCost';
import { TableStriped } from '../../../../components/tables/TableStriped';
// import { ModalProductionCost } from '../../../../components/examProductionCost/ModalProductionCost';
//import { IndexCosts } from "./IndexCosts";
//import { IndexProcesses } from './IndexProcesses';
import { IndexCostsMicrobiology } from '../IndexCostsMicrobiology';
import { IndexProcessesMicrobiology } from './IndexProcessesMicrobiology';
import { useExamProductionCostMicrobiology } from '../../../../hooks/micro/useExamProductionCostMicrobiology';
import { ModalProductionCostMicrobiology } from '../../../../components/examProductionCostMicrobiology/ModalProductionCostMicrobiology';

export const IndexExamProductionCostMicrobiology = () => {

    const history = useHistory();

    const {
        examId, examName, sectionId,
        productionPrices, method, handleInputChange, validationsProduction,
        cost,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        tabGeneral, changeTabGeneral
    } = useExamProductionCostMicrobiology();

    return (
        <Fragment>
            {/* <Breadcrumb parent="Diseño examen" title={examName} /> */}
            {/* style={{  textAlign: "-webkit-right"}} */}
            <Container fluid={true} className=""> <br />        
                {/* <Col sm="12" > 
                    <Row>
                        <Col sm="6">
                            <label>{examName}</label>
                        </Col>
                        <Col sm="6" style={{  textAlign: "-webkit-right"}} >
                        <Button color="light" className=" btn-air-light m-1"  onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                        </Col>
                    </Row>
                </Col> */}
                {/* <label htmlFor="">Pruebas</label> */}
                {/* <Card> */}
                    <CardBody>
                        <Row>
                            <Col sm="3" className="tabs-responsive-side">
                            <Col sm="12"> <br />
                                <label className='f-18 txt-secondary'>Exámen: {examName}</label> <br />

                                </Col> <br /> 
                                <Card className="shadow">
                                    <CardHeader className="p-3 bg-primary">
                                        <h6>Edición</h6>
                                    </CardHeader>
                                    <Nav className="flex-column nav-pills border-tab nav-left">
                                    <NavItem>
                                        <NavLink
                                            href="#javascript"
                                            className={tabGeneral === "1" ? "active" : ""}
                                            onClick={() => changeTabGeneral("1")}
                                        >
                                            <i className="icofont icofont-ui-edit"></i>
                                            {`Costo de producción`}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#javascript"
                                            className={tabGeneral === "2" ? "active" : ""}
                                            onClick={() => changeTabGeneral("2")}
                                        >
                                            {/* <i className="icofont icofont-ui-edit"></i> */}
                                            <i className="icofont icofont-first-aid-alt"></i>
                                            {`Kit de toma de muestra`}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#javascript"
                                            className={tabGeneral === "3" ? "active" : ""}
                                            onClick={() => changeTabGeneral("3")}
                                        >
                                            {/* <i className="icofont icofont-ui-edit"></i> */}
                                            <i className="icofont icofont-settings-alt f-14"></i>
                                            {`Procesos`}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                </Card>

                                <Card className="shadow">
                                 <CardHeader className="p-3 bg-primary">
                                    <h6>Acciones</h6>
                                </CardHeader> 
                                <br />
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="1"></Col>
                                            <Col sm="8">
                                            <Button color="light" className="float-right mr-1 ml-1" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <br />
                                </Card>

                               
                            </Col>
                            <Col md="9">
                                <TabContent activeTab={tabGeneral}>
                                    <TabPane className="fade show" tabId="1">
                                        <Card className="shadow">
                                            <CardHeader className="p-3 bg-secondary" >
                                                <h6>{RibbonProductionCost}</h6>
                                            </CardHeader>
                                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                                    <Button className="btn btn-pill btn-air-secondary" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                                </div>
                                                <CardBody>
                                                <div className="table-responsive">
                                                    <TableStriped
                                                        notMaped={["id_exam"]}
                                                        methodsActions={false}
                                                        cabeceras={["Costo", "Vigente desde", "Vigente hasta"]}
                                                        items={productionPrices}
                                                    />
                                                </div>
                                                </CardBody> 
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <IndexCostsMicrobiology examId={examId} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <IndexProcessesMicrobiology examId={examId} sectionId={sectionId} />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </CardBody>
                {/* </Card> */}
            </Container>
            <ModalProductionCostMicrobiology
                {
                ...{
                    method, handleInputChange, validationsProduction,
                    cost,
                    toggle, modal, modalTitle,
                    loading,
                    saveCreate
                }
                }
            />
        </Fragment>
    )
}
