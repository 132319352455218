import { sendRequest } from '../../hooks/requests/useRequest'

export async function getAuthentication(_username,_password)
{

    let _body = {
        "username": _username,
        "password": _password
      }

    let data = JSON.stringify(_body);

    let requestOptions = {
        method: 'POST',
        body: data

    };

    const response = await sendRequest(requestOptions, "Users/CheckUserPassword");

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }   
}