import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useSites = () => {

    const [sites, setSites] = useState([]);
    const [sitesId, setSitesId] = useState(0);
    const [dataSiteSearch, setDataSiteSearch] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle,setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [formValues,handleInputChange, reset, handleUpdateValues,] = useForm({
        name:"",
    });
    const [validaciones, setValidaciones] = useState({
        name_valid:false,
    })
    const {name, } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(sites.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listsites = [];
        sites.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listsites.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSites(listsites);
    }, [totalPageCount, currentPage])

    const toggle = ()=>{
        setModal(!modal);
    }
   
    useEffect(()=>{
        getSites();
    },[history.location]);


    const getSites = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Sites/List");

        if (respuesta.code === 200) {
            let listSite = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data,index) => {
                    
                let posicion = index + 1;
                let visibleTable = false;
                
                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                listSite.push({
                        id_mic_site: data.id_mic_site,
                        name: data.name,
                        visibleTable: visibleTable
                    });
                });
                setSites(listSite);
                setDataSiteSearch(listSite);
            }
        }
    }

    
    const handleCreate = () => {
        handleUpdateValues({
            name: "",
        });

        setValidaciones({
            name_valid: false,
        });

        setSitesId(0);
        setMethod("create");
        setModalTitle("Crear nuevo sitio");
        toggle();
    }   

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Sites/Create");
        if (respuesta.code === 200) {
            getSites();
            createSweet("create", "success", "Exito!", "Sitio creado con exito");
            setLoading(false);
            toggle();
        }
    }

    const handleUpdate = (id_mic_site) => {
        let site = sites.find(s => s.id_mic_site === id_mic_site);

        handleUpdateValues({
            name: site.name,
        });

        setValidaciones({
            name_valid: false,
        });

        setSitesId(id_mic_site);
        setMethod("update");
        setModalTitle("Modificación del sitio");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_mic_site: sitesId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Sites/Update");

        if (respuesta.code === 200) {
            getSites();
            createSweet("create", "info", "Exito!", "Sitio actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_mic_site) => {
        
        let site = sites.find(s => s.id_mic_site === id_mic_site);

        handleUpdateValues({
            name: site.name,
        });

        setSitesId(id_mic_site);
        setMethod("delete");
        setModalTitle("eliminar");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Sites/${sitesId}`);

        if (respuesta.code === 200) {
            getSites();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Sitio eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
       

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }
        setValidaciones(newValidations);

        return statusValidation;
    }

    const handleSearchSite = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataSiteSearch.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setSites(newMethod);
    }

    return {
        sites, name, validaciones,
        method, 
        handleInputChange, handleSearchSite, loading, 
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
       handleDelete, saveDelete,
       nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }

}




