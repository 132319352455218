import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormTomaMuestraLis } from './FormTomaMuestraLis';
import { DeleteTomaMuestra } from './DeleteTomaMuestra';


export const ModalTomaMuestra = (props) => {

    const {
 

        toggle3,method3,modalTitle3,modal3,saveCreate, cancellation_reazon, handleInputChange,id_cancellation_reazon_sample,loading,
        saveUpdate,saveDelete
    } = props;

    
    return (
   
        <Modal isOpen={modal3} toggle={toggle3} backdrop='static' keyboard={false} centered={true} className={(method3 !== 'delete') ? 'modal-lg' : ''}>
            <ModalHeader toggle={toggle3} className="bg-primary">
                {modalTitle3}
            </ModalHeader>
            {
                method3 === "create"
                    ? <FormTomaMuestraLis
                        button="Guardar"
                        {...{ toggle3,cancellation_reazon, handleInputChange,id_cancellation_reazon_sample,loading ,methodAction: saveCreate}}
                    />
                    : (method3 === "update")
                        ?
                        <FormTomaMuestraLis
                            button="Actualizar"
                            {...{
                                toggle3,cancellation_reazon, handleInputChange,id_cancellation_reazon_sample,loading ,methodAction: saveUpdate
                            }}
                        />
                        :
                        <DeleteTomaMuestra
                             {...{ toggle3, loading, saveDelete,  }}
                         />
                        
                        
            }
        </Modal>
    )
}
