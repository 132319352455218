
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';

export const useInfoAnalytes = () => {

    const history = useHistory();

    const [analytes, setAnalytes] = useState([]);
    const [analyteId, setAnalyteId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet, sweetConfirmation,sweetDelete, sweetError, createSweett, sweetConfirmationUpdate] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        code: "",
        is_interfaced: true
    });


    const { name, code, is_interfaced } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        code_valid: false,
    });

    const toggle = () => {
        setModal(!modal);
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(analytes.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listAnalyte = [];
        analytes.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAnalyte.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAnalytes(listAnalyte);

    }, [totalPageCount, currentPage])

    const getInfoAnalytes = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        //let listAnalyte = [];
        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/ListAnalytes");
        console.log("🚀 ~ getInfoAnalytes ~ respuesta:", respuesta)

        if (respuesta.code === 200) {

            let listAnalyte1 = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((obj, index) => {


                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listAnalyte1.push({
                        id_info_analyte: obj.id_info_analyte,
                        name: obj.name,
                        code: obj.code,
                        is_interfaced: obj.is_interfaced,
                        visibleTable: visibleTable
                    });
                });
                setAnalytes(listAnalyte1);
                setAnalyteData(listAnalyte1)
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInfoAnalytes);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setAnalytes([]);

        }

    }, [history]);

    useEffect(() => {
        getInfoAnalytes();
    }, [getInfoAnalytes]);

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: "",
            is_interfaced: true
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
        });

        setAnalyteId(0);
        setMethod("create");
        setModalTitle("Nuevo analito");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/CreateAnalyte");

        if (respuesta.code === 200) {
            reset();
            createSweet("create", "success", "Exito!", "Analito creado con exito");
            setLoading(false);
            toggle();
            getInfoAnalytes();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false)
        }

    }

    const handleUpdate = (id_info_analyte) => {
        let analyte = analytes.find(x => x.id_info_analyte === parseInt(id_info_analyte));

        handleUpdateValues({
            name: analyte.name,
            code: analyte.code,
            is_interfaced: analyte.is_interfaced
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
        });

        setAnalyteId(id_info_analyte);
        setMethod("update");
        setModalTitle("Actualizar analito");
        toggle();
    }

    const saveUpdate = async (e, validation = -1) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        if (validation === 0) {
            
            setLoading(false);
            return;
        }

        let body = {
            id_info_analyte: analyteId,
            ...{...formValues}
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: "PUT",
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, `InfoAnalyte/UpdateAnalyte?valid_updated=${validation === -1 ? false : validation}`);

        if (respuesta.code === 200) {
            
            let validation = respuesta.data.valid_action;
            let message = respuesta.data.message;

            if (validation && message !== '') {
                
                sweetConfirmationUpdate("Advertencia", message, "warning", saveUpdate, e, true);
                return;
            }
            else if (validation === false && message === "") {
                sweetConfirmationUpdate("Información", "Deseas actualizar el siguiente analito", "warning",saveUpdate, e, true)
                return;
            }
            else
            {
                getInfoAnalytes();
                setLoading(false);                
                createSweet("success", "success", "Info", message);
                toggle();
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading();
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false);
        }
    }   

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.code === undefined || formValues.code.length < 2) {
            newValidations = {
                ...newValidations,
                code_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_valid: false
            }
        }        
        setValidaciones(newValidations);

        return statusValidation;
    }

    const [analyteData, setAnalyteData] = useState([])

    const handleSearch = (e) => {


        let busqueda = e.target.value;
        let newMethod = [];

        analyteData.forEach(element => {
            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setAnalytes(newMethod);
    }


    return {
        analytes, method, validaciones,
        name, code, is_interfaced,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearch,
        handleUpdate, saveUpdate
    }
};
