import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { validarSesion } from '../tokens/useToken';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';

export const useViewDetailWorkOrder = () => {

    const history = useHistory();
    const { id_work_order } = useParams();

    const [detailWorkOrder, setDetailWorkOrder] = useState([]);
    const [examsPrinters, setExamsPrinters] = useState([]);
    const [count, setCount] = useState(0);
    const [printerPoints, setPrinterPoints] = useState([]);
    const [createSweet] = useSweetAlert();
    const [formPrinter, handleInputChangePrinter, resetPrinter, handleUpdateValuesPrinter, handleSelectValuesPrinter] = useForm({
        id_printer_point: 0
    });
    const { id_printer_point } = formPrinter;
    const [loading, setLoading] = useState(false);

    const getViewDetailWorkOrder = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrder/DetailWorkOrder/" + id_work_order);

        if (respuesta.code === 200) {
            setDetailWorkOrder(respuesta.data);
        }
        else {

            validarSesion(history, respuesta.code, getViewDetailWorkOrder);
        }
    }, [history]);

    const getPrinterPoints = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let newPrinterPoints = [];

        const respuesta = await sendRequest(requestOptions, "PrinterPoints");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 0) {
                respuesta.data.forEach((element) => {
                    newPrinterPoints.push({
                        value: element.id_printer_point,
                        label: element.name
                    });
                });
                setPrinterPoints(newPrinterPoints);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron los puntos de impresión");
                setPrinterPoints([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getPrinterPoints);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Orden de trabajo", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Orden de trabajo", respuesta.data.msg);
        }
        
    }

    useEffect(() => {
        getViewDetailWorkOrder();
        getPrinterPoints();
    }, []);

    const handleCheckPrint = (id, type) => {
        let newExamsPrinters = examsPrinters;
        if (newExamsPrinters.find(x => x.id === id && x.type === type) === undefined) {
            newExamsPrinters.push({
                id: id,
                type: type
            });
            setExamsPrinters(newExamsPrinters);
            setCount(newExamsPrinters.length);
        } else {
            let nuevo = [];
            newExamsPrinters.forEach(obj => {
                if (!(obj.id === id && obj.type === type)) {
                    nuevo.push(obj);
                }
            });
            setExamsPrinters(nuevo);
            setCount(nuevo.length);
        }
    }

    const imprimir = async () => {
        setLoading(true);
        let raw = JSON.stringify(examsPrinters);

        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, `WorkOrder/RePrint/${id_work_order}/${id_printer_point.value}`);
        if (respuesta.code === 200) {
            createSweet("update", "success", "La re impresión fue exitosa");
        } 
        else {
            ////console.log("Error al crear la unidad...");
            validarSesion(history, respuesta.code, imprimir);
        }
        setLoading(false);
    }

    return {
        detailWorkOrder, handleCheckPrint, examsPrinters, count, imprimir,
        printerPoints, handleInputChangePrinter, id_printer_point, handleSelectValuesPrinter, loading
    }
}
