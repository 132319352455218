import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteTypePrecodedText = ({
    toggle, loading, name, saveDelete
}) => {
    return (
        <Form role="form" onSubmit={saveDelete}>
            <ModalBody>
                <h6>Estas a punto de eliminar el tipo de texto precodificado: <i>{name}</i>
                    <br />
                    <b>¿Estás seguro de hacerlo?</b></h6>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar tipo de texto precodificado
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
