import React, { Fragment, useState } from 'react'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Table, Button, Collapse, Progress, Badge } from 'reactstrap'
import { useQuoterDashboard } from '../../hooks/quoter/useQuoterDashboard'
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ToolTips } from '../../components/tooltips/ToolTips';
import { useQuatation } from '../../hooks/quoter/useQuatation';


export const IndexDashboardQuoter = () => {

	const [filterDate, setFilterDate] = useState(true);

	const {
		date_ini, date_end, obtenerDatos, handlePickerValues, validInputDate, validToInputDate, loading, listQuoter,
		countTotal, countUsed, printPdf, downdloadpdf, navigateUpdate

	} = useQuoterDashboard();
	////console.log('LISTQUOTER: ',listQuoter)

	return (
		<Fragment>
			<Col sm="12" className='p-l-0' >
				<Row>
					<Col sm="3">
						<Col sm="12">
							<Card className='shadow'>
								<CardHeader className='bg-primary p-2' >
									<label className=''>Acciones</label>
								</CardHeader>
								<br />
								<label className='f-w-600 f-14 badge badge-light-primary'>Generar Cotización</label> <br />
								<Col sm="12">
									<Row>
										<Col sm="2" className='p-r-0' >
										</Col>
										<Col sm="8" style={{ textAlign: "-webkit-center" }} >
											<Button outline color="primary" size='xs' onClick={() => navigateUpdate()} >Cotización</Button>
										</Col>
									</Row>
								</Col>
								<br />
							</Card>
						</Col>

						<Col className="default-according style-1 faq-accordion job-accordion " id="accordionoc" xs="12" sm="12" md="12" lg="12" xl="12">
							<Row className="position-sticky" style={{ top: 120 }}>
								<Col xl="12">
									<Card>
										<br />
										<CardHeader className=' bg-primary '>
											<h5 className="mb-0">
												<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
													data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="fa fa-filter fIconFilter"></i>&nbsp; &nbsp; &nbsp; &nbsp;Filtros</Button>
											</h5>
										</CardHeader>
										<Collapse isOpen={filterDate}>
											<CardBody className="p-3">
												<Form className=''>
													<Row>
														<Col md="12">
															<FormGroup>
																<Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
																<Datetime
																	key={"date_ini"}
																	inputProps={{
																		placeholder: 'DD-MM-YYYY',
																		name: 'date_ini',
																		autoComplete: "off",
																		className: "form-control form-control-sm input-air-primary"
																	}}
																	timeFormat={false}
																	value={date_ini}
																	locale="es"
																	isValidDate={validInputDate}
																	closeOnSelect={true}
																	onChange={(e) => handlePickerValues(e, "date_ini")}
																	dateFormat="DD-MM-YYYY"
																/>
															</FormGroup>
														</Col>
														<br />
														<Col md="12">
															<FormGroup>
																<Label className='col-md-12'>Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Datetime
																	key={"date_end"}
																	inputProps={{
																		placeholder: 'DD-MM-YYYY',
																		name: 'date_end',
																		autoComplete: "off",
																		className: "form-control form-control-sm input-air-primary"
																	}}
																	timeFormat={false}
																	value={date_end}
																	locale="es"
																	isValidDate={validToInputDate}
																	closeOnSelect={true}
																	onChange={(e) => handlePickerValues(e, "date_end")}
																	dateFormat="DD-MM-YYYY"
																/>
															</FormGroup>
														</Col>
														<Col sm="12">
															<br />
															<Button
																type='button'
																className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																outline
																color="primary"
																style={{ textAlign: "-webkit-center" }}
																onClick={() => obtenerDatos()}
															>
																{"Buscar"}
															</Button>
														</Col>
													</Row>
												</Form>
											</CardBody>
										</Collapse>
									</Card>
								</Col>
							</Row>
						</Col>






					</Col>




					<Col xs="9" sm="9" md="9" lg="9" xl="9">
						<Card className='shadow' style={{ height: "145px" }}>
							<div className='ecommerce-widgets media'>
								<Col sm="12">
									<Row>
										<Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
											<Row>
												<Col xs="4" sm="4" md="4" lg="4" xl="4">
													<div className='ecommerce-box light-bg-primary'>
														<img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
													</div>
												</Col>
												<Col xs="6" sm="6" md="6" lg="6" xl="6">
													<label className='f-20'>{countTotal}</label>
												</Col>
												<Col xs="12" sm="12" md="12" lg="12" xl="12">
													<label className='txt-secondary'>Cotizaciones Generadas</label>
												</Col>
												<Col sm="12">
													{
														(countTotal === 0) ?
															<Progress className="sm-progress-bar" color="danger" value={1} style={{ height: "8px" }} />
															:
															<Progress className="sm-progress-bar" color="primary" value={100} style={{ height: "8px" }} />
													}
												</Col>
											</Row>
										</Col>
										<Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
											<Row>
												<Col xs="4" sm="4" md="4" lg="4" xl="4" >
													<div className='ecommerce-box light-bg-primary'>
														<img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
													</div>
												</Col>
												<Col xs="6" sm="6" md="6" lg="6" xl="6">
													<label className='f-20'>{countUsed}</label>
												</Col>
												<Col xs="12" sm="12" md="12" lg="12" xl="12">
													<label className='txt-secondary f-16'>Cotizaciones utilizadas</label>
												</Col>
												<Col sm="12">
													{
														(countUsed === 0) ?
															<Progress className="sm-progress-bar" color="danger" value={1} style={{ height: "8px" }} />
															:
															<Progress className="sm-progress-bar" color="primary" value={100} style={{ height: "8px" }} />
													}
												</Col>
											</Row>
										</Col>
										<Col xs="4" sm="4" md="4" lg="4" xl="4"> <br />
											<Row>
												<Col xs="4" sm="4" md="4" lg="4" xl="4">
													<div className='ecommerce-box light-bg-primary'>
														<img src={require("../../assets/images/loginHelenLabs/dashboard/WorkOrder.svg")} style={{ width: "80%" }} alt="" />
													</div>
												</Col>
												<Col xs="6" sm="6" md="6" lg="6" xl="6">
													<label className='f-20'>{"0"}</label>
												</Col>
												<Col xs="12" sm="12" md="12" lg="12" xl="12">
													<label className='txt-secondary f-16'>Pacientes Totales</label>
												</Col>
												<Col sm="12">
													<Progress className="sm-progress-bar" color="danger" value={1} style={{ height: "8px" }} />
												</Col>
											</Row>
										</Col>

									</Row>
								</Col>
							</div>
						</Card>
						<br />
						<Card>
							<CardBody className="p-1">
								<div className="ribbon ribbon-clip ribbon-primary">
									{"Detalle"}
								</div>

								<div className="table-responsive">
									{
										listQuoter.length === 0 ?
											<div style={{ textAlign: "-webkit-center" }} >
												<img src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "50%" }} alt="" /> <br />
												<label className='f-w-600 f-14 badge badge-light-primary'>0 resultados de su busqueda</label>
											</div>
											:
											<div style={{ textAlign: "-webkit-center" }}> <br /> <br /> <br />
												<Table>
													<thead className=" text-center">
														<tr className='border-bottom-info'>
															<th scope="col" >#</th>
															<th scope="col" >Código</th>
															<th scope="col" >Nombre</th>
															<th scope="col" >Apellido Paterno</th>
															<th scope="col" >Estatus</th>
															<th scope="col" >Fecha de uso</th>
															<th scope="col" >Acciones</th>
														</tr>
													</thead>
													<tbody className="f-13 text-center">
														{
															listQuoter.length >= 0 && listQuoter.map((obj, key) => {
																return (
																	<tr key={key} className=" border-bottom-info">
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">{(key + 1)}</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.code}</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.name_user}</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">{obj.aPaterno}</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">
																			{
																				obj.status === true ?
																					<Badge color='success' >Utilizado</Badge>
																					:
																					<Badge color='danger'>No utilizado</Badge>
																			}
																		</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">
																			{
																				obj.use_admision === null ?

																					<i className="fa fa-calendar txt-danger f-16"></i>
																					:
																					<i className="fa fa-calendar-o txt-success f-14">{obj.use_admision}</i>
																			}
																		</td>
																		<td className="pt-1 pb-1 pl-2 pr-2 align-middle">
																			<Badge
																				id={`tooltip-ticket-${key}`}
																				color="dark"
																				className='f-12 pointer'
																				onClick={() => downdloadpdf(obj.id_quoter)}
																			>
																				<i className="icofont icofont-download-alt "></i>
																				<ToolTips
																					placement="top"
																					dataTarget={`tooltip-ticket-${key}`}
																					dataText="Descargar"
																				/>
																			</Badge>
																		</td>
																	</tr>

																)
															})
														}


													</tbody>


												</Table>

											</div>

									}

								</div>

							</CardBody>








						</Card>



					</Col>
				</Row>

			</Col>
		</Fragment>
	)
}
