import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormTypePrecodedText } from './FormTypePrecodedText';
import { DeleteTypePrecodedText } from './DeleteTypePrecodedText';

export const ModalTypePrecodedText = (props) => {
    const { modal, toggle, loading, method, name, handleInputChange, validaciones, saveCreate, saveUpdate, saveDelete, data, data2, changeOrder, arrayPrecod, changeArrayPrecod } = props;
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Creación de tipo de texto precodificado"
                        : (method === "update") ?
                            "Actualización de tipo de texto precodificado"
                            : "Eliminar tipo de texto precodificado"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormTypePrecodedText
                    // nuevo tipo de texto precodificado
                        button="Crear texto precodificado"
                        {...{ toggle, loading, name, handleInputChange, validaciones, data, method, methodAction: saveCreate }}
                    />
                    : (method === "update") ?
                        <FormTypePrecodedText
                        // Actualizar tipo de texto precodificado
                            button="Actualizar texto precodificado"
                            {...{ toggle, loading, name, handleInputChange, validaciones, data, method, data2, changeOrder, arrayPrecod, changeArrayPrecod, methodAction: saveUpdate }}
                        />
                        : <DeleteTypePrecodedText {...{ toggle, name, saveDelete }} />
            }
        </Modal>
    )
}
