import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { LabelInputSpecimen, LabelInputAbbreviationSpecimen, ModalButtonCancel } from '../../constant';

export const FormSpecimen = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, abbreviation, button
    } = props;

    const {
        name_valid, abbreviation_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">{ LabelInputSpecimen } <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={name}
                                autoComplete="nope"
                                onChange={handleInputChange}
                                invalid={name_valid}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Debe contener 3 ó más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="col-form-label">{ LabelInputAbbreviationSpecimen } <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="abbreviation"
                                value={abbreviation}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={abbreviation_valid}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Debe contener 3 o más caracteres</FormFeedback>
                        </FormGroup> 
                    </Col>
                </Row>
            </ModalBody> 
            <ModalFooter>
                <Button size="sm" outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm"  outline color='danger' type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Form>
    )
}
