// dashbaord
import Default from '../components/dashboard/default'
import Ecommerce from '../components/dashboard/ecommerce'

// starter kits 
import Starterkits from '../components/starter-kits'

//Admission of patient
import { IndexWorkOrder } from '../views/admission/workOrder/IndexWorkOrder';
import { IndexCompanies } from '../views/catalog/companies/IndexCompanies';
import { IndexDoctors } from '../views/catalog/doctors/IndexDoctors';
import { IndexPatient } from '../views/catalog/patient/IndexPatient';
import { IndexBranch } from '../views/configuraciones/branches/IndexBranch';
import { IndexGraph } from '../views/graphs/IndexGraph';

import { IndexPrecodedText } from '../views/configuraciones/precodedTexts/IndexPrecodedText';
import { IndexUnit } from '../views/configuraciones/units/IndexUnit';
import { IndexMachine } from '../views/configuraciones/machines/IndexMachine';
import { IndexPrices } from '../views/configuraciones/prices/IndexPrices';
import { IndexPrinterPoints } from '../views/configuraciones/printerPoints/IndexPrinterPoints';
import { IndexSpecimens } from '../views/configuraciones/specimens/IndexSpecimens';
import { IndexSections } from '../views/configuraciones/sections/IndexSections';
import { IndexExams } from '../views/disenio/exams/IndexExams';
import { IndexExamMethod } from '../views/disenio/exams/IndexExamMethod';
import { CreateOrUpdateAnalytes } from '../views/disenio/exams/CreateOrUpdateAnalytes';
import { IndexProfile } from '../views/disenio/profiles/IndexProfile';
import { IndexWorkOrderDay } from '../views/admission/viewWorkOrderDay/IndexWorkOrderDay';
import { IndexRates } from '../views/configuraciones/rates/IndexRates';
import { IndexWorkSheetsDay } from '../views/admission/viewWorkOrderDay/IndexWorkSheetsDay';
import { IndexCommercialLines } from '../views/configuraciones/commercialLines/IndexCommercialLines';
import { IndexPayMethods } from '../views/configuraciones/payMethods/IndexPayMethods';
import { IndexViewDetailWorkOrder } from '../views/admission/detailWorkOrder/IndexViewDetailWorkOrder';
import { IndexAddAgreement } from '../views/agreements/IndexAddAgreement';
import { IndexExamProductionCost } from '../views/disenio/exams/IndexExamProductionCost';
import { HistoryPatient } from '../views/catalog/patient/HistoryPatient';
import { IndexSpecialDiscount } from '../views/discounts/IndexSpecialDiscount';
import { IndexCommonDiscount } from '../views/discounts/IndexCommonDiscount';
import { IndexAgreements } from '../views/configuraciones/agreements/IndexAgreements';
import { IndexUsedDiscounts } from '../views/discounts/IndexUsedDiscounts';
import { IndexGraphBar } from '../views/graphs/IndexGraphBar';
import { IndexLaboratories } from '../views/catalog/laboratoriesExternal/indexLaboratories'
import { IndexUpdateLaboratories } from '../views/catalog/laboratoriesExternal/IndexUpdateLaboratories';
import { ListCompaniesAgreements } from '../views/agreements/ListCompaniesAgreements';
import { DetailCompanyAgreements } from '../views/agreements/DetailCompanyAgreements';
import { IndexProfit } from '../views/configuraciones/profit/IndexProfit';
import { IndexBalance } from '../views/balanceDashboard/IndexBalance';
import { IndexNameCompany } from '../views/balanceDashboard/company/IndexNameCompany';
import { CaptureResultsFromNim } from '../views/admission/resultsCapture/CaptureResultsFromNim';
import { CaptureResultsFromNim as CaptureResultsFromNimMicro } from '../views/admission/resultsCaptureSanitaria/CaptureResultsFromNim';
import { IndexReturnReason } from '../views/configuraciones/returnReason/IndexReturnReason';
import { IndexSampling } from '../views/configuraciones/samplings/IndexSampling';
import { IndexInvoiceClient } from '../views/invoice/IndexInvoiceClient';
import { IndexAnalytes } from '../views/disenio/analytes/IndexAnalytes';
import { CreateExamSimple } from '../views/disenio/exams/CreateExamSimple';
import { IndexPriceExam } from '../views/disenio/profiles/IndexPriceExam';
import { IndexQuatation } from '../views/quoter/IndexQuatation';
import { IndexQuoter } from '../views/quoter/indexQuoter'
import { IndexUser } from '../views/configuraciones/users/IndexUser';
import { IndexIndications } from '../views/configuraciones/indications/IndexIndications';
import { CostsProfile } from '../views/disenio/profiles/CostsProfile';
import { GlobalReferenceValues } from '../views/disenio/profiles/GlobalReferenceValuesProfile';
import { IndexInvoiceCFDI } from '../views/invoice/IndexInvoiceCFDI';
import { IndexReports } from '../views/balanceDashboard/company/IndexReports';
import { IndexInvoiceCompany } from '../views/invoice/IndexInvoiceCompany';
import { IndexStatusWorkDay } from '../views/admission/viewWorkOrderDay/IndexStatusWorkDay';
import { IndexCancellationTM } from '../views/sampling/IndexCancellationTM';
import { IndexSurvey } from '../views/survey/IndexSurvey';
import { IndexCost } from '../views/configuraciones/prices/IndexCost'
import { IndexAnalyzerInterface } from '../views/configuraciones/analyzerInterface/IndexAnalyzerInterface';
import { IndexInformedConsent } from '../views/InformedConsent/IndexInformedConsent';
import { IndexSeller } from '../views/catalog/seller/IndexSeller';
import { IndexBacter } from '../views/micro/catalogos/IndexBacter';
import { IndexMorphology } from '../views/micro/catalogos/IndexMorphology';
import { IndexWorkOrderMicrobiology } from '../views/admission/workOrder/IndexWorkOrderMicrobiology';
import { Indexlog } from '../views/configuraciones/logss/Indexlog';
import { IndexAntibiotic } from '../views/micro/catalogos/IndexAntibiotic';
import { IndexAntibiogram } from '../views/micro/catalogos/IndexAntibiogram';
import { IndexQuoterMic } from '../views/micro/cotizacion/IndexQuoterMic';
import { IndexMicroQuoter } from '../views/sanitary/microQuote/indexMicroQuoter';
import { IndexListSampligs } from '../views/sanitary/micSampling/IndexListSampligs';

import { IndexListMachinesSanitary } from '../views/sanitary/worksheets/IndexListMachinesSanitary';
import { IndexListLot } from '../views/sanitary/worksheets/IndexListLot';
import { IndexWorkSheetSanitary } from '../views/sanitary/worksheets/IndexWorkSheetSanitary';
import { IndexCaptureWorkSheetSanitary } from '../views/sanitary/worksheets/IndexCaptureWorkSheetSanitary';

import { IndexCulture } from '../views/micro/catalogos/IndexCulture';
import { CreateExam } from '../views/micro/disenio/exams/CreateExam';
import { IndexTransportsRacks } from '../views/transports/IndexTransportsRacks';
import { IndexCurvesTimes } from '../views/configuraciones/curvesTimes/IndexCurvesTimes';
import { IndexExamsMicrobiology } from '../views/micro/disenio/exams/IndexExamsMicrobiology';

import { IndexExamProductionCostMicrobiology } from '../views/micro/disenio/exams/IndexExamProductionCostMicrobiology';
import { IndexExamMethodMicrobiology } from '../views/micro/disenio/exams/IndexExamMethodMicrobiology';
import { IndexSatisfaction } from '../views/survey/IndexSatisfaction';
import { IndexCash } from '../views/cash/IndexCash';
import { IndexDashboardDoctor } from '../views/dashboardInformatio/IndexDashboardDoctor';
import { IndexDashboardQuoter } from '../views/quoter/IndexDashboardQuoter';
import { IndexUserRole } from '../views/configuraciones/usersRoles/IndexUserRole';
import { IndexDoubleValidation } from '../views/admission/processExam/IndexDoubleValidation';
import { IndexConfigExams } from '../views/configuraciones/maquilaInterna/IndexConfigExams';

import { IndexView1 } from '../views/admission/Bi/IndexView1';
import { IndexView2 } from '../views/admission/Bi/IndexView2';
import { IndexPbi1 } from '../views/admission/Bi/IndexPbi1';
import { IndexPbi2 } from '../views/admission/Bi/IndexPbi2';
import { IndexPbi3 } from '../views/admission/Bi/IndexPbi3';
import { IndexPbi4 } from '../views/admission/Bi/IndexPbi4';
import { InvoiceSettings } from '../views/configuraciones/commercialLines/InvoiceSettings';
import { IndexInvoiceService } from '../views/invoice/IndexInvoiceService';

import { IndexSites } from '../views/sanitary/sites/IndexSites';
import { IndexMicSample } from "../views/sanitary/micSample/IndexMicSample";
import { IndexMicSiteArea } from "../views/sanitary/micSiteArea/IndexMicSiteArea";
import { IndexTypeSample } from "../views/sanitary/typeSample/IndexTypeSample";
import { IndexCashFlowGlobal } from '../views/cash/IndexCashFlowGlobal';
import { IndexMicSampling } from '../views/sanitary/micSampling/IndexMicSampling';
import { IndexMicDiary } from '../views/sanitary/micSampling/IndexMicDiary';
import { IndexViewInvoice } from '../views/invoice/IndexViewInvoice';

import { IndexInvoiceReport } from '../views/invoice/IndexInvoiceReport';
import { IndexUpdatePrices } from '../views/invoice/IndexUpdatePrices';
import { IndexReportCxc } from '../views/reports/cxc/IndexReportCxc';
import { IndexBankingDeposits } from '../views/invoice/IndexBankingDeposits';

import { IndexViewCalender } from '../views/SerieB/IndexViewCalender'; 

export const routes = [

        { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: Default },
        { path: `${process.env.PUBLIC_URL}/Bi/1`, Component: IndexView1 },
        { path: `${process.env.PUBLIC_URL}/Bi/2`, Component: IndexView2 },

        { path: `${process.env.PUBLIC_URL}/admisiones/registro/`, Component: IndexWorkOrder },
        { path: `${process.env.PUBLIC_URL}/admisiones/:id_work_order/editar`, Component: IndexWorkOrder },
        { path: `${process.env.PUBLIC_URL}/admisiones/microbiologia`, Component: IndexWorkOrderMicrobiology },
        { path: `${process.env.PUBLIC_URL}/dashboard/Cotizador`, Component: IndexDashboardQuoter },
        { path: `${process.env.PUBLIC_URL}/admisiones/:id_work_order/editar/:idNotifications`, Component: IndexWorkOrder },


        { path: `${process.env.PUBLIC_URL}/busqueda/trabajo-dia`, Component: IndexWorkOrderDay },
        { path: `${process.env.PUBLIC_URL}/busqueda/hojas-trabajo-dia`, Component: IndexWorkSheetsDay },
        { path: `${process.env.PUBLIC_URL}/busqueda/status-del-dia`, Component: IndexStatusWorkDay },
        { path: `${process.env.PUBLIC_URL}/busqueda/doble-validacion`, Component: IndexDoubleValidation },
        { path: `${process.env.PUBLIC_URL}/busqueda/:id_work_order/detalle-orden`, Component: IndexViewDetailWorkOrder },

        { path: `${process.env.PUBLIC_URL}/catalogos/pacientes/`, Component: IndexPatient },
        { path: `${process.env.PUBLIC_URL}/catalogos/pacientes/:pacienteId/historial`, Component: HistoryPatient },
        { path: `${process.env.PUBLIC_URL}/catalogos/clientes/`, Component: IndexCompanies },
        { path: `${process.env.PUBLIC_URL}/catalogos/medicos/`, Component: IndexDoctors },
        { path: `${process.env.PUBLIC_URL}/catalogos/laboratoriesExternal`, Component: IndexLaboratories },
        { path: `${process.env.PUBLIC_URL}/catalogos/:laboratoriesId/asignar-examen`, Component: IndexUpdateLaboratories },
        { path: `${process.env.PUBLIC_URL}/catalogos/vendedores/`, Component: IndexSeller },


        { path: `${process.env.PUBLIC_URL}/captura-resultados/:nim`, Component: CaptureResultsFromNim },
        { path: `${process.env.PUBLIC_URL}/captura-resultados-sanitaria/:nim`, Component: CaptureResultsFromNimMicro },

        { path: `${process.env.PUBLIC_URL}/convenios`, Component: IndexAgreements },
        { path: `${process.env.PUBLIC_URL}/convenios/nuevo-convenio`, Component: IndexAddAgreement },
        { path: `${process.env.PUBLIC_URL}/convenios/:agreementId/editar-convenio`, Component: IndexAddAgreement },
        { path: `${process.env.PUBLIC_URL}/clientes/convenios`, Component: ListCompaniesAgreements },
        { path: `${process.env.PUBLIC_URL}/clientes/convenios/:companyId`, Component: DetailCompanyAgreements },

        { path: `${process.env.PUBLIC_URL}/diseno/examenes`, Component: IndexExams },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/:examId/metodos`, Component: IndexExamMethod },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/:examId/analitos`, Component: CreateOrUpdateAnalytes },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/:examId/precios`, Component: IndexExamProductionCost },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/simple/:examEditId`, Component: CreateExamSimple },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/simple`, Component: CreateExamSimple },
        { path: `${process.env.PUBLIC_URL}/diseno/perfiles`, Component: IndexProfile },
        { path: `${process.env.PUBLIC_URL}/diseno/nuevo-perfil/:methodd`, Component: IndexPriceExam },
        { path: `${process.env.PUBLIC_URL}/diseno/:profilesId/editar-perfil/:methodd`, Component: IndexPriceExam },
        { path: `${process.env.PUBLIC_URL}/diseno/:profilesId/costs`, Component: CostsProfile },
        { path: `${process.env.PUBLIC_URL}/diseno/:profilesId/global`, Component: GlobalReferenceValues },
        { path: `${process.env.PUBLIC_URL}/diseno/analitos`, Component: IndexAnalytes },

        { path: `${process.env.PUBLIC_URL}/configuraciones/unidades/`, Component: IndexUnit },
        { path: `${process.env.PUBLIC_URL}/configuraciones/textos-precodificados/`, Component: IndexPrecodedText },
        { path: `${process.env.PUBLIC_URL}/configuraciones/sucursales/`, Component: IndexBranch },
        { path: `${process.env.PUBLIC_URL}/configuraciones/:rateId/precios`, Component: IndexPrices },
        { path: `${process.env.PUBLIC_URL}/configuraciones/tarifario`, Component: IndexRates },
        { path: `${process.env.PUBLIC_URL}/configuraciones/puntos-impresion`, Component: IndexPrinterPoints },
        { path: `${process.env.PUBLIC_URL}/configuraciones/equipos`, Component: IndexMachine },
        { path: `${process.env.PUBLIC_URL}/configuraciones/especimenes`, Component: IndexSpecimens },
        { path: `${process.env.PUBLIC_URL}/configuraciones/secciones`, Component: IndexSections },
        { path: `${process.env.PUBLIC_URL}/configuraciones/transportes`, Component: IndexTransportsRacks },
        { path: `${process.env.PUBLIC_URL}/configuraciones/especimenes`, Component: IndexSpecimens },
        { path: `${process.env.PUBLIC_URL}/configuraciones/razon-social`, Component: IndexCommercialLines },
        { path: `${process.env.PUBLIC_URL}/configuraciones/razon-social/configuracion-folios/:idBranch`, Component: InvoiceSettings },
        { path: `${process.env.PUBLIC_URL}/configuraciones/metodos-pago`, Component: IndexPayMethods },
        { path: `${process.env.PUBLIC_URL}/configuraciones/margen-minimo`, Component: IndexProfit },
        { path: `${process.env.PUBLIC_URL}/configuraciones/usuarios`, Component: IndexUser },
        { path: `${process.env.PUBLIC_URL}/configuraciones/indicaciones`, Component: IndexIndications },
        { path: `${process.env.PUBLIC_URL}/configuraciones/tiempos-curvas`, Component: IndexCurvesTimes },
        { path: `${process.env.PUBLIC_URL}/configuraciones/interfaz-analizador`, Component: IndexAnalyzerInterface },
        { path: `${process.env.PUBLIC_URL}/configuraciones/logs`, Component: Indexlog },
        { path: `${process.env.PUBLIC_URL}/configuraciones/roles`, Component: IndexUserRole },


        { path: `${process.env.PUBLIC_URL}/graph`, Component: IndexGraph },
        { path: `${process.env.PUBLIC_URL}/graph/bar`, Component: IndexGraphBar },

        { path: `${process.env.PUBLIC_URL}/descuentos/especiales`, Component: IndexSpecialDiscount },
        { path: `${process.env.PUBLIC_URL}/descuentos/campañas`, Component: IndexCommonDiscount },
        { path: `${process.env.PUBLIC_URL}/descuentos/usados`, Component: IndexUsedDiscounts },
        { path: `${process.env.PUBLIC_URL}/descuentos/usados`, Component: IndexUsedDiscounts },

        { path: `${process.env.PUBLIC_URL}/balance`, Component: IndexBalance },
        { path: `${process.env.PUBLIC_URL}/balance/company`, Component: IndexNameCompany },
        { path: `${process.env.PUBLIC_URL}/balance/reports`, Component: IndexReports },
        { path: `${process.env.PUBLIC_URL}/balance/corte/:User`, Component: IndexCash },
        { path: `${process.env.PUBLIC_URL}/reportes/cxc`, Component: IndexReportCxc },
        { path: `${process.env.PUBLIC_URL}/deposits/reports`, Component: IndexBankingDeposits },
        { path: `${process.env.PUBLIC_URL}/Reporte/:Admin`, Component: IndexCash },



        { path: `${process.env.PUBLIC_URL}/configuraciones/toma-de-muestra`, Component: IndexSampling },

        { path: `${process.env.PUBLIC_URL}/invoice/service`, Component: IndexInvoiceService },
        { path: `${process.env.PUBLIC_URL}/facturacion/Emitidos`, Component: IndexViewInvoice },
        { path: `${process.env.PUBLIC_URL}/invoice/:id_work_order_r/:id_patient_r/:_id_company_r/service`, Component: IndexInvoiceService },
        { path: `${process.env.PUBLIC_URL}/invoice/service/:request_id_invoice`, Component: IndexInvoiceService },
        { path: `${process.env.PUBLIC_URL}/facturacion/reporte`, Component: IndexInvoiceReport },
        { path: `${process.env.PUBLIC_URL}/facturacion/actualizarPrecios`, Component: IndexUpdatePrices },


        { path: `${process.env.PUBLIC_URL}/invoice/client`, Component: IndexInvoiceClient },
        { path: `${process.env.PUBLIC_URL}/invoice/company`, Component: IndexInvoiceCompany },
        { path: `${process.env.PUBLIC_URL}/invoice`, Component: IndexInvoiceCompany },

        { path: `${process.env.PUBLIC_URL}/quatation`, Component: IndexQuatation },
        { path: `${process.env.PUBLIC_URL}/quatation/nueva-cotizacion/:methodd`, Component: IndexQuoter },
        { path: `${process.env.PUBLIC_URL}/cfdi`, Component: IndexInvoiceCFDI },
        { path: `${process.env.PUBLIC_URL}/cancelaciones-de-toma-de-muestra`, Component: IndexCancellationTM },
        { path: `${process.env.PUBLIC_URL}/encuestas`, Component: IndexSurvey },
        { path: `${process.env.PUBLIC_URL}/configuraciones/:rateId/costos`, Component: IndexCost },

        { path: `${process.env.PUBLIC_URL}/consentimiento-informado`, Component: IndexInformedConsent },
        { path: `${process.env.PUBLIC_URL}/satisfaction/consentimiento`, Component: IndexSatisfaction },

        { path: `${process.env.PUBLIC_URL}/micro/bacterium`, Component: IndexBacter },
        { path: `${process.env.PUBLIC_URL}/micro/morpholgy`, Component: IndexMorphology },
        { path: `${process.env.PUBLIC_URL}/micro/antibiotic`, Component: IndexAntibiotic },
        { path: `${process.env.PUBLIC_URL}/micro/antibiogram`, Component: IndexAntibiogram },
        { path: `${process.env.PUBLIC_URL}/micro/cultivo`, Component: IndexCulture },
        { path: `${process.env.PUBLIC_URL}/micro/diseno/:examId/analitos`, Component: CreateExam },

        { path: `${process.env.PUBLIC_URL}/diseno/examenes/:examId/metodos`, Component: IndexExamMethod },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/Micro`, Component: IndexExamsMicrobiology },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/Micro/:examId/precios`, Component: IndexExamProductionCostMicrobiology },
        { path: `${process.env.PUBLIC_URL}/diseno/examenes/:examId/metodos/Micro`, Component: IndexExamMethodMicrobiology },

        { path: `${process.env.PUBLIC_URL}/dashboard/doctor`, Component: IndexDashboardDoctor },//este Index lo checare
        { path: `${process.env.PUBLIC_URL}/maquilaInterna/:branchId`, Component: IndexConfigExams },
        
        { path: `${process.env.PUBLIC_URL}/Pbi/1`, Component: IndexPbi1 },
        { path: `${process.env.PUBLIC_URL}/Pbi/2`, Component: IndexPbi2 },
        { path: `${process.env.PUBLIC_URL}/Pbi/3`, Component: IndexPbi3 },
        { path: `${process.env.PUBLIC_URL}/Pbi/4`, Component: IndexPbi4 },



        //SANITARIA - CATALOGOS
        { path: `${process.env.PUBLIC_URL}/sanitaria/sitios`, Component: IndexSites },
        { path: `${process.env.PUBLIC_URL}/sanitaria/muestras`, Component: IndexMicSample },
        { path: `${process.env.PUBLIC_URL}/sanitaria/areas`, Component: IndexMicSiteArea },
        { path: `${process.env.PUBLIC_URL}/sanitaria/cotizacion`, Component: IndexQuoterMic },
        { path: `${process.env.PUBLIC_URL}/sanitaria/tipo-muestra`, Component: IndexTypeSample },
        { path: `${process.env.PUBLIC_URL}/sanitaria/agenda`, Component: IndexMicDiary },
        { path: `${process.env.PUBLIC_URL}/sanitaria/muestreo/:code`, Component: IndexMicSampling },
        { path: `${process.env.PUBLIC_URL}/cierre/global`, Component: IndexCashFlowGlobal },
        { path: `${process.env.PUBLIC_URL}/configuraciones/catalogos`, Component: IndexReturnReason },
        { path: `${process.env.PUBLIC_URL}/sanitaria/cotizacion-micro`, Component: IndexMicroQuoter },
        { path: `${process.env.PUBLIC_URL}/sanitaria/lista-hojas-muestreo`, Component: IndexListSampligs},
        { path: `${process.env.PUBLIC_URL}/sanitaria/cat_equipos_sanitaria`, Component: IndexListMachinesSanitary},
        { path: `${process.env.PUBLIC_URL}/sanitaria/cat_lote_medios_sanitaria`, Component: IndexListLot},
        { path: `${process.env.PUBLIC_URL}/sanitaria/hojas_trabajo_sanitary`, Component: IndexWorkSheetSanitary},
        { path: `${process.env.PUBLIC_URL}/sanitaria/captura_hojas_trabajo_sanitaria/:idDetailMicSample`, Component: IndexCaptureWorkSheetSanitary},
       
        //*FINANCE 
        { path: `${process.env.PUBLIC_URL}/visualizador/calender`, Component: IndexViewCalender },
        { path: `${process.env.PUBLIC_URL}/visualizador/:_start_date/:_end_date/calender`, Component: IndexViewCalender },




]