import React from 'react'
import { useForm } from '../forms/useForm';
import { useState, useEffect } from 'react';
import { LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useLaboratories = () => {

    const history = useHistory();

    const [externalLaboratory, setExternalLaboratory] = useState([]);
    const [externalLaboratoryId, setExternalLaboratoryId] = useState(0);

    const [states, setStates] = useState({});
    const [municipalities, setMunicipalities] = useState({});

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();


    useEffect(() => {

        getExternalLaboratories();

    }, [history.location])


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        id_external_laboratory: 0,
        id_state: 0,
        id_municipality: 0,
        name_state: "",
        name_municipality: "",
        code: "",
        name: "",
        business_name: "",
        rfc: "",
        phone: "",
        address: "",
        colony: "",
        cp: 0,
        email: "",
        web_site: ""
    });


    const { id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
        name, business_name, rfc, phone, address, colony, cp, email, web_site } = formValues;


    const [validaciones, SetValidaciones] = useState({
        id_state_valid: false,
        id_municipality_valid: false,
        code_valid: false,
        name_valid: false,
        business_name_valid: false,
        rfc_valid: false,
        address_valid: false,
        colony_valid: false,
        cp_valid: false,
        email_valid: false,
    });


    const toggle = () => {
        setModal(!modal);
    }


    const handleCreate = () => {

        handleUpdateValues({
            id_external_laboratory: 0,
            id_state: 0,
            id_municipality: 0,
            name_state: "",
            name_municipality: "",
            code: "",
            name: "",
            business_name: "",
            rfc: "",
            phone: "",
            address: "",
            colony: "",
            cp: 0,
            email: "",
            web_site: ""
        });

        SetValidaciones({
            id_state_valid: false,
            id_municipality_valid: false,
            code_valid: false,
            name_valid: false,
            business_name_valid: false,
            rfc_valid: false,
            address_valid: false,
            colony_valid: false,
            cp_valid: false,
            email_valid: false,
        })

        setExternalLaboratoryId(0);
        setMethod("create");
        setModalTitle("Registrar un nuevo Laboratorio Externo");
        getState();
        toggle();
    }

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(externalLaboratory.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listExternal = [];
        externalLaboratory.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listExternal.push({
                ...data,
                visibleTable: visibleTable
            });
        });

       setExternalLaboratory(listExternal);

    }, [totalPageCount, currentPage]);







    const getExternalLaboratories = async () => {

        let requestOptions = {
            method: 'GET'
        };

        //let ListLaboratories = [];

        const respuesta = await sendRequest(requestOptions, "ExternalLaboratories/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
      
                let ListLaboratories = [];
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;
                startItems = endItems - numberItems;


                respuesta.data.forEach((value,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    ListLaboratories.push({
                        id_external_laboratory: value.id_external_laboratory,
                        id_state: value.id_state,
                        id_municipality: value.id_municipality,
                        name_state: value.name_state,
                        name_municipality: value.name_municipality,
                        code: value.code,
                        name: value.name,
                        business_name: value.business_name,
                        rfc: value.rfc,
                        phone: value.phone,
                        address: value.address,
                        colony: value.colony,
                        cp: value.cp,
                        email: value.email,
                        web_site: value.web_site,
                        visibleTable:visibleTable
                    });
                });
                setExternalLaboratory(ListLaboratories);
                setEsternalData(ListLaboratories)
            }
            else {
                createSweet("error", "warning", "Hubo un error!", "No se encontraron los laboratorios");
                setExternalLaboratory([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getExternalLaboratories);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "laboratorios Externos", respuesta.data.msg);
        }
      
    }


    const getState = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "State/List");

        if (respuesta.code === 200) {
            setStates(respuesta.data);
            //////console.log(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getState);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Laboratorios Externos", respuesta.data.msg);

        }
   
    }


    const getMunicipality = async (id_state) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `Municipality/List/${id_state}`);

        if (respuesta.code === 200) {
            setMunicipalities(respuesta.data);
            //////console.log(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMunicipality);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Laboratorios Externos", respuesta.data.msg);

        }
     
    }


    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mData = formValues;

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(mData)
        };

        const respuesta = await sendRequest(requestOptions, "ExternalLaboratories/Create");
        if (respuesta.code === 200) {

            getExternalLaboratories();
            reset();
            createSweet("create", "success", "Exito!", "Laboratorio externo creado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Laboratorios Externos", respuesta.data.msg);
            //setLoading(false);

        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        // }
    }


    const handleChangeState = (e) => {
        handleInputChange(e);

        let mValue = e.target.value;

        getMunicipality(mValue);
    }


    const handleUpdate = (id_external_laboratory) => {

        let laboratory = externalLaboratory.find(b => b.id_external_laboratory === parseInt(id_external_laboratory));

        handleUpdateValues({
            id_state: laboratory.id_state,
            id_municipality: laboratory.id_municipality,
            name_state: laboratory.name_state,
            name_municipality: laboratory.name_municipality,
            code: laboratory.code,
            name: laboratory.name,
            business_name: laboratory.business_name,
            rfc: laboratory.rfc,
            phone: laboratory.phone,
            address: laboratory.address,
            colony: laboratory.colony,
            cp: laboratory.cp,
            email: laboratory.email,
            web_site: laboratory.web_site
        });
        getState();
        getMunicipality(laboratory.id_state);
        setExternalLaboratoryId(id_external_laboratory);
        setMethod("update");
        setModalTitle("Modificar laboratorio");
        toggle();
    }


    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }


        let body = {
            id_external_laboratory: externalLaboratoryId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "ExternalLaboratories/Update");

        if (respuesta.code === 200) {
            getExternalLaboratories();
            createSweet("create", "info", "Exito!", "Laboratorio actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Laboratorios Externos", respuesta.data.msg);
            //setLoading(false);

        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        //     setLoading(false);
        //     validarSesion(history, saveUpdate);
        // }
    }



    const handleDelete = (id_external_laboratory) => {
        let laboratory = externalLaboratory.find(b => b.id_external_laboratory === parseInt(id_external_laboratory));


        handleUpdateValues({
            code: laboratory.code,
            name: laboratory.name
        });
        setExternalLaboratoryId(id_external_laboratory);
        setMethod("delete");
        setModalTitle("Eliminar laboratorio");
        toggle();

    }


    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };
        const respuesta = await sendRequest(requestOptions, `ExternalLaboratories/${externalLaboratoryId}`);

        if (respuesta.code === 200) {
            getExternalLaboratories();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Laboratorio eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Laboratorios Externos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Laboratorios Externos", respuesta.data.msg);
            setLoading(false);

            //setLoading(false);

        }


        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        //     setLoading(false);
        //     validarSesion(history, saveDelete);
        // }
    }

    
const [esternalData, setEsternalData] = useState([]);

    const handleSearchExternal = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        esternalData.forEach(element => {
            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setExternalLaboratory(newMethod);
    }








    const validarForm = () => {

        let statusValidacion = false;
        let newValidaciones = {};

        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValues.id_state === "-1" || formValues.id_state === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_state_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_state_valid: false
            };
        }

        if (formValues.id_municipality === "-1" || formValues.id_municipality === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_municipality_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_municipality_valid: false
            };
        }


        if (formValues.code.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                code_valid: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            };
        }


        if (formValues.email === "") {
            newValidaciones = {
                ...newValidaciones,
                email_valid: false
            };
        }
        else {
            if (!pattern.test(formValues.email)) {
                newValidaciones = {
                    ...newValidaciones,
                    email_valid: true
                };
                statusValidacion = true;
            }
            else {
                newValidaciones = {
                    ...newValidaciones,
                    email_valid: false
                };
            }
        }


        if (formValues.name.length <= 0) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }


        if (formValues.business_name.length <= 0) {
            newValidaciones = {
                ...newValidaciones,
                business_name_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                business_name_valid: false
            };
        }


        if (formValues.rfc.length <= 0) {
            newValidaciones = {
                ...newValidaciones,
                rfc_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                rfc_valid: false
            };
        }


        if (formValues.address.length <= 0) {
            newValidaciones = {
                ...newValidaciones,
                address_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                address_valid: false
            };
        }


        if (formValues.colony.length <= 0) {
            newValidaciones = {
                ...newValidaciones,
                colony_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                colony_valid: false
            };
        }


        if (parseInt(formValues.cp) <= 0) {
            newValidaciones = {
                ...newValidaciones,
                cp_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                cp_valid: false
            };
        }

        SetValidaciones(newValidaciones);

        return statusValidacion;

    }

    const navigateUpdate = (id_external_laboratory) => {
        history.push(`../catalogos/${id_external_laboratory}/asignar-examen`);

    }




    return {

        toggle, modal, modalTitle, handleCreate, saveCreate, externalLaboratory, method, loading,
        handleDelete, handleUpdate, handleInputChange, validaciones,
        id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
        name, business_name, rfc, phone, address, colony, cp, email, web_site,
        states, municipalities, handleChangeState, saveDelete, saveUpdate,
        navigateUpdate,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchExternal


    }
}
