import React, { useState, useEffect } from 'react'

export const usePaginationCash = (total_count, page_size) => {


    const [currentPage1, setCurrentPage1] = useState(1);
    const [totalPageCount1, setTotalPageCount1] = useState(0);

    useEffect(() => {
        let total_page_count = Math.ceil(total_count / page_size);

        setTotalPageCount1(total_page_count);


    }, [total_count, page_size])


    const nextPage1 = () => {
        let current_page = currentPage1;
        current_page++;
        setCurrentPage1(current_page);
    }

    const previousPage1 = () => {
        let current_page = currentPage1;
        current_page--;
        setCurrentPage1(current_page);
    }

    const goToPage1 = (page) => {
        setCurrentPage1(page);
    }

    return {
        totalPageCount1, currentPage1,
        nextPage1, previousPage1, goToPage1
    }
}
