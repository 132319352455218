import React, { useState } from 'react';
import { Collapse, Card, Col,CardBody,Row } from 'reactstrap';
import { labelHistory} from '../../constant';

export const CollapseRecordTransaction = ({ items }) => {


    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);


    return (
        <div>
            <Card className="shadow">
            <div onClick={toggle} className="p-4" style={{ cursor: "pointer" }}>
                    <div className="align-items-center row">
                        <div className="col-12">
                            <h5 className="mb-0">{labelHistory}</h5>
                        </div>
                        <div className="text-right col-12">
                         <i className={`icofont icofont-arrow-${isOpen ? "up" : "down"}`}></i>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={isOpen}>
                     <CardBody className="pt-0 pl-4 pr-4" >
                     <Col sm="12">
                          <Row>
                              <Col sm="1" className='p-l-0 '>
                              </Col>
                              <Col sm="4" className='p-l-0'> 
                              <center><h6>{"Pago Recibido por: "}</h6></center>
                              </Col>
                              <Col sm="4"> 
                              <center><h6>{"fecha y Hora "}</h6></center>
                              </Col>
                              <Col sm="3"  style={{"text-align": "-webkit-right"}}>
                             <h6>{"Monto"}</h6>
                              </Col>
                          </Row>
                      </Col>
                        {
                            items.length >= 1 && items.map((obj,key) => {
                                return(
                                    <div key={key} className="align-items-center row pb-2 pt-2 border-bottom ">
                                        <Col sm="1" style={{"text-align": "-webkit-center"}}>
                                        <i className="fa fa-2x fa-arrow-right text-success"></i>
                                        </Col>
                                        <Col sm="4" style={{"text-align": "-webkit-center"}}>
                                            <h7 className="mb-0">{ obj.name_user}</h7>     
                                        </Col>
                                        <Col sm="4" style={{"text-align": "-webkit-center"}}>                                            
                                            <h7 ><strong>{obj.date} </strong></h7> <br/> 
                                            <h7>{obj.time}</h7>
                                        </Col>
                                        <Col sm="3" style={{"text-align": "-webkit-center"}}>
                                            <h5  className="mb-0 text-success f-right ">$ {obj.amount.toFixed(2)}</h5>
                                        </Col>
                                        <Col sm="5">
                                        </Col>
                                    </div>
                                )
                            })
                        }
                    </CardBody> 
                </Collapse>
            </Card>
        </div>
    )
}
