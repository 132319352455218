import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteUnit } from './DeleteUnit';
import { FormUnit } from './FormUnit';

export const ModalUnit = (props) => {

    const { modal, toggle, loading, method, unit, abbreviation, handleInputChange, saveCreate, saveUpdate, saveDelete } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Creación de unidades"
                        : (method === "update") ?
                            "Actualización de unidad"
                            : "Eliminar unidad"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormUnit button="Crear nueva unidad" {...{ toggle, loading, unit, abbreviation, handleInputChange, methodAction: saveCreate }} />
                    : (method === "update") ?
                        <FormUnit button="Actualizar unidad" {...{ toggle, loading, unit, abbreviation, handleInputChange, methodAction: saveUpdate }} />
                        : <DeleteUnit {...{ toggle, loading, unit, saveDelete }} />
            }
        </Modal>
    )
}
