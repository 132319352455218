import React from 'react';
import { FormGroup, Label, Input, FormFeedback ,Col} from 'reactstrap';

export const NumberRealForm = (props) => {

    const {
        activeTab, isSimple, id_type_analyte, integers, decimals,
        handleInputChange, vDecimals, vIntegers
    } = props;
    return (
        <>
            <Col xs="12" sm={isSimple ? "3" : "6"} md={isSimple ? "3" : "6"} lg={isSimple ? "3" : "6"} xl={isSimple ? "3" : "6"}>
            <FormGroup className={`mb-3 ${(id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "7") ? "" : "d-none"}`}>
                <Label className="text-sm">No. de dígitos</Label>
                <Input
                    readOnly={activeTab === 2}
                    className="form-control form-control-sm input-air-primary" 
                    bsSize="sm"
                    placeholder="Número de enteros"
                    type="number"
                    name="integers"
                    value={integers}
                    required={true}
                    autoComplete="off"
                    onChange={handleInputChange}
                    invalid={vIntegers}
                />
                <FormFeedback>El valor debe ser mayor a 0 </FormFeedback>
            </FormGroup>
            </Col>
            <Col xs="12" sm={isSimple ? "3" : "6"} md={isSimple ? "3" : "6"} lg={isSimple ? "3" : "6"} xl={isSimple ? "3" : "6"}>
            <FormGroup className={`mb-3 ${(id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "7") ? "" : "d-none"}`}>
                <Label className="text-sm">No. de decimales</Label>
                <Input
                    readOnly={activeTab === 2}
                    className="form-control form-control-sm input-air-primary" 
                    bsSize="sm"
                    placeholder="Número de decimales"
                    type="number"
                    name="decimals"
                    value={decimals}
                    required={true}
                    autoComplete="off"
                    onChange={handleInputChange}
                    invalid={vDecimals}
                />
                <FormFeedback>El valor debe ser mayor a 0 </FormFeedback>
            </FormGroup>
            </Col>

        </>
    )
}
