import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useForm } from '../forms/useForm';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

export const useDoubleValidation = () => {
    const history = useHistory();
    const [createSweet] = useSweetAlert();

    const [nims, setNims] = useState([]);
    const [dataWorkOrder, setDataWorkOrder] = useState(null);

    const [itemsTree, setItemsTree] = useState([]);
    const [disabled, setDisabled] = useState(false)

    //Sections
    const [sections, setSections] = useState([]);
    const [sectiondefault, setSectiondefault] = useState(null);
    const [selectedSections, setselectedSections] = useState(null);

    const [dateInit, setDateInit] = useState(new moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(new moment().format('YYYY-MM-DD'));

    let id_branch = localStorage.getItem('branchId');
    const [dataCompany, setDataCompany] = useState([]);


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        id_company: 0
    });

    const { id_company } = formValues;

    const [filterRangeNims, setFilterRangeNims] = useState({
        date_range_nim: new moment().format('YYYY/MM/DD'),
        branch_range_nim: "",
        init_range_nim: "0",
        end_range_nim: "1"
    });


    useEffect(() => {
        getNims();
        getBranches();
        getSections();
    }, [])


    


    const treeviewRef = useRef(null);

    const onRenderItem = (item, treeview) => {
        return (
            <div className="treeview-item-example">
                <span className="treeview-item-example-text">{item.name}</span>
            </div>
        )
    }

    //#region Branch
    const [branches, setBranches] = useState([]);
    const [branchesSelected, setBranchesSelected] = useState([]);

    const getBranches = async () => {

        let listTemp = await handleRequest("GET", "Branch/List", "Sucursal");

        if (listTemp !== null && listTemp.length > 0) {

            let newBranches = [];
            let arreglo = [];

            listTemp.forEach((element) => {
                newBranches.push({
                    value: element.id_branch,
                    label: element.name,
                    check: false,
                    ...element
                });
                arreglo.push(element.id_branch);
            });
            setBranches(newBranches);
            setBranchesSelected(arreglo);
        }
    }

    //#endregion Branch


    const getNims = async (filters = false) => {

        let body = {};
        if (filters === false) {
            body = {
                "date_init": "",
                "date_end": "",
                "list_branches": [],
                "listSections": [],
                "nim": "string",
                "type": "all",
                "id_company": null

            }
        } else {
            body = {
                "date_init": dateInit,
                "date_end": dateEnd,
                "list_branches": branchesSelected,
                "filtro_secciones": selectedSections,
                "nim": "string",
                "type": "filter",
                "id_company": id_company !== null ? id_company.value : ""
            }
        }

        let listTemp = await handleRequest("POST", "ProcessExam/ListNims", "procesos", body);
        
        if (listTemp !== null && listTemp.length > 0) {
            setNims([...listTemp]);
        } else {
            setNims([]);
            createSweet("", "warning", "Estudios", "No hay estudios liberados");     
        }

        let tmpCompanies = [];
        const companyNamesSet = new Set();

                listTemp.forEach((item, index) => {
                    if (item.id_company !== null && item.id_company !== 0) {
                        const companyName = item.name_company;

                        if (!companyNamesSet.has(companyName)) {
                            companyNamesSet.add(companyName);

                            tmpCompanies.push({
                                value: item.id_company,
                                label: companyName
                            });
                        }
                    }
                });
                setDataCompany([...tmpCompanies]);    
    }

    const changeMultiselect = (e, data) => {
        setBranchesSelected([...e.value]);
    }


    const changeDate = (data, type) => {

        let fecha = new moment(data._d).format('YYYY-MM-DD');
        if (type === "init") {
            setDateInit(fecha)
        } else {
            setDateEnd(fecha)
        }

    }

    const changeCheckBox = (id_work_order) => {
        let newNims = nims;
        newNims = newNims.map(x => {
            if (x.id_work_order === id_work_order) {
                x.check = !x.check;
            }
            return x;
        })

        setNims([...newNims]);
        getProfileAndExams(id_work_order);
    }


    //#region Sections 
    const getSections = async () => {
        let _listTemp = await handleRequest("GET", "Section/List/false", "Secciones");

        if (_listTemp !== null && _listTemp.length > 0) {
            let _secciones_vinculadas = JSON.parse(localStorage.getItem("sections"));
            let _secciones_guardadas = localStorage.getItem("filter_sections");
            if (_secciones_guardadas != '[]') {
                _secciones_guardadas = JSON.parse(_secciones_guardadas);
            }
            let secciones = [];
            let sectionmultiselect = [];
            let sectiondefault = [];
            _listTemp.forEach(({ id_section, name }) => {
                _secciones_vinculadas.forEach(function (element, index, _secciones_vinculadas) {
                    if (element == id_section) {
                        let checkAux = false;
                        if (_secciones_guardadas != '[]') {
                            let validacion = _secciones_guardadas.find(x => x.id_section === id_section);
                            if (validacion !== undefined) {
                                checkAux = true;
                                sectiondefault.push(
                                    { name: name, id_section: id_section },
                                )
                            } else {
                                checkAux = false;
                            }
                        } else if (_secciones_guardadas == "[]") {
                            sectiondefault.push(
                                { name: name, id_section: id_section },
                            )
                            checkAux = true;
                        }
                        secciones.push({
                            id_section: id_section,
                            name: name,
                            check: checkAux,
                            label: name,
                            value: id_section
                        })
                        sectionmultiselect.push(
                            { name: name, id_section: id_section },
                        )
                    }
                });
            });
            setSections([...secciones]);
            setSectiondefault(sectionmultiselect);
            setselectedSections(sectiondefault);
            if (_secciones_guardadas == "[]") {
                localStorage.setItem("filter_sections", JSON.stringify(secciones));
            }
            return secciones;
        }
    }

    const changeCheckboxSection = (e) => {
        let _array = sections;
        let sectionfind = [];
        _array.map(_obj => {
            if (e.some(x => x.id_section === _obj.id_section)) {
                sectionfind.push(
                    { name: _obj.name, id_section: _obj.id_section },
                )
            }
            return _obj;
        });
        _array.map(_obj => {
            if (e.some(x => x.id_section === _obj.id_section)) {
                _obj.check = true;
            } else {
                _obj.check = false;
            }
            return _obj;
        });
        let filter_sections = _array.filter(x => x.check === true);
        localStorage.setItem("filter_sections", JSON.stringify(filter_sections));
        localStorage.setItem("sectionfind", JSON.stringify(filter_sections));
        setselectedSections(sectionfind);
        setSections(_array);
    }

    const getProfileAndExams = async (id_work_order) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "ProcessExam/ListProfileAndExams/" + id_work_order);
        if (respuesta.code === 200) {
            ////console.log(respuesta.data);
            setDisabled(true);
            let tree = createTree(respuesta.data);
            setItemsTree(tree);
            respuesta.data.id_work_order = id_work_order;
            setDataWorkOrder(respuesta.data)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getProfileAndExams);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Consulta", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Consulta", respuesta.data.msg);
        }
    }

    const cancelarDesvalidar = () => {
        setDisabled(false);
        let id_work_order = dataWorkOrder.id_work_order;
        let newNims = nims;
        newNims = newNims.map(x => {
            if (x.id_work_order === id_work_order) {
                x.check = !x.check;
            }
            return x;
        })

        setNims([...newNims]);
        setDataWorkOrder(null);
    }

    const desvalidar = async () => {
        const api = treeviewRef.current.api
        const items = api.getItems();
        let eliminar = [];
        let seCambio = false;
        items.forEach(element => {
            //EXAMENES
            if (element.id_work_order_exam !== undefined) {
                if (element.state === 1) {
                    eliminar.push({
                        "type": "exam",
                        "id_work_order_exam": element.id_work_order_exam,
                        "exam": element.name
                    });
                    seCambio = true;
                }
            }
            //PERFILES
            else {
                element.children.forEach(ex => {
                    if (ex.state === 1) {
                        eliminar.push({
                            "type": "profile",
                            "id_work_order_profile": element.id_work_order_profile,
                            "id_work_order_exam": ex.id_work_order_exam,
                            "exam": ex.name
                        })
                        seCambio = true;
                    }
                })
            }
        });

        if (seCambio) {
            let id_work_order = dataWorkOrder.id_work_order;

            let requestOptions = {
                method: 'POST',
                body: JSON.stringify(eliminar)
            };

            const respuesta = await sendRequest(requestOptions, "ProcessExam/RemoveValidation/" + id_work_order);
            if (respuesta.code === 200) {
                createSweet("success", "success", "DESVALIDACIÓN", "Se desvalidaron las pruebas correctamente");
            }

            // let newNims = nims;
            // newNims = newNims.filter(x => x.id_work_order !== id_work_order);



            // setNims([...newNims]);
            getNims();
            setDisabled(false);
            setDataWorkOrder(null);
        } else {
            createSweet("", "warning", "Advertencia", "No has marcado ningun examen");
        }
    }

    const authorize = async () => {
        setLoadingRelease(true);
        let id_work_orders = [];
        nims.forEach(element => {
            id_work_orders.push(element.id_work_order);
        });

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(id_work_orders)
        };
        // ////console.log(id_work_orders, "nims");
        // return;
        const respuesta = await sendRequest(requestOptions, "ProcessExam/Authorized");

        if (respuesta.code === 200) {
            createSweet("success", "success", "AUTORIZACIÓN", "Se autorizaron las pruebas correctamente");
            setNims([]);
            toggleRelease();
        } else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, authorize);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Doble validación", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Doble validación", respuesta.data.msg);
        }
    }

    const changeTree = (e) => {
        ////console.log(e);
    }

    const createTree = (data) => {
        let tree = [];

        if (data.exams.length > 0) {
            data.exams.forEach(x => {
                tree.push({
                    "children": [],
                    "state": 2,
                    "id": x.id_work_order_exam,
                    ...x
                })
            })
        }

        if (data.profiles.length > 0) {
            data.profiles.forEach(x => {
                let examenes_aux = [];
                x.exams.forEach(exam => {
                    examenes_aux.push({
                        "state": 2,
                        "id": exam.id_work_order_exam,
                        ...exam
                    })
                })
                tree.push({
                    "children": examenes_aux,
                    "state": 2,
                    "id": x.id_work_order_profile,
                    ...x
                })
            })
        }

        return tree;
    }

    const [modalRelease, setModalRelease] = useState(false);
    const [loadingRelease, setLoadingRelease] = useState(false);

    const toggleRelease = () => {
        setLoadingRelease(false);
        setModalRelease(!modalRelease);
    }

    //#region Range Nim

    const changeFiltersRangeNim = (value, type) => {
        let data = filterRangeNims;

        switch (type) {
            case "date_range_nim":
                data.date_range_nim = new moment(value).format('YYYY/MM/DD');
                break;
            case "branch_range_nim":
                data.branch_range_nim = value.value;
                break;
            case "init_range_nim":
                data.init_range_nim = value;
                break;
            case "end_range_nim":
                data.end_range_nim = value;
                break;
            default:
                break;
        }

        setFilterRangeNims(data);
    }


    const getNimsFromRange = async () => {

        if (filterRangeNims.branch_range_nim === "" || filterRangeNims.init_range_nim === "" || filterRangeNims.end_range_nim === "") {
            createSweet("warning", "warning", "Alerta", "Debes llenar todos los campos para poder filtrar por rango");
            return;
        }

        let listTemp = await handleRequest("POST", "ProcessExam/ListNimsFromRangeNim", "procesos", filterRangeNims);

        if (listTemp !== null && listTemp.length > 0) {
            setNims([...listTemp]);
        }
    }
    //#endregion Range Nim

    //#region Preview

    const [typeModal, setTypeModal] = useState("viewP");
    const [modal, setModal] = useState(false);
    const [preview, setPreview] = useState("")

    const toggle = () => {
        setModal(!modal);
    }

    // const getViewPreview = (nim) => {
    //     setTypeModal("viewP");
    //     toggle()
    //     previewWorkOrder(nim)
    // }

    const previewWorkOrder = async (nim) => {

        let viewP = {
            "nim": nim,
            "password": "",
            "type": "NP",
            "is_double_validation":true
        }

        let listTmp = await handleRequest("POST", "PrintResult/Preview", "Preview", viewP);

        if (listTmp !== null) {
        }
        setPreview(listTmp.cadena);
        setTypeModal("viewP");
        toggle();

    }

    //#endregion Preview



    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }


    return {
        nims, dataWorkOrder, changeCheckBox, treeviewRef, onRenderItem, itemsTree, cancelarDesvalidar, desvalidar, disabled, changeTree,
        getNims, branches, changeMultiselect, branchesSelected, dateInit, dateEnd, changeDate, changeCheckboxSection,
        modalRelease, toggleRelease, loadingRelease, authorize, sectiondefault, selectedSections, handleSelectValues, id_company, dataCompany, handleUpdateValues,
        //range nim
        changeFiltersRangeNim, getNimsFromRange,
        //preview
        previewWorkOrder, preview, typeModal, modal, toggle,
        //getViewPreview
    }
}
