import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../hooks/forms/useForm';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router';

export const useExamMethodMicrobiology = () => {

    // RUTAS
    const history = useHistory();
    const { examId } = useParams();

    // ESTADOS GENERALES
    const [nameExam, setNameExam] = useState("");
    const [examMethods, setExamMethods] = useState([]);
    const [examMethodId, setExamMethodId] = useState(0);
    const [method, setMethod] = useState("");
    const [methodDefault, setMethodDefault] = useState(0);
    const [machineModels, setMachineModels] = useState([]);
    const [selectMachineModels, setSelectMachineModels] = useState([]);
    const [methods, setMethods] = useState([]);
    const [selectMethod, setSelectMethod] = useState([]);

    const [createSweet] = useSweetAlert();

    // CONSTANTES DE FORMULARIOS
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_exam: examId,
        name: "",
        code: "",
        id_machine_models: [],
        id_method: {}
    });
    const { name, code, id_machine_models, id_method } = formValues;

    const [validaciones, setValidaciones] = useState({
        name: false,
        code: false,
    });

    // RELACIONADO AL MODAL
    const [modal, setModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    }

    // METODOS ASYNC
    const getExam = useCallback(async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetExam/" + examId);

        if (respuesta.code === 200) {
            setNameExam(`${respuesta.data.name}[${respuesta.data.code}]`);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExam);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getExam);
        // }
    }, [history]);

    const getExamsMethods = useCallback(async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods/MethodsFromExam/" + examId);

        if (respuesta.code === 200) {
            let newExamsMethos = [];
            respuesta.data.forEach((obj) => {
                if (obj.firstOption) {
                    setMethodDefault(obj.id_exam_method);
                }

                let machine_models_arrays = [];
                let name_machine_models;

                if (obj.id_machine_models.length > 0) {
                    name_machine_models = [];
                    obj.id_machine_models.forEach((machineModel) => {
                        name_machine_models.push([
                            true,
                            machineModel.name,
                            machineModel.branchs
                        ])
                        machine_models_arrays.push(machineModel.value);
                    });
                } else {
                    name_machine_models = "Sin máquinas asignadas";
                }

                newExamsMethos.push({
                    id_exam_method: obj.id_exam_method,
                    name: obj.name,
                    code: obj.code,
                    firstOption: obj.firstOption,
                    machine_models_arrays: machine_models_arrays,
                    name_machine_models: name_machine_models,
                });
            })
            setExamMethods(newExamsMethos);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getExamsMethods);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
    }, [history]);

    const getMachineModels = useCallback(async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel/List/");

        if (respuesta.code === 200) {
            let new_array = [];
            respuesta.data.forEach(element => {
                let obj = {
                    "value": element.id_machine_model,
                    "label": `${element.brand} → ${element.model}`,
                    "name": `${element.brand} → ${element.model}`,
                }
                new_array.push(obj);
            });
            setMachineModels(respuesta.data);
            setSelectMachineModels(new_array);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getMachineModels);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getMachineModels);
        // }
    }, [history]);

    const getMethods = async () => {
        let requestOptions = {
            method: "GET"
        }

        const respuesta = await sendRequest(requestOptions, "Method/List");

        if (respuesta.code === 200) {
            let new_array = [];
            respuesta.data.forEach(element => {
                let obj = {
                    "value": element.id_method,
                    "label": `${element.name} [${element.code}]`,
                    "name": `${element.name} [${element.code}]`,
                }
                new_array.push(obj);
            });
            setMethods(new_array);
            // setMachineModels(respuesta.data);
            // setSelectMachineModels(new_array);
        } 
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getMethods);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getMethods);
        // }
    }

    // METODOS DE USEEFECT

    useEffect(() => {
        getMethods();
    }, [])


    useEffect(() => {
        getExamsMethods()
    }, [getExamsMethods])

    useEffect(() => {
        getExam()
    }, [getExam])

    useEffect(() => {
        getMachineModels()
    }, [getMachineModels])


    // METODOS DE CREACIÓN
    const handleCreate = () => {
        handleUpdateValues({
            id_exam: examId,
            name: "",
            code: ""
        });
        setValidaciones({
            name: false,
            code: false,
        })
        setExamMethodId(0);
        setMethod("create");
        toggleModal();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        // ////console.log(formValues);
        // return;
        if (validarForm()) return;
        setLoadingModal(true);

        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "ExamMethods");

        if (respuesta.code === 200) {
            getExamsMethods();
            reset();
            setLoadingModal(false);
            createSweet("create", "success");
            toggleModal();
        } 
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
    }

    // METODOS DE EDICIÓN

    const handleUpdate = (id_exam_method) => {
        let examMethod = examMethods.find(x => x.id_exam_method === id_exam_method);
        let machine_models_arrays_aux = [];
        examMethod.machine_models_arrays.forEach(element => {
            machine_models_arrays_aux.push(selectMachineModels.find(x => x.value === element));
        });
        handleUpdateValues({
            id_exam: examId,
            name: examMethod.name,
            code: examMethod.code,
            id_machine_models: machine_models_arrays_aux
        });
        setValidaciones({
            name: false,
            code: false,
        });
        setExamMethodId(id_exam_method);
        setMethod("update");
        toggleModal();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        if (validarForm()) return;
        let body = {
            id_exam_method: examMethodId,
            ...{ ...formValues }
        }
        setLoadingModal(true);

        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "ExamMethods");
        if (respuesta.code === 200) {
            getExamsMethods();
            setLoadingModal(false);
            createSweet("update", "success");
            toggleModal();
        } 
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    // METODOS DE ELIMINACIÓN
    const handleDelete = (id_exam_method) => {
        let examMethod = examMethods.find(x => x.id_exam_method === id_exam_method);
        handleUpdateValues({
            id_exam: examId,
            name: examMethod.name,
            code: examMethod.code
        });
        setExamMethodId(id_exam_method);
        setMethod("delete");
        toggleModal();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };
        setLoadingModal(true);
        const respuesta = await sendRequest(requestOptions, `ExamMethods/${examMethodId}`);
        if (respuesta.code === 200) {
            let examMethod = examMethods.filter(x => x.id_exam_method !== examMethodId);
            setExamMethods(examMethod);
            setLoadingModal(false);
            createSweet("delete", "success");
            toggleModal();
        } 
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
    }

    const changeMethodDefault = async (id_exam_method) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, `ExamMethods/ChangeDefaultMethod/${id_exam_method}`);
        if (respuesta.code === 200) {
            setMethodDefault(id_exam_method);
            createSweet("success", "success", "Método default actualizado");
        } 
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, changeMethodDefault);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Metodos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Metodos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, changeMethodDefault);
        // }
    }

    // VALIDACIONES DE FORMULARIOS
    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        // if (formValues.name.length < 2) {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         name: true
        //     }
        //     statusValidacion = true;
        // } else {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         name: false
        //     }
        // }

        // if (formValues.code.length < 1) {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         code: true
        //     }
        //     statusValidacion = true;
        // } else {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         code: false
        //     }
        // }

        setValidaciones(newValidaciones)

        return statusValidacion;
    }


    // VARIABLES A REGRESAR
    return {
        nameExam, examMethods, method,
        name, code, id_machine_models, id_method, handleInputChange, validaciones, handleSelectValues,
        toggleModal, modal, loadingModal,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        changeMethodDefault, methodDefault,
        machineModels, selectMachineModels,
        methods
    }
}
