import React from 'react'
import { Button,Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import './StyleProfile.css';


export const ViewListprofileCommercial = (props) => {

    const {
           toggle3, listCommercial
        } = props;
      

        ////console.log(listCommercial);
  return ( 
            <>
            <div className='cardPrice' >
            <h5  class="disableLabel" for="u">Precios por Sucursales  </h5> 
            <ul className="cardPrice-content">
                {
                    listCommercial.length === 0 ?
                    <div>
                        <label>No se cuenta con ningún precio asignado</label>
                    </div>
                    :
                    <div > 
                         {
                            listCommercial.length >= 1 && listCommercial.map( (obj, index) =>{
                            return (
                                <li key={index}>
                                {obj.commercial_name} <br /><label> $ {(obj.price).toFixed(2)}  </label> 
                                </li>       
                                )
                            })
                        }

                    </div>
                }       
             </ul>
            </div>
            <ModalFooter>
            <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle3}>{"Cerrar"}</Button>
            </ModalFooter>
            
            </>
   
  )
}
