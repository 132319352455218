import React from 'react'
import { useState, useEffect } from 'react'
import { LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { Label } from 'reactstrap';

export const useReturnReason = () => {


    const [listReturnReason, setListReturnReason] = useState([]);
    const [listReturnReasonId, setListReturnReasonId] = useState(0)

    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({

        id_return_reason: 0,
        reason: "",
        is_sample: false,
        is_work_order: false,
        is_tracking: false,
        is_send: false,
        mSelectReason: 0,

    });

    

    const { id_return_reason, reason, is_sample, is_work_order, is_tracking, is_send, mSelectReason } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listReturnReason.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listtReturnReason = [];
        listReturnReason.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listtReturnReason.push({
                ...data,
                visibleTable: visibleTable
            });
        });
        //console.log('Que pasa aquí? ', listtReturnReason);

        setListReturnReason(listtReturnReason);

    }, [totalPageCount, currentPage])

    const [validaciones, SetValidaciones] = useState({
        id_return_reason_valid: false,
        reason_valid: false,
    });

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {

        getReturnReason();

    }, [])

    //#region RETURN REASON

    const [Reason, setReason] = useState([
        {
            value: 1,
            label: "Cancelación de Orden de Trabajo"
        },
        {
            value: 2,
            label: "Cancelación de Toma de Muestra"
        },
        {
            value: 4,
            label: "Cancelacion de Envios de Muestra"
        },
        {
            value: 3,
            label: "Cancelación de Analitica de Muestra"
        },
    ]);
    //console.log('pruebas return reason: ', formValues);
    const getFilterReason = (e, search = true) => {
        e.preventDefault();

        if (search) {

            let query = "";

            if (mSelectReason !== "") {

                if (mSelectReason.value === 1) {
                    query += `is_sample=false&is_work_order=true&is_tracking=false&is_send=false`;
                }
                if (mSelectReason.value === 2) {
                    query += `is_sample=true&is_work_order=false&is_tracking=false&is_send=false`;
                }
                if (mSelectReason.value === 3) {
                    query += `is_sample=false&is_work_order=false&is_tracking=true&is_send=false`;
                }
                if (mSelectReason.value === 4) {
                    query += `is_sample=false&is_work_order=false&is_tracking=false&is_send=true`;
                }
            }

            getReturnReason(true, query)
        }
    }

    const getReturnReason = async (search = false, filter) => {
        setLoading(true)

        var _listTemp = {};

        if (search === false) {

            _listTemp = await handleRequest("GET", `ReturnReason/List?is_sample=false&is_work_order=false&is_tracking=false&is_send=false`, "Return");
        }
        else {
            _listTemp = await handleRequest("GET", `ReturnReason/List?${filter}`, "Return");
        }

        let _ListReason = [];

        if (_listTemp !== null && _listTemp.length > 0) {
            setLoading(false)


            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;
            endItems = aux;
            startItems = endItems - numberItems;

            //console.log('Antes del foreach: ', _listTemp);

            _listTemp.forEach((_obj, _index) => {
                let posicion = _index + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                _ListReason.push({
                    id_return_reason: _obj.id_return_reason,
                    reason: _obj.reason,
                    is_work_order: _obj.is_work_order,
                    is_sample: _obj.is_sample,
                    is_send: _obj.is_send,
                    is_tracking: _obj.is_tracking,
                    visibleTable: visibleTable
                });
            });

            //console.log('Después del foreach: ', _ListReason);
            setListReturnReason(_ListReason);
            setDataReason(_ListReason);
        }
        else {
            setListReturnReason([]);

        }
    }
    //#endregion RETURN REASON

    //#region create reason
    const handleCreate = () => {

        handleUpdateValues({
            id_return_reason: 0,
            reason: "",
            is_sample: false,
            is_work_order: false,
            is_tracking: false,
            is_send: false
        });

        SetValidaciones({
            reason_valid: false,
        })

        setListReturnReasonId(0);
        setMethod("create");
        setModalTitle("Modificar el motivo de la devolución");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let _mData = formValues;

        const _response = await handleRequest("POST", "ReturnReason", "Motivos de cancelación", _mData);
        if (_response !== null) {
            getReturnReason();
            reset();
            createSweet("create", "success", "Exito!", "Motivo de devolución creado");
            setLoading(false);
            toggle();
        }
    }
    //#endregion create reason

    //#region update reason
    const handleUpdate = (id_return_reason) => {

        let reason = listReturnReason.find(b => b.id_return_reason === parseInt(id_return_reason));

        //console.log('Aquí entras en el update: ',reason);

        handleUpdateValues({
            id_return_reason: reason.id_return_reason,
            reason: reason.reason,
            is_sample: reason.is_sample,
            is_work_order: reason.is_work_order,
            is_tracking: reason.is_tracking,
            is_send: reason.is_send
        });

        setListReturnReasonId(id_return_reason);
        setMethod("update");
        setModalTitle("Modificar el motivo de la devolución");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }


        let body = {
            id_return_reason: listReturnReasonId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "ReturnReason/Update");

        if (respuesta.code === 200) {
            getReturnReason();
            createSweet("create", "info", "Exito!", "Razón actualizada");
            setLoading(false);
            toggle();
        }
    }

    //#endregion update reason

    //#region delete reason

    const handleDelete = (id_return_reason) => {

        let reason = listReturnReason.find(b => b.id_return_reason === parseInt(id_return_reason));

        handleUpdateValues({
            reason: reason.reason
        });
        setListReturnReasonId(id_return_reason);
        setMethod("delete");
        setModalTitle("Eliminar Razón");
        toggle();
    }



    const saveDelete = async (e) => {
        //e.preventDefault();
        setLoading(true);

        let _listTmp = await handleRequest("DELETE", `ReturnReason/${listReturnReasonId}`, 'delete');

        if (_listTmp !== null) {
            getReturnReason();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Razón Eliminada");
            toggle();
        }
    }
    //#endregion delete reason

    const validarForm = () => {

        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.reason === "") {
            newValidaciones = {
                ...newValidaciones,
                reason_valid: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                reason_valid: false
            };
        }

        SetValidaciones(newValidaciones);

        return statusValidacion;
    }

    const [dataReason, setDataReason] = useState([])


    const handleSearchReason = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataReason.forEach(element => {

            let cadena = element.is_sample2.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setListReturnReason(newMethod);
    }


    //#region request
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }



    return {

        listReturnReason, listReturnReasonId,
        method, modalTitle, loading, modal, toggle,
        handleCreate, saveCreate, handleUpdate, saveUpdate, handleDelete, saveDelete,
        id_return_reason, reason, handleInputChange, validaciones,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearchReason, is_sample,
        is_work_order, is_tracking, is_send, getFilterReason, Reason, handleSelectValues, mSelectReason


    }
}
