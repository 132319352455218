import React from 'react'
import { Button, Card, CardBody, Col,Input } from 'reactstrap';
import { useMachine } from '../../hooks/machine/useMachine'
import { TabBranchMachine } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { useBranchMachine } from '../../hooks/branchMachine/useBranchMachine';
import { ModalDoctor } from '../../components/doctors/ModalDoctor';
import { ModalBranchMachine } from '../../components/branchMachine/ModalBranchMachine';

export const IndexBranchMachine = ({activeTab}) => {

    const {
        branchMachine, machines, branches, sections,
        method, handleInputChange, validaciones,
        id_machine_model, id_branch, id_section, serial_number,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchMachine

    } = useBranchMachine({activeTab});

    const txtsection = localStorage.getItem('TextAS');

    return (
        <>
            <Card>
                <CardBody className="p-1"> <br />
                    
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button>
                    </div> 
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchMachine} />
                        </div>
                    </div>
                    <br />
                    <div className="table-responsive">
                        <TableStriped
                            notMaped={["visibleTable","id_machine_model","id_branch","id_section"]}
                            methodsActions={true}
                            cabeceras={["Num. Serie" ,"Equipo", "Sucursal", txtsection]}
                            items={branchMachine}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalBranchMachine
                {
                    ...{
                        method, handleInputChange, validaciones, loading,
                        id_machine_model, id_branch, id_section, serial_number,
                        machines, branches, sections,
                        toggle, modal, modalTitle, 
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
