export default function FormatNumbers (cantidad, decimals) 
{
    cantidad += '';
    cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

    decimals = decimals || 0;

    if (isNaN(cantidad) || cantidad === 0) {
        return parseFloat(0).toFixed(decimals);
    }

    cantidad = '' + cantidad.toFixed(decimals);

    let cantidad_parts = cantidad.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(cantidad_parts[0]))
        cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

    return cantidad_parts.join('.');
}