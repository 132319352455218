import React from 'react';
import { Modal,ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeleteSite } from './DeleteSite';
import { FormSite } from './FormSite';

export const ModalSite = (props) => {
    const{
        method, modalTitle, loading, modal, toggle,
        handleInputChange, saveCreate, saveUpdate, saveDelete,
        validaciones, name,
    }=props;


    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ?<FormSite
                    button = {ModalButtonSave}
                    {...{toggle, loading, handleInputChange, validaciones, methodAction: saveCreate,}}
                />
                :
                (method === "update")
                ?
                <FormSite
                    button={ModalButtonUpdate}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name }}
                />
                :
                <DeleteSite 
                    {...{toggle, loading, saveDelete ,name}}
                />
            }
        </Modal>
    )
}