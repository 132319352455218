import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row ,Input} from 'reactstrap';
import { RibbonPayMethod } from '../../../constant';
import { usePayMethods } from '../../../hooks/payMethods/usePayMethods';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalPayMethods } from '../../../components/payMethods/ModalPayMethods';

export const IndexPayMethods = () => {

    const {
        payMethods, method,
        name, abbreviation, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchPay

    } = usePayMethods();

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title={"Métodos de pago"} /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {RibbonPayMethod}
                                </div> <br />
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchPay} />
                                    </div>
                                </div>
                                
                                <br />
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Nombre", "Abreviación"]}
                                        items={payMethods}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalPayMethods
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    name, abbreviation, handleInputChange, validaciones,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </Fragment>
    )
}
