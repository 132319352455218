import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row, Input } from 'reactstrap';
import { useCommercialLines } from '../../../hooks/commercialLines/useCommercialLines';
import { RibbonCommercialLine } from '../../../constant';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalCommercialLines } from '../../../components/commercialLines/ModalCommercialLines';
import { ListCommercialLine } from './ListCommercialLine';

export const IndexCommercialLines = () => {

    const {
        commercialLines, method,
        long_name, short_name, rfc, theme_color, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleSearchCommercial,
        navigateSettingsInvoice
    } = useCommercialLines();

    let filter = ["nameCommercial"];


    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Razones sociales" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <CardBody className="p-1">
                            <div className="ribbon ribbon-clip ribbon-primary">
                                {RibbonCommercialLine}
                            </div> <br />
                            <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                {/* <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button> */}
                            </div>
                            <Col sm="12">
                                <ListCommercialLine
                                    filterProps={filter}
                                    type="list"
                                    inputFiter={true}
                                    items={commercialLines}
                                    {...{ handleUpdate, handleDelete}}
                                />
                            </Col>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
            <ModalCommercialLines
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    long_name, short_name, rfc, theme_color, handleInputChange, validaciones,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </Fragment>
    )
}
