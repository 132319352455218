import React, { Fragment, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { DollarSign } from 'react-feather';
import PropTypes from 'prop-types'
import './styleCash.css';


export const TimeLineGlobal = ({ items, formatter }) => {

    return (
        <Fragment>
            <VerticalTimeline>
                {
                    items.listHistoricGlobal !== undefined && items.listHistoricGlobal.map((o, i) => {
                        return <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date={o.fech}
                            iconStyle={{ background: 'rgb(229 74 81)', color: '#fff' }}
                            icon={<DollarSign />}
                        >
                            <label className=' f-14'>Cantidad Depositada:</label> <b>{formatter.format(o.amount_deposit)}</b> <br />
                            <label className=' f-14'>Cantidad Real:</label> <b>{formatter.format(o.total_amount)}</b>
                        </VerticalTimelineElement>
                    })
                }
            </VerticalTimeline>
        </Fragment>
    )
}

TimeLineGlobal.propTypes = {
    items: PropTypes.array.isRequired,
}