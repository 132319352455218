import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteLaboratories } from './DeleteLaboratories';
import { FormLaboratories } from './FormLaboratories';

export const ModalLaboratories = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        handleInputChange,validaciones,
        id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
        name, business_name, rfc, phone, address, colony, cp, email, web_site,
        saveCreate, saveUpdate, saveDelete,
        states,municipalities, handleChangeState,
    } = props;

    
    return (
        // <Modal isOpen={modal} toggle={toggle} className={(method !== "delete") ? "modal-lg'" : ""} backdrop='static' keyboard={false} centered={true}>
        //     <ModalHeader toggle={toggle}  className="bg-primary">
        //         {modalTitle}
        //     </ModalHeader>
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className={(method !== 'delete') ? 'modal-lg' : ''}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormLaboratories
                        button="Guardar"
                        {...{ toggle, loading, handleInputChange,validaciones,  methodAction: saveCreate, states,municipalities, handleChangeState,}}
                    />
                    : (method === "update")

                        ?
                        <FormLaboratories
                            button="Actualizar"
                            {...{
                                toggle, loading, handleInputChange,validaciones,
                                id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
                                name, business_name, rfc, phone, address, colony, cp, email, web_site, methodAction: saveUpdate,
                                states, municipalities, handleChangeState,
                            }}
                        />
                        :
                        <DeleteLaboratories
                            {...{ toggle, loading, saveDelete, name, code }}
                        />
                        
                        
            }
        </Modal>
    )
}
