import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormSubsections = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, sections, id_section, name, abbreviation, button
    } = props;

    const {
        id_section_valid, name_valid, abbreviation_valid
    } = validaciones;
    const txtsection = localStorage.getItem('TextAS');
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row style={{"place-content": "center"}}>
                    <Col md="8">
                        <FormGroup>
                            <Label className="col-form-label">{txtsection} <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input 
                            type="select" 
                            name="id_section" 
                            value={ id_section } 
                            onChange={handleInputChange} 
                            required={true} 
                            invalid={id_section_valid}
                            className="form-control form-control-sm input-air-primary" 
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    sections.length > 0 && sections.map( (ss, key) => {
                                        return <option key={key} value={ ss.id_section }>
                                            { ss.name }
                                        </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{"place-content": "center"}}>
                    <Col md="8">
                        <FormGroup>
                            <Label className="col-form-label">Subsección <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                onChange={handleInputChange}
                                value={name}
                                autoComplete="off"
                                invalid={name_valid}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{"place-content": "center"}}>
                    <Col md="8">
                        <FormGroup>
                            <Label className="col-form-label">{txtsection} <span className='f-16' style={ { color: "#0079C7" } }>*</span></Label>
                            <Input
                                type="text"
                                name="abbreviation"
                                onChange={handleInputChange}
                                value={abbreviation}
                                autoComplete="off"
                                invalid={abbreviation_valid}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}
