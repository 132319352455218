import React from "react";
import { Form, Col, Row, Input } from "reactstrap";
import "../../../assets/css/pantalla1.css";

export const FormIDcard = (props) => {
  const { getDataNim, handleInputChangeNim, loading, nim } = props;

  return (
    <Form onSubmit={getDataNim}>
      <Col sm="12" style={{ border: "#46AFE5 3px solid" }}>
        <Row>
          <Col sm="1"></Col>
          <Col sm="10">
            <br /> <br />
            <div>
              {/* text-center */}
              <Input
                className="tamañoinputID text-center form-control form-control-sm input-air-primary"
                placeholder="Ingresa el número de la muestra"
                name="nim"
                value={nim}
                onChange={handleInputChangeNim}
              ></Input>
            </div>
            <br /> <br />
            <div style={{ textAlign: "center" }}>
              <button
                className={loading ? "modalButton" : "modalButton"}
                type="submit"
              >
                Ingresar
              </button>
            </div>
            <br />
          </Col>
          <Col sm="1"></Col>
        </Row>
      </Col>
    </Form>

    // <div>
    //    <input type="text" className='tamañoinputID' />
    // </div>
  );
};
