import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useSeller = () => {

    const history = useHistory();

      const [sellerD, setSellerD] = useState([]);
      const [sellerID, setSellerID] = useState([]);

      const [method, setMethod] = useState("");
      const [modal, setModal] = useState(false);
      const [modalTitle, setModalTitle] = useState("");

      const [loading, setLoading] = useState(false);
      const [createSweet] = useSweetAlert();

      useEffect(() => {

        getSeller();


      }, [history.location]);
      

        const toggle = () => {
          setModal(!modal);
        }

        const [formValues, handleInputChange, reset, handleUpdateValues, handlePickerValues] = useForm({
          name: "",
          paternal_surname:"",
          maternal_surname: "",
          number_phone: "",
          email: ""
        });


        const {
          name,paternal_surname,maternal_surname,number_phone,email
        } = formValues;


        const [validaciones, SetValidaciones] = useState({
          name_valid: false,
          paternal_surname_valid: false,
          maternal_surname_valid: false,
          number_phone_valid: false,
           
        });


        const handleCreate = () => {

          handleUpdateValues({
            name: "",
            paternal_surname: "",
            maternal_surname:"",
            number_phone: "",
            email: ""
            
          });

          SetValidaciones({
            name_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: false,
        })

          setSellerID(0);
          setMethod("create");
          setModalTitle("Registrar un Vendedor");
          toggle();
        }

      
         const getSeller = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Seller/List");

        if (respuesta.code === 200) {

                if (respuesta.data.length >= 1) {
          
                    let ListSeller = [];
                    let startItems = 0;
                    let endItems = numberItems;
                    let aux = numberItems * currentPage;

                    endItems = aux;
                    startItems = endItems - numberItems;


                    respuesta.data.forEach((value,index) => {

                        let posicion = index + 1;
                        let visibleTable = false;
            
                        if (posicion > startItems && posicion <= endItems) {
                            visibleTable = true;
                        }

                        ListSeller.push({
                          id_seller: value.id_seller,
                          name: value.name,
                          paternal_surname: value.paternal_surname,
                          maternal_surname: value.maternal_surname,
                          number_phone: value.number_phone,
                          email: value.email,
                          visibleTable: visibleTable
                        });
                    });
                    setSellerD(ListSeller);
                    setDataSeller(ListSeller)
                  }
                  else {
                      createSweet("error", "warning", "Hubo un error!", "No se encontraron vendedores");
                      setSellerD([]);
                  }
              }
              else if (respuesta.code === 401) {
                  validarSesion(history, respuesta.code, getSeller);
              }
              else if (respuesta.code === 500) {
                  createSweet("error", "error", "Vendedores", "Ocurrio un error en el servidor");
              }
              else {
                  createSweet("error", "warning", "Vendedores", respuesta.data.msg);
              }
          }


        const numberItems = 10;

        const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(sellerD.length, numberItems);

          useEffect(() => {

              let startItems = 0;
              let endItems = numberItems;

              let aux = numberItems * currentPage;

              endItems = aux;

              startItems = endItems - numberItems;

              let listSellerr = [];
              sellerD.forEach((data, key) => {

                  let posicion = key + 1;
                  let visibleTable = false;

                  if (posicion > startItems && posicion <= endItems) {
                      visibleTable = true;
                  }

                  listSellerr.push({
                      ...data,
                      visibleTable: visibleTable
                  });
              });

            setSellerD(listSellerr);

          }, [totalPageCount, currentPage]);



          const [dataSeller, setDataSeller] = useState([]);


          const handleSearchSeller = (e) => {

            let busqueda = e.target.value;
            let newMethod = [];
    
            dataSeller.forEach(element => {
                let cadena = element.name.toUpperCase();
                if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                    element.visibleTable = true;
                    newMethod.push(element);
                }
            });
            setSellerD(newMethod);
            }

          const saveCreate = async (e) => {
                e.preventDefault();
                setLoading(true);

                if (validarForm()) {
                  setLoading(false);
                  return;
              }
      
        
              
                let mData = formValues;
        
                let requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(mData)
                };
        
                const respuesta = await sendRequest(requestOptions, "Seller/Create");
                if (respuesta.code === 200) {
        
                    getSeller();
                    reset();
                    createSweet("create", "success", "Exito!", "Vendedor ");
                    setLoading(false);
                    toggle();
                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, saveCreate);
                }
                else if (respuesta.code === 500) {
                    createSweet("error", "error", "Vendedor", "Ocurrio un error en el servidor");
                }
                else {
                    createSweet("error", "warning", "Vendedor", respuesta.data.msg);
        
                }
            }

            const handleUpdate = (id_seller) => {

                let sellerR = sellerD.find(b => b.id_seller === parseInt(id_seller));
        
                handleUpdateValues({
                  name: sellerR.name,
                  paternal_surname: sellerR.paternal_surname,
                  maternal_surname: sellerR.maternal_surname,
                  number_phone: sellerR.number_phone,
                  email: sellerR.email 
                });

              
                setSellerID(id_seller);
                setMethod("update");
                setModalTitle("Modificar Vendedor");
                toggle();
              }

            const saveUpdate = async (e) => {
                    e.preventDefault();

                    setLoading(true);

                    if (validarForm()) {
                      setLoading(false);
                      return;
                  }

                  
                    let body = {
                      id_seller: sellerID,
                        ...{ ...formValues }
                    };

                    let requestOptions = {
                        method: 'PUT',
                        body: JSON.stringify(body)
                    };

                    const respuesta = await sendRequest(requestOptions, "Seller");

                    if (respuesta.code === 200) {
                        getSeller();
                        createSweet("create", "info", "Exito!", "Vendedor Actualizado");
                        setLoading(false);
                        toggle();
                    }
                    else if (respuesta.code === 401) {
                        validarSesion(history, respuesta.code, saveUpdate);
                    }
                    else if (respuesta.code === 500) {
                        createSweet("error", "error", "Vendedor", "Ocurrio un error en el servidor");
                    }
                    else {
                        createSweet("error", "warning", "Vendedor", respuesta.data.msg);
                    }
              }

            const handleDelete = (id_seller) => {

                  let sellerR = sellerD.find(b => b.id_seller === parseInt(id_seller));
          
                  handleUpdateValues({
                      name: sellerR.name
                  });
                  setSellerID(id_seller);
                  setMethod("delete");
                  setModalTitle("Eliminar Vendedor");
                  toggle();
          
              }

            const saveDelete = async (e) => {
                e.preventDefault();
        
                setLoading(true);
        
                let requestOptions = {
                    method: 'DELETE'
                };
                const respuesta = await sendRequest(requestOptions, `Seller/${sellerID}`);
        
                if (respuesta.code === 200) {
                    getSeller();
                    setLoading(false);
                    createSweet("create", "info", "Exito!", "Vendedor eliminado");
                    toggle();
                }
                else if (respuesta.code === 401) {
                    validarSesion(history, respuesta.code, saveDelete);
                }
                else if (respuesta.code === 500) {
                    createSweet("error", "error", "Vendedor", "Ocurrio un error en el servidor");
                }
                else {
                    createSweet("error", "warning", "Vendedor", respuesta.data.msg);
                    setLoading(false);  
                }
              }


            const validarForm = () => {

              let statusValidacion = false;
              let newValidaciones = {};
      
      
              
      
              if (formValues.name.length < 3) {
                  newValidaciones = {
                      ...newValidaciones,
                      name_valid: true
                  }
                  statusValidacion = true;
              }
              else {
                  newValidaciones = {
                      ...newValidaciones,
                      name_valid: false
                  };
              }

              if (formValues.paternal_surname.length < 3) {
                newValidaciones = {
                    ...newValidaciones,
                    paternal_surname_valid: true
                }
                statusValidacion = true;
                }
                else {
                    newValidaciones = {
                        ...newValidaciones,
                        paternal_surname_valid: false
                    };
                }

                if (formValues.maternal_surname.length < 3) {
                  newValidaciones = {
                      ...newValidaciones,
                      maternal_surname_valid: true
                  }
                  statusValidacion = true;
                  }
                  else {
                      newValidaciones = {
                          ...newValidaciones,
                      maternal_surname_valid: false
                      };
                  }
      
  
                 
              
              SetValidaciones(newValidaciones);
              return statusValidacion;
      
          }



    return {
      name,paternal_surname,maternal_surname,number_phone,email,sellerD,handleInputChange,handleCreate,
      method,modalTitle,loading,toggle,modal,
      totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchSeller,
      saveCreate,handleUpdate,saveUpdate,handleDelete,saveDelete,validaciones

    }
}
