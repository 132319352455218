import React, { useEffect, useState } from 'react'
import moment from 'moment';


export const TimerCard = ({ time }) => {

    const [newTime, setNewTime] = useState('');


    useEffect(() => {
        var intervalo = setInterval(() => {
            let tiempo = moment(time);
            let hoy = moment();
            let segundos = hoy.diff(tiempo, 'seconds');
            var minute = Math.floor(segundos / 60);
            var hora = Math.floor(minute / 60)
            let rest_seconds = segundos % 60;
            let rest_minutes = (minute % 60);

            rest_seconds = String(rest_seconds).length === 1 ? `0${rest_seconds}` : rest_seconds;
            rest_minutes = String(rest_minutes).length === 1 ? `0${rest_minutes}` : rest_minutes;
            hora = String(hora).length === 1 ? `0${hora}` : hora;

            setNewTime(hora + ":" + rest_minutes + ":"+rest_seconds);
        }, 1000);

        return () => {
            clearInterval(intervalo);
        }
    }, [time])

    return (
        <div><label className='Label-Card-Header-Time'>{newTime}</label></div>
    )
}
