import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Table, Label, ModalBody, ModalFooter, Row, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import './StyleExamCreate.css';


export const CreateExamImagenology = (props) => {

    const {

        methodAction, toggleImagenologia,
        formValues, method, handleInputChange, validaciones, handleSelectValues,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
        toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
        changeSelect, handleChangePrice,
        sections2, number_projections, met, enable_analyte_configuration, isImagenologyy, examEditIm

    } = props;

    //modificar creacion del examen para los examnes de imagenologias

    let isImagenology = true
    const txtsection = localStorage.getItem('TextAS');

    ////console.log(isImagenologyy);

    return (
        <Form onSubmit={methodAction} >
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-4">
                        <Label className='labelInfoAnalyte'>Clave <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.code}
                            type="text"
                            name="code"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label className='labelInfoAnalyte'>Alias <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.alias}
                            type="text"
                            name="alias"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        //invalid={validationCode}
                        />
                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label className='labelInfoAnalyte'>Abreviatura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.abbreviation}
                            type="text"
                            name="abbreviation"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        //invalid={validationCode}
                        />
                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label className='labelInfoAnalyte'>Nombre del exámen<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.name}
                            type="text"
                            name="name"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        // invalid={validationCode}
                        />
                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className='labelInfoAnalyte'>{txtsection}<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.id_section}
                            type="select"
                            name="id_section"
                            required={true}
                            onChange={changeSelect}
                        //invalid={validationSection}
                        >
                            <option value="0">Selecciona una opción</option>
                            {
                                sections2.length > 0 && sections2.map((s, key) => {
                                    return <option key={key} value={s.id_section}>
                                        {s.name}
                                    </option>
                                })
                            }
                        </Input>

                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-3">
                        <Label className='labelInfoAnalyte'>Subsección<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.id_subsection}
                            type="select"
                            name="id_subsection"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        // invalid={validationCode}
                        >
                            <option value="0">Selecciona una opción</option>
                            {
                                subSections.length > 0 && subSections.map((ss, key) => {
                                    return <option key={key} value={ss.id_subsection}>
                                        {ss.name}
                                    </option>
                                })
                            }

                        </Input>

                        {/* <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-3">
                        <Label className='labelInfoAnalyte'>Número de proyecciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.number_projections}
                            type="text"
                            name="number_projections"
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}

                        >

                        </Input>
                    </FormGroup>
                    <Col sm="3">
                        {/* {
                            examId > 0 ? "" :
                                <> */}
                        <Row>
                            <FormGroup className="mb-2 col-11 mb-3">
                                <Label>Costo de producción <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <InputGroup className="">
                                    <Input type="number" min={0} className="form-control" name="cost" value={cost} onChange={handleInputChange} />
                                    <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        {/* </>
                        } */}
                    </Col>
                    <FormGroup className="mb-3 col-3">
                        <Label className='labelInfoAnalyte'>Tiempo de entrega<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.delivery_time}
                            type="text"
                            name="delivery_time"
                            required={false}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-4">
                        <Label className='labelInfoAnalyte'>Tipo de muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label> <br />
                        <label className='txt-secondary'>{"Selecciona placa"}</label>
                        <Select
                            value={formValues.id_specimen}
                            classNamePrefix="select"
                            name="id_specimen"
                            placeholder={`Selección de especimen`}
                            isClearable={false}
                            noOptionsMessage={() => "Sin opciones"}
                            options={specimens}
                            onChange={(e) => handleSelectValues(e, "id_specimen")}
                        />
                        <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label className='labelInfoAnalyte'>Procedencia de la muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <label className='txt-secondary'>Selecciona de humano</label>
                        <Select
                            value={formValues.id_specimen_provenance}
                            classNamePrefix="select"
                            name="id_specimen_provenance"
                            placeholder={`Seleciona la procedencia`}
                            isClearable={false}
                            noOptionsMessage={() => "Sin opciones"}
                            options={specimenProvenances}
                            onChange={(e) => handleSelectValues(e, "id_specimen_provenance")}
                        />
                        <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-3">
                        <Label>Venta individual</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" name="individual_sale" checked={formValues.individual_sale} onChange={handleInputChange} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="mb-3 col-3">
                        <Label>¿Es referenciado?</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" hecked={formValues.is_referenced} name="is_referenced" onChange={handleInputChange} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="mb-3 col-3">
                        <Label>¿Es critico?</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" checked={formValues.is_critic} name="is_critic" onChange={handleInputChange} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                </Row>
                <Row>
                    <Col sm="6">
                        <label className=''>Precio inicial</label>
                        <Table className="table table-bordered table-sm">
                            <tbody>
                                {
                                    commercialLines.length >= 1 && commercialLines.map((data, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className='bg-secondary' >{data.short_name}</td>
                                                <td className='bg-secondary' >
                                                    <InputGroup className="">
                                                        <Input
                                                            type="number"
                                                            name="price_commercial_line"
                                                            onChange={handleChangePrice}
                                                            className="form-control"
                                                            data-id={key} step="any" placeholder="Precio inicial" />
                                                        <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Input type='hidden' value={isImagenologyy} name="isImagenology" />

            </ModalBody>
            <ModalFooter>

                {
                    isImagenologyy ?
                        <Button
                            // color="primary"
                            outline color="primary"
                            type="submit"
                        ///wmic bios get serialnumber
                        //wmic csproduct get name, identifyingnumber
                        // className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""

                        // className={`${(examId > 0 && examEditIm === undefined) ? "d-none " : ""} ${loading ? "disabled progress-bar-animated progress-bar-striped" : ""}`}
                        > {"Guardar"}
                            {/* {examEditIm === undefined ? "Guardar" : "Actualizar"} */}
                        </Button>
                        : <Button
                            outline color="primary"
                            // color="primary"
                            type="submit"
                            className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            Guardar
                        </Button>
                }

                {
                    !isImagenologyy &&
                    <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggleImagenologia}>Cancelar</Button>
                }
            </ModalFooter>

        </Form>

    )
}
