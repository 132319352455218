import React, { useState, useEffect, useCallback,Fragment } from 'react';
import { Container, Row, Col, Card, Button, CardBody, Table, Badge, Progress, Label, Form, FormGroup, Input } from 'reactstrap';
// import { useHistory } from 'react-router-dom';
// import { sendRequest } from '../../../hooks/requests/useRequest';
// import { validarSesion } from '../../../hooks/tokens/useToken';




// const history = useHistory();

// useEffect(() => {
//     handleGetSpecialties();
// }, []);



// const handleGetSpecialties = async () => {
//     let listTemp = await handleRequest("GET", `WorkOrder/DetailPbi`, 'Pbi');
//     ////console.log(listTemp);
   
// }



// const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
//     let requestOptions = {
//         method: method
//     };

//     if (body !== null) {
//         requestOptions = {
//             ...requestOptions,
//             body: JSON.stringify(body)
//         };
//     }

//     const response = await sendRequest(requestOptions, methodUrl);

//     if (response.code === 200) {
//         return response.data;
//     }
//     else if (response.code === 401) {
//         validarSesion(history, response.code, handleRequest);
//     }
//     else if (response.code === 500) {
//         createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
//     }
//     else {
//         createSweet("warning", "warning", nameMethod, response.data.msg);
//     }

//     return null;
// }








export const IndexView1 = () => {
  return (
    <Fragment>
        <Container>
            <Col sm="12">
                <Card className='shadow' >
                <div style={{ textAlign: "-webkit-center" }} >
					{/* <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
				    <label className='f-w-600 f-14 badge badge-light-primary'>BI</label> */}
                    <iframe id="inlineFrameExample"
                        title="Inline Frame Example"
                        width="800"
                        height="200"
                        src="https://app.powerbi.com/reportEmbed?reportId=9829fcb7-efec-414d-9fcc-a1054a00e17f&autoAuth=true&ctid=4f95184a-1e24-4492-8aaa-4571454a493a"
                        
                        >
                    </iframe>
				</div>

                </Card>
            </Col>

        </Container>
    </Fragment>
  )
}
