import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { AuthorizedCommonDiscount } from './AuthorizedCommonDiscount';
import { DeleteDiscountCommon } from './DeleteDiscountCommon';
import { FormCommonDiscounts } from './FormCommonDiscounts';

export const ModalCommonDiscount = (props) => {

    const {
        modal, toggleModal, modalTitle, method, loading,
        id_gender,  name, code, valid_from, valid_to, days_of_week,
        percentage, auto_redeem, accumulative, percentage_global, day_from,
        day_to, list_exams_includes, list_exams_excludes, list_profiles_includes,

        treeViewRef,

        listBranchesTree, listSections, listExamsFiltered, listProfiles,

        handleInputChange, handleChangeSections, handleSelectExamsIncludes,
        handleChangePercentage, handleRemoveTest, handleSelectProfilesIncludes,
        handleCheckDaysOfWeek, saveCommonDiscount, saveDelete, saveAuthorized,

        validationsCommonDiscount, 
        
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggleModal} backdrop='static' keyboard={false} centered={true} size={(method === "delete" || method === "auth" || method === "unAuth") ? "md" : "xl"} scrollable={true}>
            <ModalHeader toggle={toggleModal} className="bg-primary">
                { modalTitle }
            </ModalHeader>
            {
                
                method === "delete" ?
                    <DeleteDiscountCommon
                        {
                        ...{ toggleModal, loading, saveDelete, name, code, }
                        }
                    />
                    :
                    ((method === "auth" || method === "unAuth") ?
                        <AuthorizedCommonDiscount
                            typeAuthorization={method === "auth" ? "autorizar" : "desautorizar"}
                            {
                            ...{ toggleModal, loading, saveAuthorized, name, code }
                            }
                        />
                        :
                        <FormCommonDiscounts
                            readOnly={method === "view" ? true : false}
                            button={ method === "create" ? "Crear campaña" : (method === "update" ? "Editar campaña" : "") }
                            {...{
                                method, toggleModal, loading,
                                id_gender,  name, code, valid_from, valid_to, days_of_week,
                                percentage, auto_redeem, accumulative, percentage_global, day_from,
                                day_to, list_exams_includes, list_exams_excludes, list_profiles_includes,

                                treeViewRef,

                                listBranchesTree, listSections, listExamsFiltered, listProfiles,

                                handleInputChange, handleChangeSections, handleSelectExamsIncludes,
                                handleChangePercentage, handleRemoveTest, handleSelectProfilesIncludes,
                                handleCheckDaysOfWeek, saveCommonDiscount,

                                validationsCommonDiscount
                            }}
                        />
                    )
            }
        </Modal>
    )
}
