import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteMicSiteArea } from './DeleteMicSiteArea';
import { FormMicSiteArea } from './FormMicSiteArea';


export const ModalMicSiteArea= (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, id_mic_site, sites,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;
    //////console.log(id_mic_site);
   // ////console.log(name);
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormMicSiteArea
                        button={"Crear sitio"}
                        {...{ toggle, loading, handleInputChange, sites, validaciones, methodAction: saveCreate, sites}}
                    />
                    : (method === "update")
                        ?
                        <FormMicSiteArea
                            button={"Actualizar sitio"}
                            {...{ toggle, loading, handleInputChange, id_mic_site, sites, validaciones, methodAction: saveUpdate, name, sites }} />
                        :
                        <DeleteMicSiteArea {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )
}
