import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormMethod } from './FormMethod';



export const ModalMethod = (props) => {
    const {
        modal, modalTitle, toggle, method,
                validaciones,
                name,code,
                handleInputChange, loading,
                saveCreate,saveUpdate
    } = props;

    return <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle} className="bg-primary f-12">
            {modalTitle}
        </ModalHeader>
        {
            method === "create"
                ? <FormMethod
                    button={"Crear método"}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                />
                : (method === "update")
                    ?
                    <FormMethod
                    button={"Actualizar método"}
                    {...{ toggle, loading, handleInputChange,name,code, validaciones, methodAction: saveUpdate }}
                />
                    :
                    ""
        }
    </Modal>
};
