import { useCallback, useEffect, useRef, useState } from 'react';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useHistory, useParams } from 'react-router';


export const useDetectedActivity = (tiempoMonitor = 30000, tiempoDetector = 5000) => {
    const history = useHistory();

    useEffect(() => {
        monitor();
        return () => {
            clearInterval(interval);
        }
    }, [])



    var interval;
    const monitor = () => {
        interval = setInterval(() => {
            document.onmousemove = detector;
            setTimeout(() => {
                document.onmousemove = null;
            }, tiempoDetector);
        }, tiempoMonitor);
    }


    var timeout;
    var detector = () => {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            validarSesion(history, 401);
        }, 500);
    }
}
