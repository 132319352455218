import React, { Fragment } from 'react'
import { useReturnReason } from '../../../hooks/returnReason/useReturnReason'
import { Card, Col, Container, Row, Button, CardBody, Input, Form } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalReturnReason } from '../../../components/returnReason/ModalReturnReason';
import Select from 'react-select';



export const IndexReturnReason = () => {

    const {
        listReturnReason,
        method, modalTitle, loading, modal, toggle, handleInputChange,
        handleCreate, saveCreate, handleUpdate, saveUpdate, handleDelete, saveDelete,
        id_return_reason, reason, validaciones,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleSearchReason, is_sample,
        is_work_order, is_tracking, is_send,
        getFilterReason, Reason,
        handleSelectValues, mSelectReason
    } = useReturnReason();



    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {"Motivo de Devoluciones"}
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate} ><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchReason} />
                                    </div>
                                </div>
                                <Col sm="12">
                                    <Form onSubmit={(e) => getFilterReason(e, true)}>
                                        <Row>

                                            <Col sm="6" >
                                                <label className=" ">Selecciona el catalogo de devoluciones que deseas visualizar</label>
                                                <Select
                                                    name="mSelectReason"
                                                    isClearable={true}
                                                    placeholder="Selecciona un Cátalogo"
                                                    noOptionsMessage="Sin resultados"
                                                    options={Reason}
                                                    onChange={(e) => handleSelectValues(e, 'mSelectReason')}
                                                    value={mSelectReason}
                                                    className="b-r-9"
                                                />
                                            </Col>
                                            <Col sm="2" className='p-2' >
                                                <br />
                                                <Button type="submit" outline color="primary" size='sm' className={(loading ? "disabled progress-bar-animated progress-bar-striped mt-1 text-center" : "text-center mt-1")} > Buscar</Button>

                                            </Col>
                                        </Row>

                                    </Form>
                                </Col>
                                {
                                    listReturnReason.length === 0 ?
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" />
                                            <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se a registrado alguna cancelación o devolución.</p>
                                        </div>
                                        :
                                        <div className="table-responsive  p-2">
                                            <TableStriped
                                                notMaped={["visibleTable"]}
                                                methodsActions={true}
                                                cabeceras={["Motivo o Razón", "Orden", "Toma", "Envios", "Análitica"]}
                                                items={listReturnReason}
                                                {...{ handleUpdate, handleDelete }}
                                                nextPage={nextPage}
                                                previousPage={previousPage}
                                                totalPageCount={totalPageCount}
                                                currentPage={currentPage}
                                                goToPage={goToPage}
                                            />
                                        </div>
                                }
                                <br />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalReturnReason
                {
                ...{
                    method, modalTitle, loading, modal, toggle,
                    handleCreate, saveCreate, handleInputChange, handleUpdate, saveUpdate, handleDelete, saveDelete,
                    id_return_reason, reason, validaciones, is_sample, is_work_order, is_tracking, is_send
                }
                }
            />
        </Fragment>
    )
}
