import React from 'react'
import Select from 'react-select';
import { Form, Col, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const ModalAddProcess = (props) => {

    const {
        toggle, loading, handleInputChange, methodAction, name, code, button, processes, processesSelected, changeSelectProcesses
    } = props;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Procesos <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                isMulti={true}
                               // className="form-control form-control-plaintext form-control-sm"
                                classNamePrefix="select"
                                name="id_exam_include"
                                options={processes}
                                value={processesSelected}
                                placeholder={`Selección de procesos`}
                                isClearable={false}
                                onChange={(array, actionData) => changeSelectProcesses(array, actionData)}
                            />
                            <FormFeedback>Minimo 3 caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
