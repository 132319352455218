import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteDepartament } from './DeleteDepartament';
import { FormDepartament } from './FormDepartament';

export const ModalDepartament = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, id_area, areas, specimen, specimens, handleSelectValues,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete,use_consent
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormDepartament
                        button={"Crear departamento"}
                        {...{ toggle, loading, handleInputChange, validaciones, areas, specimens, specimen, handleSelectValues, methodAction: saveCreate, specimens, use_consent }}
                    />
                    : (method === "update")
                        ?
                        <FormDepartament
                            button={"Actualizar departamento"}
                            {...{ toggle, loading, handleInputChange, validaciones, id_area, areas, specimens, specimen, handleSelectValues, methodAction: saveUpdate, areas, name, specimens, use_consent }} />
                        :
                        <DeleteDepartament {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )
}
