import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteSampling } from './DeleteSampling';
import { FormSampling } from './FormSampling';

export const ModalSampling = (props) => {

    const {
        samplingItems,
        modal, toggle, modalTitle, method, loading,
        handleInputChange, handleSelectValues, validaciones,
        name, code, items,
        saveCreate, saveDelete, saveUpdate,
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} className={(method !== "delete") ? "modal-xl" : ""} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle}>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormSampling
                        button="Guardar"
                        {...{
                            samplingItems,
                            name, code, items,
                            toggle, loading, handleInputChange, handleSelectValues, methodAction: saveCreate,
                            validaciones,
                        }}
                    />
                    : (method === "update")
                        ?
                        <FormSampling
                            button="Actualizar"
                            {...{
                                samplingItems,
                                name, code, items,
                                toggle, loading, handleInputChange, handleSelectValues, methodAction: saveUpdate,
                                validaciones,
                            }}
                        />
                        :
                        <DeleteSampling
                            {...{ toggle, loading, name, saveDelete }}
                        />
            }
        </Modal>
    )
}
