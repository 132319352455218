import React, { useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row,ModalHeader,Input,Alert} from 'reactstrap';
import 'animate.css';
import StepZilla from "react-stepzilla";
import {EventAmount}   from './EventAmount'
import {CaptureAmount}   from './CaptureAmount'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "moment/locale/es";
import DeniReactTreeView from 'deni-react-treeview';


export const ModalEvent = (props) => {
    
    const {
        loadingPreview,modalTitle,toggleModalEvent,modalPreviewInvoice,
        start,end,setEnd,setStart,countSelected,amount_required,handleInputChange,
        saveProgrammingDiary,handleClose,title,validationsCreate,loadingViews,
        listBranchesTree,treeViewRef,onRenderItem,handleChangeAmount,
        onChangeItemtree,loadingProgramming
    } = props;

        //*PROGRAMMING EVENT MONTHLY
        const steps =
            [
                { name: 'Periodo de programación', component:  <EventAmount start={start} end={end} setEnd={setEnd} setStart={setStart} handleInputChange={handleInputChange} title={title} loadingViews={loadingViews}  /> },
                { name: 'Asignar montos a sucursales', component:   <CaptureAmount amount_required={amount_required} handleInputChange={handleInputChange} treeViewRef={treeViewRef}  listBranchesTree={listBranchesTree}  handleChangeAmount={handleChangeAmount} onRenderItem={onRenderItem} onChangeItemtree={onChangeItemtree} /> },            
            ]

        //*ALERT SAVE EVENT
        const AlertItem = props => {
            const { item } = props;
            const [Open, setOpen] = useState(true);
    
            return (
                <Alert className="alert-dismissible alert-color"
                    color={item.alertcolor}
                    isOpen={Open}
                    target={"Alert-" + item.id}
                >
                    {item.icon}
                    <label className='f-14'>{item.alerttxt}</label>
                </Alert>
            );
        }



    return (
        <Modal isOpen={modalPreviewInvoice} toggle={toggleModalEvent} centered={true} size='xl' scrollable={true}>
              <ModalHeader  className='bg-calender'>
                {modalTitle}
            </ModalHeader>
            <ModalBody className='p-t-0'  >
                {
                    loadingPreview === true && <>
                        <Row className='mt-2'>
                            <Col sm="12" md="12" lg="12" className='text-center'>
                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'150px' }} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                            <Col sm="3" md="3" lg="3" className='text-left'>
                                <p className='f-24 text-primary'><b>Cargando</b></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p> 
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                            </Col>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                        </Row>
                    </>                    
                }
                {
                    loadingPreview === false && <>
                    {
                        <Col sm="12" className='p-l-0 p-r-0'>
                            {
                                countSelected != 0 ? 
                                <Col sm="12" className='p-l-0 p-r-0' > <br />
                                {
                                 ((validationsCreate.amount_required  || validationsCreate.title)) &&  
                                 <AlertItem
                                      item={
                                          {
                                              alerttxt: "Debes de llenar todos los campos requeridos para realizar su registro.",
                                              alertcolor: 'danger inverse',
                                              btnclose: 'default',
                                              icon: <i className="icon-alert"></i>
                                          }
                                      }
                                  />
                                }
                                <Col sm="12" className='p-l-0 p-r-0'>
                                    <Row>
                                        <Col sm="5">
                                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Titulo *</span>
                                                <Input  type='text' name="title" value={title} onChange={handleInputChange} className={ loadingViews === true ? "border-0 mr-2" : `form-control form-control-sm input-air-primary border-primary b-r-9 f-14` }    />  
                                        </Col>
                                        <Col sm="3">
                                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Inicia-Termina  *</span>
                                            <DateRangePicker
                                                initialSettings={{
                                                locale: {
                                                format: "YYYY-MM-DD",
                                                applyLabel: "Aplicar",
                                                cancelLabel: "Cancelar"
                                                },
                                                startDate: start,
                                                endDate: end,
                                                timePicker: false,
                                                drops: 'down',
                                                singleDatePicker: true,
                                                minDate: start,
                                                maxDate: start,
                                                }}
                                                onApply={(event, picker) => {
                                                setStart(new Date(picker.startDate));
                                                setEnd(new Date(picker.endDate));
                                                }}
                                                >
                                            <input  className={ loadingViews === true ? "border-0 mr-2" : `form-control form-control-sm input-air-primary border-primary  b-r-9 f-14` }   type="text" />                                 
                                            </DateRangePicker>
                                        </Col>
  
                                    </Row>
                                </Col>  
                                <hr />     
                                <Col sm="12">
                                    <div className='border-range-programming' >
                                    <Row>
                                        <Col sm="4">
                                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Sucursales *</span> 
                                        <DeniReactTreeView
                                            items={listBranchesTree}
                                            ref={treeViewRef}
                                            showCheckbox={true}
                                            showIcon={false}
                                            onRenderItem={onRenderItem}
                                            style={{ marginRight: '10px', marginBottom: '10px', height: '240px', border: 'none' }}
                                            onCheckItem={(e) => onChangeItemtree(e)}
                                            className="deni-react-treeview-container-4"
                                        />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Monto*</span> 
                                        {listBranchesTree?.map((_find, _key) => (
                                            _find.children.map((_branch) => {
                                                return (
                                                    <div key={_branch.id} className={`p-1 `}> 
                                                        <Input type='text' name="amount" value={_branch.amount} onChange={(e) => handleChangeAmount(e, _branch.id)} autoComplete='off' 
                                                            className={`form-control form-control-sm input-air-primary ${_branch?.state == 2 ? 'd-none': ''}`} /> 
                                                    </div>
                                                );
                                            }) 
                                        ))}
                                        </Col>
                                    </Row>
                                    </div>
                                </Col>
                                </Col>
                                :
                                <div>
                                  {
                                    loadingProgramming === false ? 
                                    <StepZilla 
                                    steps={steps}
                                    showSteps={true}
                                    showNavigation={true}
                                    stepsNavigation={true}
                                    prevBtnOnLastStep={true}
                                    dontValidate={true}
                                    backButtonText="Anterior"
                                    nextButtonText="Siguiente"
                                    />
                                    :
                                    <div> <br />
                                    <Row className='mt-2'>
                                      <Col sm="12" md="12" lg="12" className='text-center'>
                                          <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'150px' }} />
                                      </Col>
                                     </Row>
                                    <Row className='mt-2'>
                                        <Col sm="2" md="2" lg="3" className='text-center'></Col>
                                        <Col sm="3" md="3" lg="4" className='text-right'>
                                            <p className='f-22 text-primary'><b>Recopilando Información</b></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p> 
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                                        </Col>
                                     </Row>
                                    </div>
                                    }
                                </div>
                            }
                        </Col>
                    }
                    </>
                }
            </ModalBody>
            {
                loadingPreview === false && <ModalFooter>
                    {
                       
                        <div>
                             <Button size="sm" className="btn-air-light" outline color="danger" onClick={handleClose} type="button" >Cerrar</Button>
                             <Button size="sm" className="btn-air-light" outline color="secondary" onClick={ () =>saveProgrammingDiary()} type="button" >Autorizar</Button>
                        </div>
                    }
                </ModalFooter>
            }           
        </Modal>
    )
    
}
