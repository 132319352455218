import React, { useEffect, useRef } from 'react';
import { Card, CardBody, Col, Row, Button, ButtonGroup  } from 'reactstrap';
import List from 'list.js';


export const ListPrinter = (props) => {

    const {
        filterProps = null, type = "list", inputFiter = false, items, handleUpdate = null, handleDelete = null, methodsAbsolutes = null
    } = props;

    ////console.log(props);

    const refList = useRef(null);

    useEffect(() => {
        if (items.length > 0) {
            new List(refList.current, {
                valueNames: filterProps,
                listClass: type
            });
        }
    }, [items]);



  return (
        <>
            <div ref={refList}>
                <Row>
                <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3"></Col>
                    <Col md="3">
                        {
                            inputFiter ?  <input className="form-control form-control-sm search" placeholder="Buscar" /> : ''
                        }
                    </Col>
                </Row>
                <br />
                <Row className={ type }>
                    {
                        items.length > 0 
                        ?
                        items.map((data,key) =>{
                            return(
                                <Col md="6" sm="12" xl="3" key={key}>
                                    <Card>
                                        <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                            <ButtonGroup>
                                            <Button outline className="btn btn-air-info" color="info" size="xs" onClick={() => handleUpdate(data.id_printer_point)}>
                                            <i className="icofont icofont-ui-edit f-14"></i>
                                            </Button>
                                            <Button outline className="btn btn-air-info" color="danger" size="xs" onClick={() => handleDelete(data.id_printer_point)}>
                                            <i className="icofont icofont-ui-delete f-14"></i>
                                            </Button>
                                            </ButtonGroup>
                                        </div>
                                        <Col sm="12">
                                        <div className="profile-vector" style={{ textAlign: "-webkit-center" }}>
                                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Print/Print.svg")} style={{ width: "35%" }} alt="" />
                                        </div>
                                        </Col> <br />
                                        <Col sm="12">
                                        <Row>
                                            <Col sm="6" style={{ textAlign :"center" }}>
                                            <label className=' f-12 text-secondary'>Nombre</label> <br />
                                            <label className='namePrinter f-12'>{ data.name }</label>
                                            </Col>
                                            <Col sm="6" style={{ textAlign :"center" }}>
                                            <label className=' text-secondary f-12'>Usuario</label> <br />
                                                <label className='f-12'>{data.username}</label>
                                            </Col>
                                          </Row>
                                        </Col>
                                    </Card>
                                </Col>
                            )
                        })
                        : null
                    }

                </Row>
                
            </div>

        </>
  )
}
