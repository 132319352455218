import React, { Fragment, useEffect, useRef, useState } from 'react';
import Blur from 'react-css-blur';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { Button, ButtonGroup, CardBody, Col, Container, Row } from 'reactstrap';
import { useSpecialDiscount } from '../../hooks/specialDiscounts/useSpecialDiscount';
import { ModalInformation } from '../../components/specialDiscounts/ModalInformation';
import { ModalSpecialDiscount } from '../../components/specialDiscounts/ModalSpecialDiscount';
import List from 'list.js';

import './StyleDiscount.css'

export const IndexSpecialDiscount = () => {

    const {
          //MODALS
        modalTitle,
        modalInformation, toggleInformation, methodInformation, 
        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount,
        
        //SPECIAL DISCOUNT MODEL        
        loading, listSpecialDiscounts, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
        id_user, code, id_exam_profile, percentage, percentage_global, 
        id_exam_include, id_profile_include, password,
        validationsSpecialDiscount, idSpecialDiscount,
        validCode,

        //SPECIAL DISCOUNTS METHODS
        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes,
        handleInputPercentageIndividual, 
        handleCreate, saveCreate,
        handleRemoveTest,
        handleAuthorized, saveAuthorized,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleShowValidCode, getInformationSpecialDiscount,dataCode, validName,valid_percentage
    } = useSpecialDiscount();

    const filterProps = ["codeSearch"], type = "list", inputFiter = true;
    const refList = useRef(null);

    useEffect(() => {
        
        if (listSpecialDiscounts.length > 0) {
            new List(refList.current,{
                valueNames: filterProps,
                listClass: type
            });

            setLongitud(listSpecialDiscounts.length);
        }

    }, [listSpecialDiscounts])
    
    const [longitud, setLongitud] = useState(0);

    const updateFilter = () => {
        setLongitud(refList.current.children[1].children.length);
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="mt-1">
                    <Col sm="12" md="12" lg="12">
                        <CardBody className='pl-3 pt-1 pr-3 pb-2'>
                            <div className='ribbon ribbon-clip ribbon-primary'>
                                {"Descuentos Especiales"}
                            </div>
                            <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreate}>
                                    <span className='fa fa-plus-circle'></span>
                                </Button>
                            </div>
                            <div ref={refList}>
                                <Row>
                                    <Col sm="9" md="9" lg="9"></Col>
                                    <Col sm="3" md="3" lg="3">
                                        {
                                            inputFiter ? 
                                                <input onKeyUp={updateFilter} autoComplete='off' name='filter' className='form-control form-control-sm search' placeholder='Búscar...' />
                                            : ""
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-2 list">
                                    {
                                        listSpecialDiscounts.length > 0 && listSpecialDiscounts.map((obj, key) => {
                                            return (
                                                <Col key={`card-${key}`} sm="4" className='mt-3'>
                                                    <div className='project-box p-3 ribbon-wrapper-left'>
                                                        <div className={`ribbon ribbon-clip ribbon-${obj.id_authorized != null ? (obj.used_at !== null ? "rev" : "aprov") : "inac" }`}>
                                                            {obj.id_authorized !== null ? (obj.used_at !== null ? "Reclamado" : "Disponible") : "Sin autorizar"}
                                                        </div>
                                                        <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                                            <ButtonGroup>
                                                                {
                                                                    obj.id_authorized === null &&
                                                                    <Button outline className='btn' color='success' size='xs' onClick={() => handleAuthorized(obj.id_special_discount)}>
                                                                        <i id={`tooltip-authorizedDiscount-${key}`} className="icofont icofont-ui-lock">
                                                                            <ToolTips
                                                                                placement="top"
                                                                                dataTarget={`tooltip-authorizedDiscount-${key}`}
                                                                                dataText={'Autorizar descuento'}
                                                                            />
                                                                        </i>
                                                                    </Button>
                                                                }
                                                                {
                                                                    <Button outline className='btn' color='info' size='xs' onClick={() => handleUpdate(obj.id_special_discount, (obj.id_authorized === null ? "edit" : "view"))}>
                                                                        <i id={`tooltip-${obj.id_authorized === null ? 'editDiscount' : 'viewDiscount' }-${key}`} 
                                                                            className={`icofont ${obj.id_authorized === null ? 'icofont-ui-edit' : 'icofont-eye-alt'}`}>
                                                                                <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`tooltip-${obj.id_authorized === null ? 'editDiscount' : 'viewDiscount'}-${key}`}
                                                                                    dataText={obj.id_authorized === null ? 'Editar' : 'Visualizar'}
                                                                                />
                                                                        </i> 
                                                                    </Button>
                                                                }
                                                                {
                                                                    obj.id_authorized === null &&
                                                                    <Button outline className='btn' color='danger' size='xs' onClick={() => handleDelete(obj.id_special_discount)}>
                                                                        <i id={`tooltip-deleteDiscount-${key}`} className='icofont icofont-ui-delete'>
                                                                            <ToolTips
                                                                                placement="top"
                                                                                dataTarget={`tooltip-deleteDiscount-${key}`}
                                                                                dataText="Eliminar "
                                                                            />
                                                                        </i>
                                                                    </Button>
                                                                }
                                                            </ButtonGroup>
                                                        </div>
                                                        <div className='text-center'>
                                                            <span>
                                                                <h5>
                                                                <span className='codeSearch'>COD-00{obj.is_special_discount}</span> / &nbsp;

                                                                    {/* <span className='codeSearch'>COD-00{obj.is_special_discount}</span> / &nbsp; */}
                                                                    {
                                                                        obj.visibility === false ?
                                                                            <Blur cursor={`${obj.id_authorized !== null && 'pointer' }`} radius="5px" transition='400ms'>
                                                                                <span onClick={obj.id_authorized !== null ? () => handleShowValidCode(obj.id_special_discount) : null} onCopy={(e) => { e.preventDefault(); return false; }}>
                                                                                    CÓDIGO
                                                                                </span>
                                                                            </Blur>
                                                                        :
                                                                            <span>{obj.code}</span>    
                                                                    }
                                                                </h5>
                                                            </span>
                                                        </div>
                                                        <Row>
                                                            <Col sm="6">
                                                                <div className='media'>
                                                                    <img className='img-35 mr-1 rounded-circle' src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} style={{ width: "22%" }} />
                                                                    <div className='media-body'>
                                                                        <label className='titleDiscount f-14'>Creado por:</label>
                                                                        <p className='f-12'><b>{ obj.name_user_created }</b></p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="6">
                                                                <div className='media text-right'>
                                                                    <div className='media-body'>
                                                                        {
                                                                            obj.name_user_authorized !== null ?
                                                                                <>
                                                                                    <p className="m-0 p-0 titleDiscount ">Autorizado por: &nbsp;</p>
                                                                                    <p className='f-12'><b>{ obj.name_user_authorized }</b></p>
                                                                                </>
                                                                            :
                                                                                <label className='tittleAut f-14'>Sin autorizar &nbsp;</label>
                                                                        }
                                                                    </div>
                                                                    <img className="img-35 mr-1 rounded-circle" 
                                                                        src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount2.svg")}  style={{ width: "22%" }}  
                                                                        alt="" 
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
            <ModalInformation
                {
                    ...{
                        modalInformation, toggleInformation, validCode, password, handleInputChange, getInformationSpecialDiscount,validName,valid_percentage
                    }
                }
            />
            <ModalSpecialDiscount
                {
                    ...{
                        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount, modalTitle,
                        loading, percentage, percentage_global, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
                        idSpecialDiscount, id_exam_include, id_profile_include,
                        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes, handleInputPercentageIndividual,
                        validationsSpecialDiscount,
                        saveCreate, 
                        handleRemoveTest,
                        saveAuthorized, saveUpdate, saveDelete,validName
                    }
                }
            />
        </Fragment>
    )
}
