import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateSubSection, ModalUpdateSubSection, ModalDeleteSubSection } from '../../constant';

export const useSubsections = () => {
    
    const history = useHistory();

    const [subsections, setSubsections] = useState([]);
    const [subsectionId, setSubsectionId] = useState(0);

    const [sections, setSections] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [ formValues, handleInputChange, reset, handleUpdateValues ] = useForm({
        id_section: 0,
        name: '',
        abbreviation: ''
    });

    const { id_section, name, abbreviation } = formValues;

    const [validaciones, setValidaciones] = useState({
        id_section_valid: false,
        name_valid: false,
        abbreviation_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getSubsections = useCallback( async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SubSection/List");

        if (respuesta.code === 200) {
            let listSubsections = [];

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data) => {
                    listSubsections.push({
                        id_subsection: data.id_subsection,
                        id_section: data.id_section,
                        section: data.section,
                        name: data.name,
                        abbreviation: data.abbreviation
                    });
                });

                setSubsections(listSubsections);
            }
            else{
                //createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSubsections([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSubsections);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Sub secciones", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Sub secciones", respuesta.data.msg);
        }

    }, [history]);

    useEffect( () => {
        getSubsections();
    }, [getSubsections]);

    const getSections = async () => {
        let requestOptions = {
            method: 'GET'        
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {
            let listSections = [];

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data) => {
                    listSections.push({
                        id_section: data.id_section,
                        name: data.name,
                        abbreviation: data.abbreviation
                    });
                });

                setSections(listSections);
            }
            else{
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSections([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSections);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Sub secciones", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Sub secciones", respuesta.data.msg);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_section: '',
            name: "",
            abbreviation: ""
        });

        setValidaciones({
            id_section_valid: false,
            name_valid: false,
            abbreviation_valid: false
        });

        setSubsectionId(0);
        getSections();
        setMethod("create");
        setModalTitle(ModalCreateSubSection);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SubSection");
        if (respuesta.code === 200) {
            getSubsections();
            reset();
            createSweet("create", "success", "Exito!", "Subsección creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Sub secciones", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Sub secciones", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_subsection) => {
        let subsection = subsections.find(ss => ss.id_subsection === id_subsection);

        handleUpdateValues({
            id_section: subsection.id_section,
            name: subsection.name,
            abbreviation: subsection.abbreviation
        });

        setValidaciones({
            id_section_valid: false,
            name_valid: false,
            abbreviation_valid: false
        });
        
        getSections();
        setSubsectionId(id_subsection);
        setMethod("update");
        setModalTitle(ModalUpdateSubSection);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_subsection: subsectionId,
            ...{...formValues}
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "SubSection/Update");
        if (respuesta.code === 200) {
            getSubsections();
            createSweet("create", "info", "Exito!", "Subsección actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Sub secciones", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Sub secciones", respuesta.data.msg);
        }
    }

    const handleDelete = (id_subsection) => {
        let subsection = subsections.find(ss => ss.id_subsection === id_subsection);

        handleUpdateValues({
            name: subsection.name,
            abbreviation: subsection.abbreviation
        });

        setSubsectionId(id_subsection);
        setMethod("delete");
        setModalTitle(ModalDeleteSubSection);
        toggle();
    }

    const saveDelete = async(e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions,`SubSection/${subsectionId}`);
        if(respuesta.code === 200){
            getSubsections();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Subsección eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Sub secciones", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Sub secciones", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.id_section === 0 ) {
            newValidations = {
                ...newValidations,
                id_section_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                id_section_valid: false
            }
        }

        if (formValues.name === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.abbreviation === undefined || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    return {
        subsections, sections, method, validaciones,
        id_section, name, abbreviation,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete        
    }
}
