import React from 'react'
import { useEffect, useState } from 'react';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useHistory } from 'react-router';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";

export const useLogs = () => {

    
    const [dataLog, setDataLog] = useState([]);
    const [filters, setfilters] = useState("");
    const [username, setUsername] = useState([]);
    const [examsData, setExamsData] = useState([]);

    const [idUser, setIdUser] = useState([]);
    const [userState, setUserState] = useState([]);



    const history = useHistory();


    
    useEffect(() => {

        getUser();

    }, [history.location])

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        status_code: "",
        method: "",
        url: "",
        from: "",
        to: "",
        ids_users:0

    });

    const { status_code, method, url,from,to,ids_users} = formValues;


    const handleFilters = () => {

        let query = "";

        let mfrom = (from === '') ? "" : moment(from).format("YYYY-MM-DD");
        let mto = (to === '') ? "" : moment(to).format("YYYY-MM-DD");
     
    
        if (status_code !== "") {
          query += `&status_code=${status_code}`;
        }

        if (method !== "") {
            query += `&method=${method}`;
        }

        if (url !== "") {
            query += `&url=${url}`;
        }

        if (mfrom !== "") {
            query += `&from=${mfrom}`;
        }
      
        if (mto !== "") {
            query+= `&to=${mto}`;
        }

        if (ids_users !==0){

            let iddd= []
            ids_users.forEach(element => {
                iddd.push(element.value);
            });
            query+= `&ids_users=${iddd}`;
        }
        
        setfilters(query);

      }



      useEffect(() => {

        searchAsync();
    
      }, [filters])


      const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Logs/List",
        history,
        "",
        filters
    );

    useEffect(() => {

        let listLog = [];
        results.forEach((dataa, key) => {
            let visibleTable = true;

            listLog.push({
                id_log: dataa.id_log,
                username: dataa.username,
                url: dataa.url,
                method: dataa.method,
                status_code: dataa.status_code,
                created_at: dataa.created_at,
                visibleTable: visibleTable
            });
        });
        setExamsData(results);
        setDataLog(listLog);
    }, [results])


    const getUser = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Users");

        let listuser = []

        if (respuesta.code === 200) {
            if (respuesta.data.length >=1) {
                respuesta.data.forEach((element) => {
                    listuser.push({
                        id_user: element.id_user,
                        name: element.name,
                        
                    })
                });
                setUsername(listuser);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getUser);
        }
        else if (respuesta.code === 500) {
            //createSweet("error", "error", "Logs", "Ocurrio un error en el servidor");
        }
        else {
            //createSweet("error", "warning", "Logs", respuesta.data.msg);
        }
    }



    const handleChangeUser = (e) => {
        handleInputChange(e);

        let user = e.target.value;

        if (user.length > 0) {
            setIdUser(user);
        }
        else {
            setIdUser(user);
            handleUpdateValues({
                ...formValues,
                ids_users: 0
            });
        }
    }

    





  return {
    status_code, method, url,handleFilters,handleInputChange,dataLog,
    goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
    username,examsData,from,to,handlePickerValues,ids_users,
    handleChangeUser,idUser, userState
    
  }
}
