import React, { useState } from 'react'

export const TableItemSubLista = (props) => {
    const {
        key1, element
    } = props;
    const verMas = element[2].length > 2 ? true : false;
    const [visibilidad, setVisibilidad] = useState(false);
    return (
        <li key={key1}>{element[1]}
            <ul>
                {
                    element[2].map((secondary, key2) => {
                        {
                            if (key2 <= 1) {
                                return <li key={key2}>
                                    {secondary}
                                </li>
                            } else {
                                return <li key={key2} className={(visibilidad ? "animate__fadeIn" : "d-none animate__fadeOut") + " animate__animated "}>
                                    {secondary}
                                </li>
                            }
                        }
                    })
                }
                {
                    verMas &&
                    <span onClick={() => setVisibilidad(!visibilidad)} className="text-primary" style={{ cursor: "pointer" }}>
                        {
                            visibilidad ?
                                "Ver menos" :
                                "Ver más"
                        }
                    </span>
                }
            </ul>
        </li >
    )
}
