import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllPayMethod ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `PayMethod/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}
