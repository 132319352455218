import React from 'react'
import { Col, Row } from 'reactstrap'

export const DataAnalyte = ({ data }) => {
    return (
        <Row >
            <Col>
                <b>Impresión:</b> {data.print === true ? "Si" : "No"}
            </Col>
            <Col>
                <b>Tipo de captura:</b> {data.required === true ? "Riguroso" : "No riguroso"}
            </Col>
            {
                data.name_analyte_group !== null && data.name_analyte_group !== "" &&
                <Col>
                    <b>Grupo de analitos:</b> {data.name_analyte_group}
                </Col>
            }
            {
                data.name_type_precoded_text !== null &&
                <Col>
                    <b>Tipo de texto precodificado:</b> {data.name_type_precoded_text}
                </Col>
            }
            {
                data.integers !== null &&
                <Col>
                    <b>Número de dígitos:</b> {data.integers}
                </Col>
            }
            {
                data.decimals !== null &&
                <Col>
                    <b>Número de decimales:</b> {data.decimals}
                </Col>
            }
            {
                data.name_unit !== null &&
                <Col>
                    <b>Unidad:</b> {data.name_unit}
                </Col>
            }
            {
                data.name_unit_to !== null &&
                <Col>
                    <b>Unidad de destino:</b> {data.name_unit_to}
                </Col>
            }
            {
                data.compute !== "" &&
                <Col>
                    <b>Fórmula:</b> {data.compute}
                </Col>
            }
            {
                data.replace !== null &&
                <Col>
                    <b>Reemplazar:</b> {data.replace ? "Si" : "No"}
                </Col>
            }
            {
                data.auto_select !== null &&
                <Col>
                    <b>Auto seleccionar:</b> {data.auto_select ? "Si" : "No"}
                </Col>
            }
            {
                data.loinc_num !== null &&
                <Col>
                    <b>Código internacional:</b> {`${data.component} [${data.loinc_num}]`}
                </Col>
            }
        </Row>
    )
}
