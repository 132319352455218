import React from 'react'
import { IndexCosts } from "../exams/IndexCosts";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container,Col,Row,Button} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


export const CostsProfile = () => {

  const { profilesId } = useParams();
  const history = useHistory();


  return (
    <>
      <Breadcrumb parent="Diseño de examen" title={`Costo de perfil`} />
      <Container className="mt--7" fluid>
        <Col sm="12">
          <Row>
            <Col sm="2">
            <Button color="light" className=" btn-air-light m-4" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
            </Col>
            <Col sm="10">
            <IndexCosts examId={null} profileId={profilesId} />
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  )
}
