import React from 'react'
import { Button, Col, Form, FormGroup, FormText, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { PlaceHolderOption } from '../../constant';
import Select from 'react-select';

export const UpdateRoles = (props) => {

    const {
        toggle, loading, handleSelectValues,
        validaciones, saveUpdatePatient, 
        name, listRol, listRoles
    } = props;

    const {
        listRoles_valid
    } = validaciones;

    return (
        <Form className="form" role="form" onSubmit={saveUpdatePatient}>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12" className="text-center">
                        <p><b>{ name }</b></p>
                    </Col>
                </Row>
                <Row className='m-t-2'>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <FormGroup>
                            <Label>Roles</Label>
                            <Select
                                classNamePrefix='select'
                                name="listRoles"
                                value={listRoles}
                                options={listRol}
                                onChange={(e) => handleSelectValues(e, 'listRoles')}
                                placeholder={PlaceHolderOption}
                                isMulti
                                valid
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                            <FormText color='danger' hidden={!listRoles_valid}>Debes seleccionar al menos una opción</FormText>                            
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type='submit' className={loading ? 'disabled progress-bar-animated progress-bar-striped' : ''} disabled={loading}>
                    Actualizar roles 
                </Button>
                <Button size="sm" className='btn-air-light' outline color='danger' type='button' onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
