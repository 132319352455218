import React from 'react'
import { Form, ModalBody,Button, Col,FormGroup,Label,Input} from 'reactstrap';
import './StyleReturnReason.css';
//datos mostrados en la misma linea de carga de documento 
export const FormSendCSV = (props) => {

    const {
//datos mostrados en la misma linea  accept=".csv" 
       loading,toggle2,file,handleInputChange,handleOnChange,
       methodAction

    }= props;

    return (
        <Form className='' role="form" onSubmit={methodAction}>
            <br /> <br />
            <Col sm="12">
            <FormGroup>
                <Label className="col-sm-6 coll-form-label">{"Seleccione archivo"}</Label>
                <Col sm="12">
                    <Input 
                     //className="form-control"
                     className="form-control form-control-sm input-air-primary" 
                     accept={".csv"}
                     onChange={handleOnChange}
                     type="file"
                    />
                </Col>
            </FormGroup>
            </Col>
           <br /> <br /><br />
           <ModalBody  style={{"text-align": "-webkit-right"}}>
                    <Button
                         outline
                         color="primary"
                         type="submit"
                         className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                   >
                    {"Cargar"}
                    </Button> &nbsp;
                     <Button outline color='danger' type="button" onClick={toggle2}>Cancelar</Button>
                   </ModalBody>
        </Form> 
    )
}
