import React from 'react'
import Select from 'react-select';

import {
    Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Card, Table, CardSubtitle
} from 'reactstrap';

export const UpdateExamImagenology = (props) => {
    const txtsection = localStorage.getItem('TextAS');
    const {
        toggleImagenologia,
        formValues, handleInputChange, validaciones, loading,
        changeSelect, specimens, specimenProvenances, subSections, id_section, id_subsection,
        code, alias, name, abbreviation, individual_sale, is_referenced, is_critic,
        methodAction, handleSelectValues, sections2, number_projections,

        //nuevo_campo,nuevo_campo2

    } = props;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-4">
                        <Label>Clave<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="text"
                            name="code"
                            value={code}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        //invalid={validationCode}
                        />
                        <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label>Alias<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="text"
                            name="alias"
                            value={alias}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        //invalid={validationCode}
                        />
                        <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label>Abreviatura<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="text"
                            name="abbreviation"
                            value={abbreviation}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                        <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label>Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="text"
                            name="name"
                            value={name}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label>{txtsection} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="select"
                            name="id_section"
                            required={true}
                            onChange={changeSelect}
                            value={id_section}
                        >
                            <option value={0}>Selecciona una opción</option>
                            {
                                sections2.length > 0 && sections2.map((s, key) => {
                                    return <option
                                        key={key} value={s.id_section}
                                    >
                                        {s.name}
                                    </option>
                                })
                            }
                        </Input>
                        <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-5">
                        <Label className='p-1'>Subsección</Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="select"
                            name="id_subsection"
                            required={true}
                            onChange={handleInputChange}
                            value={id_subsection}
                        >
                            <option value={0}>Selecciona una opción</option>
                            {
                                subSections.length > 0 && subSections.map((ss, key) => {
                                    return <option
                                        key={key} value={ss.id_subsection}
                                    >
                                        {ss.name}
                                    </option>
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <label className=''>Número de proyecciones<span className='f-16' style={{ color: "#0079C7" }}>*</span></label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            type="text"
                            name="number_projections"
                            value={number_projections}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup className="mb-3 col-3">
                        <Label className='labelInfoAnalyte'>Tiempo de entrega<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            className="form-control form-control-sm input-air-primary"
                            value={formValues.delivery_time}
                            type="text"
                            name="delivery_time"
                            required={false}
                            autoComplete="off"
                            onChange={handleInputChange}
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label>Tipo de muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label> <br />
                        <label className='txt-secondary'>{"Selecciona placa"}</label>

                        <Select
                            value={formValues.id_specimen}
                            classNamePrefix="select"
                            name="id_specimen"
                            placeholder={`Selección de especimen`}
                            isClearable={false}
                            noOptionsMessage={() => "Sin opciones"}
                            options={specimens}
                            onChange={(e) => handleSelectValues(e, "id_specimen")}
                        />
                        <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label>Procedencia de la muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label> <br />
                        <label className='txt-secondary'>Selecciona de humano</label>
                        <Select
                            value={formValues.id_specimen_provenance}
                            classNamePrefix="select"
                            name="id_specimen_provenance"
                            placeholder={`Seleciona la procedencia`}
                            isClearable={false}
                            noOptionsMessage={() => "Sin opciones"}
                            options={specimenProvenances}
                            onChange={(e) => handleSelectValues(e, "id_specimen_provenance")}
                        />
                        <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                    </FormGroup>

                </Row>




                <Row>
                    <FormGroup className="mb-3 col-4 mb-3" style={{ "text-align": "center" }} >
                        <br />
                        <Label>Venta individual</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" name="individual_sale" onChange={handleInputChange} checked={individual_sale} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4 mb-3" style={{ "text-align": "center" }}    >
                        <br />
                        <Label>¿Es referenciado?</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" name="is_referenced" onChange={handleInputChange} checked={is_referenced} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4 mb-3" style={{ "text-align": "center" }}    >
                        <br />
                        <Label>¿Es crítico?</Label>
                        <br />
                        <Label className="switch">
                            <Input type="checkbox" name="is_critic" onChange={handleInputChange} checked={is_critic} />
                            <span className="slider round"></span>
                        </Label>
                    </FormGroup>
                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {"Actualizar"}
                </Button>
                <Button outline color='danger' type="button" onClick={toggleImagenologia}>{"Cancelar"}</Button>

            </ModalFooter>
        </Form>
    )
}
