import React from "react";
import { Button, Card, CardBody, Input } from 'reactstrap';
import { ModalInfoAnlyte } from "../../../components/infoAnalytes/ModalInfoAnlyte";
import { TableStriped } from '../../../components/tables/TableStriped';
import { useInfoAnalytes } from "../../../hooks/analytes/useInfoAnalytes";


export const IndexInfoAnalytes = () => {

    const {
        analytes, method, validaciones,
        name, code, is_interfaced,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearch,
        handleUpdate, saveUpdate
    } = useInfoAnalytes();

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de analitos
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={true}
                            cabeceras={["Nombre", "Clave", "Interfazado"]}
                            items={analytes}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                            {...{ handleUpdate}}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalInfoAnlyte
                {...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, code, is_interfaced,
                    handleInputChange, loading,
                    saveCreate,
                    saveUpdate,
                }}
            />
            <br />
        </>
    );
};
