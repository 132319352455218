import React, { Fragment } from 'react'
import { Card, CardBody, Col, Container, Row, Button,Input } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalTypePrecodedText } from '../../components/typePrecodedTexts/ModalTypePrecodedText';
import { useTypePrecodedTexts } from '../../hooks/typePrecodedTexts/useTypePrecodedText';


export const IndexTypePrecodedTexts = () => {
    const {
        typePrecodedTexts, method,
        name, handleInputChange, validaciones,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete, data,
        arrayPrecod, data2, changeOrder, changeArrayPrecod,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchPrecod

    } = useTypePrecodedTexts();


    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de tipos de texto precodificados
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchPrecod} />
                        </div>
                    </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Nombre"]}
                                        items={typePrecodedTexts}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ModalTypePrecodedText
                    {...{ modal, toggle, loading, method, name, handleInputChange, validaciones, saveCreate, saveUpdate, saveDelete, data, data2, changeOrder, arrayPrecod, changeArrayPrecod }}
                />
            </Container>
        </Fragment>
    )
}
