import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss'
import jwt_decode from "jwt-decode";
import '../../assets/css/loginCaptura.css'
import { useLogin } from '../../hooks/tomaMuestra/useLogin';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { style } from '@mui/system';

export const LoginSeguimiento = (props) => {

    const {
        branches, sections, process: proccesArray,
        id_area, id_departament, userName, password, handleInputChange, handleSelectValues, formValues, card_codes,
        handleViewCard, handleCloseCard, cardView,
        setFocusCard, cardAutoFocus, setSetFocusCard, handleUpdateValues
    } = useLogin();

    // ////console.log(formValues);

    const [loading, setLoading] = useState(false);
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const [loged, setLoged] = useState(false);
    const jwt_token = localStorage.getItem('token');
    const tomaMuestra = localStorage.getItem('tomaMuestra');
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
    const [togglePassword, setTogglePassword] = useState(false)
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (loged === true) {
            window.location.href = `${process.env.PUBLIC_URL}`;
        }
    }, [loged]);

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const txtsection = localStorage.getItem('TextAS');
    const [showText, setShowText] = useState(false);









    const iniciarSesion = async (e) => {
        e.preventDefault();
        setShowText(false);
        setLoading(true);

        let raw = JSON.stringify(formValues);
        ////console.log(formValues);
        // return;
        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Users/LoginTracking");
        if (respuesta.code === 200) {

            if (respuesta.data.msg !== "Éxito") {
                //createSweet("", respuesta.data.msg, "warning");
                createSweet("", "warning", "Alerta", respuesta.data.msg, "warning");

                setLoading(false);

            }
            else {
                let { Name } = jwt_decode(respuesta.data.token);

                localStorage.setItem("token", respuesta.data.token);
                localStorage.setItem("id", respuesta.data.id_user);
                localStorage.setItem("nameUser", Name);
                localStorage.setItem("tomaMuestra", 3);
                localStorage.setItem("branchId", formValues.id_branch);

                let sectionProcess = localStorage.getItem("sectionProcessArray");

                if (sectionProcess != null) {
                    sectionProcess = JSON.parse(sectionProcess);
                    if (sectionProcess.find(x => x.id_section == id_area && x.id_process == id_departament) !== undefined) {
                        let dataInitial = {
                            "id_section": id_area,
                            "id_process": id_departament,
                            "proceso": proccesArray.find(x => x.id_process === parseInt(id_departament)).name,
                            "seccion": sections.find(x => x.id_section === parseInt(id_area)).name,
                            "enable_racks": sections.find(x => x.id_section === parseInt(id_area)).enable_racks ? (proccesArray.find(x => x.id_process === parseInt(id_departament)).is_operative ? true : false) : false,
                            "enable_reception": sections.find(x => x.id_section === parseInt(id_area)).enable_reception
                        };

                        localStorage.setItem("sectionProcessArray", JSON.stringify([dataInitial]));

                        let dataUser = {
                            "name": respuesta.data.username,
                            "id_user": respuesta.data.id_user,
                            "id_section": id_area,
                            "id_process": id_departament
                        }

                        localStorage.setItem("usersArray", JSON.stringify([dataUser]));
                    }
                } else {
                    let dataInitial = {
                        "id_section": id_area,
                        "id_process": id_departament,
                        "proceso": proccesArray.find(x => x.id_process === parseInt(id_departament)).name,
                        "seccion": sections.find(x => x.id_section === parseInt(id_area)).name,
                        "enable_racks": sections.find(x => x.id_section === parseInt(id_area)).enable_racks ? (proccesArray.find(x => x.id_process === parseInt(id_departament)).is_operative ? true : false) : false,
                        "enable_reception": sections.find(x => x.id_section === parseInt(id_area)).enable_reception
                    };

                    let dataUser = {
                        "name": respuesta.data.username,
                        "id_user": respuesta.data.id_user,
                        "id_section": id_area,
                        "id_process": id_departament
                    }

                    localStorage.setItem("usersArray", JSON.stringify([dataUser]));

                    localStorage.setItem("sectionProcessArray", JSON.stringify([dataInitial]));
                }

                setLoged(true);
            }

            handleUpdateValues({
                id_area: "",
                id_departament: "",
                id_branch: "",
                userName: "",
                password: "",
                card_codes: ""
            })




        }
        else {

            handleUpdateValues({
                id_area: "",
                id_departament: "",
                id_branch: "",
                userName: "",
                password: "",
                card_codes: ""
            })


            setShowText(true);
            setLoading(false);
        }
    }

    useEffect(() => {

        if (jwt_token !== null && tomaMuestra !== null) {
            ////console.log(jwt_token, tomaMuestra);
            props.history.replace(`${process.env.PUBLIC_URL}/dashboard/seguimiento`);
        }

    }, [props.history]);

    const [viewTab, setViewTab] = useState(1);

    const navigatePrev = () => {
        let tab = viewTab;

        tab = tab - 1;

        setViewTab(tab);
    }

    const navigateNext = () => {

        let tab = viewTab;

        tab = tab + 1;

        setViewTab(tab);
    }

    function onKeyPressCard(event) {


        if (event.keyCode === 13) {

            let mValue = event.target.value;

            setLoading(true);

            if (mValue.length > 0) {

                iniciarSesion();
            }
            else {
                createSweett("", "Debes de ingresar una credencial valida", "warning");

                handleUpdateValues({
                    id_area: "",
                    id_departament: "",
                    id_branch: "",
                    userName: "",
                    password: "",
                    card_codes: ""
                })


                setSetFocusCard(true);
                setLoading(false);
            }
        }
    }




    return (
        <Container className="login-main-container-toma bg-toma" fluid={true} style={{ backgroundColor: "#ffffff", boxSizing: "border-box" }}>
            <Row>
                <Col className="center-container-toma" xs="12" sm="8" md="12" lg="12" xl="12" style={{ padding: "0px" }}>
                    <div className="login-card-toma p-0">
                        <div className="login-main-toma">
                            <Col sm="12" style={{ textAlign: "center" }}>
                                <img src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LogoHelenLabs2.svg")} alt="LogoDNA" className={cardView ? "imgCardT2" : "imgCartT"} />   <br />   <br />
                            </Col>
                            <Form onSubmit={iniciarSesion}>
                                <Row>
                                    <Col sm="12" style={{ textAlign: "center" }}>
                                        <Label className='pp'>Elige una sección</Label>
                                        <FormGroup>
                                            <select
                                                className="tamañoinput"
                                                style={{ border: "#FFF 2px solid" }}
                                                name="id_area"
                                                onChange={handleInputChange}
                                                value={id_area}
                                            >
                                                <option value={0}>{"Selecciona una opción"} </option>
                                                {
                                                    sections.length >= 1 && sections.map((obj, index) => {
                                                        return <option value={obj.id_section} key={index} >{obj.name}</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                        <Col sm="12" style={{ textAlign: "center" }}>
                                            <Label className='pp'>Elige un proceso</Label>
                                        </Col>
                                        <FormGroup >
                                            <select
                                                className="tamañoinput"
                                                style={{ border: "#FFF 2px solid" }}
                                                name="id_departament"
                                                onChange={handleInputChange}
                                                value={id_departament}
                                            >
                                                <option value={-1}>{"Selecciona una opción"} </option>
                                                {
                                                    proccesArray.length >= 1 && proccesArray.map((obj, index) => {
                                                        return <option value={obj.id_process} key={index} >{obj.name}[{obj.code}]</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                        <Col sm="12" style={{ textAlign: "center" }}>
                                        </Col>
                                        <Label className='pp'>Elige una sucursal</Label>
                                        <FormGroup>
                                            <select
                                                className="tamañoinput"
                                                style={{ border: "#FFF 2px solid" }}
                                                name="id_branch"
                                                onChange={handleInputChange}
                                            // value={id_branch}
                                            >
                                                <option value={0}>{"Selecciona una opción"} </option>
                                                {
                                                    branches.length >= 1 && branches.map((obj, index) => {
                                                        return <option value={obj.id_branch} key={index} >{obj.name}</option>
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                        <FormGroup className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `}>
                                            <input placeholder='tarjeta' className='tamañoinput' style={{ border: "#FFF 2px solid" }} name="card_codes" value={card_codes} onChange={handleInputChange} required="" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${!cardView ? 'm-t-10 animate__animated animate__flipInX' : 'animate__animated animate__flipOutX'}`} hidden={cardView}>
                                    <Col sm="12" style={{ textAlign: "center" }}>
                                        <div style={{ position: 'absolute', left: "-1000", top: "-1000", zIndex: 3 }} className={'ocultar-input'}	>
                                            <input
                                                id='inputFocusCard'
                                                autoFocus={setFocusCard}
                                                value={card_codes}
                                                onChange={(e) => handleSelectValues(e.target.value, "card_codes")}
                                                onKeyDown={onKeyPressCard}
                                                onBlur={cardAutoFocus}
                                            />
                                        </div>
                                        <img className="" src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/CardLogin.gif")} alt="LogoDNA" style={{ width: "15%" }} />   <br />
                                        {/* <p className={`incorrect-loginn ${!showText && "d-none"}`}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo</p> */}
                                        <p className={`incorrect-loginn ${!showText && "d-none"}`}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo y tu sucursal</p>
                                        <br />
                                    </Col>
                                </Row>
                                <Row className={`${cardView ? 'animate__animated animate__flipInX ' : 'd-none'} `} >
                                    <Col sm="12" style={{ textAlign: "center" }} >
                                        <FormGroup className="mb-0">
                                            <Button size="xs" type="submit" color="" className={"tamañoButton" + (loading && " disabled progress-bar-animated progress-bar-striped")} >
                                                <img src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LoginIn.svg")} className="imgFlecha" />
                                            </Button>
                                            <br /> <br />
                                            <p className={`incorrect-loginn ${!showText && "d-none"}`}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo</p>
                                        </FormGroup>


                                    </Col>




                                </Row>

                            </Form>

                        </div>


                        {/* <br /> <br />
                                            <p className={`incorrect-loginn ${!showText && "d-none"}`}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Selecciona tu area de trabajo y tu sucursal</p> */}
                        {/* </FormGroup> */}

                    </div>
                </Col>
            </Row>

            <div className={cardView ? "d-none" : "tap-top-B"} style={{ display: "block" }} onClick={() => handleViewCard()} >
                Inicia Seción Manual
            </div>

            <div className={cardView ? "tap-top-regresar" : "d-none"} style={{ display: "block" }} onClick={() => handleCloseCard()} >
                Regresar

            </div>
        </Container >
    )
}
