import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';
import { usePagination } from '../pagination/usePagination';
import { Badge } from 'reactstrap';
import formatEmail  from '../../utils/validations/formatEmail'


export const useCompanies = () => {

    const history = useHistory();

    useEffect(() => {

        getCompanies();

    }, [history.location]);


    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(0);

    const [states, setStates] = useState([]);
    const [companyTurn, setCompanyTurn] = useState([]);
    const [listTaxRegime, setListTaxRegime] = useState([]);

    const [previewLogo, setPreviewLogo] = useState("");
    const [imageLogo, setImageLogo] = useState("");

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [companiesSearch, setCompaniesSearch] = useState([]);
    const [check_actual_req_es,setCheck_actual_req_es]=useState(false); 

    // ? SEND EMAIL
    const [modalEmail, setModalEmail] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues, handleDinamicInputRFC] = useForm({
        id_company_turn: 0,
        code: "",
        name: "",
        is_maquila: false,
        customer_since: "",
        send_results_pacient: false,
        results_email: "",
        microsite: true,
        password_microsite: "",
        category: 1,
        is_block: 1,
        consecutive_number: false,
        listCompanyBusinessNames: [],
        is_requirements:false,
        requirements:"",
        payment_by_company: true,
        payment_by_patient: false,
        require_invoice: true,
        available_invoice_patient: false,
        credit_days: 0,
        stamp_invoice:false,
        email: ""
    });


    const {
        id_company_turn, code, name, is_maquila,
        customer_since, send_results_pacient,
        results_email, logo, microsite,password_microsite, username, category, is_block, consecutive_number,
        listCompanyBusinessNames,is_requirements,requirements, payment_by_company, payment_by_patient, 
        require_invoice, available_invoice_patient, credit_days,stamp_invoice,
        email
    } = formValues;

    

    const [validaciones, setValidaciones] = useState({
        id_company_turn_valid: false,
        code_valid: false,
        name_valid: false,
        is_maquila_valid: false,
        customer_since_valid: false,
        category_valid: false,
        is_block_valid: false,
        //  microsite_valid: false,
        consecutive_number_valid: false,
        listCompanyBusinessNames_valid: false,
        results_email_valid: false
    });

    const [validacionesEmail, setValidacionesEmail] = useState({
        email_valid: false,
      
    });

    const toggle = () => {
        setModal(!modal);
    }

    // ? SEND EMAIL
    const toggleEmail = () => {
        setModalEmail(!modalEmail);
    }


    const getCompanies = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListCompanies = [];

        const respuesta = await sendRequest(requestOptions, "Company/List");

        if (respuesta.code === 200) {

            let listdocto1 = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value, index) => {
                    //console.log(value);
                    // let arrayComany = value.listCompanyBusinessNames

                    // let arrayCompany = []
                    // arrayComany.forEach((obj,i) =>{
                    //     arrayCompany.push({
                    //         address: obj.address,
                    //         id_company: obj.id_company
                    //     })
                    // });

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    ListCompanies.push({
                        id_company: value.id_company,
                        id_company_turn: value.id_company_turn,
                        code: value.code,
                        name: value.name,
                        is_maquila: value.is_maquila,
                        customer_since: value.customer_since,
                        send_results_pacient: value.send_results_pacient,
                        results_email: value.results_email,
                        logo: value.logo,
                        imgLogo: value.imgLogo,
                        microsite: value.microsite,
                        password_microsite: value.password_microsite,
                        rfc: value.rfc,
                        name_company_turn: value.name_company_turn,
                        category: value.category,
                        label_category: value.category !== null ? (value.category == 1 ? <Badge color='white'> <label className="f-14">A</label> </Badge> : (value.category == 2 ? <Badge color='white'> <label className="f-14" >B</label> </Badge> : <Badge color='white'> <label className="f-14">C</label></Badge>)) : 'Sin categoria',
                        is_block: value.is_block,
                        icon_block: value.is_block !== null ? (value.is_block === 0 ? <Badge color='danger'> <i className="icofont icofont-lock f-14"></i> </Badge> : <Badge color='success'> <i className="fa fa-unlock-alt f-14"></i> </Badge>) : "Sin estatus",
                        id_state: value.id_state,
                        consecutive_number: value.consecutive_number,
                        listCompanyBusinessNames: value.listCompanyBusinessNames,
                        is_requirements:value.is_requirements,
                        requirements:value.requirements,
                        payment_by_company: value.payment_by_company,
                        payment_by_patient: value.payment_by_patient,
                        require_invoice: value.require_invoice,
                        available_invoice_patient: value.available_invoice_patient,
                        credit_days: value.credit_days,
                        stamp_invoice: value.stamp_invoice,
                        visibleTable: visibleTable,
                    })
                });
                setCompanies(ListCompanies);
                setCompaniesSearch(ListCompanies);
                setArrayMunicipality(ListCompanies);

            }
            else {
                //createSweet("error", "warning", "Hubo un error!", "No se encontraron Clientes registrados");
                setCompanies([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCompanies);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);

        }
    }

    const getStates = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let ListStates = [];

        const respuesta = await sendRequest(requestOptions, "State/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach(({ id_state, name }) => {

                    ListStates.push({
                        id_state,
                        name
                    });
                });

                setStates(ListStates);
            }
            else {
                createSweet("error", "warning", "Hubo un error!", "No se encontraron datos para los estados");
                setStates([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getStates);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
        }
    }

    const getCompanyTurn = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListCompanyTurn = [];

        const respuesta = await sendRequest(requestOptions, "CompanyTurn/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach(({ id_company_turn, code, name }) => {
                    ListCompanyTurn.push({
                        id_company_turn,
                        code,
                        name
                    });
                });

                setCompanyTurn(ListCompanyTurn);
            }
            else {
                createSweet("error", "warning", "Hubo un error!", "No se encontraron datos para los giros de compañia");
                setCompanyTurn([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCompanyTurn);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
        }
    }

    const getTaxRegime = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listTaxRegimes = [];

        const respuesta = await sendRequest(requestOptions, "CfdiCatalog/ListTaxRegime/4.0");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach(({ id_tax_regime, code, name }) => {
                    listTaxRegimes.push({
                        value: id_tax_regime,
                        label: "(" + code + ") " + name
                    });
                });

                setListTaxRegime(listTaxRegimes);
            }
            else {
                createSweet("error", "warning", "Hubo un error!", "No se encontraron datos para los giros de compañia");
                setListTaxRegime([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getCompanyTurn);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
        }
    }

    const [arrayMunicipality, setArrayMunicipality] = useState([]);

    const handleChangeState = async (e, nameInput, nameArrayParent) => {
        handleDinamicInputRFC(e, nameInput, nameArrayParent);


        let indiceCompanies = e.target.dataset.id;
        let idState = e.target.value;

        if (idState != "" || idState != 0) {

            let listMunicipalities = await getMunicipality(idState);

            let copy = listCompanyBusinessNames;


            copy[indiceCompanies].listMunicipalities = listMunicipalities;

            handleUpdateValues({
                ...formValues,
                listCompanyBusinessNames: [...copy]
            })
        }
    }

    const getMunicipality = async (id_state) => {

        let requestOptions = {
            method: 'GET'
        };

        let ListMunicipality = [];

        const respuesta = await sendRequest(requestOptions, `Municipality/List/${id_state}`);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach(({ id_municipality, id_state, name }) => {
                    ListMunicipality.push({
                        id_municipality,
                        id_state,
                        name
                    });
                });
            }
            else {
                createSweet("error", "warning", "Hubo un error!", "No se encontraron datos para los municipios");
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMunicipality);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
        }
        return ListMunicipality;
    }

    const imageHandler = (e) => {
        handleInputChange(e);

        let name_input = e.target.name;
        const reader = new FileReader();

        if (name_input === "logo") {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setPreviewLogo(reader.result);
                }
            }
        }

        if (e.target.files.length >= 1) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleChangePaymentBy = (e) => {
        if (e?.target?.value === "2") {
            handleUpdateValues({
                ...formValues,
                payment_by_company: true,
                payment_by_patient: false,
            })
        }
        else if (e?.target?.value === "1") {
            handleUpdateValues({
                ...formValues,
                payment_by_company: false,
                payment_by_patient: true,
            })
        }
        else if (e?.target?.value === "0") {
            handleUpdateValues({
                ...formValues,
                payment_by_company: true,
                payment_by_patient: true,
            })
        }
    }

    const handleCreate = () => {

        handleUpdateValues({
            id_company_turn: 0,
            code: "",
            name: "",
            is_maquila: false,
            customer_since: "",
            send_results_pacient: false,
            results_email: "",
            microsite: true,
            password_microsite: "",
            category: 1,
            is_block: 1,
            consecutive_number: false,
            listCompanyBusinessNames: [{
                id_company_business_name: 0,
                id_company: 0,
                id_state: 0,
                id_municipality: 0,
                id_tax_regime: 0,
                rfc: "",
                business_name: "",
                address: "",
                colony: "",
                cp: "",
                phone: "",
                email: "",
                is_default: true,
                type_person: 2
            }],
            payment_by_company: true,
            payment_by_patient: false,
            require_invoice: true,
            available_invoice_patient: false,
            credit_days: 0,
            stamp_invoice:true
        });

        setValidaciones({
            id_company_turn_valid: false,
            code_valid: false,
            name_valid: false,
            is_maquila_valid: false,
            customer_since_valid: false,
            category_valid: false,
            is_block_valid: false,
            microsite_valid: false,
            consecutive_number_valid: false,
            listCompanyBusinessNames_valid: false
        });

        setCompanyId(0);
        setMethod("create");
        setModalTitle("Registrar un nuevo cliente");
        getStates();
        getCompanyTurn();
        getTaxRegime();
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_company_turn: formValues.id_company_turn,
            code: formValues.code,
            name: formValues.name,
            is_maquila: formValues.is_maquila,
            customer_since: formValues.customer_since,
            send_results_pacient: formValues.send_results_pacient,
            results_email: formValues.results_email,
            microsite: formValues.microsite,
            password_microsite: formValues.password_microsite,
            category: formValues.category,
            is_block: formValues.is_block,
            consecutive_number: formValues.consecutive_number,
            listCompanyBusinessNames: formValues.listCompanyBusinessNames,
            is_requirements:formValues.is_requirements,
            requirements:formValues.requirements,
            payment_by_company: formValues.payment_by_company,
            payment_by_patient: formValues.payment_by_patient,
            require_invoice: formValues.require_invoice,
            available_invoice_patient: formValues.available_invoice_patient,
            credit_days: formValues.credit_days,
            stamp_invoice: formValues.stamp_invoice
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Company/Create");


        if (respuesta.code === 200) {

            let idCompany = respuesta.data.id_company;
            CreateImages(idCompany)

            getCompanies();
            reset();
            createSweet("create", "success", "Exito!", "Cliente creado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
            setLoading(false);

        }
    }

    const CreateImages = async (idCompany) => {

        var formData = new FormData();


        formData.append("logo", formValues.logo);
        formData.append("id_company", idCompany);

        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const respuesta = await sendRequest(requestOptions, "Company/CreateOrUpdateImages", 'multipart/form-data');

        if (respuesta.code === 200) {

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, CreateImages);
            setLoading();
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
            setLoading(false);
        }
    }

    const handleUpdate = (id_company) => {
        let company = companies.find(c => c.id_company === parseInt(id_company));

        if (company !== null) {

            let listCompanies = company.listCompanyBusinessNames;

            listCompanies.forEach(async (obj) => {
                let idState = obj.id_state;
                obj.listMunicipalities = await getMunicipality(idState);
            });

            handleUpdateValues({
                id_company_turn: company.id_company_turn,
                code: company.code,
                name: company.name,
                is_maquila: company.is_maquila,
                customer_since: company.customer_since,
                send_results_pacient: company.send_results_pacient,
                results_email: company.results_email,
                logo: company.logo,
                microsite: company.microsite,
                password_microsite: company.password_microsite,
                category: company.category,
                consecutive_number: company.consecutive_number,
                is_block: company.is_block,
                id_state: company.id_state,
                listCompanyBusinessNames: listCompanies,
                is_requirements:company.is_requirements,
                requirements:company.requirements,
                payment_by_company: company.payment_by_company,
                payment_by_patient: company.payment_by_patient,
                require_invoice: company.require_invoice,
                available_invoice_patient: company.available_invoice_patient,
                credit_days: company.credit_days,
                stamp_invoice: company.stamp_invoice
            });

            setImageLogo(company.imgLogo);

            setValidaciones({
                id_company_turn_valid: false,
                code_valid: false,
                name_valid: false,
                is_maquila_valid: false,
                customer_since_valid: false,
                category_valid: false,
                is_block_valid: false,
                // microsite_valid: false,
                consecutive_number_valid: false,
                listCompanyBusinessNames_valid: false,
                results_email_valid: false
            });

            getStates();
            getCompanyTurn();
            getTaxRegime();
            setCompanyId(id_company);
            setMethod("update");
            setModalTitle("Modificar cliente");
            toggle();
        }
    }

    const saveUpdate = async (e) => {

        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_company: companyId,
            id_company_turn: formValues.id_company_turn,
            code: formValues.code,
            name: formValues.name,
            is_maquila: formValues.is_maquila,
            customer_since: formValues.customer_since,
            send_results_pacient: formValues.send_results_pacient,
            results_email: formValues.results_email,
            microsite: formValues.microsite,
            password_microsite: formValues.password_microsite,
            category: formValues.category,
            is_block: formValues.is_block,
            consecutive_number: formValues.consecutive_number,
            listCompanyBusinessNames: formValues.listCompanyBusinessNames,
            is_requirements:formValues.is_requirements,
            requirements:formValues.requirements,
            payment_by_company: formValues.payment_by_company,
            payment_by_patient: formValues.payment_by_patient,
            require_invoice: formValues.require_invoice,
            available_invoice_patient: formValues.available_invoice_patient,
            credit_days: formValues.credit_days,
            stamp_invoice: formValues.stamp_invoice
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Company/Update");

        if (respuesta.code === 200) {
            CreateImages(companyId);
            getCompanies();
            reset();
            createSweet("create", "info", "Exito!", "Cliente actualizado");
            setLoading(false);
            toggle();

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
            setLoading(false);

        }
    }

    const handleDelete = (id_company) => {

        let company = companies.find(c => c.id_company === parseInt(id_company));

        handleUpdateValues({
            name: company.name,
            code: company.code
        });

        setCompanyId(id_company);
        setMethod("delete");
        setModalTitle("Eliminar cliente");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Company/${companyId}`);

        if (respuesta.code === 200) {
            getCompanies();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Cliente eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Clientes", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Clientes", respuesta.data.msg);
            setLoading(false);

        }
    }

    const handleAddCompany = () => {

        let listBusiness = listCompanyBusinessNames;

        listBusiness.push({
            id_company_business_name: 0,
            id_company: 0,
            id_state: 0,
            id_municipality: 0,
            id_tax_regime: 0,
            rfc: "",
            business_name: "",
            address: "",
            colony: "",
            cp: "",
            phone: "",
            email: "",
            is_default: false
        })

        handleUpdateValues({
            ...formValues,
            listCompanyBusinessNames: [...listBusiness]
        });
    }

    const removeAlterCompanies = (indice) => {

        let copyTest = listCompanyBusinessNames;

        copyTest.splice(indice, 1);

        handleUpdateValues({

            ...formValues,
            listCompanyBusinessNames: [...copyTest]

        });
    }

    // ? SEND EMAIL COMPANY
    const handleSendEmail = (id_company) => {
        
        setCompanyId(id_company);
        toggleEmail()
    }

    const saveEmail = async () => {

        if (validarFormEmail()) {
            return;
        }
        setLoading(true);
        createSweet("create", "success", "", "Enviando......");

        let _form ={
            "id_company": companyId,
            "emails": formValues.email
        }

        let requestOptions = {
             method: 'POST',
             body: JSON.stringify(_form)
         };

        const respuesta = await sendRequest(requestOptions, "Company/SendEmailCompanyAccess");

        if (respuesta.code === 200 ) {
            setLoading(false);
            toggleEmail()
            setCompanyId(0);
            createSweet("create", "success", "Exito!", "Se ha enviado correctamente"); 
            handleUpdateValues({
                ...formValues,
                email:""
            })
        }
        else {
            createSweet("warning", "warning", "Envios!", "No se pudo enviar el correo"); 
            setLoading(false);
            toggleEmail()
            setCompanyId(0);
            handleUpdateValues({
                ...formValues,
                email:""
            })

        } 
    }

    const validarFormEmail = () => {

        let statusValidacion = false;
        let newValidaciones = {};
        if (formValues.email !== "") {

            let _validations = formatEmail(formValues.email);

            if (_validations.status) {  
            createSweet("warning", "warning", "Correo",_validations.message);
            }
            statusValidacion = _validations.status; 
        }
        setValidacionesEmail(newValidaciones);
        return statusValidacion;
    }


    const validarForm = () => {

        let statusValidacion = false;
        let newValidaciones = {};

        const validacion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

        if (formValues.id_company_turn <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_company_turn_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_company_turn_valid: false
            };
        }

        if (formValues.code.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                code_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            }
        }

        if (formValues.name.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }

        if (formValues.customer_since === "") {
            newValidaciones = {
                ...newValidaciones,
                customer_since_valid: true
            };
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                customer_since_valid: false
            };
        }

        if (formValues.send_results_pacient) {

            ////console.log("prueba");

            if (formValues.results_email !== "") {

                if (validacion.test(formValues.results_email)) {

                    newValidaciones = {
                        ...newValidaciones,
                        contact_email_valid: false
                    };
                }
                else {
                    newValidaciones = {
                        ...newValidaciones,
                        contact_email: true
                    };

                    statusValidacion = true;
                }
            }
            else {
                newValidaciones = {
                    ...newValidaciones,
                    results_email_valid: true
                };

                statusValidacion = true;
            }
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                results_email_valid: false
            };
        }

        // if (formValues.microsite === "") {
        //     newValidaciones = {
        //         ...newValidaciones,
        //         microsite_valid: true
        //     };

        //     statusValidacion = true;
        // }
        // else {

        //     newValidaciones = {
        //         ...newValidaciones,
        //         microsite_valid: false
        //     };
        // }

        if (formValues.listCompanyBusinessNames.length === 0) {
            createSweet("error", "warning", "Agrega al menos una razón social");
            statusValidacion = true;
        }
        else {

            formValues.listCompanyBusinessNames.forEach((o, i) => {
                if (o.rfc === "") {
                    createSweet("error", "warning", "Advertencia", `El campo RFC no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.business_name === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Razón social no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;

                    return;
                }

                else if (o.id_state === 0) {
                    createSweet("error", "warning", "Advertencia", `El campo Estado no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.id_municipality === 0) {
                    createSweet("error", "warning", "Advertencia", `El campo RFC no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.id_tax_regime === 0) {
                    createSweet("error", "warning", "Advertencia", `El campo Régimen fiscal no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.address === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Dirección no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.colony === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Colonia no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.cp === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Código postal no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;
                    return;
                }

                else if (o.phone === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Teléfono no puede ir vacio para la posición: ${i + 1}`);

                    statusValidacion = true;

                    return;
                }

                else if (o.email === "") {
                    createSweet("error", "warning", "Advertencia", `El campo Correo electronico no puede ir vacio para la posición: ${i + 1}`);


                    statusValidacion = true;
                    return;
                }
                else if (o.email !== "") {

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                let emails = o.email.split(',');


                for (let email of emails) {
                    email = email.trim(); 
                    if (!emailRegex.test(email)) {
                        createSweet("error", "warning", "Advertencia", `El correo '${email}' no cuenta con una estructura válida.`);
                        statusValidacion = true;
                    }
                }
                    
                }

            });
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(companies.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listcompanies = [];
        companies.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listcompanies.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setCompanies(listcompanies);

    }, [totalPageCount, currentPage]);

    const handleSearchCompany = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        companiesSearch.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setCompanies(newMethod);
    }
    
    const on_check_req_especial=(e)=>{
        if(check_actual_req_es==true){
            setCheck_actual_req_es(false)
            handleUpdateValues({
                ...formValues,
                is_requirements:false,
            })
        }else{
            setCheck_actual_req_es(true)
            handleUpdateValues({
                ...formValues,
                is_requirements: true
            })
        }
        
        
    }

    return {
        companies, states, companyTurn, listTaxRegime,
        method, handleInputChange, handlePickerValues,
        handleSelectValues, validaciones,
        id_company_turn, code, name, is_maquila,
        customer_since, send_results_pacient,
        category, is_block, consecutive_number,
        results_email, logo, microsite,password_microsite, username, imageLogo,
        payment_by_company, payment_by_patient, 
        require_invoice, available_invoice_patient, credit_days,
        toggle, modal, modalTitle, loading,
        handleChangePaymentBy,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleChangeState,
        previewLogo, imageHandler,
        handleAddCompany,
        listCompanyBusinessNames, handleDinamicInputRFC,
        removeAlterCompanies,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearchCompany,
        //new values micro
        is_requirements,requirements,on_check_req_especial,
        stamp_invoice,
        // ? SEND EMAIL
        handleSendEmail,modalEmail,toggleEmail,email,saveEmail,validacionesEmail
    }
}
