import React, { Fragment } from 'react';
//import Loader from '../layoutMicrositio/loader';
import Loader from '../layoutTomaDeMuestra/header';
import Header from '../layoutTomaDeMuestra/header'
import Sidebar from '../layoutTomaDeMuestra/sidebar'
//import Footer from '../layoutMicrositio/footer'
import ThemeCustomize from "../layoutTomaDeMuestra/theme-customizer"
import { ToastContainer } from 'react-toastify'

const AppTomaMuestra = ({ children }) => {
  console.warn = () => { }
  return (
    <Fragment>

      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />

        <Sidebar />

        <div className="page-body">
          {children}
        </div>

        <ThemeCustomize />

      </div>

    </Fragment>
  );
}

export default AppTomaMuestra;