import React, { Fragment,Container,Col,Card } from 'react'
// import {Container} from 'reactstrap';
import './stylePbi.css';




export const IndexView2 = () => {
  return (
    <Fragment>
        <Container>
            <Col sm="12">
                <Card className='shadow' >
                <div style={{ textAlign: "-webkit-center" }} >
					<img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
				    <label className='f-w-600 f-14 badge badge-light-primary'>BI</label>
				</div>

                </Card>
            </Col>

        </Container>


    </Fragment>
  )
}
