import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useAnalyzerInterface = () => {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const [filterAnalyzer, setFilterAnalyzer] = useState(true);

    const [idBranch, setIdBranch] = useState(0);
    const [idMachineModel, setIdMachineModel] = useState(0);
    const [listMachineModels, setListMachineModels] = useState([]);
    const [listBranch, setListBranch] = useState([]);
    const [analyzerInterface, setAnalyzerInterface] = useState([]);
    const [analyzerInterfaceData, setAnalyzerInterfaceData] = useState([]);

    const [idAppCode, setIdAppCode] = useState(0);
    const [modal, setModal] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        appCode: ""
    });

    const { appCode } = formValues;

    const numberItems = 10;

    const {
        totalPageCount, currentPage, nextPage, previousPage, goToPage
    } = usePagination(analyzerInterface.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listAnalyzer = [];

        analyzerInterface.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAnalyzer.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAnalyzerInterface(listAnalyzer)

    }, [totalPageCount, currentPage]);

    useEffect(() => {

        handleGetBranch();

    }, []);

    const toggle = () => {
        setModal(!modal);
    }

    const handleGetBranch = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Branch/List");
        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                let data = [];

                respuesta.data.forEach(obj => {
                    data.push({
                        "id_branch": obj.id_branch,
                        "label": "[ " + obj.code + " ] " + obj.name
                    });
                });

                setListBranch(data);
            }
        }
        else if (respuesta.code === 401) {
            setListBranch([]);
            validarSesion(history, respuesta.code, handleGetBranch);
        }
        else if (respuesta.code === 500) {
            setListBranch([]);
            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");
        }
        else {
            setListBranch([]);
            createSweet("error", "warning", "Sucursales", respuesta.data.msg);
        }
    }

    const handleChangeBranch = (e) => {
        if (e.target.name === 'selectBranch') {
            let value = parseInt(e.target.value);
            setIdBranch(value);

            if (value !== 0) {

                handleGetMachineModel(value);
            }
            else {
                setListMachineModels([]);
            }
        }
        else if (e.target.name === 'selectAnalyzer') {
            let value = parseInt(e.target.value);
            setIdMachineModel(value);
        }
    }

    const handleGetMachineModel = async (id_branch) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, `MachineModel/ListMachineModelByBranch/${id_branch}`);

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {

                let data = [];
                respuesta.data.forEach(obj => {
                    data.push({
                        "id_machine_model": obj.id_machine_model,
                        "label": obj.name_machine_model
                    });
                });

                setListMachineModels(data);
            }
        }
        else if (respuesta.code === 401) {
            setListMachineModels([]);
            validarSesion(history, respuesta.code, handleGetMachineModel);
        }
        else if (respuesta.code === 500) {
            setListMachineModels([]);
            createSweet("error", "error", "Analizadores", "Ocurrio un problema en el servidor");
        }
        else {
            setListMachineModels([]);
            createSweet("error", "warning", "Analizadores", respuesta.data.msg);
        }
    }

    const handleGetAnalyzer = () => {

        let id_branch = parseInt(idBranch);
        let id_machine_model = parseInt(idMachineModel);

        if (id_branch !== 0 && id_machine_model !== 0) {

            getAnalyzerInterface(idBranch, idMachineModel);
        }
        else {
            createSweet("error", "warning", "Advertencia!", "Selecciona una sucursal o un analizador");
        }
    }

    const getAnalyzerInterface = async (idBranch, idMachineModel) => {
        let requestOptions = {
            method: 'GET'
        };

        setLoading(true);

        const respuesta = await sendRequest(requestOptions, `AppCode/ListAppCodesInterface/${idMachineModel}`);
        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                let datos = [];
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;
                startItems = endItems - numberItems;

                respuesta.data.forEach((obj, index) => {
                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    datos.push({
                        "id_app_code": obj.id_app_code,
                        "id_brand": obj.id_brand,
                        "id_info_analyte": obj.id_info_analyte,
                        "app_code": obj.app_code,
                        "code_info_analyte": obj.code_info_analyte,
                        "name_exam": obj.name_exam,
                        "analyte": obj.analyte,
                        "type_result": obj.type_result,
                        "visibleTable": visibleTable
                    });
                });

                setAnalyzerInterface(datos);
                setAnalyzerInterfaceData(datos);
            }
            else {
                setAnalyzerInterface([]);
                setAnalyzerInterfaceData([]);
            }
            setLoading(false);
        }
        else if (respuesta.code === 401) {
            setAnalyzerInterface([]);
            setAnalyzerInterfaceData([]);
            validarSesion(history, respuesta.code, getAnalyzerInterface);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            setAnalyzerInterface([]);
            setAnalyzerInterfaceData([]);
            createSweet("error", "error", "Búsqueda", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            setAnalyzerInterface([]);
            setAnalyzerInterfaceData([]);
            createSweet("error", "warning", "Busqueda", respuesta.data.msg);
            setLoading(false);
        }
    }

    const handleSearchAppCode = (e) => {
        let search = e.target.value;
        let newMethod = [];

        analyzerInterfaceData.forEach(obj => {

            let analyte = obj.analyte.toUpperCase();
            let app_code = obj.app_code.toUpperCase();
            let name_exam = obj.name_exam.toUpperCase();
            // let type_result = obj.type_result.toUpperCase();
            //  let type_result = obj.type_result.toUpperCase();

            if ((analyte.indexOf(search.toUpperCase()) > -1) || (app_code.indexOf(search.toUpperCase()) > -1) || (name_exam.indexOf(search.toUpperCase()) > -1)) {
                obj.visibleTable = true;
                newMethod.push(obj);
            }


            // let cadena = obj.app_code.toUpperCase();

            // if (cadena.indexOf(search.toUpperCase()) > -1) {
            //     obj.visibleTable = true;
            //     newMethod.push(obj);
            // }
        });

        setAnalyzerInterface(newMethod);
    }

    const handleUpdate = (id_app_code) => {
        let appCode = analyzerInterface.find(x => x.id_app_code === parseInt(id_app_code));

        handleUpdateValues({
            appCode: appCode.app_code
        });

        setIdAppCode(id_app_code);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (formValues.appCode.length === 0) {
            setLoading(false);
            return;
        }

        let body = {
            id_app_code: idAppCode,
            id_machine_model: idMachineModel,
            app_code: formValues.appCode
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "AppCode/UpdateAppCode");

        if (respuesta.code === 200) {
            let copy = analyzerInterface;

            let find = analyzerInterface.findIndex(x => x.id_app_code === parseInt(idAppCode));

            if (copy[find] !== undefined) {
                copy[find].app_code = formValues.appCode;

                setAnalyzerInterface([...copy]);
                setAnalyzerInterfaceData([...copy]);
            }

            createSweet("create", "info", "Exito!", "Appcode actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Exámen", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Exámen", respuesta.data.msg);
            setLoading(false);
        }
    }

    return {
        filterAnalyzer, setFilterAnalyzer,
        handleChangeBranch,
        listMachineModels, listBranch,
        analyzerInterface,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleGetAnalyzer,
        loading, handleSearchAppCode,
        toggle, modal, handleUpdate,
        appCode, handleInputChange, saveUpdate
    }


}
