import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';


export const useDoctors = ({ activeTab }) => {

    const history = useHistory();

    const [createSweet] = useSweetAlert();
    const [dataDoctor, setDataDoctor] = useState([]);
    const [doctorId, setDoctorId] = useState(0);

    const [specialties, setSpecialties] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 500);

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [method, setMethod] = useState("");


    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_specialty: 0,
        name: "",
        professional_license: "",
        phone: "",
        address: "",
        email: "",
        name_specialty: "",
        isCommercialPartner: false
    });

    const { id_specialty, name, professional_license, phone, address, email, name_specialty, isCommercialPartner } = formValues;

    const [nameInputs, setNameInputs] = useState({
        name: "name",
        id_specialty: "id_specialty",
        professional_license: "professional_license",
        phone: "phone",
        address: "address",
        email: "email",
        isCommercialPartner: "isCommercialPartner"
    });

    const [validaciones, setValidaciones] = useState({
        id_specialty: false,
        name: false,
    });


    const toggle = () => {
        setModal(!modal);
    }

    //#region pagination
    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])



    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "Doctors/List",
        history,
        searchQuery,
    );


    useEffect(() => {

        let listDoctor = [];

        results.forEach((data, key) => {

            let visibleTable = true;

            listDoctor.push({
                ...data,
                visibleTable: visibleTable
            });
        });
        setDataDoctor(listDoctor);

    }, [results]);


    //#endregion pagination


    //#region Crud Doctor

    const handleGetSpecialties = async () => {
        let listTmp = await handleRequest("GET", `Specialties/List`, 'Especialidades');
        // ////console.log(listTmp);

        if (listTmp !== null && listTmp.length > 0) {
            let listSpecial = [];

            if (listTmp.length >= 1) {
                listTmp.forEach(element => {
                    listSpecial.push({
                        id_specialty: element.id_specialty,
                        name: element.name
                    })
                });
            }
            setSpecialties(listSpecial)
        }
    }

    const isDoctorDuplicated = (newDoctor) => {
        const duplicatedDoctor = dataDoctor.find(doctor => doctor.name.toLowerCase() === newDoctor.name.toLowerCase());
        return duplicatedDoctor !== undefined;
    }
    
    const handleCreate = () => {
        handleUpdateValues({
            id_specialty: 0,
            name: "",
            professional_license: "",
            phone: "",
            address: "",
            email: "",
            name_specialty: "",
            isCommercialPartner: false

        });

        ////console.log(handleUpdateValues);
        setDoctorId(0);
        setMethod("create");
        toggle();
        handleGetSpecialties();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        // if (isDoctorDuplicated(formValues)) {
        //     createSweet("warning", "warning", "Médico Duplicado", "Este médico ya existe en la lista.");
        //     return;
        // }

        setLoading(true);

        let newForm = {
            id_specialty: formValues.id_specialty === "new" ? null : formValues.id_specialty,
            name: formValues.name,
            professional_license: formValues.professional_license,
            phone: formValues.phone,
            address: formValues.address,
            email: formValues.email,
            name_specialty: formValues.name_specialty,
            isCommercialPartner: formValues.isCommercialPartner === true ? 1 : null
        };

        let listTmp = await handleRequest("POST", "Doctors", "Doctores", newForm); 
        ////console.log('listTmp',listTmp)
        if (listTmp !== null) {
            setLoading(false);
            toggle();
            createSweet("create", "success", "Médico creado");
        }else {
            setLoading(false);
        }

    }

    //update doctor
    const handleUpdate = (id_doctor) => {


        let doctor = dataDoctor.find(x => x.id_doctor === parseInt(id_doctor));

        handleUpdateValues({
            id_specialty: doctor.id_specialty,
            name: doctor.name,
            professional_license: doctor.professional_license,
            phone: doctor.phone,
            address: doctor.address,
            email: doctor.email,
            name_specialty: doctor.name_specialty,
            isCommercialPartner: doctor.isCommercialPartner

        });

        setDoctorId(id_doctor);
        setMethod("update");
        handleGetSpecialties();
        toggle();

    }


    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let newForm = {
            id_doctor: doctorId,
            id_specialty: (formValues.id_specialty === "new" ? null : formValues.id_specialty),
            name: formValues.name,
            professional_license: formValues.professional_license,
            phone: formValues.phone,
            address: formValues.address,
            email: formValues.email,
            isCommercialPartner: formValues.isCommercialPartner === true ? 1 : null

        };

        let listTmp = await handleRequest("PUT", "Doctors/Update", "Doctores", newForm);

        if (listTmp !== null) {

            let a = dataDoctor
            let find = a.find(x => x.id_doctor === doctorId);

            let isCommercialPartner = formValues.isCommercialPartner === true ? 1 : null
            let name = formValues.name
            let id_specialty = (formValues.id_specialty === "new" ? null : formValues.id_specialty)

            if (find) {

                find.isCommercialPartner = isCommercialPartner
                find.name = name
                find.id_specialty = id_specialty

                setDataDoctor(
                    a,
                    find
                );
            }

            handleUpdateValues({
                id_specialty: 0,
                name: "",
                professional_license: "",
                phone: "",
                address: "",
                email: "",
                name_specialty: "",
                isCommercialPartner: false
            });

            setLoading(false);
            createSweet("create", "success", "Doctor actualizado correctamente");
            setDoctorId(0);
            handleGetSpecialties();
            toggle();
        }
    }

    //delete Doctor
    const handleDelete = (id_doctor) => {
        let doctor = dataDoctor.find(x => x.id_doctor === parseInt(id_doctor));

        handleUpdateValues({
            name: doctor.name,
        });

        setDoctorId(id_doctor);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let listTmp = await handleRequest("DELETE", `Doctors/${doctorId}`, "Doctores");

        if (listTmp !== null) {

            setLoading(false);
            createSweet("create", "success", "Médico eliminado");
            toggle();
        }
    }
    //beta-pedropp-configCompany

    //#endregion Crud Doctor

    //#region Validar Form

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.id_specialty <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_specialty: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                id_specialty: false
            }
        }

        if (formValues.name.length <= 2) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    //#endregion Validar Form


    //GLOBAL REQUEST

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 409) { // Añadiendo el manejo de error 409
            createSweet("warning", "warning", nameMethod, "Este médico ya existe.");
        } 
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }


    return {
        id_specialty, name, professional_license, phone, address, email, handleInputChange, specialties,
        //pagination
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage, dataDoctor, handleSearch,
        //method
        saveCreate, handleCreate, handleGetSpecialties, toggle, loading, method, modal, nameInputs, name_specialty,
        handleUpdate, saveUpdate, handleDelete, saveDelete, validaciones, isCommercialPartner


    }
}
