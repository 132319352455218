import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
// import { CreateExam } from './CreateExam';
// import { DeleteExam } from './DeleteExam';
// import { UpdateExam } from './UpdateExam';
import { CreateExamMicrobiology } from './CreateExamMicrobiology';
import { DeleteExamMicrobiology } from './DeleteExamMicrobiology';
import { UpdateExamMicrobiology } from './UpdateExamMicrobiology';

export const ModalExamMicrobiology = (props) => {

    const {

        formValues,
        method, handleInputChange, validaciones,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, is_critic,
        toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory,
        changeSelect, handleSelectValues,
        saveCreate,
        saveUpdate,
        saveDelete,
        handleChangePrice,isImagenologyy,use_antibiogram
    } = props;

    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    return (
        <Modal isOpen={modal} toggle={toggle} className={(method === "update") ? "modal-lg" : "modal-lg"} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary" close={closeBtn}>{modalTitle}</ModalHeader>
            {
                method === "create"
                    ? <CreateExamMicrobiology {...{ formValues, toggle, handleInputChange, handleChangePrice, handleSelectValues, validaciones, loading, sections, changeSelect, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports, id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, methodAction: saveCreate ,isImagenologyy,use_antibiogram}} />
                    : (method === "update")
                        ? <UpdateExamMicrobiology {...{ formValues, toggle, handleInputChange, validaciones, loading, sections, handleSelectValues, changeSelect, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, transports, id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, is_referenced, is_critic, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, methodAction: saveUpdate,use_antibiogram }} />
                        : <DeleteExamMicrobiology  {...{ toggle, loading, code, alias, name, saveDelete }} />
            }
        </Modal>
    )
}
