import React from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ModalAnalytes = (props) => {

    const {
        toggleModal: toggle, modal, nameAnalyte, loadingModal, deleteAnalyte
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle}>
                Eliminar analito
            </ModalHeader>
            <Form role="form" onSubmit={deleteAnalyte}>
                <ModalBody>
                    <h6>Estas a punto de eliminar el analito: <i>{nameAnalyte}</i></h6>
                        <br />
                        <p>Esta acción es irreversible y eliminara el analito definitivamente</p>
                        <b>¿Estás seguro de hacerlo?</b>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className={loadingModal ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                        Si,eliminar analito
                    </Button>
                    <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}