import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormInvoiceCompany } from './FormInvoiceCompany';


export const ModalInvoiceCompany = (props) => {

    const {
     

        toggle, handleCreateInvoice, method, modal,saveCreate,examenes,loading
        ,examProfileTest,examProfile
    }= props;

    return (
        <Modal isOpen={modal} toggle={toggle}  backdrop='static' keyboard={false} centered={true}>
        <ModalHeader toggle={toggle}  className="bg-primary">
            {"Facturar"}
        </ModalHeader>
        {
            method === "list"
                ? <FormInvoiceCompany
                    button="Guardar"
                    {...{ toggle, loading,examProfileTest,examProfile,  methodAction: saveCreate}}
                />
                : 
                ""                    
                    
        }
    </Modal>
       
    )
}
