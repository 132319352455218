import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router';
import { useForm } from '../../hooks/forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';

export const useSurvey = () => {

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setMo3dalTitle] = useState("");

    const [createSweet] = useSweetAlert();

    const [listQuestión, setListQuestión] = useState({})

    const history = useHistory();

//samplingservice
    const getListPaintQuestion = async () => {


        let requestOptions = {
            method: 'GET'
        };


        const respuesta = await sendRequest(requestOptions, "SatisfactionQuestion/ListPaintQuestion");

        if (respuesta.code === 200) {
            setListQuestión(respuesta.data)
        }
        
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getListPaintQuestion);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Toma de Muestra", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Toma de Muestra", respuesta.data.msg);

        }
    }

//capitulo 15 ver 
    
    const navigateSatisfaction = () => {
        history.push(`../satisfaction/consentimiento`);
            //toma/consentimiento/:methodd

    }
   




  return {
    method,modal,modalTitle,navigateSatisfaction


  }
}
