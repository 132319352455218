import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useSubsections } from '../../hooks/subsections/useSubsections';
import { RibbonSubsecciones } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalSubsections } from '../../components/subsections/ModalSubsections';

export const IndexSubsections = () => {

    const {
        subsections, sections, method, validaciones,
        id_section, name, abbreviation,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    } = useSubsections();

    const txtsection = localStorage.getItem('TextAS');
    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        {RibbonSubsecciones}
                    </div> <br />
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button>
                    </div> <br />
                    <div className="table-responsive">
                        {
                            subsections.length === 0 ?
                            <div style={{ textAlign: "-webkit-center" }}>
                            <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "26%" }} alt="" />   <br />                                         
                            <br /> <p className="f-18">{"No se a registrado una Subsección aún."}</p>
                            </div>
                            :
                             <TableStriped
                            notMaped={["id_section"]}                            
                            methodsActions={true}
                            cabeceras={[{txtsection},"Subsección", "Abreviación"]}
                            items={subsections}
                            {...{handleUpdate, handleDelete}}
                        /> 
                        }                        
                    </div>
                </CardBody>
            </Card>  
            <ModalSubsections
                {
                    ...{
                        sections,
                        modal, modalTitle, toggle, method,
                        validaciones, 
                        id_section, name, abbreviation,
                        handleInputChange, loading,
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
