import React from 'react'
import Select from 'react-select';
import { Form, ModalBody, Row, Col, ModalFooter, Button, Label, Input, FormFeedback, FormGroup } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"

export const FormCard = (props) => {
    let {
        toggleCard, loading, methodAction, button, typeCard = "typeCard", checkbox, check,
        handleCheckboxChange, handleInputChangeT, id_departament, departament,
        handleSectionUser, section, changeTree, treeviewRef, tree, onRenderItem, processes,
        saveCardCode, handleListSectionsCard, card_code, handleInputChange, checkSection,
        handleCheckChange, listDepartament, handleSelectValues, formUser, id_departaments,
        listDepa
    } = (props)
    tree = section

    //console.log(formUser)   

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row md="12">
                    <Col md="4" className="d-flex justify-content-center">
                        <i className="icofont icofont-card f-70" style={{ color: '#305575', animation: 'spin 2s linear infinite' }}></i>
                    </Col>
                    <Col md="4">
                        <Label className="col-form-label">Código: <span className='f-20' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input type="text" autoComplete="off" className="form-control form-control-sm input-air-primary"
                            name="card_code" value={card_code} onChange={handleInputChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <FormFeedback>Debes llenar este campo</FormFeedback>
                    </Col>
                    {/* <Col md="4">
                        <div className="custom-control custom-radio custom-control-inline">
                            <Input type="checkbox" className="custom-control-input" id="defaultInline1" name="inlineDefaultRadiosExample" onChange={handleCheckboxChange} />
                            <Label className="custom-control-label" for="defaultInline1">Mostrar</Label>
                        </div>
                    </Col> */}
                </Row>
                <br />

                <Row md={12}>
                    <Col md="6">
                        <Label className="col-form-label">Departamento: <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                    </Col>
                    {/* <Col md="6">
                        <FormGroup>
                            <Input
                                type='select'
                                placeholder="Buscar dto."
                                name="id_departament"
                                onChange={handleInputChangeT}
                                value={id_departament}
                                className="form-control form-control-sm input-air-primary">
                                <option value={0}>{"Selecciona una opción"} </option>
                                {
                                    departament.length >= 1 && departament.map((obj, index) => {
                                        return <option value={obj.id_departament} key={index}> {obj.name}[{obj.id_departament} - {obj.id_area}]</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    
                    </Col> */}
                    
                    <Col sm = "12">
                        <Select
                            isMulti
                            name="id_departaments"
                            value= {id_departaments}
                            //onChange={handleInputChangeT}
                            onChange={(e) => handleSelectValues(e, "id_departaments")}
                            options={listDepartament}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                            onBlur={(e) => {
                                if (!e.target.value) {
                                  // Realizar alguna acción si se recibe un dato nulo
                                  ////console.log("¡Se recibió un dato nulo!");
                                }
                            }}
                            
                        />
                    </Col>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Col className="col-md-12">
                    <br />
                        <Label className="col-form-label">Área - Sección:</Label>
                        {
                            section && section.map(element => (
                                <FormGroup key={element.id_section}>
                                    <Label className='switch'>
                                        <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={element.checkSection}
                                            //onChange={(e) => handleCheckChange(e)}
                                            onChange={(e) => handleCheckChange(e, element.id_section)}
                                        />
                                        <span className="slider round"></span>
                                        <Col md={4}>
                                            <p style={{ whiteSpace: 'normal', margin: 0 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element.name}</p>
                                        </Col>
                                    </Label>
                                </FormGroup>
                            ))
                        }
                    </Col>

                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} disabled={loading}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggleCard}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}