import React from 'react'
import { Button, Card, CardBody } from 'reactstrap';
import { ModalArea } from '../../components/areas/ModalArea';
import { TableStriped } from '../../components/tables/TableStriped';
import { useArea } from '../../hooks/areas/useArea';

export const IndexAreas = () => {

    const {
        areas, method, validaciones,
        name, id_external,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage
    } = useArea();

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de areas
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="table-responsive"> <br />
                        <TableStriped
                            notMaped={["visibleTable"]}
                            methodsActions={true}
                            cabeceras={["ID exterior", "Nombre"]}
                            items={areas}
                            {...{ handleUpdate, handleDelete }}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalArea
                {...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, id_external,
                    handleInputChange, loading,
                    saveCreate, saveUpdate, saveDelete
                }}
            />
        </>
    )
}
