import { useState } from "react"


export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);
 
    const reset = () => {
        setValues(initialState);
    }


    const handleInputChange = ({ target }) => {
        let mValue = "";

        if (target.type === 'checkbox') {
            mValue = target.checked;
        }
        else if (target.type === 'file') {
            mValue = target.files[0];
        }
        else {
            mValue = target.value;
        }

        setValues({
            ...values,
            [target.name]: mValue
        });
    }


    const handleUpdateValues = (valores) => {
        setValues(valores);
    }

    const handleSelectValues = (e, nombre) => {
   //      ////console.log("pasa por aca?", e, nombre);
        setValues({
            ...values,
            [nombre]: e
        });
    }

    const handlePickerValues = (e, nombre) => {
        
        setValues({
            ...values,
            [nombre]: e
        });
    }



    const handleDinamicInput = (e, nameInput, nameArrayParent, nameArrayChildren = "", idArrayParent = "", idArrayChildren = "") => {

        const _tempPayInputMethod = [...values[nameArrayParent]];

        if (nameArrayChildren !== "") {


            if (idArrayParent !== "" && idArrayChildren !== "") {

                _tempPayInputMethod[idArrayParent][nameArrayChildren][idArrayChildren][nameInput] = e;
            }
            else {
                let children = e.target.dataset.idchildren;

                _tempPayInputMethod[e.target.dataset.id][nameArrayChildren][children][nameInput] = e.target.value;

            }

        }
        else {

            if (e.target.type === 'radio') {
                _tempPayInputMethod[e.target.dataset.id][nameInput] = e.target.checked;
            }
            else {
                _tempPayInputMethod[e.target.dataset.id][nameInput] = e.target.value;
            }
        }


        setValues({
            ...values,
            [nameArrayParent]: _tempPayInputMethod
        });
    }

    const handleDate = (e, nombre) => {
        setValues({
            ...values,
            [nombre]: e.target.value
        });
        ////console.log(...values);
    }


    // const handledate = (e, nombre) => {
    //     setValues({
    //         ...values,
    //         [nombre]: e.target.value
    //     });
    // }

    return [values, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues, handleDinamicInput, handleDate];

}
