import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { ModalCreatePayMethod, ModalUpdatePayMethod, ModalDeletePayMethod } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const usePayMethods = () => {

    const history = useHistory();

    const [payMethods, setPayMethods] = useState([]);
    const [idPayMethod, setIdPayMethod] = useState(0);

    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        abbreviation: ""
    });

    const { name, abbreviation } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        abbreviation_valid: false
    });

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(payMethods.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listPay = [];
        payMethods.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listPay.push({
                ...data,
                visibleTable: visibleTable


            });
        });

        setPayMethods(listPay);
    }, [totalPageCount, currentPage])




    const toggle = () => {
        setModal(!modal);
    }

    const getPayMethods = useCallback(
        async () => {
            let requestOptions = {
                method: 'GET'
            };

            let listPayMethods = [];

            const respuesta = await sendRequest(requestOptions, "PayMethod/List");

            if (respuesta.code === 200) {

                if (respuesta.data.length >= 1) {

                    let startItems = 0;
                    let endItems = numberItems;
                    let aux = numberItems * currentPage;

                    endItems = aux;

                    startItems = endItems - numberItems;

                    respuesta.data.forEach((obj, index) => {

                        let posicion = index + 1;
                        let visibleTable = false;

                        if (posicion > startItems && posicion <= endItems) {
                            visibleTable = true;
                        }

//to
                        listPayMethods.push({
                            id_pay_method: obj.id_pay_method,
                            name: obj.name,
                            abbreviation: obj.abbreviation,
                            is_interfaced: obj.true,
                            visibleTable: visibleTable
                        });
                    });

                    setPayMethods(listPayMethods);
                    setPayMethodData(listPayMethods);
                }
                else {
                    setPayMethods([]);
                }
            }
            else {
                validarSesion(history, respuesta.code, getPayMethods);
            }
        }, [history]
    )

    useEffect(() => {
        getPayMethods();
    }, [getPayMethods])

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation: ""
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setIdPayMethod(0);
        setMethod("create");
        setModalTitle(ModalCreatePayMethod);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let data = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "PayMethod/Create");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se creo correctamente");

            toggle();
            getPayMethods();
            handleUpdateValues({
                name: "",
                abbreviation: ""
            });
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Método de pago", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Método de pago", respuesta.data.msg);
            setLoading(false);
        }

    }

    const handleUpdate = (id_pay_method) => {
        let payMethod = payMethods.find(p => p.id_pay_method === parseInt(id_pay_method));

        handleUpdateValues({
            name: payMethod.name,
            abbreviation: payMethod.abbreviation
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setIdPayMethod(id_pay_method);
        setMethod("update");
        setModalTitle(ModalUpdatePayMethod);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let mValues = {
            id_pay_method: idPayMethod,
            ...{ ...formValues }
        };

        let data = JSON.stringify(mValues);
        let requestOptions = {
            method: 'PUT',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "PayMethod/Update");

        if (respuesta.code === 200) {
            getPayMethods();
            createSweet("update", "info", "Exito!", "Se actualizo el método de pago");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Método de pago", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Método de pago", respuesta.data.msg);
        }
    }

    const handleDelete = (id_pay_method) => {
        let payMethod = payMethods.find(p => p.id_pay_method === parseInt(id_pay_method));

        handleUpdateValues({
            name: payMethod.name,
            abbreviation: payMethod.abbreviation
        });

        setIdPayMethod(id_pay_method);
        setMethod("delete");
        setModalTitle(ModalDeletePayMethod);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `PayMethod/${idPayMethod}`);
        if (respuesta.code === 200) {
            getPayMethods();
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        }
        else {
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }

        if (formValues.abbreviation === "" || formValues.abbreviation.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: false
            };
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    const [payMethodData, setPayMethodData] = useState([]) ;

    const handleSearchPay = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        payMethodData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setPayMethods(newMethod);
    }

    return {
        payMethods, method,
        name, abbreviation, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchPay

    }
}   
