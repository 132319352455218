import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, CardBody, Container, Row, Col, Button } from 'reactstrap';
import { usePrinterPoints } from '../../../hooks/printerPoints/usePrinterPoints';
import { PrinterPointList } from '../../../constant';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalPrinterPoint } from '../../../components/printerPoints/ModalPrinterPoint';
import { ListPrinter } from './ListPrinter';

export const IndexPrinterPoints = () => {

    const {
        printerPoints, method,
        name, username, password, password_confirmation, handleInputChange, validaciones,
        toggle, modal, modalTitle ,loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    } = usePrinterPoints();


    let filter = ["namePrinter"];

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        {/* <Card> */}
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {PrinterPointList}
                                </div> <br />
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div> <br />
                                <Col sm="12">
                                    <ListPrinter
                                     filterProps={filter}
                                     type="list"
                                     inputFiter={true}
                                     items={printerPoints}
                                     {...{ handleUpdate, handleDelete }}
                                    />
                                </Col>
                            </CardBody>
                        {/* </Card> */}
                    </Col>
                </Row>
            </Container>
            <ModalPrinterPoint
                {
                    ...{
                        modal, modalTitle, toggle, method, loading,
                        name, username, password, password_confirmation, handleInputChange, validaciones,
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </Fragment>
    )
}
