import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteCurveTime } from './DeleteCurveTime';
import { FormCurveTimes } from './FormCurveTimes';

export const ModalCurveTimes = (props) => {

    const {
        modal, modalTitle, toggle, method, loading, 
        name, handleInputChange, validations,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ?
                        <FormCurveTimes button={"Guardar"} {...{ toggle, loading, handleInputChange, validations, methodAction: saveCreate }} />
                    :
                        (method === 'update')
                            ? 
                                <FormCurveTimes button={"Actualizar tiempo"} {...{ toggle, loading, handleInputChange, validations, methodAction: saveUpdate, name }} />
                            : <DeleteCurveTime {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )

}
