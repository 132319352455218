import React from 'react'
import { useSatisfactionQuestion } from '../../../../hooks/tomaMuestra/useSatisfactionQuestion'
import { Container, Row, Col, Label, Card } from 'reactstrap';
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/cartaConsentimiento.css'
import { ComponentQuestin } from './ComponentQuestin';

export const IndexSatisfactionQuestion = () => {

  const {
    dinamica, navigateNext, navigatePrev, saveQuestion, questionOpen, showAlternativeQ

  } = useSatisfactionQuestion();



  return (
    <div> <br /> <br />
      <Col xs="12" sm="12" md="12" lg="12" xl="12" >
        <Row>
          <Col xs="3" sm="3" md="3" lg="3" xl="3">
          </Col>
          <Col xs="9" sm="9" md="9" lg="9" xl="9">
            <Card>
              <ComponentQuestin
                items={dinamica}
                {
                ...{ navigateNext, navigatePrev, saveQuestion, questionOpen, showAlternativeQ }
                }
              />

            </Card>

          </Col>
        </Row>
      </Col>

    </div>


  )
}
