import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { CreateExamImagenology } from './CreateExamImagenology'
import { UpdateExamImagenology } from './UpdateExamImagenology';
import { DeleteExam } from './DeleteExam';


export const ModalExamImagenology = (props) => {

  const {
    methodImagenolofia,titleImagenologia,modalImagenologia,toggleImagenologia,
    formValues, method, handleInputChange, validaciones, handleSelectValues,
    sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
    id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
    toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
    changeSelect,saveCreate,handleChangePrice ,saveDelete,sections2,number_projections,enable_analyte_configuration,
    isImagenologyy,handleUpdateImagenology,saveUpdate,examEditIm
    //nuevo_campo,nuevo_campo2
  } = props;

  const closeBtn = <button className="close" onClick={toggleImagenologia}>&times;</button>;

  return (
    <Modal isOpen={modalImagenologia} toggle={toggleImagenologia} className={(methodImagenolofia === "updateImagen") ? "modal-lg" : "modal-lg"} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleImagenologia} className="bg-primary" close={closeBtn}>{titleImagenologia}</ModalHeader>
            {
                methodImagenolofia === "createImagen"
                    ? <CreateExamImagenology {...{ toggleImagenologia,
                      formValues, method, handleInputChange, validaciones, handleSelectValues,
                      sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
                      id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
                      toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
                      changeSelect,
                      handleChangePrice,sections2,number_projections,
                      methodAction: saveCreate ,enable_analyte_configuration,
                      isImagenologyy,examEditIm
                      //saveCreate
                      //nuevo_campo,nuevo_campo2
                     }}
                      />
                    : (methodImagenolofia === "updateImagen")
                    ? <UpdateExamImagenology {...{toggleImagenologia,
                      formValues, method, handleInputChange, validaciones, handleSelectValues,
                      sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
                      id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced,
                      toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, is_critic,
                      changeSelect,
                      saveCreate, handleChangePrice,sections2,number_projections,
                      methodAction: saveUpdate
                      //nuevo_campo,nuevo_campo2
                    }}
                       />
                    :
                    <DeleteExam {...{ toggle, loading, code, alias, name, saveDelete }}s
                    />
            }
        </Modal>
  )
}
