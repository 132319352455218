import React from 'react'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

export const ModalDeleteTest = (props) => {

    const {
           //* MODAL DELETE TEST
           modalCancellation,toggleModalCancellation,modalTitle,informationExams,handleDeleteTest,loading
    } = props;



    return (
        <Modal isOpen={modalCancellation} toggle={toggleModalCancellation} centered={true}>
            <ModalHeader toggle={toggleModalCancellation} className='bg-danger'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                       <b className='f-14 '>Estas seguro de eliminar el siguiente estudio:</b>
                    </Col>
                  <br />
                    <Col sm="12" md="12" lg="12"> <br />
                        {
                            informationExams.price !== undefined && <>
                                <Row className='m-b-1'>
                                    <Col sm="8" md="8" lg="8">
                                        <Label><b>Nombre:</b></Label>
                                        <p>{ informationExams.name }</p>
                                    </Col>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Precio</b></Label>
                                        <p>{ informationExams.price }</p>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='danger' type='button' disabled={loading} className={ loading ? "disabled progress-bar-animated progress-bar-striped" : "" } onClick={() => handleDeleteTest(informationExams.id_agreement_test)}> 
                    Aceptar
                </Button>
                <Button size='sm' className='btn-air-ligth' outline type='button' onClick={toggleModalCancellation}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}