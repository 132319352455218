import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormInfoAnalyte } from './FormInfoAnalyte';
import { DeleteInfoAnalyte } from './DeleteInfoAnalyte';

export const ModalInfoAnlyte = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, code, is_interfaced,
        handleInputChange, loading,
        saveCreate, saveUpdate, is_analyte = true
    } = props;

    return <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle} className="bg-primary f-12">
            {modalTitle}
        </ModalHeader>
        {
            method === "create"
                ? <FormInfoAnalyte
                    button={is_analyte ? "Crear analito" : "Crear caracteristica"}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, name, code, is_interfaced, is_analyte, method }}
                />
                : (method === "update")
                    ? <FormInfoAnalyte
                        button={is_analyte ? "Editar analito" : "Editar caracteristica"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, code, is_interfaced, is_analyte, method }}
                    />
                    : <DeleteInfoAnalyte
                    />
        }
    </Modal>
};
