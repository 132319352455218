import React from 'react'
import {Button, Modal, ModalBody, CardBody, Table,Col, Form, Input, FormFeedback,Row } from 'reactstrap';

export const ModalPreviewTicket = (props) => {

    const {modalPrint,toggleModalPrint,dataHistoric,printTicket,loadingPrint} = props;

    
    return (
        <Modal isOpen={modalPrint} toggle={toggleModalPrint} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    {
                        <>
                        <Col sm="12">
                        <Row>
                            <Col sm="12" className='p-l-0 p-r-0' >
                            <label className='f-14 f-w-600'>  Reimprimir recibos</label>
                            <div className='hr-detail-patient' >
                           </div> <br /> 
                            </Col>
                            <div className='table-wrapper'>
                            <Table className='b-r-5 table table-sm'>
                                <thead className='b-r-5'>
                                    <tr style={{ backgroundColor: "#033d5b" }}>
                                        <th className='labeltheadInvoice' style={{textAlign:"center"}} ></th>
                                        <th className='labeltheadInvoice' style={{textAlign:"center"}} >Fecha</th>
                                        <th className='labeltheadInvoice' style={{textAlign:"center"}} >Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loadingPrint && <tr>
                                        <td colSpan={3} className='bg-info text-center f-16'>
                                            <i className="fa fa-spin fa-spinner"></i> Cargando...
                                        </td>
                                        </tr>
                                    }
                                    {!loadingPrint && dataHistoric?.map((_find, _key) => {
                                        return (
                                            <tr key={_key}  >
                                            <td style={{ textAlign: "center" }}>
                                            <img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/price/impresora.png")} style={{ width: "23px", height: "23px" }} />
                                            </td>
                                            <td style={{ textAlign: "center" }}> <label className='f-w-600 f-12'>{_find?.fech ?? "----"}</label> </td>
                                            <td style={{textAlign:"center"}}><Button outline color='dark' size='xs' onClick={() => printTicket(_find.id_cash_flow,true)} >Descargar</Button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>

                   </div>
                        </Row>
                        </Col>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                       
                        <Row>
                            <Col sm="8">
                            </Col>
                            <Col sm="3">
                            <Button color="danger text-center" type="button" onClick={toggleModalPrint}>{"Cancelar"}</Button>
                            </Col>
                        </Row>
                            
                        </Col>
                        </>
                    }
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
