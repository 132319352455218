export const ExportInvoiceDefaultValues = ({
    
    defaultIdCoin: 2,
    defaultTypeReleationship: {
        value: 1,
        label: '01 NOTA DE CRÉDITO DE LOS DOCUMENTOS RELACIONADOS',
        code: '01'
    },
    defaultEgreesClaveProdServ: '84111506',
    defaultCodeUnitEgrees: 'ACT',
    defaultConcepEgrees: 'Devoluciones, descuentos, bonificaciones por estudios de laboratorio',
    tableHeaderEgress: {
        thFolio: 'Folio',
        thDate: 'Fecha',
        thCoin: 'Moneda',
        thPayMethod: 'Forma de pago',
        thUuid: 'UUID',
        thTotal: 'Total',
        thPaid: '¿Está pagada?'
    },
    defaultCodePayMethod: "PUE",
    defaultPayMethod: "(PUE) PAGO EN UNA SOLA EXHIBICIÓN"
});