import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';
import { ModalButtonCancel, ModalButtonDelete } from '../../constant';

export const DeleteAgreement = (props) => {
    
    const { toggle, loading, name_company, contract_number, validity, saveDelete } = props;
 
    return (
        <Form className="theme-form" role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el siguiente convenio:</p>
                <b>{ `${name_company}` }</b>
                <p>{ `Con número de contrato: ${ contract_number }, con vigencia: ${ validity }` }</p>
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {ModalButtonDelete}
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>{ ModalButtonCancel }</Button>
            </ModalFooter>
        </Form>
    )
}
