import React, { useState, useEffect } from 'react'
import { sendRequest } from '../requests/useRequest'
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const usePaginationBackend = (requestOptions, url, history, searchQuery = "", filters = null) => {
    const [totalRecords, setTotalRecords] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [prevPage, setPrevPage] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [resultCount, setResultCount] = useState(0);
    const [results, setResults] = useState([]);
    const [createSweet] = useSweetAlert();
    const [dataA, setDataA] = useState([]);

    const getData = async (requestOptions, url) => { 
             
        const respuesta = await sendRequest(requestOptions, `${url}?EnablePagination=true&Page=${actualPage}&Size=10${searchQuery.length > 0 ? `&search=${searchQuery}` : ""}${filters !== null ? filters : ""}`);
        if (respuesta.code === 200) {
         
            let data = respuesta.data;
            setTotalRecords(data.total_records);
            setTotalPages(data.total_pages);
            setPrevPage(data.prev_page);
            setNextPage(data.next_page);
            setResultCount(data.result_count);
            setResults(data.results);
            setDataA(data)
        } else {
            validarSesion(history, respuesta.code, getData)
        }
    }
   

    const goNextPage = () => {
        let actual_pagina = actualPage;
        actual_pagina++;
        setActualPage(actual_pagina);
    }

    const goPreviousPage = () => {
        let actual_pagina = actualPage;
        actual_pagina--;
        setActualPage(actual_pagina);
    }

    const goToPage = (page) => {
        setActualPage(page);
    }

    const searchAsync = () => {
        getData(requestOptions, url);
    }

    useEffect(() => {
        getData(requestOptions, url);
    }, [])

    useEffect(() => {
        getData(requestOptions, url);
    }, [actualPage])

    useEffect(() => {
        if (actualPage > totalPages) {
            goToPage(totalPages);
        }
    }, [totalPages])



    return {
        goNextPage, goPreviousPage, goToPage, searchAsync,
        totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results,dataA
    }
}
