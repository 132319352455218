

import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, CardBody,Input} from 'reactstrap';
import { useSeller } from '../../../hooks/seller/useSeller';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalSeller } from './ModalSeller';



export const IndexSeller = () => {

  const {
    name,paternal_surname,maternal_surname,number_phone,email,sellerD,handleInputChange,handleCreate,
    method,modalTitle,loading,toggle,modal,
    totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchSeller,
    saveCreate,handleUpdate,saveUpdate,handleDelete,saveDelete,validaciones

  } = useSeller();


  return (
    <Fragment>
      <Container fluid= {true}>
        <Row>
          <Col sm="12">
              <Card>
                <CardBody>
                  <div className="ribbon ribbon-clip ribbon-primary">
                    {"Vendedores"}
                  </div>
                  <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate} ><span className="fa fa-plus-circle"></span></Button>
                  </div>
                  <div className="row">
                  <div className="offset-8 col-4 pt-1 pb-2">
                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSeller}  />
                  </div>
                </div> <br />
                <div className="table-responsive">
                  {
                    sellerD.length === 0 ?
                      <div style={{ textAlign: "-webkit-center" }}>
                      <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                      <br /> <p className="f-18">{"No se ha registrado un vendedor aún."}</p>
                      </div>
                      :
                      <TableStriped
                      notMaped={[ "visibleTable"]}
                      methodsActions={true}
                      cabeceras={["Nombre", "Apellido Paterno", "Apellido Materno", "Telefono", "Correo Electrónico"]}
                      items={sellerD}
                     {...{ handleUpdate,handleDelete}}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      totalPageCount={totalPageCount}
                      currentPage={currentPage}
                      goToPage={goToPage}
                  />
                  }
                  </div>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </Container>
      <ModalSeller
          {
            ...{
                modal, toggle, modalTitle, method, loading,
                name,paternal_surname,maternal_surname,number_phone,email,handleInputChange,
                saveCreate,saveUpdate,saveDelete,validaciones
              }
            }

       />

    </Fragment>
  )
}
