import React from 'react'
import { Container,Col,Row,Button} from 'reactstrap';
import Breadcrumb from "../../../layout/breadcrumb";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IndexGlobalReferenceValues } from './IndexGlobalReferenceValues';

export const GlobalReferenceValues = () => {

    const { profilesId } = useParams();
    const history = useHistory();

    return (
        <>
            <Breadcrumb parent="Diseño de Perfil" title={`Valores de referencia del Perfil`} />
            <Container className="mt--7" fluid>
                <Col sm="12">
                    <Row>
                        <Col sm="2">
                            <Button color="light" className=" btn-air-light m-4" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                        </Col>
                        <Col sm="10">
                            <IndexGlobalReferenceValues examId={null} profileId={profilesId} />
                        </Col>
                    </Row>
                </Col>
            </Container>
        </>
    )
}