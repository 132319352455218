import React, { Fragment } from 'react';
import Loader from '../layoutMicrositio/loader';
import Header from '../layoutMicrositio/header'
import Sidebar from '../layoutMicrositio/sidebar'
//import Footer from '../layoutMicrositio/footer'
import ThemeCustomize from "../layoutMicrositio/theme-customizer"
import { ToastContainer } from 'react-toastify'

const AppMicrositio = ({ children }) => {
  console.warn = () => { }
  return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header />
        
        {/* <div className="page-body-wrapper sidebar-icon"> */}
        <Sidebar />
        
          <div className="page-body">
            {children}
          </div>
          {/* <Footer /> */}
          <ThemeCustomize />
        {/* </div> */}
      </div>
      {/* <ToastContainer /> */}
    </Fragment>
  );
}

export default AppMicrositio;