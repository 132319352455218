import React from 'react';
import { Form, ModalFooter, ModalBody, Button } from 'reactstrap';

export const DeleteCurveTime = (props) => {
    
    const {
        toggle, loading, name, saveDelete
    } = props;

    return (
        <Form className='theme-form' role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar este tiempo:</p>
                <b>{`${name}`}</b>
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si, eliminar
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )

}
