import React, { Fragment} from 'react'
import {Card,Col,Row,Container} from 'reactstrap'
import ReactApexChart from 'react-apexcharts';

export const ComponentDetailBranchs = (props) => {

    const {options,series,sum_branches_recolected,listBranchesTree,handleGeneralPatients,classifications,typePeriod} = props;

    return (
        <Fragment>
        <Container fluid={true} className='p-l-0 p-r-0'>
          {
            //!DETAIL AMOUNT BRANCHS
          }
          <ReactApexChart options={options} series={series} type="bar" height={100} />
          <br />
          {
            //!REDIRECT 
          }
        <Col sm="12" >
          <Row>
          <Col sm="10" className={sum_branches_recolected === 0 ?  "opacity-patient-detail p-r-0 " : " p-r-0"}  >
          <Card className='shadow-detail-patient' style={{height: '340px'}} >
            <div className='card-detail-patien'>
            <div className='hr-detail-patient' >
              <b className=''>Detallado de Pacientes</b>
            </div> <br /> 
          <Col sm="12" className='p-l-0 p-r-0' >
              {
               listBranchesTree?.map((_find,_key) => (
               _find.children.map((_branch) => {
                if (_branch.amount === 0) {
                    return;
                }
              return (
                <div className="row product-hover">
                <div className="col-10">
                <i className="fa fa-plus-square-o f-14 branches-label"></i>  <label className='branches-label f-12'>{_branch.text}</label>
                </div>
                <div className="col-2">
                  <div className='badge-branches-view pointer' onClick={() => handleGeneralPatients(_branch.id,typePeriod)}   ><i className='fa fa-eye f-12'></i></div>
                </div>
                </div>
                );
                })
               ))}
          </Col>                    
             </div>
            </Card>
          </Col>
            <Col sm="2">
              <li>
              <ul>
              <i  className={classifications === 'muy alto' ? "fa fa-calendar-o f-48 calender-deatil-branches-alto-m "  : "fa fa-calendar-o f-30 calender-deatil-branches-alto-m "   }     style={classifications === 'muy alto' ? { }: {filter: 'blur(1px)' } }   ></i>
              </ul> <br />
              <ul>
              <i className={classifications === 'alto' ? "fa fa-calendar-o f-48 calender-deatil-branches-alto"  : "fa fa-calendar-o f-30 calender-deatil-branches-alto" } style={classifications === 'alto' ? { }: {filter: 'blur(1px)' } }  ></i>
              </ul> <br />
              <ul>
              <i className={ classifications === 'medio' ? "fa fa-calendar-o f-48 calender-deatil-branches-medio" :"fa fa-calendar-o f-30 calender-deatil-branches-medio"} style={classifications === 'medio' ? { }: {filter: 'blur(1px)'} }  ></i>
              </ul> <br />
              <ul>
              <i className={classifications === 'medio bajo' ? "fa fa-calendar-o f-48 calender-deatil-branches-medio-b" : "fa fa-calendar-o f-30 calender-deatil-branches-medio-b"} style={classifications === 'medio bajo' ? { }: {filter: 'blur(1px)' } }  ></i>
              </ul> <br />
              <ul>
              <i className={classifications === 'bajo' ? "fa fa-calendar-o f-48 calender-deatil-branches calender-deatil-branches-bajo" : "fa fa-calendar-o f-30 calender-deatil-branches calender-deatil-branches-bajo"} style={classifications === 'bajo' ? { }: {filter: 'blur(1px)' } } ></i>
              </ul> <br />
              <ul>
              <i className={classifications === 'nada' ? "fa fa-calendar-o f-48 calender-deatil-branches-nada " : "fa fa-calendar-o f-30 calender-deatil-branches-nada " } style={classifications === 'nada' ? { }: {filter: 'blur(1px)' } } ></i>
              </ul>
              </li>
            </Col>
          </Row>
         </Col>
        </Container>   
        </Fragment>
    )
}
