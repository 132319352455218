import React from 'react'
import { Button,Col, ModalBody, ModalFooter, Row ,ListGroup,ListGroupItem,Card,Label} from 'reactstrap';


export const ViewListStudies = (props) => {
    const {
        toggle, studieQ,quoter,codeCard
    } = props;
   

  return (
    <>
<ModalBody> 
            <Row>
                <Col  xl="12" className="box-col-6  pr-0 file-spacing  p-0 m-0 t-0 ">
                    <div className="file-sidebar  p-0 m-0  t-0  ">
                        <ul>
                        <li>
                        <div className=" p-2">
                            <Row>
                            <Col className="col-md-12 p-0  text-center text-secondary ">
                            <p className='txt-primary'><h5>{"Estudios Realizados"}</h5> </p>
                            <hr/>
                            </Col>
                            </Row>
                            <Col className='col-md-12 p-0'>
                            <Col sm="12">
                           
                            <div className="pricingtable-header">
                            {
                                studieQ.length >= 1 && studieQ.map( (obj, index) =>{
                                   
                                    return (
                                <ListGroup key={index}>
                                <ListGroupItem className="d-flex justify-content-between align-items-center list-group-item-action">{obj.name_test}<span className="badge badge-info counter digits">precio: $ {(obj.price).toFixed(2)}</span></ListGroupItem>
                                </ListGroup>

                                    )
                                }
                                )
                            }
                            <br /> <br />
                      </div>  
                                
                            </Col>
                        </Col>
                    </div>
                </li>
                </ul>
            </div>
        </Col> 
            </Row>
        </ModalBody  >
        <br />
        <ModalFooter>
            <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>  
        </ModalFooter>
    
    </>
   
  )
}
