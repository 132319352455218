import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, CardBody,Input } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useIndications } from '../../../hooks/indications/useIndications'
import { ModalIndications } from '../../../components/indications/ModalIndications';
import { ModalSendCSV } from '../../../components/indications/ModalSendCSV';


export const IndexIndications = () => {

    const {
        listIndications,
        method, modal, modalTitle, loading,toggle,
        handleCreate, saveCreate,handleUpdate, saveUpdate,handleDelete, saveDelete,
        id_exam_indication, indication, handleInputChange,validaciones,
        //PAGINATION FRONT-END
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        //MODAL SEND SCV
        handleSendCSV, modal2, method2, modalTitle2,toggle2,file,
        handleOnChange,ExamIndication,handleSearchIndication


    } = useIndications();


  return (
      <Fragment>
           {/* <Breadcrumb parent="Configuraciones" title="Indicaciones" className="m-b-0" /> */}
           <Container fluid={true}>
               <Row>
                   <Col sm="12">
                       <Card>
                           <CardBody className='p-1'>
                           <div className="ribbon ribbon-clip ribbon-primary">
                            {"Indicaciones"}
                           </div>
                           <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}> 
                           <Button  className="btn btn-pill btn-air-primary"  color="primary" size="sm" onClick={handleSendCSV} ><span className="icofont icofont-upload-alt"></span></Button>
                           &nbsp; &nbsp; &nbsp; 
                               <Button className="btn btn-pill btn-air-secondary" color="secondary" size="sm"  onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                           </div>
                           <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchIndication} />
                                </div>
                            </div>
                           {
                            listIndications.length === 0 ?
                            <div style={{ textAlign: "-webkit-center" }}>
                            <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                            <br /> <p className="f-18">{"No se ha registrado algúna Indicación aún."}</p>
                            </div>
                            :
                         
                            <div className="table-responsive p-2">
                            <TableStriped
                             notMaped={["visibleTable"]}
                             methodsActions={true}
                             cabeceras={["Indicaciones para el exámen"]}
                             items={listIndications}
                             {...{ handleUpdate,handleDelete}}
                             nextPage={nextPage}
                             previousPage={previousPage}
                             totalPageCount={totalPageCount}
                             currentPage={currentPage}
                             goToPage={goToPage}
                             />   
                        </div>
                           }
                           </CardBody>
                       </Card>
                   </Col>
               </Row>
           </Container>
           <ModalIndications
            {
                ...{
                    method, modalTitle, loading, modal, toggle,
                    saveCreate,handleInputChange,saveUpdate,saveDelete,validaciones,
                    id_exam_indication, indication
                    
                }
            }
            />
            <ModalSendCSV
            {
                ...{
                    modal2, method2, modalTitle2,loading,toggle2,file,handleInputChange,handleOnChange,
                    ExamIndication

                }
            }

            />
      </Fragment>
  )
}
