import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { LostConnection, NotFoundDataConn } from '../../constant';

export const usePrinterPoints = () => {
    
    const history = useHistory();

    const [printerPoints, setPrinterPoints] = useState([]);
    const [printerPointId, setPrinterPointId] = useState(0);

    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        username: "",
        password: "",
        password_confirmation: ""
    });

    const { name, username, password, password_confirmation } = formValues;

    const [validaciones, setValidaciones] = useState({
        name: false,
        username: false,
        password: false,
        password_confirmation: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getPrintetProints = useCallback(
        async () => {
            let requestOptions = {
                method: 'GET'
            };

            const respuesta = await sendRequest(requestOptions, "PrinterPoints");

            if (respuesta.code === 200) {
                setPrinterPoints(respuesta.data);
            }
            else{
                validarSesion(history, respuesta.code, getPrintetProints);
            }
        },
        [history]
    )

    useEffect(() => {
        getPrintetProints();
    }, [getPrintetProints])

    const handleCreate = () => {
        setValidaciones({
            name: false,
            username: false, 
            password: false,
            password_confirmation: false
        });

        handleUpdateValues({
            name: "",
            username: "",
            password: "",
            password_confirmation: ""
        });

        setPrinterPointId(0);
        setMethod("create");
        setModalTitle("Creación de punto de impresión");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, 'PrinterPoints');

        if (respuesta.code === 200) {
            let newPrinterPoints = printerPoints;

            newPrinterPoints.push({
                id_printer_point: respuesta.data.id_printer_point,
                name: name,
                username: username,
            });

            setPrinterPoints(newPrinterPoints);
            reset();
            createSweet("create","success", "Exito!", "Se creo el punto de impresión");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Método de pago", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Método de pago", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_printer_point) => {
        let printerPoint = printerPoints.find(x => x.id_printer_point === id_printer_point);

        handleUpdateValues({
            name: printerPoint.name,
            username: printerPoint.username,
            password: "",
            password_confirmation: ""
        });

        setValidaciones({
            name: false,
            username: false,
            password: false,
            password_confirmation: false,
        });

        setPrinterPointId(id_printer_point);
        setMethod("update");
        setModalTitle("Actualización de punto de impresión");
        toggle(); 
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }
        
        setLoading(true);

        let body = {
            id_printer_point: printerPointId,
            ...{ ...formValues }
        }

        let raw = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "PrinterPoints");

        if (respuesta.code === 200) {
            
            let newPrinterPoints = printerPoints.map(function (obj) {
                if (obj.id_printer_point === printerPointId) {
                    obj.name = name;
                    obj.username = username;
                }
                return obj;
            });
            
            setPrinterPoints(newPrinterPoints);
            createSweet("update", "info", "Exito!", "Se actualizo el punto de impresión");
            setLoading(false);
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_printer_point) => {
        let printerPoint = printerPoints.find(x => x.id_printer_point === id_printer_point);
        
        handleUpdateValues({
            name: printerPoint.name,
        });
        
        setPrinterPointId(id_printer_point);
        setMethod("delete");
        setModalTitle("Eliminar punto de impresión");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE',
        };

        const respuesta = await sendRequest(requestOptions, `PrinterPoints/${printerPointId}`);
        
        if (respuesta.code === 200) {
            let newPrinterPoints = printerPoints.filter(x => x.id_printer_point !== printerPointId);
            
            setPrinterPoints(newPrinterPoints);
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        } 
        else {
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        } 
        else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        if (formValues.username.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                username: true
            }
            statusValidacion = true;
        } 
        else {
            newValidaciones = {
                ...newValidaciones,
                username: false
            }
        }

        if (formValues.password.length < 5) {
            newValidaciones = {
                ...newValidaciones,
                password: true
            }
            statusValidacion = true;
        } 
        else {
            newValidaciones = {
                ...newValidaciones,
                password: false
            }
        }

        if (password !== password_confirmation) {
            newValidaciones = {
                ...newValidaciones,
                password_confirmation: true
            }
            statusValidacion = true;
        } 
        else {
            newValidaciones = {
                ...newValidaciones,
                password_confirmation: false
            }
        }

        setValidaciones(newValidaciones)

        return statusValidacion;
    }

    return {
        printerPoints, method,
        name, username, password, password_confirmation, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    }

}
