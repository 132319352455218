import React, { useState, useEffect } from 'react'

export const usePagination = (total_count, page_size) => {

    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);

    useEffect(() => {
        let total_page_count = Math.ceil(total_count / page_size);

        setTotalPageCount(total_page_count);


    }, [total_count, page_size])


    const nextPage = () => {
        let current_page = currentPage;
        current_page++;
        setCurrentPage(current_page);
    }

    const previousPage = () => {
        let current_page = currentPage;
        current_page--;
        setCurrentPage(current_page);
    }

    const goToPage = (page) => {
        setCurrentPage(page);
    }

    return {
        totalPageCount, currentPage,
        nextPage, previousPage, goToPage
    }
}
