import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';


export const DeleteProfile = (props) => {

    const { toggle2, loading, saveDelete,abbreviation, name} = props;

   return (
        <Form role="form" onSubmit={ saveDelete }>
            <ModalBody>
            <p className="h6 txt-danger">Estas a punto de eliminar el perfil:</p>  
            <b>{name}</b> <label > {abbreviation} </label>
            <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar
                </Button>
                <Button color="light" type="button" onClick={toggle2}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
