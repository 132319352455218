import { useEffect, useState } from 'react';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from 'react-router';
import { useForm } from '../../hooks/forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';


export const useTypePrecodedTexts = () => {

    const history = useHistory();

    useEffect(() => {
        getTypePrecodedTexts();
    }, [history.location])


    const [typePrecodedTexts, setTypePrecodedTexts] = useState([]);
    const [typePrecodedTextId, setTypePrecodedTextId] = useState(0);
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
    });

    const [validaciones, setValidaciones] = useState({
        name: false
    })

    const { name } = formValues;

    const getTypePrecodedTexts = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "TypePrecodedTexts");
        if (respuesta.code === 200) {

            let listprecod = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((element,index) => {
                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listprecod.push({
                        id_type_precoded_text: element.id_type_precoded_text,
                        name: element.name,
                        visibleTable:visibleTable
                    }) 
                });
            }
            setTypePrecodedTexts(listprecod);
            setPrecodData(listprecod);

        } else {
            validarSesion(history, respuesta.code, getTypePrecodedTexts);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
        });
        setTypePrecodedTextId(0);
        setMethod("create");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) return;

        setLoading(true);
        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "TypePrecodedTexts");

        if (respuesta.code === 200) {
            let newTypePrecodedTexts = typePrecodedTexts;
            newTypePrecodedTexts.push({
                id_type_precoded_text: respuesta.data.id_type_precoded_text,
                name: name
            });
            setTypePrecodedTexts(newTypePrecodedTexts);
            reset();
            setLoading(false);
            createSweet("create", "success");
            toggle();
        } else {
            ////console.log("Error al crear el tipo de texto precodificado...");
            validarSesion(history, respuesta.code, saveCreate);
        }
    }

    const handleUpdate = (id_type_precoded_text) => {
        let typePrecodedText = typePrecodedTexts.find(t => t.id_type_precoded_text === id_type_precoded_text);
        handleUpdateValues({
            name: typePrecodedText.name,
        });
        setTypePrecodedTextId(id_type_precoded_text);
        setMethod("update");
        getListPrecoded();
        getListPrecoded(id_type_precoded_text).then(x => {

            toggle();


        })

        //  saveList(id_type_precoded_text);
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        let body = {
            id_type_precoded_text: typePrecodedTextId,
            ...{ ...formValues }
        }
        setLoading(true);
        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "TypePrecodedTexts");
        if (respuesta.code === 200) {
            let newTypePrecodedTexts = typePrecodedTexts.map(function (obj) {
                if (obj.id_type_precoded_text === typePrecodedTextId) {
                    obj.name = name;
                }
                return obj;
            });
            setTypePrecodedTexts(newTypePrecodedTexts);
            setLoading(false);
            createSweet("update", "success");
            saveList();
            //  saveList()
            toggle();

        } else {
            validarSesion(history, respuesta.code, saveUpdate);
            ////console.log("Error");
        }
    }

    const handleDelete = (id_type_precoded_text) => {
        let typePrecodedText = typePrecodedTexts.find(t => t.id_type_precoded_text === id_type_precoded_text);
        handleUpdateValues({
            name: typePrecodedText.name
        });
        setTypePrecodedTextId(id_type_precoded_text);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };
        setLoading(true);
        const respuesta = await sendRequest(requestOptions, `TypePrecodedTexts/${typePrecodedTextId}`);
        if (respuesta.code === 200) {
            let newTypePrecodedTexts = typePrecodedTexts.filter(t => t.id_type_precoded_text !== typePrecodedTextId);
            setTypePrecodedTexts(newTypePrecodedTexts);
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveDelete);
            ////console.log("Error");
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            };
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            };
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }


    const [arrayPrecod, setArrayPrecod] = useState([])
    const [data, setData] = useState([]);

    const changeArrayPrecod = (arreglo) => {
        setArrayPrecod(arreglo);
    }

    const getListPrecoded = async (id_type_precoded_text) => {

        if (id_type_precoded_text === undefined) {
            return;
        }

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, `PrecodedTexts/ListOrderPrecodedText/${id_type_precoded_text}`);

        if (respuesta.code === 200) {
            setData(respuesta.data);
        }
        else {
            validarSesion(history, respuesta.code, getListPrecoded);
        }
    }


    const changeOrder = (payload,) => {

        let array = payload.columns[0].cards
        let array2 = data.columns

        array2.columns = {
            cards: array,
            id: 1,
        };
        setData({ ...array2 });
    }


    const saveList = async () => {

        let array = data;
        ////console.log(array, "datos array");

        let raw = JSON.stringify(array);

        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, `PrecodedTexts/UpdateListOrderPrecodedText/${typePrecodedTextId}`);
        if (respuesta.code === 200) {

        }
    }

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(typePrecodedTexts.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listPrecod = [];
        typePrecodedTexts.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listPrecod.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setTypePrecodedTexts(listPrecod);

    }, [totalPageCount, currentPage]);

    const [precodData, setPrecodData] = useState([])


    const handleSearchPrecod = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        precodData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setTypePrecodedTexts(newMethod);
    }


    return {
        typePrecodedTexts, method,
        name, handleInputChange, validaciones,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        arrayPrecod, data, changeOrder, changeArrayPrecod,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchPrecod

    };
}