import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

export const ModalViewDocImagenology = (props) => {

    const {
        modalViewImagenology, modalTitleImg, toggleModalViewImagenology, documentImagenology
    } = props;

    return (
        <Modal isOpen={modalViewImagenology} backdrop='static' keyboard={false} centered={true} className="modal-xl">
            <ModalHeader toggle={toggleModalViewImagenology} className="bg-primary">
                { modalTitleImg }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <embed src={documentImagenology} title='Previsualizar resultado' type='application/pdf' width="100%" height='600px'></embed>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' className='btm-air-light' outline color='danger' type='button' onClick={toggleModalViewImagenology}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
