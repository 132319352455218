import React from 'react'
import { Modal, ModalHeader, ModalFooter, Form, ModalBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

export const ModalAnalizerInterfaces = (props) => {

    const {
        modal, toggle, loading,
        handleInputChange, appCode, saveUpdate
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary">
                Editar App Code
            </ModalHeader>
            <Form className='' role="form" onSubmit={saveUpdate}>
                <ModalBody>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <h6>APPCODE anterior: <b>{appCode}</b></h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                            <FormGroup>
                                <Label className='col-form-label'>Nuevo Appcode <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="text"
                                    name="appCode"
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    value={appCode}
                                    className="form-control form-control-sm input-air-primary"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size='sm' outline color='primary' type='submit' className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                        Actualizar
                    </Button>
                    <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )

}
