import { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';

export const useRoles = () => {
   
    //GLOBAL VARIABLES
    const history = useHistory();    
    const numberItems = 8;
    //SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //END SHOW DISPLAT MESSAGE

    //#region MODALS
        const [modalTitle, setModalTitle] = useState(false);
        const [modalRoles, setModalRoles] = useState(false);
        const [methodRoles, setMethodRoles] = useState("");

        const toggleRol = () => {
            setModalRoles(!modalRoles);
        }
    //#endregion MODALS

    //#region ROLES 
        //#region ROLES MODEL            
            const [listRoles, setListRoles] = useState([]);
            const [listRolesSearch, setListRolesSearch] = useState([]);
            const [listRolesData, setListRolesData] = useState([]);
            const [rolId, setRolId] = useState(0);
            const [listViews, setListViews] = useState([]);

            const [loading, setLoading] = useState(false);

            const [formRole, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
                name: "",
                ids_views: []
            });

            const { name, ids_views } = formRole;
        //#endregion ROLES MODEL

        //#region ROLES VALIDATIONS
            const [validationsRole, setValidationsRole] = useState({
                name_valid: false,
                views_valid: false
            });
        //#endregion ROLES VALIDATIONS

        //#region USERS METHOD
            const handleGetRoles = useCallback( async () => {
                let listTmp = await handleRequest("GET", "Roles/List", "Roles");               

                let list = [];      
                if (listTmp !== null && listTmp.length > 0) {
                    let startItems = 0;
                    let endItems = numberItems;
                    let aux = numberItems * currentPage;
    
                    endItems = aux;
                    startItems = endItems - numberItems;              
                    
                    listTmp.forEach( (obj, index) => {

                        let posicion = index + 1;
                        let visibleTable = false;

                        if (posicion > startItems && posicion <= endItems) {
                            visibleTable = true;
                        }
                        list.push({
                            id_role: obj.id_role,
                            name: obj.name,
                            group_views: obj.group_views,
                            visibleTable: visibleTable
                        });                       
                    });
                }
                setListRoles(list);
                setListRolesSearch(list);
            }, [history]);

            const handleGetViews = async () => {
                //console.log('acdentro')
                let listTmp = await handleRequest("GET", "Roles/ListViews", "Vistas");
                ////console.log(listTmp);
                if (listTmp !== null && listTmp.length > 0) {
                    
                    let list = [];

                    listTmp.forEach(obj => {
                        list.push({
                            value: obj.id_view,
                            label: obj.name
                        });
                    });

                    setListViews(list);
                }
            }

            const handleCreateRoles = () => {
                handleUpdateValues({
                    name: "",
                    ids_views: []
                });

                setModalTitle("Crear rol");
                toggleRol();
                setMethodRoles("create");

                setValidationsRole({
                    name_valid: false,
                    views_valid: false
                });

                handleGetViews();
            }

            const saveCreateRol = async (e) => {
                e.preventDefault();
                setLoading(true);

                if (validationsRoles()) {
                    setLoading(false);
                    return;
                }

                let list_id_views = [];

                formRole.ids_views.forEach(obj => {
                    list_id_views.push(obj.value);
                });

                let body = {
                    name: formRole.name,
                    ids_views: list_id_views
                };

                let listTmp = await handleRequest("POST", "Roles/Create", "Rol", body);

                if (listTmp !== null) {
                    setLoading(false);
                    toggleRol();
                    createSweet("create", "success", "Rol creado"); 
                    handleUpdateValues({
                        name: "",
                        ids_views: []
                    });  

                    handleGetRoles();  
                }
                else {
                    setLoading(false);                    
                }
            }

            const handleUpdate = (id_role) => {
                let findRole = listRoles.find(x => x.id_role === parseInt(id_role));

                if (findRole) {
                    let list_update_views = [];

                    if (findRole.group_views.length > 0) {
                        findRole.group_views.forEach(group => {                                                        
                            if (group.views.length > 0) {
                                group.views.forEach(view => {
                                    list_update_views.push({
                                        value: view.id_view,
                                        label: view.name
                                    });
                                });
                            }
                        });
                    }                   

                    handleUpdateValues({
                        name: findRole.name,
                        ids_views: list_update_views
                    });

                    setRolId(id_role);
                    setMethodRoles("update");
                    setModalTitle("Actualizar rol"); 
                    toggleRol();
                    handleGetViews();
                }
            }

            const saveUpdateRole = async (e) => {
                e.preventDefault();

                setLoading(true);

                if (validationsRoles()) {
                    setLoading(false);
                    return;
                }

                let list_id_views = [];

                formRole.ids_views.forEach(obj => {
                    list_id_views.push(obj.value);
                });

                let body = {
                    name: formRole.name,
                    ids_views: list_id_views
                };

                let listTmp = await handleRequest("PUT", `Roles/Update/${rolId}`, "Rol", body);

                if (listTmp !== null) {
                    setRolId(0);
                    setLoading(false);
                    toggleRol();
                    createSweet("update", "info", "Rol actualizado");
                    handleUpdateValues({
                        name: "",
                        ids_views: []
                    });

                    handleGetRoles();
                }
                else{
                    setLoading(false);
                }
            }

            const handleDelete = (id_role) => {
                let find_rol = listRoles.find(x => x.id_role === parseInt(id_role));

                if (find_rol) {
                    handleUpdateValues({
                        name: find_rol.name
                    });

                    setRolId(id_role);
                    setMethodRoles("delete");
                    setModalTitle("Eliminar rol");
                    toggleRol();
                }
            }

            const saveDeleteRol = async (e) => {
                e.preventDefault();

                setLoading(true);

                let listTmp = await handleRequest("PUT", `Roles/Delete/${rolId}`, "Eliminar rol");

                if (listTmp !== null) {
                    handleGetRoles();
                    setLoading(false);
                    createSweet("create", "info", "Exito!", "Se elimino correctamente");
                    toggleRol();
                    setRolId(0);
                }
                else {
                    setLoading(false);
                }
            }

            const handleViewViews = (id_role) => {
                let findRol = listRoles.find(x => x.id_role === parseInt(id_role));
                ////console.log(findRol);
                if (findRol) {
                    setListRolesData(findRol.group_views);
                    setMethodRoles("view");
                    setModalTitle("Listado de vistas");
                    toggleRol();
                }
            }

            const handleSearchRol = (e) => {
                let busqueda = e.target.value;
                let newMethod = [];

                listRolesSearch.forEach(obj => {
                    let cadena = obj.name.toUpperCase();

                    if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                        obj.visibleTable = true;
                        newMethod.push(obj);
                    }
                });

                setListRoles(newMethod);
            }
        //#endregion USERS METHOD

        //#region PAGINATION             
            const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listRoles.length, numberItems);

            useEffect(() => {
                let startItems = 0;
                let endItems = numberItems;

                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                let listTmp = [];

                listRoles.forEach((data, key) => {
                    let posicion = key + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems ) {
                        visibleTable = true;
                    }

                    listTmp.push({
                        ...data,
                        visibleTable: visibleTable
                    });
                });

                setListRoles(listTmp);

            }, [totalPageCount, currentPage]);
            
        //#endregion PAGINATION

        //#region USERS USE EFFECT

            useEffect(() => {
                handleGetRoles();
            }, [handleGetRoles])
            
            
        //#endregion USERS USE EFFECT

    //#endregion ROLES

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method            
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions, 
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const validationsRoles = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formRole.name === "" || formRole.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            };
        }

        if (formRole.ids_views.length === 0) {
            newValidations = {
                ...newValidations,
                views_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                views_valid: false
            };
        }

        setValidationsRole(newValidations);
        return statusValidation;
    }



    return {
        modalTitle, modalRoles, methodRoles, toggleRol,
        //ROLES MODEL
        listRoles, listViews, loading, 
        handleInputChange, handleSelectValues,
        name, ids_views,    validationsRole,     
        listRolesData,
        
        //ROLES METHODS
        handleCreateRoles, saveCreateRol,
        handleUpdate, saveUpdateRole,
        handleDelete, saveDeleteRol,
        handleViewViews,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, 
        handleSearchRol
    }
}
