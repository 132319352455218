import React from 'react'
import {Button, Modal, ModalBody, CardBody, Media,Col, Form, Input, FormFeedback,Row } from 'reactstrap';

export const ModalUpdateMethod = (props) => {

    const {modalPreviewUpdate,toggleModalUpdate,user_method, user_password_method, id_pay_method_method,handleInputChangeMethod,listPayMethod,validatePassword,aunthentication,loadingUpdateMethod} = props;

    
    return (
        <Modal isOpen={modalPreviewUpdate} toggle={toggleModalUpdate} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    {
                        <>
                         <Media> <br /> <br /><Media style={{paddingTop:"50px"}}  className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                <Media body>
                                    <Row>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                        <Input
                                        type="text"
                                        name="user_method"
                                        value={user_method}
                                        required={true}
                                        autoComplete="nope"
                                        onChange={handleInputChangeMethod}
                                        className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}
                                    />
                                    <FormFeedback>Escribe el usuario</FormFeedback>
                                    </span></span></span>
                                        </Col>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-key"> </i><span className="px-2">{"Contraseña"}
                                        <Input
                                            type="password"
                                            name="user_password_method"
                                            value={user_password_method}
                                            required={true}
                                            autoComplete="nope"
                                            onChange={handleInputChangeMethod}
                                            className={`form-control form-control-sm input-air-primary ${(aunthentication ? 'border-danger' : 'border-primary')}`}
                                        />
                                        <FormFeedback>Escribe la contraseña</FormFeedback>
                                        </span></span></span>
                                        </Col>
                                    </Row>
                                   <span className="d-block"><span><i className="fa fa-money"></i><span className="px-2">{"Metódo de Pago"}
                                <Input style={{fontSize:"13px"}} type="select" name='id_pay_method_method' className="form-control form-control-sm input-air-primary border-primary"
                                onChange={handleInputChangeMethod} value={id_pay_method_method} >
                                    <option value={0}>Selecciona una opción</option>
                                        {
                                            listPayMethod.map(_pMethod => {                                                                                         
                                            return (
                                                    <option   value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                    )                                                                                       
                                                })
                                                }
                                </Input>     
                                </span></span></span>
                                </Media>
                        </Media>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                        <Row>
                            <Col sm="5">
                            </Col>
                            <Col sm="3">
                            <Button disabled={loadingUpdateMethod ? true : false} color="danger text-center" type="button" onClick={toggleModalUpdate}>{"Cancelar"}</Button>
                            </Col>
                            <Col sm="3">
                            <Button disabled={loadingUpdateMethod ? true : false} color="success text-center" type="button" onClick={validatePassword} >{"Autorizar"}</Button>  

                            </Col>
                        </Row>
                            
                        </Col>
                        </>
                    }
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
