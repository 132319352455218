import React, { useState } from 'react';
import {
    Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row,
    Card, Table, CardSubtitle,Col,InputGroup ,InputGroupAddon ,InputGroupText 
} from 'reactstrap';
import { nameID, nameadd, sociall, rfcf, defaut, referenceLaboratories, save, cancell } from '../../constant';
import Select from 'react-select';


export const UpdateExam = (props) => {


    const { formValues, toggle, handleInputChange, validaciones, loading,
        sections, changeSelect, specimenOrigins, specimens, specimenProvenances, subSections, transports,
        id_section, id_subsection, id_specimen_origin,
        code, alias, name, abbreviation, individual_sale, is_referenced, is_critic,
        listExternalLaboratories, examId,
        getUpdateDefaultExternalLaboratory, methodAction, handleSelectValues, print_new_page,
           //*CONFIGURATION EXTERNAL LABORATORY
           dataLaboratory,handleChangePriceIni
    } = props;
           console.log("🚀 ~ UpdateExam ~ dataLaboratory:", dataLaboratory)

    //console.log(dataLaboratory);

    const {
        code: validationCode,
        alias: validationAlias,
        name: validationName,
        abbreviation: validationAbbreviation,
        id_section: validationSection,
        id_specimen_origin: validationSpecimenOrigen
    } = validaciones;
    const txtsection = localStorage.getItem('TextAS');
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <div>
                </div>
                <Card>
                    <Card>
                        <Row>
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Clave<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    type="text"
                                    name="code"
                                    value={code}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    invalid={validationCode}
                                />
                                <FormFeedback>El codigo debe tener 3 o más caracteres</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary'>Alias <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    type="text"
                                    name="alias"
                                    value={alias}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    invalid={validationAlias}
                                />
                                <FormFeedback>El alias debe tener 3 o más caracteres</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Abreviatura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    type="text"
                                    name="abbreviation"
                                    value={abbreviation}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    invalid={validationAbbreviation}
                                />
                                <FormFeedback>La abreviatura debe tener 2 o más caracteres</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Tiempo de entrega<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    value={formValues.delivery_time}
                                    type="text"
                                    name="delivery_time"
                                    required={false}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                />
                                <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup className="mb-3 col-6">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Nombre del exámen <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    type="text"
                                    name="name"
                                    value={name}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleInputChange}
                                    invalid={validationName}
                                />
                                <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-6">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >{txtsection} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    className="form-control form-control-sm input-air-primary"
                                    type="select"
                                    name="id_section"
                                    required={true}
                                    onChange={handleInputChange} 
                                    invalid={validationSection || id_section === "0"}
                                    value={id_section}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        sections.length > 0 && sections.map((s, key) => {
                                            return <option
                                                key={key} value={s.id_section}
                                            >
                                                {s.name}
                                            </option>
                                        })
                                    }
                                </Input>
                                {id_section === 0 && <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>}
                                {/* <FormFeedback>Debes seleccionar al menos una opción</FormFeedback> */}
                            </FormGroup>

                        </Row> <br />
                        <Row>

                            {/* <FormGroup className="mb-3 col-3">
                                <Label>Subsección</Label> <br />
                                <Input
                                    className="form-control form-control-sm input-air-primary "
                                    type="select"
                                    name="id_subsection"
                                    required={true}
                                    onChange={handleInputChange}
                                    value={id_subsection}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        subSections.length > 0 && subSections.map((ss, key) => {
                                            return <option
                                                key={key} value={ss.id_subsection}
                                            >
                                                {ss.name}
                                            </option>
                                        })
                                    }
                                </Input>
                            </FormGroup> */}
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary'>Tipo de muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>

                                <Select
                                    value={formValues.id_specimen}
                                    classNamePrefix="select"
                                    name="id_specimen"
                                    placeholder={`Selección de especimen`}
                                    isClearable={false}
                                    noOptionsMessage={() => "Sin opciones"}
                                    options={specimens}
                                    onChange={(e) => handleSelectValues(e, "id_specimen")}
                                />
                                <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Procedencia de la muestra <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Select
                                    value={formValues.id_specimen_provenance}
                                    classNamePrefix="select"
                                    name="id_specimen_provenance"
                                    placeholder={`Seleciona la procedencia`}
                                    isClearable={false}
                                    noOptionsMessage={() => "Sin opciones"}
                                    options={specimenProvenances}
                                    onChange={(e) => handleSelectValues(e, "id_specimen_provenance")}
                                />
                                <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                            </FormGroup>
                            <FormGroup className="mb-3 col-6">
                                <Label className='f-w-600 f-12 badge badge-light-secondary' >Medio(s) de transporte <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Select
                                    value={formValues.list_transports}
                                    isMulti
                                    classNamePrefix="select"
                                    name="list_transports"
                                    onChange={(e) => handleSelectValues(e, "list_transports")}
                                    // value={valuePeriod}
                                    placeholder={`Selección de medio de transporte`}
                                    isClearable={false}
                                    noOptionsMessage={() => "Sin opciones"}
                                    options={transports}
                                />
                                <FormFeedback>Debes seleccionar al menos una opción</FormFeedback>
                            </FormGroup>
                           
                        </Row>
                        <Row>


                            
                        </Row>
                        <Row>
                            <FormGroup className="mb-3 col-3 mb-3" style={{ "text-align": "center" }} >
                                <br />
                                <Label>Venta individual</Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="individual_sale" onChange={handleInputChange} checked={individual_sale} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3 mb-3" style={{ "text-align": "center" }}    >
                                <br />
                                <Label>¿Es referenciado?</Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_referenced" onChange={handleInputChange} checked={is_referenced} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3 mb-3" style={{ "text-align": "center" }}    >
                                <br />
                                <Label>¿Es crítico?</Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_critic" onChange={handleInputChange} checked={is_critic} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                            <FormGroup className="mb-3 col-3 mb-3" style={{ "text-align": "center" }}    >
                                <br />
                                <Label>¿Impresión en una hoja diferente?</Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="print_new_page" onChange={handleInputChange} checked={print_new_page} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>

                        </Row>
                    </Card>
                    {
                        ////console.log(listExternalLaboratories)
                    }
                    <div className={listExternalLaboratories !== null && listExternalLaboratories.length >= 1 && is_referenced ? 'table-responsive' : 'd-none'} >
                        {/* <br />
                        <CardSubtitle tag="h6" className="mb-2 text-muted">{referenceLaboratories}</CardSubtitle>
                        <Table className="table table-sm">
                            <thead className="bg-light-primary text-center">
                                <tr className="border-bottom-primary">
                                    <th style={{ "text-align": "-webkit-auto" }}>{nameID}</th>
                                    <th style={{ "text-align": "-webkit-auto" }}>{nameadd}</th>
                                    <th style={{ "text-align": "-webkit-auto" }}>{sociall}</th>
                                    <th style={{ "text-align": "-webkit-auto" }}>{rfcf}</th>
                                    <th style={{ "text-align": "-webkit-auto" }}>{defaut}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listExternalLaboratories !== null && listExternalLaboratories.length >= 1 && listExternalLaboratories.map((obj, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ "text-align": "inherit" }}>{obj.code}</td>
                                                <td style={{ "text-align": "inherit" }}>{obj.name}</td>
                                                <td style={{ "text-align": "inherit" }}>{obj.business_name}</td>
                                                <td style={{ "text-align": "inherit" }}>{obj.rfc}</td>
                                                <td>
                                                    <FormGroup check className="mb-3 col-5 offset-1">
                                                        <Input type="radio" name="chek" checked={obj.is_default} onChange={() => getUpdateDefaultExternalLaboratory(examId, obj.id_external_laboratory)} />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table> */}
                        <Col sm="12">
                        <label className='txt-secondary f-14'>Asignar Precios a Laboratorios Externos</label>
                        <div  >
                            <Table className='b-r-5 table table-sm'>
                            <thead className='b-r-5'>
                              <tr style={{ backgroundColor: "#46AFE5" }}>
                                  <th className='labeltheadInvoice'>Laboratorio</th>
                                  <th className='labeltheadInvoice'>Precio</th>
                                  <th className='labeltheadInvoice'>Costo</th>
                                  <th className='labeltheadInvoice'>Default</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                                dataLaboratory?.map((_find,key) => {
                                    console.log("🚀 ~ dataLaboratory?.map ~ _find:", _find)
                                    return(
                                        <tr>
                                            <td>{_find.external_laboratory ?? "-----"}</td>
                                            <td> 
                                                <Col sm="8" className='p-l-0 p-r-0' >
                                                <InputGroup className="">
                                                <Input  type="number" min={0} className="form-control"  name="amount" value={_find.price}   onChange={(e) => handleChangePriceIni(e, _find.id_external_laboratory)} /> 
                                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                </InputGroup>        
                                                </Col>                              
                                            </td>
                                            <td>
                                                <Col sm="8" className='p-l-0 p-r-0' >
                                                <InputGroup className="">
                                                <Input  type="number" min={0} className="form-control"  name="cost" value={_find.cost}   onChange={(e) => handleChangePriceIni(e, _find.id_external_laboratory)}  /> 
                                                <InputGroupAddon addonType="append"><InputGroupText>{"$"}</InputGroupText></InputGroupAddon>
                                                </InputGroup>    
                                                </Col>
                                            </td>
                                            <td style={{textAlign :"center"}} >	
												<Input type='radio' name="default_lab" checked={_find.default_lab} onChange={(e) => handleChangePriceIni(e,_find.id_external_laboratory )} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                          </tbody>
                            </Table>
                        </div>
                        </Col>
                    </div>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="secondary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {save}
                </Button>
                <Button outline color="danger" type="button" onClick={toggle}>{cancell}</Button>
            </ModalFooter>
        </Form>
    )
}
