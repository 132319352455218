import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row, Input, CardHeader } from 'reactstrap';
import { usePrices } from '../../../hooks/prices/usePrices';
import { PriceList, ButtonGoBack } from '../../../constant';
import { TablePrices } from '../../../components/tables/TablePrices';
import { ModalPrices } from '../../../components/prices/ModalPrices';
import { useHistory } from 'react-router-dom';
import { TableStriped } from '../../../components/tables/TableStriped';


export const IndexPrices = () => {

    const history = useHistory();

    const {
        rate, prices, method, handleInputChange, validacionesPrice,
        price, from, to, id_examProfile,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        examProfileState,
        handlePickerValues, validInputDate, validToInputDate,
        //totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearch
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,

        handleSearch, metaData, examsData,
        isCurve, curves, addCurve, editCurve

    } = usePrices();


    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title={"Tarifario gatos " + rate.name} /> */}
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Col sm="12">
                            <Row>
                                <Col sm="2">
                                    <Card className='shadow'>
                                        <CardHeader className="p-3 bg-primary">
                                            <h6>Acciones</h6>
                                        </CardHeader>
                                        <Button color="light" className=" btn-air-light m-1" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>
                                    </Card>
                                </Col>
                                <Col sm="10">
                                    <Card>
                                        <div className="ribbon ribbon-clip ribbon-primary">
                                            {"Listado de precios"}
                                        </div>
                                        <div className="table-responsive">
                                            <div>
                                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                                </div>
                                                <div className="row">
                                                    <div className="offset-8 col-4 pt-1 pb-2">
                                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                                    </div>
                                                </div>
                                                <TableStriped
                                                    responsive={true}
                                                    notMaped={["visibleTable", "id_rate", "id_profile", "id_exam", "curvePrices"]}
                                                    methodsActions={true}
                                                    cabeceras={["Exámen / Perfil", "Precio", "Valido desde", "Valido hasta"]}
                                                    items={metaData}
                                                    dataCollapsed={true}
                                                    dataCollapsedType="curvesProfiles"
                                                    nextPage={goNextPage}
                                                    previousPage={goPreviousPage}
                                                    totalPageCount={totalPages}
                                                    currentPage={actualPage}
                                                    goToPage={goToPage}
                                                    paginingBack={true}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <ModalPrices
                {
                ...{
                    modal, toggle, modalTitle, method, loading,
                    handleInputChange, handlePickerValues, validacionesPrice,
                    price, from, to, id_examProfile,
                    saveCreate, saveUpdate, saveDelete,
                    examProfileState,
                    validInputDate, validToInputDate,
                    isCurve, curves, addCurve, editCurve
                }
                }
            />
        </Fragment>
    )
}
