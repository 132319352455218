import React, { Fragment } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap';
import { BtnClientEmpresa, BtnClientMaquila, BtnClientPublic, BtnSaveWorkOrder, CheckTextEmail, CheckTextPrint, CheckTextWhats, LabelDoctor, LabelPrinterPoint, labelWorkOrderPriority, PlaceHolderOption, PrinterPointList, SearchPatient } from '../../../constant';
import { useWorkOrderMicrobiology } from '../../../hooks/workOrder/useWorkOrderMicrobiology';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { TableWorkOrder } from '../../../components/elements/TableWorkOrder';
import { ModalViewExams } from '../../../components/workOrders/ModalViewExams';
import { ModalPatient } from '../../../components/patients/ModalPatient';
import { ModalDoctor } from '../../../components/doctors/ModalDoctor';
import { ModalSpecialDiscount } from '../../../components/workOrders/ModalSpecialDiscount';
import { ModalCommonDiscounts } from '../../../components/workOrders/ModalCommonDiscounts';
import { ModalQuoter } from '../../../components/workOrderQuoter/ModalQuoter';
import { ModalInvoice } from '../../../components/workOrderInvoice/ModalInvoice';
import Select from 'react-select';
import './StyleWorkOrder.css';
import 'animate.css';
import { ModalRepeatProfile } from '../../../components/workOrders/ModalRepeatProfile';

export const IndexWorkOrderMicrobiology = () => {
    //SE OBTIENE DIRECTO DEL TOKEN 
    let enable_whatsapp = localStorage.getItem('enable_whatsapp');
    const enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let versionLayout = localStorage.getItem('layout_version');
    const {
        modalTitle, number_format,
        //WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts,
        id_patient, id_company, id_printer_point,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        checkDoctor, checkInvoice, times, loading, listTest, listPayMethods, listDoctors,
        listCompanies, searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW,
        showMessageW, showMessageWF, showClassSD, showClass, viewExamsProfile, validationsWorkOrder,
        viewWorkOrder, enableBtnSaveWorkOrder, medical_order, handleOnChange, DeleteFile,
        validationsRepeat, checkComments, ShowClassNameC, valid_commont,
        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,

        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,
        //SOCIAL GENDER
        formSocialGender, saveSocialGender, toggleModalSocialGender, handleInputChangeSocialGender, validationsSG,
        //VALUES
        formValuesPatient, listBloodType,
        searchPatientState, informationPatient, listStates, listMunicipalities, Listsocialgender,
        modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient,
        handlePickerValuesPatient, validationsPatient,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, modalSocialGender,
        //METHODS 
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient,
        //DOCTOR
        //VALUES
        formDoctor, informationDoctor,
        toggleDoctor,
        modalDoctor, methodDoctor, loadingDoctor,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        //METHODS
        handleCreateDoctor, saveCreateDoctor,
        handleUpdateDoctor, saveUpdateDoctor,

        //SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,

        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,

        //COMMON DISCOUNTS
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount, checkDiscount,

        //METHODS
        handleModalListCommonDiscounts, clearDiscount, handleModalSocialGender,

        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,

        //QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,

        //INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,

        //PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //new methods
        handleChangeCompany, informationCompany, viewCompany
    } = useWorkOrderMicrobiology();

    const {
        id_patient_valid,
        id_printer_point_valid,
        id_company_valid,
        listTest_valid,
        listPayMethods_valid,
        listid
    } = validationsWorkOrder;

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12" md={checkTypeClient === 1 ? 6 : 6} lg={checkTypeClient === 1 ? 6 : 6} xl={checkTypeClient === 1 ? 6 : 6} >
                        <Card className="shadow sizeModalDP">
                            <CardHeader className='bg-primary p-2'>
                                <h6 className='m-t-5 m-b-0 m-l-5'>
                                    <i className="fa fa-user f-15"></i>&nbsp; &nbsp;
                                    <Label className='labelSearchPatient f-10'>Busqueda del cliente</Label>
                                </h6>
                            </CardHeader>
                            <CardBody className='p-3'>
                                <Row className='m-b-15'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <ButtonGroup md="12" className='btn-group-pill btn-block'>
                                            <Button color='primary' type='button' onClick={() => handleChangeTypeClient(1)} outline={checkTypeClient === 1 ? false : true}><i className="icofont icofont-id-card"></i>Particular</Button>
                                            <Button color='primary' type='button' onClick={() => handleChangeTypeClient(3)} outline={checkTypeClient === 3 ? false : true}><i className="icofont icofont-industries-alt-4"></i>Empresa</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div className={checkTypeClient === 1 ? 'text-right d-none' : 'text-right'}>
                                            <Label className='m-t-15 m-b-20'>Cliente <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            {/* <Select
                                                classNamePrefix='select'
                                                name="id_company"
                                                value={id_company}
                                                options={listCompanies}
                                                onChange={(e) => handleSelectValuesWorkOrder(e, 'id_company')}
                                                placeholder={PlaceHolderOption}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#898989'
                                                        }
                                                    })
                                                }
                                            /> */}
                                            <Select
                                                classNamePrefix="select"
                                                name="id_company"
                                                value={id_company}
                                                placeholder={PlaceHolderOption}
                                                options={listCompanies}
                                                onChange={(e) => handleChangeCompany(e, "id_company")}
                                                valid
                                                theme={
                                                    (theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: (versionLayout === 'dark-only') ? '#4c9aff' : "#deebff"
                                                        }
                                                    })
                                                }
                                            />
                                            <FormText color='danger' hidden={!id_company_valid}>Debes de seleccionar aun cliente</FormText>

                                        </div>
                                        <div className={checkTypeClient === 3 ? 'text-right d-none' : 'text-right'}>
                                            <ButtonGroup size='xs'>
                                                <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleScannQrPatient}>
                                                    <i className="icofont icofont-qr-code p-1"></i>
                                                    &nbsp;<label className='labelcreatePatient p-1'>
                                                        {
                                                            modalScannQrPatient ? "Cancelar escaneo" : "Escanear Qr"
                                                        }
                                                    </label>
                                                </Button>
                                                <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleCreatePatient}>
                                                    <i className="fa fa-user p-1"></i>
                                                    &nbsp;<label className='labelcreatePatient p-1'>Nuevo paciente</label>
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Col>
                                </Row>

                                {informationCompany.code !== undefined && checkTypeClient === 3
                                    ?
                                    <>
                                        <Row className='m-t-15 animate__animated animate__flipInX'>
                                            <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                <small>
                                                    Código
                                                    <br />
                                                    <b>{informationCompany.code}</b>
                                                </small>
                                            </Col>
                                            <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                <small>Teléfono <br />
                                                    <b>{informationCompany.phone}</b>
                                                </small>
                                            </Col>
                                            <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                <small>
                                                    Correo <br />
                                                    <b>{informationCompany.email}</b>
                                                </small>
                                            </Col>
                                        </Row>
                                    </>
                                    : ""
                                }
                                {
                                    modalScannQrPatient
                                        ?
                                        <>
                                            <Row className='m-b-5 m-t-5'>
                                                <Col sm="12" md="12" lg="12" xl="12" className='text-center'> <br /> <br />
                                                    <label className='f-w-600 f-14 badge badge-light-primary'>Escanea el código QR del cliente</label>

                                                </Col>
                                            </Row>
                                            <Row className='m-b-5'>
                                                <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                    <img src={require('../../../assets/images/loginHelenLabs/TomaDeMuestra/Barra1.gif')} style={{ width: '150px', height: '150px', filter: "invert(100%) sepia(100%) saturate(1000%) hue-rotate(170deg)" }} />
                                                    <div style={{ opacity: "0" }}>
                                                        <input
                                                            ref={inputReadQrCode}
                                                            id='codeQrPatient'
                                                            type='text'
                                                            autoComplete='off'
                                                            autoFocus={focusQrPatient}
                                                            value={user_unique_code}
                                                            onChange={(e) => handleSelectValuesQr(e.target.value, "user_unique_code")}
                                                            onKeyDown={onKeyPressQr}
                                                            onBlur={qrCodeAutoFocus}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row className={checkTypeClient === 3 ? 'text-right d-none m-t-10' : 'text-right m-t-10'}>
                                                <Col sm="12" md="12" lg="12" xl="12">
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        value={id_patient}
                                                        name="id_patient"
                                                        method={handleChangePatient}
                                                        loincState={searchPatientState}
                                                        url="Patient/SearchPatient"
                                                        maxLenght={3}
                                                        placeholder="Búsqueda por nombre o curp"
                                                    />
                                                    <FormText color='danger' hidden={!id_patient_valid}>Debes de seleccionar un paciente</FormText>
                                                </Col>
                                            </Row>
                                            {
                                                informationPatient.name !== null
                                                    ?
                                                    <>
                                                        <Row className='m-b-5'>
                                                            <Col sm="12" md="12" lg="12" xl="12" className='text-xs-center'>
                                                                <FormText className='text-center animate__animated animate__fadeIn animate__infinite infinite animate__slow' color="danger" hidden={informationPatient.is_complete === false ? false : true}>
                                                                    <b className='f-12'><i className="icofont icofont-warning text-danger"></i> Actualiza los datos del paciente</b>
                                                                </FormText>
                                                            </Col>
                                                        </Row>
                                                        <Row className='m-t-15 animate__animated animate__flipInX'>
                                                            <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                                                <i className='fa fa-circle iconResults iconPatien'></i>
                                                                <Label className='labelPatientSize'>  F. nacimiento</Label>&nbsp;
                                                                <br />
                                                                <b className='labelPatientSize'>{informationPatient.birthday}</b>
                                                            </Col>
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                <label className='labelPatientSize'>  Télefono:</label>
                                                                <br />
                                                                <b className='labelPatientSize'>{informationPatient.phone}</b>
                                                            </Col>
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                <label className='labelPatientSize'> Sexo: </label>
                                                                <br />
                                                                <b className='labelPatientSize'>{informationPatient.gender}</b>
                                                            </Col>
                                                            <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                <label className='labelPatientSize'> Edad: </label>
                                                                <br />
                                                                <b>{informationPatient.age}</b>
                                                            </Col>
                                                        </Row>
                                                        <Row className='m-t-10 animate__animated animate__flipInX'>
                                                            <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                                {
                                                                    informationPatient.email === "" || informationPatient.email === null
                                                                        ?
                                                                        <div>
                                                                            <i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
                                                                            <label className='labelPatientSize'> E-mail: </label>
                                                                            <br />
                                                                            <b>{informationPatient.email}</b>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                            <label className='labelPatientSize'> E-mail: </label>
                                                                            <br />
                                                                            <b>{informationPatient.email}</b>
                                                                        </div>
                                                                }
                                                            </Col>
                                                            <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                                                {
                                                                    informationPatient.curp === ""
                                                                        ?
                                                                        <div>
                                                                            <i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
                                                                            <label className='labelPatientSize'>Curp:</label>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                            <label className='labelPatientSize'>Curp:</label>
                                                                            <br />
                                                                            <b>{informationPatient.curp}</b>
                                                                        </div>
                                                                }
                                                            </Col>
                                                            <Col xs="3" sm="3">
                                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                <label className='labelPatientSize'> Membresia: </label>
                                                                <br />
                                                                <b>{informationPatient.membership}</b>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                                    <i className="icofont icofont-ui-edit "></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="12" md={checkTypeClient === 1 ? 6 : 6} lg={checkTypeClient === 1 ? 6 : 6} xl={checkTypeClient === 1 ? 6 : 6}>
                        <Card className="form theme-form sizeModalDP shadow" >
                            <CardHeader className='bg-primary p-2'>
                                <h6 className='m-t-5 m-b-0 m-l-5'>
                                    <i className="fa fa-user-md f-15"></i>&nbsp; &nbsp;
                                    <Label className='labelSearchPatient f-10'>{LabelDoctor}</Label>
                                </h6>
                            </CardHeader>
                            <CardBody className='p-3'>
                                <Row>
                                    <Col sm="5"></Col>
                                    <Col sm="7">
                                        <div className='text-right'>
                                            <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleCreateDoctor}>
                                                <i className="fa fa-user-md p-1"></i>
                                                &nbsp;<label className='labelcreatePatient p-1'>Nuevo médico</label>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='m-t-10'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <SearchAsync
                                            cacheOptions={false}
                                            name="id_doctor"
                                            method={handleChangeDoctor}
                                            loincState={searchDoctorState}
                                            url="Doctors/SearchDoctor"
                                            maxLenght={3}
                                            mMultiple={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={informationDoctor.length > 0 ? 'p-0 m-t-10' : 'd-none'}>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        {
                                            informationDoctor.length > 0 && informationDoctor.map(obj => {
                                                ////console.log(obj);
                                                let a = obj.isCommercialPartner === true ? <label htmlFor="">Si</label> : <label>No</label>
                                                return (
                                                    <Row key={"doctorId-" + obj.id_doctor} className="m-t-10 animate__animated animate__flipInX">
                                                        <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'> Nombre </label>
                                                            <br />
                                                            <b className='labelPatientSize'>{obj.name}</b>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'>Especialidad:</label>
                                                            <br />
                                                            <small><b className='labelPatientSize'>{obj.name_specialty}</b></small>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'> Correo </label>
                                                            <br />
                                                            {
                                                                obj.email === null || obj.email === "" ?
                                                                    <b className='labelPatientSize'>No esta registrado</b>
                                                                    :
                                                                    <b className='labelPatientSize'>{obj.email}</b>
                                                            }
                                                        </Col>
                                                        <Col xs="1" sm="1">
                                                            <Button outline color="primary" onClick={() => handleUpdateDoctor(obj.id_doctor)} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                                <i className="icofont icofont-ui-edit "></i>
                                                            </Button>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            {
                                                                obj.isCommercialPartner === null ? ""
                                                                    :
                                                                    <div>
                                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                        <label className='labelPatientSize'> Convenio con Doctor </label>
                                                                        <br />
                                                                        <b className='labelPatientSize'>{a}</b>
                                                                    </div>

                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={viewCompany ? 'm-b-15 animate__animated animate__fadeInDown' : 'd-none'}>
                    <Col sm="6" md="6" lg="6" xl="6">
                        <Card className='shadow  sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-cogs f-20"></i> &nbsp; &nbsp;&nbsp; &nbsp;
                                <label className='labelSearchPatient' >Acciones</label>
                            </CardHeader>
                            <CardBody className='p-l-2 p-t-0 p-b-10'>
                                <Row className='m-t-5'>
                                    <Col sm="12"> <br />
                                        <Label className='labelPriority'>{LabelPrinterPoint} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <FormGroup>
                                            <Select
                                                classNamePrefix="select"
                                                name="id_printer_point"
                                                value={id_printer_point}
                                                options={listPrinterPoint}
                                                onChange={(e) => handleSelectValuesWorkOrder(e, "id_printer_point")}
                                                placeholder={PlaceHolderOption}
                                                isClearable={true}
                                            />
                                            <FormText color='danger' className='m-t-10' hidden={!id_printer_point_valid}>Selecciona una opción</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='m-t-5'>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4" >
                                        <Label className="labelCheckPrint">{CheckTextPrint}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkPrint" onChange={handleInputChangeWorkOrder} checked={checkPrint} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">{CheckTextEmail}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkEmail" onChange={handleInputChangeWorkOrder} checked={checkEmail} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>

                                </Row>
                                <Row className='m-t-5'>
                                    {
                                        enable_whatsapp === "True"
                                            ?
                                            <Col xs="6" sm="6" md="6" lg="4" xl="4" >
                                                <Label className="labelCheckPrint">{CheckTextWhats}</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkWhats" onChange={handleInputChangeWorkOrder} checked={checkWhats} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        informationDoctor.length > 0
                                            ?
                                            <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                                <Label className="labelCheckPrint">Envio a médico</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkDoctor" onChange={handleInputChangeWorkOrder} checked={checkDoctor} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }

                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" md="6" lg="6" xl="6">
                        <Card className='shadow  sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-cogs f-20"></i> &nbsp; &nbsp;&nbsp; &nbsp;
                                <label className='labelSearchPatient' >Datos Generales</label>
                            </CardHeader>
                            <CardBody className='p-l-2 p-t-0 p-b-10'>
                                <Row className='m-t-5'>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">Muestreo</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkEmail" onChange={handleInputChangeWorkOrder} checked={checkEmail} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <FormGroup>
                                            <Label className="col-form-label">Fecha de Muestreo <span className='f-14' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input type="date" className="form-control form-control-sm input-air-primary" name="date_sample" value="" onChange={handleInputChangeWorkOrder} />

                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Row>
                                            <Col sm="3" md="3" lg="3" xl="3">
                                                <Label className='f-w-600 f-12 badge badge-light-primary'>Comentarios</Label>
                                            </Col>
                                        </Row>
                                        <textarea
                                            name="observations"
                                            className='form-control form-control-sm input-air-primary'
                                            rows="2"
                                            onChange={handleInputChangeWorkOrder}
                                            value={observations}
                                        >
                                        </textarea>
                                    </Col>

                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className={viewWorkOrder ? 'm-b-15 animate__animated animate__fadeInDown' : 'd-none'}>
                    <Col sm="6" md="6" lg="6" xl="6">
                        <Card className='shadow  sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-cogs f-20"></i> &nbsp; &nbsp;&nbsp; &nbsp;
                                <label className='labelSearchPatient' >Acciones</label>
                            </CardHeader>
                            <CardBody className='p-l-2 p-t-0 p-b-10'>
                                <Row className='m-t-5'>
                                    <Col sm="12"> <br />
                                        <Label className='labelPriority'>{LabelPrinterPoint} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <FormGroup>
                                            <Select
                                                classNamePrefix="select"
                                                name="id_printer_point"
                                                value={id_printer_point}
                                                options={listPrinterPoint}
                                                onChange={(e) => handleSelectValuesWorkOrder(e, "id_printer_point")}
                                                placeholder={PlaceHolderOption}
                                                isClearable={true}
                                            />
                                            <FormText color='danger' className='m-t-10' hidden={!id_printer_point_valid}>Selecciona una opción</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='m-t-5'>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4" >
                                        <Label className="labelCheckPrint">{CheckTextPrint}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkPrint" onChange={handleInputChangeWorkOrder} checked={checkPrint} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">Resultados parciales</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkPartial" onChange={handleInputChangeWorkOrder} checked={checkPartial} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                        <Label className="labelCheckPrint">{CheckTextEmail}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkEmail" onChange={handleInputChangeWorkOrder} checked={checkEmail} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className='m-t-5'>
                                    {
                                        enable_whatsapp === "True"
                                            ?
                                            <Col xs="6" sm="6" md="6" lg="4" xl="4" >
                                                <Label className="labelCheckPrint">{CheckTextWhats}</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkWhats" onChange={handleInputChangeWorkOrder} checked={checkWhats} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        informationDoctor.length > 0
                                            ?
                                            <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                                <Label className="labelCheckPrint">Envio a médico</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkDoctor" onChange={handleInputChangeWorkOrder} checked={checkDoctor} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" md="6" lg="6" xl="6" className='p-l-0'>
                        <Card className='shadow sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-comments-o f-20"></i> &nbsp; &nbsp;&nbsp;
                                <label className='labelSearchPatient' >Observaciones</label>
                            </CardHeader>
                            <CardBody className='p-l-5 p-t-10 p-b-15'>
                                <Row className='match-height m-t-10'>
                                    <Col sm="12" md="12" lg="12" xl="12">

                                    </Col>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Row>
                                            <Col sm="3" md="3" lg="3" xl="3">
                                                <Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de Trabajo</Label>
                                            </Col>
                                            <Col sm="4" md="4" lg="4" xl="4">
                                            </Col>
                                            <Col sm="5" md="5" lg="5" xl="5" className='p-l-0 p-r-0' >
                                                <Label htmlFor="medical_order" className={showClassSD.ClassSD} outline color='primary' size="xs">
                                                    {showMessageWF.labelWF} <span class="icofont icofont-upload-alt"></span>
                                                    <Input type="file" accept="image/*,.pdf" id="medical_order" name="medical_order" onChange={handleOnChange} style={{ display: 'none' }} />
                                                </Label> &nbsp; &nbsp; &nbsp;
                                                <Button onClick={DeleteFile} className={showClass.ClassName} outline color="danger" size="xs" type="button">
                                                    <i className="fa fa-trash"></i>
                                                </Button>&nbsp; &nbsp;
                                            </Col>
                                        </Row>
                                        <textarea
                                            name="observations"
                                            className='form-control form-control-sm input-air-primary'
                                            rows="2"
                                            onChange={handleInputChangeWorkOrder}
                                            value={observations}
                                        >
                                        </textarea>
                                    </Col>
                                </Row>
                                <Row className='m-t-20 m-b-20 match-height'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de flebotomista</Label> <br />
                                        <textarea
                                            name="observations_sample"
                                            className='form-control form-control-sm input-air-primary'
                                            rows="2"
                                            onChange={handleInputChangeWorkOrder}
                                            value={observations_sample}
                                        >
                                        </textarea>
                                    </Col>
                                    <br /> <br /> <br />   <br /> <br />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className={viewWorkOrder ? 'animate__animated animate__fadeInDown' : 'd-none'}>
                    <Row className='m-b-5'>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <Card className='shadow'>
                                <CardHeader className="p-2 bg-primary">
                                    <i className="icofont icofont-laboratory f-20"></i>&nbsp; &nbsp;
                                    <label>Búsqueda de examen/perfil</label>
                                </CardHeader>
                                <CardBody className='p-3'>
                                    <Row className='p-2'>
                                        <Col sm="10"></Col>
                                        <Col sm="2">
                                            <div className='text-right'>
                                                <Button className='f-right bottonquoter' outline color='primary' size="xs" onClick={() => handleSearchQuoter()}>
                                                    <i className="icofont icofont-id"></i> Cargar Cotización
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className=''>
                                        <Col sm="12" md="12" >
                                            <FormGroup>
                                                <SearchAsync
                                                    loincState={searchExamProfileState}
                                                    name="id_profileExam"
                                                    method={handleSelectExamChange}
                                                    url="Profiles/SearchProfileExam"
                                                    maxLenght={3}
                                                />
                                                <FormText color='danger' hidden={!listTest_valid}>Debes de seleccionar un examen o perfil</FormText>
                                            </FormGroup>
                                        </Col>
                                    </Row> <br />
                                    <Row className='m-t-5'>
                                        <Col sm="12" md="12">
                                            <div className='table-responsive'> <br />
                                                {
                                                    listTest.length > 0 &&
                                                    <TableWorkOrder
                                                        key="tableExams"
                                                        items={listTest}
                                                        {
                                                        ...{
                                                            removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, number_format, changeSelectSamplings, changeValueTimeSampling, times, handleViewExamsProfile,
                                                            checkTypeClient, listid, checkDiscount, enable_tracking_toma, valid_commont
                                                        }
                                                        }
                                                    />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='m-b-15'>
                        <Col sm="12" md="4" lg="4" xl="4">
                            <Card className='shadow'>
                                <CardHeader className='bg-primary p-1'>
                                    <br />
                                    &nbsp; &nbsp; <i className="icofont icofont-prescription f-20"></i> &nbsp; &nbsp; &nbsp; &nbsp;
                                    <label className='labelIndication'>Indicaciones del Exámen / Perfil</label>
                                </CardHeader>
                                <CardBody className='p-l-1 p-t-1'>
                                    <Row>
                                        <Col sm="12" md="12" lg="12" xl="12" className='scrollWork'>
                                            {
                                                listTest.length > 0 && listTest.map((obj, index) => {
                                                    if (obj.id_exam !== null) {
                                                        return (
                                                            <div key={`indications-${index}`}>
                                                                <i className="icofont icofont-laboratory f-16 icon-Exam"></i><b className='labelIndication'>{obj.name}</b>
                                                                <Col>
                                                                    {
                                                                        (obj.listIndications === undefined || obj.listIndications.length === 0)
                                                                            ?
                                                                            <label className='text-secondary PIndication'>-SIN INDICACIONES PREVIAS </label>
                                                                            :
                                                                            obj.listIndications.map((o, i) => {
                                                                                return (
                                                                                    <ul key={`subIndications-${i}`}>
                                                                                        <label className='text-secondary PIndication'>-{o.indication}</label>
                                                                                    </ul>
                                                                                )
                                                                            })
                                                                    }
                                                                </Col>
                                                            </div>
                                                        )
                                                    }
                                                    else if (obj.id_profile !== null) {
                                                        return (
                                                            <div key={`indication-${index}`}>
                                                                <i className="icofont icofont-laboratory f-16 icon-Exam"></i>
                                                                <label className='labelIndication'>
                                                                    {obj.name.toUpperCase()}
                                                                </label>
                                                                <Col>
                                                                    {
                                                                        (obj.listIndications === undefined || obj.listIndications.length === 0)
                                                                            ?
                                                                            <label className='PIndication'>-Sin indicaciones previas </label>
                                                                            :
                                                                            <ul>
                                                                                {
                                                                                    obj.listIndications.map((ind, i) => {
                                                                                        return (
                                                                                            <li key={`subItem-${i}`}>
                                                                                                <label className='labelIndication' >{ind.name_exam}</label>
                                                                                                <ul>
                                                                                                    {
                                                                                                        (ind.listIndications === undefined || ind.listIndications.length === 0)
                                                                                                            ?
                                                                                                            <label className='PIndication'>-SIN INDICACIONES PREVIAS</label>
                                                                                                            :
                                                                                                            ind.listIndications.map((a, c) => {
                                                                                                                return (
                                                                                                                    <li key={`exaIndi-${c}`}>
                                                                                                                        <label className='PIndication' >-{a.indication}</label>
                                                                                                                    </li>
                                                                                                                )
                                                                                                            })
                                                                                                    }
                                                                                                </ul>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                    }
                                                                </Col>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md="8" lg="8" xl="8">
                            <Card className='shadow'>
                                <CardHeader className='bg-primary p-1'>
                                    <br />
                                    &nbsp; &nbsp; <i className="fa fa-usd f-20"></i>&nbsp; &nbsp;
                                    <label className='labelDateGenerales'>Información de pago</label>
                                </CardHeader>
                                <CardBody className='p-l-2 p-t-0'>
                                    <Row className='m-t-10 p-1'>
                                        <Col sm="12" md="12" lg="12" xl="12" className={ShowClassNameC}>
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Observaciones Generales</Label>
                                            <textarea name="observations_general" className='form-control form-control-sm input-air-primary'
                                                rows="2" onChange={handleInputChangeWorkOrder}
                                                value={observations_general}>
                                            </textarea>
                                        </Col>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-l-0 mt-2'>
                                            <Row>
                                                {
                                                    listTest.length > 0
                                                        ?
                                                        <Col xs="6" sm="6" md="6" lg="4" xl="4">
                                                            <Label className="labelCheckPrint">{"Emitir factura"}</Label> <br />
                                                            <Label className="switch">
                                                                <Input type="checkbox" name="checkInvoice" onChange={(e) => handleCreateInvoice(e)} checked={checkInvoice} />
                                                                <span className="slider round"></span>
                                                            </Label>
                                                        </Col>
                                                        : ""
                                                }
                                                <Col xs="12" sm="7" md="7" lg="10" xl="7">
                                                    <Button type='button' className='float-right btn-air-primary' outline color='primary' size='sm' onClick={handleAddPayMethodInputs}>
                                                        <i className='fa fa-plus'></i> Método de pago
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15'>
                                                <Table>
                                                    <thead>
                                                        <tr className='text-center border-bottom-info'>
                                                            <th className='p-1 f-12'><label className='labelResumen'>Método de pago</label></th>
                                                            <th className='p-1 f-12'><label className='labelResumen'>Monto</label></th>
                                                            <th className='p-1'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            listPayMethods.map((p, key) => {
                                                                return (
                                                                    <tr key={`metodo-${key}`}>
                                                                        <td className=''>
                                                                            <Input type='select' className='form-control input-air-primary form-control-sm' name="id_pay_method" onChange={(e) => handleDinamicInputWorkOrder(e, "id_pay_method", "listPayMethods")} disabled={totalW > 0 ? false : true}
                                                                                data-id={key} value={p.id_pay_method}
                                                                            >
                                                                                <option value={0}>Selecciona una opción</option>
                                                                                {
                                                                                    checkTypeClient === 1 ?
                                                                                        listPayMethod.length > 0 && listPayMethod.map((obj, index) => {
                                                                                            if (obj.abbreviation !== "CONV") {
                                                                                                return (
                                                                                                    <option value={obj.id_pay_method} key={`metodo-${obj.id_pay_method}`}>{`${obj.abbreviation} - ${obj.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        :
                                                                                        listPayMethod.length > 0 && listPayMethod.map((obj, index) => {
                                                                                            return (
                                                                                                <option value={obj.id_pay_method} key={`metodo-${obj.id_pay_method}`}>{`${obj.abbreviation} - ${obj.name}`}</option>
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </Input>
                                                                        </td>
                                                                        <td>
                                                                            <Input type="number" name="amount" data-id={key} disabled={totalW > 0 ? false : true} value={p.amount} placeholder="$ 0.0" step="any"
                                                                                className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                            />
                                                                        </td>
                                                                        <td className='p-1 text-center' style={{ verticalAlign: "middle" }} >
                                                                            <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(key)}><i className='icofont icofont-ui-delete'></i></Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan={3} className="text-xs-center">
                                                                <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-right'>
                                                                <b className='labelChange'>{showMessageW.labelMoney}</b>
                                                            </td>
                                                            <td>
                                                                <b className='labelChange'>$ {`${number_format(showMessageW.amount, 2)}`}</b>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Row>
                                        </Col>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-r-0 mt-2'>
                                            <Row>
                                                <Col xs="12" sm="4" md="4" lg="2" xl="4">
                                                    <Label className="labelcheckComment">{"Comentarios"}</Label> <br />
                                                    <Label className="switch">
                                                        <Input type="checkbox" name="checkComment" onChange={enableComments} checked={checkComments} />
                                                        <span className="slider round"></span>
                                                    </Label>
                                                </Col>
                                                <Col xs="12" sm="7" md="7" lg="10" xl="7">
                                                    {
                                                        specialDiscount === null ?
                                                            <Button type='button' className='float-right btn-air-primary' outline color='primary' size='sm' onClick={() => handleModalSpecialDiscount()}>
                                                                <i className='fa fa-plus'></i> Descuento especial
                                                            </Button>
                                                            :
                                                            <Button type='button' disabled className='float-right btn-air-primary disabled' outline color='primary' size='sm' onClick={null}>
                                                                <i className='fa fa-check'></i> Descuento especial aplicado
                                                            </Button>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                                <Col sm="12">
                                                    <Table className="table-xs">
                                                        <thead>
                                                            <tr>
                                                                <th className='p-1 f-12' colSpan={2}>
                                                                    <b className='labelResumen'>Resúmen</b>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14'><label className='labelPrices'>Subtotal</label></td>
                                                                <td className='text-right f-14'><label className='labelPrices'>$ {number_format(subTotalW, 2)}</label></td>
                                                            </tr>
                                                            <tr style={{ cursor: "pointer" }} className="border-bottom-primary">
                                                                <td className='f-14'><label className='labelPrices'>Descuento</label>  </td>
                                                                <td className="text-right f-14">
                                                                    <b className="text-danger labelPrices">(-) ${number_format(totalDiscountW, 2)}</b>
                                                                    <a onClick={handleModalListCommonDiscounts} class="f-w-600 f-12 badge badge-light-primary ml-2"><i class="fa fa-plus-circle"></i></a>
                                                                </td>
                                                            </tr>
                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14'><label>I.V.A</label></td>
                                                                <td className='text-right f-14'><label><b>${number_format(totalTaxesW, 2)}</b></label></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='f-14'> <b className='f-14'>Total</b></td>
                                                                <td className="text-right f-14"><b className='f-14'>$ {number_format(totalW, 2)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15'>
                                                <Col sm="12">
                                                    {
                                                        listTest.length > 0 &&
                                                        <ButtonGroup className="btn-group-pill btn-group-sm" size='sm' style={{ width: '100%' }}>
                                                            <Button
                                                                className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                                                                outline
                                                                color='primary-2x'
                                                                disabled={enableBtnSaveWorkOrder}
                                                                onClick={() => saveWorkOrder()}
                                                            >
                                                                <label className="labelSaveCreate">{BtnSaveWorkOrder}</label>
                                                            </Button>
                                                            <Button
                                                                className='btn-air-danger'
                                                                outline
                                                                color='danger'
                                                                onClick={() => cancellWorkOrder()}
                                                            >
                                                                <label className='labelSaveCreate'>Cancelar</label>
                                                            </Button>
                                                        </ButtonGroup>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
            <ModalPatient
                {
                ...{
                    modal: modalPatient, toggle: togglePatient, modalTitle, method: methodPatient,
                    togglesg: toggleModalSocialGender, modalsg: modalSocialGender,
                    loading: loadingPatient, handleInputChange: handleInputChangePatient, handleInputChangeSocialGender, handlePickerValues: handlePickerValuesPatient,
                    validaciones: validationsPatient, validationsSG, saveCreate: saveCreatePatient, saveSocialGender,
                    states: listStates, municipalities: listMunicipalities, socialgenders: Listsocialgender, handleChangeState: handleChangeStatePatient,
                    saveUpdatePatient, formValuesPatient, curp: formValuesPatient.curp, email_patient: formValuesPatient.email_patient, listBloodType, handleDate: handleDatePatient, handleModalSocialGender,
                    formSocialGender, name_genderc: formSocialGender.name_genderc, abreviaturac: formSocialGender.abreviaturac,
                }
                }
            />
            <ModalDoctor
                nameInputs={nameInputsDoctor}
                addSpecialty={true}
                {
                ...{
                    modal: modalDoctor, toggle: toggleDoctor, handleInputChange: handleInputChangeDoctor, saveCreate: saveCreateDoctor,
                    saveUpdate: saveUpdateDoctor, loading: loadingDoctor, method: methodDoctor, specialties, validaciones: validationsDoctor,
                    id_specialty, name_specialty, name: name_doctor, professional_license, phone: phone_doctor, address: address_doctor,
                    email: email_doctor, isCommercialPartner
                }
                }
            />
            <ModalViewExams
                {
                ...{
                    modalViewExamsP, toggleModalViewExams, viewExamsProfile
                }
                }
            />

            <ModalSpecialDiscount
                {
                ...{
                    modal: modalSpecialDiscounts, toggle: toggleSpecialDiscount, special_discount_code, handleInputChangeSpecialDiscount, validateCodeSpecialDiscount
                }
                }
            />

            <ModalCommonDiscounts
                {
                ...{
                    modal: modalCommonDiscount, toggle: toggleCommonDiscount,
                    arrayCommonDiscounts, arrayNameDiscounts: arrayNameDiscount, checkDiscount, clearDiscount,
                    arrayAgreementDiscounts, valid_commont
                }
                }
            />

            <ModalQuoter
                {
                ...{
                    modal: modalQuoter, toggle: toggleModalQuoter, modalTitle, method: methodQuoter, loading: loadingQuoter,
                    handleGetQuoter, code_quoter, date_quoter, listQuoter,
                    handleInputChangeQuoter, validationsQuoter, searchQuoterByCode,
                    enableViewTest, viewTestQuoter, handleViewTest, handleCloseViewTest,
                }
                }
            />

            <ModalInvoice
                {
                ...{
                    modal: modalInvoice, toggle: toggleModalInvoice, modalTitle, loading: loadingInvoice, method: methodInvoice,
                    listUseCfdi, handleInputChange: handleInputChangeWorkOrder,
                    validationsInvoice, saveInvoice, cancelInvoice,
                    business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice,
                }
                }
            />
            <ModalRepeatProfile
                {
                ...{
                    modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile
                }
                }
            />
        </Fragment>
    )

}
