import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../assets/css/pantalla1.css";
import "../../../assets/css/Toma1.css";
import { Container, Row, Col, Label, Card, Media, Button, Alert } from "reactstrap";
import { useConsentimiento } from "../../../hooks/tomaMuestra/useConsentimiento";
import { useLogin } from "../../../hooks/tomaMuestra/useLogin";
import { sendRequest } from "../../../hooks/requests/useRequest";
import { ModalCancellation1 } from "./ModalCancellation1";
import 'animate.css';
import DeniReactTreeView from "deni-react-treeview"
import Select from 'react-select';
import { useForm } from "../../../hooks/forms/useForm";
import { useSweetAlert } from "../../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from "../../../hooks/tokens/useToken";
import { ModalPatientDetaill } from "./ModalPatientDetaill";
import { ToolTips } from '../../../components/tooltips/ToolTips';




export const IndexTomaMuestra = () => {

  const { navigaConsentimientoInformado1, hour, minute, second,
    typeModalCancell, modalCancell, toggleCancell1,
    //Detail Patient
    typeModalPatient, modalPatient, toggleDetailPatient, handlePatient
  } = useConsentimiento();

  //#region RECEIVE NIM
  const history = useHistory();
  const { nimm } = useParams();
  const { time, reload, } = useLogin();
  let name5 = localStorage.getItem('willson');
  let name2 = localStorage.getItem('flebotomisaNombre');
  //#region STATE
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const [message, setMessage] = useState(true);
  //#endregion STATE
  const [patien, setPatien] = useState("");
  const [idGender, setIdGender] = useState(0);
  const [commetFlebotomista, setCommetFlebotomista] = useState("");
  const [images, setImages] = useState([]);
  //#region sample
  const themes = ['green']
  const [itemsTree, setItemsTree] = useState([]);
  const treeviewRef = useRef(null);
  const [loadin, setLoadin] = useState(false);
  const [enableImagen, setEnableImagen] = useState(false);
  const [reason, setReason] = useState([]);
  const [idW, setIdW] = useState(0);
  const [createSweet] = useSweetAlert();
  const [phone, setPhone] = useState("");
  const [curp, setCurp] = useState("");
  const [mail, setMail] = useState("");
  const [age, setAge] = useState("");


  let gato = 1
  let gato2 = 4

  useEffect(() => {
    if (nimm !== undefined) {
      getDataFromNimPost(nimm)
      handleReason();
    }
  }, [nimm]);


  const getDataFromNimPost = async (nimm) => {
    setLoadin(false);

    let _body = {
      nim: nimm.substring(0, 13),
      id_departament: localStorage.getItem('depW')
    }

    const _response = await handleRequest("POST", "Sass/GetDataFromPatient", "sample", _body);

    

    if (_response !== null) {
      setLoadin(true);

      let nim = _response.lista_pruebas;

      let _isToma = await handleRequest("GET", `Sample/DetailSample/${nim[0].nim}`, 'sample');
      

      let is_toma = _isToma[0];
      let validateToma = is_toma.finallyFech;

      let _namePatient = ""
      let _idgender = 0
      let _comment = ""
      let _arrayNuevo = [];
      let _dataInformationStudy = [];
      let _background_image = [];
      let _idW = 0;
      let _mail = "";
      let _curp = "";
      let _phone = "";
      let _age = "";

      _response.lista_pruebas.forEach(_find => {
        _namePatient = _find.name_patient
        _idgender = _find.gender
        _comment = _find.observations_sample
        _background_image = _find.background_image
        _idW = _find.id_work_order
        _mail = _find.mail
        _curp = _find.curp
        _phone = _find.phone
        _age = _find.age

        _arrayNuevo.push({
          "profiles": _find.listProfile,
          "exams": _find.listExam,
          "is_toma" : _isToma.length > 0  && validateToma !== '' ? _isToma : ''
        })

        _dataInformationStudy.push({
          "Nuevo": _find.listExam
        })

        let tree = createTree(_arrayNuevo);
        setItemsTree(tree);
        
      });
      setPatien(_namePatient);
      setIdGender(_idgender);
      setCommetFlebotomista(_comment);
      setImages(_background_image)
      setIdW(_idW);
      setPhone(_phone);
      setCurp(_curp);
      setAge(_age);
      setMail(_mail)
    }
  }

  const getCancelSample = async () => {
    let _listTemp = await handleRequest("GET", `Sample/CancelSample/${nimm}`, "sample");
    if (_listTemp !== null) {
      navigateInicio();
    }
  }

  const navigateInicio = () => {
    history.push('/dashboard/defaultTomaDeMuestra');
  }
  //#endregion COLLECTION NIM

  const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
    id_return_reason: 0,
  });

  const { id_return_reason } = formR;
  //#region Exams and Profiles the sample toman and patient 
  const createTree = (_arrayNew) => {

    let _tree = [];
    _arrayNew.forEach(_findEP => {
      if (_findEP.exams.length > 0) {
        _findEP.exams.forEach(_e => {
          _tree.push({
            "children": [],
            "id": _e.id_work_order_exam,
            "state": _e.check_specimen === true ? 1 : 2,
            "text": _e.name,
            "isLeaf": _e.check_specimen,
            "refused_check_specimen": (_e.is_toma === false && _e.check_forwarded === false && _e.state === 2) ? true : _e.refused_check_specimen,
            "check_forwarded": _e.check_forwarded,
            "is_toma" : _findEP.is_toma
          })
        });
      }
      if (_findEP.profiles.length > 0) {
        _findEP.profiles.forEach(_e => {
          let _examenes_aux = [];
          let _checkParent = 1;
          let checy = 0;
          let exam = true
          
          _e.listExam.forEach(_exam => {
            if (!_exam.check_specimen) {
              _checkParent = 2;
            }
            _examenes_aux.push({
              "id": _exam.id_work_order_exam,
              "isLeaf": _exam.check_specimen,
              "state": _exam.check_specimen === true ? 1 : 2,
              "text": _exam.name,
              "refused_check_specimen": (_exam.is_toma === false && _exam.check_forwarded === true && _exam.state === 2) ? true : _exam.refused_check_specimen,
              "check_forwarded": _exam.check_forwarded,
              "is_toma" : _exam.is_toma
            })
            checy = _e.refused_check_specimen === false ? 1 : 2
            exam = _exam.refused_check_specimen
          });
          _tree.push({
            "children": _examenes_aux,
            "state": checy,
            "text": _e.name_profile,
            "refused_check_specimen": exam,
            "is_toma": _findEP.is_toma
          })
        });
      }
    });
    return _tree;
  }



  const onRenderItem = (_find) => {
    return (
      <div className="treeview-item-example">
        {_find.refused_check_specimen === false ? (
          _find.check_forwarded === true || (_find.is_toma === true && _find.refused_check_specimen === false) ? (
            <span className='text-secondary'>
              <i className="icofont icofont-laboratory f-12"></i>{_find.text}
            </span>
          ) : (
            _find.check_forwarded === false ? (
              <span className=''>
                <i className="icofont icofont-laboratory f-12"></i>{_find.text}
              </span>
            ) : 
            (<span className=''>
            <i className="icofont icofont-laboratory f-12"></i>{_find.text}
          </span>)
          )
        ) : (
          <span style={{color:'#FF8F00'}}>
            <i style={{color:'#FF8F00'}} className="icofont icofont-laboratory f-12"></i>{_find.text}
          </span>
        )}
      </div>
    );
    
  }
  //#endregion Exams and Profiles



  const handleImage = () => {
    setEnableImagen(true);
  }

  const handleCloseViewTest = () => {
    setEnableImagen(!enableImagen);
  }

  const handleReason = async () => {
    let listTmp = await handleRequest("GET", "ReturnReason/List?is_sample=true&is_work_order=false&is_tracking=false&is_send=false", "sample");

    if (listTmp !== null) {
      let list = [];

      listTmp.forEach((obj, i) => {
        list.push({
          value: obj.id_return_reason,
          label: obj.reason
        });
      });
      setReason([...list]);
    }
  }
  //Cancell Sample
  const saveCancellSample = async () => {
    if (validationSample()) {
      return;
    }
    let _id_return_reason2 = formR.id_return_reason.value

    let _body = [{
      "id_work_order_exam": 1,
      "id_return_reason": _id_return_reason2,
      "origen": "Con Toma"
    }]

    const _response = await handleRequest("POST", "Sass/CreateRefusedGlobal", "refused", _body);
    if (_response !== null) {
      navigateInicio();
    }
  }

  //validations Sample
  const [validationsS, setvalidationsS] = useState({
    id_return_reason: false
  });

  const validationSample = () => {
    let _statusValidation = false;
    let _newValidations = {};
    let _id_return_reason2 = formR.id_return_reason.value

    if (_id_return_reason2 === undefined) {
      _newValidations = {
        ..._newValidations,
        id_return_reason: true,
      };
      _statusValidation = true;
    }
    else {
      _newValidations = {
        ..._newValidations,
        id_return_reason: false,
      };
    }
    setvalidationsS(_newValidations);
    return _statusValidation;
  }

  //GLOBAL REQUEST
  const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
    let requestOptions = {
      method: method
    };

    if (body !== null) {
      requestOptions = {
        ...requestOptions,
        body: JSON.stringify(body)
      };
    }

    const response = await sendRequest(requestOptions, methodUrl);

    if (response.code === 200) {
      return response.data;
    }
    else if (response.code === 401) {
      validarSesion(history, response.code, handleRequest);
    }
    else {
      createSweet("warning", "warning", nameMethod, response.data.msg);
    }
    return null;
  }

  const AlertItem = props => {
    const { item } = props;
    const [Open, setOpen] = useState(true);

    return (
      <Alert className="alert-dismissible"
        color={item.alertcolor}
        isOpen={Open}
        target={"Alert-" + item.id}
      >
        {item.icon}
        <p>{item.alerttxt}</p>
      </Alert>
    );
  }


  return (
    <Fragment>
      <Container fluid={true} className="bg-barra1">
        <Col sm="12" md="12" lg="12" xl="12" className="p-0">
          <Row>

            <Col sm="12" md="12" lg="12" xl="12" className="p-l-1"> <br />
              <div className='box-departamento'>
                <Col sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                  <Row>
                    <Col sm="2" md="2" lg="2" xl="2" className='p-l-0'>
                      <Label className='labelToma1'>{time}</Label>
                    </Col>
                    <Col sm="1" md="1" lg="1" xl="1" >
                    </Col>
                    <Col sm="6" md="6" lg="6" xl="6" style={{ textAlign: "center" }}>
                      <label className="labelToma1">Toma de Muestra&nbsp;/&nbsp;{name5}</label>
                    </Col>

                    <Col sm="2" md="2" lg="2" xl="2" className='p-1' style={{ textAlign: "-webkit-right" }}>
                      {
                        show1 ? (<label className='rc'>{hour}:{minute}:{second}</label>) : ""
                      }
                    </Col>
                    <Col sm="1" md="1" lg="1" xl="1" className='p-2' >
                      {
                        show1 ? (<img className="" style={{ width: "38%" }} src={require("../../../assetsToma/imagenes/Cronometro.png")} alt="Cronometro" />
                        ) : ""
                      }
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Col>
        <br />

        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-0 p-r-0"  >
          <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-0 p-r-0" >
            {
              loadin === false ?
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center p-l-0 p-r-0'>
                  <Card className='shadow' >
                    <Row className='mt-2'> <br />
                      <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br /> <br /> <br /> <br />
                        <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col sm="3" md="3" lg="3" className='text-center'></Col>
                      <Col sm="2" md="2" lg="2" className='text-left p-r-0'>
                        <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p> <br />

                      </Col>
                      <Col sm="1" md="1" lg="1" className='text-center'>
                        <br />
                        <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                      </Col>
                      <Col sm="1" md="1" lg="1" className='text-center'>
                        <br />
                        <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                      </Col>
                      <Col sm="1" md="1" lg="1" className='text-center'>
                        <br />
                        <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                      </Col>
                      <Col sm="2" md="2" lg="2" className='text-center'></Col>
                    </Row>

                  </Card>
                </Col>
                :
                <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
                  <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }}>
                    <label className="f-w-600 f-20 badge badge-light-secondary">HOLA&nbsp; {patien}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      (images === null || images === "") ? "" : <i className="icofont icofont-document-search f-24 txt-secondary animate__slower animate__infinite  animate__animated animate__fadeInDown pointer" onClick={handleImage}></i>
                    }
                    {
                      <i className="fa fa-folder-open-o f-30 txt-secondary pointer" onClick={handlePatient} id={`tooltip-specimen-${"llabe"}`}>
                        <ToolTips
                          placement="top"
                          dataTarget={`tooltip-specimen-${"llabe"}`}
                          dataText={'Detalle General del paciente'}
                        />
                      </i>
                    }
                  </Col>
                  <Col style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0'>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                      {
                        show ?
                          (
                            ""
                          )
                          :
                          (
                            <Col xs="12" sm="12" md="12" lg="12" xl="12"><br />
                              <div className='username-Active ' >
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <Row>
                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }}>
                                      <br />
                                      <i className="fa fa-circle f-30 icon-active"></i>
                                    </Col>
                                    <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0' > <br />
                                      <label className='label-menuToma p-0 ' >{name2}</label>
                                    </Col>
                                  </Row>
                                </Col>
                              </div> <br />
                              <div className='username-Active pointer' onClick={reload} >
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <Row>
                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                      <img className=" " src={require("../../../assetsToma/imagenes/Recargar.png")} style={{ width: "50%" }} alt="" />
                                    </Col>
                                    <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 ' > <br />
                                      <label className='r-label-active p-0 pointer'>{"Recargar"}</label>
                                    </Col>
                                  </Row>
                                </Col>
                              </div> <br />
                              <div className='username-Active pointer' onClick={() => { setShow1(!show1); }}>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <Row>
                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                      <img className=" " src={require("../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "50%" }} alt="" />
                                    </Col>
                                    <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                                      <label className='r-label-active p-0 pointer'>{"Cronómetro"}</label>
                                    </Col>
                                  </Row>
                                </Col>
                              </div><br />
                              <div className='username-Active pointer' onClick={() => getCancelSample()} >
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                  <Row>
                                    <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0' style={{ textAlign: "-webkit-center" }}> <br />
                                      <img className=" " src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "39%" }} alt="" />
                                    </Col>
                                    <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                                      <label className='r-label-active-omitir-Encuesta p-0 pointer' >{"Cancelar Toma"}</label>
                                    </Col>
                                  </Row>
                                </Col>
                              </div>
                            </Col>
                          )
                      }
                    </Col>
                  </Col>
                  <Card className={`${!enableImagen ? 'm-t-10 animate__animated animate__fadeIn shadow cardSampleD' : 'animate__animated animate__fadeIn shadow cardSampleD '}`} hidden={enableImagen} style={{ height: "340px" }} >
                    <Row>
                      <Col xs="2" sm="2" md="2" lg="2" xl="2" className="p-r-0" > <br /> <br />
                        <div>
                          {
                            (idGender) === 2 ?
                              <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "80%" }} alt="" /></div>
                              :
                              <div className="profile-vector"><img className="" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "80%" }} alt="" /></div>
                          }


                        </div>
                      </Col>
                      
                      <Col xs="6" sm="6" md="6" lg="6" xl="6" className="p-l-0 p-r-0" style={{ textAlign: "center" }}> <br /> <br />
                      
                       
                     
                        <Row>
                          {
                           
                            itemsTree.length === 0 ?
                              <div className="LoadingSampling1">

                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br /> <br />
                                  <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '120px' }} />
                                </Col>
                                <Row className='mt-2'>
                                  <Col xs="1" sm="1" md="1" lg="1" xl="1" >
                                  </Col>
                                  <Col xs="9" sm="9" md="9" lg="9" xl="9" className='text-left p-r-0'>
                                    <p className='f-12 text-primary'> <b>No cuenta con Estudios En esta Area</b></p>
                                    <label className="txt-secondary">(cancele la toma para continuar...)</label>
                                  </Col>
                                </Row>
                              </div>
                              :
                              (
                                <div className="col-12">
                                  <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                    <ul className="list-group" style={{display:'-webkit-box', width:'100%'}}>
                                    <li className="list-group-item list-group-item-success">A recibir</li>
                                      <li className="list-group-item list-group-item-secondary">En posesión </li>
                                      <li style={{backgroundColor:'#FF8F00'}} className="list-group-item">Pendiente</li>
                                    </ul>
                                  </Col>
                                  <DeniReactTreeView
                                    ref={treeviewRef}
                                    style={{ marginRight: '10px', marginBottom: '10px', height: "270px", textAlign: "center", width: "100%", marginLeft: '10px' }}
                                    showCheckbox={false}
                                    showIcon={false}
                                    items={itemsTree}
                                    selectRow={false}
                                    onRenderItem={onRenderItem}
                                    theme={themes}
                                    className="deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected"
                                  />
                                </div>
                              )
                              
                          }
                        </Row>
                        <Row>
                        </Row>
                      </Col>
                      <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "center" }}> <br /> <br />
                        {
                          message ?
                            <div className="profile-vector">


                              <img src={require("../../../assets/images/loginHelenLabs/dashboard/Examenes.svg")} style={{ width: "80%" }} />
                            </div>
                            :
                            (
                              <div className="animate__animated animate__pulse">
                                <div className="comentFlebotomistaSampling1">
                                  <label htmlFor="">{commetFlebotomista}</label>
                                </div>
                              </div>
                            )
                        }


                      </Col>
                    </Row>
                  </Card>
                  <Col sm="12" className={`${enableImagen ? 'animate__animated animate__fadeIn m-t-10  p-l-0 p-r-0' : 'd-none'} `}>
                    <Row>
                      <Col sm={gato} xl={gato2} className="p-r-0" >
                        {
                          gato2 ? <DeniReactTreeView
                            ref={treeviewRef}
                            style={{ marginRight: '10px', marginBottom: '10px', width: "90%", textAlign: "center" }}
                            showCheckbox={false}
                            showIcon={false}
                            items={itemsTree}
                            selectRow={false}
                            onRenderItem={onRenderItem}
                            theme={themes}
                          /> : <label htmlFor=""></label>
                        }
                        {
                          gato === 1 ? <Button color="primary" onClick={handleCloseViewTest}  >Regresar</Button> : <Button color="primary" onClick={handleCloseViewTest} >Regresar</Button>
                        }
                      </Col>
                      <Col sm="11" xl="8" className="p-l-0" >
                        <Card style={{ backgroundColor: "#f0f8fd", height: "350px" }} className="shadow" >
                          <br />
                          <Col sm="12" className="p-l-0 p-r-0" >
                            <Row>
                              <Col sm="5">
                                <Media
                                  src={(images !== undefined) ? "data:image/png;base64," + images : ""}
                                />
                              </Col>
                              <Col sm="7">
                                {
                                  (validationsS.id_return_reason) &&
                                  <Col sm="12">
                                    <Row>
                                      <Col sm="1" className="divIne" >
                                        <i className="icon-alert f-16"></i>
                                      </Col>

                                      <Col sm="10" className="alert-ine-toma p-1" >
                                        <label className="txt-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seleccione un motivo para cancelar la toma.</label>
                                      </Col>
                                    </Row>
                                  </Col>
                                }
                                <br />
                                <label className="txt-secondary f-12">En caso que la persona no sea la correspondiente a la crencial, seleccionar el motivo para cancelar la toma</label>
                                <br />
                                <Col sm="9" className='p-l-0' > <br />
                                  <label className='txt-secondary f-14'>Responsable del Rechazo:</label>&nbsp;&nbsp;<b className='f-12'>{name2}</b>
                                  <br />
                                  <label className='txt-secondary f-14'>Paciente:</label>&nbsp;&nbsp;<b className='f-12'>{patien}</b>
                                  <br />
                                  <label className='txt-secondary f-14'>Departamento:</label>&nbsp;&nbsp;<b className='f-12'>{name5}</b>
                                </Col>
                                <div>
                                  <br />
                                  <b className='txt-danger f-12'>Selecciona un motivo por el cual estas cancelando la Toma:</b>
                                  <Select
                                    classNamePrefix="select"
                                    name="id_return_reason"
                                    value={id_return_reason}
                                    options={reason}
                                    onChange={(e) => handleSelectValuesR(e, "id_return_reason")}
                                    isClearable={true}
                                    placeholder={"Selecciona un motivo..."}
                                  />

                                </div>
                                <br />
                                <Col sm="12" className="p-r-0"  >
                                  <Row>
                                    <Col sm="6">
                                    </Col>
                                    <Col sm="6" style={{ textAlign: "end" }}>
                                      <Button color="danger" onClick={() => saveCancellSample()} >Cancelar Toma</Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Col>
                            </Row>

                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Col>
            }
          </Col>
        </Col >
        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-0" style={{ textAlign: "-webkit-center" }}>
          <button className="confirm-Button-study-Toma1" onClick={() => navigaConsentimientoInformado1(nimm)}>Estos son mis estudios</button>
        </Col>
        {/* <Col style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0'>
          <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />
            {
              show ?
                (
                  ""
                )
                :
                (
                  <Col xs="12" sm="12" md="12" lg="12" xl="12"><br />
                    <div className='username-Active ' >
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }}>
                            <br />
                            <i className="fa fa-circle f-30 icon-active"></i>
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0' > <br />
                            <label className='label-menuToma p-0 ' >{name2}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div> <br />
                    <div className='username-Active pointer' onClick={reload} >
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/Recargar.png")} style={{ width: "50%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 ' > <br />
                            <label className='r-label-active p-0 pointer'>{"Recargar"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div> <br />
                    <div className='username-Active pointer' onClick={() => { setShow1(!show1); }}>
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "50%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                            <label className='r-label-active p-0 pointer'>{"Cronómetro"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div><br />
                    <div className='username-Active pointer' onClick={() => getCancelSample()} >
                      <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Row>
                          <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0' style={{ textAlign: "-webkit-center" }}> <br />
                            <img className=" " src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "39%" }} alt="" />
                          </Col>
                          <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                            <label className='r-label-active-omitir-Encuesta p-0 pointer' >{"Cancelar Toma"}</label>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                )
            }
          </Col>
        </Col> */}
        {
          commetFlebotomista === null || commetFlebotomista === "" ?
            ""
            :
            <div className='tap-top-chat' style={{ display: "block" }} onClick={() => { setMessage(!message) }}  >
              <img className="imgMessage" src={require("../../../assets/images/loginHelenLabs/TomaDeMuestra/correo.gif")} /> <br />
            </div>
        }
        <div className='tap-top' style={{ display: "block" }} onClick={() => { setShow(!show) }}><i className="icon-angle-double-up f-24"></i>
        </div>

      </Container >

      <ModalCancellation1
        {
        ...{ typeModalCancell, modalCancell, toggleCancell1, getCancelSample }
        }
      />

      <ModalPatientDetaill
        {
        ...{ typeModalPatient, modalPatient, toggleDetailPatient, handlePatient, patien, idGender, mail, phone, curp, age }
        }

      />


    </Fragment >
  );
};
