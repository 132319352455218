import React, { useCallback } from 'react'
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';
import { useForm } from '../../hooks/forms/useForm';
import 'moment/locale/es';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";


export const useGraph = () => {


    const [createSweet] = useSweetAlert();

    const history = useHistory();

    //CHART EXAM
    const [serie, setSerie] = useState([]);
    //CHART PROFILE
    const [serie2, setSerie2] = useState([]);


    const [examProfile, setexamProfile] = useState([]);

    //CARDS CHART EXAM
    const [maximo, setMaximo] = useState(0);
    const [minimo, setMinimo] = useState(0);
    const [promedio, setPromedio] = useState(0);

    //CARDS CHART PROFILE
    const [maximoP, setMaximoP] = useState(0);
    const [minimoP, setMinimoP] = useState(0);
    const [promedioP, setPromedioP] = useState(0);


    const [formValues, handleInputChange, reset, handleUpdateValues, handlePickerValues, handleSelectValues] = useForm({
        id_exam: 0,
        id_profile: 0,
        from: "",
        to: "",
        fromm: "",
        too: ""
    });


    const { id_exam, id_profile, from, to, fromm, too } = formValues;


    const [validacionesChart, setValidacionesChart] = useState({
        from_valid: false,
    })



    const handleSelectExamChange = (e) => {
        handleInputChange(e)

        let examProfile = e.target.value;
        if (examProfile !== 0 && examProfile !== null) {
            examProfile = examProfile.split("-");

            let mIdExam = examProfile[0];
            let mTipo = examProfile[1];

            if (mTipo === "1") {
                createSweet("error", "error", "No se pueden agregar perfiles ");

            }

        }
    }


    const getPriceChart = async () => {


        let idExam = 0;

        if (formValues.id_exam != "") {
            let profileExam = formValues.id_exam.split("-");

            idExam = profileExam[0]
        }


        let requestOptions = {
            method: 'GET'
        };


        let mfrom = (formValues.from === '') ? moment().format("YYYY-MM-DD") : moment(formValues.from).format("YYYY-MM-DD");
        let mto = (formValues.to === '') ? moment().format("YYYY-MM-DD") : moment(formValues.to).format("YYYY-MM-DD");

        const respuesta = await sendRequest(requestOptions, `Reports/GetExamPricesChart?IdExam=${idExam}&from=${mfrom}&to=${mto}`);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                let min = Infinity;
                let max = 0;
                let sum = 0;
                let prom = 0

                let precioVenta = respuesta.data[1]
                sum = 0;

                precioVenta.data.forEach(obj => {


                    if (obj.y > 0 && min > obj.y)
                        min = obj.y;

                    if (obj.y > 0 && max < obj.y)
                        max = obj.y;

                    sum += parseFloat(obj.y);

                });

                prom = sum / precioVenta.data.length


                if (!Number.isFinite(min)) {
                    min = 0
                }
                setMaximo(max);
                setMinimo(min);
                setPromedio(prom)
                setSerie(respuesta.data);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron los datos");
                setSerie([]);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getPriceChart);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Graficas", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", "Error de  conexion");
        //     validarSesion(history, respuesta.code, getPriceChart);
        // }
    }



    const getPriceChartProfile = async () => {


        let idExam = 0;

        if (formValues.id_profile != "") {
            let profileExam = formValues.id_profile.split("-");

            idExam = profileExam[0]
        }


        let requestOptions = {
            method: 'GET'
        };


        let mfrom = (formValues.fromm === '') ? moment().format("YYYY-MM-DD") : moment(formValues.fromm).format("YYYY-MM-DD");
        let mto = (formValues.too === '') ? moment().format("YYYY-MM-DD") : moment(formValues.too).format("YYYY-MM-DD");


        const respuesta = await sendRequest(requestOptions, `Reports/GetProfilePricesChart?idProfile=${idExam}&from=${mfrom}&to=${mto}`);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                let min = Infinity;
                let max = 0;
                let sum = 0;
                let prom = 0

                respuesta.data.forEach(value => {

                    value.data.forEach(obj => {

                        if (obj.y > 0 && min > obj.y)
                            min = obj.y;

                        if (obj.y > 0 && max < obj.y)
                            max = obj.y;

                        sum += parseFloat(obj.y);

                    });

                    prom = sum / value.data.length

                    if (!Number.isFinite(min)) {
                        min = 0
                    }
                });

                setMaximoP(max);
                setMinimoP(min);
                setPromedioP(prom);
                setSerie2(respuesta.data);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron los datos");
                setSerie2([]);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getPriceChartProfile);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Costo de produccion", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Costo de produccion", respuesta.data.msg);
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", "Error de  conexion");
        //     validarSesion(history, respuesta.code, getPriceChartProfile);
        // }
    }


    const validInputDate = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }


    const validToInputDate = (current) => {
        
        let date = moment(from).subtract(1, "day");

        return current.isAfter(date);
    }

    const validInputDateP = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }

    const validToInputDateP = (current) => {
        
        let date = moment(fromm).subtract(1, "day");

        return current.isAfter(date);
    }   

    const handleCreate = () => {
        handleUpdateValues({
            id_exam: 0,
            id_profile: 0,
            from: new Date(),
            to: "",
            fromm: new Date(),
            too: ""
        });


        setValidacionesChart({
            from_valid: false,
        });
    }

    const obtenerDatos = () => {
        getPriceChart();
    }

    const obtenerDatoss = () => {
        getPriceChartProfile();
    }

//#46AFE5

    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#46AFE5", "#0079C7"],

        
        // fill: {
        //     type: 'gradient' 
        // },

        stroke: {
            width: [4, 4]
        },
        plotOptions: {
            bar: {
                columnWidth: "20%"
            }
        },

        xaxis: {
            type: 'datetime',
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#46AFE5"
                },
                labels: {
                    style: {
                        colors: "#46AFE5"
                    }
                },
                title: {
                    text: "Costo de Producción",
                    style: {
                        color: "#46AFE5"
                    }
                }
            },
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#0079C7"
                },
                labels: {
                    style: {
                        colors: "#0079C7"
                    }
                },
                title: {
                    text: "Costo de Venta",
                    style: {
                        color: "#0079C7"
                    }
                }
            }
        ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$" + val.toFixed(2)
                }
            }
        },
        stroke: {
            curve: 'straight'
        },

        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
    }


    const optionss = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#46AFE5", "#0079C7"],
        stroke: {
            width: [4, 4]
        },
        plotOptions: {
            bar: {
                columnWidth: "20%"
            }
        },

        xaxis: {
            type: 'datetime',
        },
        yaxis: [
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#46AFE5"
                },
                labels: {
                    style: {
                        colors: "#46AFE5"
                    }
                },
                title: {
                    text: "Costo de Venta",
                    style: {
                        color: "#46AFE5"
                    }
                },
                categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            }

        ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$" + val.toFixed(2)
                }
            }
        },
        stroke: {
            curve: 'straight'
        },

        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },


    }

    return {
        serie, options, optionss,
        id_exam, handleInputChange,
        validInputDate, validToInputDate, from, to, handleCreate, validacionesChart,
        handlePickerValues, obtenerDatos,
        //FORMVALUES  
        handleSelectExamChange, examProfile, handleInputChange,
        id_profile, serie2, obtenerDatoss,
        fromm, too,
        //CARD CHART EXAM
        maximo, minimo, promedio,
        //CARD CHART PROFILE
        maximoP, minimoP, promedioP,
        validToInputDateP, validInputDateP

    }
}
