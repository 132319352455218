import React, { Fragment } from 'react'
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { ListCardSurvey } from './ListCardSurvey';
import { useSurvey } from '../../hooks/survey/useSurvey';


export const IndexSurvey = () => {

    let data = 1

    let filter = ["fil"];

    const {
        navigateSatisfaction

    } = useSurvey();




  return (
     <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {"Banco de Preguntas"}
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={navigateSatisfaction} ><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <Row>
                                <Col md="12" className='p-1 '>
                                    <CardBody>
                                    <ListCardSurvey
                                        filterProps={filter}
                                        type="list"
                                        inputFiter={true}
                                        items={data}
                                        // datos={infoData}
                                        // {...{ navigateUpdate, handleDelete, handleUpdateStatus, handleListProfile, handleList, handleUpdateCategory }}
                                    />

                                    </CardBody>
                                    
                                </Col>
                        </Row>
                              
                            </CardBody>
                    </Col>
                </Row>
            </Container>
        </Fragment>
  )
}
