import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Col, Input, InputGroup, InputGroupAddon, Button, Table, ButtonGroup, Card, CardBody, CardHeader } from 'reactstrap';

import { Range, getTrackBackground, checkValuesAgainstBoundaries, relativeValue } from 'react-range';
import { sendRequest } from '../../../../hooks/requests/useRequest';
import { validarSesion } from '../../../../hooks/tokens/useToken';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../../../hooks/debounce/useDebounce';

const STEP = 0.01;
const COLORS = ['#f5365c ', '#ffd600 ', '#2dce89', '#ffd600 ', "#f5365c "];


export const ReferenceValues = ({ tabTypeAnalyte, listRanges, saveTempAnalytesNumberAlerts, listReferenceValues, saveTempAnalytesTextAlerts, listReferenceTexts, }) => {

    const history = useHistory();
    const { innerHeight: height } = window;
    const [analyteNumberAlertId, setAnalyteNumberAlertId] = useState(0);
    const [values, setValues] = useState([20, 40, 60, 80]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(100);
    const [inputValues, setInputValues] = useState({ min: 0, max: 100 });
    const [rangoId, setRangoId] = useState(0);
    const [errorSelect, setErrorSelect] = useState(0);
    const [rangos, setRangos] = useState([]);
    const [rangosT, setRangosT] = useState([]);
    const [methodActive, setMethodActive] = useState("create");
    const [nameRange, setNameRange] = useState("");
    const [valoresReferencia, setValoresReferencia] = useState([]);
    const [inputIndex, setInputIndex] = useState(-1);
    // ////console.log(listReferenceValues,"Como llega aqui?");

    const debounceValue = useDebounce(values, 2000);
    const [inferiorLimitNotAlert, setInferiorLimitNotAlert] = useState(values[0]);
    const [normalValue, setNormalValue] = useState(values[1]);
    const [normalMaxValue, setNormalMaxValue] = useState(values[2]);
    const [superiorValue, setSuperiorValue] = useState(values[3]);


    const [analyteTextAlertId, setAnalyteTextAlertId] = useState(0);
    const [textosReferencia, setTextosReferencia] = useState([]);
    const [inputText, setInputText] = useState("");

    const handleRangeLimit = ({ target }) => {
        if (target.name === "min") {
            setInputValues({
                ...inputValues,
                min: target.value
            })
        } else {
            setInputValues({
                ...inputValues,
                max: target.value
            })
        }
    }

    const changeInputText = ({ target }) => {
        setInputText(target.value);
    }

    const setRangeLimits = () => {
        if (isNaN(parseFloat(inputValues.min)) && isNaN(parseFloat(inputValues.max))) {
            setErrorSelect(2);
            return;
        } else if (isNaN(parseFloat(inputValues.max))) {
            setErrorSelect(3);
            return;
        } else if (isNaN(parseFloat(inputValues.min))) {
            setErrorSelect(4);
            return;
        } else {
            setErrorSelect(0);
        }
        setMin(parseFloat(inputValues.min));
        setMax(parseFloat(inputValues.max));
    }

    // METODOS PARA LA CREACIÓN DE ALERTAS DE TEXTO
    const addReferenceText = () => {
        setErrorSelect(0);

        let newTextosReferencia = textosReferencia;
        let range = rangosT.find(x => x.id_analyte_age_gender_range === rangoId);

        ////console.log("HACIENDO UNA PRUEBA: ", range);

        let name = range.rango_edad + " // " + range.metodo + " // " + range.genero;
        let id_aux = -Date.now();

        newTextosReferencia.push({
            id_analyte_text_alert: id_aux,
            id_analyte_age_gender_range: rangoId,
            name_range: name,
            text: inputText
        });

        setInputText("");
        setTextosReferencia(newTextosReferencia);
        // let newRangos = rangosT.filter(x => x.id_analyte_age_gender_range !== rangoId);
        // setRangosT(newRangos);
        setRangoId(0);
    }

    const editReferenceText = (id) => {
        let analyteTextAlert = textosReferencia.find(x => x.id_analyte_text_alert === id);
        let { id_analyte_age_gender_range, name_range, text } = analyteTextAlert;

        if (analyteTextAlertId !== id) {
            // let rango_anterior = textosReferencia.find(x => x.id_analyte_text_alert === analyteTextAlertId);
            // let newRangos = rangosT.filter(x => x.id_analyte_age_gender_range !== (analyteTextAlertId > 0 ? rango_anterior.id_analyte_age_gender_range : 0));
            // let rango = listRanges.find(x => x.id_analyte_age_gender_range === id_analyte_age_gender_range);
            // // ////console.log(newRangos);
            // newRangos.push(rango);
            // setRangosT(newRangos);
            setRangoId(id_analyte_age_gender_range);
            setAnalyteTextAlertId(id);
        }

        setInputText(text);
        setNameRange(name_range);
        setMethodActive("edit");

    }

    const saveEditReferenceText = () => {
        let newTextosReferencia = textosReferencia;
        newTextosReferencia.map((obj, key) => {
            if (obj.id_analyte_text_alert === analyteTextAlertId) {
                let range = rangosT.find(x => x.id_analyte_age_gender_range === rangoId);
                let name = range.rango_edad + " // " + range.metodo + " // " + range.genero;

                obj.id_analyte_text_alert = analyteTextAlertId;
                obj.id_analyte_age_gender_range = rangoId;
                obj.name_range = name;
                obj.text = inputText
            }
            return obj;
        });

        setInputText("");

        // let newRangos = rangosT.filter(x => x.id_analyte_age_gender_range !== rangoId);
        // setRangosT(newRangos);
        setRangoId(0);
        setAnalyteTextAlertId(0);
        setMethodActive("create");
    }

    const removeReferenceText = async (id) => {

        if (id > 0) {
            let requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, `Exam/DeleteAnalyteTextAlert/${id}`);
            if (respuesta.code === 200) {

            } else {
                validarSesion(history, respuesta.code, removeReferenceText);
            }
        }
        // let newRangos = null;
        // // ////console.log(textosReferencia);
        // // if (analyteTextAlertId !== 0) {
        // if (id !== 0) {
        //     let rango_anterior = textosReferencia.find(x => x.id_analyte_text_alert === analyteTextAlertId);
        //     newRangos = rangosT.filter(x => x.id_analyte_age_gender_range !== (analyteTextAlertId > 0 ? rango_anterior.id_analyte_age_gender_range : 0));
        // } else {
        //     newRangos = rangos;
        // }
        // let rangeId = textosReferencia.find(x => x.id_analyte_text_alert === id).id_analyte_age_gender_range;
        // let rango = listRanges.find(x => x.id_analyte_age_gender_range === rangeId);

        // newRangos.push(rango);
        // setRangosT(newRangos);

        let newTextosReferencia = textosReferencia.filter(x => x.id_analyte_text_alert !== id);
        setTextosReferencia(newTextosReferencia);
    }

    const saveReferenceTexts = () => {
        saveTempAnalytesTextAlerts(textosReferencia);
    }

    //-------------------------------------------------

    const addReferenceValue = () => {
        setErrorSelect(0);
        if (rangoId === 0) {
            setErrorSelect(1);
            return;
        }

        let newValoresReferencia = valoresReferencia;
        let range = rangos.find(x => x.id_analyte_age_gender_range === rangoId);
        let name = range.rango_edad + " // " + range.metodo + " // " + range.genero;
        let id_aux = -Date.now();

        newValoresReferencia.push({
            id_analyte_number_alert: id_aux,
            id_analyte_age_gender_range: rangoId,
            name_range: name,
            low_alert: min,
            low: values[0],
            normal_min: values[1],
            normal_max: values[2],
            high: values[3],
            high_alert: max,
        })
        setValoresReferencia(newValoresReferencia);
        setInputValues({
            min: 0,
            max: 100
        });
        setMin(0);
        setMax(100);
        setValues([20, 40, 60, 80]);
        let newRangos = rangos.filter(x => x.id_analyte_age_gender_range !== rangoId);
        setRangos(newRangos);
        setRangoId(0);
        setAnalyteNumberAlertId(0);
    }

    const editReferenceValue = (id) => {
        let analyteNumberAlert = valoresReferencia.find(x => x.id_analyte_number_alert === id);
        let { id_analyte_age_gender_range, name_range, low_alert, low, normal_min, normal_max, high, high_alert } = analyteNumberAlert;
        if (analyteNumberAlertId !== id) {
            let rango_anterior = valoresReferencia.find(x => x.id_analyte_number_alert === analyteNumberAlertId);
            let newRangos = rangos.filter(x => x.id_analyte_age_gender_range !== (analyteNumberAlertId > 0 ? rango_anterior.id_analyte_age_gender_range : 0));
            let rango = listRanges.find(x => x.id_analyte_age_gender_range === id_analyte_age_gender_range);
            newRangos.push(rango);
            setRangos(newRangos);
            setRangoId(id_analyte_age_gender_range);
            setAnalyteNumberAlertId(id);
        }
        setInputValues({
            min: low_alert,
            max: high_alert
        });
        setMin(low_alert);
        setMax(high_alert);
        setValues([low, normal_min, normal_max, high]);
        setNameRange(name_range);
        setMethodActive("edit");
    }

    const saveEditReferenceValue = () => {
        let newValoresReferencia = valoresReferencia;
        newValoresReferencia.map((obj, key) => {
            if (obj.id_analyte_number_alert === analyteNumberAlertId) {
                let range = rangos.find(x => x.id_analyte_age_gender_range === rangoId);
                let name = range.rango_edad + " // " + range.metodo + " // " + range.genero;

                obj.id_analyte_number_alert = analyteNumberAlertId;
                obj.id_analyte_age_gender_range = rangoId;
                obj.name_range = name;
                obj.low_alert = min;
                obj.low = values[0];
                obj.normal_min = values[1];
                obj.normal_max = values[2];
                obj.high = values[3];
                obj.high_alert = max;
            }
            return obj;
        });
        setValoresReferencia(newValoresReferencia);
        setInputValues({
            min: 0,
            max: 100
        });
        setMin(0);
        setMax(100);
        setValues([20, 40, 60, 80]);
        let newRangos = rangos.filter(x => x.id_analyte_age_gender_range !== rangoId);
        setRangos(newRangos);
        setRangoId(0);
        setAnalyteNumberAlertId(0);
        setMethodActive("create");
    }

    const removeReferenceValue = async (id) => {
        if (id > 0) {
            let requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, `Exam/DeleteAnalyteNumberAlert/${id}`);
            if (respuesta.code === 200) {
            } else {
                validarSesion(history, respuesta.code, removeReferenceValue);
            }
        }

        let newRangos = null;
        if (analyteNumberAlertId > 0) {
            let rango_anterior = valoresReferencia.find(x => x.id_analyte_number_alert === analyteNumberAlertId);
            newRangos = rangos.filter(x => x.id_analyte_age_gender_range !== (analyteNumberAlertId > 0 ? rango_anterior.id_analyte_age_gender_range : 0));
        } else {
            newRangos = rangos;
        }
        let rangeId = valoresReferencia.find(x => x.id_analyte_number_alert === id).id_analyte_age_gender_range;
        let rango = listRanges.find(x => x.id_analyte_age_gender_range === rangeId);
        newRangos.push(rango);
        setRangos(newRangos);

        let newValoresReferencia = valoresReferencia.filter(x => x.id_analyte_number_alert !== id);
        ////console.log(newValoresReferencia);
        setValoresReferencia(newValoresReferencia);
    }

    const handleInputChange = ({ target }) => {
        setRangoId(parseInt(target.value));
    }

    const saveReferenceValues = () => {
        saveTempAnalytesNumberAlerts(valoresReferencia);
    }

    const backToCreate = () => {
        setInputValues({
            min: 0,
            max: 100
        });
        setMin(0);
        setMax(100);
        setValues([20, 40, 60, 80]);
        let newRangos = rangos.filter(x => x.id_analyte_age_gender_range !== rangoId);
        setRangos(newRangos);
        setRangoId(0);
        setMethodActive("create");
        setMethodActive("create");
    }

    const msgErrors = () => {
        switch (errorSelect) {
            case 0:
                return "";
                break;
            case 1:
                return "Debes de seleccionar un rango para agregar los valores de referencia";
                break;
            case 2:
                return "Los valores de límite inferior y superior con alerta no pueden estar vacíos.";
                break;
            case 3:
                return "El límite superior con alerta no puede estar vacío.";
                break;
            case 4:
                return "El límite inferior con alerta no puede estar vacío.";
                break;
            default:
                return ""
                break;
        }
    }

    const handleChangeNumericValues = (e, indice) => {
        let copyValues = values;
        let valAfter = copyValues[indice];

        if (e.target.name === "inferiorLimitNotAlert") {
            setInferiorLimitNotAlert(e.target.value);

            if (e.target.value !== '') {
                copyValues[indice] = parseFloat(e.target.value);

                setInputIndex(indice);
                setValues([...copyValues]);
            }
        }
        else if (e.target.name === "normalValue") {
            setNormalValue(e.target.value);

            if (e.target.value !== '') {
                copyValues[indice] = parseFloat(e.target.value);

                setInputIndex(indice);
                setValues([...copyValues]);
            }
        }
        else if (e.target.name === "normalMaxValue") {
            setNormalMaxValue(e.target.value);

            if (e.target.value !== '') {
                copyValues[indice] = parseFloat(e.target.value);

                setInputIndex(indice);
                setValues([...copyValues]);
            }
        }
        else if (e.target.name === "superiorValue") {
            setSuperiorValue(e.target.value);

            if (e.target.value !== '') {
                copyValues[indice] = parseFloat(e.target.value);

                setInputIndex(indice);
                setValues([...copyValues]);
            }
        }

        if (indice == 0) {
            if ((valAfter == copyValues[1]) && (valAfter == copyValues[2]) && (valAfter == copyValues[3])) {
                copyValues[1] = parseFloat(max);
                copyValues[2] = parseFloat(max);
                copyValues[3] = parseFloat(max);

                setValues([...copyValues]);
            }
        }
    }

    useEffect(() => {
        const valuesCopy = [...values].map((value) => {
            return parseFloat(checkValuesAgainstBoundaries(value, min, max));
        });
        setValues(valuesCopy);
        setInputIndex(-1)
    }, [min, max]);


    useEffect(() => {
        let newListRanges = [];
        if (listReferenceValues.length > 0) {
            listRanges.forEach((rango) => {
                let valdiacion = false;
                listReferenceValues.forEach((obj) => {
                    if (rango.id_analyte_age_gender_range === obj.id_analyte_age_gender_range) {
                        valdiacion = true;
                    }
                })
                if (!valdiacion) {
                    newListRanges.push(rango);
                }
            })
        } else {
            newListRanges = listRanges;
        }
        setValoresReferencia(listReferenceValues);
        setRangos(newListRanges);
    }, [listReferenceValues, listRanges])


    useEffect(() => {
        let newListRanges = [];
        if (listReferenceTexts.length > 0) {
            listRanges.forEach((rango) => {
                let valdiacion = false;
                listReferenceTexts.forEach((obj) => {
                    if (rango.id_analyte_age_gender_range === obj.id_analyte_age_gender_range) {
                        valdiacion = true;
                    }
                })
                if (!valdiacion) {
                    newListRanges.push(rango);
                }
            })
        } else {
            newListRanges = listRanges;
        }
        setTextosReferencia(listReferenceTexts);
        setRangosT(listRanges);
    }, [listReferenceTexts, listRanges])

    const [referenceTypes, setReferenceTypes] = useState([]);

    useEffect(() => {
        getReferenceTypes().then(result => {
            if (result.length > 0) {
                setCheckTypeReference(result[0].id)
            }
        })

        return () => {

        }
    }, [])

    const [checkTypeReference, setCheckTypeReference] = useState(referenceTypes.length > 0 ? referenceTypes[0].id : 0);

    const getReferenceTypes = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, `Exam/GetReferencesTypes`);
        if (respuesta.code === 200) {
            let referenceTypesAux = [];
            Object.entries(respuesta.data).forEach(element => {
                let newObj = {
                    "id": parseInt(element[0]),
                    "name": element[1],
                }
                referenceTypesAux.push(newObj);
            });
            setReferenceTypes(referenceTypesAux);
            return referenceTypesAux;
        } else {
            validarSesion(history, respuesta.code, getReferenceTypes);
        }
    }

    useEffect(() => {
        let copy = values;

        if (copy.length > 0 && inputIndex >= 0) {

            let val = copy[inputIndex];

            if (inputIndex === 0) {
                if (val > min && val <= values[1]) {
                    copy[inputIndex] = val;
                    setInferiorLimitNotAlert(val);
                }
                else {
                    copy[inputIndex] = parseFloat(min);
                    setInferiorLimitNotAlert(min);
                }
            }
            else if (inputIndex == 1) {

                if (val > values[0] && val <= values[2]) {

                    copy[inputIndex] = val;
                    setNormalValue(val);
                }
                else {
                    copy[inputIndex] = parseFloat((values[0]).toFixed(2));
                    setNormalValue(parseFloat((values[0]).toFixed(2)));
                }
            }
            else if (inputIndex == 2) {

                if (val > values[1] && val <= values[3]) {

                    copy[inputIndex] = val;
                    setNormalMaxValue(val);
                }
                else {
                    copy[inputIndex] = parseFloat((values[1]).toFixed(2));

                    setNormalMaxValue(parseFloat((values[1]).toFixed(2)));
                }
            }
            else if (inputIndex == 3) {

                if (val > values[2] && val <= max) {

                    copy[inputIndex] = val;
                    setSuperiorValue(val);
                }
                else {
                    copy[inputIndex] = parseFloat((values[2]).toFixed(2));

                    setSuperiorValue(parseFloat((values[2]).toFixed(2)));
                }
            }
        }
        else if (inputIndex == -1) {
            setInferiorLimitNotAlert(values[0]);
            setNormalValue(values[1]);
            setNormalMaxValue(values[2]);
            setSuperiorValue(values[3]);
        }

        setValues([...copy]);

    }, [debounceValue]);

    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm={12}>
                        <p>Selecciona el tipo de referencia a ocupar</p>
                        <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                            {
                                referenceTypes.map(referenceType => {
                                    return (
                                        <div key={`radioType${referenceType.id}`} className="radio radio-primary">
                                            <Input id={`rType${referenceType.id}`} type="radio" name={`rTypes`}
                                                value={referenceType.id}
                                                checked={checkTypeReference === referenceType.id ? true : false}
                                                onChange={() => setCheckTypeReference(referenceType.id)}
                                            />
                                            <Label for={`rType${referenceType.id}`}>{referenceType.name}</Label>
                                        </div>
                                    )
                                })
                            }
                            <hr />
                        </FormGroup>
                    </Col>
                </Row>
                {
                    checkTypeReference === 2 &&
                    <Row>
                        <FormGroup className={`mb-3 pl-1 pr-1 col-5`}>
                            <Label className="text-xs">Rango</Label>
                            <Input
                                disabled={tabTypeAnalyte === 2}
                                bsSize="sm"
                                placeholder="Selecciona un rango"
                                type="select"
                                name="rangoId"
                                value={rangoId}
                                required={true}
                                onChange={handleInputChange}
                                className="text-xs"
                                invalid={errorSelect === 1 && true}
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    checkTypeReference === 1 ?
                                        rangos.map((l, key) => {
                                            return <option
                                                key={key}
                                                value={l.id_analyte_age_gender_range}
                                            >
                                                {l.rango_edad} // {l.metodo} // {l.genero}
                                            </option>
                                        })
                                        :
                                        rangosT.map((l, key) => {
                                            return <option
                                                key={key}
                                                value={l.id_analyte_age_gender_range}
                                            >
                                                {l.rango_edad} // {l.metodo} // {l.genero}
                                            </option>
                                        })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className={`mb-3 pl-1 pr-1 col-5`}>
                            <Label className="text-xs">Referencia tipo texto:</Label>
                            <Input
                                readOnly={tabTypeAnalyte === 2}
                                bsSize="sm"
                                placeholder="Texto..."
                                type="text"
                                name="text_alert"
                                value={inputText}
                                required={true}
                                autoComplete="off"
                                onChange={changeInputText}
                                className="text-xs"
                            // invalid={errorSelect === 3 && true || errorSelect === 2 && true}
                            />
                        </FormGroup>
                        <FormGroup className={`mb-3 pl-1 pr-1 col-2`} style={{ display: "flex", justifyContent: "end", flexDirection: "column-reverse" }}>
                            <Button onClick={methodActive === "edit" ? saveEditReferenceText : addReferenceText} className="btn-icon btn-2 btn-sm" color="primary" type="button">
                                {
                                    methodActive === "edit" ?
                                        "Editar" :
                                        "Agregar"
                                }
                            </Button>
                        </FormGroup>
                        <Col sm={12}>
                            <Row>

                                <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={4}>Rango</Col>
                                <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={6}>Texto</Col>
                                <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={2}>Acciones</Col>
                            </Row>
                            {
                                textosReferencia.map(obj => {
                                    return (
                                        <Row key={obj.id_analyte_text_alert}>
                                            <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={4}>{obj.name_range}</Col>
                                            <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={6}>{obj.text}</Col>
                                            <Col className='border p-2' style={{ borderColor: "#c6c6c6 !important" }} sm={2}>
                                                <ButtonGroup>
                                                    <button
                                                        className="btn-icon btn btn-sm btn-info"
                                                        onClick={() => editReferenceText(obj.id_analyte_text_alert)}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </button>
                                                    <button
                                                        className="btn-icon btn btn-sm btn-danger"
                                                        onClick={() => removeReferenceText(obj.id_analyte_text_alert)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Col>
                        <Col>
                            {
                                tabTypeAnalyte !== 2 &&
                                textosReferencia.length > 0 &&
                                <Col className="text-right pt-2" sm="12">
                                    <Button onClick={saveReferenceTexts} color="primary" className="btn-block">
                                        Confirmar textos de referencia
                                    </Button>
                                </Col>
                            }
                        </Col>
                    </Row>
                }
                {
                    checkTypeReference === 1 &&
                    <>
                        <Row>
                            {
                                methodActive === "edit" &&
                                <Col sm="12" className="pl-1 pr-1 border-bottom">
                                    <p className="m-0">Editando el rango: {nameRange}</p>
                                    <a style={{ cursor: "pointer" }} className="link text-left" onClick={backToCreate}>Regresar a creación</a>
                                </Col>
                            }
                            <FormGroup className={`mb-3 pl-1 pr-1 col-5`}>
                                <Label className="text-xs">Rango</Label>
                                <Input
                                    disabled={tabTypeAnalyte === 2}
                                    bsSize="sm"
                                    placeholder="Género"
                                    type="select"
                                    name="rangoId"
                                    value={rangoId}
                                    required={true}
                                    onChange={handleInputChange}
                                    className="text-xs"
                                    invalid={errorSelect === 1 && true}
                                >
                                    <option value={0}>Selecciona una opción</option>
                                    {
                                        rangos.map((l, key) => {
                                            return <option
                                                key={key}
                                                value={l.id_analyte_age_gender_range}
                                            >
                                                {l.rango_edad} // {l.metodo} // {l.genero}
                                            </option>
                                        })
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup className={`mb-3 pl-1 pr-1 col-3`}>
                                <Label className="text-xs">Límite inferior con alerta</Label>
                                <Input
                                    readOnly={tabTypeAnalyte === 2}
                                    bsSize="sm"
                                    placeholder="Límite inferior con alerta"
                                    type="number"
                                    name="min"
                                    value={inputValues.min}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleRangeLimit}
                                    className="text-xs"
                                    invalid={errorSelect === 4 && true || errorSelect === 2 && true}
                                />
                            </FormGroup>
                            <FormGroup className={`mb-3 pl-1 pr-1 col-3`}>
                                <Label className="text-xs">Límite superior con alerta</Label>
                                <Input
                                    readOnly={tabTypeAnalyte === 2}
                                    bsSize="sm"
                                    placeholder="Límite superior con alerta"
                                    type="number"
                                    name="max"
                                    value={inputValues.max}
                                    required={true}
                                    autoComplete="off"
                                    onChange={handleRangeLimit}
                                    className="text-xs"
                                    invalid={errorSelect === 3 && true || errorSelect === 2 && true}
                                />
                            </FormGroup>
                            {
                                tabTypeAnalyte !== 2 &&
                                <FormGroup className={`mb-3 pl-1 pr-1 col-1`} style={{ display: "flex", justifyContent: "end", flexDirection: "column-reverse" }}>
                                    <Button onClick={setRangeLimits} className="btn-icon btn-2 btn-sm" color="primary" type="button">
                                        <i className="fa fa-check" />
                                    </Button>
                                </FormGroup>
                            }
                        </Row>
                        <Row>
                            <Col className='mb-3 pl-1 pr-1 col-3'>
                                <Card className='pb-0 pt-2 pl-1 pr-1'>
                                    <CardHeader className='pb-0 pt-2 pl-1 pr-1 text-md-center' style={{ backgroundColor: "#ffd600" }}>
                                        <Label className="text-md-center font-light">Límite inferior sin alerta</Label>
                                    </CardHeader>
                                    <CardBody className='pb-0 pt-2 pl-1 pr-1'>
                                        <FormGroup>
                                            <Input
                                                // readOnly={tabTypeAnalyte === 2}
                                                bsSize="sm"
                                                type="number"
                                                name="inferiorLimitNotAlert"
                                                step={STEP}
                                                min={min}
                                                max={values[1]}
                                                placeholder="0.00"
                                                value={inferiorLimitNotAlert}
                                                className="text-md-center"
                                                onChange={(e) => handleChangeNumericValues(e, 0)}
                                            />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className='mb-3 pl-1 pr-1 col-3'>
                                <Card className='pb-0 pt-2 pl-1 pr-1'>
                                    <CardHeader className='pb-0 pt-2 pl-1 pr-1 text-md-center' style={{ backgroundColor: "#2dce89" }}>
                                        <Label className="text-md-center font-light">Valor normal mínimo</Label>
                                    </CardHeader>
                                    <CardBody className='pb-0 pt-2 pl-1 pr-1'>
                                        <FormGroup>
                                            <Input
                                                // readOnly={tabTypeAnalyte === 2}
                                                bsSize="sm"
                                                type="number"
                                                name="normalValue"
                                                step={STEP}
                                                min={min}
                                                max={max}
                                                placeholder="0.00"
                                                value={normalValue}
                                                className="text-md-center"
                                                onChange={(e) => handleChangeNumericValues(e, 1)}
                                            />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className='mb-3 pl-1 pr-1 col-3'>
                                <Card className='pb-0 pt-2 pl-1 pr-1'>
                                    <CardHeader className='pb-0 pt-2 pl-1 pr-1 text-md-center' style={{ backgroundColor: "#2dce89" }}>
                                        <Label className="text-md-center font-light">Valor normal máximo</Label>
                                    </CardHeader>
                                    <CardBody className='pb-0 pt-2 pl-1 pr-1'>
                                        <FormGroup>
                                            <Input
                                                // readOnly={tabTypeAnalyte === 2}
                                                bsSize="sm"
                                                type="number"
                                                name="normalMaxValue"
                                                step={STEP}
                                                min={min}
                                                max={max}
                                                placeholder="0.00"
                                                value={normalMaxValue}
                                                className="text-md-center"
                                                onChange={(e) => handleChangeNumericValues(e, 2)}
                                            />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className='mb-3 pl-1 pr-1 col-3'>
                                <Card className='pb-0 pt-2 pl-1 pr-1'>
                                    <CardHeader className='pb-0 pt-2 pl-1 pr-1 text-md-center' style={{ backgroundColor: "#ffd600" }}>
                                        <Label className="text-md-center font-light">Límite superior sin alerta</Label>
                                    </CardHeader>
                                    <CardBody className='pb-0 pt-2 pl-1 pr-1'>
                                        <FormGroup>
                                            <Input
                                                bsSize="sm"
                                                type="number"
                                                name="superiorValue"
                                                step={STEP}
                                                min={min}
                                                max={max}
                                                placeholder="0.00"
                                                value={superiorValue}
                                                className="text-md-center"
                                                onChange={(e) => handleChangeNumericValues(e, 3)}
                                            />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col sm="12">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Range
                                        // disabled={tabTypeAnalyte === 2}
                                        disabled={true}
                                        values={values}
                                        step={STEP}
                                        min={min}
                                        max={max}
                                        onChange={(values) => setValues(values)}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                onMouseDown={props.onMouseDown}
                                                onTouchStart={props.onTouchStart}
                                                style={{
                                                    ...props.style,
                                                    height: '36px',
                                                    display: 'flex',
                                                    width: '100%'
                                                }}
                                            >
                                                <div
                                                    ref={props.ref}
                                                    style={{
                                                        height: '5px',
                                                        width: '100%',
                                                        borderRadius: '4px',
                                                        background: getTrackBackground({
                                                            values,
                                                            colors: COLORS,
                                                            min: min,
                                                            max: max,
                                                        }),
                                                        alignSelf: 'center'
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            </div>
                                        )}
                                        renderThumb={({ props, isDragged, index }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: '22px',
                                                    width: '22px',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#FFF',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    boxShadow: '0px 2px 6px #AAA'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-32px',
                                                        color: `#${(index === 0 || index === 3) ? "3c3c3c" : "272727"}`,
                                                        fontWeight: 'normal',
                                                        fontSize: '14px',
                                                        padding: '5px',
                                                        borderRadius: '4px',
                                                        backgroundColor: `#${(index === 0 || index === 3) ? "ffd600" : "2dce89"}`
                                                    }}
                                                >
                                                    {values[index].toFixed(2)}
                                                </div>
                                                <div
                                                    style={{
                                                        height: '16px',
                                                        width: '5px',
                                                        backgroundColor: isDragged ? '#5e72e4' : '#CCC'
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col className="text-left" sm="6">
                                Límite inferior con alerta
                                <br />
                                <h2 className="text-danger">{min}</h2>
                            </Col>
                            <Col className="text-right" sm="6">
                                Límite superior con alerta
                                <br />
                                <h2 className="text-danger">{max}</h2>
                            </Col>
                            {
                                tabTypeAnalyte !== 2 &&
                                <Col className="text-right" sm="12">
                                    <p className="text-danger">{msgErrors()}</p>
                                    <Button onClick={methodActive === "edit" ? saveEditReferenceValue : addReferenceValue} color="primary" className="btn-block btn-sm">

                                        {
                                            methodActive === "edit" ?
                                                "Editar valores de referencia" :
                                                "Agregar valores de referencia y alertas"
                                        }
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </>
                }
            </Col>
            {
                checkTypeReference === 1
                &&
                <>
                    <Col sm="12" className="mt-2" style={{ height: `${height - 550}px`, overflow: "scroll" }}>
                        {
                            valoresReferencia.length > 0 &&
                            valoresReferencia.map((obj, key) => {
                                return <div
                                    className="mt-3 card-header pb-0 pt-2"
                                    key={key}
                                    style={{ borderRadius: "8px" }}
                                >
                                    <p><b>{obj.name_range}</b></p>
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th>Inferior con alerta</th>
                                                <th>Inferior sin alerta</th>
                                                <th>Normal mínimo</th>
                                                <th>Normal máximo</th>
                                                <th>Superior sin alerta</th>
                                                <th>Superior con alerta</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td style={{ textAlign: "center", }}><h5>{obj.low_alert} <i style={{ color: "#f5365c" }} className="fa fa-angle-double-down"></i></h5> </td>
                                                <td style={{ textAlign: "center", }}><h5>{obj.low} <i style={{ color: "#e0bc00" }} className="fa fa-angle-down"></i></h5> </td>
                                                <td style={{ textAlign: "center", }}><h5>{obj.normal_min} <i style={{ color: "#2dce89" }} className="fa fa-angle-down"></i></h5> </td>
                                                <td style={{ textAlign: "center", }}><h5>{obj.normal_max} <i style={{ color: "#2dce89" }} className="fa fa-angle-up"></i></h5> </td>
                                                <td style={{ textAlign: "center", }}><h5>{obj.high} <i style={{ color: "#e0bc00" }} className="fa fa-angle-up"></i></h5> </td>
                                                <td style={{ textAlign: "center", }}><h5>{obj.high_alert} <i style={{ color: "#f5365c" }} className="fa fa-angle-double-up"></i></h5> </td>
                                                <td>
                                                    {
                                                        tabTypeAnalyte !== 2 &&
                                                        <ButtonGroup>
                                                            <button
                                                                className="btn-icon btn btn-sm btn-info"
                                                                onClick={() => editReferenceValue(obj.id_analyte_number_alert)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            <button
                                                                className="btn-icon btn btn-sm btn-danger"
                                                                onClick={() => removeReferenceValue(obj.id_analyte_number_alert)}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        </ButtonGroup>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            })
                        }
                    </Col>
                    {
                        tabTypeAnalyte !== 2 &&
                        valoresReferencia.length > 0 &&
                        <Col className="text-right" sm="12">
                            <Button onClick={saveReferenceValues} color="primary" className="btn-block">
                                Confirmar valores de referencia
                            </Button>
                        </Col>
                    }
                </>
            }
        </Row >

    );
};