import moment from 'moment/moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useInformedConsent = () => {

    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [searchNim, setSearchNim] = useState("");
    const [date_ini, setDate_ini] = useState("");
    const [date_end, setDate_end] = useState("");

    const [filters, setFilters] = useState(true);
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [listInformedConsent, setListInformedConsent] = useState([]);


    useEffect(() => {
        getInformedConsent()
      
    
     
    }, [])
    



    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listInformedConsent.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listInd = [];

        listInformedConsent.forEach((data, index) => {
            let position = index + 1;
            let visibleTable = false;

            if (position > startItems && position <= endItems) {
                visibleTable = true;
            }

            listInd.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListInformedConsent(listInd);

    }, [totalPageCount, currentPage]);

    const handleChangeValue = (e, nameInput = "") => {

        if (nameInput !== '' && nameInput === "date_ini") {
            setDate_ini(e);
        }

        else if (nameInput !== '' && nameInput === "date_end") {
            setDate_end(e);
        }

        else if (e.target.name === "searchNim") {
            setSearchNim(e.target.value);
        }
    }

    const handleFilter = () => {
        let dateIni = "";
        let dateEnd = "";

        if (date_ini._d !== undefined) {
            dateIni = moment(date_ini).format("YYYY-MM-DD");
        }

        if (date_end._d !== undefined) {
            dateEnd = moment(date_end).format("YYYY-MM-DD");
        }

        getInformedConsent(searchNim, dateIni, dateEnd);
    }

    const getInformedConsent = async (nim = "", date1 = "", date2 = "") => {
        let requestOptions = {
            method: 'GET'
        };

        let listIc = [];

        let alternativeUrl = "";

        if (date1 !== "" && date2 !== "") {
            if (nim !== "") {
                alternativeUrl = "?date_ini=" + date1 + "&date_end=" + date2 + "&nim=" + nim;
            }
            else {
                alternativeUrl = "?date_ini=" + date1 + "&date_end=" + date2;
            }
        }
        else if (nim !== "") {
            alternativeUrl = "?nim=" + nim;
        }

        const respuesta = await sendRequest(requestOptions, `Sample/ListPatientCISample${alternativeUrl}`);

        if (respuesta.code === 200) {
            setListInformedConsent(respuesta.data);
            if (respuesta.data.length > 0) {

                respuesta.data.forEach(obj => {
                    listIc.push({
                        id_patient: obj.nim,
                        nim: obj.nim,
                        name: obj.name + " " + obj.surnames,
                        date: obj.date
                    });
                });

                setListInformedConsent(listIc);
                setSampleData(listIc)
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInformedConsent);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Consentimiento informado", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Consentimiento informado", respuesta.data.msg);
        }
    }

    const printPdfConsentInformed = async (nim) => {
        let token = localStorage.getItem("token");

        await fetch(`/api/Sample/DownloadCi/${nim}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "consentimiento_informado.pdf");
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    const [sampleData, setSampleData] = useState([])

    const handleSearchSample = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        sampleData.forEach(element => {

            let cadena = element.cancellation_reazon.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        //setListCancellation(newMethod);
    }



    return {
        filters, setFilters, loadingFilters,
        searchNim, date_ini, date_end,
        handleChangeValue,
        handleFilter,
        listInformedConsent,
        printPdfConsentInformed,
        nextPage, previousPage, totalPageCount, currentPage, goToPage, handleSearchSample
    }
}
