import React from 'react';
import { Modal, Col, Row, Label, Button, Input, Media, ModalBody, Table, ModalFooter, Card } from 'reactstrap';
import 'animate.css';
import '../../../../assetsToma/css/isMicro.css'
import { Select } from '@mui/material';
import { useSend } from '../../../../hooks/send/useSend';
import { options } from '@fullcalendar/core/preact';
//import '../../../../assetsToma/css/isMicro.css'
//import {ComponentMic } from '../sending/ComponentMic'

export const ModalMicro = (props) => {

    const {
        typeMicro = "Ismicro", modalMicro, closeModalMicro, micDetail, handleCreateThermometer, thermometers, id_thermometer_send,
        temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send,
        handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend, expiration_send, mark_and_lot_send,
        factorCorrection, codeMic, dataMic
    } = props;

    return (
        <Modal className='modal-position' isOpen={modalMicro} toggle={closeModalMicro} backdrop='static' keyboard={false} centered={true} scrollable={true}>
            {
                typeMicro === "Ismicro" ?
                    <>
                        <Col sm="12" style={{ textAlign: "center" }}>
                            <ModalBody className="modal-body">
                                <Row className='border-3 b-primary' style={{ height: '440px', overflowY: "scroll" }} >

                                    {list_mic_sample !== undefined && list_mic_sample.map((_mic, _key) => {
                                        const matchingDataMic = dataMic.find(_micro => {
                                            if (_micro.lista_pruebas && _micro.lista_pruebas.length > 0) {
                                                return _micro.lista_pruebas.some(_prueba =>
                                                    _prueba.listExam && _prueba.listExam.length > 0 &&
                                                    _prueba.listExam.some(_exam => _exam.id_mic_detail_sample === _mic.id_mic_detail_sample)
                                                );
                                            }
                                            return false;
                                        });

                                        let currentMicro = null;

                                        if (matchingDataMic) {
                                            return (
                                                <Col sm="12" key={`curveTr-${_key}`}>
                                                    <Col sm="12" className='bg-primary' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}>
                                                        <label className='f-14 ca1'><h5>{_mic.nameMic}</h5></label> <br />
                                                        <label className='m-1 ca1'><b>{_mic.code}</b> / {_mic.id_mic_type_sample}</label>
                                                    </Col>
                                                    {
                                                        <Table className='table table-striped table-sm'>
                                                            <tr>
                                                                <th>Temperatura de recepción</th>
                                                                <th>
                                                                    <Input type='text' name="temperature_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th >Termómetros</th>
                                                                <th>
                                                                    <Input
                                                                        className="form-control form-control-sm input-air-primary"
                                                                        type="select"
                                                                        name={`id_thermometer_send${_mic.id_mic_detail_sample}`}
                                                                        value={_mic.id_thermometer_send}
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    >
                                                                        <option value="">Selecciona una opción</option>
                                                                        {
                                                                            thermometers.length > 0 && thermometers.map((c, key) => (
                                                                                <option key={key} value={c.id_thermometer}>{c.label}</option>
                                                                            ))
                                                                        }
                                                                    </Input>
                                                                    {/* <Input
                                                                        className="form-control form-control-sm input-air-primary"
                                                                        type="select"
                                                                        name="id_thermometer_send"
                                                                        onChange={handleInputChangeMic}
                                                                    >
                                                                        <option value={0}>Selecciona una opción</option>
                                                                        {
                                                                            thermometers.length > 0 && thermometers.map((c, key) => {
                                                                                return <option key={key} value={c.id_thermometer} selected={(id_thermometer_send === c.id_thermometer_send) ? 'selected' : ''}>{c.label}</option>
                                                                            })
                                                                        }
                                                                    </Input> */}
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Temperatura corregida</th>
                                                                <th>
                                                                    <Input
                                                                        type='text' name="temperature_corrected_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary'
                                                                    />
                                                                </th>

                                                            </tr>
                                                            <tr>
                                                                <th>Cumple temperatura</th>
                                                                <th>
                                                                    <Input type='text' name="comply_temperature_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Identificación</th>
                                                                <th>
                                                                    <Input type='text' name="identification_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Marca y Lote:</th>
                                                                <th>
                                                                    <Input type='text' name="mark_and_lot_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Caducidad </th>
                                                                <th>
                                                                    <Input type='text' name="expiration_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Contenedor</th>
                                                                <th>
                                                                    <Input type='text' name="container_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Cantidad</th>
                                                                <th>
                                                                    <Input type='text' name="quantity_send" autoComplete='off'
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                        className='form-control form-control-sm input-air-primary' />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Observaciones</th>
                                                                <th>
                                                                    <textarea
                                                                        className='form-control form-control-sm input-air-primary'
                                                                        name="comments"
                                                                        rows="3"
                                                                        onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
                                                                    >
                                                                    </textarea>
                                                                </th>
                                                            </tr>
                                                        </Table>
                                                    }

                                                    {
                                                        matchingDataMic.lista_pruebas !== undefined && matchingDataMic.lista_pruebas.map((_prueba, _pruebaKey) => {

                                                            // Almacena la referencia a _micro actual
                                                            currentMicro = _mic;

                                                            return (
                                                                <div key={`prueba_${_pruebaKey}`}>
                                                                    {_prueba.listExam !== undefined && _prueba.listExam.map((_exam, _examKey) => {
                                                                        // Verificar si el id_mic_detail_sample coincide con _micro.id_mic_detail_sample
                                                                        if (_exam.id_mic_detail_sample === currentMicro.id_mic_detail_sample) {
                                                                            return (
                                                                                _exam && (
                                                                                    <div key={`exam_${_examKey}`}>
                                                                                        {_exam.name && _exam.abbreviation && (
                                                                                            <label className='f-14 ca1'>DETERMINACIÓN: {_exam.name} - {_exam.abbreviation}</label>
                                                                                        )}
                                                                                        {_exam.name_specimen_origin && (
                                                                                            <label className='m-1 ca1'>   -{_exam.name_specimen_origin}</label>
                                                                                        )}
                                                                                        <br />
                                                                                    </div>
                                                                                )
                                                                            );
                                                                        } else {
                                                                            // Si no coincide, no renderizar nada
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </div>
                                                            );
                                                        })}
                                                </Col>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button size='sm' outline color='primary' type="button" onClick={saveMicDetailSend} >
                                    {"Aceptar"}
                                </Button>
                                {/* <Button size='sm' className='btn-air-light' outline color='danger' type='button' >Cancelar</Button> */}
                            </ModalFooter>
                        </Col>
                    </>
                    :
                    ""
            }

        </Modal >

    )


    // return (
    //     <Modal className='modal-position' isOpen={modalMicro} toggle={closeModalMicro} backdrop='static' keyboard={false} centered={true} scrollable={true}>
    //         {
    //             typeMicro === "Ismicro" ?
    //                 <>
    //                     <Col sm="12" style={{ textAlign: "center" }}>
    //                         <ModalBody className="modal-body">
    //                             <Row className='border-3 b-primary' style={{ height: '440px', overflowY: "scroll" }} >
    //                                 {
    //                                     list_mic_sample !== undefined && list_mic_sample.map((_mic, _key) => {
    //                                         return (
    //                                             <Col sm="12" key={`curveTr-${_key}`}>
    //                                                 <Col sm="12" className='bg-primary' style={{ borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }}>
    //                                                     <label className='f-14 ca1'><h5>{_mic.nameMic}</h5></label> <br/>
    //                                                     <label className='m-1 ca1'><b>{_mic.code}</b> / {_mic.id_mic_type_sample}</label>
    //                                                 </Col>
    // <Table className='table table-striped table-sm'>
    //     <tr>
    //         <th>Temperatura de recepción</th>
    //         <th>
    //             <Input type='text' name="temperature_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th >Termómetros</th>
    //         <th>
    //             <Input
    //                 className="form-control form-control-sm input-air-primary"
    //                 type="select"
    //                 name="id_thermometer_send"
    //                 onChange={handleInputChangeMic}
    //             >
    //                 <option value={0}>Selecciona una opción</option>
    //                 {
    //                     thermometers.length > 0 && thermometers.map((c, key) => {
    //                         return <option key={key} value={c.id_thermometer} selected={(id_thermometer_send === c.id_thermometer_send) ? 'selected' : ''}>{c.label}</option>
    //                     })
    //                 }
    //             </Input>
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Temperatura corregida</th>
    //         <th>
    //             <Input type='text' name="temperature_corrected_send" autoComplete='off'
    //                 value={factorCorrection} disabled
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Cumple temperatura:</th>
    //         <th>
    //             <Input type='text' name="comply_temperature_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Identificación:</th>
    //         <th>
    //             <Input type='text' name="identification_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Marca y Lote:</th>
    //         <th>
    //             <Input type='text' name="mark_and_lot_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Caducidad: </th>
    //         <th>
    //             <Input type='text' name="expiration_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Contenedor</th>
    //         <th>
    //             <Input type='text' name="container_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Cantidad</th>
    //         <th>
    //             <Input type='text' name="quantity_send" autoComplete='off'
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //                 className='form-control form-control-sm input-air-primary' />
    //         </th>
    //     </tr>
    //     <tr>
    //         <th>Observaciones</th>
    //         <th>
    //             <textarea
    //                 className='form-control form-control-sm input-air-primary'
    //                 name="comments"
    //                 rows="3"
    //                 onChange={(e) => handleChangeMic(e, _mic.id_mic_detail_sample)}
    //             >
    //             </textarea>
    //         </th>
    //     </tr>
    // </Table>
    //                                                 {
    //                                                     dataMic !== undefined && dataMic.map((_micro, _key) => {
    //                                                         return (
    //                                                             <div key={`micro_${_key}`}>
    //                                                                 <Col sm="12" className='bg-custom text-left' key={`exam_${_key}`}>
    //                                                                     <label className='f-14 ca1'><h5><center>{_mic.nameMic}</center></h5></label> <br />
    //                                                                     {
    //                                                                         _micro.lista_pruebas !== undefined && _micro.lista_pruebas.map((_prueba, _pruebaKey) => {
    //                                                                             return (
    //                                                                                 <div key={`prueba_${_pruebaKey}`}>
    //                                                                                     {
    //                                                                                         _prueba.listExam !== undefined && _prueba.listExam.map((_exam, _examKey) => {
    //                                                                                             return (
    //                                                                                                 _exam && (
    //                                                                                                     <div key={`exam_${_examKey}`}>
    //                                                                                                         {
    //                                                                                                             _exam.name && _exam.abbreviation && (
    //                                                                                                                 <label className='f-14 ca1'>DETERMINACIÓN: {_exam.name} - {_exam.abbreviation}</label>
    //                                                                                                             )
    //                                                                                                         }
    //                                                                                                         {
    //                                                                                                             _exam.name_specimen_origin && (
    //                                                                                                                 <label className='m-1 ca1'>   -{_exam.name_specimen_origin}</label>
    //                                                                                                             )
    //                                                                                                         }
    //                                                                                                         <br />
    //                                                                                                     </div>
    //                                                                                                 )
    //                                                                                             )
    //                                                                                         })
    //                                                                                     }
    //                                                                                 </div>
    //                                                                             )
    //                                                                         })
    //                                                                     }
    //                                                                 </Col>
    //                                                             </div>
    //                                                         )
    //                                                     })
    //                                                 }
    //                                             </Col>
    //                                         )
    //                                     })
    //                                 }
    //                             </Row>
    //                         </ModalBody>
    //                         <ModalFooter>
    //                             <Button size='sm' outline color='primary' type="button" onClick={saveMicDetailSend} >
    //                                 {"Aceptar"}
    //                             </Button>
    //                             {/* <Button size='sm' className='btn-air-light' outline color='danger' type='button' >Cancelar</Button> */}
    //                         </ModalFooter>
    //                     </Col>
    //                 </>
    //                 :
    //                 ""
    //         }

    //     </Modal >

    // )
}