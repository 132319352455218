import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card, Button, Form, Label,Input } from 'reactstrap';
import { TableComponent } from './TableComponent';
export const ModalPrintI = (props) => {

    const {
        togglePrintI, typeModalPrintI = "viewPrintI", modalPrintI, dataExams,
        handleCheckProfile, handleCheckAll, Impresionbyexam, loading, handleCheckAllProfile, letter, handleInputChange
    } = props;

    return (
        <Modal isOpen={modalPrintI} toggle={togglePrintI} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            <ModalHeader toggle={togglePrintI} className="bg-primary">
                Listado de examenes
            </ModalHeader>
            {
                typeModalPrintI === "viewPrintI" ?

                    <ModalBody>
                        <Card className='shadow' >
                                    <TableComponent
                                        key={"table"}
                                        indexs={dataExams}
                                        handleCheckProfile={handleCheckProfile}
                                        handleCheckAll={handleCheckAll}
                                        handleCheckAllProfile={handleCheckAllProfile}
                                    />                             
                        </Card>
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
                              
                        <Label>Membretado</Label>
                    
                        <Label className="switch">
                            <Input type="checkbox" name="letter" onChange={handleInputChange} checked={letter} />
                            <span className="slider round"></span>
                        </Label>
                    
              
                <Button size='sm' outline color='primary' type='button' onClick={Impresionbyexam} className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Imprimir
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={togglePrintI}>Cancelar</Button>
            </ModalFooter>

        </Modal>
    )
}