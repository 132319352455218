import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap'
import Select from 'react-select';

export const FormExamMethodsMicrobiology = (props) => {
    const {
        toggleModal, loadingModal, name, code, id_machine_models, id_method, machineModels, selectMachineModels, methods, handleInputChange, handleSelectValues, validaciones, methodAction, button
    } = props;

    const {
        name: validacionName,
        code: validacionCode,
    } = validaciones;
    return (
        <Form role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm d-none">Nombre del método</Label>
                        <Input
                            placeholder="Nombre del método"
                            type="hidden"
                            name="name"
                            value={name}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionName}
                        />
                        <FormFeedback>El nombre debe tener 2 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        <Label className="text-sm d-none">Código</Label>
                        <Input
                            placeholder="Código"
                            type="hidden"
                            name="code"
                            value={code}
                            required={true}
                            autoComplete="off"
                            onChange={handleInputChange}
                            invalid={validacionCode}
                        />
                        <FormFeedback>El código debe tener 1 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-12 d-none">
                        <Label className="text-sm">Selección de modelos de máquinas</Label>
                        {/* <Input type="hidden" invalid={validacionIdExams} /> */}
                        <Select
                            defaultValue={id_machine_models}
                            isMulti
                            name="id_machine_models"
                            onChange={(e) => handleSelectValues(e, "id_machine_models")}
                            options={selectMachineModels}
                            className="basic-multi-select form-control form-control-plaintext d-none"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    
                                }
                            }
                        />
                        <FormFeedback>Debes de seleccionar mínimo una opción</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-12">
                        <Label className="text-sm">Selección de método</Label>
                        <Select
                            name="id_method"
                            onChange={(e) => handleSelectValues(e, "id_method")}
                            options={methods}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />
                        <FormFeedback>Debes de seleccionar mínimo una opción</FormFeedback>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                outline
                    color="primary"
                    type="submit"
                    className={loadingModal ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button outline color="danger" type="button" onClick={toggleModal}>Cancelar</Button>
            </ModalFooter>
        </Form >
    )
}
