import React, { Fragment, useState } from 'react';
import { useGraph } from '../../hooks/graph/useGraph'
import Chart from 'react-apexcharts'
import { Col, Card, CardHeader, FormGroup, Form, CardBody, Row, Label, Container, Nav, NavLink, NavItem, TabContent, TabPane, Button, Collapse } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb'
import './StyleGraph.css';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { SearchAsync } from '../../components/elements/SearchAsync';



export const IndexGraph = () => {

	const {

		serie, options, optionss, loading, id_exam,
		validInputDate, validToInputDate, from, to,
		handlePickerValues, obtenerDatos, handleSelectExamChange, examProfile,
		serie2, id_profile, obtenerDatoss, fromm, too,
		maximo, minimo, maximoP, minimoP, promedio, promedioP, validToInputDateP, validInputDateP


	} = useGraph();


	const [filterDate, setFilterDate] = useState(true);

	const [activeTab, setActiveTab] = useState("1");

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}



	////console.log(serie.length);


	return (
		<Fragment>
			{/* <Breadcrumb parent="Reportes" title="Evolución de costos" className="m-b-0" /> */}
			<Container fluid={true}>
				<Row>
					<Col md="12">
						<Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
							<NavItem>
								<NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}> <i className="icofont icofont-blood-drop"></i>  Exámenes</NavLink>
							</NavItem>
							<NavItem>
								<NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="icofont icofont-blood"></i>Perfiles</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}  >
							<TabPane className="fade show " tabId="1">
								<Col sm="12">
									<Row>
										<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="3" xl="3">
											<Row className="position-sticky" style={{ top: 140 }}>
												<Col xl="12">
													<Card>
														<br />
														<CardHeader className=' bg-primary '>
															<h5 className="mb-0">
																<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
																	data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="icofont icofont-laboratory"></i> &nbsp; &nbsp; &nbsp; &nbsp;Busqueda por Exámen</Button>
															</h5>
														</CardHeader>
														<Collapse isOpen={filterDate}>
															<CardBody className="p-3">
																<Form className=''>
																	<Row>
																		<Label className="col-12">Buscar Exámen:<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																		<FormGroup className='col-md-12'>
																			<SearchAsync
																				className="col-md-12 "
																				value={id_exam}
																				name="id_exam"
																				method={handleSelectExamChange}
																				loincState={examProfile}
																				url="Profiles/SearchProfileExam"
																				maxLenght={3}
																				selectSize="col-md-12"
																			/>
																		</FormGroup>
																		<br />
																		<br />
																		<Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
																		<FormGroup className="col-md-12  ">
																			<Datetime
																				key={"from"}
																				inputProps={{
																					placeholder: 'DD-MM-YYYY',
																					name: 'from',
																					autoComplete: "off",
																					className: "form-control form-control-sm input-air-primary"
																				}}
																				timeFormat={false}
																				value={from}
																				locale="es"
																				isValidDate={validInputDate}
																				onChange={(e) => handlePickerValues(e, "from")}
																				dateFormat="DD-MM-YYYY"
																			/>
																		</FormGroup>
																		<br />
																		<Label className='col-md-12'>Hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																		<FormGroup className="col-md-12  ">
																			<Datetime
																				key={"to"}
																				inputProps={{
																					placeholder: 'DD-MM-YYYY',
																					name: 'to',
																					autoComplete: "off",
																					className: "form-control form-control-sm input-air-primary"
																				}}
																				timeFormat={false}
																				value={to}
																				locale="es"
																				isValidDate={validToInputDate}
																				onChange={(e) => handlePickerValues(e, "to")}
																				dateFormat="DD-MM-YYYY"																																								
																			/>
																		</FormGroup>

																		<Col sm="12">
																			<br />
																			<Button
																				className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-pill f-right btn-air-primary"}
																				outline
																				color="secondary"
																				style={{ textAlign: "-webkit-center" }}
																				onClick={() => obtenerDatos()}
																			>
																				{"Buscar"}
																			</Button>
																		</Col>
																	</Row>
																</Form>
															</CardBody>
														</Collapse>
														<br /> <br />
													</Card>
												</Col>
											</Row>
										</Col>
										<Col xs="12" sm="12" md="12" lg="9" xl="9">
											<br />
											<Col sm="12">
												<Row>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0'>
															<Row>
																<Col className='col-md-4 p-2 '>
																	<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportPromedio.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Máximo"}</h6>
																	<h8 className="text-left"> $ {maximo.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0'>
															<Row>
																<Col className='col-md-4 p-2 '>
																	<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMinimo.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Minimo"}</h6>
																	<h8 className="text-left">$ {minimo.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0'>
															<Row>
																<Col className='col-md-4 p-2 '>
																	<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMaximo.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Promedio"}</h6>
																	<h8 className="text-left">$ {promedio.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
												</Row>
											</Col>
											<div id="line-chart">
												<Card>
													{
														serie.length === 0 ? 
														<div style={{ textAlign: "-webkit-center" }}>
														<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/AlertChart.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
														<br /> <p className="f-18">{"No hay Registrado Ninguna Busqueda de un exámen."}</p>
														</div>
														:
														<Chart options={options} series={serie} type="line" height={315} />
													}
												</Card>
											</div>
										</Col>
									</Row>
								</Col>
							</TabPane>
							<TabPane className="fade show" tabId="2">
								<Col sm="12">
									<Row>
										<Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="3" xl="3">
											<Row className="position-sticky" style={{ top: 140 }}>
												<Col xl="12">
													<Card>
														<br />
														<CardHeader className='bg-secondary'    >
															<h5 className="mb-0">
																<Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
																	data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="icofont icofont-laboratory"></i> &nbsp; &nbsp; &nbsp; &nbsp;Busqueda por Perfil</Button>
															</h5>
														</CardHeader>
														<Collapse isOpen={filterDate}>
															<CardBody className="p-3">
																<Row>
																	<Label className="col-12">Buscar Pérfil:<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																	<FormGroup className='col-md-12'>
																		<SearchAsync
																			className="col-md-12 "
																			value={id_profile}
																			name="id_profile"
																			method={handleSelectExamChange}
																			loincState={examProfile}
																			url="Profiles/SearchProfile"
																			maxLenght={3}
																			selectSize="col-md-12"
																		/>
																	</FormGroup>
																	<br />
																	<Label className='col-12'>Desde <span className='f-16' style={{ color: "#0079C7" }}>*</span> </Label>
																	<FormGroup className="col-md-12">																		
																		<Datetime
																			key={"fromm"}
																			inputProps={{
																				placeholder: 'DD-MM-YYYY',
																				name: 'fromm',
																				autoComplete: "off",
																				className: "form-control form-control-sm input-air-primary"
																			}}
																			timeFormat={false}
																			value={fromm}
																			locale="es"
																			isValidDate={validInputDateP}
																			onChange={(e) => handlePickerValues(e, "fromm")}
																			dateFormat="DD-MM-YYYY"																																								
																		/>
																	</FormGroup>
																	<br />
																	<Label className='col-md-12'>Hasta<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																	<FormGroup className="col-md-12  ">
																		<Datetime
																			key={"too"}
																			inputProps={{
																				placeholder: 'DD-MM-YYYY',
																				name: 'too',
																				autoComplete: "off",
																				className: "form-control form-control-sm input-air-primary"
																			}}
																			timeFormat={false}
																			value={too}
																			locale="es"
																			isValidDate={validToInputDateP}
																			onChange={(e) => handlePickerValues(e, "too")}
																			dateFormat="DD-MM-YYYY"																																								
																		/>																		
																	</FormGroup>

																	<Col sm="12">
																		<br />
																		<Button
																			className={loading ? " btn-pill f-right disabled progress-bar-animated progress-bar-striped btn-air-secondary" : "btn-pill f-right btn-air-secondary"}
																			outline
																			color="primary"
																			style={{ textAlign: "-webkit-center" }}
																			onClick={() => obtenerDatoss()}
																		>
																			{"Buscar"}
																		</Button>
																	</Col>
																</Row>
															</CardBody>
														</Collapse>
														<br /> <br />
													</Card>
												</Col>
											</Row>
										</Col>



										<Col xs="12" sm="12" md="12" lg="9" xl="9">
											<br />
											<Col sm="12">
												<Row>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0'>
															<Row>
																<Col className='col-md-4 p-0 '>
																<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportPromedio.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Máximo"}</h6>
																	<h8 className="text-left"> $ {maximoP.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0'>
															<Row>
																<Col className='col-md-4 p-2 '>
																<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMinimo.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Minimo"}</h6>
																	<h8 className="text-left"> $ {minimoP.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
													<Col xs="12" sm="12" md="12" lg="4" xl="4">
														<Card style={{ height: "90px" }} className=' p-0 '>
															<Row>
																<Col className='col-md-4 p-0 '>
																<img className="bg-img " src={require("../../assets/images/loginHelenLabs/Graph/ReportMaximo.svg")} alt="" />
																</Col>
																<Col className='col-md-8 '>
																	<br />
																	<h6 className=' text-left'>{"Costo Promedio"}</h6>
																	<h8 className="text-left">$ {promedioP.toFixed(2)}</h8>
																</Col>
															</Row>
														</Card>
													</Col>
												</Row>
											</Col>
											<div id="line-chart">
												<Card>
													{
														serie2.length === 0 ?
														<div style={{ textAlign: "-webkit-center" }}>
														<img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/AlertChart.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
														<br /> <p className="f-18">{"No hay Registrado Ninguna Busqueda de un perfil."}</p>
														</div>
														:
														<Chart options={optionss} series={serie2} type="line" height={315} />


													}
												</Card>
											</div>
										</Col>
									</Row>
								</Col>
							</TabPane>
						</TabContent>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
}






