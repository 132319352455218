import React from 'react';
import { Modal, Button, Form, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Row, Col, Input, Container, FormFeedback, } from 'reactstrap';
import { ListOrderingItem } from '../../../components/lists/ListOrderingItem';

export const ModalOrderAnalytes = (props) => {
    const {
        analytesGroup, handleModalOrder: toggle, modalOrder, loadingOrder, saveOrderAnalytes
    } = props;

    return (
        <Modal isOpen={modalOrder} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="pb-1 pl-2 pr-2">
                Ordenar analitos
            </ModalHeader>
            <ModalBody>
                <label className="text-gray text-sm">Arrastre y suelte los grupos y analitos para ordenarlos</label>
                <ListOrderingItem itemsOrdered={analytesGroup} {...{ modalOrder }} />
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => saveOrderAnalytes(analytesGroup)}
                    color="primary"
                    type="submit"
                    className={loadingOrder ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Ordenar analitos
                </Button>
                <Button color="secondary" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
