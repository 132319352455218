import React from 'react'
import { useState, useEffect } from "react";
import 'moment/locale/es';
import moment from 'moment';
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory } from "react-router";
import { LostConnection } from "../../constant";
import { useForm } from '../forms/useForm';


export const useReportDoctor = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();
    const [dataDoctor, setDataDoctor] = useState([]);


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues ,handlePickerValues] = useForm({
        date_ini: "",
        date_end: ""
    });

    const {
        date_ini, date_end
    } = formValues;


    const getReportDoctor = async () => {

        if (date_ini === '' || date_end === '') {
            return;
        }

        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (date_ini === '') ? moment().format("YYYY-MM-DD") : moment(date_ini).format("YYYY-MM-DD");
        let mto = (date_end === '') ? moment().format("YYYY-MM-DD") : moment(date_end).format("YYYY-MM-DD");

       // const respuesta = await sendRequest(requestOptions, `Doctors/ReportDoctor/${mfrom}/${mto}`);
       
    }

    const validInputDate = (current) => {

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }


    const validToInputDate = (current) => {
        
        let date = moment(date_ini).subtract(1, "day");

        return current.isAfter(date);
    }    


    const obtenerDatos = () => {
        getReportDoctor();
    }


  return {

    date_ini, date_end,obtenerDatos,handlePickerValues,validInputDate,validToInputDate

  }
}
