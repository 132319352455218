import React from 'react'
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useDepartament = () => {

    const history = useHistory();

    const [departaments, setDepartaments] = useState([]);
    const [departamentId, setDepartamentId] = useState(0);
    const [areas, setAreas] = useState([]);
    const [specimen, setSpecimens] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        name: "",
        id_area: "",
        use_consent: false,
        specimens: []
    });
    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        id_area_valid: false,
        specimen_valid: false,
    });

    const { name, id_area, specimens, use_consent } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(departaments.length, numberItems);

    useEffect(() => {
        getAreas().then(result => {
            getDepartaments(result);
        });
        getSpecimens();
    }, [history.location]);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listDepartaments = [];
        departaments.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listDepartaments.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setDepartaments(listDepartaments);
    }, [totalPageCount, currentPage])


    const getDepartaments = async (result = null) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Departaments/List");

        let areaS = result === null ? areas : result;
        if (respuesta.code === 200) {
            let listDepartaments = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    let area = areaS.find(x => x.id_area == parseInt(data.id_area))


                    listDepartaments.push({
                        //...data,
                        id_departament:data.id_departament,
                        id_area:data.id_area,
                        muestraArea:area.name,
                        name:data.name,
                        use_consent:data.use_consent,
                        specimens:data.specimens,
                        visibleTable: visibleTable
                    });
                });
                setDepartaments(listDepartaments);
            }
            else {
                // createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setDepartaments([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getDepartaments);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Departamentos", "Ocurrio un problema en el servidor");

        }
        else {
            createSweet("error", "warning", "Departamentos", respuesta.data.msg);

        }
        // else {
        //     validarSesion(history, respuesta.code, getDepartaments);
        // }
    }

    const getAreas = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Areas/List");

        if (respuesta.code === 200) {
            setAreas(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAreas);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Departamentos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Departamentos", respuesta.data.msg);
        }
        // else {
        //     validarSesion(history, respuesta.code, getAreas);
        // }
    }
    // ////console.log(areas);
    // ////console.log(departaments);

    const getSpecimens = async () => {
        let requestOptions = {
            method: 'GET'
        };
        let listSpecimens = [];
        const respuesta = await sendRequest(requestOptions, "Specimen/List");

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach(({ id_specimen, name }) => {
                    listSpecimens.push({
                        value: id_specimen,
                        label: name,
                        name: name,
                    })
                });
                setSpecimens(listSpecimens);
            }

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSpecimens);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Departamentos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Departamentos", respuesta.data.msg);
        }
    }

    useEffect(() => {
        if (history.location.hash === "#departamentos") {
            getAreas();
        }
    }, [history.location])


    useEffect(() => {
        if (departaments.length > 0) {
            let a = departaments

            let findd = departaments.find(x => x.value == parseInt(id_area))
        }
    }, [departaments]);


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            id_area: "",
            use_consent: false,
            specimens: [],
        });

        setValidaciones({
            name_valid: false,
            id_area_valid: false,
            specimen_valid: false,
        });

        //setDepartamentId(0);
        setMethod("create");
        setModalTitle("Crear departamento");
        toggle();
        getSpecimens();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }
        //formValues.specimens = formValues.specimens_selected;
        //////console.log(formValues);
        let mValues = JSON.stringify(formValues);
        // ////console.log(mValues);
        // return;
        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Departaments");

        if (respuesta !== null) {
            setLoading(false);
            toggle();
            createSweet("create", "success", "Exito!", "Departamento creado con exito");
            handleUpdateValues({
                name: "",
                id_area: "",
                use_consent: false,
                specimens: [],
            })
            getDepartaments();
        } else {
            setLoading(false);
        }
        // if (respuesta.code === 200) {
        //     getDepartaments();
        //     reset();
        //     createSweet("create", "success", "Exito!", "Departamento creado con exito");
        //     setLoading(false);
        //     toggle();
        // }
        // else if (respuesta.code === 401) {
        //     validarSesion(history, respuesta.code, saveCreate);
        //     setLoading(false);
        // }
        // else if(respuesta.code === 500){
        //     createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
        //     setLoading(false);
        // }
        // else{
        //     createSweet("error", "warning", "Analitos", respuesta.data.msg);
        //     setLoading(false)
        // }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_departament) => {
        let departament = departaments.find(s => s.id_departament === parseInt(id_departament));

        handleUpdateValues({
            name: departament.name,
            id_area: departament.id_area,
            use_consent: departament.use_consent,
            specimens: departament.specimens,
        });

        setValidaciones({
            name_valid: false,
            id_area_valid: false,
            specimen_valid: false,
        });

        setDepartamentId(id_departament);
        setMethod("update");
        setModalTitle("Actualización de departamento");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_departament: departamentId,
            id_area: areas,
            specimens: specimen,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Departaments/Update");

        if (respuesta.code === 200) {
            getDepartaments();
            createSweet("create", "success", "Exito!", "Departamento actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Departamentos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Departamentos", respuesta.data.msg);
            setLoading(false)
        }

    }

    const handleDelete = (id_departament) => {
        let departament = departaments.find(s => s.id_departament === id_departament);

        handleUpdateValues({
            name: departament.name,
            id_area: departament.id_area,
            use_consent: departament.use_consent
        });

        setDepartamentId(id_departament);
        setMethod("delete");
        setModalTitle("Eliminar departamento");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Departaments/${departamentId}`);
        if (respuesta.code === 200) {
            getDepartaments();
            setDepartamentId(0);
            setLoading(false);
            createSweet("delete", "success", "Exito!", "Departamento eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Departamentos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Departamentos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.id_area === "" || formValues.id_area <= 0) {
            newValidations = {
                ...newValidations,
                id_area_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_area_valid: false
            }
        }
        if (formValues.specimens === "" || formValues.specimens <= 0) {
            newValidations = {
                ...newValidations,
                specimen_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                specimen_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }


    return {
        departaments, method, validaciones,
        name, id_area, areas, specimen, specimens, handleSelectValues,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, use_consent
    }
}
