import React, { useState, Fragment } from 'react';
import { Input, Label, Collapse, Col, Badge,Row } from 'reactstrap';
import gato from '../../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg'


export const TableComponent = (props) => {
    const {
        indexs, handleCheckProfile, handleCheckAll, handleCheckAllProfile
    } = props;


    const statuNoPrint = JSON.parse(localStorage.getItem('unprintable_preview_status'));
    const [openStates, setOpenStates] = useState(indexs.map(() => true));

    const toggle = (index) => {
        const updatedOpenStates = [...openStates];
        updatedOpenStates[index] = !updatedOpenStates[index];
        setOpenStates(updatedOpenStates);
    };

    return (
        <Col sm="12">
                <div class="checkbox checkbox checkbox-primary m-squar">
                    <Input id="solid1_1" type="checkbox" onChange={handleCheckAll} class="form-check-input" />
                    <Label for="solid1_1"  className='f-w-600 f-12 badge badge-light-primary' >TODOS</Label>
                </div>
                <br />
                <Row>
                    {
                        indexs.length >= 1 && indexs.map((_find, _index) => {
                            let _newArray = _find.sendBy
                            if (_find.id_exam != null) {
                                return(
                                    <Col sm="12" key={_find.id_work_order_exam}>
                                        <Row>
                                            <Col sm="5">
                                                <div className="checkbox checkbox checkbox-primary m-squar">
                                                <Input
                                                    disabled={_find.show_print ? false : true}
                                                    id={`checkbox${_find.id_work_order_exam + "-" + _index}`}
                                                    checked={_find.check_box}
                                                    onChange={(e) =>handleCheckProfile(e, null, _find.id_exam, null, _find.id_work_order_exam)}
                                                    type="checkbox"
                                                />
                                                 <Label style={{ fontSize: '10px' }} htmlFor={`checkbox${_find.id_work_order_exam + "-" + _index}`}>
                                                    <i className="fa fa-caret-square-o-left"></i>{_find.name}
                                                </Label>
                                                </div>
                                            </Col>
                                            <Col sm="2"> <br />
                                                {(_find.id_estatus === 1) ?
                                                <Badge style={{ width: "80px" }} color='info' >Abierto </Badge> :
                                                    (_find.id_estatus === 2) ?
                                                     <Badge style={{ width: "80px" }} color='warning'  >En Progreso </Badge> :
                                                        (_find.id_estatus === 3) ?
                                                            <Badge style={{ width: "80px" }} color='warning'  >Capturado </Badge> :
                                                             (_find.id_estatus === 4) ?
                                                                <Badge color='warning' style={{ width: "80px" }} >Validado</Badge> :
                                                                 (_find.id_estatus === 5) ?
                                                                     <Badge color='warning' style={{ width: "80px" }} >Parcial</Badge> :
                                                                        (_find.id_estatus === 6) ?
                                                                         <Badge color='warning' style={{ width: "80px" }} >Liberado</Badge> :
                                                                         (_find.id_estatus === 7) ?
                                                                            <div> <Badge color='success' style={{ width: "80px" }}>Enviado</Badge>
                                                                         </div> :
                                                                        (_find.id_estatus === 8) ?
                                                                        <div> <Badge color='success' style={{ width: "80px" }}>Entregado</Badge>
                                                                        <br /> 
                                                                        </div>
                                                                        :
                                                (_find.id_estatus === 9) ?
                                                    <Badge style={{ width: "80px" }} color='success'>Ent/Env</Badge> :
                                                        (_find.id_estatus === 10) ?
                                                            <Badge style={{ width: "80px", backgroundColor: "#ff8000" }} color=""  >Autorizado</Badge> :
                                                            (_find.id_estatus === 13) ?
                                                                <Badge style={{ width: "80px" }} color='warning'>Impreso</Badge> :
                                                                (_find.id_estatus === 15) ?
                                                                    <Badge style={{ width: "80px" }} color='warning'>Reimpreso</Badge> :
                                                                    (_find.id_estatus === 20) ?
                                                                        <Badge style={{ width: "80px" }} color='danger'>Cancelado</Badge> :
                                                                        (_find.id_estatus === 21) ?
                                                                        <Badge style={{ width: "80px" }} color='success'>Descargado</Badge> : ""}
                                                                
                                                
                                            </Col>
                                           
                                            <Col sm="5">
                                                <Row>
                                                    <Col sm="2" > <br />
                                                    {
                                                        _newArray === null ? " "
                                                        :
                                                        <div>
                                                            <img style={{ cursor: "pointer" }} className="img-30 mr-1 rounded-circle" src={ gato} alt="" />&nbsp;
                                                        </div>
                                                    }
                                                    </Col>
                                                    <Col sm="10" className='p-r-0'>
                                                    <label className='txt-secondary'>{_newArray?.user ?? ""}</label>&nbsp;
                                                        <br />
                                                    <label className='txt-secondary'>{_newArray?.sended_at ?? ""}</label>   
                                                    </Col>
                                                </Row> 
                                                
                                                       
                                                   
                                            
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                            else {
                                return(
                                    <Col sm="12"  key={_find.id_profile}>
                                        <div
                                        onClick={() => toggle(_index)}
                                        >
                                        <Row>
                                            <Col sm="5">
                                            <div className="checkbox checkbox checkbox-secondary m-squar">
                                                <Input
                                                    disabled={_find.show_print ? false : true}
                                                    id={`checkbox${_find.id_profile + "-" + _index}`}
                                                    checked={_find.check_box}
                                                    onChange={(e) => handleCheckAllProfile(e, _find.id_profile, _find.id_work_order_profile)}
                                                    type="checkbox"
                                                />
                                                <Label  style={{ fontSize: '10px' }} className=" f-w-600 f-10 badge badge-light-primary" htmlFor={`checkbox${_find.id_profile + "-" + _index}`}>
                                                    <i className="fa fa-caret-square-o-left"></i>{_find.name}
                                                   
                                                </Label>
                                            </div>
                                            </Col>
                                            <Col sm="2" className='p-l-1'> <br />
                                            {(_find.id_estatus === 1) ?
                                            <Label onClick={toggle} color='info' ><i style={{ marginRight: "10 px" }} className=""></i></Label> :
                                            (_find.id_estatus === 2) ?
                                                <Label onClick={toggle} color='warning'  ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                (_find.id_estatus === 3) ?
                                                    <Label onClick={toggle} color='warning'  ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                    (_find.id_estatus === 4) ?
                                                        <Label onClick={toggle} color='warning' ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                        (_find.id_estatus === 5) ?
                                                            <Label onClick={toggle} color='warning' ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                            (_find.id_estatus === 6) ?
                                                                <Label onClick={toggle} color='warning' ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                (_find.id_estatus === 7) ?
                                                                    <Label onClick={toggle} color='success' ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                    (_find.id_estatus === 8) ?
                                                                        <Label onClick={toggle} color='success' ><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                        (_find.id_estatus === 9) ?
                                                                            <Label onClick={toggle} color='success'><i style={{ marginLeft: "10 px !important" }} className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                            (_find.id_estatus === 10) ?
                                                                                <Label onClick={toggle} style={{ backgroundColor: "#ff8000" }} color=""  ><i style={{ marginLeft: "10 px !important" }} className="fa fa-arrow-circle-down"></i></Label> :
                                                                                (_find.id_estatus === 13) ?
                                                                                    <Label onClick={toggle} color='warning'><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                                    (_find.id_estatus === 15) ?
                                                                                        <Label onClick={toggle} color='warning'><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> :
                                                                                        (_find.id_estatus === 20) ?
                                                                                            <Label onClick={toggle} color='danger'><i className="fa fa-arrow-down f-14 txt-secondary"></i></Label> : ""}
                                            </Col>
                                            <Col sm="5">
                                            </Col>
                                        </Row>
                                        <Col sm="12" className='p-r-0' >
                                            {
                                                _find.listExam.length >= 1 && _find.listExam.map((_find2 , _index2) => {
                                                    let _newArray2 = _find2.sendBy
                                                    return(
                                                        <Collapse isOpen={openStates[_index]}>
                                                            <Row>
                                                            <Col sm="5">
                                                           
                                                           <div className="checkbox checkbox checkbox-secondary m-squar">
                                                       <Input
                                                           disabled={_find2.show_print ? false : true}
                                                           id={`checkbox${_find2.id_work_order_exam + "-" + _index2}`}
                                                           checked={_find2.check_box}
                                                           onChange={(e) =>
                                                               handleCheckProfile(
                                                                   e,
                                                                   _find.id_profile,
                                                                   _find2.id_exam,
                                                                   _find.id_work_order_profile,
                                                                   _find2.id_work_order_exam
                                                               )
                                                           }
                                                           type="checkbox"
                                                       />
                                                       <Label style={{ fontSize: '10px' }} className="active nav-link" htmlFor={`checkbox${_find2.id_work_order_exam + "-" + _index2}`}>
                                                           <i className="icofont icofont-caret-up"></i>
                                                           {_find2.name}
                                                       </Label>
                                                   </div>
                                                           </Col>
                                                           <Col sm="2"> <br />
                                                           {(_find2.id_estatus === 1) ?
                                                       <Badge style={{ width: "80px" }} color='info' >Abierto </Badge> :
                                                       (_find2.id_estatus === 2) ?
                                                           <Badge style={{ width: "80px" }} color='warning'  >En Progreso </Badge> :
                                                           (_find2.id_estatus === 3) ?
                                                               <Badge style={{ width: "80px" }} color='warning'  >Capturado </Badge> :
                                                               (_find2.id_estatus === 4) ?
                                                                   <Badge color='warning' style={{ width: "80px" }} >Validado</Badge> :
                                                                   (_find2.id_estatus === 5) ?
                                                                       <Badge color='warning' style={{ width: "80px" }} >Parcial</Badge> :
                                                                       (_find2.id_estatus === 6) ?
                                                                           <Badge color='warning' style={{ width: "80px" }} >Liberado</Badge> :
                                                                           (_find2.id_estatus === 7) ?
                                                                               <Badge color='success' style={{ width: "80px" }}>Enviado</Badge> :
                                                                               (_find2.id_estatus === 8) ?
                                                                                   <Badge color='success' style={{ width: "80px" }}>Entregado</Badge> :
                                                                                   (_find2.id_estatus === 9) ?
                                                                                       <Badge style={{ width: "80px" }} color='success'>Ent/Env</Badge> :
                                                                                       (_find2.id_estatus === 10) ?
                                                                                           <Badge style={{ width: "80px", backgroundColor: "#ff8000" }} color=""  >Autorizado</Badge> :
                                                                                           (_find2.id_estatus === 13) ?
                                                                                               <Badge style={{ width: "80px" }} color='warning'>Impreso</Badge> :
                                                                                               (_find2.id_estatus === 15) ?
                                                                                                   <Badge style={{ width: "80px" }} color='warning'>Reimpreso</Badge> :
                                                                                                   (_find2.id_estatus === 20) ?
                                                                                                       <Badge style={{ width: "80px" }} color='danger'>Cancelado</Badge> :
                                                                                                       (_find2.id_estatus === 21) ?
                                                                                                           <Badge style={{ width: "80px" }} color='success'>Descargado</Badge> : ""
                                                   }
                                                           </Col>
                                                           <Col sm="5">     
                                                           <Row>
                                                            <Col sm="2" > <br />
                                                            {
                                                                _newArray2 === null ? " "
                                                                :
                                                                <div>
                                                                    <img style={{ cursor: "pointer" }} className="img-30 mr-1 rounded-circle" src={ gato} alt="" />&nbsp;
                                                                </div>
                                                            }

                                                            </Col>
                                                            <Col sm="10" className='p-r-0'>
                                                            <label className='txt-secondary'>{_newArray2?.user ?? ""}</label>&nbsp;
                                                                    <br />
                                                                    <label className='txt-secondary'>{_newArray2?.sended_at ?? ""}</label>   
                                                            </Col>
                                                            </Row>                                              
                                                                                                                
                                                           </Col>
                                                            </Row>
                                                        </Collapse>
                                                    )
                                                })
                                            }
                                        </Col>
                                        </div>
                                    </Col>
                                    )
                            }
                        })
                    }
                </Row>       
        </Col>
    );
};