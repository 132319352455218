import React from 'react'
import { Form, ModalBody,Button,Col,Label,FormGroup,Table,Row} from 'reactstrap';
import { SearchAsync } from '../../components/elements/SearchAsync';
import Select from 'react-select';


export const FormIndicationExam = (props) => {

    const {

    loading,id_exam_indication,handleChangeIndication,
    listIndication,selectIndication,removeExamProfile,CreateOrUpdateExamIndications,
     handleSelectValuesIndication,dataIndication

    }= props;



    return (
        <Form className="theme-form" role="form" onSubmit={CreateOrUpdateExamIndications} >
                        <Col sm="12 p-1">
                        <Label className="col-12">Ingresa la indicación</Label>
                            <FormGroup className="col-md-12">
                            <Select
                            isMulti
                            name="listIndication"
                            value={listIndication}
                            onChange={(e) => handleSelectValuesIndication(e, "listIndication")}
                            options={dataIndication}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />



                            {/* <SearchAsync
                                cacheOptions={false}
                                value={id_exam_indication}
                                name="id_exam_indication"
                                method={handleChangeIndication}
                                loincState={selectIndication}
                                url="ExamIndication/SearchIndications"
                                maxLenght={3}
                                placeholder="Ingresa los 3 primeros caracteres"
                                className="form-control-sm input-air-primary"
                            /> */}
                            </FormGroup>
                        </Col>  <br /> <br /> <br /> <br /> <br /> <br />
                        {/* <Col sm="12"><i className="icofont icofont-prescription f-22 "></i>Indicaciones</Col>
                             <br /> 
                             <Col sm="12">
                                 <Row>
                                     <Col sm="1">
                                     </Col>
                                 <Col sm="10">
                            {
                                listIndication.length >=1 && listIndication.map((obj,index) => {
                                    return(
                                        <div key={index} className="align-items-center row pb-2 pt-2 border-top border-right border-left border-bottom">
                                        <Col sm="1">
                                         <label  className='f-14 text-secondary'></label>         
                                        </Col>
                                        <Col sm="7">
                                          <label className="f-14 text-secondary">{obj.indication}</label>
                                        </Col>
                                        <Col sm="4" style={{"text-align": "-webkit-center"}}>
                                         <Button outline color="secondary" className="btn btn-xs" onClick={ () => removeExamProfile(index)}  ><i className="icofont icofont-bin"></i></Button>
                                        </Col>
                                    </div>
                                    )
                                })
                            }                       
                        </Col>
                        <Col sm="1">
                        </Col>
                        </Row>    
                       </Col>               */}
                        <ModalBody  style={{"text-align": "-webkit-right"}}>
                        <Button
                                outline
                                color="primary"
                                type="submit"
                                className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            {"Guardar"}
                        </Button> &nbsp;
                        </ModalBody>
                        </Form>
            
    )
}
