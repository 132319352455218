import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormSeller } from './FormSeller';
import { DeleteSeller } from './DeleteSeller';

export const ModalSeller = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        name,paternal_surname,maternal_surname,number_phone,email,handleInputChange,
        saveCreate,saveUpdate,saveDelete,validaciones
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
             
            </ModalHeader>
            {
                method === "create" ?
                    <FormSeller
                        button="Crear"
                        {...{ toggle,loading, name,paternal_surname,maternal_surname,number_phone,email,handleInputChange,validaciones, methodAction: saveCreate}}
                    />
                    : (method === "update") ?
                        <FormSeller
                            button="Actualizar vendedor"
                            {...{  toggle,loading,name,paternal_surname,maternal_surname,number_phone,email,handleInputChange,validaciones, methodAction: saveUpdate}}
                        />
                        : <DeleteSeller
                            {...{ toggle, loading, name, saveDelete }}
                        />
            }
        </Modal>
    )
}
