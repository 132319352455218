import React from 'react';
import { Modal, Col, Row, Label, ModalBody, Table, Badge, FormGroup, Button } from 'reactstrap';
import 'animate.css';



export const ModalHistoricSample = (props) => {

    const {
        closeModalPending, typePending = "Pending", historic, modalPending, toggleHistoric,
        dataSample, loadingSample, dataSendProcess2, dataSendProcess3, dataTrackingRecepetion
    } = props;


    return (
        <Modal isOpen={modalPending} toggle={toggleHistoric} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typePending === "Pending" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Trazabilidad de Muestra"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "35%" }} alt="" onClick={toggleHistoric} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadingSample === false ?
                                        <>
                                            <Row className='mt-2'>
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    <p className='f-24 text-primary'><b>Cargando</b></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <Col sm="12" className='text-center'>
                                            <Row>
                                                <Col sm="12" style={{ textAlign: "center" }} >
                                                    <label className='txt-secondary f-16'>Toma de Muestra</label>
                                                </Col>
                                                <br />
                                                <Col sm="12">
                                                    {
                                                        dataSample.length === 0 ?
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE TOMA</label>
                                                            :
                                                            <div>
                                                                {
                                                                    dataSample.length >= 0 && dataSample.map((_obj) => {
                                                                        return (
                                                                            <div>
                                                                                {
                                                                                    _obj.status === true ?
                                                                                        <div>
                                                                                            <label className='txt-danger f-14'>TOMA CANCELADA</label> <br />
                                                                                            <b className='txt-danger' >fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                                            <br />
                                                                                            <b className='txt-danger'>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                                            <br />
                                                                                            <b className='txt-danger'>Cancelaciones en toma:</b>  <label>
                                                                                                {
                                                                                                    _obj.cancellations
                                                                                                }
                                                                                            </label>
                                                                                            <hr className='txt-danger' />
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                                            <br />
                                                                                            <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                                            <br />
                                                                                            <b>Cancelaciones en toma:</b>  <label>
                                                                                                {
                                                                                                    _obj.cancellations
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                }


                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12" style={{ textAlign: "center" }} >
                                                    <label className='txt-secondary f-16'>Envio de muestra</label>
                                                </Col>
                                                <br />
                                                <Col sm="12">
                                                    {
                                                        dataSendProcess2.length === 0 ?
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE ENVIO DE MUESTRA A SUCURSAL</label>
                                                            :
                                                            <div>
                                                                {
                                                                    dataSendProcess2.length >= 0 && dataSendProcess2.map((_obj) => {
                                                                        return (
                                                                            <div>
                                                                                <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                                <br />
                                                                                <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                                <br />
                                                                                <b>Cancelaciones en envio de muestras</b>  <label>
                                                                                    {
                                                                                        _obj.cancellations

                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12" style={{ textAlign: "center" }} >
                                                    <label className='txt-secondary f-16'>Preparación de muestra</label>
                                                </Col>
                                                <br />
                                                <Col sm="12">
                                                    {
                                                        dataSendProcess3.length === 0 ?
                                                            <label className='txt-danger f-14'>NO A INICIADO EL PROCESO DE PREPARACIÓN</label>
                                                            :
                                                            <div>
                                                                {
                                                                    dataSendProcess3.length >= 0 && dataSendProcess3.map((_obj) => {
                                                                        return (
                                                                            <div>
                                                                                <b>fecha de inicio:</b>  <label >{_obj.initFech}</label>
                                                                                <br />
                                                                                <b>fecha final: </b> <label>{_obj.finallyFech}</label>
                                                                                <br />
                                                                                <b>Cancelaciones en envio de muestras</b>  <label>
                                                                                    {
                                                                                        _obj.cancellations

                                                                                    }
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12" style={{ textAlign: "center" }} >
                                                    <label className='txt-secondary f-16'>Seguimiento de muestra (Recepción de muestra)</label>
                                                </Col>
                                                <br />
                                                <Col sm="12">
                                                    {
                                                        dataTrackingRecepetion.length === 0 ?
                                                            <label className='txt-danger f-14'>NO A INICIADO LA RECEPCIÓN DE LA MUESTRA</label>
                                                            :
                                                            <div>
                                                                {
                                                                    dataTrackingRecepetion.length >= 0 && dataTrackingRecepetion.map((_obj) => {
                                                                        return (
                                                                            <div>
                                                                                <label className='txt-secondary f-10'>{_obj.nameExam}</label><b>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;{_obj.initFech}</b>


                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                    }
                                                </Col>



                                            </Row>
                                            <hr />


                                        </Col>








                                }
                            </ModalBody>
                        </Col>
                    </>
                    : ""
            }
        </Modal >
    )
}