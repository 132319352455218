import React from 'react';
import classnames from "classnames";
import { useState } from 'react';
import { Fragment } from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { IndexTransports } from './IndexTransports';
import { IndexRack } from '../configuraciones/racks/IndexRack';

export const IndexTransportsRacks = () => {

    const [activeTab, setActiveTab] = useState(1);

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        
        <Fragment>
            <Container fluid={true}>
                <br />
                <Row>   
                    <Col sm="12">
                            <CardBody className='p-1'>
                                <div className='nav-wrapper'>
                                    <Nav className='nav-fill flex-column flex-md-row' id='tabs-icons-text' pills role='tablist'>
                                        <NavItem>
                                            <NavLink aria-selected={activeTab === 1} className={
                                                    classnames("mb-sm-3 mb-md-0", {
                                                        active: activeTab === 1
                                                    })
                                                }
                                                onClick={() => { toggle(1); }}
                                                href="#"
                                                role={"tab"}
                                            >
                                                <i className='icofont iconfont-file-document'></i>
                                                Transportes
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                aria-selected={activeTab === 2}
                                                className={classnames("mb-sm-3 mb-md-0", {
                                                    active: activeTab === 2
                                                })}
                                                onClick={() => { toggle(2); }}
                                                href="#"
                                                role="tab"
                                            >
                                                <i className="icofont icofont-file-exe"></i>
                                                Gradillas
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <TabContent activeTab={"tabs"+activeTab}>
                                    <TabPane tabId={"tabs1"}>
                                        <IndexTransports />
                                    </TabPane>
                                    <TabPane tabId={"tabs2"}>
                                        <IndexRack { ...{ activeTab } } />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
