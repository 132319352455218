import React from 'react'
import { Button, Form, FormGroup, Input, InputGroup, ModalBody, ModalFooter, Row } from 'reactstrap'

export const FormUnit = (props) => {

    const { toggle, loading, unit, abbreviation, handleInputChange, methodAction, button } = props;

    return (
        <Form role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-6">
                        Nombre: 
                        <InputGroup className="input-group">
                            <Input
                                //placeholder="Nombre de la unidad"
                                className="form-control form-control-sm input-air-primary" 
                                type="text"
                                name="unit"
                                value={unit}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                        Abreviatura: 
                        <InputGroup className="input-group">
                            <Input
                               // placeholder="Abreviación de la unidad"
                               className="form-control form-control-sm input-air-primary" 
                                type="text"
                                name="abbreviation"
                                value={abbreviation}
                                required={true}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                outline 
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
