import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteRol = (props) => {

    const {
        toggleRol, loading, name, saveDeleteRol
    } = props;

    return (
        <Form className='theme-form' role="form" onSubmit={saveDeleteRol}> 
            <ModalBody>
                <p className='h6 txt-danger'>Estas a punto de eliminar el siguiente rol</p>
                <b>{name}</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' color='danger' type='submit' className={loading ? "disabled progress-bar-animated progress-bar-striped" : "" }>
                    Si, eliminar
                </Button>
                <Button size='sm' className='btn-air-light' color='light' type='button' onClick={toggleRol}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
