import React, { Fragment } from 'react'
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useRack } from '../../../hooks/racks/useRack';
import { ModalRack } from '../../../components/racks/ModalRack';


export const IndexRack = ({activeTab}) => {

    const {
        racks, method,
        name, code, slots, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage
    } = useRack({ activeTab });

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title={"Gradillas"} /> */}
            <Container fluid={true}>
                <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de gradillas
                                </div> <br />
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div> <br />
                                <div className="table-responsive">
                                    {
                                        racks.length === 0 ? 
                                            <div style={{ textAlign: "-webkit-center" }}>
                                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                                                <br /> 
                                                <p className="f-18">{"No hay Registrado ninguna gradilla."}</p>
                                            </div>
                                        :
                                        <TableStriped
                                            notMaped={["visibleTable"]}
                                            methodsActions={true}
                                            cabeceras={["Nombre", "Código", "Espacios"]}
                                            items={racks}
                                            {...{ handleUpdate, handleDelete }}
                                            nextPage={nextPage}
                                            previousPage={previousPage}
                                            totalPageCount={totalPageCount}
                                            currentPage={currentPage}
                                            goToPage={goToPage}
                                        />

                                    }
                                   
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalRack
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    name, code, slots, handleInputChange, validaciones,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </Fragment>
    )
}
