import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteSpecialty } from './DeleteSpecialty';
import { FormSpecialty } from './FormSpecialty';

export const ModalSpecialty = (props) => {

    const {
        modal, toggle, method, loading,
        name, handleInputChange, validaciones,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Creación de especialidad"
                        : (method === "update") ?
                            "Actualización de especialidad"
                            : "Eliminar especialidad"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormSpecialty
                        button="Crear nuevo especialidad"
                        {...{ toggle, loading, name, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update") ?
                        <FormSpecialty
                            button="Actualizar especialidad"
                            {...{ toggle, loading, name, handleInputChange, validaciones, methodAction: saveUpdate }}
                        />
                        : <DeleteSpecialty {...{ toggle, loading, name, saveDelete }} />
            }
        </Modal>
    )
}
