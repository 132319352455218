import radialimage from '../assets/images/dashboard-2/radial-graph.png';
import ConfigDB from '../data/customizer/config';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;;

export const StatusPreInvoice = 'Prefactura';
export const StatusStampedInvoice = 'Timbrada';
export const StatusCancellationInvoice = 'Cancelada';
export const DownloadXml = "DESCARGAR XML";
export const DownloadPdf = "DESCARGAR PDF";
export const CancellInvoice = "CANCELAR FACTURA";
export const PayMethodPpd = "(PPD) PAGO EN PARCIALIDADES O DIFERIDO";
export const PayMethodPue = "(PUE) PAGO EN UNA SOLA EXIBICIÓN";
export const FilterTypeBillingParticularClient = "1";
export const FilterTypeBillingCompany = "2";
export const FilterTypeBillingGeneralPublic = "3";
export const MoralPerson = "MORAL";
export const PhysicalPerson = "FISICA";
export const IdMoralPerson = 2;
export const IdPhysicalPerson = 1;

export const Riskfactorchart = {
    series: [70],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -10,
      },
  
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          inverseOrder: true,
          hollow: {
            margin: 5,
            size: '60%',
            image: radialimage,
            imageWidth: 140,
            imageHeight: 140,
            imageClipped: false,
          },
          track: {
            opacity: 0.4,
            colors: primary
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
              return val + "%"
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
  
            style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fill: ['#2b2b2b'],
  
            },
          },
        }
      },
  
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          gradientToColors: ['#a927f9'],
          type: 'horizontal'
        },
      },
      stroke: {
        dashArray: 15,
        strokecolor: ['#ffffff']
      },
  
      labels: ['Solicitadas'],
      colors: [primary],
    }
}

export const Riskfactorchart1 = {
    series: [70],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -10,
      },
  
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          inverseOrder: true,
          hollow: {
            margin: 5,
            size: '60%',
            image: radialimage,
            imageWidth: 140,
            imageHeight: 140,
            imageClipped: false,
          },
          track: {
            opacity: 0.4,
            colors: secondary
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
              return val + "%"
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
  
            style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fill: ['#2b2b2b'],
  
            },
          },
        }
      },
  
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
          gradientToColors: ['#a927f9'],
          type: 'horizontal'
        },
      },
      stroke: {
        dashArray: 15,
        strokecolor: ['#ffffff']
      },
  
      labels: ['Solicitadas'],
      colors: [secondary],
    }
}
