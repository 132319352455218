import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormSpecialDiscount } from './FormSpecialDiscount';
import { AuthorizedSpecialDiscount } from './AuthorizedSpecialDiscount';
import { DeleteSpecialDiscount } from './DeleteSpecialDiscount';

export const ModalSpecialDiscount = (props) => {

    const {
        modalSpecialDiscount, toggleSpecialDiscount, methodSpecialDiscount, modalTitle,
        loading, percentage, percentage_global, listExams, listProfiles, listSelectedExams, listSelectedProfiles,
        idSpecialDiscount, id_exam_include, id_profile_include,
        handleInputChange, handleChangeTypeDiscount, handleExamsIncludes, handleProfileIncludes, handleInputPercentageIndividual,
        validationsSpecialDiscount,
        saveCreate, handleRemoveTest,
        saveAuthorized, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modalSpecialDiscount} toggle={toggleSpecialDiscount} backdrop='static' keyboard={false} size={(methodSpecialDiscount !== 'auth' && methodSpecialDiscount !== 'delete') ? 'xl' : ''} centered={true} scrollable={true}>
            <ModalHeader toggle={toggleSpecialDiscount} className="bg-primary">
                { modalTitle }               
            </ModalHeader>
            {
                (methodSpecialDiscount === "create" || methodSpecialDiscount === "view" || methodSpecialDiscount === "update")
                ?
                    <FormSpecialDiscount
                        readOnly={methodSpecialDiscount === "view" ? true : false }
                        button="Crear descuento especial"
                        methodAction={methodSpecialDiscount === "create" ? saveCreate : saveUpdate}
                        {
                            ...{
                                toggleSpecialDiscount, loading, 
                                handleInputChange, handleChangeTypeDiscount, percentage, percentage_global, 
                                id_exam_include, id_profile_include,
                                listExams, listProfiles, listSelectedExams, listSelectedProfiles, handleInputPercentageIndividual,
                                handleExamsIncludes, handleProfileIncludes,
                                validationsSpecialDiscount, 
                                handleRemoveTest                               
                            }
                        }
                    />
                : 
                    (methodSpecialDiscount === "auth")
                    ?
                        <AuthorizedSpecialDiscount
                            {...{ toggle:toggleSpecialDiscount, loading, saveAuthorized, idSpecialDiscount }}
                        />
                    : 
                    (methodSpecialDiscount === "delete")
                    ?
                        <DeleteSpecialDiscount
                            {
                                ...{
                                    toggle: toggleSpecialDiscount, loading, saveDelete, idSpecialDiscount
                                }
                            }
                        />
                    : ""
            }
        </Modal>
    )
}
