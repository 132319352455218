import React from 'react';
import { Modal,ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeleteCatLoteMediosSanitaria } from './DeleteCatLoteMediosSanitaria';
import { FormCatLoteMediosSanitaria } from './FormCatLoteMediosSanitaria';

export const ModalCatLoteMediosSanitaria = (props) => {
    const{
        method, modalTitle, loading, modal, toggle,
        handleInputChange, saveCreate, saveUpdate, saveDelete,
        validaciones, descripcion,n_lote,
    }=props;


    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ?<FormCatLoteMediosSanitaria
                    button = {ModalButtonSave}
                    {...{toggle, loading, handleInputChange, validaciones, methodAction: saveCreate,}}
                />
                :
                (method === "update")
                ?
                <FormCatLoteMediosSanitaria
                    button={ModalButtonUpdate}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, descripcion,n_lote}}
                />
                :
                <DeleteCatLoteMediosSanitaria 
                    {...{toggle, loading, saveDelete ,descripcion,n_lote}}
                />
            }
        </Modal>
    )
}