import React, { useMemo } from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ConfigServer } from '../../data/config';
import moment from 'moment/moment';
import { useRef } from 'react';

export const Usefooter = () => {


    //LOCAL VARIABLES
    const [createSweet] = useSweetAlert();
    const history = useHistory();
    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');

    const [listBranch, setListBranch] = useState([]);
    const [id_branch_select, setid_branch_select] = useState(id_branch);
    useEffect(() => {
        handleGetBranch();
    }, []);
    const handleGetBranch = async () => {
        let requestOptions = {
            method: 'GET'
        };
        let id_actual = parseInt(localStorage.getItem('branchIdPrincipal'));
        const respuesta = await sendRequest(requestOptions, "Branch/ListLocalStorage");
        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                let data = [];
                const addedBranches = new Set();
                let listbranch_storage = null;
                let listbranch_validar = localStorage.getItem('listbranch');
                // ////console.log(listbranch_validar);
                if (listbranch_validar != null && listbranch_validar != " " && listbranch_validar != undefined && listbranch_validar != "") {
                    listbranch_storage = JSON.parse(localStorage.getItem('listbranch'));
                } else {
                    listbranch_storage = undefined;
                }

                // //console.log(listbranch_storage);
                
                var classic_login = false;

                try {
                    classic_login =(localStorage.getItem("classic_login") == "1")
                } catch (errorw) {
                    classic_login = false;
                }
                
                console.log('classic', classic_login);

                respuesta.data.forEach(obj => {
                    if (listbranch_storage != undefined) {
                        listbranch_storage.forEach(function (element) {
                        
                            if (obj.id_helen_branch == element && !addedBranches.has(obj.id_branch)) {
                                data.push({
                                    "id_branch": obj.id_branch,
                                    "label": obj.name,
                                    "id_helen_branch": obj.id_helen_branch,
                                    "id_commercial_line": obj.id_commercial_line
                                });
                                addedBranches.add(obj.id_branch);
                            }
                        });
                    }
                    if (obj.id_branch == id_actual && !addedBranches.has(obj.id_branch)) {
                        data.push({
                            "id_branch": obj.id_branch,
                            "label": obj.name,
                            "id_helen_branch": obj.id_helen_branch,
                            "id_commercial_line": obj.id_commercial_line
                        });
                        addedBranches.add(obj.id_branch);
                    }
                    // ////console.log(data);
                });

                setListBranch(data);
                localStorage.setItem("list_branch_storage", JSON.stringify(data));
            }
        }
        else if (respuesta.code === 401) {
            setListBranch([]);
            validarSesion(history, respuesta.code, handleGetBranch);
        }
        else if (respuesta.code === 500) {
            setListBranch([]);
            createSweet("error", "error", "Sucursales", "Ocurrio un problema en el servidor");
        }
        else {
            setListBranch([]);
            createSweet("error", "warning", "Sucursales", respuesta.data.msg);
        }
    }

    const handleInputChange = (e) => {
        let value = e.target.value;
        setid_branch_select(value);
        localStorage.setItem("branchId", value);
        let id = parseInt(value);
        let array = listBranch;

        array.map(obj => {
            if (id == obj.id_branch) {
                localStorage.setItem("branchName", obj.label);
                localStorage.setItem("commercialLineId", obj.id_commercial_line);
            }
            else {
            }
           
            return obj;
        });
        window.location.reload();

    }

    return {
        listBranch, handleInputChange, id_branch_select
    }
}
