import React from 'react'
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

export const FormBacterium = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram, button
    } = props;

    const {
        bacterium_valid, gram_valid, id_mic_morphology_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Bacteria <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary"
                                name="bacterium"
                                onChange={handleInputChange}
                                value={bacterium}
                                autoComplete="off"
                                invalid={bacterium_valid}
                            />
                            <FormFeedback>Minimo 1 caracter</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Gram <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            {/* <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary"
                                name="gram"
                                onChange={handleInputChange}
                                value={gram}
                                autoComplete="off"
                                invalid={gram_valid}
                            /> */}
                            <Input
                                type="select"
                                className="form-control form-control-sm input-air-primary "
                                name="gram"
                                onChange={handleInputChange}
                                value={gram}
                                autoComplete="off"
                            >
                                <option value="N/A">N/A</option>
                                <option value="POSITIVO">POSITIVO</option>
                                <option value="NEGATIVO">NEGATIVO</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md="4" className='p-1'>
                        <FormGroup>
                            <Label className="col-form-label">Morfologia </Label>
                            <Input
                                type="select"
                                className="form-control form-control-sm input-air-primary "
                                name="id_mic_morphology"
                                onChange={handleInputChange}
                                value={id_mic_morphology}
                                autoComplete="off"
                                invalid={id_mic_morphology_valid}
                            >
                                <option value="0">Selecciona una opción</option>
                                {
                                    morphologys.length > 0 && morphologys.map((s, key) => {
                                        return <option key={key} value={s.id_mic_morphology}>
                                            {s.morphology}
                                        </option>
                                    })
                                }
                            </Input>
                            <FormFeedback>Minimo 1 caracter</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="12"> <br />
                        <DualListBox
                            selected={antibiogramsSelected}//el que va a la derecha
                            simpleValue={false}
                            onChange={onChangeAntibiogram}
                            filterPlaceholder={"Buscar..."}
                            showNoOptionsText={true}
                            canFilter
                            options={antibiograms}//izquierda
                            lang={{
                                // availableFilterHeader: 'Filter available',
                                //  availableHeader: 'Available',
                                moveAllLeft: 'Remover todas las opciones',
                                moveAllRight: 'Agregar todas las opciones',
                                moveLeft: 'Remover la opción seleccionada',
                                moveRight: 'Agregar la opcion seleccionada',
                                moveBottom: 'Move to bottom',
                                // moveDown: 'Move down',
                                //moveUp: 'Move up',
                                ///moveTop: 'Move to top',
                                noAvailableOptions: 'No hay más antibioticos disponibles',
                                noSelectedOptions: 'No se ha seleccionado ningún antibiotico',
                                //selectedFilterHeader: 'Filter selected',
                                // selectedHeader: 'Selected',
                            }}



                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
