import React from 'react';
import { Col, Row, Label, Input, FormGroup, Button, ButtonGroup, FormText } from 'reactstrap';
import { useInputTypeClient } from '../../hooks/invoice/useInputTypeClient';
import { PlaceHolderOption } from '../../constant';
import { SearchAsync } from '../../components/elements/SearchAsync';
import Select from 'react-select';
import { ToolTips } from '../../components/tooltips/ToolTips';
import 'animate.css';

export const InputTypeClient = (props) => {

    const {
        typeClient, formInvoice, handleInputChange, handleUpdateValues,

        id_patient, id_company, id_company_business_name,

        is_general_public, information_general_public,

        informationBusinessName, setInformationBusinessName, handleUpdateBusinessName, validSelectBusinessName,

        searchPatientState, informationPatient, setInformationPatient, validSelectPatient,
        handleUpdatePatient,

        viewSearchPatient, setViewSearchPatient,

        viewSearchClient, setViewSearchClient,

        validationsInvoice, handleChangePayMethodGeneralPublic, handleChangePayMethodPatient,

        id_patient_r,_id_company_r,setId_company_filter,id_work_order_r,request_id_invoice,
    } = props;

    const {
        //MODEL
        listCompanies, listCompaniesBussinessName, viewButtonEditBussinessName,
        viewButtonEditPatient,

        //METHODS
        handleChangeClient, handleChangeCompanyBussinessName, handleChangePatient,


    } = useInputTypeClient({
        typeClient, formInvoice, handleInputChange, handleUpdateValues,
        informationBusinessName, setInformationBusinessName, validSelectBusinessName,
        informationPatient, setInformationPatient, validSelectPatient, setViewSearchPatient, setViewSearchClient,
        handleChangePayMethodGeneralPublic, handleChangePayMethodPatient,
        //!CONFIGURATION DASHBOARD INVOICE
        id_patient_r,_id_company_r,setId_company_filter,request_id_invoice,
    });

    const {
        _id_patient_valid,
        _id_company_valid,
        _id_company_business_name_valid,
    } = validationsInvoice;
    
    return (
        <>
            {
                typeClient === "1" && <Row className={viewSearchPatient ? '' : 'd-none'}>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Búsqueda de paciente  <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <SearchAsync
                                cacheOptions={false}
                                name="id_patient"
                                value={id_patient}
                                method={handleChangePatient}
                                loincState={searchPatientState}
                                url="Patient/SearchPatient"
                                maxLenght={3}
                                placeholder="Búsqueda por nombre o curp"
                            />
                            <FormText color='danger' hidden={!_id_patient_valid}>Selecciona un paciente</FormText>
                        </FormGroup>
                    </Col>
                </Row>
            }
            {
                typeClient === "2" && <Row className={viewSearchClient ? '' : 'd-none'}>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Cliente  <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                className="input-air-primary input-xs"
                                classNamePrefix="select"
                                name="id_company"
                                value={id_company}
                                options={listCompanies}
                                placeholder={PlaceHolderOption}
                                isClearable={true}
                                onChange={(e) => handleChangeClient(e)}
                                isDisabled={_id_company_r !== undefined ? true : false}
                            />
                            <FormText color='danger' hidden={!_id_company_valid}>Selecciona un cliente</FormText>
                        </FormGroup>
                    </Col>
                </Row>
            }
            <Row>
                {
                    typeClient === "2" && <Col sm="12" md="12" lg="12" className={viewSearchClient ? '' : 'd-none'}>
                        <FormGroup>
                            <Label className='col-form-label'>Razón social del cliente</Label>
                            <Input type='select' className="form-control form-control-sm input-air-primary" name="id_company_business_name" value={id_company_business_name} onChange={handleChangeCompanyBussinessName}>
                                <option value="0">Selecciona una opción </option>
                                {
                                    listCompaniesBussinessName.length > 0 && listCompaniesBussinessName.map(bussiness => {
                                        return <option value={bussiness.id_company_business_name} key={bussiness.id_company_business_name} >{bussiness.business_name}</option>
                                    })
                                }
                            </Input>
                            <FormText color='danger' hidden={!_id_company_business_name_valid}>Selecciona una razón social</FormText>
                        </FormGroup>
                    </Col>
                }
                {
                    (typeClient === "1" && !viewSearchPatient && informationPatient.rfc_patient !== undefined)
                        ?
                        <Col sm="12" md="12" lg="12" className='animate__animated animate__fadeIn'>
                            <Row>
                                <Col sm="12" md="12" lg="12" className='email-body'>
                                    <div className='media'>
                                        <div className='media-body text-center'>
                                            <h6><i className="fa fa-circle-o text-info"></i> {informationPatient.name_patient}</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className='project-box p-1' style={{ borderWidth: '0px', opacity:"0.5" }}>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                    <small>Régimen fiscal</small> <br />
                                        <label style={{fontSize:"11px"}}>
                                            <i className="fa fa-circle text-info"></i>  {informationPatient.tax_regime_patient === "" ? " --- " : informationPatient.tax_regime_patient}
                                        </label>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm="5" md="5" lg="5">
                                    <small>RFC</small> <br />
                                        <label><i className="fa fa-circle text-info"></i> {informationPatient.rfc_patient === "" ? " -- " : informationPatient.rfc_patient}</label>
                                    </Col>
                                    <Col sm="5" md="5" lg="5">
                                    <small>Código postal</small> <br />
                                        <label style={{fontSize:"11px"}}><i className="fa fa-circle text-info"></i> {informationPatient.cp_patient === 0 ? " --- " : informationPatient.cp_patient}</label>   
                                    </Col>
                                    <Col sm="2" md="2" lg="2">
                                        <Button id='btn-tooltip-filter-p' outline color='secondary' onClick={() => setViewSearchPatient(!viewSearchPatient)} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                                        >
                                            <i className="icofont icofont-filter"></i>
                                            <ToolTips
                                                placement="top"
                                                dataTarget='btn-tooltip-filter-p'
                                                dataText='Seleccionar otro paciente'
                                            />
                                        </Button>
                                        {
                                            viewButtonEditPatient && <Button id='btn-tooltip-edit-p' outline color='primary' onClick={() => handleUpdatePatient()} className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}
                                            >
                                                <i className="icofont icofont-ui-edit "></i>
                                                <ToolTips
                                                    placement="bottom"
                                                    dataTarget='btn-tooltip-edit-p'
                                                    dataText='Editar datos fiscales'
                                                />
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm="12" md="12" lg="12">
                                            <small>Nombre / Razón social</small> <br />
                                            <label style={{fontSize:"11px"}}><i className='fa fa-circle text-info'></i> {informationPatient.business_name}</label>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm="12" md="12" lg="12">
                                            <small>Correo</small> <br />
                                            <label style={{fontSize:"11px"}}><i className='fa fa-circle text-info'></i> {informationPatient.email}</label>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        :
                        typeClient === "2" && !viewSearchClient && informationBusinessName.id_tax_regime_business_name !== undefined
                            ?
                            <Col sm="12" md="12" lg="12" className='animate__animated animate__fadeIn'>
                                <Row>
                                    <Col sm="12" md="12" lg="12" className='email-body'>
                                            <div className='media-body text-center'>
                                                <h6><i className="fa fa-circle-o text-info"></i> {informationBusinessName.business_name}</h6>
                                            </div>
                                    </Col>
                                </Row>
                                <div className='project-box p-1' style={{ borderWidth: '0px' }}>
                                    <Row>
                                        <Col sm="12" md="12" lg="12">
                                            <div className='media'>
                                                <div className="media-body">
                                                    <small>Régimen fiscal</small> <br />
                                                    <label style={{fontSize:"11px"}}><i className="fa fa-circle text-info"></i> {informationBusinessName.tax_regime_business_name}</label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col sm="5" md="5" lg="5" >
                                            <div className='media'>
                                                <div className="media-body">
                                                    <small>RFC</small> <br />
                                                    <label style={{fontSize:"11px"}}><i className="fa fa-circle text-info"></i> {informationBusinessName.rfc_business_name}</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4" md="4" lg="4">
                                            <div className='media'>
                                                <div className="media-body">
                                                    <small>Código postal</small> <br />
                                                    <label style={{fontSize:"11px"}}><i className="fa fa-circle text-info"></i>  {informationBusinessName.cp_business_name}</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="3" md="3" lg="3">
                                            <div className={request_id_invoice === undefined ? "" : "d-none"} >
                                            <Button id='btn-tooltip-filter-c' outline color='secondary' onClick={() => setViewSearchClient(!viewSearchClient)} className={id_work_order_r === undefined ? "p-3" : "d-none"} style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 60 }}
                                            >
                                                <i className="icofont icofont-filter"></i>
                                                <ToolTips
                                                    placement="top"
                                                    dataTarget='btn-tooltip-filter-c'
                                                    dataText='Seleccionar otro cliente'
                                                />
                                            </Button>
                                            </div>
                                            <div className={request_id_invoice === undefined ? "" : "d-none"}>
                                            {
                                                viewButtonEditBussinessName && <Button id='btn-tooltip-edit-c' outline color='primary' className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}
                                                    onClick={() => handleUpdateBusinessName()}
                                                >
                                                    <i className="icofont icofont-ui-edit "></i>
                                                    <ToolTips
                                                        placement="bottom"
                                                        dataTarget='btn-tooltip-edit-c'
                                                        dataText='Editar datos fiscales'
                                                    />
                                                </Button>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            :
                            typeClient === "3"
                                ?
                                <Col sm="12" md="12" lg="12" className='animate__animated animate__fadeIn'>
                                    <Row>
                                        <Col sm="12" md="12" lg="12" className='email-body'>
                                            <div className='media'>
                                                <div className='media-body text-center'>
                                                    <h6><i className="fa fa-circle-o text-info"></i> {information_general_public.name_general_public}</h6>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='project-box p-1' style={{ borderWidth: '0px' }}>
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <div className='media'>
                                                    <div className="media-body">
                                                        <small>Régimen fiscal</small>
                                                        <p><i className="fa fa-circle text-info"></i> {information_general_public.tax_regime_general_public}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='mt-1'>
                                            <Col sm="6" md="6" lg="6" className='text-center' >
                                                <div className='media'>
                                                    <div className="media-body">
                                                        <small>RFC</small>
                                                        <p><i className="fa fa-circle text-info"></i> {information_general_public.rfc_general_public}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="6" md="6" lg="6" className='text-center'>
                                                <div className='media'>
                                                    <div className="media-body">
                                                        <small>Código postal</small>
                                                        <p><i className="fa fa-circle text-info"></i>  {information_general_public.cp_general_public}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                : ""
                }
            </Row>
        </>
    )
}
