import React from 'react';
import { Col, Pagination, Row, Table } from 'reactstrap';
import { CardItemAgreement } from './CardItemAgreement';

export const ListCardAgreement = (props) => {

    const {
        items, methodsActions, 
        handleUpdate = null, handleDelete = null, methodsAbsolutes = null,
        handleUpdateStatus = null, navigateUpdate = null, handleListProfile = null, handleUpdateCategory = null,    
        nextPage = null, previousPage = null, totalPageCount, currentPage, goToPage,handleSearchAgreement,
        downdloadExcel
    } = props;

    return (
        <>
           
            {
                nextPage !== null && previousPage !== null && 
                <Row className='mb-5'>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Pagination aria-label='...' style={{ justifyContent: "flex-start" }}>
                                            <ul className='pagination pagination-primary'>
                                                {
                                                    currentPage === 1
                                                    ? <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                    : <li style={{ cursor: 'pointer'}} className='page-item' onClick={previousPage}><span className='page-link'>Anterior</span></li>
                                                }   
                                                {
                                                    currentPage > 1 && <>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage(1)} className="page-item"><span className='page-link'>1</span></li>
                                                        {
                                                            currentPage > 2 && <>
                                                                <li className='page-item'><span className='page-link'>...</span></li>
                                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage - 1)} className='page-item'><span className='page-link'>{currentPage - 1}</span></li>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage}</span></li>
                                                {
                                                    currentPage < totalPageCount  &&
                                                    <>
                                                        {
                                                            currentPage < (totalPageCount - 1) &&
                                                            <>
                                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage(currentPage + 1) } className="page-item"><span className='page-link'>{ currentPage + 1 }</span></li>
                                                                <li className='page-item'><span className='page-link'>...</span></li>
                                                            </>
                                                        }
                                                        <li style={{ cursor: "pointer" }} onClick={() => goToPage(totalPageCount)} className="page-item"><span className='page-link'>{totalPageCount}</span></li>
                                                    </>
                                                }
                                                 {
                                                    totalPageCount === currentPage ?
                                                    <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                    :
                                                    <li style={{ cursor: 'pointer' }} className='page-item primary' onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                                }
                                            </ul>
                                        </Pagination>
                                    </td>
                                    {/* <td style={{ width: "245px" }}>
                                      
                                            <input className='form-control form-control-sm search' placeholder='Búscar...' onChange={(e) => handleSearchAgreement(e)} />
                                    
                                    </td> */}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
            <Row className=' math-height'>            
                {
                   items.length > 0 && items.map((obj, keyId) => {
                        return <CardItemAgreement
                            {
                               ...{
                                    methodsActions, 
                                    methodsAbsolutes,
                                    handleDelete, handleUpdateStatus, navigateUpdate, handleListProfile,
                                    handleUpdateCategory,downdloadExcel
                                }
                            }
                            key={keyId}
                            items={obj}
                            objId={keyId}
                        />
                   }) 
                }
            </Row>
            
        </>
    )
}
