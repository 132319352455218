import React, { Fragment} from 'react';
import { useHistoryPatient } from '../../../hooks/patients/useHistoryPatient';
import { TimeLinePatient } from '../../../components/patients/TimeLinePatient';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, CardBody, Col, Container, Media, Row, Table, Button, Modal, ModalHeader,CardHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ButtonGoBack } from '../../../constant';
import './StylePatientNim.css';



export const HistoryPatient = () => {
    const {
        pacienteId, dataPatient, workOrders, getInformation, showInformationModal,
        modal, toggle, dataModal, password, handleInputChange, errorValidacion
    } = useHistoryPatient()


    const history = useHistory();
    return (
        <Fragment>
            {/* <Breadcrumb parent="Pacientes" title="Historial del paciente" /> */}
            <Container fluid={true}> <br />
            <Col sm="12">
                <Row>
                    <Col sm="4">
                        <Card className='shadow'>
                            <CardHeader className="p-3 bg-primary">
                                <h6>Datos Generales</h6>
                            </CardHeader> 
                            <Col sm="12" style={{textAlignLast : "center"}}> 
                                        <div>
                                            {
                                                dataPatient.gender === "FEMENINO" ?
                                                <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")}  style={{ width: "30%" }} alt="" /></div>
                                                :
                                                <div className="profile-vector"><img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")}  style={{ width: "30%" }} alt="" /></div>
                                            }
                                        </div>
                                        <h6 className="mb-1 txt-primary">{`${dataPatient.name} ${dataPatient.paternal_surname} ${dataPatient.maternal_surname}`}</h6>
                                        <p className="mb-4">{`${dataPatient.birthday} - ${dataPatient.age} años`}</p>
                            </Col> <br />

                            <Col sm="12">
                                            <Row>
                                                <Col sm="6" style={{  textAlign : "-webkit-center"}} >
                                                <i className="icofont icofont-ui-call f-16 iconProfile"></i><label htmlFor="">TÉLEFONO</label> <br />
                                                    <label htmlFor="">{dataPatient.phone}</label>
                                                </Col>
                                                <Col sm="6" style={{  textAlign : "-webkit-center"}}>
                                                <i className="icofont icofont-id-card f-16 iconProfile"></i><label htmlFor="">CURP</label><br />
                                                    <label htmlFor="">{dataPatient.curp}</label>
                                                </Col>
                                            </Row>
                            </Col> <br />
                            <Col sm="12" style={{  textAlign : "-webkit-center"}}>
                            <i className="icofont icofont-email f-16 iconProfile"></i>    <label htmlFor="">EMAIL</label><br />
                            <label htmlFor="">{dataPatient.email}</label>
                            </Col>
                          
                        </Card>

                        <Card className='shadow'>
                        <CardHeader className="p-2 bg-primary">
                                <h6>Acciones</h6>
                            </CardHeader> 
                            <Col sm="12">
                                <Row>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="9">
                                    <Button color="light" className=" btn-air-light m-4" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Card> 
                    </Col>
                    <Col sm="8">
                        <Card>
                            <CardHeader className="p-3 bg-secondary">
                                <h6>Historial Clinico del Paciente</h6>
                            </CardHeader> <br />
                            <div className='scrollHistoryPatient'>
                            <TimeLinePatient
                                    items={workOrders}
                                    method={showInformationModal}
                                />
                            </div> 
                        </Card><br />
                    </Col>
                </Row>
            </Col>
                <Modal isOpen={modal} centered={true} toggle={toggle}>
                    <ModalHeader className="bg-primary" toggle={toggle}>Validación</ModalHeader>
                    <ModalBody>
                        <p>Estas apunto de mostrar información protegida de esta orden de trabajo con nim: {dataModal.nim}</p>
                        <span>Ingresa tu contraseña</span> <br /> <br />
                        <Input placeholder="Contraseña..." type="password" name="password" value={password} onChange={handleInputChange} />
                        <span className="text-danger">{errorValidacion}</span>
                        <hr />
                        <h6>¿Estás seguro de hacerlo?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={getInformation}>Mostrar información</Button>
                        <Button color="secondary" onClick={toggle}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </Container >
        </Fragment >
    )
}
