import React, { Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { usePatients } from '../../../hooks/patients/usePatients';
import { useWorkOrder } from '../../../hooks/workOrder/useWorkOrder';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalPatient } from '../../../components/patients/ModalPatient';

export const IndexPatient = () => {

    const {
        //MODALS
            // modal, , method, loading, 
            // toggle,
            modalTitle,
        //MODELS
            isInvoiceService, setIsInvoiceService,
            viewNewSocialGender, setViewNewSocialGender,
        //LIST
            // listPatients, 
            listStates, listMunicipalities, listBloodType, 
            listTaxRegime, listUseCfdi, listSocialGender,
        
        //FORMS
            // id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
            // id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, 
            // curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,
            // edad,

        //FORM METHODS
       //     handleInputChange, handleSelectValues, handleDate,

        //FILTERS
            //formFilter, 
            //filtersForm, setFiltersForm, 
            //handleInputFilter,            

        //VALIDATIONS
            //validationsPatient,

        //HANDLE METHODS
            //handleSearch, 
//handleCreate, 
         //   handleChangeState, 
            handleChangeTypePerson, 
            handleChangeTaxRegime,
//handleUpdate, 
            //handleDelete, 
            //handleFilters, 
            //handleClearFilters,
    handleChangeSocialGender,
        //METHODS
///savePatient, 
           // saveUpdate, 
            //saveDelete,
        //VISIBILITY

        //PAGINATION
            //goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage, 
           modalPatient1,
    } = usePatients();

    const {
        modalPatient, togglePatient, methodPatient, loadingPatient, saveUpdateWorkOrder, validHopitalInterfaced, handleDatePatient,
        handleInputChangePatient, handleSelectValuesPatient, getBirthday, getAge, handleWheel, formValuesPatient, listUseCfdiPatient,
        handleChangeStatePatient, saveCreatePatient, saveUpdatePatient, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
        handleOnChangeINEU, visibility, Listsocialgender, validationsPatient ,modalSearch,
        // ? 
        handleCreatePatient, handleUpdatePatient, 
        //PAGINATION
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage, listPatients, 
        handleDelete, saveDelete, informationPatient, handleSearch, formFilter, handleInputFilter, handleUpdateValuesFilter, handleClearFilters, 
        handleFilters, filtersForm, setFiltersForm, toggleTab, activeTab, isEditWorkOrder, handleAgeC, handleDateB, isEditPatient,

    } = useWorkOrder();

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" sm="3">
                        <Row className='position-sticky' style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className='bg-primary'>
                                        <h5 className='mb-0'>
                                            <Button color='link pl-0' data-toggle="collapse" onClick={() => setFiltersForm(!filtersForm)}
                                                data-target="#collapseicon" aria-expanded={filtersForm} aria-controls='collapseIcon' >      
                                                Filtros de búsqueda
                                            </Button>
                                        </h5>   
                                    </CardHeader>
                                    <Collapse isOpen={filtersForm}>
                                        <CardBody className='p-3'>
                                            <p>Realiza tus búsquedas por uno o más filtros</p>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>CURP</Label>
                                                        <Input type='text' name='curpFilter' value={formFilter.curpFilter} className='form-control form-control-sm input-air-primary b-r-9' onChange={handleInputFilter} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Nombre</Label>
                                                        <Input type='text' name='nameFilter' value={formFilter.nameFilter} className='form-control form-control-sm input-air-primary b-r-9' onChange={handleInputFilter} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Télefono</Label>
                                                        <Input type='text' name='phoneFilter' value={formFilter.phoneFilter} className='form-control form-control-sm input-air-primary b-r-9' onChange={handleInputFilter} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Correo electrónico</Label>
                                                        <Input type='text' name='emailFilter' value={formFilter.emailFilter} className='form-control form-control-sm input-air-primary b-r-9' onChange={handleInputFilter} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Filtrar por membresia</Label>
                                                        <Input type="select" name='memebershipFilter' value={formFilter.membershipFilter} className='form-control form-control-sm input-air-primary b-r-9' onChange={handleInputFilter}>
                                                            <option value={0}>Selecciona una opción</option>
                                                            <option value={true}>Si</option>
                                                            <option value={false}>No</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <Button type='button' outline color='info' className='text-center' onClick={handleFilters}> Filtrar</Button>
                                                </Col>
                                                <Col sm="12" md="12" lg="12" className='mt-2'>
                                                    <Button type='button' outline color='danger' className='text-center' onClick={handleClearFilters}> Limpiar filtros</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="9" md="9" lg="9">
                        <Card>
                            <CardBody className='p-1'>
                                <div className='ribbon ribbon-clip ribbon-primary'>
                                    {"Listado de Pacientes"}
                                </div>
                                <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreatePatient}>
                                        <span className='fa fa-plus-circle'></span>
                                    </Button>
                                </div>
                                <Row>
                                    <Col className='offset-8 pt-1 pb-2' md="4" >
                                        <Input placeholder='Búsqueda...' className='form-control' onChange={handleSearch} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <div className='table-responsive'>
                                            {
                                                listPatients.length === 0 ?
                                                    <div style={{ textAlign: "-webkit-center" }}>
                                                        <img className='img-fluid' src={ require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg") } style={{ width: "35%" }} alt='' />
                                                        <br />
                                                        <br />
                                                        <p className='f-18'>{"No hay ningún paciente registrado aún."}</p>
                                                    </div>
                                                : <TableStriped
                                                    notMaped={[
                                                        "id_gender", "id_state", "id_municipality", "id_blood_type", "id_social_gender", "id_tax_regime", "id_use_cfdi",
                                                        "name_state", "name_municipality", "address", "colony", "cp", "unique_uuid",
                                                        "qr_unique_id", "visibleTable", "rfc", "type_person", "name_social_gender", "name_tax_regime", "name_use_cfdi", "name_blood_type"
                                                    ]}
                                                    methodsActions={true}
                                                    {...{ handleUpdatePatient, handleDelete }}
                                                    cabeceras={[
                                                        "Código", "Apellido paterno", "Apellido materno", "Nombre", "Género", "F. nacimiento", "Curp", "Teléfono", "E-mail", "Membresia" 
                                                    ]}
                                                    items={listPatients}
                                                    nextPage={goNextPage}
                                                    previousPage={goPreviousPage}
                                                    totalPageCount={totalPages}
                                                    currentPage={actualPage}
                                                    goToPage={goToPage}
                                                    paginingBack={true}
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalPatient
                {
                    ...{
                        loadingPatient, saveDelete, informationPatient,
                        modal: modalPatient, modalTitle, method: methodPatient, loading: loadingPatient, toggle: togglePatient, modalPatient1,
                        validationsPatient, saveUpdateWorkOrder, validHopitalInterfaced,
    
                        handleDatePatient, handleChangeSocialGender,
                        handleInputChange: handleInputChangePatient, handleSelectValues: handleSelectValuesPatient, handleDate:
                            handleDatePatient, getBirthday, getAge, handleWheel, 
    
                        id_gender: formValuesPatient.id_gender, id_state: formValuesPatient.id_state, id_municipality: formValuesPatient.id_municipality,
                        id_blood_type: formValuesPatient.id_blood_type, id_social_gender: formValuesPatient.id_social_gender, id_tax_regime: formValuesPatient.id_tax_regime,
                        id_use_cfdi: formValuesPatient.id_use_cfdi, rfc: formValuesPatient.rfc, type_person: formValuesPatient.type_person, code: formValuesPatient.code,
                        paternal_surname: formValuesPatient.paternal_surname, maternal_surname: formValuesPatient.maternal_surname, name: formValuesPatient.name, birthday: formValuesPatient.birthday,
                        age: formValuesPatient.age,
                        curp: formValuesPatient.curp, address: formValuesPatient.address, cp: formValuesPatient.cp, colony: formValuesPatient.colony,
                        phone: formValuesPatient.phone, email: formValuesPatient.email, membership: formValuesPatient.membership, cardnumber: formValuesPatient.cardnumber,
                        new_social_gender: formValuesPatient.new_social_gender, expedient_number: formValuesPatient.expedient_number,
                        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi: listUseCfdiPatient, listSocialGender: Listsocialgender,
    
                        handleChangeState: handleChangeStatePatient, handleChangeTypePerson, handleChangeTaxRegime,
    
                        savePatient: saveCreatePatient, saveUpdate: saveUpdatePatient,
    
                        isInvoiceService, setIsInvoiceService,
                        viewNewSocialGender, setViewNewSocialGender,
                        scan_ine_check: formValuesPatient.scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
                        img64File: formValuesPatient.img64File, handleOnChangeINEU,
                        codeP: formValuesPatient.cp, visibility, toggleTab, activeTab, isEditWorkOrder, handleAgeC, handleDateB,
                        isEditPatient,
                    }
                    }
            />
        </Fragment>
    )
}
