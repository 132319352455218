import React, { useState, useEffect, Fragment, useRef } from 'react'
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/cartaConsentimiento.css'
import { Container, Row, Col, Label } from 'reactstrap';
import { useEncuestaCliente } from '../../../../hooks/tomaMuestra/useEncuestaCliente';
import { ModalEnd } from '../ModalEnd';
import 'animate.css';
import { useLogin } from '../../../../hooks/tomaMuestra/useLogin';
import { ModalEndCancell } from '../ModalEndCancell';
import { ComponentQuestin } from './ComponentQuestin';
import { useHistory, useParams } from "react-router-dom";
import { sendRequest } from "../../../../hooks/requests/useRequest";





export const IndexEncuestaClient = () => {

    const {

        handleEnd, method, modalTitle, toggle, modal, loading,
        navigateInicio, nameModal, getEncuesta, hour, minute, second, surnames,
        handleEndCancell, method2, toggle2, modal2, getEncuestaCancell, loading2, dinamica, navigateNext, navigatePrev, saveQuestion, questionOpen, showAlternativeQ

    } = useEncuestaCliente();


    const { time, reload } = useLogin();

    let name = localStorage.getItem('nameUser');
    let name2 = localStorage.getItem('flebotomisaNombre');
    let name5 = localStorage.getItem('willson');


    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(true);
    const [modalPrueba, setModalPrueba] = useState("");
    const [modalPrueba2, setModalPrueba2] = useState("");

    const [loadingPatient, setLoadingPatient] = useState(false);


    const history = useHistory();

    const { nimm } = useParams();

    useEffect(() => {
        if (nimm !== undefined) {
            getDataFromNimPost(nimm)
        }
    }, [nimm]);


    const getDataFromNimPost = async (nimm) => {

        setLoadingPatient(false);

        let body = {
            nim: nimm.substring(0, 14),
            id_departament: localStorage.getItem('prueba')

        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Sass/GetDataNim");
        setLoadingPatient(true);

        if (respuesta.code === 200) {
            let name = respuesta.data.paciente.name
            let surname = respuesta.data.paciente.surnames
            ////console.log(surname);

            setModalPrueba(name)
            setModalPrueba2(surname);

        }


        // if (respuesta.code === 200) {

        //   setStudy(respuesta.data.paciente)

        //   if (respuesta.data.lista_pruebas.length >= 1) {

        //     let copy = respuesta.data.lista_pruebas;

        //     let nuevoObjeto = [];
        //     let indexObject = [];

        //     copy.forEach(x => {

        //       if (!nuevoObjeto.hasOwnProperty(x.name_section)) {
        //         nuevoObjeto[x.name_section] = {
        //           secciones: []
        //         }

        //         indexObject.push(x.name_section);
        //       }

        //       nuevoObjeto[x.name_section].secciones.push({
        //         nombre: x.name_exam,
        //       })
        //     })
        //     setSectionName(nuevoObjeto)
        //     setIndexSection([...indexObject])
        //   }
        // }
    }










    return (
        <Fragment>
            <Container fluid={true}  className='bg-barra1 cardSampleD'>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0'>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-0"> <br />
                            <div className='box-departamento'>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }}>
                                    <Row>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-l-0'>
                                            <Label className='r'>{time}</Label>
                                        </Col>
                                        <Col xs="1" sm="1" md="1" lg="1" xl="1">
                                        </Col>
                                        <Col xs="6" sm="6" md="6" lg="6" xl="6" >
                                            <label className='r'>Toma de Muestra&nbsp;/&nbsp;{name5}</label>
                                        </Col>
                                        <Col xs="1" sm="1" md="1" lg="1" xl="1">
                                        </Col>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-1' style={{ textAlign: "-webkit-right" }}>
                                            {
                                                show1 ? (<label className='rc'>{hour}:{minute}:{second}</label>) : ""
                                            }
                                        </Col>
                                        <Col sm="1" className='p-2' >
                                            {/* {
                                                show1 ? (<img className=" " src={require("../../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "57%" }} alt="" />
                                                ) : ""
                                            } */}
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' > <br />
                                    <ComponentQuestin
                                        items={dinamica}
                                        {
                                        ...{ navigateNext, navigatePrev, saveQuestion, questionOpen, showAlternativeQ, handleEnd }
                                        }
                                    />
                                    <div className='tap-top' style={{ display: "block" }} onClick={() => { setShow(!show); }}><i className="icon-angle-double-up f-24"></i>
                                    </div>
                                </Col>

                                <Col style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0'>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12"><br /> <br />

                                        {
                                            show ?
                                                (
                                                    <div></div>
                                                )
                                                :
                                                (
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <div className='username-Active ' >
                                                            <Col sm="12">
                                                                <Row>
                                                                    <Col sm="3" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }} > <br />

                                                                        <i className="fa fa-circle f-28 icon-active"></i>
                                                                    </Col>
                                                                    <Col sm="9" className='p-l-0 p-r-0'> <br />
                                                                        <label className='label-menuToma p-0 ' >{name2}</label>
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        </div> <br />
                                                        {/* onClick={reload} */}
                                                        <div className='username-Active pointer'  >
                                                            <Col sm="12">
                                                                <Row>
                                                                    <Col sm="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                                                        <img className=" " src={require("../../../../assetsToma/imagenes/Recargar.png")} style={{ width: "43%" }} alt="" />
                                                                    </Col>
                                                                    <Col sm="9" className='p-l-0 p-r-0 ' onClick={reload}> <br />
                                                                        <label className='r-label-active p-0 pointer'>{"Recargar"}</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div> <br />
                                                        <div className='username-Active pointer' onClick={() => { setShow1(!show1); }}>
                                                            <Col sm="12">
                                                                <Row>
                                                                    <Col sm="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                                                        <img className=" " src={require("../../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "45%" }} alt="" />
                                                                    </Col>
                                                                    <Col sm="9" className='p-l-0 p-r-0 '> <br />
                                                                        <label className='r-label-active p-0 pointer'>{"Cronómetro"}</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div><br />
                                                        {/* 50% */}
                                                        <div className='username-Active pointer' onClick={handleEndCancell}>
                                                            <Col sm="12">
                                                                <Row>
                                                                    <Col sm="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}>  <br />
                                                                        <img className=" " src={require("../../../../assetsToma/imagenes/Omitir.png")} style={{ width: "50%" }} alt="" />
                                                                    </Col>
                                                                    <Col sm="9" className='p-l-0 p-r-0 '> <br />
                                                                        <label className='r-label-active-omitir-Encuesta p-0 pointer' >{"Omitir Encuesta"}</label> <br />
                                                                        {/* <label className='cambios'>{hour}</label> */}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                )
                                        }
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
            <ModalEnd
                {...{ method, modalTitle, toggle, modal, loading, navigateInicio, nameModal, getEncuesta, surnames, modalPrueba, modalPrueba2, loadingPatient }}

            />
            <ModalEndCancell
                {...{
                    method2, toggle2, modal2, loading2, navigateInicio, getEncuestaCancell, surnames, nameModal, modalPrueba, modalPrueba2, loadingPatient
                }}

            />
        </Fragment>

    )
}


