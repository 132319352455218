import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateSpecimenProvenances, ModalUpdateSpecimenProvenances, ModalDeleteSpecimenProvenances, LostConnection } from '../../constant';
import { sendRequest } from '../requests/useRequest';

export const useSpecimenProvenances = ({activeTab}) => {
    
    const history = useHistory();

    const [specimenProvenances, setSpecimenProvenances] = useState([]);
    const [specimenProvenancesId, setSpecimenProvenancesId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        abbreviation: ""
    });

    const {
        name, abbreviation
    } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        abbreviation: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {

        getSpecimenProvenances();

    }, [activeTab])

    const getSpecimenProvenances = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenProvenances/List");

        if (respuesta.code === 200) {
            let listSpecimenProvenances = [];

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach( (data) => {
                    listSpecimenProvenances.push({
                        id_specimen_provenance: data.id_specimen_provenance,
                        name: data.name,
                        abbreviation: data.abbreviation
                    });
                });
                
                setSpecimenProvenances(listSpecimenProvenances);
            }
            else{
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSpecimenProvenances([]);
            }
        }
        else{
            validarSesion(history, respuesta.code, getSpecimenProvenances);
        }
    }, [history]);

    useEffect(() => {
        
        getSpecimenProvenances();

    }, [getSpecimenProvenances]);

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation: ""
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSpecimenProvenancesId(0);
        setMethod("create");
        setModalTitle(ModalCreateSpecimenProvenances);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenProvenances");
        if (respuesta.code === 200) {
            getSpecimenProvenances();
            reset();
            createSweet("create", "success", "Exito!", "Procedencia creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_specimen_provenance) => {
        let specimenProvenance = specimenProvenances.find(sp => sp.id_specimen_provenance === id_specimen_provenance);

        handleUpdateValues({
            name: specimenProvenance.name,
            abbreviation: specimenProvenance.abbreviation
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false
        });

        setSpecimenProvenancesId(id_specimen_provenance);
        setMethod("update");
        setModalTitle(ModalUpdateSpecimenProvenances);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_specimen_provenance: specimenProvenancesId,
            ...{ ...formValues }
        };
        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenProvenances/Update");
        
        if (respuesta.code === 200) {
            getSpecimenProvenances();
            createSweet("create", "info", "Exito!", "Procedencia actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }
    }

    const handleDelete = (id_specimen_provenance) => {
        let specimenProvenance = specimenProvenances.find(sp => sp.id_specimen_provenance === id_specimen_provenance);

        handleUpdateValues({
            name: specimenProvenance.name,
            abbreviation: specimenProvenance.abbreviation
        });

        setSpecimenProvenancesId(id_specimen_provenance);
        setMethod("delete");
        setModalTitle(ModalDeleteSpecimenProvenances);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `SpecimenProvenances/${specimenProvenancesId}`);

        if (respuesta.code === 200) {
            getSpecimenProvenances();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Procedencia eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false)
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false)
            createSweet("error", "error", "Especímen", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Especímen", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                name_valid: false
            };
        }

        if (formValues.abbreviation.length < 2) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else{
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            };
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    return {
        specimenProvenances, method,
        name, abbreviation,
        handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    }
}
