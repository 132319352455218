import React, { Fragment } from 'react';
import { Container, Row, Col, Label, Input, PlaceHolderOption } from 'reactstrap'
import './StyleFooter.css';
import { Usefooter } from '../../hooks/footer/Usefooter'
const Footer = (props) => {

  const {

    listBranch, handleInputChange, id_branch_select
  } = Usefooter();
  
  var CommercialLineShort = localStorage.getItem('CommercialLineShort');

  return (
    <Fragment>
      <footer className="footer ">
        <Container fluid={true}>
          <Row>
            <Col sm="12" md="12" lg="12" className="footer-copyright text-center">
              <Row>
                <Col xs="6" sm="3" md="3" lg="3"style={{ textAlign: "initial" }} className='' >
                  <p className="mb-0 LabelFooter ">{"Copyright 2024 © Humanly Software"}</p>
                </Col>
             
                <Col xs="6" sm="3" md="3" lg="6"style={{ textAlign: "end" }} className='' >
                  <p className="mb-0 LabelFooter">{CommercialLineShort}&nbsp;</p>

                </Col>
                <Col xs="12" sm="3" md="3" lg="3">
                  <Input
                    type="select"
                    name="id_branch_select"
                    onChange={handleInputChange}
                    value={id_branch_select}
                    className="form-control-sm-w form-control-sm input-air-primary"
                  >
                    {
                      listBranch.length >= 1 && listBranch.map((b, key) => {
                        return <option key={key} value={b.id_branch} selected={(id_branch_select === b.id_branch) ? true : false}>
                          {b.label}
                        </option>
                      })
                    }
                  </Input>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;