import React from 'react'
import { Form, ModalBody, Button, Row, FormText, Col, Label, FormGroup, Input } from 'reactstrap';
import './StyleReturnReason.css';

export const FormReturnReason = (props) => {

    const {

        loading, toggle, handleInputChange, methodAction, button, reason, validaciones, is_sample,
        is_work_order, is_tracking, is_send

    } = props;

    const {
        reason_valid,
    } = validaciones;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody className='p-b-0'>
                <Row>
                    <br />
                    <br />
                    <Col md="12">
                        <textarea
                            name="reason"
                            value={reason}
                            onChange={handleInputChange}
                            className='Timepicker-reazon'
                           // rows={4}
                            placeholder="Motivo de la cancelación o devolución....."
                            invalid={reason_valid}
                           
                        />
                    </Col>
                    <Col md="12" className='text-center'>
                        <FormText color="danger" hidden={!reason_valid} >Debes de redactar la devolución</FormText>
                    </Col>
                </Row>
                <br />
                <Col sm="12">
                    <Row>
                        <Col md="3" style={{ textAlign: "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Toma? </Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_sample" onChange={handleInputChange} checked={is_sample} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{ textAlign: "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Cancelación de Orden? </Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_work_order" onChange={handleInputChange} checked={is_work_order} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{ textAlign: "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Análitica? </Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_tracking" onChange={handleInputChange} checked={is_tracking} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{ textAlign: "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Envios? </Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_send" onChange={handleInputChange} checked={is_send} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            <ModalBody style={{ "text-align": "-webkit-right" }}>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button> &nbsp;
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalBody>
        </Form>

    )
}
