import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Button, Col, Row, Label } from 'reactstrap';
import './styleDashboard.css';

export const ModalCheckDelivery = (props) => {
    const {
        modalCheckDelivery: modal, toggleCheckDelivery: toggle, changeEstatus, idWorkOrder, commentPatient,
    } = props;

    const comments = Array.isArray(commentPatient)
        ? commentPatient.filter((comment) => comment.id_work_order !== undefined && comment.id_work_order !== null)
        : [{ comment_patient: commentPatient, id_work_order: idWorkOrder }];

    const comment = comments.find((patient) => patient.id_work_order === idWorkOrder)?.comment_patient || '';

    const [localComment, setLocalComment] = useState(comment);

    useEffect(() => {
        setLocalComment(comment);
    }, [comment]);

    const handleContinue = (e) => {
        e.preventDefault();
        changeEstatus(idWorkOrder, localComment);
    };

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                Atención
            </ModalHeader>

            <ModalBody>
                <h6 className='text-center'>¿Estás seguro de que deseas proceder con la entrega?</h6>
                <p className='text-center'>Ten en cuenta que al realizar esta acción, se desactivará la opción de impresión.</p>
            </ModalBody>

            <CardHeader className=''>
                <Row className=''>
                    <Col sm="12" md="12" lg="12" xl="12">
                        <br />
                        <Label className='f-w-600 f-12 badge badge-light-primary'>Comentario del Paciente</Label>
                        <textarea
                            name='comment'
                            className='form-control form-control-sm input-air-primary'
                            rows={2}
                            value={localComment}
                            onChange={(e) => setLocalComment(e.target.value)}
                            autoComplete='off'
                        />
                    </Col>
                </Row>
            </CardHeader>

            <ModalFooter>
                <Button size="sm" outline color="primary" type="button" onClick={handleContinue}>
                    Si, continuar
                </Button>
                <Button size="sm" className="btn-air-light" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};
