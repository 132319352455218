import React, { useState, useEffect } from 'react'
import { Button, Collapse, Label, Row, CardHeader, Col, Card, CardBody, CardFooter } from "reactstrap";
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { sendRequest } from '../../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../../../hooks/tokens/useToken';
import { useHistory } from 'react-router-dom';

export const IndexProcessesMicrobiology = ({ examId, sectionId }) => {

    const history = useHistory();

    const [arreglo, setArreglo] = useState([]);
    const [processes, setProcesses] = useState([]);
    const [processesSelected, setProcessesSelected] = useState([]);
    const [processesDeleted, setProcessesDeleted] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const grid = 8;

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#ffefc4" : "#eaeaea",
        padding: grid,
        display: 'flex',
        width: "100%",
        overflow: 'auto',
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `${grid * 1}px ${grid * 2}px`,
        margin: `0 ${grid}px`,
        background: isDragging ? "#fff" : "#ffffff",
        boxShadow: isDragging ? `0 0 10px` : "",
        border: "1px solid #cecece",
        width: "150px",
        height: "100px",
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        let list = arreglo;
        const items = reorder(list, sourceIndex, destIndex);
        setArreglo(items);
        setProcessesSelected([...items]);
    };

    const reorder = (list, startIndex, endIndex) => {
        let result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    useEffect(() => {
        if (sectionId > 0) {
            getProcessesInSection(sectionId);
        }
    }, [sectionId]);

    useEffect(() => {
        if (examId > 0 && sectionId > 0) {
            getProcessesInExam(examId);
        }
    }, [examId, sectionId])


    const getProcessesInExam = async (id_exam) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInExam/" + id_exam);

        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.label = element.name + "[" + element.code + "]";
                element.value = element.id_process;
            });
            setProcessesSelected([...respuesta.data]);
            setArreglo([...respuesta.data]);
        } else {
            validarSesion(history, respuesta.code, getProcessesInExam);
        }
    }

    const getProcessesInSection = async (id_section) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInSection/" + id_section + "/false");

        if (respuesta.code === 200) {
            respuesta.data.map(element => {
                element.label = element.name + "[" + element.code + "]";
                element.value = element.id_process;
            });
            setProcesses(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getProcessesInSection);
        }
    }

    const changeSelectProcesses = (array, actionData) => {
        setArreglo(array);
        setProcessesSelected(array);
        if (actionData.action === "remove-value") {
            setProcessesDeleted([...processesDeleted, actionData.removedValue])
        }
    }

    const saveOrUpdate = async () => {
        setLoading(true);
        let newProcessesSelected = processesSelected;
        let newProcessesDeleted = processesDeleted;

        newProcessesSelected.forEach(element => {
            newProcessesDeleted = newProcessesDeleted.filter(x => x.id_process !== element.id_process);
        });

        newProcessesDeleted = newProcessesDeleted.filter((item, index) => {
            return newProcessesDeleted.indexOf(item) === index;
        });

        ////console.log(newProcessesSelected);
        ////console.log(newProcessesDeleted);

        let data = {
            "id_section": examId,
            "list_processes": newProcessesSelected,
            "list_processes_delete": newProcessesDeleted
        }

        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Processes/SaveOrUpdateProccesesInExam");
        if (respuesta.code === 200) {
            createSweet("create", "success", "Éxito", "Procesos actualizados");
        } else {
            validarSesion(history, respuesta.code, saveOrUpdate);
        }
        setLoading(false);
        // SaveOrUpdateProccesesInExamdatos mostrasos
      
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    <Card className="form theme-form">
                        <CardHeader className=' bg-secondary p-3'>
                            <h6 className="mb-0">
                                <i className="icofont icofont-stethoscope-alt f-22 "></i>Asignación de procesos al examen
                            </h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={12}>
                                    <p>Selecciona los procesos por los cual pasará examen</p>
                                    <Select
                                        isMulti={true}
                                        className="form-control form-control-plaintext form-control-sm"
                                        classNamePrefix="select"
                                        name="id_exam_include"
                                        options={processes}
                                        value={processesSelected}
                                        placeholder={`Selección de procesos`}
                                        isClearable={false}
                                        onChange={(array, actionData) => changeSelectProcesses(array, actionData)}
                                    />
                                </Col>
                                <Col sm={12} className="pt-5">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable" type="droppableItem" direction='horizontal'>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {arreglo.map((item, index) => (
                                                        <Draggable
                                                            key={"proceso-" + item.id_process}
                                                            draggableId={"proceso-" + item.id_process}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div>
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter >
                            <Button
                             outline color="primary"
                                className={
                                    loading ? "disabled progress-bar-animated progress-bar-striped" : ""
                                }
                                onClick={saveOrUpdate}>
                                Actualizar
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
