import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col} from 'reactstrap';
import { sendRequest } from '../../hooks/requests/useRequest';
import Chart from 'react-apexcharts'
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../hooks/tokens/useToken';
import './StyleResult.css'
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { ModalChartResult } from './ModalChartResult';
import { ComponenName } from './analyteCurvas/ComponenName';
import { ComponentNumberSampling } from './analyteCurvas/ComponentNumberSampling';
import { ComponetRange } from './analyteCurvas/ComponetRange';
import { ComponentUnit } from './analyteCurvas/ComponentUnit';

export const ComponentProfileChart = ({analyte2,index,method,idPatient } ) => {

  ////console.log("analito: "+ analyte2);
  ////console.log("id paciente: "+idPatient);

  //#region states

  //#end

  const [chartOptions, setChartOptions] = useState({});
  const [series, setSeries] = useState([])
  const [show, setShow] = useState(true);
  const [createSweet] = useSweetAlert();

  const history = useHistory();


  useEffect(() => {

    if (idPatient !== 0) {
      chartCaptureresul(idPatient);
    }

}, [idPatient])


  const chartCaptureresul = async (idP) =>{

  
    let requestOptions = {
        method: 'GET'
    };

     
    const respuesta = await sendRequest(requestOptions, `Exam/charthistoricAnalitValues/${idPatient}/${analyte2.id_analyte}`);

    if (respuesta.code === 200) {
      ////console.log(respuesta.data);
        let options ={
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false
                    },
                    toolbar:{
                      tools:{
                          download: false
                        },
                    },
                    dropShadow: {
                      enabled: true,
                      color: '#000',
                      top: 18,
                      left: 7,
                      blur: 10,
                      opacity: 0.2
                    },
                  },
                  colors: ['#0079C7'],
                  dataLabels: {
                    enabled: true
                  },
                  stroke: {
                    curve: 'smooth'
                  },
                
                  grid: {
                    row: {
                      colors: ['#46AFE5', 'transparent'], 
                      opacity: 0.5
                    },
                  },
                  xaxis: {
                      categories: respuesta.data.categories,
                  },
                  yaxis: {
                       title: {
                                 text: (analyte2.unit)
                              },
                          }
                    }
             setChartOptions(options);
             setSeries(respuesta.data.series)
            }
            else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code, chartCaptureresul);
            }
            else if (respuesta.code === 500) {
               // createSweet("error", "error", "Resultados", "Ocurrio un error en el servidor");
            }
            else {
              //  createSweet("error", "warning", "Resultados", respuesta.data.msg);
            }
    }






  let namm = analyte2.name
  let namm2 = namm.toLowerCase()
                                                                  
  function capitalize(word) {
      return word[0].toUpperCase() + word.slice(1);
    }


    //#region modalChart

    const [titleChart, setTitleChart] = useState("");
    const [typeModalChart, setTypeModalChart] = useState("viewChart");
    const [modalChart, setModalChart] = useState(false);
    const [nameChart, setNameChart] = useState(analyte2.name)




    
    const toggleChart = () =>{
      setModalChart(!modalChart)
    }

    const handleChart = () => {

      setTypeModalChart("viewChart");
      setTitleChart("Grafica");
      toggleChart();   
      chartCaptureresul(idPatient);
      }


  
    //#endregion modalChart

 
    let numeros = [3, 23, 12];

    numeros.sort(function(a, b){return a - b}); // --> 3, 12, 23









    return (
        <>
       <tr key={index} style={{ textAlign : "-webkit-center"  }} >
        {
          analyte2.is_curve === false ?
          <>
            <td style={{ textAlignLast: "", width: "8px" }}>
             <i className="icofont icofont-chart-line txt-primary f-28 pointer" onClick={handleChart}  ></i>
            </td>
            <td style={{ textAlignLast: "", width: "18px" }}>
              {
                <>
                <b>{analyte2.name}</b> <br />
                {
                  analyte2.method === null ?
                  <label className='font-method1 f-10'>Método:{method}</label>
                  :
                  <label className='font-method1 f-10'>Método:{analyte2.method}</label>
                }
                </>
              }
            </td>
            <td style={{ textAlignLast: "", width: "18px" }} >
                {
                    analyte2.alert === null ?
                    <label>{analyte2.result}</label>
                    :
                    <b>{analyte2.result}</b>
                } 
            </td>
            <td style={{ textAlignLast: "", width: "18px" }}>
                {
                    analyte2.alert === null ? 
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;{analyte2.unit}</label>
                    :
                    (analyte2.alert === "danger") ?
                    <label ><i className="fa fa-arrow-up"></i>&nbsp;&nbsp;{analyte2.unit} </label>
                    :
                    <label ><i className="fa fa-arrow-down"></i>&nbsp;&nbsp;{analyte2.unit}</label>
                }
            </td>
            <td style={{ textAlignLast: "", width: "10px" }}>
                {
                    (analyte2.range === undefined) ? "" 
                    :
                    (analyte2.text_alerts === null ) ?
                    <div>
                        <label>{analyte2.range}</label>
                    </div>
                    :
                    (
                      <>
                        <label>{analyte2.range}</label> <br />
                        {
                          analyte2.text_alerts.map((obj,i) => {
                            return <div key={i}>
                                    <u className='labelTxtAlert'>{obj}</u>
                                    </div>
                          })
                        }
                      </>
                    )
                    
                    // (
                    //     (analyte2.range === "" || analyte2.text_alerts >=1) ? 
                    //     <div>
                    //         {
                    //             analyte2.text_alerts === null || analyte2.text_alerts >=1 || analyte2.text_alerts.map((obj,i) =>{
                    //               ////console.log(obj);
                    //                 return <div key={i}>
                    //                     <u className='labelTxtAlert'>{obj}</u>
                    //                 </div>
                    //             })
                    //         }
                    //     </div>
                    //     :
                    //     ""
                    // )
                }
            </td>

          </>
          :
          <>
          <td style={{ textAlignLast: "", width: "8px" }}>
           <i className="icofont icofont-chart-line txt-primary f-28 pointer" onClick={handleChart}></i> 
          </td>
          <td>
          {
            analyte2.analyteCurves.map((obj, key) => {
              return(
                <ComponenName
                analyteCurves={obj}
                key={key}
                />
              )
            })
          }
          </td>
          <td>
            {
               analyte2.analyteCurves.map((obj, key) => {
                return(
                  <ComponentNumberSampling
                  analyteCurves={obj}
                  key={key}
                  />
                )
              })
            }
          </td>
          <td>
            {
               analyte2.analyteCurves.map((obj, key) => {
                return(
                  <ComponentUnit
                  analyteCurves={obj}
                  key={key}
                  />
                )
              })
            }
          </td>
          <td>
            {
               analyte2.analyteCurves.map((obj, key) => {
                return(
                  <ComponetRange
                  analyteCurves={obj}
                  key={key}
                  />
                )
              })
            }
          </td>
          </>
        }
        </tr>
        
          <ModalChartResult
          {
            ...{titleChart,typeModalChart,modalChart,toggleChart,series,chartOptions,nameChart}
          }

          />
        
        </>
  )
}
