import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormRates } from './FormRates';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { DeleteRates } from './DeleteRates';

export const ModalRates = (props) => {

    const {
        branches, commercialLines, typeRate, modal, toggle, modalTitle, method, loading,
        handleInputChange, handleSelectValues, validacionesRates,
        name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate,
        saveCreate, saveUpdate, saveDelete, changeCheckbox, isMultiSelectOpen,
        toggleDivCommercialLine, branchDefault, selectedBranch,
        viewDivBranches
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={ toggle } className="bg-primary">
                { modalTitle }
            </ModalHeader>
            {
                method === "create"
                    ? <FormRates button={ ModalButtonSave }
                            { ...{ branches, commercialLines, typeRate, toggle, loading, handleInputChange, validacionesRates, methodAction: saveCreate, name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate, toggleDivCommercialLine, viewDivBranches, branchDefault, changeCheckbox, isMultiSelectOpen, selectedBranch }}
                        />
                    : (method === "update")
                        ? <FormRates button={ModalButtonUpdate} 
                            { ...{ branches, commercialLines, typeRate, toggle, loading, handleInputChange, validacionesRates, methodAction: saveUpdate, name, abbreviation, id_commercial_line, id_branch, id_type_rate, default_rate, toggleDivCommercialLine, viewDivBranches, branchDefault, changeCheckbox, isMultiSelectOpen, selectedBranch }}
                            />
                        : <DeleteRates { ...{ toggle, loading, name, abbreviation, saveDelete }} />                        
            }
        </Modal>
    )
}
