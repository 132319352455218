import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteBrand } from './DeleteBrand';
import { FormBrand } from './FormBrand';


export const ModalBrand = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle} className="bg-primary f-12">
            {modalTitle}
        </ModalHeader>
        {
            method === "create"
                ? <FormBrand
                    button={"Crear marca"}
                    {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                />
                : (method === "update")
                    ?
                    <FormBrand
                        button={"Actualizar marca"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name }} />
                    :
                    <DeleteBrand {...{ toggle, loading, name, saveDelete }} />
        }
    </Modal>
};
