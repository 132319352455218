import React, { useEffect } from 'react'
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, ModalBody, ModalFooter, Row, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import { VisibilityOutlined } from '@mui/icons-material';

export const FormPatient = (props) => {

    const {
        button, method, toggle, loading,

        validationsPatient, validHopitalInterfaced, methodAction,

        handleInputChange, handleSelectValues, handleDate,

        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender, expedient_number,
        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,

        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender, handleWheel,

        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender,
        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, visibility,
        toggleTab, activeTab, isEditWorkOrder, handleAgeC, handleDateB, getAge, getBirthday, isEditPatient
    } = props;

    console.log('isEditPatient ', isEditPatient)
    console.log('isEditWorkOrder ', isEditWorkOrder)

    const {
        id_gender_valid,
        paternal_surname_valid,
        maternal_surname_valid,
        name_valid,
        birthday_valid,
        age_valid,
        phone_valid,
        email_valid,
        curp_valid,
        cp_patient_valid,
        id_tax_regime_valid,
        rfc_valid,
        id_use_cfdi_valid,
        municipality_valid,
        state_valid,
        address_valid,
        colony_valid,
        cardnumber_valid,
        membership_valid,
        id_blood_type_validation,
        id_social_gender_patient_validation,
    } = validationsPatient;
    const complete = (name + ' ' + paternal_surname + ' ' + maternal_surname)
    useEffect(() => {
        if (isInvoiceService) {
            toggleTab('2');
        } else {
            toggleTab('1');
        }
    }, [isInvoiceService]);

    console.log('toggleTab, activeTab : ', activeTab)
    return (
        <>
            <ModalBody className='p-b-0' >
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={({ active: activeTab === '1' })}
                            onClick={() => { toggleTab('1'); }}
                        >
                            Paciente
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={({ active: activeTab === '2' })}
                            onClick={() => { toggleTab('2'); }}
                        >
                            Facturación
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    {activeTab === '1' && (
                        <Row>
                            <Col sm="12" md="12" lg="12">
                                <span className='link text-sm text-primary badge badge-light-primary f-12'>Información general</span>
                            </Col>

                            {
                                visibility.name === true && <>
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Nombres
                                                {visibility.name && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                                                )}
                                            </Label>
                                            <Input
                                                type='text'
                                                name="name"
                                                value={name}
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                                invalid={name_valid}
                                                className='form-control form-control-sm input-air-primary'
                                            />
                                            <FormFeedback>Nombre inválido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                            {
                                visibility.paternal_surname === true && (
                                    <>
                                        <Col sm="3" md="3" lg="3">
                                            <FormGroup>
                                                <Label className='col-form-label'>
                                                    Apellido paterno
                                                    {visibility.paternal_surname && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                                                    )
                                                    }
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name='paternal_surname'
                                                    value={paternal_surname}
                                                    autoComplete='off'
                                                    onChange={handleInputChange}
                                                    invalid={paternal_surname_valid}
                                                    className='form-control form-control-sm input-air-primary' />
                                                <FormFeedback>Completa este campo</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                visibility.maternal_surname === true && (
                                    <>
                                        <Col sm="3" md="3" lg="3" >
                                            <FormGroup>
                                                <Label className='col-form-label'>
                                                    Apellido materno
                                                    {/* {visibility.maternal_surname && (
                                                        <span className='f-16' style={{ color: "#0079c7" }}> *</span>
                                                    )} */}
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name='maternal_surname'
                                                    value={maternal_surname}
                                                    autoComplete='off'
                                                    //invalid={maternal_surname_valid}
                                                    onChange={handleInputChange}
                                                    className='form-control form-control-sm input-air-primary' />
                                                <FormFeedback>Completa este campo</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                visibility.id_gender === true && (
                                    <>
                                        <Col sm="3" md="3" lg="3">
                                            <FormGroup>
                                                <Label className='col-form-label'>Género
                                                    {visibility.id_gender && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                                                    )}
                                                </Label>
                                                <Input
                                                    type='select'
                                                    name="id_gender"
                                                    onChange={handleInputChange}
                                                    className='form-control form-control-sm input-air-primary'
                                                    value={id_gender}
                                                    invalid={id_gender_valid}>
                                                    <option value={0}>Selecciona una opción</option>
                                                    <option value={1}>Femenino</option>
                                                    <option value={2}>Masculino</option>
                                                </Input>
                                                <FormFeedback>Selecciona una opción</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </>
                                )
                            }

                            {
                                visibility.birthday && (
                                    <Col sm="3" md="3" lg="3">
                                        <Label className='col-form-label'>Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span></Label>
                                        <Input
                                            type="date"
                                            name="birthday"
                                            value={birthday}
                                            onChange={(e) => handleDateB(e.target.value)}
                                        />
                                        <FormText color='danger' hidden={!birthday_valid}>Ingresa una fecha valida</FormText>
                                    </Col>
                                )
                            }
                            {
                                visibility.age && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Edad
                                                {
                                                    visibility.age && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> * </span>
                                                    )
                                                }
                                            </Label>
                                            <Input
                                                type="number"
                                                min={0}
                                                max={110}
                                                name='age'
                                                value={age}
                                                onChange={(e) => getAge(e.target.value)}
                                            />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }

                            {/* {
                                visibility.birthday && (
                                    <Col sm="3" md="3" lg="3">
                                        <Label className='col-form-label'>Fecha de nacimiento <span className='f-16' style={{ color: "#0079C7" }}>*</span> <span style={{ color: "#0079C7" }}><b>(Dia/Mes/Año)</b></span></Label>
                                        <MaskedInput
                                            mask={[
                                                /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/
                                            ]}
                                            className='form-control form-control-sm input-air-primary'
                                            placeholder='Dia/Mes/Año'
                                            name='birthday'
                                            value={birthday}
                                            guide={false}
                                            onChange={(e) => handleDate(e, "birthday")}
                                        />
                                        <FormText color='danger' hidden={!birthday_valid}>Ingresa una fecha valida</FormText>
                                    </Col>
                                )
                            }
                            {
                                visibility.age && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Edad
                                                {
                                                    visibility.age && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> * </span>
                                                    )
                                                }
                                            </Label>
                                            <Input
                                                type="text" // Mantenemos "text" para permitir cadenas vacías
                                                name='age'
                                                value={age}
                                                autoComplete='off'
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Permitir que el campo esté vacío o que se escriban números dentro del rango
                                                    if (value === '' || (/^\d*$/.test(value) && Number(value) >= 0 && Number(value) <= 110)) {
                                                        handleInputChange(e); // Actualiza el estado con el valor actual
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    // Evitar caracteres no numéricos como 'e', 'E', '+', '-'
                                                    if (['e', 'E', '+', '-'].includes(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                invalid={age_valid}
                                                className='form-control form-control-sm input-air-primary'
                                            />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            } */}

                            {visibility.phone === true && (
                                <>
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Teléfono
                                                {visibility.phone && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> * </span>
                                                )}
                                            </Label>
                                            <Input
                                                type='tel'
                                                name='phone'
                                                value={phone}
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                                invalid={phone_valid}
                                                className='form-control form-control-sm input-air-primary'
                                            />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </>
                            )}
                            {
                                validHopitalInterfaced === "True" &&
                                <Col sm="3" md="3" lg="3">
                                    <FormGroup>
                                        <Label className='col-form-label'>Núm. Expediente <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                        <Input type='expedient_number' name='expedient_number' value={expedient_number} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                        <FormFeedback>Completa este campo</FormFeedback>
                                    </FormGroup>
                                </Col>
                            }
                            <Col sm="3" md="3" lg="3" className="d-none">
                                <FormGroup>
                                    <Label className='col-form-label'>Escanear INE</Label>
                                    <br />
                                    <Label className='switch'>
                                        <Input type='checkbox' name='scan_ine_check' checked={scan_ine_check} onChange={() => setScan_ine_check()} />
                                        <span className='slider round'></span>
                                    </Label>
                                </FormGroup>
                            </Col>
                            {scan_ine_check == true ?
                                <Col sm="5" md="5" lg="5" xl="5">
                                    <FormGroup>
                                        <Label htmlFor="file_INE" className="primary btn-primary btn-sm" outline color='primary' size="xs">
                                            Cargar INE <span className="icofont icofont-upload-alt"></span>
                                            <Input type="file" accept="image/*" id="file_INE" name="file_INE" onChange={handleOnChangeINE} style={{ display: 'none' }} />
                                        </Label> &nbsp;
                                        <Label className='col-form-label'>{showClassINE.NameFile}</Label>
                                        <Button onClick={DeleteINE} className={showClassINE.ClassNameINE} outline color="danger" size="xs" type="button">
                                            <i className="fa fa-trash"></i>
                                        </Button>&nbsp; &nbsp;
                                    </FormGroup>
                                </Col> :
                                <Col>
                                </Col>
                            }

                            <Col sm="12" md="12" lg="12">
                                <span className='link text-sm text-primary badge badge-light-primary f-12'>Información adicional</span>
                            </Col>

                            {
                                visibility.id_state && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>Estado
                                                {visibility.id_state && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                )}
                                            </Label>
                                            <Select
                                                name="id_state"
                                                value={id_state}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listStates}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleChangeState(e)}
                                            />
                                            <FormText color='danger' hidden={!state_valid}>Selecciona un estado</FormText>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.id_municipality && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Municipio
                                                {visibility.id_municipality && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                )
                                                }</Label>
                                            <Select
                                                name="id_municipality"
                                                value={id_municipality}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listMunicipalities}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleSelectValues(e, "id_municipality")}
                                            />
                                            <FormText color='danger' hidden={!municipality_valid}>Selecciona un municipio</FormText>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.address && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Dirección
                                                {visibility.address && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                )
                                                }
                                            </Label>
                                            <Input
                                                type='text'
                                                name='address'
                                                value={address}
                                                autoComplete='off'
                                                invalid={address_valid}
                                                onChange={handleInputChange}
                                                className='form-control form-control-sm input-air-primary' />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.colony && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Colonia
                                                {visibility.colony && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                )}
                                            </Label>
                                            <Input
                                                type='text'
                                                name='colony'
                                                value={colony}
                                                autoComplete='off'
                                                invalid={colony_valid}
                                                onChange={handleInputChange}
                                                className='form-control form-control-sm input-air-primary' />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.cp && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Código postal
                                                {visibility.cp && (
                                                    <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                )}
                                            </Label>
                                            <Input
                                                type='number'
                                                name='cp'
                                                value={cp}
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                                className='form-control form-control-sm input-air-primary'
                                                invalid={cp_patient_valid} />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.email && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Correo
                                                {
                                                    visibility.email && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                    )
                                                }
                                            </Label>
                                            <Input
                                                type='tel'
                                                name='email'
                                                value={email}
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                                className='form-control form-control-sm input-air-primary'
                                                invalid={email_valid} />
                                            <FormFeedback>Ingresa un correo valido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.membership && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Membresia
                                                {
                                                    visibility.membership && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                    )
                                                }
                                            </Label>
                                            <Input
                                                type='text'
                                                autoComplete='off'
                                                className='form-control form-control-sm input-air-primary'
                                                name='membership'
                                                value={membership}
                                                invalid={membership_valid}
                                                onChange={handleInputChange} />
                                            <FormFeedback>Ingresa un correo valido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.unique_uuid && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                # Tarjeta QR
                                                {
                                                    visibility.unique_uuid && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                    )
                                                }
                                            </Label>
                                            <Input
                                                type='text'
                                                autoComplete='off'
                                                className='form-control form-control-sm input-air-primary'
                                                name='cardnumber'
                                                value={cardnumber}
                                                invalid={cardnumber_valid}
                                                onChange={handleInputChange} />
                                            <FormFeedback>Ingresa un correo valido</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {/* <Col sm="12" md="12" lg="12">
                                <br />
                                <Row>
                                    <Col sm="12">
                                        <span className='link text-sm text-primary badge badge-light-primary f-12'>Información adicional</span>
                                    </Col>
                                </Row>
                            </Col> */}
                            {
                                visibility.id_social_gender_patient && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>Género social  <span hidden={!viewNewSocialGender} className='link text-sm text-primary badge badge-light-primary f-12' onClick={() => setViewNewSocialGender(!viewNewSocialGender)}>Regresar</span></Label>
                                            {
                                                !viewNewSocialGender
                                                    ?
                                                    <Select
                                                        name="id_social_gender"
                                                        value={id_social_gender}
                                                        isClearable={true}
                                                        placeholder="Selecciona una opción"
                                                        options={listSocialGender}
                                                        className="input-air-primary input-sm"
                                                        onChange={(e) => handleChangeSocialGender(e)}
                                                    />
                                                    : <Input type='text' name='new_social_gender' value={new_social_gender} autoComplete='off' onChange={handleInputChange} className='form-control form-control-sm input-air-primary' />
                                            }
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.id_blood_type && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>
                                                Tipo sanguineo
                                                {
                                                    visibility.id_blood_type && (
                                                        <span className='f-16' style={{ color: "#0079C7" }}> *</span>
                                                    )
                                                }
                                            </Label>
                                            <Select
                                                name="id_blood_type"
                                                value={id_blood_type}
                                                isClearable={true}
                                                placeholder="Selecciona una opción"
                                                options={listBloodType}
                                                className="input-air-primary input-sm"
                                                onChange={(e) => handleSelectValues(e, "id_blood_type")}
                                            />
                                            <FormText color='danger' hidden={!id_blood_type_validation}>Selecciona un tipo de sangre</FormText>
                                        </FormGroup>
                                    </Col>
                                )
                            }
                            {
                                visibility.curp && (
                                    <Col sm="3" md="3" lg="3">
                                        <FormGroup>
                                            <Label className='col-form-label'>CURP
                                                {visibility.curp && (

                                                    <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                                                )}
                                            </Label>
                                            <Input
                                                type='text'
                                                name='curp'
                                                value={curp}
                                                autoComplete='off'
                                                onChange={handleInputChange}
                                                className='form-control form-control-sm input-air-primary'
                                                invalid={curp_valid}
                                            />
                                            <FormFeedback>Completa este campo</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                )
                            }

                        </Row>
                    )}
                </TabContent>

                {
                    method !== 'create_mic_patient' && <>
                        <br />
                        
                        {(!isEditWorkOrder) && (isEditPatient !== false) &&(
                            <Row className='mt-1 justify-content-center'>
                                <Col sm="12" md="12" lg="12">
                                    <br />
                                    <Row className='justify-content-center'>
                                        <Col className='p-l-0' sm="3" style={{ textAlign: "center" }}>
                                            <span className='link text-sm text-primary badge badge-light-primary f-12'>
                                                Datos de facturación
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                                {visibility.id_use_cfdi && visibility.id_tax_regime && visibility.type_person && visibility.rfc && visibility.cp && visibility.email && (
                                    <Col sm="3" md="3" lg="3" style={{ textAlign: "center" }}>
                                        <br />
                                        <FormGroup>
                                            <Label className='col-form-label'>¿Capturar datos de facturación?</Label>
                                            <br />
                                            <Label className='switch'>
                                                <br /><br />
                                                <Input type='checkbox' name='isInvoiceService' checked={isInvoiceService} onChange={() => setIsInvoiceService(!isInvoiceService)} />
                                                <span className='slider round'></span>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                        )
                        }
                        {
                            (!isEditWorkOrder || !isEditPatient) && (
                                <TabContent activeTab={activeTab}>
                                    {activeTab === '2' && (
                                        <Row>
                                            <Col sm="6" md="6" lg="6">
                                                <FormGroup>
                                                    <Label className='col-form-label'>
                                                        NOMBRE:
                                                    </Label>
                                                    <Input
                                                        type='text'
                                                        value={complete}
                                                        autoComplete='off'
                                                        className='form-control form-control-sm input-air-primary'
                                                        disabled={true}
                                                    />
                                                    <FormFeedback>Nombre inválido</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6" md="6" lg="6">
                                            </Col>
                                            {
                                                isInvoiceService === true && <>
                                                    <Col sm="3" md="3" lg="3">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>RFC <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Input type='text' name="rfc" value={rfc} className='form-control form-control-sm input-air-primary' onChange={handleInputChange} invalid={rfc_valid} placeholder='XAXX010101000' />
                                                            <FormFeedback>Completa este campo</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3" md="3" lg="3">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Tipo de persona <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Input type="select" name='type_person' value={type_person} onChange={(e) => handleChangeTypePerson(e)} className='form-control input-air-primary'>
                                                                <option value="1">Física</option>
                                                                <option value="2">Moral</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                isInvoiceService === true && <>

                                                    <Col sm="6" md="6" lg="6">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Régimen fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Select
                                                                name="id_tax_regime"
                                                                value={id_tax_regime}
                                                                isClearable={true}
                                                                placeholder="Selecciona una opción"
                                                                options={listTaxRegime}
                                                                className="input-air-primary input-sm"
                                                                onChange={(e) => handleChangeTaxRegime(e)}
                                                            />
                                                            <FormText color='danger' hidden={!id_tax_regime_valid}>Selecciona una opción</FormText>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6" md="6" lg="6">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Uso de CFDI <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Select
                                                                name="id_use_cfdi"
                                                                value={id_use_cfdi}
                                                                isClearable={true}
                                                                placeholder="Selecciona una opción"
                                                                options={listUseCfdi}
                                                                className="input-air-primary input-sm"
                                                                onChange={(e) => handleSelectValues(e, "id_use_cfdi")}
                                                            />
                                                            <FormText color='danger' hidden={!id_use_cfdi_valid}>Selecciona una opción</FormText>
                                                        </FormGroup>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    )}
                                </TabContent>
                            )

                        }

                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type="button" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} onClick={methodAction}>
                    {button}
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </>
    )
}
