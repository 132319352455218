import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { FormPrecodedText } from './FormPrecodedText';
import { DeletePrecodedText } from './DeletePrecodedText';

export const ModalPrecodedText = (props) => {
    const { modal, toggle, loading, method, id_type_precoded_text, text, typePrecodedTexts, handleInputChange, validaciones, saveCreate, saveUpdate, saveDelete } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Creación de texto precodificado"
                        : (method === "update") ?
                            "Actualización de texto precodificado"
                            : "Eliminar texto precodificado"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormPrecodedText
                        button="Crear texto precodificado"
                        {...{ toggle, id_type_precoded_text, text, typePrecodedTexts, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update") ?
                        <FormPrecodedText
                            button="Actualizar texto precodificado"
                            {...{ toggle, loading, id_type_precoded_text, text, typePrecodedTexts, handleInputChange, validaciones, methodAction: saveUpdate }}
                        />
                        : <DeletePrecodedText {...{ toggle, loading, text, saveDelete }} />
            }
        </Modal>
    )
}
