import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteRack } from './DeleteRack';
import { FormRack } from './FormRack';

export const ModalRack = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        name, code, slots, handleInputChange, validaciones,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormRack button={"Guardar gradilla"} {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }} />
                    : (method === "update")
                        ? <FormRack button={"Actualizar gradilla"} {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, code, slots }} />
                        : <DeleteRack {...{ toggle, loading, name, code, slots, saveDelete }} />
            }
        </Modal>
    )
}
