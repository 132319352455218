import React from 'react';
import { Modal, ModalBody, Col, Row, Button } from 'reactstrap';

export const ModalPreviewGlobalBranch = (props) => {

    const {
        toggleB, typeModalB = "BrachC", modalB, loadingData, cardInfoKey, cardInfo
    } = props;


    return (
        <Modal isOpen={modalB} toggle={toggleB} backdrop='static' keyboard={false} centered={true} size={loadingData === false ? '' : 'xl'} scrollable={true} >
            <ModalBody className="border-3 b-primary "  >
                {
                    typeModalB === "BrachC" ?
                        <div>
                            {
                                loadingData === false ?
                                    <>
                                        <Row className='mt-2'>
                                            <Col sm="12" md="12" lg="12" className='text-center'>
                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            <Col sm="3" md="3" lg="3" className='text-left'>
                                                <p className='f-24 text-primary'><b>Cargando</b></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="1" md="1" lg="1" className='text-center'>
                                                <br />
                                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                            </Col>
                                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                        </Row>
                                        <Col sm="12" style={{ textAlign: "end" }} >
                                            <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleB}>Cerrar</Button>
                                        </Col>
                                    </>
                                    :
                                    <div>
                                        <b className='f-16 txt-secondary'>Responsable del corte:</b>
                                        <hr />
                                        <Row>
                                            {
                                                cardInfoKey.length > 0 && cardInfoKey.sort() && cardInfoKey.map((e, k) => {
                                                    return (
                                                        <Col sm="6" key={k} className >
                                                            <Col sm="12" className="p-l-0 p-r-0" >
                                                                <Row>
                                                                    <Col sm="12">
                                                                        <Row>
                                                                            <Col>
                                                                                <label className="labelStudyExamToma"><b>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{e}</b> </label>
                                                                            </Col>
                                                                            {
                                                                                cardInfo[e].secciones != undefined && cardInfo[e].secciones.map((l, keyy) => {
                                                                                    return (
                                                                                        <Col sm="12" key={keyy}>
                                                                                            <img className="img-35 mr-1 rounded-circle"
                                                                                                src={require("../../assets/images/loginHelenLabs/Dicount/UserDiscount.svg")} style={{ width: "6%", filter: "blur(1px)" }}
                                                                                                alt="GlobalCash" />
                                                                                            <label className="">{l.user}</label>&nbsp;<label className='txt-secondary '>({l.fech})</label>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>  <br /> <br /> <br /> <br />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        <Col sm="12" style={{ textAlign: 'right' }} >
                                            <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleB}>Cerrar</Button>
                                        </Col>
                                    </div>
                            }
                        </div>
                        :
                        ""
                }
            </ModalBody>
        </Modal>
    )
}