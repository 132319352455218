import React from 'react';
import { FormGroup, Label, Input, FormFeedback ,Col} from 'reactstrap';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

export const TextPrecodedForm = (props) => {
    const {
        activeTab, isSimple, id_type_analyte, id_type_precoded_text,
        typePrecodedTexts,
        handleInputChange,
        vIdTypePrecodedText,
        //
        onChange,data,handleChangeTextP,selected
    } = props;

   

   
    // ${isSimple ? "col-3" : "col-12"}
   
    return (
        <>
        <Col sm={ isSimple === true ? '12' : '9'} className="p-l-0" >
        <FormGroup className={`mb-3  ${(id_type_analyte === "1" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "7") ? "" : "d-none"}`}>
                <Label className="text-sm">Tipo de texto precodificado </Label>
                <Input
                    className="form-control form-control-sm input-air-primary" 
                    disabled={activeTab === 2}
                    bsSize="sm"
                    type="select"
                    name="id_type_precoded_text"
                    value={id_type_precoded_text}
                    onChange={(e) => handleChangeTextP(e)}
                    required={true}
                >
                    <option value={0}>Selecciona una opción</option>
                    {
                        typePrecodedTexts.length > 0 &&
                      
                        typePrecodedTexts.map((u, key) => {
                            return <option
                                key={key}
                                value={u.id_type_precoded_text}
                            >
                                {u.name}
                            </option>
                        })
                    }
                </Input>
                <Input type="hidden" invalid={vIdTypePrecodedText} />
                <FormFeedback>Debes seleccionar una opción</FormFeedback>
            </FormGroup>
                {
                  isSimple === true && id_type_precoded_text >=1 && id_type_analyte ==="1" ? //completo 
                  <div>
                    <Col sm="12"> <br /> 
                        <DualListBox
                          selected={selected}//el que va a la derecha
                          simpleValue={false}
                          onChange={onChange}
                          filterPlaceholder={"Buscar..."}
                          showNoOptionsText={true}
                          canFilter options={data}//izquierda
                          lang={ {
                            // availableFilterHeader: 'Filter available',
                           //  availableHeader: 'Available',
                             moveAllLeft: 'Remover todas las opciones',
                             moveAllRight: 'Agregar todas las opciones',
                             moveLeft: 'Remover la opción seleccionada',
                             moveRight: 'Agregar la opcion seleccionada',
                             moveBottom: 'Move to bottom',
                            // moveDown: 'Move down',
                             //moveUp: 'Move up',
                             ///moveTop: 'Move to top',
                             noAvailableOptions: 'No hay más textos precodificados disponibles',
                             noSelectedOptions: 'No se ha seleccionado ningún texto precodificado',
                             //selectedFilterHeader: 'Filter selected',
                            // selectedHeader: 'Selected',
                         }}
                          
                         
                          
                         />
                     </Col>
                  </div> : ""
                }
        </Col>
           
        </>
    )
}
