import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useCurvesTimes = () => {
    
    const history = useHistory();

    const [curvesTimes, setCurvesTimes] = useState([]);
    const [idTime, setIdTime] = useState(0);

    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",        
    });

    const { name } = formValues;

    const [validations, setValidations] = useState({
        name_valid: false
    });

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(curvesTimes.length, numberItems);

    useEffect(() => {
        
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listTimes = [];

        curvesTimes.forEach( (c, key) => {
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTimes.push({
                ...c,
                visibleTable: visibleTable
            });
        });

        setCurvesTimes(listTimes);

    }, [totalPageCount, currentPage]);
    
    const toggle = () => {
        setModal(!modal);
    }

    const getTimes = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listTimes = [];

        const response = await sendRequest(requestOptions, "Time/List");

        if (response.code === 200) {

            if (response.data.length > 0) {
                
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                response.data.forEach( (obj, index) => {
                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listTimes.push({
                        ...obj,
                        visibleTable: visibleTable
                    });
                });

                setCurvesTimes(listTimes);
            }
            else {
                setCurvesTimes([]);
            }
        }
        else {
            validarSesion(history, getTimes);            
        }
    }

    useEffect(() => {
        getTimes();
    
    }, []);

    const handleCreate = () => {
        handleUpdateValues({
            name: ""
        });

        setValidations({
            name_valid: false
        });

        setIdTime(0);
        setMethod("create");
        setModalTitle("Creación de nuevo tiempo");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let data = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: data
        };
        
        const respuesta = await sendRequest(requestOptions, "Time/Create");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se creo correctamente");
            
            toggle();
            getTimes();
            handleUpdateValues({
                name: ""
            });
        }
        else {
            setLoading(false);
            validarSesion(history, handleCreate);
        }
    }

    const handleUpdate = (id_time) => {
        let time = curvesTimes.find(x => x.id_time === parseInt(id_time));

        handleUpdateValues({
            name: time.name
        });

        setValidations({
            name_valid: false
        });

        setIdTime(id_time);
        setMethod("update");
        setModalTitle("Actualizar tiempo de curva");
        toggle();
    }
    
    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let mValues = {
            id_time: idTime,
            ...{ ...formValues }
        };

        let data = JSON.stringify(mValues);

        let requestOptions = {
            method: 'PUT',
            body: data
        };

        const response = await sendRequest(requestOptions, "Time/Update");

        if (response.code === 200) {
            getTimes();
            createSweet("update", "info", "Exito!", "Se actualizo correctamente");
            setLoading(false);
            toggle();
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, saveUpdate);
        }
        else if(response.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", response.data.msg);
        }
    }

    const handleDelete = (id_time) => {
        let time = curvesTimes.find(x => x.id_time === parseInt(id_time));

        handleUpdateValues({
            name: time.name
        });

        setIdTime(id_time);
        setMethod("delete");
        setModalTitle("Eliminar Tiempo");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(false);
        let requestOptions = {
            method: 'DELETE'
        };

        const response = await sendRequest(requestOptions, `Time/Delete/${idTime}`);

        if (response.code === 200) {
            getTimes();
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, saveDelete);
        }
        else if(response.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", response.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 2) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        setValidations(newValidations);

        return statusValidation;
    }

    return {
        curvesTimes, method,
        name, handleInputChange, validations,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage
    }
}
