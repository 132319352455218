import React, { Fragment, useState } from 'react';
import { Card, Container, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { ComponentRoles } from './ComponentRoles';
import { ComponentUser } from './ComponentUser';

export const IndexUserRole = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <Nav className='nav-primary nav-fill flex-column flex-md-row round' tabs>
                                <NavItem>
                                    <NavLink href='#javascript' role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className='fa fa-user'></i> Usuarios</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href='#javascript' role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="icofont icofont-ui-password"></i> Roles</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className='fade show' tabId="1">
                                    <ComponentUser
                                        {...{...props, activeTab}}
                                    />  
                                </TabPane>
                                <TabPane className='fade show' tabId="2">
                                    <ComponentRoles
                                        {
                                            ...{props, activeTab}
                                        }
                                    />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
