import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Button, Card, CardBody, Col, Container, Row,Input } from 'reactstrap';
import { PriceList, ButtonGoBack } from '../../../constant';
import { useHistory } from 'react-router-dom';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useCost } from '../../../hooks/prices/useCost';
import { ModalCost } from './ModalCost';

export const IndexCost = () => {

    const history = useHistory();

    const {
        goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
        handleSearch, metaData, examsData,handlePriceExam,
        //MODAL
        method,modal,modalTitle,loading,toggle,
        idExam,arrayExam,handleEditPrice,
        cost,handleInputChange,
        saveCreateCost,price,producction,handleEditPrice2,
        handleMargin,
        id_sectionf,listSection,handleChangeSection,
        handleFilters
    } = useCost();

    const txtsection = localStorage.getItem('TextAS');
  return (
    <Fragment>
    <Container fluid={true}> <br />
        <Button color="light" className=" btn-air-light m-1"  onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {ButtonGoBack}</Button>                
        <Row>
            <Col md="12">
                <Card>
                    <CardBody className="p-1">
                        <div className="ribbon ribbon-clip ribbon-primary">
                            {PriceList}
                        </div> <br /> <br />
                        <Col  sm="12">
                            <Row>
                                <Col sm="3">
                                <br />  <br />
                                    <label>{txtsection}</label> <br />
                                <Input
                                    className="form-control"
                                    type='select'
                                    placeholder="Selecciona una opción"
                                    name="id_sectionf"
                                    onChange={handleChangeSection}
                                    Value={id_sectionf} >
                                    <option value={3}>{"Selecciona una opción"}</option>
                                         {
                                            listSection.length >= 1 && listSection.map((obj, index) => {
                                            return <option value={obj.id_section}>{obj.name}</option>
                                         })
                                        }
                                    </Input>
                                </Col>
                                <Col sm="3">
                                <br />  <br />
                                    <label>Busqueda por exámen o código</label> <br />
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearch} />
                                </Col>
                                <Col sm="1" style={{textAlign: "-webkit-center"}} className="p-2">
                                    <br /> <br />   <br />
                                    <Button color='primary'  size='SM' outline type='button' onClick={handleFilters}>Filtrar</Button>
                                </Col>
                            </Row>
                        </Col> <br />


                     
                        <div className="table-responsive">
                        <TableStriped
                         responsive={true}
                            notMaped={["visibleTable" , "margin_gain", "production_cost", "sell_price"]}
                            methodsActions={true}
                            cabeceras={["Código","Exámen","Costo de Producción","Margen de Ganancia","Precio de Venta"]}
                            items={metaData}
                            nextPage={goNextPage}
                            previousPage={goPreviousPage}
                            totalPageCount={totalPages}
                            currentPage={actualPage}
                            goToPage={goToPage}
                            paginingBack={true}
                            methodsAbsolutes={
                                [
                                    {
                                        type: "linkId",
                                        method: handlePriceExam,
                                        icon: "icofont icofont-cur-dollar-plus",
                                        backgroundColor: "#46AFE5",
                                        color: "#fff",
                                        name: "handlePriceExam",
                                        tooltip: "Editar"
                                    }
                                ]
                            }
                        />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
    <ModalCost
        {
            ...{method,modal,modalTitle,loading,toggle,idExam,arrayExam,handleEditPrice, cost,price,handleInputChange,
                saveCreateCost,producction,handleEditPrice2,handleMargin}
        }
    />

</Fragment>

  )
}
