import { Card, Col, Container, Row, Button, CardBody,Input } from 'reactstrap';
import React, { Fragment } from 'react'
import Breadcrumb from '../../layout/breadcrumb';
import { TableStriped } from '../../components/tables/TableStriped';
import { useCancellationReazonSample } from '../../hooks/sampling/useCancellationReazonSample'
import { ModalTomaMuestra } from './ModalTomaMuestra';
import { ModalUploadCSV } from './ModalUploadCSV';


export const IndexCancellationTM = () => {

    const {
        listCancellation,id_cancellation_reazon_sample, cancellation_reazon, handleInputChange,
        loading,handleDelete, saveUpdate,handleUpdate,
        handleCreateTM,toggle3,method3,modalTitle3,modal3,saveCreate,saveDelete,
        handleCreateCSV,method4,modal4,toogle4,typeModalCsv,CancellationReazonSample,modalTitle4,
        handleOnChange,file,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchCancellation

    }=useCancellationReazonSample();

    ////console.log(listCancellation);


  return (
    <Fragment>
        {/* <Breadcrumb parent="Toma De Muestra" title="Motivos de cancelaciones de Toma de Muestra" className="m-b-0" /> */}
        <Container fluid={true}>
            <Col sm="12">
                <Card>
                    <CardBody className='p-1'>
                        <div className="ribbon ribbon-clip ribbon-primary">{"Cancelaciones"}</div>
                        <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}> 
                           <Button className="btn btn-pill btn-air-success" color="light" size="sm"   onClick={handleCreateCSV}><span className="icofont icofont-upload-alt"></span></Button>
                           &nbsp; &nbsp; &nbsp; 
                               <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreateTM} ><span className="fa fa-plus-circle"></span></Button>
                        </div>
                        <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchCancellation} />
                                    </div>
                                </div>
                        <div className="table-responsive">
                            {
                                listCancellation.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "24%" }} alt="" />   <br />                                         
                                <br /> <p className="f-18">{"No se ha registrado un Doctor aún."}</p>
                                </div>
                                :
                                <TableStriped
                                notMaped={["visibleTable"]}
                                methodsActions={true}
                                cabeceras={["Motivos para Cancelar Toma de Muestra"]}
                                items={listCancellation}
                                 {...{ handleUpdate,handleDelete}}
                                 nextPage={nextPage}
                                 previousPage={previousPage}
                                 totalPageCount={totalPageCount}
                                 currentPage={currentPage}
                                 goToPage={goToPage}
                              
                                /> 
                            

                                
                            }
                              
                           </div>
                    </CardBody>
                </Card>
            </Col>
        </Container>
        <ModalTomaMuestra
        {
            ...{ toggle3,method3,modalTitle3,modal3,saveCreate, cancellation_reazon, handleInputChange,id_cancellation_reazon_sample,loading,saveUpdate,saveDelete}
        }
        />
        <ModalUploadCSV
        {
            ...{method4,modal4,toogle4,typeModalCsv,CancellationReazonSample,handleOnChange,file}
        }

        />
    </Fragment>
  )
}
