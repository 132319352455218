import React from 'react'
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import 'moment/locale/es';
import moment from 'moment';
import { ConfigServer } from "../../data/config";



export const useListWorkSheet = () => {

    const history = useHistory();
    const [createSweet] = useSweetAlert();
    const [ListSamplesWorkSheet, setListSamplesWorkSheet] = useState([]);
    const [countTotal, setCountTotal] = useState(0);
    const [countUsed, setCountUsed] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const [code_Quoter_Temp, setCode_Quoter_Temp] = useState([]);
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        date_from: "",
        date_to: ""
    });

    const {
        date_from, date_to
    } = formValues;
   
    const [modalViewListSampling, setModalViewListSampling] = useState(false);
    useEffect(() => {
        getListSamplings()
    }, [])
 
    
    const getListSamplings = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let mfrom = (date_from === '') ? moment().format("YYYY-MM-DD") : moment(date_from).format("YYYY-MM-DD");
        let mto = (date_to === '') ? moment().format("YYYY-MM-DD") : moment(date_to).format("YYYY-MM-DD");
        
        const respuesta = await sendRequest(requestOptions, `WorkSheetSanitary/ListSamplesWS/${mfrom}/${mto}`);

        if (respuesta.code === 200) {
            ////console.log(respuesta.data,"RESPUESTA");
            setListSamplesWorkSheet(respuesta.data) 
            setCountTotal(respuesta.data.total_Quoter)
            setCountUsed(respuesta.data.tota_Quoter_used)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getListSamplings);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Reportes", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Reportes", respuesta.data.msg);
        }
    }

    const validInputDate = (current) => { 

        let yesterday = moment().subtract(0, "day");
        return current.isBefore(yesterday);
    }

    const validToInputDate = (current) => {
        let date = moment(date_from).subtract(1, "day");
        return current.isAfter(date);
    }

    const obtenerDatos = () => {
        getListSamplings();
    }
    const handleListSampling = async (id_quoter) => {
       toggleModalViewListSampling(id_quoter);
    }
    
    const toggleModalViewListSampling = (id_quoter) => {
        const foundElement = ListSamplesWorkSheet.find(item => item.id_quoter === id_quoter);

                // Verifica si se encontró el elemento.
                if (foundElement) {
                    const listSamplesVariable = foundElement.listSamplesType;
                  
                    setCode_Quoter_Temp(foundElement.code);
                    ////console.log(listSamplesVariable);
                   
                }
                setModalViewListSampling(!modalViewListSampling);
    }

    const navigateUpdate = () => {
        history.push(`../sanitaria/cotizacion`);
    }
    const downdloadpdf = async (id_quoter = 0) => {
        let order = ListSamplesWorkSheet.find(w => w.id_quoter === parseInt(id_quoter));
        let id = order.id_quoter

        let data = {
            "id_quoter": id,
            "print_result": true,
            "send_email": false,
            "email_client": ""
        }

        let raw = JSON.stringify(data);

        let token = localStorage.getItem("token");
        await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentSamplingSheet", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download","Hoja de Campo-"+order.code+".pdf")
                createSweet("success", "Descargando", "Se descargo el PDF");
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }
    const handleButtonClick = (id) => {
        // Redirecciona a la ruta especificada con el idDetailMicSample
        history.push(`${process.env.PUBLIC_URL}/sanitaria/captura_hojas_trabajo_sanitaria/${id}`);
    };

    return {
        date_from, date_to, obtenerDatos, handlePickerValues, validInputDate, validToInputDate, ListSamplesWorkSheet, 
        countTotal, countUsed, navigateUpdate,handleListSampling,toggleModalViewListSampling,modalViewListSampling,code_Quoter_Temp,
        downdloadpdf,handleButtonClick
    }
}




