import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import Select from 'react-select';

export const FormAppCode = (props) => {

    const {
        validacionAnalyte, brands, machineModels, infoAnalytes, methods,
        id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids, metodosSelect,
        toggle, loading, handleInputChange, handleValueSelect, handleMethods, validaciones, methodAction, button,
    } = props;
    // brands, machineModels, infoAnalytes, methods,
    // id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids,
    // toggle, loading, handleInputChange, validaciones, methodAction: saveCreate

    // const {
    //     id_brand_valid,
    //     id_analyzer_valid,
    //     id_machine_model_valid,
    //     id_info_analyte_valid,
    //     code_valid,
    // } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Analito <span className="f-16" style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                classNamePrefix="select"
                                options={infoAnalytes}
                                name="id_info_analyte"
                                isClearable={false}
                                value={id_info_analyte}
                                placeholder={`Busqueda de analito`}
                                onChange={(e) => handleValueSelect(e, "id_info_analyte")}
                            />
                        </FormGroup>
                    </Col>
                    {
                        validacionAnalyte &&
                        <>
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Marca <span className="f-16" style={{ color: "#0079C7" }}>*</span></Label>
                                    <Select
                                        classNamePrefix="select"
                                        options={brands}
                                        name="id_brand"
                                        isClearable={false}
                                        value={id_brand}
                                        placeholder={`Busqueda de marca`}
                                        onChange={(e) => handleValueSelect(e, "id_brand")}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Analizador <span style={{ color: "#ff0000" }}>*</span></Label>
                                    <Select
                                        className="form-control form-control-plaintext form-control-sm"
                                        classNamePrefix="select"
                                        options={[{ "label": "Cobas", "value": 1 }]}
                                        name="id_analyzer"
                                        isClearable={false}
                                        value={id_analyzer}
                                        placeholder={`Busqueda de analizador`}
                                        onChange={(e) => handleValueSelect(e, "id_analyzer")}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">Módelo <span className="f-16" style={{ color: "#0079C7" }}>*</span></Label>
                                    <Select
                                        classNamePrefix="select"
                                        options={machineModels}
                                        name="id_machine_model"
                                        isClearable={false}
                                        value={id_machine_model}
                                        placeholder={`Busqueda de módelo`}
                                        onChange={(e) => handleValueSelect(e, "id_machine_model")}
                                    />
                                </FormGroup>
                            </Col>
                        </>
                    }

                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Código <span className="f-16" style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                            	className="form-control form-control-sm input-air-primary"
                                type="text"
                                onChange={handleInputChange}
                                value={code}
                                name='code'
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            {/* <Label className="col-form-label">Factor de conversión <span style={{ color: "#ff0000" }}>*</span></Label> */}
                            <Label className="col-form-label">Factor de conversión</Label>
                            <Input
                            	className="form-control form-control-sm input-air-primary"
                                type="text"
                                onChange={handleInputChange}
                                value={convertion_factor}
                                name='convertion_factor'
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className="mb-3">
                            <Label className="col-form-label">Métodos <span className="f-16" style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                defaultValue={metodosSelect}
                                isMulti
                                name="array_methods_ids"
                                onChange={(e) => handleMethods(e, "array_methods_ids")}
                                options={methods}
                                classNamePrefix="select"
                                placeholder="Selecciona una o varias opciones"
                                noOptionsMessage={() => "Sin opciones"}
                                isSearchable={true}
                                styles={
                                    {
                                        placeholder: () => ({
                                            color: "#b6bdc4"
                                        })
                                    }
                                }
                            />
                            {/* <FormFeedback className='pt-2' style={{ display: (validacionItems ? 'block' : 'none') }}>Debes seleccionar minimo un insumo</FormFeedback> */}
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <div>
                            {
                                array_methods_ids.map(element => {
                                    // return <div key={element.value} style={{ height: element.label.length * .3 }}>
                                    //     &nbsp;
                                    // </div>
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    {"Cancelar"}
                </Button>
            </ModalFooter>
        </Form>
    );
};
