import { useState, useEffect } from "react"
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from "../sweetAlerts/useSweetAlert";
import { usePaginationBackend } from "../pagination/usePaginationBackend";
import { useDebounce } from "../debounce/useDebounce";
import { useForm } from "../forms/useForm";
import { validarSesion } from "../tokens/useToken";
import { ConfigServer } from "../../data/config";
import { useModal } from "react-morphing-modal";





export const useMicrositePatients = () => {
    const id_company = localStorage.getItem('id_company');
    const userType = localStorage.getItem('userType');

    const [typeModall, setTypeModall] = useState("ViewO");
    const [modalPreview, setModalPreview] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [workMedicalOrderPDF, setworkMedicalOrderPDF] = useState("");
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam] = useSweetAlert();

    const [formFilter, handleInputChangeFilters, resetFilters, handleUpdateValuesFilter] = useForm({
        iName: "",
        iNim: "",
        iDateFrom: "",
        iDate_to: ""
    });

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues] = useForm({
        mSelectStatus: 0,
    });

    const { mSelectStatus } = formValues;

    const {
        iNim, iDateFrom, iDate_to,
    } = formFilter;

    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const [filters, setFilters] = useState(`&userType=${userType}&_id=${id_company}`);
    const [patients, SetPatients] = useState([]);
    const debounceValue = useDebounce(searchQuery, 500);
    //#region PAGINATION
    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        'Microsite/GetListPatients',
        history,
        searchQuery,
        filters
    );

    const numberItems = 10;
    const [key, setKey] = useState(1);
    const { close, modalProps, open } = useModal({
        onClose: () => setKey(key => key + 1),
        background: "#fff"
        //  background: rgba(0, 121, 199, 0.15)

    });
    //#endregion

    // #region ESTATUS
    const [statusOrder, setstatusOrder] = useState([
        {
            value: 1,
            label: "ABIERTA"
        },
        {
            value: 5,
            label: "EN PROCESO"
        },
        {
            value: 10,
            label: "AUTORIZADA"
        },
        {
            value: 20,
            label: "CANCELADA"
        }
    ]);

    //endregion

    //Descargas 
    const [startDownloadAnimation] = useState();

    //Consulta principal (pacientes)
    useEffect(() => {

        let _list = [];
        let _numberDownloads = [];
        results.forEach(_patients => {
    
            let visibleTable = true;

            _list.push({
                id_tmp: (Date.now() + _patients.id_work_order),
                nim: _patients.nim,
                name_patient: _patients.name_patient === "  " ? <div>
                    <label className='txt-secondary' style={{ fontSize: "12px", color: "txt-secondary" }}>MICROSANITARIA </label> <br />
                </div>
                    :
                    _patients.name_patient,
                fechAndDate: _patients.fechAndDate,
                id_work_order: _patients.id_work_order,
                id_estatus_order: _patients.id_estatus_order,
                name_company: _patients.name_company,
                downloads: _patients.downloads
            });
        });

        SetPatients(_list);




        let _listMicrosite = [];
        patients.forEach((data, key) => {
            let startItems = 0;
            let endItems = numberItems;

            let aux = numberItems * actualPage;

            endItems = aux;

            key = key + 1;
            let posicion = key;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }


            _listMicrosite.push({
                ...data,
                visibleTable: visibleTable
            });
        });

    }, [results]);

    useEffect(() => {
        searchAsync();
    }, [debounceValue]);

    useEffect(() => {
        if (filters !== "") {
            searchAsync();
        }
    }, [filters]);

    const handleSearch = (e) => {
        let _search = e.target.value;

        setSearchQuery(_search);
    }

    // Filtros 
    const handleFilters = () => {
        let _query = "";

        if (iNim !== "") {
            _query += `&userType=${userType}&_id=${id_company}&nim=${iNim}`;
        }

        if (iDateFrom !== "" && iDate_to !== "") {
            _query += `&userType=${userType}&_id=${id_company}&date_from=${iDateFrom}&date_to=${iDate_to}`;
        }
        if (mSelectStatus !== 0 && mSelectStatus !== null) {
            _query += `&&userType=${userType}_id=${id_company}&status_work_order=${mSelectStatus.value}`;
        }

        setFilters(_query);
    }

    const handleCleanFilter = () => {
        let _query = `&userType=${userType}&_id=${id_company}`;
        setFilters(_query);
        handleUpdateValuesFilter({
            iNim: "",
            iDateFrom: "",
            iDate_to: "",
        });
        searchAsync();
    }

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    const togglePreview = () => {
        setModalPreview(!modalPreview);
    }

    const handlePreview = (id_workOrder) => {

        setTypeModall("ViewO")
        togglePreview();
        previewWorkOrder(id_workOrder);

    }

    const previewWorkOrder = async (id_workOrder) => {
        setLoadingPdf(true)
        let token = localStorage.getItem("token");

        let order = patients.find(w => w.id_work_order === parseInt(id_workOrder));
        let id = order.nim
        
        await fetch(ConfigServer.serverUrl + `/api/PrintResult/PreviewAlt?nim=${id}&type=NP&is_micro_sanitary=${false}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
        .then(response => {
            if (response.status !== 200) return;
            return response.json();
        })
        .then(async data => {
            await fetch(`${ConfigServer.serverUrl}/api/PrintResult/GetFile/${data.raw}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                setLoadingPdf(false)
                setworkMedicalOrderPDF(url);
            });
        });
    }

    const printWorkOrder = async (id_workOrder) => {

        let order = patients.find(w => w.id_work_order === parseInt(id_workOrder));

        let id = order.nim

        let payload = {
            "nim": id,
            "password": "",
            "type": "NP",
            "is_microsite": true
        }
        let data = JSON.stringify(payload);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResults", {
            method: 'POST',
            body: data,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se ha podido descargar el pdf");
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "hoja_resultados_" + id + ".pdf");
                document.body.appendChild(link);
                link.click();
                createSweet("success", "success", "Descarga completa");
                searchAsync();
            });



    }



    return {
        patients, handleSearch, handleFilters, handleCleanFilter,

        //RETRUN FIULTERS
        iNim, iDateFrom, iDate_to, handleInputChangeFilters,

        //RETURN PAGINATION
        goNextPage, goPreviousPage, totalPages, actualPage, goToPage,
        prevPage, nextPage, totalRecords, resultCount, key,

        //Modal preview
        modalPreview, togglePreview, handlePreview, typeModall, loadingPdf, previewWorkOrder, workMedicalOrderPDF,

        //Download PDF
        printWorkOrder,

        //status
        statusOrder, handleSelectValues, mSelectStatus,

        //Descargas
        startDownloadAnimation
    }
}