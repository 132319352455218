import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { ViewListprofileCommercial } from './ViewListprofileCommercial';


export const ModalViewProfilesCommercial = (props) => {

    const {

        modal3,toggle3,modalTitle,method,listCommercial
    } = props;

    return (
        <Modal isOpen={modal3} toggle={toggle3} backdrop="static" keyboard={false} centered={true}  >
            <ModalHeader toggle={toggle3} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "list"
                ?
                    <ViewListprofileCommercial
                        { ...{toggle3, listCommercial} }
                    />
                :
                ""
            }
        </Modal>
    )
}
