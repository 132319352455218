import React from 'react'
import { ViewListprofile } from './ViewListprofile'
import { Modal, ModalHeader } from 'reactstrap';


export const ModalViewProfiles = (props) => {

    const {

        modal, toggle, modalTitle, method, listProfile
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "list"
                ?
                    <ViewListprofile
                        { ...{toggle, listProfile} }
                    />
                :
                ""
            }
        </Modal>
    )
}
