import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteTomaMuestra = (props) => {

    const { toggle3, loading, saveDelete} = props;

    return (
        <Form role="form" onSubmit={ saveDelete }>
            <ModalBody>
            <p className="h6 txt-danger">Estas a punto de eliminar el motivo de cancelación:</p>  
            {/* <b>{name+' '+code}</b>  */}
            <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar
                </Button>
                <Button color="light" type="button" onClick={toggle3}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
