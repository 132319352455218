import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, Button, Form, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Row, Col, Input, Container, FormFeedback, } from 'reactstrap';

export const ModalOrderExam = (props) => {

    const {
        toggleOrderByExam, titleOrder, modalOrder,
        data, cambiar, handleSaveOrderExamsAnalyte,method

    }=props;


    const [info, setInfo] = useState(data)

    useEffect(() => {

      setInfo(data)
      
    }, [data])
    
    
    const grid = 8;

    let layoutVersion = localStorage.getItem('layout_version');

    const getListStyle = isDraggingOver => ({
        background:  isDraggingOver ? ( layoutVersion === "dark-only" ? "#0079C7" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        padding: grid,
        width: "100%",
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `${grid * 1}px ${grid * 2}px`,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? ( layoutVersion === "dark-only" ? "linear-gradient(270deg, #0079C7 0%, #002940 85.94%)" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        color: layoutVersion === "dark-only" ? "#fff" :  "#000" ,
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        boxShadow: isDragging ? `0 0 10px` : "",
        ...draggableStyle
    });

    const onDragEnd = (result) => {
      if (!result.destination) {
          return;
      }
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;
      let list = info;
      const items = reorder(list, sourceIndex, destIndex);
      setInfo(items);
      cambiar([...items]);
  };

  const reorder = (list, startIndex, endIndex) => {
      let result = list;
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
  };

  return (
    <Modal isOpen={modalOrder} toggle={toggleOrderByExam} backdrop='static' keyboard={false} centered={true}>
         <ModalHeader toggle={toggleOrderByExam} className="pb-1 pl-2 pr-2">
         {titleOrder}  
          </ModalHeader>
          <ModalBody>
          <label className="text-gray text-sm">Arrastre y suelte los Exámenes a ordenar</label> &nbsp;
          <label className="text-gray text-sm"> no olvide</label>
          <b>  {method === 'create' ? "Crear"  : "Actualizar"}</b>&nbsp;
         {"el exámen"}
          <br />
          <Col sm={12}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" type="droppableItem" direction='vertical'>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {info.map((item, index) => (
                                            <Draggable
                                                key={"exam-" + item.id_exam}
                                                draggableId={"exam-" + item.id_exam}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <span><i className="float-right fa fa-bars"></i></span>
                                                            {item.title}
                                                        </div>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
          </ModalBody>
          <ModalFooter>
          <Button
                    onClick={() => handleSaveOrderExamsAnalyte(info)}
                    outline
                    color="primary"
                    type="submit"
                    //className={loadingOrder ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Guardar orden
          </Button>
          <Button outline color="danger" type="button" onClick={toggleOrderByExam}>Cancelar</Button>
          </ModalFooter>
    </Modal>
  )
}