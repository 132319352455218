import React from 'react';
import { Modal, Col, Row, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import 'animate.css';


export const ModalSendSamplings = (props) => {

    const {
        typeSend = "send", modalSend, openModal, closeModal, getDataNim, handleSelectValuesNim, nim, handleInputChangeNim } = props;



    return (
        <Modal isOpen={modalSend} toggle={closeModal} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeSend === "send" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Envio de Muestra"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closeModal} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} > <br /> <br />
                            <Col sm="12" >
                                <Row>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="9">
                                        <Input
                                            className="tamañoinputID text-center form-control form-control-sm input-air-primary"
                                            placeholder="Ingresa el número de la muestra"
                                            name="nim"
                                            value={nim}
                                            onChange={handleInputChangeNim}
                                        ></Input>
                                    </Col>

                                </Row>

                            </Col>
                            <br />

                            <button
                                className={"modalButton"}
                                onClick={getDataNim}
                            >
                                Ingresar
                            </button>

                        </Col> <br /> <br />
                    </>
                    : ""
            }


        </Modal >
    )
}