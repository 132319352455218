import React from 'react';
import { Col, Card, Row, Collapse, CardBody, Label,Table } from 'reactstrap';

export const PaymentsByAgreements = (props) => {
  
    const {
        toggle, isOpen, listPayments, codeAgreement, period, total
    } = props;

    ////console.log(listPayments);

    return (
        <div>
            <Card className='shadow'>
            <div className="table-responsive">
                                <Table className=" ">
                                    <thead className=" text-center">
                                        <tr>
                                            <th ><center>{"Convenio"}</center></th>
                                            <th ><center>{"Emisión"}</center></th>
                                            <th ><center>{"Vencimiento"}</center></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listPayments.length >= 1 && listPayments.map((objj, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ "vertical-align": "middle", width: "5%" }}><center>{objj.name_user}</center></td>
                                                        <td style={{ "vertical-align": "middle", width: "10%" }}><center>{objj.date}</center></td>
                                                        <td style={{ "vertical-align": "middle", width: "10%" }}><center>{objj.time}</center></td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>




               
                
                               
                           
                                {/* <Col sm="12">
                                    {
                                        listPayments.length >= 1 && listPayments.map( (o,i) => {
                                            return (
                                                <Row key={i}>
                                                    <Col sm="1" className='text-center'>
                                                        <i className="fa fa-2x fa-arrow-right text-success"></i>
                                                    </Col>
                                                    <Col sm="4" className='text-center'>
                                                        <h7 className="mb-0">{o.name_user}</h7>
                                                    </Col>
                                                    <Col sm="4" className='text-center'>
                                                        <h7><strong>{o.date}</strong></h7>
                                                        <br />
                                                        <h7>{o.time}</h7>
                                                    </Col>
                                                    <Col sm="3" className='txt-center'>
                                                        <h5 className='mb-0 text-success f-right'>$ {o.amount.toFixed(2)}</h5>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Col> */}
                                                      
                        
            </Card>
        </div>
    )    
}
