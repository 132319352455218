import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { usePagination } from '../pagination/usePagination';



export const useSampling = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [samplingItems, setSamplingItems] = useState([]);
    const [samplings, setSamplings] = useState([]);
    const [samplingId, setSamplingId] = useState(0);

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        name: "",
        code: "",
        items: []
    });

    const { name, code, items } = formValues;

    const [validaciones, setValidaciones] = useState({
        name: false,
        code: false,
        items: false,
    });

    const toggle = () => {
        setModal(!modal);
    }



    const getSamplingItems = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Sampling/ListSamplingItems");

        if (respuesta.code === 200) {
            let new_array = [];

            respuesta.data.forEach(element => {

                let obj = {
                    "value": element.id_sampling_item,
                    "label": `[${element.code}]-${element.name}`,
                    "name": `[${element.code}]-${element.name}`,
                }

                new_array.push(obj);

            });
            setSamplingItems(new_array);
        } else {
            validarSesion(history, respuesta.code, getSamplingItems);
        }

    }, [history]);

    const getSamplings = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Sampling/ListSamplings");
        if (respuesta.code === 200) {


            let startItems = 0;
            let endItems = numberItems;

            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            let newSamplings = [];
            respuesta.data.forEach(element => {
                let obj = {
                    "id_sampling": element.id_sampling,
                    "name": element.name,
                    "code": element.code,
                    // "items" : element.items
                }
                let array_items = [];
                let total = 0.0;
                element.items.forEach(item => {
                    total = total + parseFloat(item.price.replace("$", ""));
                    array_items.push([
                        false,
                        item.name,
                        item.code
                    ])
                });
                obj.items = array_items;
                obj.total = "$" + total;

                let posicion = element + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                newSamplings.push(obj, visibleTable);
            });
            setSamplings(newSamplings);
            setSampling2(newSamplings);
        }
        else {
            validarSesion(history, respuesta.code, getSamplings);
        }
    }


    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(samplings.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listSamplings = [];
        samplings.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSamplings.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSamplings(listSamplings);

    }, [totalPageCount, currentPage])







    useEffect(() => {
        getSamplings();
    }, []);

    useEffect(() => {
        getSamplingItems();
    }, [getSamplingItems])

    const saveCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }
        let newItems = formValues.items;

        newItems.map(element => {
            element.id_sampling_item = element.value;
        });

        formValues.items = newItems;


        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: raw
        }

        const respuesta = await sendRequest(requestOptions, "Sampling/Create");

        if (respuesta.code === 200) {
            getSamplings();
            reset();
            createSweet("create", "success", "Exito!", "Asignación con exito");
            setLoading(false);
            toggle();
        }
        else {
            setLoading(false);
            validarSesion(history, respuesta.code, saveCreate);
        }

    }

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: "",
            items: []
        });

        setSamplingId(0);
        setMethod("create");
        setModalTitle("Creación de paquete de toma de muestra");
        toggle();
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        if (formValues.code.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                code: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                code: false
            }
        }

        if (formValues.items.length < 1) {
            newValidaciones = {
                ...newValidaciones,
                items: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                items: false
            }
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    // const [indicationData, setIndicationData] = useState([]);
    const [sampling2, setSampling2] = useState([])

    const handleSearchSampling2 = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        sampling2.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setSamplings(newMethod)
    }




    return {
        samplings, samplingItems, validaciones,
        name, code, items,
        method, handleInputChange, handleSelectValues,
        toggle, modal, modalTitle, loading,
        saveCreate, handleCreate,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearchSampling2

    }
}
