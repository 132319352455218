import React from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { useSections } from '../../hooks/sections/useSections';
import { RibbonSections } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalSection } from '../../components/sections/ModalSection';


export const IndexSections = () => {

    const {
        sections, method, validaciones,
        name, abbreviation, enable_reception, enable_racks,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        handleModalAddProcess, processes, processesSelected, changeSelectProcesses, updateProccesInSection,
        handleSearchSection, is_imagenology, is_micro
    } = useSections();

    ////console.log(sections);

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        {RibbonSections}
                    </div> <br />
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSection} />
                        </div>
                    </div>
                    <br />
                    <div className="table-responsive">
                        <TableStriped
                            notMaped={["visibleTable", "is_imagenology", "is_micro"]}
                            methodsActions={true}
                            cabeceras={["Nombre", "Abreviación", "¿Recepción de muestra?", "¿Uso de gradillas?"]}
                            items={sections}//wilson modificar aqui
                            {...{ handleUpdate }}
                            methodsAbsolutes={
                                [
                                    {
                                        type: "linkId",
                                        method: handleModalAddProcess,
                                        icon: "fa fa-cogs",
                                        backgroundColor: "#6c6c6c",
                                        color: "#fff",
                                        name: "navigateConfigExam",//gestionarproceso
                                        tooltip: "Gestionar Proceso"
                                    }
                                ]
                            }
                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalSection
                {
                ...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, abbreviation, enable_reception, enable_racks,
                    handleInputChange, loading,
                    saveCreate, saveUpdate, saveDelete,
                    processes, processesSelected, changeSelectProcesses, updateProccesInSection, is_imagenology, is_micro
                }
                }
            />
        </>
    )
}
