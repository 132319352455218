import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';

export const useRack = ({activeTab}) => {

    const history = useHistory();
    const [racks, setRacks] = useState([]);
    const [idRack, setIdRack] = useState(0);

    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");

    const [createSweet] = useSweetAlert();
    const [loading, setLoading] = useState(false);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        code: "",
        slots: 0,
    });

    const { name, code, slots } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        code_valid: false,
        slots_valid: false
    });

    const numberItems = 5;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(racks.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listRacks = [];
        racks.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listRacks.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setRacks(listRacks);
    }, [totalPageCount, currentPage])

    const toggle = () => {
        setModal(!modal);
    }

    const getRacks = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let listRacks = [];

        const respuesta = await sendRequest(requestOptions, "Rack/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                respuesta.data.forEach((obj, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listRacks.push({
                        ...obj,
                        visibleTable: visibleTable
                    });
                });

                setRacks(listRacks);
            }
            else {
                setRacks([]);
            }
        }
        else {
            validarSesion(history, getRacks);
        }
    }

    useEffect(() => {
        if (activeTab === 2) {
            getRacks();
        }
    }, [activeTab]);
    

    useEffect(() => {
        getRacks();
    }, [history.location])

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            code: "",
            slots: 0,
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
            slots_valid: false,
        });

        setIdRack(0);
        setMethod("create");
        setModalTitle("Creación de gradilla");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let data = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "Rack");

        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se creo correctamente");

            toggle();
            getRacks();
            handleUpdateValues({
                name: "",
                code: "",
                slots: 0,
            });
        }
        else {
            setLoading(false);
            validarSesion(history, handleCreate);
        }

    }

    const handleUpdate = (id_rack) => {
        let rack = racks.find(x => x.id_rack === parseInt(id_rack));

        handleUpdateValues({
            name: rack.name,
            code: rack.code,
            slots: rack.slots,
        });

        setValidaciones({
            name_valid: false,
            code_valid: false,
            slots_valid: false,
        });

        setIdRack(id_rack);
        setMethod("update");
        setModalTitle("Actualizar gradilla");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let mValues = {
            id_rack: idRack,
            ...{ ...formValues }
        };

        let data = JSON.stringify(mValues);
        let requestOptions = {
            method: 'PUT',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "Rack/Update");

        if (respuesta.code === 200) {
            getRacks();
            createSweet("update", "info", "Exito!", "Se actualizo la gradilla");
            setLoading(false);
            toggle();
        }
        else {
            validarSesion(history, saveUpdate);
        }
    }

    const handleDelete = (id_rack) => {
        let rack = racks.find(x => x.id_rack === parseInt(id_rack));

        handleUpdateValues({
            name: rack.name,
            code: rack.code,
            slots: rack.slots,
        });

        setIdRack(id_rack);
        setMethod("delete");
        setModalTitle("Eliminar gradilla");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Rack/${idRack}`);
        if (respuesta.code === 200) {
            getRacks();
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        }
        else {
            validarSesion(history, saveDelete);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name === "" || formValues.name.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            };
        }

        if (formValues.code === "" || formValues.code.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                code_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            };
        }

        if (formValues.slots === "" || formValues.slots <= 1) {
            newValidaciones = {
                ...newValidaciones,
                slots_valid: true
            };

            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                slots_valid: false
            };
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    return {
        racks, method,
        name, code, slots, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage
    }
}
