import React, { useState } from 'react';
import { Form, ModalBody, ModalFooter, Button,Input, Label,FormFeedback,Row,Col,FormGroup} from 'reactstrap';
import {nameCompany,social,rfcLaboratories,codeCompany,telephone, state,municipality,direction,colonyy,codePostal,emailLaboratory,siteWeb} from '../../constant/index'

export const FormLaboratories = (props) => {

    const {
        
        toggle, loading,methodAction,button,handleInputChange,validaciones,
        id_state, id_municipality,code,name, business_name, rfc, phone, 
        address, colony, cp, email, web_site,states, municipalities, handleChangeState,

    } = props;

    const {
        id_state_valid,
        id_municipality_valid,
        code_valid,
        name_valid,
        business_name_valid,
        rfc_valid,
        address_valid,
        colony_valid,
        cp_valid,
        email_valid,

     } = validaciones;


    return (
        <Form className="form theme-form " role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{nameCompany}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                invalid={name_valid}
                                value={name}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{social}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input                                
                                type="text"
                                name="business_name"
                                invalid={business_name_valid}
                                value={business_name}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{rfcLaboratories}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="rfc"
                                invalid={rfc_valid}
                                value={rfc}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{codeCompany}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="code"
                                invalid={code_valid}
                                value={code}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{telephone}</Label>
                            <Input
                                type="text"
                                name="phone"
                                value={phone}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Debes ingresar al menos un número telefonico</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{emailLaboratory}</Label>
                            <Input
                                type="text"
                                name="email"
                                invalid={email_valid}
                                value={email}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>Ingresa un correo válido</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">{state}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <select value={id_state} size="sm" name="id_state"className="form-control form-control-sm input-air-primary" onChange={handleChangeState}>
                                <option value="-1">Selecciona una opción</option>
                                    {
                                    states.length > 0 && states.map((s, key) => {
                                    return <option key={key} value={s.id_state} >{s.name}</option>
                                     })
                                    }
                          </select>
                          <Input hidden invalid={id_state_valid} />
                        <FormFeedback>Debes seleccionar una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">{municipality}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <select value={id_municipality} size="sm" name="id_municipality" className="form-control form-control-sm input-air-primary"  onChange={handleInputChange}>
                                    <option value="-1">Selecciona una opción</option>
                                    {
                                    municipalities.length > 0 && municipalities.map((m, key) => {
                                    return <option key={key} value={m.id_municipality} >{m.name}</option>
                                    })
                                    }
                            </select>
                            <Input hidden invalid={id_municipality_valid} />
                          <FormFeedback>Debes seleccionar una opción</FormFeedback>                    
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{direction}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="address"
                                invalid={address_valid}
                                value={address}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 
                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{colonyy}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="colony"
                                invalid={colony_valid}
                                value={colony}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 

                            />
                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{codePostal}<span style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="cp"
                                invalid={cp_valid}
                                value={cp}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 
                            />
                            <FormFeedback>Ingresa un código postal valido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                    <FormGroup>
                            <Label className="col-form-label">{siteWeb}</Label>
                            <Input
                                type="text"
                                name="web_site"
                                value={web_site}
                                autoComplete="off"
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary" 
                            />
                            <FormFeedback>Ingresa un correo válido</FormFeedback>
                        </FormGroup>
                    </Col>    
                    </Row>
                    <Row>  
                 </Row>  
            </ModalBody>
            <ModalFooter>
                <Button
                    outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                {button}
                </Button>
                <Button outline color="danger" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
