import React, { Fragment,useRef,useState} from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Input, Label, Media, Row,Table ,Container,FormGroup,Alert} from 'reactstrap'
import { useViewCalender } from '../../hooks/serieB/useViewCalender'
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from '@fullcalendar/core/locales/es';
import './styleCalender.css';
import moment from "moment";
import "moment/locale/es";
import { ModalEvent } from './ModalEvent';
import ReactApexChart from 'react-apexcharts';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "moment/locale/es";
import { ComponentDetailPatient } from './ComponentDetailPatient';
import { ComponentDetailBranchs } from './ComponentDetailBranchs';
import { ToolTips } from '../../components/tooltips/ToolTips';
import Chart from 'react-apexcharts'


export const IndexViewCalender = () => {


        const {
          
        //*CONFIGURATION CALENDER
        weekendsVisible,

        //*VARIABLES AND REF COMPONENTS
        calendarRef,treeViewRef,
        
        //*VARIABLES GLOBALES CALENDER
        start, end, setEnd, setStart,

        //*VARIABLES CALENDER,
        loadingPreview,

        //*VARIABLES MODAL
        modalTitle, toggleModalEvent, modalPreviewInvoice,handleClose,tolerance,

        //*SAVE METHOD
        loadingSave,

        //*DETAIL VIEW CALENDER
        eventView,loadingViews,ViewsEvents,

        //*DETAIL BRANCH
        listBranchesTree,

        //*CONFIGURATION VIEWS
        dateFilter, setDateFilter,loadingProgramming,

        //* FORM CALENDER
        amount_required, handleInputChange, title, observation,

        //*INPUT DATE AND TREE
        onRenderItem,onChangeItemtree,handleChangeAmount,handleFilterDate,

        //*FUNCTION
        handleSelectDate,handleEvent, handeGeneralEvent, countSelected,

        //*POST AND GET METHODS
        saveProgrammingDiary,GetProgrammingAmount,

        //*VALIDATIONS
        validationsCreate,

        //*DETAIL INFORMATION CALENDER
        detailInformation,

        //*DATA TABLE
        handleMouseEnter,handleMouseLeave,isHovered,setHoveredColumn,hoveredColumn,    

        //*CONFIGURATIONS DETAIL
        handleViewDetails, valid_visible, handleCloseViewDetails,

        //!TOTAL AMOUNTS
        total_Programming,number_format,

        //*CONFIGURATIONS CHARTS
        sum_branches,

        //!CONFIGURATION PROGRAMING
        options1,ChartProgrammingAmount,

        //!CONFIGURATION RECOLECTED
        total_Recollected,sum_branches_recolected,

        //!CONFIGURATION GLOBAL
        options,series,classifications,

        //*EVENT EXCLUDED PATIENT 
        handleGeneralPatients,valid_loading_patient,patient_excluded,

        //*REPORTS
        exportToExcel,

        //*CONFIGURATIONS FILTER
        handleView,state_filter,loadinfProgramming,handleClosedInformations,
        handleChangeTypePerson,type_filter_person,patient_Include,patient_Excluded,total_patient,

        //*FILTER TABLE 
        filterCon,setFilterCon,filterSin,setFilterSin,totalP,

        //*TYPE BUTTONS
        handleMouseEnter3,handleMouseLeave3,isHovered3,handleMouseEnter4,handleMouseLeave4,isHovered4,

        //*CONFIGURATION PERIOD
        handleViewMonth,viewMonth,handleViewRange,viewRange,handleClosedViewRange,handleClosedViewMonth,handleViewDetailsFilter,typePeriod,chartPeriod

        } = useViewCalender();

        //*CONGIGURATION FOTER
        const totalRow2 = (
          <tr style={{ backgroundColor: 'rgb(1 12 18)', height: '20px' }} className='sticky-footer' >
            <td></td>
          <td className='labeltheadInvoice'>Programado:</td>
          <td  className='labeltheadInvoice'>${number_format(total_Programming,2)}</td>
          <td className='labeltheadInvoice'>Recaudado:</td>
          <td  className='labeltheadInvoice'>${number_format(total_Recollected,2)}</td>
      </tr> 
        );

        let _valid_Size = valid_visible === true ? 9 : 6
        let _valid_Size2 = valid_visible === true ? 3 : 6
        const [expandedRow, setExpandedRow] = useState(null);

        const handleExpandRow = (index) => {
          if (expandedRow === index) {
            setExpandedRow(null);
          } else {
            setExpandedRow(index);
          }
        };


 
    return (
        <Fragment>
            <Container fluid={true}>
                {
                    // * HEADER FILTER CALENDER
                }
                  <Col xs="12" className='p-l-0 p-r-0'  >
                    <Row>
                    {
                      //*CALENDER
                    }
                      <Col sm="12" md="12" lg="12"  className={valid_visible === true ? "d-none" : "p-l-0 p-r-0"}  >
                        <Card className="shadow" style={{height : "100px"}}>
                          <CardBody className='p-3'>
                          <Row>
                          <Col sm="6" className='pt-4'>
                            {//!TYPE RANGE
                              viewMonth && <Row>
                                <Col sm="2" >
                                  <i className="icofont icofont-filter f-28 branches-label pointer"></i>
                                  <label className='text-center txt-center'  >Periodo</label>
                                </Col>
                                <Col sm="5">
                                  {viewMonth&&<Input type='month' value={dateFilter} className='border-0 mr-2' onChange={(e) => {setDateFilter(e.target.value)}} />}
                                </Col>
                                <Col sm="1" className='pt-2 p-l-0 p-r-0'> 
                                  {viewMonth&&<Button color='info' size='xs' outline onClick={handleFilterDate}> Filtrar</Button>}
                                </Col>
                                <Col sm="1" className='pt-1'>
                                  <i id={"tooltip-comments1"} className="fa fa-ban f-28 gauge-pointer pointer" onClick={handleClosedViewMonth}  >
                                    <ToolTips
                                      dataPlacement='top'
                                      dataTarget={"tooltip-comments1"}
                                      dataText="Cancelar Filtro"
                                    />
                                  </i>
                                </Col>
                                </Row>
                            }
                            {//!TYPE RANGE
                              viewRange && <Row>
                                <Col sm="3" className='' >          
                                  {state_filter === true ? <i className="icofont icofont-filter f-28 branches-label pointer"  ></i> : <i className="icofont icofont-filter f-28 gauge-pointer"  ></i>}
                                  {state_filter && <label className='branches-label'>Rango de Fechas:</label>}
                                </Col>
                                <Col sm="5" className='p-r-0 p-l-0' style={{textAlign : "end"}} >
                                  {
                                    state_filter &&
                                    <DateRangePicker
                                      initialSettings={{
                                        locale: {
                                        format: "YYYY-MM-DD",
                                        applyLabel: "Aplicar",
                                        cancelLabel: "Cancelar"
                                        },
                                      startDate: start,
                                      endDate: end,
                                      timePicker: false,
                                      drops: 'down'
                                      }}
                                      onApply={(event, picker) => {
                                          const startDate = new Date(picker.startDate);
                                          const endDate = new Date(picker.endDate);
                                          setStart(startDate);
                                          setEnd(endDate);
                                          handleView(startDate,endDate);
                                          }}
                                      >
                                      <input className={`form-control form-control-sm input-air-primary border-primary-input`} type="text" />
                                      </DateRangePicker> 
                                  }
                                  {
                                    !state_filter && <label htmlFor="">Filtros</label>
                                  }
                                </Col>
                                <Col sm="1">
                                  <i id={"tooltip-comments"} className="fa fa-ban f-28 gauge-pointer pointer" onClick={handleClosedViewRange}  >
                                  <ToolTips
                                    dataPlacement='top'
                                    dataTarget={"tooltip-comments"}
                                    dataText="Cancelar Filtro"
                                  />
                                  </i>       
                                </Col>         
                                </Row>
                              }         
                                </Col>
                              <Col className={loadinfProgramming ? "disabled clase_desabilita" : ""} sm="6" style={{textAlign: "end"}}> <br />
                                  <button className={ isHovered ? 'buttong buttong-hovered' : 'buttong'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handeGeneralEvent}>Generar Evento Mensual</button>
                                  <button className={isHovered3 ? ' button-filter button-filter-hovered ' : 'button-filter'} onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3} onClick={handleViewMonth} >Generar Consulta Mensual</button>
                                  <button className={isHovered4 ? 'button-filter-d button-filter-d-hovered ' : 'button-filter-d'} onMouseEnter={handleMouseEnter4} onMouseLeave={handleMouseLeave4} onClick={handleViewRange} >Generar Busqueda por Rangos</button>
                              </Col>
                          </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                        <Row>
                          {
                            //!VIEW CALENDER EVENT
                          }
                      <Col sm="12" md="7" lg={_valid_Size} className={valid_visible === true ? " p-l-0" : "p-l-0"} >
                          {
                          (valid_visible === true  )?       
                          <Col sm="12"  className={valid_loading_patient === true ? "d-none" : "p-l-0 p-r-0"}  >
                            <Card className='shadow'>
                              <CardHeader className='text-center p-1 bg-success'>
                                <h6 className='pt-1'>Información General</h6>
                              </CardHeader>
                              <br /> 
                          <Col sm="12"  >
                            {
                              typePeriod !=="" &&  <Col sm="12" className='p-l-0 p-r-0' style={{textAlign : "center"}}>
                                <b className='txt-success f-16'>Periodo seleccionado:&nbsp;{typePeriod}</b></Col>  
                            }
                            {
                              typePeriod !== "" && <Col sm="12">
                                <Chart options={chartPeriod.options} series={chartPeriod.series} type="line" height={350} /></Col>
                            }
                            {
                              typePeriod === "" &&
                                  <Row className='pt-3'>
                                  <Col sm="6" className='p-r-0'> 
                                    <label className='f-w-600 f-12 badge badge-light-primary'>Saldo Programado en Sucursales</label> <b className='f-16' >${number_format(sum_branches,2) ?? 0}</b> <br /> <br /> <br />
                                    <ReactApexChart options={ChartProgrammingAmount?.options} series={ChartProgrammingAmount?.series} type='donut' height={350} />
                                  </Col>
                                  <Col sm="6" >
                                  <label className='f-w-600 f-12 badge badge-light-primary'>Saldo Recuperado en Sucursales</label> <b className='f-16'  >${number_format(sum_branches_recolected,2) ?? 0}</b>
                                    <ReactApexChart options={options1} type="radialBar" series={options1.series} height={400} />
                                  </Col>
                                </Row>
                            }    
                          </Col>
                          <br />
                            </Card>          
                          {
                            typePeriod !== "" && <Col sm="12" className='p-l-0 p-r-0'>
                            <Row className='pt-3'>
                              <Col sm="6" className='p-r-0'> 
                                <Card style={{height :"370px"}} className='shadow' >
                                  <b className='f-16 label-Filter p-1'>Saldo Programado</b> 
                                  <ReactApexChart options={ChartProgrammingAmount?.options} series={ChartProgrammingAmount?.series} type='donut' height={350} />
                                </Card>
                                  </Col>
                              <Col sm="6" >
                                <Card style={{height :"370px"}}>
                                  <b className='f-16 label-Filter p-1'>Saldo Recuperado</b>
                                  <ReactApexChart options={options1} type="radialBar" series={options1.series} height={400} />
                                  </Card>
                              </Col>   
                                </Row>
                              </Col>
                            }         
                        </Col>
                            :
                          <div className={loadinfProgramming === true ? "disabled disableLabel" : ""}>
                                  <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    nowIndicator={true}
                                    headerToolbar={{
                                      left: "prev,today,next",
                                      center: 'title',
                                      right: 'dayGridMonth,timeGridWeek,timeGridDay' 
                                    }}
                                    locale={esLocale}
                                    ref={calendarRef}
                                    timeZone={'UTC'}
                                    editable={true}
                                    selectable={true}
                                    selectMirror={true}
                                    dayMaxEvents={true}
                                    weekends={weekendsVisible}
                                  datesSet={(info) => {
                                    const startStr ="";
                                    const endStr = "";
                                    GetProgrammingAmount({ startStr, endStr });
                                  }}
                                    events={eventView}
                                    select={handleEvent}
                                    eventClick={ViewsEvents}
                                    dateClick={handleSelectDate}
                                  />
                          </div>  
                          }
                          </Col>
                          {
                            //!VIEW TABLE EVENT
                          }
                            <Col sm="12" md="5" lg={_valid_Size2} className={valid_visible === true ? "p-l-0 p-r-0" : "p-r-0"}  >
                              {
                               valid_visible === false ?
                               <Card className='shadow'  style={{height: '500px'}} >
                                 <CardHeader className='text-center p-1 '>
                                 <div className='hr-detail-patient pt-3' >  
                            <b className='pt-1'>Detallado del mes</b> <br /> <br /> 
                          </div> <br /> 
                                </CardHeader>
                               <Col className='p-r-0 p-l-0' >
                               {
                                 //*DATA TABLE
                               }
                               <div className='table-wrapper-event'>
                               <Table className='b-r-5 table table-sm table custom-table'>
                                  <thead className='b-r-5' style={{textAlign : "center"}} >
                                    <tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
                                      <th></th>
                                      <th>Fecha</th>
                                      <th>Programado</th>
                                      <th>Recuperado</th>
                                      <th>Indicadores</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{textAlign : "center"}} >
                                      {
                                        loadinfProgramming && <tr>
                                            <td colSpan={9} className=' text-center f-16'>
                                            <Col sm="12" md="12" lg="12" className='text-center pt-4'>
                                                <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'120px' }} /> <br /> <br />
                                                <label className='txt-secondary f-18'>Cargando.....</label>
                                            </Col>
                                            <br />
                                            </td>
                                        </tr>
                                       }
                                    
                                    { !loadinfProgramming &&  detailInformation?.map((_find, _k) => (

                                      <React.Fragment key={_find?.id}>
                                        <tr onMouseEnter={() => setHoveredColumn(_k)} style={{ backgroundColor: hoveredColumn === _k ? '#01273c2b' : 'inherit' }}>
                                        <td>
                                    <i className={`fa fa-${expandedRow === _k ? 'plus-square' : 'plus-square-o'} f-20 branches-label`} onClick={() => handleExpandRow(_k)}></i>
                                  </td>
                                          <td>{_find?.start_date ?? "------"}</td>
                                          <td className=' f-w-700'>${number_format(_find?.sum_branches,2) ?? 0}</td>
                                          <td className='txt-danger f-w-700'>${number_format(_find?.sum_branches_collected) ?? 0}</td>
                                          {/* <td> */}
                                          {
                                            !_find.valid ? (
                                              <td> 
                                                <i className="fa fa-eye f-20 branches-label" onClick={() => handleViewDetails(_find.id_event)}></i>
                                              </td>
                                            ) : (
                                              <td>
                                                <i className="fa fa-eye f-20 branches-label" onClick={() => handleViewDetailsFilter(_find.id_event)}></i>
                                              </td>
                                            )
                                          }

                                        </tr>
                                        {expandedRow === _k && (
                                          <tr  >
                                            <td colSpan={1}></td>
                                            <td colSpan={2} style={{textAlign : "justify"}} >
                                            <b className='branches-label'>Sucursales Activas</b>
                                              {
                                                _find.commercialBranchTreeView.map((_a) => (
                                                  _a.children.map((_x) => {
                                                    if (_x.amount === 0) {
                                                      return;
                                                    }
                                                    return(
                                                      <div>
                                                        <li className='branches-label'>{_x.text}</li>
                                                      </div>
                                                    )
                                                  })
                                                ) )  
                                              }
                                            </td>
                                            <td colSpan={2}  style={{textAlign : "justify"}} >
                                            <b className='branches-label'>Montos Activos Recuperados</b>

                                              {
                                                _find.commercialBranchTreeView.map((_a) => (
                                                  _a.children.map((_x) => {
                                                    if (_x.amount === 0) {
                                                      return;
                                                    }
                                                    return(
                                                      <div>
                                                        <li className='branches-label'>${number_format(_x?.amount_collected,2) ?? 0} de {number_format(_x?.amount,2) ?? 0} </li>
                                                      </div>
                                                    )
                                                  })
                                                ) ) 
                                              }
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    ))}
                                    {totalRow2}
                                  </tbody>
                                </Table>
                               </div>
                               </Col> <br />
                             </Card>
                             :
                             <Col sm="12" className={valid_loading_patient === true ? "d-none" : "p-l-0"}  >
                              { //*COMPONENT DETAIL BRANCHS
                                <ComponentDetailBranchs 
                                {
                                  ...{ options,series,sum_branches_recolected,listBranchesTree,handleGeneralPatients,classifications,typePeriod
                                    }
                                }
                                />
                              }
                              {
                                typePeriod !== "" && <Col sm="12">
                                  <Card style={{height : "100px"}} className='shadow' >
                                    <Col sm="12">
                                    <Row>
                                      <Col sm="4" className='pt-3'>
                                      <img style={{height : "120%", filter : "brightness(0.5)"}} src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />

                                      </Col>
                                      <Col sm="8" className='pt-3'>
                                          <label className='f-w-600 f-14 txt-secondary'>Saldo Recuperado</label>  <br /> <b className='f-16'  >${number_format(sum_branches_recolected,2) ?? 0}</b>

                                      </Col>
                                    </Row>
                                    </Col>


                                  </Card>
                                  <Card style={{height : "100px"}} className='shadow'>
                                  <Col sm="12">
                                    <Row>
                                      <Col sm="4" className='pt-3'>
                                      <img style={{height : "120%" , filter:"grayscale(1)"}} src={require("../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} />

                                      </Col>
                                      <Col sm="8" className='pt-3'>
                                          <label className='f-w-600 f-14 txt-secondary'>Saldo Programado</label>  <br /> <b className='f-16'  >${number_format(sum_branches,2) ?? 0}</b>

                                      </Col>
                                    </Row>
                                    </Col>



                                  </Card>
                                </Col>
                              }   
                              <div style={{ display: "block" }}  onClick={() => handleCloseViewDetails("sss")}  className='tap-top-Serie '>Regresar</div>
                             </Col>
                              }
                            </Col>
                        </Row>
                    </Col>
                    {
                      //*COMPONENT DETAIL INFORMATION PATIENT
                    }
                    {
                       valid_loading_patient &&
                      <ComponentDetailPatient 
                      {
                        ...{  patient_excluded,handleClosedInformations, handleChangeTypePerson,type_filter_person,
                              number_format,patient_Include,patient_Excluded,total_patient,  
                              filterCon,setFilterCon,
                              filterSin,setFilterSin,totalP,exportToExcel
                           }
                      }
                      />
                    }    
            </Container>
            <ModalEvent
                {
                ...{
                  loadingPreview,modalTitle,toggleModalEvent,modalPreviewInvoice,
                  start,end,setEnd,setStart,countSelected,
                  //*save
                  amount_required,handleInputChange,loadingSave,saveProgrammingDiary,handleClose,
                  title,observation,validationsCreate,
                  loadingViews,
                  listBranchesTree,treeViewRef ,onRenderItem,tolerance,handleChangeAmount,
                  onChangeItemtree,loadingProgramming
                }
                }
            />
        </Fragment>
    )
}
