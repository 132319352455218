import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListOrderingSubItem } from './ListOrderingSubItem';

export const ListOrderingItem = ({ itemsOrdered }) => {
    const grid = 8;

    let layoutVersion = localStorage.getItem('layout_version');

    // ////console.log(itemsOrdered);
    const getListStyle = isDraggingOver => ({
        background:  isDraggingOver ? ( layoutVersion === "dark-only" ? "#0079C7" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        padding: grid,
        width: "100%"
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `${grid * 1}px ${grid * 2}px`,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? ( layoutVersion === "dark-only" ? "linear-gradient(270deg, #0079C7 0%, #002940 85.94%)" :  "#0079c726" ) : ( layoutVersion === "dark-only" ? "#002940" : "#fff" ),
        color: layoutVersion === "dark-only" ? "#fff" :  "#000" ,
        border: layoutVersion === "dark-only" ? `1px solid #46AFE5` :  "1px solid #0079C7" ,
        boxShadow: isDragging ? `0 0 10px` : "",
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        if (result.type === "droppableItem") {
            let list = arreglo;
            const items = reorder(list, sourceIndex, destIndex);
            setArreglo(items);
        } else if (result.type === "droppableSubItem") {
            const itemSubItemMap = arreglo.reduce((acc, item) => {
                acc[item.id] = item.subItems;
                return acc;
            }, {});
            const sourceParentId = result.source.droppableId;
            const destParentId = result.destination.droppableId;

            const sourceSubItems = itemSubItemMap[sourceParentId];
            const destSubItems = itemSubItemMap[destParentId];

            let newItems = [...arreglo];
            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destIndex
                );
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.subItems = reorderedSubItems;
                    }
                    return item;
                });
                setArreglo(newItems);
            } else {
                let newSourceSubItems = [...sourceSubItems];
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                let newDestSubItems = [...destSubItems];
                newDestSubItems.splice(destIndex, 0, draggedItem);
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.subItems = newSourceSubItems;
                    } else if (item.id === destParentId) {
                        item.subItems = newDestSubItems;
                    }
                    return item;
                });
                setArreglo(newItems);
            }
        }
    }

    const [arreglo, setArreglo] = useState([]);

    useEffect(() => {
        setArreglo(itemsOrdered);
    }, []);


    const reorder = (list, startIndex, endIndex) => {
        let result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return (

        <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Droppable droppableId="droppable" type="droppableItem">
                {(provided, snapshot) => (
                    < div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {arreglo.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {item.content}
                                            <span><i className="float-right fa fa-bars"></i></span>
                                            <ListOrderingSubItem
                                                subItems={item.subItems}
                                                type={item.id}
                                            />
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
