import React, {useState} from 'react';
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { Col, Row, Table, Badge, Pagination, Button } from 'reactstrap';
import './styleCash.css';

export const ModalReportCash = (
    { close, formatter, data, branchs, totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1, nextPage = null, previousPage = null, dataPaymets,
        dataCancell, dataDiscount, dataPatient, mostrar, mDateRange1, mDateRange2, handleInputChangeCash, loading, getReport, mSelectBranch,
        handleSelectValuesCash, branches, footer, viewFormFilters, handleCloseViewTest, dataCredit, printCashClosing, totalPaymets, exportToExcel, dataMDiscount,
        handleClean,
        ...props }) => {

    //*CONFIGURATION TABLE
    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpandRow = (index) => {
        if (expandedRow === index) {
          setExpandedRow(null);
        } else {
          setExpandedRow(index);
        }
      };
    //*END CONFIGURATION TABLE


    //*CONFIGURATON FINANCE
    let _valid_Finance = localStorage.getItem('finance');
    let _finance2 = localStorage.getItem('finance_view');
    let _validationFinance = (_valid_Finance === "True" &&  _finance2 === "False") ? true : false



    return (
        <Modal {...props} padding={false} closeButton={false}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div >
                    {footer && (
                        <div
                            style={{
                                zIndex: 1
                            }}
                        >
                            {footer}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: "flex"
                    }}
                >
                    <button className="butto-full-modal" onClick={close}>
                        <label className='f-20'>X</label>
                    </button>
                </div>
                <Table >
                    <tbody>
                        <tr>
                            <td>
                                <Pagination aria-label='...' style={{ justifyContent: "left" }}>
                                    <ul className='pagination pagination-primary'>
                                        {
                                            currentPage1 === 1 ?
                                                <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item' onClick={previousPage1} ><span className='page-link'>Anterior</span></li>
                                        }
                                        {
                                            currentPage1 > 1 &&
                                            <>
                                                <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goToPage1(1)} ><span className='page-link'>1</span></li>
                                                {
                                                    currentPage1 > 2 &&
                                                    <>
                                                        <li className="page-item"><span className='page-link'>...</span></li>
                                                        <li style={{ cursor: "pointer" }} onClick={() => goToPage1(currentPage1 - 1)} className='page-item'><span className='page-link'>{currentPage1 - 1}</span></li>
                                                    </>
                                                }
                                            </>
                                        }
                                        <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage1}</span></li>
                                        {
                                            currentPage1 < totalPageCount1 &&
                                            <>
                                                {
                                                    currentPage1 < (totalPageCount1 - 1) &&
                                                    <>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(currentPage1 + 1)} className='page-item'><span className='page-link'>{currentPage1 + 1}</span></li>
                                                        <li className='page-item'><span className='page-link'>...</span></li>
                                                    </>
                                                }
                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(totalPageCount1)} className='page-item'><span className='page-link'>{totalPageCount1}</span></li>
                                            </>
                                        }
                                        {
                                            totalPageCount1 === currentPage1 ?
                                                <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item primary' onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                        }
                                    </ul>
                                </Pagination>
                            </td>
                            <td style={{ width: "88px" }}  >
                                <Button type="button" outline color="danger" size='xs' onClick={printCashClosing} className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} >
                                    <i class="icofont icofont-file-pdf text-danger"></i> PDF
                                </Button>
                            </td>
                            <td style={{ width: "88px" }}>
                                <Button type="button" size="xs" outline color="success" className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} onClick={() => exportToExcel(data)}>
                                    <i className="icofont icofont-file-excel" /> Excel
                                </Button>
                            </td>
                            <td style={{ width: "85px" }}>
                                <Button outline color="primary" onClick={handleClean} size='xs' className={(loading ? "disabled progress-bar-animated progress-bar-striped ml-1 text-center" : "text-center ml-1")} >Limpiar</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
               
                <div style={{ position: "relative" }}>
                    <Col sm="12" className={`${viewFormFilters ? 'm-t-10  p-l-0 p-r-0' : 'd-none'} `}  >
                        <Col sm="12" className='p-l-0 p-r-0' >
                            <div className="">
                                <table className="table-sm">
                                    <thead className="theadInvoice">
                                        <tr className='text-center'>
                                            <th></th>
                                            <th className='labelThead'>{_validationFinance ? "#" : "Solicitud(NIM)" } </th>
                                            <th className='labelThead'>Paciente</th>
                                            <th className='labelThead'>Compañia</th>
                                            <th className='labelThead'> Usuario / Hora</th>
                                            <th className='labelThead'>Tarjeta de Credito</th>
                                            <th className='labelThead'>Tarjeta de Debito</th>
                                            <th className='labelThead'>Efectivo</th>
                                            <th className='labelThead'>Transferencia</th>
                                            <th className='labelThead'>Credito</th>
                                            <th className='labelThead'>Total</th>
                                            <th className='labelThead'>Estatus del pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading && <tr>
                                                <td colSpan={17} className='bgTableCash text-center f-16'>
                                                    <i className="fa fa-spin fa-spinner"></i> Cargando...
                                                </td>
                                            </tr>
                                        }
                                       {
                                        !loading && data !== undefined && data?.length > 0 && data?.map((a, b) => {
                                            if (a.visibleTable) {
                                                let _valid_Color = a.amountDiscount === 0 ? "branches-label" : "labelIndicadorCashf"
                                                //!VALIDATIONS FINANCE METHODS
                                                if (_valid_Finance === "True") {
                                                    if (_finance2 === "False") {
                                                        if (a.complete_information === "True") {
                                                            return;   
                                                        }
                                                    }
                                                }
                                                return (
                                                    <React.Fragment key={a?.id}>
                                                        <tr className={a.status === "Vigente" ? "" : "parrafo"}>
                                                            <td style={{ verticalAlign: "middle", textAlign : "center"}}><i className={`fa fa-${expandedRow === b ? 'plus-square' : 'plus-square-o'} f-20 ${_valid_Color} `} onClick={() => handleExpandRow(b)}></i></td>
                                                            <td style={{ verticalAlign: "middle" }}>{_validationFinance ? <>{b + 1 }</> : <> {a?.nim ?? "--"} </>}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{a?.namePatiente ?? "--"}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{a?.type ?? "--"}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{a?.user ?? "--"}&nbsp;&nbsp;/&nbsp;&nbsp; {a.hour ?? "--"} </td>
                                                            <td style={{ verticalAlign: "middle" }}>{formatter.format(a?.creditCard ?? 0)}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{formatter.format(a?.debitCard ?? 0) }</td>
                                                            <td style={{ verticalAlign: "middle" }}>{formatter.format(a?.cash ?? 0)}</td>
                                                            <td style={{ verticalAlign: "middle" }}>{formatter.format(a?.electronicTransfer ?? 0 )}</td>
                                                            <td style={{ verticalAlign: "middle" }}>${(a?.credit.toFixed(2) ?? 0)} </td>
                                                            <td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0' >
                                                            {a.ajuste ?<div><b className={a?.ajuste === true ? "labelIndicadorCash" : "d-none"} >Saldo recuperado</b><br /></div>: ""}
                                                            {(a.status === "Vigente" ? <div> {formatter.format(a?.amount ?? 0)}</div> : "")}
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>{a?.is_fullPaid ?? "--"}</td>
                                                        </tr>
                                                        {expandedRow === b && (
                                                            <tr style={{backgroundColor : "rgb(3 61 91 / 6%)"}}>
                                                                <td colSpan={1}></td>
                                                                <td colSpan={2} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Tipo de Descuento</b> 
                                                                    <br />
                                                                    <label className='branches-label'>{a?.nameCommont ?? "--"}</label>
                                                                </td>
                                                                <td colSpan={2} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Total Aplicado</b> 
                                                                    <br />
                                                                    <label className='branches-label f-12'>{(a?.amountDiscount) === 0 ? "" : <div> ${(a?.amountDiscount ?? 0)}</div>}</label>
                                                                </td>
                                                                <td colSpan={2} style={{ textAlign: "justify" }}>
                                                                    <b className='text-danger f-12'>Código de descuento</b> 
                                                                    <br />
                                                                    <label className='branches-label f-12'>{(a?.codeD ?? "--")}</label>
                                                                </td>
                                                                <td colSpan={3} style={{ textAlign: "justify" }}>
                                                                    <b className='labelIndicadorCash f-12'>Usuario / S.R</b> 
                                                                    <br />
                                                                    <label className='branches-label f-12'>{a?.ajuste ? (a?.nameUserAdjust + " " + a?.fech_Adjust) : "" }</label>
                                                                </td>
                                                                <td colSpan={1}></td>
                                                                <td colSpan={1} style={{ textAlign: "justify" }}>
                                                                    
                                                                    <Badge size="xs" color={(a?.status === "Vigente" ? "success" : "danger")} className='rounded-pill '> {a?.status ?? ""}</Badge>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <br /> <br />
                        <Col sm="12">
                            <Row >
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table size='sm' className="table-border-vertical">
                                            <thead className="theadInvoice">
                                                <tr className='text-center'>
                                                    <th style={{ textAlign: "center" }} className='labelThead'>MÉTODO DE PAGO</th>
                                                    <th style={{ textAlign: "center" }} className='labelThead'>TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                {
                                                !loading && dataPaymets.map((obj, key) => {
                                                        if (obj.amount == 0 ) {
                                                            return;
                                                        }
                                                        return (
                                                            <tr key={key}>
                                                                <td style={{ textAlign: "center" }} >{obj.pay_method}</td>
                                                                <td style={{ textAlign: "center" }}>${(obj.amount).toFixed(2)}</td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <thead >
                                                {
                                                     !loading && <tr className='text-center'>
                                                     <th > <label className='f-w-600 f-12 badge badge-light-primary '>Total Global</label> </th>
                                                     <th > <b className='f-14' >${totalPaymets}</b></th>
                                                 </tr>

                                                }
                                           
                                            </thead>
                                        </Table>
                                    </div>
                                </Col>
                                <Col sm="4">
                                </Col>
                                <Col sm="4">
                                    <div className="table-responsive">
                                        <Table size='sm' className="table-border-vertical">
                                            <thead className="theadInvoice">
                                                <tr className='text-center'>
                                                    <th colSpan={2} className='labelThead'>DETALLE DE MOVIMIENTOS</th>
                                                </tr>
                                            </thead>
                                            <tbody>                                            
                                                <tr>
                                                    <td>CANCELACIONES</td>
                                                    {
                                                        !loading && <td>${dataCancell}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>DESCUENTOS</td>
                                                    {
                                                        !loading && <td>${(dataMDiscount).toFixed(2)}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>TOTAL DE PACIENTES</td>
                                                    {
                                                        !loading && <td>{data.length}</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </Col> <br />
                    </Col> <br />
                </div>
                <Col className={`${!viewFormFilters ? 'm-t-10 animate__animated animate__fadeIn' : 'animate__animated animate__fadeIn'}`} hidden={viewFormFilters} >
                    <br />
                    <div className="table-responsive">
                        <Table size='sm' className="table-border-vertical">
                            <thead className="theadA">
                                <tr className='text-center'>
                                    <th className='labelThead' >Solicitud(NIM)</th>
                                    <th className='labelThead' >Paciente</th>
                                    <th className='labelThead' >Fecha</th>
                                    <th className='labelThead' >Monto</th>
                                    <th className='labelThead'>Método de Pago</th>
                                    <th className='labelThead'>Estatus del Pago</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataCredit !== undefined && dataCredit.length > 0 && dataCredit.map((_find, _key) => {
                                        return (
                                            <tr key={_key}>
                                                <td style={{ textAlign: "center" }} >{_find.nim}</td>
                                                <td style={{ textAlign: "center" }}>{_find.name_patient}</td>
                                                <td style={{ textAlign: "center" }}>{_find.fech}</td>
                                                <td style={{ textAlign: "center" }}>{_find.amount_deposit}</td>
                                                <td style={{ textAlign: "center" }}>{"CREDITO"}</td>
                                                <td style={{ textAlign: "center" }}>{"PENDIENTE"}</td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </div> <br />
        </Modal >
    )
}