import React, { Fragment,ref } from 'react'
import Chart from 'react-apexcharts'
import {Col, Card,Row, CardBody,Table, Label,CardHeader,Input } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb'
import { useBalance } from '../../hooks/balanceDashboard/useBalance'
import CountUp from 'react-countup';
import { labelCobrar,labelConvenios,labelVen, labelVencido, } from '../../constant'
import { useListAgreementsPendingPay } from '../../hooks/agreements/useListAgreementsPendingPay';
import { TableStriped } from '../../components/tables/TableStriped';

//import './styleBalan.css';


export const IndexBalance = () => {

    
    const { agreementsPendingPay,totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchPanding
    } = useListAgreementsPendingPay();


     const {

        options1,serie1,cobrarGlobal,cobrarGlobal2,option, serie2,serie3, option3,

    }= useBalance();

    ////console.log(agreementsPendingPay);



    return (
        <Fragment>
            {/* <Breadcrumb parent="Saldos" title="Saldos Totales" className="m-b-0"/> */}
            <Col sm="12">
                    <Row className="m-b-15">
                      <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Card className='shadow'>
                            <CardHeader className="p-3 bg-primary">
                                <h6>Cuentas por Cobrar</h6>
                            </CardHeader>
                            <div style={{ textAlign: "-webkit-center"}}>
                                {
                                    serie1.length === 0 ?
                                    <div>
                                     <img className="bg-img" src={require("../../assets//images/loginHelenLabs/Alerts/AlertChart.svg")} style={{ width: "35%" }}  alt="" />
                                     <br /> <p className="f-16">{"No hay Registrado Ningún Saldo."}</p>
 
                                    </div>
                                    :
                                    <div> 
                                        <br />
                                        <h5>
                                        <CountUp 
                                            start={0}
                                            end={cobrarGlobal}
                                            duration={10}
                                            delay={4}
                                            separator=","
                                            decimals={2}
                                            decimal="."
                                            prefix="$"
                                        >
                                    </CountUp>
                                        </h5> <br />
                                    <Chart options={options1} series={serie1} type="bar" height={100} /> 
                                    </div>
                                }
                            </div>
                        </Card>
                    </Col>
                       <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Card className='shadow'>
                            <CardHeader className='p-3 bg-primary'>
                                <h6>{labelVen}</h6>
                            </CardHeader>
                            <div style={{ textAlign: "-webkit-center"}}>
                                {
                                    serie2.length === 0 ?
                                    <div>
                                     <img className="bg-img" src={require("../../assets//images/loginHelenLabs/Alerts/AlertChart.svg")} style={{ width: "35%" }}  alt="" />
                                     <br /> <p className="f-16">{"No hay registrado Saldos es estos últimos 6 meses."}</p>
                                    </div>
                                    :
                                    <div> <br />
                                    <h5>Últimos 6 Meses:&nbsp;
                                        <CountUp 
                                        start={0}
                                            end={cobrarGlobal2}
                                            duration={10}
                                            delay={6}//4
                                            separator="."
                                            decimals={2}
                                            decimal="."
                                            prefix="$"
                                        >
                                    </CountUp></h5> <br />
                                    <Chart options={option} series={serie2} type="bar" height={110} />
                                   </div>
                                }
                            </div>
                        </Card>
                       </Col>
                    </Row>
                    </Col>    
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="8" xl="8">
                                <Card className='shadow'>
                                    <CardHeader className='p-3 bg-primary'>
                                        <h6>{labelConvenios}</h6>
                                    </CardHeader>
                                    <div style={{ textAlign: "-webkit-center"}}>
                                        {
                                            agreementsPendingPay.length === 0 ?
                                            <div>
                                            <img className="bg-img" src={require("../../assets//images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "25%" }}  alt="" />
                                            <br /> <p className="f-16">{"No hay Registrado Ningún Saldo."}</p>
                                           </div>
                                           : 
                                            <div className="table-responsive" >
                                                <div className="row">
                                                    <div className="offset-8 col-4 pt-1 pb-2">
                                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchPanding} />
                                                    </div>
                                                </div>
                                                <TableStriped
                                                notMaped={["visibleTable","payment_name","payment_terms","payment_phone","payment_email" ]}
                                                methodsActions={false}
                                                cabeceras={[ "Cliente", "N.Contrato", "Tipo de cuenta", "Cantidad","Vencimiento"]}
                                                items={agreementsPendingPay}
                                                nextPage={nextPage}
                                                previousPage={previousPage}
                                                totalPageCount={totalPageCount}
                                                currentPage={currentPage}
                                                goToPage={goToPage}
                                            />         
                                            
                                            </div>
                                        }
                                    </div>
                                </Card>
                            </Col>

                            <Col xs="12" sm="12" md="12" lg="6" xl="4">   
                                <Card className='shadow'>
                                    <CardHeader className='p-3 bg-primary'>
                                        <h6>{labelVencido}</h6>
                                    </CardHeader>
                                    <div style={{ textAlign: "-webkit-center"}}>
                                        {
                                            serie3.length === 0 ?
                                            <div>
                                            <img className="bg-img" src={require("../../assets//images/loginHelenLabs/Alerts/AlertChart.svg")} style={{ width: "50%" }}  alt="" />
                                            <br /> <p className="f-16">{"No hay Registrado Ningún Saldo."}</p>
                                            </div>
                                            :
                                            <div>
                                                {
                                                    serie3.length >= 1 && <Chart options={option3} series={serie3} type="donut" width={390} />
                                                }    
                                            </div>
                                        }
                                    </div>         
                                </Card>
                            </Col>
                        </Row>       <br /> <br />      
        </Fragment>
  
    )
}
