import React, { Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ModalCurveTimes } from '../../../components/curvesTimes/ModalCurveTimes';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useCurvesTimes } from '../../../hooks/curvesTimes/useCurvesTimes';

export const IndexCurvesTimes = () => {
    
    const {
        curvesTimes, method,
        name, handleInputChange, validations,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage
    } = useCurvesTimes();

    return (
        <Fragment>
            <Container fluid={true}>
                <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className='p-1'>
                                <div className='ribbon ribbon-clip ribbon-primary'>
                                    Listado de tiempos
                                </div>
                                <br />
                                <div className='text-right' style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className='btn btn-pill btn-air-success' color='secondary' size='sm' onClick={handleCreate}>
                                        <span className='fa fa-plus-circle'></span>
                                    </Button>
                                </div>
                                <br />
                                <div className='table-responsive'>
                                    {
                                        curvesTimes.length === 0
                                            ?
                                                <div style={{ textAlign: '-webkit-center' }}>
                                                    <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                                                    <p className="f-18">{"No hay registros"}</p>
                                                </div>
                                            :
                                                <TableStriped
                                                    notMaped={["visibleTable"]}
                                                    methodsActions={true}
                                                    cabeceras={["Nombre"]}
                                                    items={curvesTimes}
                                                    {...{ handleUpdate, handleDelete }}
                                                    nextPage={nextPage}
                                                    previousPage={previousPage}
                                                    totalPageCount={totalPageCount}
                                                    currentPage={currentPage}
                                                    goToPage={goToPage}
                                                />
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalCurveTimes
                {
                    ...{
                        modal, modalTitle, toggle, method, loading, 
                        name, handleInputChange, validations,
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </Fragment>
    )
}
