import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody, Col, Row, Badge, Label, Tooltip } from 'reactstrap'
import List from 'list.js';
//import man from '../../assets/images/dashboard/profile.jpg'
import man from '../../assets/images/dashboard/profile.jpg'


export const ListCardSurvey = (props) => {

    const {
        filterProps = null, type = "list", inputFiter = false,  items, navigateUpdate, handleDelete, handleUpdateStatus, handleListProfile, handleList, infoData, datos, handleUpdateCategory
    } = props;

    
    const refList = useRef(null);

    useEffect(() => {
        if (items.length > 0) {
            new List(refList.current, {
                valueNames: filterProps,
                listClass: type
            });
        }
    }, [items]);


  return (
        <>
        <div ref={refList}>
            <Row>
                <Col md="9"></Col>
                <Col sm="3">
                    {
                        inputFiter ?  <input className="form-control form-control-sm search" placeholder="Buscar" /> : ''
                    }
                </Col>
            </Row> <br /> <br />
            <Row className={ type }>
                {
                    // items.length > 0 ?
                    // items.map((obj,key) =>{
                    //     return(
                            <Col xs="3" sm="12" md="3" lg="4" xl="3"   className='box' >
                                <Card className="shadow-lg p-1 shadow-showcase ribbon-wrapper" style={{ height: '200px' }}>
                                    <Col sm="12" style={{ textAlign: "-webkit-center" }}> <br /> <br /> 
                                    <img className="b-r-10" src={man} style={{ width: "20%" }} /> <br /> <br />
                                    <label className='----'>Encuesta de Satisfacción</label>
                                    </Col>
                                 
                                    <div>
                                 


                                    </div>

                                </Card>
                            </Col>

                    //     )
                    // })

                    // : ""
                }
                

            </Row>

        </div>

        </>
  )
}
