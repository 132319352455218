import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { LogIn, User, Bell, Minimize, Search, X } from 'react-feather';
import Bookmark from "../../layout/bookmark"
import { useNotification } from '../../hooks/notification/useNotification';
//import { UseSignalR } from '../../hooks/signalR/UseSignalR'
//import { UseSignalR } from '../../hooks/signalR/UseSignalR'
//import { UseSignalR } from '../../hooks/signalR/UseSignalR'
import { useSignalR } from '../../hooks/signalR/useSignalR'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import { Notification, Admin, Account, LogOut } from '../../constant'

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { useHistory } from 'react-router-dom';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();


const Rightbar = (props) => {
  const [searchresponsive, setSearchresponsive] = useState(true)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  // const [notificationDropDown, setNotificationDropDown] = useState(false)
  const history = useHistory();

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true)
    }
  }, []);


  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  //Mode Moonligth
  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  const cerrarSesion = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("empresa")

    setTimeout(() => {
      history.replace(`${process.env.PUBLIC_URL}/micrositio`);
    }, 500);
  }

  const [nameUser, setNameUser] = useState("User");
  const [roleUser, setRoleUser] = useState("Normal")

 
  useEffect(() => {
    localStorage.getItem("nameUser") !== null && setNameUser(localStorage.getItem("nameUser").toUpperCase());
    localStorage.getItem("companyName") !== null && setRoleUser(localStorage.getItem("companyName").toUpperCase());
  }, []);

//datos del micrositio del paciente que es mostrar los datos
  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
        <li>
            <div className="mode text-white" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              {/* <img className="b-r-10" src={man} alt="" />  modificar profile-media img de 70px    */ }
              <img className="b-r-10  "
             src={require("../../assets/images/loginHelenLabs/1.svg")} 
              style={{ height: "30%" }}
               alt="" />

              <div className="media-body"><span>{nameUser}</span>
                <p className="mb-0 font-roboto">{roleUser} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li><User /><span>{Account} </span></li>
              <li onClick={cerrarSesion}><LogIn /><span>{LogOut}</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}
export default translate(Rightbar);