import { useState, useEffect } from "react"
import React from 'react'
import { useHistory } from "react-router";
import { sendRequest } from "../requests/useRequest"
import { useSweetAlert } from "../../hooks/sweetAlerts/useSweetAlert";
import { validarSesion } from '../../hooks/tokens/useToken';


export const useBalance = () => {

    const [createSweet] = useSweetAlert();

    const history = useHistory();

    const [serie1, setSerie1] = useState([]);
    const [cobrarGlobal, setCobrarGlobal] = useState([]);

    const [serie2, setSerie2] = useState([]);
    const [option, setOption] = useState({});
    const [cobrarGlobal2, setCobrarGlobal2] = useState([]);

    const [serie3, setSerie3] = useState([]);
    const [option3, setOption3] = useState({});
    const [cobrarGlobal3, setCobrarGlobal3] = useState([]);


    useEffect(() => {

        GetExpiredWallet();
        GetMonthlySales();
        GetPieExpiredClient();

    }, [history.location])



    const GetExpiredWallet = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/GetExpiredWallet");

        if (respuesta.code === 200) {

            let seriesC = respuesta.data.series;

            seriesC.forEach((obj, index) => {

                let data = obj.data;

                data.forEach((d, indice) => {

                    data[indice] = number_format(((d / respuesta.data.totalGlobal) * 100), 2);

                });

            });

            setSerie1(seriesC);
            setCobrarGlobal(respuesta.data.totalGlobal);

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetExpiredWallet);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Cuentas por cobrar", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Cuentas por cobrar", respuesta.data.msg);
        }
    }

    const options1 = {
        chart: {
            type: 'bar',
            height: 150,
            stacked: true,
            toolbar: {
                show: false
            },
            width: '100%',
            stackType: '100%'
        },
        colors: ['#00A878', '#FFFB46', '#f75e25', '#B80C09'],
        plotOptions: {
            bar: {
                horizontal: true,
                vertical: false,
                dataLabels: {
                    position: 'bottom'
                  },
            },
        },
        grid: {
            borderColor: ''
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: [" "],
            labels: {
                show: false
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " % "
                }
            }
        },
    };

    //Grahp sales 

    const GetMonthlySales = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/GetMonthlySales");

        if (respuesta.code === 200) {

            setOption({
                chart: {
                    height: 180,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                },
                yaxis: {
                    show: false,

                },

                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: ''
                },
                fill: {
                    colors:[function ({ dataPointIndex }) {
                        let colores = (["#848484"])
                        return colores[(dataPointIndex + 3) % colores.length];
                    }],

                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 0.1,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 0.6,
                        stops: [0, 100]
                    }
                },
                

                xaxis: {
                    categories: respuesta.data.categories

                },

                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " $ "
                        }
                    }
                },
            }
            );

            setSerie2(respuesta.data.series)
            setCobrarGlobal2(respuesta.data.salesGlobal)
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetMonthlySales);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Saldos", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Saldos", respuesta.data.msg);
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!", LostConnection);
        //     validarSesion(history, respuesta.code, GetMonthlySales);
        // }
    };

    const GetPieExpiredClient = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/GetPieExpiredClient");

        if (respuesta.code === 200) {

            setOption3({
                chart: {
                    width: 380,
                    type: 'donut',
                    dropShadow: {
                        enabled: true,
                        color: '#111',
                        top: -1,
                        left: 3,
                        blur: 3,
                        opacity: 0.2
                    }
                },
                legend: {
                    show: false,
                },
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    label: 'Total',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return "$ " + number_format((a + b), 2)
                                        }, 0)
                                    }
                                },
                            }
                        },
                    }
                },
                labels: respuesta.data.labels,
                dataLabels: {
                    dropShadow: {
                        blur: 3,
                        opacity: 0.8,
                    },
                    position: 'top'
                },

                fill: {
                    opacity: 1,
                    pattern: {
                        enabled: true,
                    },
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5
                },

                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "Adeudo:$ " + number_format((val), 2)
                        }
                    },
                },

                states: {
                    hover: {
                        filter: 'none'
                    }
                },
                theme: {
                    palette: 'palette2'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            })

            setSerie3(respuesta.data.series)
            setCobrarGlobal3(respuesta.data.totalGlobal)

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, GetPieExpiredClient);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Saldo vencido", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Saldo vencido", respuesta.data.msg);
        }
    };


    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }


    return {

        serie1, options1, cobrarGlobal, cobrarGlobal2, option, serie2, serie3, option3, cobrarGlobal3

    }
}