import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormCost } from './FormCost';

export const ModalCost = (props) => {

    const {

        method,modal,modalTitle,loading,toggle,idExam,arrayExam,handleEditPrice, cost,price,handleInputChange,
        saveCreateCost,producction,handleEditPrice2,handleMargin


        
    }= props;

    return (
        <Modal isOpen={modal} toggle={toggle}  backdrop='static' keyboard={false} centered={true} >
        <ModalHeader toggle={toggle}  className="bg-primary">
            {modalTitle}
        </ModalHeader>
        {
            method === "list"
                ? <FormCost
                    button="Guardar"
                    {...{method,modal,modalTitle,loading,toggle,idExam,arrayExam,handleEditPrice, cost,price,handleInputChange,
                        saveCreateCost,producction,handleEditPrice2,handleMargin}}
                />
                : (method === "update")
            
        }
    </Modal>
       
    )
}
