import React from "react";
import { Modal, ModalHeader, Col, Row } from "reactstrap";
//import { FormIndicationExam} from './FormIDcard'
import { FormIDcard } from "./FormIDcard";
import "../../../assets/css/pantalla1.css";
import { height } from "@mui/system";

export const ModalIDcard = (props) => {
  const {
    method,
    modalTitle,
    closeModal,
    modal,
    loading,
    getDataNim,
    data,
    handleInputChangeNim,
    nim,
  } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={closeModal}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <div
        className="colorModalIDmuestra"
        style={{ "text-align-last": "center" }}
      >
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <Row>
            <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
            <Col xs="8" sm="8" md="8" lg="8" xl="8" className="p-2">
              <label className="labelModal p-1">
                {" "}
                &nbsp;{"Código de Barras"}
              </label>
            </Col>
            <Col xs="2" sm="2" md="2" lg="2" xl="2" className="p-3">
              <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={closeModal} />
            </Col>
          </Row>
        </Col>
        <br />
      </div>

      {method === "list" ? (
        <FormIDcard
          button="Guardar"
          {...{ closeModal, loading, getDataNim, data, handleInputChangeNim, nim }}
        />
      ) : (
        method === "update"
      )}
    </Modal>
  );
};
