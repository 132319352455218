import React, { Fragment, useState } from 'react'
import Breadcrumb from '../../layout/breadcrumb';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table, CardHeader, Collapse, Badge, FormText, FormFeedback } from 'reactstrap';
import { useInvoiceClient } from '../../hooks/invoice/useInvoiceClient';
import { SearchAsync } from '../../components/elements/SearchAsync';
import './StyleInvoiceClient.css';



export const IndexInvoiceClient = () => {

    const {
        patient, id_patient, cdfi, id_use_cfdi, id_work_order, rfc,
        handleInputChange, dateNims, loading, examProfile, email, phone,
        handleChangeExams, removeExamProfile,
        handleChangePatient, number_format,
        //operaciones
        total, discount, priceIVA, priceTotal,
        //list 
        commercialLine, id_commercial_line, branch, id_branch, handleChangeCommercial, saveCreate,
        //validation
        validationsInvoice, checkPrint, checkSend,
        name_business_name,handleSelectValues


    } = useInvoiceClient();



    const {

        name_business_nameValid,
        rfc_valid,
        email_valid,
        phone_valid,
        id_commercial_line_valid,
        id_branch_valid,
        id_use_cfdi_valid,

    } = validationsInvoice;



    const [filterDate, setFilterDate] = useState(true);

    ////console.log(dateNims);



    return (
        <Fragment>
            {/* <Breadcrumb parent="Facturación" title="Clientes" /> */}
            <Container fluid={true}>
                <Col sm="12">
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            <Card className='p-2  ' style={{ height: "330px" }}>
                                <div>
                                    <Table style={{ "fontSize": "11px" }} className='' >
                                        <thead>
                                            <tr className='arcoiris'>
                                                <th className='txt-light' colSpan={2}>  <i className="icofont icofont-user-male f-16 mr-2"></i>  {"Datos Fiscales "}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-bottom-secondary">
                                                <td> Nombre/Razón Social<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td > <FormGroup className='m-0'>
                                                    <Input
                                                        type='text'
                                                        name="name_business_name"
                                                        value={name_business_name}
                                                        invalid={name_business_nameValid}
                                                        onChange={handleInputChange}
                                                        className="form-control form-control-sm input-air-primary"
                                                    />
                                                    <FormFeedback>Nombre Invalido</FormFeedback>
                                                </FormGroup>
                                                </td>
                                                {/* <td> Dirección</td>    */}
                                            </tr>
                                            <tr className="border-bottom-secondary">
                                                <td>RFC<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td><FormGroup className='m-0'>
                                                    <Input type='text'
                                                        name="rfc"
                                                        value={rfc}
                                                        invalid={rfc_valid}
                                                        onChange={handleInputChange}
                                                        className="form-control form-control-sm input-air-primary"
                                                    />
                                                    <FormFeedback>Longitud incorrecta</FormFeedback>
                                                </FormGroup>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom-secondary">
                                                <td>Télefono<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td><FormGroup className='m-0'>
                                                    <Input
                                                        type='text'
                                                        name="phone"
                                                        value={phone}
                                                        invalid={phone_valid}
                                                        onChange={handleInputChange}
                                                        className="form-control form-control-sm input-air-primary"
                                                    />
                                                    <FormFeedback>Número invalido</FormFeedback>
                                                </FormGroup>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Correo<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td><FormGroup className='m-0'>
                                                    <Input
                                                        type='email'
                                                        name="email"
                                                        value={email}
                                                        invalid={email_valid}
                                                        onChange={handleInputChange}
                                                        className="form-control form-control-sm input-air-primary"
                                                    />
                                                    <FormFeedback>Correo Inválido</FormFeedback>
                                                </FormGroup>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="0">
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                            <Card className='p-2  form theme-form' style={{ height: "330px" }}>
                                <div>
                                    <Table style={{ "fontSize": "11px" }}>
                                        <thead>
                                            <tr className='arcoiris'>
                                                <th className='txt-light' colSpan={2}> <i className="icofont icofont-paper f-16 mr-2"></i> {"Datos Generales"} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-bottom-secondary">
                                                <td >Nombre o Razón Social<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td> <FormGroup className='m-0'>
                                                    <Input
                                                        className=" input-air-primary"
                                                        type='select'
                                                        placeholder="Selecciona una opción"
                                                        name="id_commercial_line"
                                                        onChange={handleChangeCommercial}
                                                        invalid={id_commercial_line_valid}
                                                        Value={id_commercial_line} >

                                                        <option value={3}>{"Selecciona una opción"}</option>
                                                        {
                                                            commercialLine.length >= 1 && commercialLine.map((obj, index) => {
                                                                return <option value={obj.id_commercial_line}>{obj.long_name}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <FormFeedback>selecciona una razon Social</FormFeedback>
                                                </FormGroup></td>
                                            </tr>
                                            <tr className="border-bottom-secondary">
                                                <td>Sucursal<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td> <FormGroup className='m-0'>
                                                    <Input
                                                        className="form-control form-control-sm input-air-primary"
                                                        type='select'
                                                        placeholder="Selecciona una opción"
                                                        name="id_branch"
                                                        onChange={handleInputChange}
                                                        invalid={id_branch_valid}
                                                        Value={id_branch} >
                                                        <option value={0}>{"Selecciona una opción"}</option>
                                                        {
                                                            branch.length >= 1 && branch.map((obj, index) => {
                                                                return <option value={obj.id_branch}>{obj.name}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <FormFeedback>selecciona una sucursal</FormFeedback>
                                                </FormGroup>
                                                </td>
                                            </tr>
                                            <tr className="border-bottom-secondary">
                                                <td>Uso del CFDI<span className='f-16' style={{ color: "#0079C7" }}>*</span></td>
                                                <td> <FormGroup className='m-0'>
                                                    <Input
                                                        type='select'
                                                        placeholder="Buscar cdfi"
                                                        name="id_use_cfdi"
                                                        onChange={handleInputChange}
                                                        invalid={id_use_cfdi_valid}
                                                        value={id_use_cfdi}
                                                        className="form-control form-control-sm input-air-primary"  >
                                                        <option value={0}>{"Selecciona una opción"}</option>
                                                        {
                                                            cdfi.length >= 1 && cdfi.map((obj, index) => {
                                                                return <option value={obj.id_use_cfdi}>{obj.description}</option>
                                                            })
                                                        }
                                                    </Input>
                                                    <FormFeedback>selecciona un CFDI</FormFeedback>
                                                </FormGroup>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>Método de envio</td>
                                                <td>

                                                    <Row>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "end" }}>
                                                            <Label >Imprimir </Label> <br />
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3"  >
                                                            <FormGroup>
                                                                <Label className="switch">
                                                                    <Input type="checkbox" name="checkPrint" onChange={handleInputChange} checked={checkPrint} />
                                                                    <span className="slider round"></span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "end" }}>
                                                            <Label>Enviar </Label> <br />
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            <FormGroup>
                                                                <Label className="switch">
                                                                    <Input type="checkbox" name="checkSend" onChange={handleInputChange} checked={checkSend} />
                                                                    <span className="slider round"></span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Row>
                    <Col className="default-according style-1 faq-accordion job-accordion position-relative" id="accordionoc" xs="12" sm="12" md="12" lg="43" xl="3">
                        <Row className="position-sticky" style={{ top: 140 }}>
                            <Col xl="12">
                                <Card>
                                    <CardHeader className=' bg-primary '>
                                        <h5 className="mb-0">
                                            <Button color="link pl-0" data-toggle="collapse" onClick={() => setFilterDate(!filterDate)}
                                                data-target="#collapseicon" aria-expanded={filterDate} aria-controls="collapseicon" ><i className="fa fa-user"></i> &nbsp; &nbsp; &nbsp; &nbsp;Busqueda por Paciente</Button>
                                        </h5>
                                    </CardHeader>
                                    <Collapse isOpen={filterDate}>
                                        <CardBody className="p-3">
                                            <Row>
                                                <Label className="col-12">Ingresa el Paciente a buscar</Label>
                                                <FormGroup className="col-md-12">
                                                    <SearchAsync
                                                        cacheOptions={false}
                                                        value={id_patient}
                                                        name="id_patient"
                                                        method={handleChangePatient}
                                                        loincState={patient}
                                                        url="Patient/SearchPatient"
                                                        maxLenght={3}
                                                        placeholder="Búsqueda por Nombre o CURP"
                                                        className="form-control-sm input-air-primary"
                                                    />
                                                </FormGroup>
                                                <Label className="col-12">NIM</Label>
                                                <FormGroup className="col-md-12  ">
                                                    <Input className="form-control form-control-sm input-air-primary" type='select' placeholder="Buscar cdfi" name="id_work_order" onChange={handleChangeExams} defaultValue={id_work_order} >
                                                        <option value={0}>{"Selecciona un NIM"}</option>
                                                        {
                                                            dateNims.length >= 1 && dateNims.map((obj, index) => {
                                                                return <option value={obj.id_work_order}>{obj.nim}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            </Row>
                                        </CardBody>
                                    </Collapse>
                                </Card>
                            </Col>

                        </Row>

                    </Col>
                    <Col xs="12" sm="12" md="12" lg="9" xl="9">
                        
                        <Card className="p-2" >
                            <div className="ribbon ribbon-clip ribbon-primary">{"Concepto"}</div>
                            <br />
                            <br />
                            <Row>
                                <Col sm="12">
                                    <div className="table-responsive ">
                                        <Table className="table-xs">
                                            <thead className="text-center">
                                                <tr className='border-bottom-info' >
                                                    <th >{"Cantidad"}</th>
                                                    <th>{"Nombre"}</th>
                                                    <th >{"NIM"}</th>
                                                    <th >{"Exámen/Perfil"}</th>
                                                    <th>{"Precios"}</th>
                                                    <th >{"Accciones"}</th>
                                                </tr>
                                            </thead>
                                            <tBody>
                                                {
                                                    examProfile.length >= 1 && examProfile.map((obj, index) => {
                                                        return (
                                                            <tr key={index}  className='border-bottom-info'>
                                                                <td style={{ "vertical-align": "middle" }}><center>1</center></td>
                                                                <td style={{ "vertical-align": "middle" }}><center>{obj.name}</center></td>
                                                                <td style={{ "vertical-align": "middle" }}> <center> {obj.nim}</center></td>
                                                                <td style={{ "vertical-align": "middle" }}> <center>
                                                                    {
                                                                        obj.test.length >= 1 && obj.test.map((o, i) => {
                                                                            return (
                                                                                <Row key={i}>
                                                                                    <Col className=' p-1'>
                                                                                        -{o.name_test}
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        })
                                                                    }
                                                                </center>
                                                                </td>
                                                                <td style={{ "vertical-align": "middle" }} > <center>
                                                                    {
                                                                        obj.test.length >= 1 && obj.test.map((o, i) => {
                                                                            return (
                                                                                <Row key={i}>
                                                                                    <Col className='p-1'>
                                                                                        $ {number_format((o.price / 1.16), 2)}
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        })
                                                                    }
                                                                </center>

                                                                </td>
                                                                <td style={{ "vertical-align": "middle" }}>
                                                                    <center>
                                                                        <Button outline color="danger" className="btn btn-xs" onClick={() => removeExamProfile(index)}  ><i className="icofont icofont-bin"></i></Button>
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tBody>
                                        </Table>
                                    </div>
                                </Col>
                                <br /> <br /> <br />
                            </Row>
                            <br /> <br /> <br />
                            <Row>
                                <Col xs="8" sm="8" md="8" lg="4" xl="4" className='offset-md-8'>
                                    <Card>
                                        <Badge className="f-12" color="primary ">Resúmen</Badge>
                                        <Row>
                                            <Col md="12">
                                                <div className="table-responsive">
                                                    <Table className="table table-xs">
                                                        <thead className='border-bottom-info'>
                                                            <tr className='border-bottom-info' >
                                                                <td className='border-bottom-info'>Subtotal</td>
                                                                <td className="text-right">$ {number_format(total, 2)}</td>
                                                            </tr>
                                                            <tr className='border-bottom-info'>
                                                                <td>Descuento</td>
                                                                <td className="text-right text-danger" >$ {number_format(discount, 2)}</td>
                                                            </tr>
                                                            <tr className='border-bottom-info'>
                                                                <td>IVA&nbsp;(.16%)</td>
                                                                <td className="text-right">$ {number_format(priceIVA, 2)}</td>
                                                            </tr>
                                                            <tr className='border-bottom-info'>
                                                                <td><b>Total</b></td>
                                                                <td className="text-right"><b>$ {number_format(priceTotal, 2)}</b></td>
                                                            </tr>
                                                        </thead>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card className='col-sm-4'>
                                        {
                                            examProfile.length >= 1 &&
                                            <Button
                                                outline color="primary " size="xs"
                                                onClick={() => saveCreate()}
                                            >
                                                {"Facturar"}
                                            </Button>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>

    )
}
