import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormIndicationExam} from './FormIndicationExam'

export const ModalIndicationExam = (props) => {

    const {

        toggle2, modal2,modalTitle, loading,method,listIndications,indication,handleChangeIndication,
        id_exam_indication,indicationn,listIndication,selectIndication,removeExamProfile,CreateOrUpdateExamIndications,
        handleSelectValuesIndication,dataIndication

        
    }= props;

    return (
        <Modal isOpen={modal2} toggle={toggle2}  backdrop='static' keyboard={false} centered={true} className= "modal-lg" >
        <ModalHeader toggle={toggle2}  className="bg-primary">
            {modalTitle}
        </ModalHeader>
        {
            method === "list"
                ? <FormIndicationExam
                    button="Guardar"
                    {...{ toggle2, loading,listIndications,indication,handleChangeIndication,id_exam_indication,
                        indicationn,listIndication,selectIndication,removeExamProfile,CreateOrUpdateExamIndications,
                        handleSelectValuesIndication,dataIndication}}
                />
                : (method === "update")
            
        }
    </Modal>
       
    )
}
