import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { validarSesion } from '../../../hooks/tokens/useToken';
import {Col,Row,Card,Table} from 'reactstrap';
import Select from 'react-select';
import Chart from 'react-apexcharts'
import { sendRequest} from '../../../hooks/requests/useRequest'
import { ComponentCollapseTransaction } from './ComponentCollapseTransaction';
import { ComponentCollapseRecordTransaction} from './ComponentCollapseRecordTransaction'
import './styleMicrositio.css';




export const ComponentInformationAgreement = ({obj}) => {
    const { history } = useHistory();
    const [info, setInfo] = useState(obj);


    useEffect(() => {
        setInfo(obj);
    }, [])

    const [valuePeriod, setValuePeriod] = useState(obj.selected)
    const [optionsPeriod, setOptionsPeriod] = useState(obj.options)
    const id_agreement = obj.id_agreement;

    const handleValuePeriod = ({ label, value }) => {

        setValuePeriod({
            value: value,
            label: label
        });
        getData(value);
    }

    const getData = async (value) => {
       
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'

        };

        const respuesta = await sendRequest(requestOptions, "CompanyWebSite/UpdateFromPeriod/" + id_agreement + "/" + value);
        if (respuesta.code === 200) {
            let element = respuesta.data;

            let percentage = 0;
            if (element.id_financing_methods === 3) {
                percentage = (element.used * 100 / element.limit).toFixed(2);
            } else {
                if (element.limit === 0) {
                    percentage = 100;
                } else {
                    percentage = (element.used * 100 / element.limit).toFixed(2);
                }
            }

            element.options = element.periods;

            element.selected = {
                value: element.number_period,
                label: "Periodo " + element.number_period
            }

            element.graph = {
                series: [percentage],
                options: {
                    chart: {
                        height: 200,
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 15,
                                size: "70%"
                            },

                            dataLabels: {
                                showOn: "always",
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: true
                                }
                            }
                        }
                    },
                    fill: {
                                type: 'gradient',
                                gradient: {
                               shade: 'dark',
                               type: 'horizontal',
                               shadeIntensity: 0.5,
                               gradientToColors: ['#848484'], //#ABE5A1 ----->modifique este color anterior
                               inverseColors: true,
                               opacityFrom: 1,
                               opacityTo: 1,
                               stops: [0, 100]
                                }
                            },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Gastado'],
                }
            }

            element.used = element.used.toFixed(2)
            setInfo(element);
          
        } else {
            validarSesion(history, respuesta.code, getData);
        }
    }
////console.log(id_agreement);
  
  return (
    <>
    {
        info === null ?
            <Col md="12">
                <h6 className="sub-title mb-0 text-center">{"Loader 3"}</h6>
                <div className="loader-box">
                    <div className="loader-3"></div>
                </div> 
            </Col>
            :
            <Col sm="12" className='p-1 '>
               
                <Col xs="12" style={{textAlign: "-webkit-left"}} className ="p-2">
                    <Row>
                        <Col sm="6" style={{textAlign: "right"}} className="p-r-0 p-l-0">
                            <label className='labelContract'>Número de contrato:</label>
                        </Col>
                        <Col sm="6" className=''>
                            <label className='txt-primary labelContractNumber'>{info.contract_number}</label>
                        </Col>
                    </Row>
                </Col> 

                <Col sm="12" className='p-1'>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4" className=" left_side_earning ">
                        <Card  className='p-2 shadow'>
                        <Row>
                        <Col xs="3" sm="3" md="4" lg="4" xl="4" className='p-2' style={{textAlign : "-webkit-right"}}>
                        <img className="imgFinancing" src={require("../../../assets/images/loginHelenLabs/Agreement/CardAvaible.svg")}/>
                       </Col>
                        <Col xs="9" sm="9" md="8" lg="8" xl="8" className='p-2' style={{"text-align-last": "start"}}>
                        {
                            info.id_financing_methods === 3 ?
                            <h6 className='text-secondary'><center>Crédito disponible</center> </h6> :
                            <h6 className='text-secondary'> <center>Saldo disponible </center> </h6>
                        }
                        <center><h4 className='text-success'>$ {(info.limit - info.used).toFixed(2)}</h4></center>
                        </Col>
                        </Row>
                        </Card>
                    </Col>

                    <Col xs="12" sm="12" md="12" lg="4" xl="4" className=" left_side_earning ">
                        <Card  className='p-2 shadow'>
                        <Row>
                        <Col xs="3" sm="3" md="4" lg="4" xl="4" className='p-2' style={{textAlign : "-webkit-right"}}>
                        <img className="imgFinancing" src={require("../../../assets/images/loginHelenLabs/Agreement/CardUsed.svg")} />                 
                       </Col>
                        <Col xs="9" sm="9" md="8" lg="8" xl="8" className=' p-2' style={{"text-align-last": "start"}}>
                        {
                                 info.id_financing_methods === 3 ?
                                 <h6 className='text-secondary'><center>Crédito usado</center> </h6> :
                                 <h6 className='text-secondary'> <center>Saldo usado </center> </h6>
                        }
                       <center> <h4 className='text-danger'>$ {info.used}</h4></center> 
                        </Col>
                        </Row>
                        </Card>
                    </Col>

                    <Col xs="12" sm="12" md="12" lg="4" xl="4" className=" left_side_earning ">
                        <Card className='p-2 shadow'>
                        <Row>
                        <Col xs="3" sm="3" md="4" lg="4" xl="4" className='p-2' style={{textAlign: "-webkit-right"}}>
                        <img className="imgFinancing2" src={require("../../../assets/images/loginHelenLabs/Agreement/CardInitial.svg")}  />
                       </Col>
                        <Col xs="9" sm="9" md="8" lg="8" xl="8" className='p-2' style={{textAlignLast : "start"}}>
                        {
                                 info.id_financing_methods === 3 ?
                                 <h6 className='text-secondary'><center>Crédito inicial</center> </h6> :
                                 <h6 className='text-secondary'> <center>Saldo inicial </center> </h6>
                        }
                        <center> <h4 className='text-muted'>$ {(info.limit).toFixed(2)}</h4></center>      
                        </Col>
                        </Row>
                        </Card>
                    </Col>
                </Row>
                </Col>

                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Row>
                        <Col sm="4" className='p-l-0 p-r-0'>
                        {/* style={{height:"440px"}} */}
                            <Card >
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="text-center">
                           
                            <div id="chart-widget5">
                            <Chart options={info.graph.options} series={info.graph.series} height="300" type="radialBar" />
                            </div>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <hr />
                            </Col> 
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{textAlign: "-webkit-left"}} className="p-1">
                            <i className="fa fa-credit-card f-20 icon-Color"></i> &nbsp;
                            <b className=''>Tipo de convenio:</b> <label className='labelMethodCredit'> {
                                info.id_financing_methods === 3 ?
                                    "CRÉDITO" :
                                    "SALDO"
                            }
                            </label>
                            </Col> 
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{textAlign: "-webkit-left"}} className ="p-1">
                            <i className= "icon-money f-22 icon-Color"></i> &nbsp;
                            
                           <b className=''>Método de pago:</b> <br /> <label className='labelMethodCredit'>  {info.name_pay_method}
                            </label>
                            </Col> 
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{textAlign: "-webkit-left"}} className="p-1">
                            <i className="fa fa-calendar f-20 icon-Color">  </i> &nbsp;
                            
                            <b className=''>Válido hasta: </b> <label className='labelMethodCredit'> {info.validity}
                            </label>
                            </Col>
                           </Col>

                            </Card>
                        </Col>

                        <Col sm="8" className='p-l-0 p-r-0'>

                        <Col sm="12">
                            <Row>
                                <Col sm="6">
                                </Col>
                                <Col sm="6">
                                <Select
                                    classNamePrefix="select"
                                    name="id_exam_include"
                                    options={optionsPeriod}
                                    value={valuePeriod}
                                    placeholder={`Selección de examenes`}
                                    isClearable={false}
                                    onChange={(e) => handleValuePeriod(e)}
                                />
                                </Col>
                            </Row>
                        
                     </Col>
                     <br /> <br />
                            <Col sm="12" >
                              <ComponentCollapseTransaction
                               items={info.listTransactions}
                              />
                            </Col>

                            <Col sm="12">
                             <ComponentCollapseRecordTransaction
                              items={info.listPaymentPeriods}
                             />
                            </Col>
                        </Col>
                        <hr />
                    </Row>
                </Col> <hr />
                <br /> <br />
            </Col>
    }
</>
    
  )
}
