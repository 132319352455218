import React from 'react';
import { Modal, ModalBody, Col, Row, Button } from 'reactstrap';
import { TimeLineGlobal } from './TimeLineGlobal';



export const ModalPreviewGlobalSearch = (props) => {

    const {
        toggleS, typeModalS = "Search", modalS, dataHistoric, formatter
    } = props;



    return (
        <Modal isOpen={modalS} toggle={toggleS} backdrop='static' keyboard={false} centered={true} size='xl' scrollable={true} >
            <ModalBody className="border-3 b-primary "  >
                {
                    typeModalS === "Search" ?
                        <div>
                            <b className='f-16 txt-secondary'>Historial de Cierres Globales:</b>
                            <hr />
                            <Col sm="12">
                                {
                                    dataHistoric.listHistoricGlobal === null || dataHistoric.listHistoricGlobal === undefined ?
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                            <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No ha registrado un cierre global hasta el momento.</p>
                                        </div>
                                        :
                                        <TimeLineGlobal
                                            items={dataHistoric}
                                            formatter={formatter}
                                        />

                                }



                            </Col>
                            <Col sm="12" style={{ textAlign: 'right' }} >
                                <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggleS}>Cerrar</Button>
                            </Col>
                        </div>
                        :
                        ""
                }
            </ModalBody>
        </Modal>
    )
}

