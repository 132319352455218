import React from 'react';
import { Modal, ModalHeader, Media, ModalBody, ModalFooter, Col, Button, Row, Form } from 'reactstrap';
import Select from 'react-select';
//MULTISELECT

import { MultiSelect } from 'primereact/multiselect';
//gato




export const ModalViewSection = (props) => {

    const { modalTitleSection, modalSection, toggleSection, typeModal = "typeSection", selected, onChange, data, saveUpdateSections, loading,
        listSection, listSect, handleSelectValues, sectionSelected
    } = props;
////console.log("Sections Total:",listSection);
    ////console.log("Sections Select:",listSect);





    return (
        <Modal isOpen={modalSection} toggle={toggleSection} backdrop='static' keyboard={false} centered={true} className="modal-lg" zIndex={10} >
            <ModalHeader toggle={toggleSection} className="bg-primary">
                {modalTitleSection}
            </ModalHeader>
            <Form></Form>
            {
                typeModal === "typeSection" ?
                    <Form className="form" role="form" onSubmit={saveUpdateSections}>
                        <ModalBody>
                            {
                                <div>
                                    <label htmlFor="">Secciones/Áreas</label>
                                    <Col sm="12">
                                        <Select
                                            isMulti
                                            name="listSection"
                                            value={listSection}
                                            onChange={(e) => handleSelectValues(e, "listSection")}
                                            options={listSect}
                                            className="basic-multi-select form-control form-control-plaintext"
                                            classNamePrefix="select"
                                            placeholder="Selecciona una o varias opciones"
                                            noOptionsMessage={() => "Sin opciones"}
                                            isSearchable={true}
                                            styles={
                                                {
                                                    placeholder: () => ({
                                                        color: "#b6bdc4"
                                                    })
                                                }
                                            }
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                  // Realizar alguna acción si se recibe un dato nulo
                                                  ////console.log("¡Se recibió un dato nulo!");
                                                }
                                            }}
                                        />
                                    </Col>
                                    <br /> <br /> <br /> <br /> <br /> <br /> <br />  <br /> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br /> <br />

                                    {/* <Col sm="12">

                                        <MultiSelect
                                            className="basic-multi-select input-air-primary form-control-sm "
                                            value={sectionSelected}
                                            options={listSect}
                                            onChange={(e) => handleSelectValues(e, "listSection")}
                                            optionLabel="name"
                                            placeholder="Selecciona una sección o área"
                                            maxSelectedLabels={3}
                                            selectedItemsLabel="Secciones Agregadas"
                                            style={{ zIndex: 1080, position: "relative" }}
                                        />
                                    </Col> */}

                                </div>

                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button size='sm' outline color='primary' type='submit' className={loading ? 'disabled progress-bar-animated progress-bar-striped' : ''} disabled={loading} >
                                Actualizar Secciones/Áreas
                            </Button>
                            <Button size="sm" className='btn-air-light' outline color='danger' type='button' onClick={toggleSection}>
                                Cancelar
                            </Button>
                        </ModalFooter>


                    </Form>


                    : ""


            }
        </Modal>
    )
}