import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useLisLot = () => {

    const [catlotessanitary, setCatLotesSanitary] = useState([]);
    const [id_lote_sanitary, setId_Lote_Sanitary] = useState(0);
    const [dataDataCatLotesSanitarySearch, setDataCatLotesSanitarySearch] = useState([]);

  
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle,setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [formValues,handleInputChange, reset, handleUpdateValues,] = useForm({
        descripcion:"",
        n_lote:"",
    });
    const [validaciones, setValidaciones] = useState({
        descripcion_valid:false,
    })
    const {descripcion,n_lote } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(catlotessanitary.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listcatlotessanitary = [];
        catlotessanitary.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listcatlotessanitary.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setCatLotesSanitary(listcatlotessanitary);
    }, [totalPageCount, currentPage])

    const toggle = ()=>{
        setModal(!modal);
    }
   
    useEffect(()=>{
        getSample();
    },[history.location]);


    const getSample = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CatLotesSanitary/List");

        if (respuesta.code === 200) {
            let listcatlotessanitary= [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data,index) => {
                    
                let posicion = index + 1;
                let visibleTable = false;
                
                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                listcatlotessanitary.push({
                        id_lote_sanitary: data.id_lote_sanitary,
                        descripcion: data.descripcion,
                        n_lote: data.n_lote
                    });
                });
                setCatLotesSanitary(listcatlotessanitary);
                setDataCatLotesSanitarySearch(listcatlotessanitary);
            }
        }
    }

    
    const handleCreate = () => {
        handleUpdateValues({
            descripcion: "",
            n_lote: "",
        });

        setValidaciones({
            descripcion_valid: false,
        });

        setId_Lote_Sanitary(0);
        setMethod("create");
        setModalTitle("Crear un nuevo Lote de Medio");
        toggle();
    }   

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "CatLotesSanitary/Create");
        if (respuesta.code === 200) {
            getSample();
            createSweet("create", "success", "Éxito!", "Lote de Medios creada con éxito");
            setLoading(false);
            toggle();
        }
    }

    const handleUpdate = (id_lote_sanitary) => {
        let catlotessanitarytemp = catlotessanitary.find(s => s.id_lote_sanitary === id_lote_sanitary);

        handleUpdateValues({
            descripcion: catlotessanitarytemp.descripcion,
            n_lote: catlotessanitarytemp.n_lote,
        });

        setValidaciones({
            descripcion_valid: false,
        });

        setId_Lote_Sanitary(id_lote_sanitary);
        setMethod("update");
        setModalTitle("Modificación de Lote de Médio");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_lote_sanitary: id_lote_sanitary,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "CatLotesSanitary/Update");

        if (respuesta.code === 200) {
            getSample();
            createSweet("create", "info", "Exito!", "Lote de Medio actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_lote_sanitary) => {
        
        let catlotessanitarytemp = catlotessanitary.find(s => s.id_lote_sanitary === id_lote_sanitary);

        handleUpdateValues({
            descripcion: catlotessanitarytemp.descripcion,
            n_lote: catlotessanitarytemp.n_lote,
        });

        setId_Lote_Sanitary(id_lote_sanitary);
        setMethod("delete");
        setModalTitle("eliminar");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `CatLotesSanitary/${id_lote_sanitary}`);

        if (respuesta.code === 200) {
            getSample();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Lote de Medio eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
       

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.descripcion.length < 3) {
            newValidations = {
                ...newValidations,
                descripcion_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                descripcion_valid: false
            }
        }
        setValidaciones(newValidations);

        return statusValidation;
    }

    const handleSearchSample = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataDataCatLotesSanitarySearch.forEach(element => {            

            let cadena = element.descripcion.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setCatLotesSanitary(newMethod);
    }

    return {
        catlotessanitary, descripcion,n_lote, validaciones,
        method, 
        handleInputChange, handleSearchSample, loading, 
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }

}




