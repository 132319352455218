import React from 'react'
import { Modal, ModalHeader, Row, Col } from 'reactstrap';
import '../../../assets/css/pantalla1.css'
import { FormEnd } from './FormEnd';


export const ModalEnd = (props) => {

  const {
    method, modalTitle, toggle, modal, loading, navigateInicio, nameModal, getEncuesta, surnames, modalPrueba, modalPrueba2, loadingPatient
  } = props;


  return (
    <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >

      <div
        className="colorModal-End-muestra"
        style={{ "text-align-last": "center" }}
      >
        <Col sm="12">
          <Row>
            <Col sm="2"></Col>
            <Col sm="8" className="p-2">
              <label className="labelModal-end p-1">
                {" "}
                &nbsp;{"Gracias"}
              </label>
            </Col>
            <Col sm="2" className="p-3">
              <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={toggle} />

              {/* <i
                className="icofont icofont-error  iconModal-End pointer "
                onClick={toggle}
              ></i> */}
            </Col>
          </Row>
        </Col>
        <br />
      </div>

      {
        method === "listEnd"
          ? <FormEnd
            button="Guardar"
            {...{ toggle, loading, navigateInicio, nameModal, getEncuesta, surnames, modalPrueba, modalPrueba2, loadingPatient }}
          />
          :
          ""
      }
    </Modal>
  )
}
