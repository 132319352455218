import React from 'react'
import { useRooms } from '../../hooks/areas/useRooms'
import { Button, Card, CardBody } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalRoom } from '../../components/rooms/ModalRoom';

export const IndexRooms = () => {

    const {
        rooms, method, validaciones, departaments,
        name, code,
        handleInputChange, loading, handleSelectValues,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, id_departament,
    } = useRooms();

    //////console.log(id_departament);
    //////console.log(rooms);

    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de cuartos
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    {
                        rooms.length === 0 ?
                            <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se a creado ningún cuarto aún.</p>
                            </div>
                            :
                            <div className="table-responsive">
                                <TableStriped
                                    notMaped={["visibleTable", "id_area", "id_departament"]}
                                    methodsActions={true}
                                    cabeceras={["Departamento", "Nombre", "Código", ,]}
                                    items={rooms}
                                    {...{ handleUpdate, handleDelete }}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />
                            </div>
                    }

                </CardBody>
            </Card>
            <ModalRoom
                {...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, code, departaments, id_departament,
                    handleInputChange, loading, handleSelectValues,
                    saveCreate, saveUpdate, saveDelete,
                }}
            />
        </>
    )
}
