import {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';

export const useInputImagenology = ({dataExam, imagenologyData, saveFileExamImagenology, sectionProfileId }) => {
    
    const history = useHistory();

    //GLOBAL VARIABLES
    const id_exam = dataExam.id_exam;
    const id_work_order_exam = dataExam.id_work_order_exam;
    const id_section = dataExam.id_section;
    const id_section_profile = sectionProfileId;

    //ALERTS MESSAGE
    const [createSweet] = useSweetAlert();

    //#region CAPTURE RESULTS
        //#region CAPTURE RESULTS MODEL
        const [inputState, setInputState] = useState("");
        const [objeto, setObjeto] = useState(null);
        const [userValidate, setUserValidate] = useState(null);
        const [users, setUsers] = useState([]);
        //#endregion

        //#region USE EFFECTS CAPTURE RESULTS
        useEffect(() => {

            if (imagenologyData.usersCapture !== null) {
                setUsers([...imagenologyData.usersCapture]);
            }
            else{
                setUsers([]);
            }
            
            if (imagenologyData.userValidate !== null) {
                setUserValidate(imagenologyData.userValidate);
            }

        }, []);

        useEffect(() => {
            if (imagenologyData.usersCapture !== null) {
                
                setUsers([...imagenologyData.usersCapture]);
            }
            else{ 
                setUsers([]);
            }

        }, [imagenologyData.usersCapture]);
        
        useEffect(() => {
            
            if (imagenologyData.userValidate !== null) {
                setUserValidate(imagenologyData.userValidate);
            }
            else {
                setUserValidate(null);
            }

        }, [imagenologyData.userValidate]);
        
        
        //#endregion

        //#region CAPTURE RESULTS METHODS
        const changeInput = (e) => {
            let value = e.target.files[0];
    
            if (value.type !== "application/pdf") {
                createSweet("error", "error", "Imagenologia", "Selecciona unicamente archivos PDF");
            }
    
            setInputState(value);

            let objeto = {};

            objeto = {
                "id_exam": id_exam,
                "id_work_order_exam": id_work_order_exam,
                "valor": value,
                "id_section": id_section,
                "sectionProfileId": id_section_profile
            }

            setObjeto(objeto);
            return;
        }

        const saveFile = async () => {
            
            await saveFileExamImagenology(objeto);
        }
        //#endregion

    //#endregion

    return {
        inputState, userValidate, users,
        changeInput, saveFile
    }
}
