import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteProcess } from './DeleteProcess';
import { FormProcess } from './FormProcess';

export const ModalProcess = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, code,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {

                method === "create"
                    ? <FormProcess
                        button='Crear proceso'
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormProcess
                            button='Actualizar proceso'
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, code }} />
                        :
                        <DeleteProcess {...{ toggle, loading, name, code, saveDelete }} />
            }
        </Modal>
    )
}
