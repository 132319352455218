import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate,  } from '../../constant';
import { DeleteMicSample } from './DeleteMicSample';
import { FormMicSample } from './FormMicSample';

export const ModalMicSample = (props) => {

    const{
        modal, modalTitle, toggle, method, loading,
        name, handleInputChange,
        validaciones, saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={ toggle } className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                ? 
                    <FormMicSample
                        button={ModalButtonSave}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                :
                (method === "update")
                ?
                    <FormMicSample
                        button={ModalButtonUpdate}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, }}
                    />
                :
                <DeleteMicSample
                    {...{ toggle, loading, name, saveDelete }} 
                />
            }
        </Modal>
    )
}
