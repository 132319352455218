import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { sendRequest } from '../requests/useRequest';
import { usePagination } from '../pagination/usePagination';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';

export const useAppCodes = () => {

    const history = useHistory();

    const [appCodes, setAppCodes] = useState([]);
    const [appCodesData, setAppCodesData] = useState([]);
    const [appCodeId, setAppCodeId] = useState(0);
    const [brandId, setBrandId] = useState(0);

    const [brands, setBrands] = useState([]);
    const [machineModels, setMachineModels] = useState([]);
    const [analytes, setAnalytes] = useState([]);
    const [infoAnalytes, setInfoAnalytes] = useState([]);
    const [methods, setMethods] = useState([]);
    const [metodosSelect, setMetodosSelect] = useState([]);

    const [validacionAnalyte, setValidacionAnalyte] = useState(true);

    const [method, setMethod] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [loadingAppCodes, setLoadingAppCodes] = useState(false);

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(appCodes.length, numberItems);

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_brand: { "value": 0, "label": "" },
        id_analyzer: null,
        id_machine_model: null,
        id_info_analyte: null,
        code: "",
        convertion_factor: "",
        array_methods_ids: []
    });

    const { id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids } = formValues;

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {
        if (id_brand !== null) {
            setBrandId(parseInt(id_brand.value))
        }
    }, [id_brand]);

    useEffect(() => {
        getMachineModels();
    }, [brandId]);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;
        endItems = aux;

        startItems = endItems - numberItems;
        let listAppcode = [];

        appCodes.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAppcode.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAppCodes(listAppcode);

    }, [totalPageCount, currentPage]);


    const getAppCodes = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        setLoadingAppCodes(true);

        const respuesta = await sendRequest(requestOptions, "AppCode/ListAnalytes");

        if (respuesta.code === 200) {

            let newAppCodes = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;
            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length > 0) {
                respuesta.data.forEach((obj, index) => {
                    let methods_arrays = [];
                    let name_methods;

                    if (obj.methods.length > 0) {
                        name_methods = [];
                        obj.methods.forEach((method) => {
                            name_methods.push([
                                false,
                                method.name,
                            ])
                            methods_arrays.push(method.id_method);
                        });
                    } else {
                        name_methods = "Sin métodos asignados";
                    }

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    newAppCodes.push({
                        id_app_code: obj.id_app_code,
                        id_brand: obj.id_brand,
                        brand_name: obj.brand_name,
                        id_analyzer: obj.id_analyzer,
                        // analyzer_name: obj.analyzer_name,
                        id_machine_model: obj.id_machine_model,
                        machine_model_name: obj.machine_model_name,
                        id_info_analyte: obj.id_info_analyte,
                        info_analyte_name: obj.info_analyte_name,
                        code: obj.code,
                        convertion_factor: obj.convertion_factor === "" ? "N/A" : obj.convertion_factor,
                        methods_arrays: methods_arrays,
                        name_methods: name_methods,
                        visibleTable: visibleTable
                    });
                });

                setAppCodes(newAppCodes);
                setAppCodesData(newAppCodes);
                setLoadingAppCodes(false);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAppCodes);
            setLoadingAppCodes(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un error en el servidor");
            setLoadingAppCodes(false);
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
            setLoadingAppCodes(false)
        }
    }, [history]);

    const getBrands = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Brand/List");

        if (respuesta.code === 200) {
            let array_aux = [];
            respuesta.data.forEach(element => {
                array_aux.push({
                    "label": element.name,
                    "value": element.id_brand,
                })
            });
            ////console.log(array_aux);
            setBrands(array_aux);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBrands);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
        }
    }, [history]);

    const getMachineModels = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel/List");

        if (respuesta.code === 200) {
            let array_aux = [];
            respuesta.data.forEach(element => {
                if (element.id_brand === brandId) {
                    array_aux.push({
                        "label": element.model,
                        "value": element.id_machine_model,
                    })
                }
            });
            setMachineModels(array_aux);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMachineModels);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Nuevo convenio", "Ocurrio un error en el servidor");

        }
        else {
            createSweet("error", "warning", "Nuevo convenio", respuesta.data.msg);
        }
    };

    const getInfoAnalytes = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "InfoAnalyte/ListAnalytes");
        console.log("🚀 ~ getInfoAnalytes ~ respuesta:", respuesta)

        if (respuesta.code === 200) {
            // setAppCodes(respuesta.data);
            let array_aux = [];
            setAnalytes(respuesta.data);
            respuesta.data.forEach(element => {
                array_aux.push({
                    "label": element.code + "[" + element.name + "]",
                    "value": element.id_info_analyte,
                })
            });
            setInfoAnalytes(array_aux);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getInfoAnalytes);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
        }
    }, [history]);

    const getMethods = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Method/List");

        if (respuesta.code === 200) {
            // setAppCodes(respuesta.data);
            let array_aux = [];
            respuesta.data.forEach(element => {
                array_aux.push({
                    "label": element.name + "[" + element.code + "]",
                    "value": element.id_method,
                })
            });
            setMethods(array_aux);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getMethods);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {

        getAppCodes();
        getBrands();
        getInfoAnalytes();
        getMethods();

    }, [getAppCodes]);


    // no eliminar el efecto para que se cargue todo en el diseño de examen :(
    useEffect(() => {
        getInfoAnalytes();
        getMethods();
    }, [history.location])


    const handleCreate = () => {
        handleUpdateValues({
            id_brand: null,
            id_analyzer: { "label": "Cobas", "value": 1 },
            id_machine_model: null,
            id_info_analyte: null,
            code: "",
            convertion_factor: "",
            array_methods_ids: []
        });

        setAppCodeId(0);
        setMethod("create");
        setModalTitle("Nuevo app code");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        let metodos = [];

        array_methods_ids.forEach(element => {
            let nuevo = {
                "id_method": element.value,
                "name": element.label,
                "code": element.label,
            };

            metodos.push(nuevo);
        });

        let formData = {};

        if (validacionAnalyte) {
            formData = {
                id_brand: id_brand.value,
                id_analyzer: id_analyzer.value,
                id_machine_model: id_machine_model.value,
                id_info_analyte: id_info_analyte.value,
                code: code,
                convertion_factor: convertion_factor,
                methods: metodos
            };
        }
        else {
            formData = {
                id_brand: null,
                id_analyzer: null,
                id_machine_model: null,
                id_info_analyte: id_info_analyte.value,
                code: code,
                convertion_factor: convertion_factor,
                methods: metodos
            };
        }

        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "AppCode");
       
        if (respuesta.code === 200) {
            getAppCodes();
            reset();
            createSweet("create", "success", "Exito!", "Creación con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
            setLoading(false)
        }
    }

    const handleUpdate = (id_app_code) => {
        setAppCodeId(id_app_code);

        let app_code = appCodes.find(x => x.id_app_code == parseInt(id_app_code));

        let id_brand = app_code.id_brand != null ? brands.find(x => x.value === app_code.id_brand) : 0;
        let idMachine = app_code.id_machine_model !== null ? { "label": app_code.machine_model_name, "value": app_code.id_machine_model } : 0;

        checkTypeAnalyte(app_code.id_info_analyte);

        handleUpdateValues({
            id_brand: id_brand,
            id_analyzer: { "label": "Cobas", "value": 1 },
            id_info_analyte: infoAnalytes.find(x => x.value === app_code.id_info_analyte),
            id_machine_model: idMachine,
            code: app_code.code,
            convertion_factor: app_code.convertion_factor !== null ? app_code.convertion_factor : "",
            array_methods_ids: app_code.methods_arrays
        });

        let auxarray = [];

        app_code.methods_arrays.forEach(element => {
            let obj = {
                "label": methods.find(x => x.value === element).label,
                "value": element
            };

            auxarray.push(obj);
        });

        setMetodosSelect(auxarray);

        setMethod("update");
        setModalTitle("Actualizar app code");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let metodos = [];

        array_methods_ids.forEach(element => {
            // ////console.log(element.value, "askdn");
            let value_aux = element.value === undefined ? element : element.value;
            let nuevo = {
                "id_method": value_aux,
                "name": methods.find(x => x.value === value_aux).label,
                "code": methods.find(x => x.value === value_aux).label,
            }
            metodos.push(nuevo);
        });

        let formData = {
            id_app_code: appCodeId,
            id_brand: (id_brand.value === undefined ? null : id_brand.value),
            id_analyzer: (id_analyzer.value === undefined ? null : id_analyzer.value),
            id_machine_model: (id_machine_model.value === undefined ? null : id_machine_model.value),
            id_info_analyte: (id_info_analyte.value === undefined ? null : id_info_analyte.value),
            code: code,
            convertion_factor: convertion_factor,
            methods: metodos
        }

        // ////console.log(formData);
        // return;
        let raw = JSON.stringify(formData);

        let requestOptions = {
            method: 'PUT',
            body: raw
        }

        const respuesta = await sendRequest(requestOptions, "AppCode/Update");

        if (respuesta.code === 200) {
            getAppCodes();
            reset();
            createSweet("create", "success", "Exito!", "Actualización con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Vinculación de analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Vinculación de analitos", respuesta.data.msg);
            setLoading(false)
        }
    }

    const handleValueSelect = ({ label, value }, input) => {
        if (input === "id_info_analyte") {
            checkTypeAnalyte(value);
        }

        if (input !== "array_methods_ids") {
            handleUpdateValues({
                ...formValues,
                [input]: { "label": label, "value": value }
            });
        }
    }

    const handleMethods = (e) => {
        handleUpdateValues({
            ...formValues,
            "array_methods_ids": e
        });
    }

    const checkTypeAnalyte = (idAnalyte) => {
        let analito = analytes.find(x => x.id_info_analyte === idAnalyte);

        if (analito) {

            setValidacionAnalyte(analito.is_interfaced)
        }
    }

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        let newAppCodes = [];

        appCodesData.forEach(element => {
            let cadena = element.info_analyte_name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newAppCodes.push(element);
            }
        });

        setAppCodes(newAppCodes);
    }
     const handleDelete = (id_app_code) => {
        
            setAppCodeId(id_app_code);
            setMethod("delete");
            toggle();
           
    }

    const saveDelete = async (e) => {
        
        e.preventDefault();
    
        setLoading(true);
    
        try {
            // Realiza la eliminación del doctor
            const deleteResponse = await sendRequest({ method: 'DELETE' }, `AppCode/${appCodeId}`, "AppCodes");
            
            if (deleteResponse.code === 200) {
                getAppCodes();
                reset();
                createSweet("create", "success", "Exito!", "Creación con exito");
                setLoading(false);
                toggle();
            }
            else if (deleteResponse.code === 401) {
                validarSesion(history, deleteResponse.code, saveCreate);
                setLoading(false);
            }
            else if (deleteResponse.code === 409) {
                createSweet("error", "warning", "Vinculación de analitos", deleteResponse.data.msg);
                setLoading(false);
            }
            else if (deleteResponse.code === 500) {
                createSweet("error", "error", "Vinculación de analitos", "Ocurrio un problema en el servidor");
                setLoading(false);
            }
            else {
                createSweet("error", "warning", "Vinculación de analitos", deleteResponse.data.msg);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false);
            createSweet("error", "error", "Vinculación de analitos", "Ocurrió un error inesperado");
        }
    };
    

    return {
        appCodes, brands, machineModels, infoAnalytes, methods, validacionAnalyte,
        id_brand, id_analyzer, id_machine_model, id_info_analyte, code, convertion_factor, array_methods_ids,
        modal, modalTitle, toggle, method,
        handleInputChange, handleValueSelect, handleMethods, loading, metodosSelect,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        loadingAppCodes,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearch,handleDelete,saveDelete
    }
}
