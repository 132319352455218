import React from 'react'
import { Form, ModalBody,Button,Col,Label,FormGroup,Table,Row} from 'reactstrap';
import { SearchAsync } from '../../components/elements/SearchAsync';
import Select from 'react-select';


export const FormIndicationExamMicrobiology = (props) => {

    const {

    loading,listIndication,CreateOrUpdateExamIndications,handleSelectValuesIndication,dataIndication

    }= props;



    return (
        <Form className="theme-form" role="form" onSubmit={CreateOrUpdateExamIndications} >
                        <Col sm="12 p-1">
                            <FormGroup className="col-md-12">
                            <Select
                            isMulti
                            name="listIndication"
                            value={listIndication}
                            onChange={(e) => handleSelectValuesIndication(e, "listIndication")}
                            options={dataIndication}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />
                        </FormGroup>
                        </Col>  <br /> <br /> <br /> <br /> 
                        <Col sm="12" className={ listIndication.length === 0 ? "d-none" : "p-l-0"}>
                            <Row>
                                <Col sm="1" className='p-r-0 p-l-0'>
                                </Col>
                                <Col sm="10" className='p-l-0'>
                                <Label className="col-12">Listado de Indicaciones</Label>
                                {
                                    listIndication.length >=1 && listIndication.map((obj,index) => {
                                        return(
                                            <div key={index} className="align-items-center row pb-2 pt-2 border-top border-right border-left border-bottom">
                                            <Col sm="12">
                                            <label className="f-14 text-secondary"><i className="icofont icofont-prescription f-22 "></i>{obj.label}</label>
                                            </Col>
                                        </div>
                                        )
                                    })
                                } 
                                </Col>
                                <Col sm="1">
                                </Col>
                            </Row>
                        </Col>
                        <ModalBody  style={{"text-align": "-webkit-right"}}>
                        <Button
                                outline
                                color="primary"
                                type="submit"
                                className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                        >
                            {"Guardar"}
                        </Button> &nbsp;
                        </ModalBody>
                        </Form>
            
    )
}
