import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useMicSample = () => {

    const [samplesMic, setSampleMic] = useState([]);
    const [sampleId, setSampleId] = useState(0);
    const [dataSampleSearch, setDataSampleSearch] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle,setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [formValues,handleInputChange, reset, handleUpdateValues,] = useForm({
        name:"",
    });
    const [validaciones, setValidaciones] = useState({
        name_valid:false,
    })
    const {name, } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(samplesMic.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listSamples = [];
        samplesMic.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSamples.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSampleMic(listSamples);
    }, [totalPageCount, currentPage])

    const toggle = ()=>{
        setModal(!modal);
    }
   
    useEffect(()=>{
        getSample();
    },[history.location]);


    const getSample = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SampleMic/List");

        if (respuesta.code === 200) {
            let listSamples= [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data,index) => {
                    
                let posicion = index + 1;
                let visibleTable = false;
                
                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                listSamples.push({
                        id_mic_sample: data.id_mic_sample,
                        name: data.name,
                        visibleTable: visibleTable
                    });
                });
                setSampleMic(listSamples);
                setDataSampleSearch(listSamples);
            }
        }
    }

    
    const handleCreate = () => {
        handleUpdateValues({
            name: "",
        });

        setValidaciones({
            name_valid: false,
        });

        setSampleId(0);
        setMethod("create");
        setModalTitle("Crear nueva muestra");
        toggle();
    }   

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SampleMic");
        if (respuesta.code === 200) {
            getSample();
            createSweet("create", "success", "Éxito!", "Muestra creada con éxito");
            setLoading(false);
            toggle();
        }
    }

    const handleUpdate = (id_mic_sample) => {
        let sample = samplesMic.find(s => s.id_mic_sample === id_mic_sample);

        handleUpdateValues({
            name: sample.name,
        });

        setValidaciones({
            name_valid: false,
        });

        setSampleId(id_mic_sample);
        setMethod("update");
        setModalTitle("Modificación de muestra");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_mic_sample: sampleId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SampleMic/Update");

        if (respuesta.code === 200) {
            getSample();
            createSweet("create", "info", "Exito!", "Muestra actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_mic_sample) => {
        
        let sample = samplesMic.find(s => s.id_mic_sample === id_mic_sample);

        handleUpdateValues({
            name: sample.name,
        });

        setSampleId(id_mic_sample);
        setMethod("delete");
        setModalTitle("eliminar");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `SampleMic/${sampleId}`);

        if (respuesta.code === 200) {
            getSample();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Muestra eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
       

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }
        setValidaciones(newValidations);

        return statusValidation;
    }

    const handleSearchSample = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataSampleSearch.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setSampleMic(newMethod);
    }

    return {
        samplesMic, name, validaciones,
        method, 
        handleInputChange, handleSearchSample, loading, 
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }

}




