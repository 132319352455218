import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteCulture } from './DeleteCulture';
import { FormCulture } from './FormCulture';

export const ModalCulture = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        culture, bacteriums, bacteriumsSelected, onChangeBacterium,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className='modal-lg'>
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormCulture
                        button={"Crear bacteria"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, culture, bacteriums, bacteriumsSelected, onChangeBacterium, }}
                    />
                    : (method === "update")
                        ?
                        <FormCulture
                            button={"Actualizar bacteria"}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, culture, bacteriums, bacteriumsSelected, onChangeBacterium, }} />
                        :
                        <DeleteCulture {...{ toggle, loading, culture, saveDelete }} />
            }
        </Modal>
    )
}
