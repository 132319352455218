import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LostConnection } from '../../constant';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';


export const useLogin = () => {

    const history = useHistory();
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();

    // const [createSweet] = useSweetAlert();
    const [branches, setBranches] = useState([]);
    const [departamento, setDepartamento] = useState([]);
    const [sections, setSections] = useState([]);
    const [process, setProcess] = useState([]);
    const [time, changeTime] = useState(new Date().toLocaleTimeString());
    //MODAL CARD ID LOG IN FLEBOTOMISTA
    const [methodCardID, setMethodCardID] = useState("");
    const [modalCardID, setModalCardID] = useState(false);
    const [modalTitleCardID, setModalTitleCardID] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataCancellation, setDataCancellation] = useState([])

    //[formValues, handleInputChange, reset,handleUpdateValues, handleSelectValues
    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_area: "",
        id_departament: "",
        id_branch: "",
        userName: "",
        password: "",
        card_codes: ""
    });

    const { id_area, id_departament, userName, password, card_codes } = formValues;

    const toggleCardID = () => {
        setModalCardID(!modalCardID)

    }

    const navigareLogued = () => {
        history.push(`../logeo/user`);
    }

    //{ path: `${process.env.PUBLIC_URL}/dashboard/defaultTomaDeMuestra/`, Component: Default },
    const navigateTomaDeMuestra = () => {
        history.push(`../dashboard/defaultTomaDeMuestra/1`);

    }



    useEffect(() => {
        getBranches();
        getDepartaments();
        getSections();
        getCancellation();

    }, [history.location]);


    //TIME REAL
    useEffect(function () {
        setInterval(() => {
            changeTime(new Date().toLocaleTimeString());
        }, 1000);
    }, []);


    const reload = () => {
        window.location.reload(true);
    }



    ///api/Departaments/List
    const getDepartaments = async (id_departament) => {

        let requestOptions = {
            method: 'GET'
        };

        let ListDepartaments = [];

        const respuesta = await sendRequest(requestOptions, "Departaments/List");



        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {

                    ListDepartaments.push({
                        id_departament: value.id_departament,
                        id_area: value.id_area,
                        name: value.name,
                        use_consent: value.use_consent
                    });
                });
                setDepartamento(ListDepartaments);
            }
            else {
                setDepartamento([]);
            }
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }


    const getSections = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {
            setSections(respuesta.data);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getBranches = async () => {

        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Branch/List");

        if (respuesta.code === 200) {
            // ////console.log(respuesta.data);
            let data = [];
            respuesta.data.forEach(element => {
                let obj = {
                    id_branch: element.id_branch,
                    name: element.name,
                    toma: element.toma
                };
                data.push(obj);
            });
            setBranches(data);
            // setSections(respuesta.data);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    useEffect(() => {
        if (id_area > 0) {
            getProcessFromSection();
        }
    }, [id_area])


    const getProcessFromSection = async () => {

        let requestOptions = {
            method: 'GET'
        };


        const respuesta = await sendRequest(requestOptions, "Processes/GetProccessInSection/" + id_area + "/true");

        if (respuesta.code === 200) {
            setProcess(respuesta.data);
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const handleCardIDFlebotomista = () => {

        setMethodCardID("list");
        setModalTitleCardID("Tarjeta de ID")
        toggleCardID();

    };

    const cerrarSesion2 = (urlAlt) => {

        setTimeout(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("tomaMuestra");

            history.replace(`${urlAlt}/toma`);
        }, 500);
    }


    const [typeModalCancellation, setTypeModalCancellation] = useState("cancellToma");

    const [modal5, setModal5] = useState(false);

    const toggle5 = () => {
        setModal5(!modal5);
    }



    const getCancellation = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "CancellationReazonSample/List");

        if (respuesta.code === 200) {
            setDataCancellation(respuesta.data);
        }
    }

    const handleModalCancellToma = () => {

        toggle5();
        setTypeModalCancellation("cancellToma")

    }

    const [formValuesCancellation, handleInputChangeCancellation, resett, handleUpdateValuesCancellation] = useForm({

        id_cancellation_reazon_sample: 0

    });

    const {

        id_cancellation_reazon_sample

    } = formValuesCancellation;


    //#region card

    const [cardView, setCardView] = useState(false);
    const [setFocusCard, setSetFocusCard] = useState(true);
    //const [focusCard, setFocusCard] = useState(second)


    const handleViewCard = () => {
        setCardView(true);
        // setSetFocusCard(true)

    }

    const handleCloseCard = () => {
        setCardView(!cardView);
        //setSetFocusCard(false);

    }

    const cardAutoFocus = () => {
        if (setFocusCard) {
            if (document.getElementById("inputFocusCard") !== null) {
                document.getElementById("inputFocusCard").focus();
            }
        }
    }

    useEffect(() => {

        cardAutoFocus()

    }, [setFocusCard]);




    // useEffect(() => {

    //     let array = branches
    //     let id = 0

    //     array.forEach(element => {
    //         ////console.log(element);
    //         id = element.toma === false

    //     });
    //     ////console.log(id);


    //     if (branches.length > 0) {
    //         let _find = branches.find(x => x.toma == false)
    //         ////console.log(_find);
    //         handleUpdateValues({
    //             ...formValues,
    //             id_branch: _find.id_branch,
    //         });
    //     }
    // }, [branches]);

    // ////console.log(formValues);












    //#endregion card








    return {
        branches, departamento, sections, process, time, navigareLogued,
        handleCardIDFlebotomista, methodCardID, modalTitleCardID, loading, modalCardID, toggleCardID,
        navigateTomaDeMuestra,
        reload, cerrarSesion2,
        id_area, id_departament, userName, password, handleInputChange, handleSelectValues, formValues,
        dataCancellation, handleModalCancellToma, typeModalCancellation, toggle5, modal5,
        id_cancellation_reazon_sample, handleInputChangeCancellation, card_codes,
        handleViewCard, handleCloseCard, cardView,
        setFocusCard, cardAutoFocus, setSetFocusCard, handleUpdateValues
    }
}
