import React from 'react'
import { Form, ModalBody,Button,Row, FormText, Col} from 'reactstrap';
//import './StyleReturnReason.css';

export const FormInvoiceCompany = (props) => {

    const {


       toggle, loading, examProfileTest, methodAction,examProfile

    }= props;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row> <br /> <br /> 
                <Col sm="12" >
                <p className="h6 txt-danger">Estas a punto de facturar los siguientes exámenes:</p> <br />
                {
                                      examProfile.length >0 &&
                                        <table className="table table-xs table-stripped">
                                        <tbody>
                                          <tr className="table-Encabezado">
                                            <th style={{ textAlign: "-webkit-center" }}>Nombre</th>
                                            <th style={{ textAlign: "-webkit-center" }}>Nim</th>
                                            <th style={{ textAlign: "-webkit-center" }}>Exámen / Perfil</th>
                                          </tr>
                                          {examProfile !== undefined && examProfile.map((x, key) => {
                                            return (
                                              <tr key={ key}>
                                                <td style={{ verticalAlign :"middle"}} >{x.name+" "+x.paternal_surname+" "+x.maternal_surname}</td>
                                                <td style={{ verticalAlign :"middle"}}>{x.nim}</td>
                                                <td style={{ verticalAlign: "middle"}} >
                                                    {                                                                
                                                    x.examsProfiles.length >= 1 && x.examsProfiles.map( (test, i) => {
                                                        return (
                                                            <Row key={i}>
                                                               <Col className='p-1'>
                                                               <i className="icofont icofont-ui-press kit-icon f-8"></i>&nbsp;{test.name_test}
                                                                </Col>
                                                               </Row>
                                                             )
                                                          })
                                                        }
                                                </td>
                                             
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    } 
                
             

                    
                </Col>
                    
                  
                    
                        
                   </Row>
                  </ModalBody>
                   <ModalBody  style={{textAlign: "-webkit-right"}}>
                   <Button
                        color="primary"
                        type="submit"
                        className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                   >
                   {"Facturar"}
                   </Button> &nbsp;
                    <Button color="light" type="button" onClick={toggle}>Cancelar</Button>
                  </ModalBody>
                 </Form>
     
    )
}
