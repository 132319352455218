import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import Select from 'react-select';
import { PlaceHolderOption } from '../../constant';

export const FormRol = (props) => {
    
    const {
        toggleRol, loading, name, ids_views, handleInputChange, handleSelectValues, validationsRole, methodAction, button, listViews
    } = props;

    const {
        name_valid,
        views_valid
    } = validationsRole;

    return (
        <Form className='form them-form' role="form" onSubmit={methodAction}> 
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Rol <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type='text'
                                name="name"
                                value={name}
                                required={true}
                                autoComplete='off'
                                onChange={handleInputChange}
                                className='form-control form-control-sm input-air-primary'
                                invalid={name_valid}
                            />
                            <FormFeedback>El nombre debe contener más de 3 catacteres</FormFeedback>
                        </FormGroup>
                    </Col>                    
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Vistas <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Select
                                classNamePrefix='select'
                                name="ids_views"
                                value={ids_views}
                                options={listViews}
                                onChange={(e) => handleSelectValues(e, 'ids_views')}
                                placeholder={PlaceHolderOption}
                                isMulti
                                valid
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                            <FormText color='danger' hidden={!views_valid}>Debes seleccionar almenos una opción</FormText>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type='submit' className={loading ? 'disabled progress-bar-animated progress-bar-striped' : '' } disabled={loading}>
                    {button}
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggleRol}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )

}
