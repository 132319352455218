import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useRooms = () => {

    const history = useHistory();

    const [rooms, setRooms] = useState([]);
    const [roomId, setRoomId] = useState(0);

    const [departaments,setDepartaments]=useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_departament: "",
        name: "",
        code: "",
    });

    const { id_departament, name, code } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(rooms.length, numberItems);

    useEffect(() => {
        getDepartament().then(result=> {
            getDepartament(result);
        });
        getRooms();
    }, [history.location]);


    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listRooms = [];
        rooms.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listRooms.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setRooms(listRooms);
    }, [totalPageCount, currentPage])

    const [validaciones, setValidaciones] = useState({
        id_departament_valid: false,
        name_valid: false,
        code_valid: false,
    });

    const getRooms = async (result = null) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Rooms/List");
        //////console.log(respuesta);
        if (respuesta.code === 200) {
            let listRooms = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;
            let departamentos = result === null ? departaments : result;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {
                    

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    let departamento = departamentos.find(x => x.id_departament == data.id_departament);
                                  
                    listRooms.push({
                        //...data,
                        id_room:data.id_room,
                        id_departament:data.id_departament,
                        name_departament:departamento.name,
                        name:data.name,
                        code:data.code,
                        visibleTable: visibleTable
                    });
                });

                setRooms(listRooms);
            }
            else {
                // createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setRooms([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getRooms);

        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Cuartos", "Ocurrio un problema en el servidor");

        }
        else{
            createSweet("error", "warning", "Cuartos", respuesta.data.msg);

        }
        // else {
        //     validarSesion(history, respuesta.code, getRooms);
        // }
    }

    const getDepartament=async()=>{
        let requestOptions={
            method: 'GET'
        };
        // let listDepartaments=[];
        const respuesta=await sendRequest(requestOptions,'Departaments/List');
        if (respuesta.code === 200) {
            setDepartaments(respuesta.data);
          } 
    }

    // ////console.log(departaments);
    // ////console.log(rooms);

    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_departament: "",
            name: "",
            code: "",
        });

        setValidaciones({
            id_departament_valid: false,
            name_valid: false,
            code_valid: false,
        });

        //setRoomId(0);
        setMethod("create");
        setModalTitle("Crear cuarto");
        toggle();
        getDepartament();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }        

        let mValues = JSON.stringify(formValues);


        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Rooms");

        if (respuesta !==null) {
            //getRooms();
            //reset();
            createSweet("create", "success", "Exito!", "Cuarto creado con exito");
            setLoading(false);
            toggle();
            handleUpdateValues({
                id_departament:"",
                name:"",
                code:"",
            })
            getRooms();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Cuartos", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Cuartos", respuesta.data.msg);
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_room) => {
        let romm = rooms.find(s => s.id_room === parseInt(id_room));
////console.log(id_room,rooms);
        handleUpdateValues({
            id_departament: romm.id_departament,
            name: romm.name,
            code: romm.code,
        });

        setValidaciones({
            id_departament_valid: false,
            name_valid: false,
            code_valid: false,
        });

        setRoomId(id_room);
        setMethod("update");
        setModalTitle("Actualización de cuarto");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_room: roomId,
            id_departament:departaments,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Rooms/Update");

        if (respuesta.code === 200) {
            getRooms();
            createSweet("create", "info", "Exito!", "Cuarto actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Cuartos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Cuartos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_room) => {
        let room = rooms.find(s => s.id_room === parseInt(id_room));

        if (room) {
            handleUpdateValues({
                id_departament:room.id_departament,
                name: room.name,
                abbreviation: room.abbreviation
            });
        }

        setRoomId(id_room);
        setMethod("delete");
        setModalTitle("Eliminar cuarto");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Rooms/${roomId}`);
        if (respuesta!==null) {
            getRooms();
            setRoomId(0);
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Cuarto eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Cuartos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Cuartos", respuesta.data.msg);
            setLoading(false)
        }
    }


    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 2) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }

        if (formValues.code === "" || formValues.code.length < 2) {
            newValidations = {
                ...newValidations,
                code_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_valid: false
            }
        }

        if (formValues.id_departament === "" || formValues.id_departament <= 0) {
            newValidations = {
                ...newValidations,
                id_departament_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_departament_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }


    return {
        rooms, method, validaciones,
        name, id_departament, code, departaments,
        handleInputChange, loading, handleSelectValues,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }
}
