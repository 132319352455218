import React from 'react'
import { Button, Col, Modal, Row, ModalBody, ModalFooter, ModalHeader, Input, Label, FormGroup } from 'reactstrap';

export const ModalCommonDiscounts = (props) => {
    const {
        modal, toggle, arrayCommonDiscounts, clearDiscount, arrayAgreementDiscounts,newDiscount,validPorcentejeSpecial,setValidateCommonDiscountDetail
    } = props;
        
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                Listado de descuentos
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" className={validPorcentejeSpecial === "https://labcen.helen-sw.com" ? "" : "d-none"}>
                    <b className='txt-danger'>Una vez activada la campaña ya no se podra desactivar</b>

                    </Col>
                 
                </Row>
                <br />
                <Row>
                
                    {
                        arrayAgreementDiscounts.length > 0 && arrayAgreementDiscounts.map(_agreement => {
                            return (
                                <Col sm="12" md="12"  lg="12">
                                    <FormGroup>
                                        <i className="fa fa-check text-info"></i> &nbsp;&nbsp;
                                        <Label> Descuento por <b>Convenio - { _agreement.contract_number }</b></Label>
                                    </FormGroup>
                                </Col>
                            )
                        })
                    }
                    {
                        arrayCommonDiscounts.length > 0 && arrayCommonDiscounts.map(_common => {
                            if (_common.is_available_discount === true) {
                                setValidateCommonDiscountDetail(true)        
                            }

                            return (
                                <Col sm="12" md="12" lg="12 " >
                                    <Row>
                                        <Col sm="3" md="3" lg="3" className={validPorcentejeSpecial === "https://labcen.helen-sw.com" ? "d-none" : ""} >
                                            <FormGroup>
                                                <Label className='switch col-md-12'>
                                                    <Input type='checkbox' id={`CommonDiscount-${_common.id_common_discount}`} checked={_common.is_available_discount} disabled={!_common.is_enable} onChange={(e) => clearDiscount(e, _common.id_common_discount) } />
                                                    <span className='slider round'></span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <br />
                                      
                                        <Col  style={{textAlign: "center"}}   sm="3" className={validPorcentejeSpecial === "https://labcen.helen-sw.com" ? "" : "d-none"}>
                                            <br />
                                           
                                        <FormGroup>
                                                <Label className='switch col-md-12'>
                                                    <Input type='checkbox' id={`CommonDiscount-${_common.id_common_discount}`} checked={_common.is_available_discount} disabled={!_common.is_enable} onChange={(e) => newDiscount(e, _common.id_common_discount) } />
                                                    <span className='slider round'></span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="9" md="9" lg="9">
                                            <Label><b>{ _common.name }</b></Label>
                                            <br />
                                            <Label>Valido desde {_common.valid_from} hasta {_common.valid_to}</Label>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="button" onClick={toggle}>Cerrar visualización</Button>
            </ModalFooter>
        </Modal>
    )
}