import React from 'react'
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { usePaginationBackend } from '../pagination/usePaginationBackend';
import { useDebounce } from '../debounce/useDebounce';
import { useForm } from '../../hooks/forms/useForm';



export const useCost = () => {

    const history = useHistory();
    const { rateId } = useParams();
    const [createSweet] = useSweetAlert();


    const [filters, setfilters] = useState("");


    const [formValuesFilter, handleInputChangeFilter, resetFilter, handleUpdateValuesFilter, handleSelectValuesFilter, handlePickerValuesFilter] = useForm({
        id_sectionf: "0",
      });


      const {id_sectionf } = formValuesFilter;

       
    const handleFilters = () => {

        let query = "";
    
          if (id_sectionf !== "0") {
            query += `&id_section=${id_sectionf}`;
          }

        setfilters(query);
      }
    

    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 150);

    const handleSearch = (e) => {
        let busqueda = e.target.value;
        setSearchQuery(busqueda);
    }

    useEffect(() => {
        searchAsync();
    }, [debounceValue])

 
    useEffect(() => {

        searchAsync();
    
      }, [filters])



    const [metaData, setMetaData] = useState([]);
    const [examsData, setExamsData] = useState([]);


    const { goNextPage, goPreviousPage, goToPage, searchAsync, totalRecords, actualPage, totalPages, prevPage, nextPage, resultCount, results } = usePaginationBackend(
        { method: 'GET' },
        "ExamPriceCost/List/" + rateId,
        history,
        searchQuery,
        filters
    );

    useEffect(() => {

        let listPrice = [];
        
        results.forEach((dataa, key) => {

             dataa.production_cost = (dataa.production_cost.toFixed(2));
             dataa.sell_price =  (dataa.sell_price.toFixed(2));
             dataa.margin_gain =  (dataa.margin_gain.toFixed(2));

        
            let visibleTable = true;

            listPrice.push({
                id_exam: dataa.id_exam,
                code: dataa.code,
                name: dataa.name,
                production_cost: dataa.production_cost,
              //  margin_gain_label: dataa.margin_gain < 0 ?  cantidad (dataa.margin_gain) : (dataa.margin_gain) ,
                margin_gain:  dataa.margin_gain,
                pruena3 : ( "$" + dataa.production_cost),
                prueba1 :  (  dataa.margin_gain + "%" ),
                prueba2 : ( "$" + dataa.sell_price),
              
                sell_price: dataa.sell_price,
                visibleTable: visibleTable
            });

            // dataa.sell_price = cantidad(dataa.margin_gain)
            // return dataa;
            
        });
        setExamsData(results);
        setMetaData(listPrice);
    }, [results])

    const cantidad = (margin_gain) => {
        return <span style={{ color: "#f00"}}> {margin_gain}</span>
    }


    const [idExam, setIdExam] = useState(0);
    const [arrayExam, setArrayExam] = useState([]);
    const [price, setPrice] = useState(0);
    const [cost, setCost] = useState(0)
    const [producction, setProducction] = useState(0)


    const handlePriceExam = (id_exam) => {

        let exam = metaData.find(e => e.id_exam === parseInt(id_exam));

        let a = exam.sell_price;
        let b = exam.margin_gain;
        let c = exam.production_cost;
    
        setIdExam(id_exam);
        setArrayExam(exam);
        setMethod("list");
        toggle();
        setModalTitle("Asignar Costo");
        setPrice(a)//precio de venta
        setCost(b)//margen de ganancia
        setProducction(c)//costo de produccion
    }

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);

     
    const toggle = () => {
        setModal(!modal)
    }


    const handleEditPrice = (e) => {//costo de producción
        setProducction(e.target.value);
        calculatePorcentaje(cost, e.target.value)
        
        
    }

    const handleEditPrice2 = (e) => { //precio de venta
         setPrice(e.target.value);
         calculateMargin(e)
         
     }

     const handleMargin = (e,id_exam) =>{ //margen de ganancia
        setCost(e.target.value)
        calculatePorcentaje(e.target.value, producction)

     }


     const calculateMargin = (e) =>{   //para  price
  
        let value = e.target.value;
       
        if (value !== '') {

            value = parseFloat(value);

            let operacion =  (( value - producction ) / producction ) * 100
            setCost(operacion.toFixed(2))
        }
     }

   

    const calculatePorcentaje = (m, p) =>{

        let price = p;
        let margin = m;
       
        if (price !== '' && margin != '') {

            price = parseFloat(price);
            margin = parseFloat(margin);
            
            let operacion = ( ( margin / 100 ) + 1 ) * price;
            setPrice(operacion.toFixed(2))
             // (( cost- producction) / producction )  * 100
        }
     }



    const saveCreateCost = async (e,id_exam) => {

         e.preventDefault();
        setLoading(true);
      
        let body = {
            id_exam: idExam,
            id_rate: rateId,
            cost: producction,
            price: price,
        }


        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "ExamPriceCost/CreateOrUpdate");

        if (respuesta.code === 200 ) {
            createSweet("success", "success", "Tarifarios", "Los precios del tarifario fueron actualizados correctamente");

            let examenes = metaData;
              
            let find = examenes.find(x => x.id_exam === idExam);
        
           if (find) {

             find.production_cost = producction
             find.sell_price = price
             find.margin_gain = cost
            // find.margin_gain_label = cantidad(cost)
            find.prueba1 = (cost + "%")
            find.prueba2 = ( "$" + price)
            find.pruena3 = ( "$" + producction)
              setMetaData(
                  examenes,
                  find
              );
          }

            setLoading(false);
            toggle();
        }
    }

    
    useEffect(() => {
     
        getSection();
      }, []);


   

      const [listSection, setListSection] = useState([]);

    const getSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListSectionn = [];

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListSectionn.push({
                        id_section: value.id_section,
                        name: value.name,
                    });
                });
                setListSection(ListSectionn);
            }
            else {
                setListSection([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSection);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Exámen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Exámen", respuesta.data.msg);
        }
    }


    const handleChangeSection = (e) => {

        handleInputChangeFilter(e)

        let p = e.target.value

        getSection(p)

    }

   


  return {

    goNextPage, goPreviousPage, goToPage, actualPage, totalPages, prevPage, nextPage,
    handleSearch, metaData, examsData,handlePriceExam,
    method,modal,modalTitle,loading,toggle,idExam,arrayExam,handleEditPrice,
    saveCreateCost,price,cost,producction,handleEditPrice2,handleMargin,
    //filter
    id_sectionf,listSection,handleChangeSection,handleFilters



  }
}
