import React from 'react'
import { Button, Card, CardBody, Col,Input } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { useSamplingItems } from '../../hooks/samplings/useSamplingItems';

export const IndexSamplingItems = ({ activeTab }) => {

    const { samplingItems,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchSampling
    } = useSamplingItems();




    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Insumos
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={null}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                        <div className="row">
                            <div className="offset-8 col-4 pt-1 pb-2">
                                <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchSampling} />
                            </div>
                        </div>
                        <br />
                            <div>
                                <TableStriped
                                    notMaped={["visibleTable", "id_external", "id_sampling_items_category"]}
                                    methodsActions={false}
                                    cabeceras={["Nombre", "Código", "Precio", "Stock"]}
                                    items={samplingItems}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />
                            </div>
                        
                        
                                                    {/* {
                                samplingItems.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/login/title.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                                <br /> <p className="f-18">{"No se ha registrado algúna Indicación aún."}</p>
                                </div>
                                :
                                <div className="table-responsive">
                                   
                                <TableStriped
                                    notMaped={["visibleTable", "id_external", "id_sampling_items_category"]}
                                    methodsActions={false}
                                    cabeceras={["Nombre", "Código", "Precio", "Stock"]}
                                    items={samplingItems}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />
                            </div>
                            } */}
                   
                </CardBody>
            </Card>
        </>
    )
}
