import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useInvoiceSettings = () => {

    //PARAMS
    const history = useHistory();
    const { idBranch } = useParams();

    //#region DISPLAY MESSAGE
    const [ createSweet ] = useSweetAlert();
    //#endregion

    //#region MODALS    
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);

    const toggle = () => 
    {
        setModal(!modal);
    }

    //#endregion

    //#region INVOICE SETTINGS

        //#region INVOICE SETTINGS MODELS
        const [viewFormFilters, setViewFormFilters] = useState(false);
        const [idInvoiceSettings, setIdInvoiceSettings] = useState(null);
        const [filterTypeDocument, setFilterTypeDocument] = useState(0);
        const [filterByStatus, setFilterByStatus] = useState("");
        
        const [reloadMethods, setReloadMethods] = useState(false);
        
        const [listInvoiceSettings, setListInvoiceSettings] = useState([]);
        const [listTypeDocuments, setListTypeDocuments] = useState([]);

        const [formValues, handleInputChange, reset, handleUpdateValues] = useForm(
            {
                id_type_document: 0,
                code: "",
                serie: "",
                initial_folio: 1,
                final_folio: 0,
                is_default: false
            }
        );

        const {
            id_type_document, code, serie, initial_folio, final_folio, is_default
        } = formValues;
        //#endregion

        //#region VALIDATIONS
        const [validationInvoiceSettings, setValidationInvoiceSettings] = useState(
            {
                id_type_document_valid: false,
                code_valid: false,
                serie_valid: false,
                initial_folio_valid: false,
                final_folio_valid: false,                
            }
        )
        //#endregion

        //#region USE EFFECTS
        useEffect(() => {          
            getListInvoiceSettings();
            getListTypeDocument();
        }, []);

        useEffect(() => {
            
            if (reloadMethods) {
                getListInvoiceSettings();
            }

        }, [reloadMethods]);
        
        
        //#endregion
        
        //#region INVOICE SETTINGS METHODS
        const handleViewFilters = () => {
            setViewFormFilters(!viewFormFilters);
        }

        const getListInvoiceSettings = async () => {
            let list = await handleRequest("GET", `SettingsSeriesFolios/List/${idBranch}`, "Configuración de series");

            if (list !== null && list.length > 0) {

                ////console.log(list);

                setListInvoiceSettings(list);
                setReloadMethods(false);
            }
            else {
                setReloadMethods(false);
            }
        }

        const getListTypeDocument = async () => {
            let list = await handleRequest("GET", "CfdiCatalog/ListTypeDocument", "Lista de tipos de documentos");

            if (list !== null && list.length > 0) {
                setListTypeDocuments(list);
            }
        }

        const handleChangeFilterTypeDocument = (e) =>{ 
            let value = e.target.value;

            setFilterTypeDocument(value);
        }

        const handleAddInvoiceSettings = () => {
            setModalTitle("Nueva serie y folio");
            
            handleUpdateValues(
                {
                    id_type_document: 0,
                    code: "",
                    serie: "",
                    initial_folio: 1,
                    final_folio: 0,
                    is_default: false
                }
            );

            setValidationInvoiceSettings({
                id_type_document_valid: false,
                code_valid: false,
                serie_valid: false,
                initial_folio_valid: false,
                final_folio_valid: false,      
            });

            setMethod("create");
            toggle();
        }

        const saveCreate = async (e) => {
            e.preventDefault();

            setLoading(true);

            if (validInvoiceSettings()) {
                setLoading(false);
                return;
            }

            let body = {
                ...formValues,
                id_branch: idBranch
            };

            const response = await handleRequest("POST", "SettingsSeriesFolios/Create", "Crear configuración", body);

            if (response !== null) {
                createSweet("create", "success", "Exito!", "La serie y el folio se agregaron con exito!");
                setModal(false);
                setReloadMethods(true);
                setMethod("");
                setModalTitle("");
            }
            else {
                setLoading(false);
            }
        }

        const handleUpdate = (id_invoice_settings) => {
            setIdInvoiceSettings(id_invoice_settings);

            let findInvoiceSettings = listInvoiceSettings.find(x => x.id_invoice_settings == id_invoice_settings);

            if (findInvoiceSettings !== undefined) {
                
                handleUpdateValues({
                    id_type_document: findInvoiceSettings.id_type_document,
                    code: findInvoiceSettings.code,
                    serie: findInvoiceSettings.serie,
                    initial_folio: findInvoiceSettings.initial_folio,
                    final_folio: findInvoiceSettings.final_folio,
                    is_default: findInvoiceSettings.is_default
                });

                setValidationInvoiceSettings({
                    id_type_document_valid: false,
                    code_valid: false,
                    serie_valid: false,
                    initial_folio_valid: false,
                    final_folio_valid: false,     
                });

                setModalTitle("Editar serie y folio");
                toggle();
                setMethod("update");
            }
        }

        const saveUpdate = async (e) => {
            e.preventDefault();

            setLoading(true);

            if (validInvoiceSettings()) {
                setLoading(false);
                return;
            }

            let body = {
                ...formValues,
                id_invoice_settings: idInvoiceSettings,
                id_branch: idBranch
            };

            const response = await handleRequest("PUT", "SettingsSeriesFolios/Update", "Actualizar serie y folio", body);

            if (response !== null) {
                createSweet("update", "success", "Exito!", "La serie y el folio se actualizaron con exito!");
                setReloadMethods(true);
                setModal(!modal);
                setMethod("");
                setModalTitle("");
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }

        const handleDelete = (id_invoice_settings) => {
            setIdInvoiceSettings(id_invoice_settings);

            let findSettings = listInvoiceSettings.find(x => x.id_invoice_settings === id_invoice_settings);

            if (findSettings !== undefined) {
                handleUpdateValues({
                    code: findSettings.code,
                    serie: findSettings.serie,
                    initial_folio: findSettings.initial_folio
                });

                setMethod("delete");
                setModalTitle("Eliminar serie y folio");
                toggle();
            }
        }

        const saveDelete = async (e) => {
            e.preventDefault();

            setLoading(true);

            const response = await handleRequest("DELETE", "SettingsSeriesFolios/"+idInvoiceSettings, "Eliminar series y folios");

            if (response !== null) {
                createSweet("delete", "warning", "Exito!", "Se elimino la serie y el folio con exito!");
                setReloadMethods(true);
                toggle();
                setMethod("");
                setModalTitle("");
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        }
        //#endregion
    
    //#endregion

    //#region VALIDATIONS
    const validInvoiceSettings = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.id_type_document <= 0) {
            newValidations = {
                ...newValidations,
                id_type_document_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_type_document_valid: false
            }
        }

        if (formValues.serie.length === 0) {
            newValidations = {
                ...newValidations,
                serie_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                serie_valid: false
            };
        }

        if (formValues.initial_folio === "" || (formValues.initial_folio === 0 || formValues.initial_folio === "0")) {
            newValidations = {
                ...newValidations,
                initial_folio_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                initial_folio_valid: false
            };
        }    
        
        setValidationInvoiceSettings(newValidations);

        return statusValidation;
    }
    //#endregion

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) =>
    {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");            
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        //MODALS SETTINGS
        modal, modalTitle, method, loading,

        toggle,

        //INVOICE SETTINGS MODELS
        viewFormFilters,listInvoiceSettings,
        filterTypeDocument,
        id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
        listTypeDocuments,

        //INVOICE SETTINGS METHODS
        handleInputChange, handleChangeFilterTypeDocument,
        handleViewFilters, 
        handleAddInvoiceSettings, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    }
}
