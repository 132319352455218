import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteBacterium } from './DeleteBacterium';
import { FormBacterium } from './FormBacterium';

export const ModalBacterium = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className='modal-lg' >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormBacterium
                        button={"Crear bacteria"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram, }}
                    />
                    : (method === "update")
                        ?
                        <FormBacterium
                            button={"Actualizar bacteria"}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram, }} />
                        :
                        <DeleteBacterium {...{ toggle, loading, bacterium, saveDelete }} />
            }
        </Modal>
    )
}
