import React from 'react'
import { Button, Card, CardBody } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalDepartament } from '../../components/departaments/ModalDepartament';
import { useDepartament } from '../../hooks/areas/useDepartament';

export const IndexDepartaments = () => {

    const {
        departaments, method, validaciones,
        name, id_area, areas, specimen, specimens,
        handleSelectValues, handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, use_consent
    } = useDepartament();


    return (
        <>
            <Card>
                <CardBody className="p-1">
                    <div className="ribbon ribbon-clip ribbon-primary">
                        Listado de departamentos
                    </div>
                    <div className="text-right" style={{ margin: 5, padding: 0 }}>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    {
                        departaments.length === 0 ?
                            <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "48%" }} alt="" />
                                <br /> <p className='f-w-600 f-14 badge badge-light-primary' >No se ha registrado ningún departamento aún.</p>
                            </div>
                            :
                            <div className="table-responsive">
                                <TableStriped
                                    notMaped={["visibleTable", "specimens", "id_area"]}
                                    methodsActions={true}
                                    cabeceras={["Área", "Nombre", "Consentimiento Informado",]}
                                    items={departaments}
                                    {...{ handleUpdate, handleDelete }}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    goToPage={goToPage}
                                />
                            </div>
                    }
                </CardBody>
            </Card>
            <ModalDepartament
                {...{
                    modal, modalTitle, toggle, method,
                    validaciones,
                    name, id_area, areas, specimen, specimens,
                    handleSelectValues, handleInputChange, loading,
                    saveCreate, saveDelete, saveUpdate, use_consent
                }}
            />
        </>
    )
}
