import React from 'react'
import { useState, useEffect } from 'react'
import { useDebounce } from '../debounce/useDebounce';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';
import { sendRequest } from '../../hooks/requests/useRequest';
import { useHistory } from 'react-router';
import { validarSesion } from '../tokens/useToken';
import { useDebounceSpecial } from '../debounce/useDebounceSpecial';
import { usePagination } from '../pagination/usePagination';
import { P } from '@antv/g2plot';
import { useForm } from '../forms/useForm';


export const useProfit = () => {


    const [dataTable, setDataTable] = useState({
        columns: [
            {
                label: 'Razón Social',
                field: 'razon_social',
                width: 100,
            },
            {
                label: 'Clave del Tarifario',
                field: 'clave_tarifario',
                width: 100,
            },
            {
                label: 'Tarifario',
                field: 'tarifario',
                width: 100,
            },
            {
                label: 'Código',
                field: 'codigo',
                width: 100,
            },
            {
                label: 'Exámen/Perfil',
                field: 'exam_per',
                width: 100,
            },
            {
                label: 'Precio',
                field: 'precio',
                width: 100,
            },
            {
                label: 'Precio Mínimo de Venta',
                field: 'pmin',
                width: 100,
            },
        ],
        rows: []
    })
    //
    const [rangeval, setRangeval] = useState(0);
    const debounceValue = useDebounce(rangeval, 500);

    const [rangevalSpecial, setRangevalSpecial] = useState(0);
    const [idTest, setIdTest] = useState(0)
    const [typeTest, setTypeTest] = useState("")
    //Special
    const [indexx, setIndexx] = useState(-1);
    const debounceSpecial = useDebounce(rangevalSpecial, 500);

    const [profitTest, setProfitTest] = useState([]);
    const [percentage, setPercentage] = useState([]);
    const [agreementPercentage, setAgreementPercentage] = useState([]);

    const [buscar, setBuscar] = useState([]);
    const [examProfile, setexamProfile] = useState([]);
    const [id_exam_profile, setId_exam_profile] = useState(0)

    //Delete Exam/Profile
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    //Cards Global Prices 
    const [minPriceSell, setMinPriceSell] = useState(0);
    const [sumPrice, setSumPrice] = useState(0);//1
    const [lossSellTest, setLossSellTest] = useState(0); //2
    //percentaje 
    const [porPice, setPorPice] = useState(0);//count
    const [sumPriceS, setSumPriceS] = useState(0);
    //percentaje Special
    const [porPriceS, setPorPriceS] = useState(0)//Count
    const [comercialLine, setComercialLine] = useState([])//count
    const [perdidaagreement, setPerdidaagreement] = useState([])//perdida

    const [perPrice, setPerPrice] = useState(0);
    const [perSocial, setPerSocial] = useState(0)

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    //loading table
    const [loadinTable, setLoadinTable] = useState(false)

    //search razón social and Seaction
    const [commercialLine, setCommercialLine] = useState([]);
    const [listSection, setListSection] = useState([]);


    useEffect(() => {
        if (debounceValue) {
            saveCreate();
        }

    }, [debounceValue])

    useEffect(() => {

        if (rangevalSpecial) {

            saveUpdate();

            if (indexx !== -1) {
                let date = profitTest;
                date[indexx].percentage = rangevalSpecial;
                setProfitTest([...date])
            }
        }

    }, [debounceSpecial])


    useEffect(() => {

        getProfitPercentage();
        getTestPercentage();
        getProfittestPercentage();
        getCommercialLines();
        getSection();


    }, [history.location])

    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        id_commercial_line: 0,
        id_section: 0
    });


    const {
        id_commercial_line, id_section

    } = formValues;



    const getCommercialLines = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListCommercialLine = [];

        const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListCommercialLine.push({
                        id_commercial_line: value.id_commercial_line,
                        long_name: value.long_name,
                    });
                });
                setCommercialLine(ListCommercialLine);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron las lines sucursales");
                setCommercialLine([]);
            }
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getCommercialLines);
        }
    }

    const handleChangeCommercial = (e) => {

        handleInputChange(e)
    }


    const getSection = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let listIdSection = [];

        const respuesta = await sendRequest(requestOptions, "Section/List/false");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    listIdSection.push({
                        id_section: value.id_section,
                        name: value.name,
                    });
                });
                setListSection(listIdSection);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron las lines sucursales");
                setListSection([]);
            }
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getSection);
        }
    }

    const handleChangeSection = (e) => {

        handleInputChange(e)
    }



    const numberItems = 5;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(percentage.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listExams = [];
        percentage.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listExams.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setPercentage(listExams);

    }, [totalPageCount, currentPage])



    const handleChange = (e) => {
        setRangeval(e.target.value)
    }

    const handleChangeSpecial = (e, id_profile, id_exam, index) => {

        if (id_profile === 0) {

            setIdTest(id_exam)
            setTypeTest("exam")
        }
        else {
            setIdTest(id_profile)
            setTypeTest("profile")
        }
        setIndexx(index)
        setRangevalSpecial(e.target.value);
        let date = profitTest;
        date[index].percentage = e.target.value;
        setProfitTest([...date])
    }


    const saveCreate = async () => {


        setLoading(true);

        let body = { percentage: rangeval }
        let data = JSON.stringify(body);


        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "ProfitPercentage/Create");

        if (respuesta.code === 200) {
            if (respuesta.data.estatus === 1) {
                setLoading(false);
                createSweet("success", "success", "Se guardo correctamente un porcentaje global ");
                getListDetailPercentageTest();

            }
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }


    const getProfitPercentage = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "ProfitPercentage/List");

        if (respuesta.code === 200) {

            if (respuesta.data.percentage !== undefined) {
                setRangeval(respuesta.data.percentage);

            }
        }
        else {
           // createSweet("error", "error", "Hubo un error!", "Error de conexion");
            validarSesion(history, respuesta.code, getProfitPercentage);
        }
    }

    const handleSelectExamChange = (e) => {

        setId_exam_profile(e.target.value)

        let examProfile = e.target.value;

        let findRequestExam = "";

        if (examProfile !== 0 && examProfile !== null) {
            examProfile = examProfile.split("-");

            let mIdExam = examProfile[0];
            let mTipo = examProfile[1];


            if (mTipo === "0") {
                findRequestExam = profitTest.find(r => r.id_exam === parseInt(mIdExam));

            }
            else {
                findRequestExam = profitTest.find(r => r.id_profile === parseInt(mIdExam));
            }

            if (findRequestExam !== undefined) {
                createSweet("error", "error", "Ya existe el exámen seleccionado");
                setexamProfile({});
            }
            else {
                saveExamProfile(mIdExam, mTipo)
            }
        }
    }


    const saveExamProfile = async (mIdExam, mTipo) => {

        let body = {
            id_exam: (mTipo === "0") ? mIdExam : 0,
            id_profile: (mTipo === "1") ? mIdExam : 0,
            percentage: debounceValue
        }
        let data = JSON.stringify(body);

        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "ProfitTestPercentage/Create");


        if (respuesta.code === 200) {

            setLoading(false);
            createSweet("success", "success", "Se guardo correctamente ");
            getTestPercentage()

        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getTestPercentage = async () => {
        let requestOptions = {
            method: 'GET'
        };

        let ListCompanyTurn = [];

        const respuesta = await sendRequest(requestOptions, "ProfitTestPercentage/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach(({ id_profit_test_percentage, id_exam, id_profile, name_test, code_test, percentage }) => {
                    ListCompanyTurn.push({
                        id_profit_test_percentage: id_profit_test_percentage,
                        id_exam: id_exam === null ? 0 : id_exam,
                        id_profile: id_profile === null ? 0 : id_profile,
                        name_test,
                        code_test,
                        percentage

                    });
                });
                setProfitTest(ListCompanyTurn);
            }
            else {
                //createSweet("error", "error", "Hubo un error!", "No se encontraron los examenes");
                setProfitTest([]);
            }
        }
        else {
            validarSesion(history, respuesta.code, getTestPercentage);
        }
    }

    const saveUpdate = async () => {
        setLoading(true);
        let body = {
            id_exam: (typeTest === 'exam') ? idTest : 0,
            id_profile: (typeTest === 'profile') ? idTest : 0,
            percentage: debounceSpecial
        }
        let data = JSON.stringify(body);


        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, "ProfitTestPercentage/Create");


        if (respuesta.code === 200) {
            setLoading(false);
            createSweet("success", "success", "Se actualizo correctamente la ganancia especial");
            getTestPercentage();
            getProfittestPercentage();
        }
        //datosmostrados de la misma manera en la conexion d e
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
        }
    }

    const getPercentage = (e) => {

        e.preventDefault();
        setLoading(true);

        if (formValues.id_commercial_line || formValues.id_section.length > 0) {
            getListDetailPercentageTest();
        }
        else {
            createSweet("error", "warning", "Ingresa los datos a consultar");
            setLoading(false);
            return;
        }
        setLoading(false);
    }



    const getListDetailPercentageTest = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListDetailPe = [];


        setLoadinTable(true);

        const respuesta = await sendRequest(requestOptions, `ProfitTestPercentage/ListDetailPercentageTest?id_commercial_line=${formValues.id_commercial_line}&id_section=${formValues.id_section}`);
        if (respuesta.code === 200) {
            setLoadinTable(false)

            if (respuesta.data.length >= 1) {

                let data = dataTable;

                respuesta.data.forEach((obj) => {
                    data.rows.push({
                        razon_social: obj.name_commercial_line,
                        clave_tarifario: obj.abbreviation_rate,
                        tarifario: obj.name_rate,
                        codigo: obj.code_test,
                        exam_per: obj.name_test,
                        precio: '$' + number_format(obj.price, 2),
                        pmin: '$' + number_format(obj.profit_price, 2)
                    })
                });

                setDataTable({ ...data })

                let tper = 0;
                let pmin = 0;
                let psum = 0;
                let pPorce = 0;

                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;

                startItems = endItems - numberItems;

                respuesta.data.forEach((value, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    if (value.price < value.profit_price) {
                        pmin++;
                    }

                    let perdida = value.profit_price - value.price;

                    if (perdida > 0) {
                        tper += perdida;
                    }

                    psum += parseFloat(value.profit_price);

                    ListDetailPe.push({

                        id_rate: value.id_rate,
                        id_commercial_line: value.id_commercial_line,
                        id_branch: value.id_branch,
                        id_exam: value.id_exam,
                        id_profile: value.id_profile,
                        name_commercial_line: value.name_commercial_line,
                        name_rate: value.name_rate,
                        abbreviation_rate: value.abbreviation_rate,
                        name_branch: value.name_branch,
                        code_test: value.code_test,
                        name_test: value.name_test,
                        price: '$ ' + number_format(value.price, 2),
                        profit_price: '$ ' + number_format(value.profit_price, 2),
                        from: value.from,
                        to: value.to,
                        visibleTable: visibleTable
                    });
                });

                pPorce = (tper / psum) * 100;

                setSumPrice(psum);
                setPorPice(pPorce);
                setMinPriceSell(pmin);
                setLossSellTest(tper);
                setPercentage([...ListDetailPe]);
            }
            else {
                setPercentage([]);
            }
        }
        else {
            validarSesion(history, respuesta.code, getListDetailPercentageTest);
        }
    }

    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }


    const getPercentageProfit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (formValues.id_commercial_line || formValues.id_section.length > 0) {
            getProfittestPercentage();
        }
        else {
            createSweet("error", "warning", "Selecciona los campos a consultar");
            setLoading(false);
            return;
        }
        setLoading(false);
    }

//wilson
    const getProfittestPercentage = async () => {

        let requestOptions = {
            method: 'GET'
        };
        let ListTestPercentage = [];

        const respuesta = await sendRequest(requestOptions, `ProfitTestPercentage/ListDetailPercentageAgreement?id_commercial_line=${formValues.id_commercial_line}&id_section=${formValues.id_section}`);
        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                let pTotal = 0;
                let cReg = 0;
                let arrayClient = [];
                let pPrice = 0;
                let psumm = 0;
                let pPorceS = 0;

                respuesta.data.forEach((value) => {

                    let deuda = value.profit_price - value.price_agreement;
                    if (arrayClient.length === 0) {
                        arrayClient.push({
                            nameCompany: value.name_company,
                            deudas: deuda
                        })
                    }
                    else {
                        let bus = arrayClient.find(c => c.nameCompany === value.name_company)

                        if (!bus) {
                            arrayClient.push({
                                nameCompany: value.name_company,
                                deudas: deuda
                            })
                        }
                    };

                    if (value.price_agreement < value.profit_price) {
                        cReg++;
                    }

                    let perdida = value.profit_price - value.price_agreement;

                    if (perdida > 0) {
                        pTotal += perdida;
                    }



                    psumm += parseFloat(value.profit_price);

                    ListTestPercentage.push({
                        code_company: value.code_company,
                        name_commercial_line: value.name_commercial_line,
                        name_company: value.name_company,
                        contract_number: value.contract_number,
                        validity_agreement: value.validity_agreement,
                        status_agreement: value.status_agreement,
                        code_test: value.code_test,
                        name_test: value.name_test,
                        min_range: value.min_range,
                        max_range: value.max_range,
                        percentage: + (value.percentage) + '%',
                        price_agreement: '$' + number_format(value.price_agreement, 2),
                        profit_price: '$ ' + number_format(value.profit_price, 2),
                    });
                });

                pPorceS = (pTotal / psumm) * 100;

                setPerdidaagreement(pPrice)
                setSumPriceS(psumm);
                setPorPriceS(pPorceS);
                setComercialLine(arrayClient);
                setPerPrice(pTotal);
                setPerSocial(arrayClient.length)
                setAgreementPercentage(ListTestPercentage);
            }
            else {
                setAgreementPercentage([]);
            }
        }
        else {
            validarSesion(history, respuesta.code, getProfittestPercentage);
        }
    }




    






    return {
        rangeval, setRangeval, handleChange, debounceValue, saveCreate,
        profitTest, handleChangeSpecial, percentage,
        buscar,
        handleSelectExamChange, examProfile, id_exam_profile, agreementPercentage,
        number_format,
        minPriceSell, lossSellTest,
        dataTable,
        perPrice, perSocial, sumPrice, porPice, comercialLine, perdidaagreement,
        porPriceS, sumPriceS,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        //loadinTable
        loadinTable,
        //search razon social and Section
        commercialLine, handleChangeCommercial, id_commercial_line,
        listSection, id_section, handleChangeSection, getSection, handleInputChange, getPercentage,
        getPercentageProfit

    }
}
