import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { TabSections, TabSubSections } from '../../../constant';
import { IndexSections as ComponentSections } from '../../sections/IndexSections';
import { IndexSubsections } from '../../subsections/IndexSubsections';
import { IndexAreas } from '../../areas/IndexAreas';
import { IndexRooms } from '../../areas/IndexRooms';
import { IndexProcess as ComponentProcess } from './IndexProcess';


export const IndexSections = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Listado de secciones" /> */}
            <Container fluid={true}><br />
                <Row>
                    <Col md="12">
                        <Card>
                            <Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className="icofont icofont-attachment"></i> {TabSections}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="icofont icofont-attachment"></i> {TabSubSections}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "3" ? 'active' : ''} onClick={() => toggleTab("3")}><i className="icofont icofont-attachment"></i>Áreas de proceso</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <ComponentSections
                                        {...{ ...props, activeTab }}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="2">
                                    <IndexSubsections
                                        {...{ ...props, activeTab }}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="3">
                                    <ComponentProcess {...{ ...props, activeTab }} />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
