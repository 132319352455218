import { useEffect, useState } from 'react';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useForm } from '../../hooks/forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router';


export const useUnitsConversions = (props) => {
    const { activeTab } = props;
    const history = useHistory;

    useEffect(() => {
        getUnitsConversions();
        getUnits();
    }, [history.location]);

    useEffect(() => {
        if (activeTab === 2) {
            getUnits();
        }
    }, [activeTab]);

    const [unitsConversions, setUnitsConversions] = useState({});
    const [units, setUnits] = useState({});
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [unitConversionId, setUnitConversionId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_unit_from: 0,
        id_unit_to: 0,
        conversion_factor: 0
    });

    const { id_unit_from, id_unit_to, conversion_factor } = formValues;


    const getUnits = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Units");
        if (respuesta.code === 200) {
            setUnits(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getUnits);
        }
    }

    const getUnitsConversions = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "UnitConversions");
        if (respuesta.code === 200) {
            setUnitsConversions(respuesta.data);
        } else {
            validarSesion(history, respuesta.code, getUnitsConversions);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_unit_from: "",
            id_unit_to: "",
            conversion_factor: ""
        });
        setUnitConversionId(0);
        setMethod("create");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();
        if (formValues.id_unit_from <= 0) {
            alert("Debes de seleccionar una unidad de origen");
        } else if (formValues.id_unit_to <= 0) {
            alert("Debes de seleccionar una unidad de destino");
        } else if (formValues.conversion_factor <= 0) {
            alert("El factor de conversión debe de ser mayor a 1.");
        }
        else {
            setLoading(true);
            let raw = JSON.stringify(formValues);
            let requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            const respuesta = await sendRequest(requestOptions, "UnitConversions");

            if (respuesta.code === 200) {
                let unit_from = units.find(u => u.id_unit === parseInt(formValues.id_unit_from));
                let unit_to = units.find(u => u.id_unit === parseInt(formValues.id_unit_to));
                let newUnitsConversions = unitsConversions;

                newUnitsConversions.push({
                    id_unit_conversion: respuesta.data.id_unit_conversion,
                    id_unit_from: id_unit_from,
                    id_unit_to: id_unit_to,
                    name_unit_from: unit_from.unit,
                    name_unit_to: unit_to.unit,
                    abbreviation_unit_from: unit_from.abbreviation,
                    abbreviation_unit_to: unit_to.abbreviation,
                    conversion_factor: conversion_factor,
                })
                setUnitsConversions(newUnitsConversions);
                reset();
                setLoading(false);
                createSweet("create", "success");
                toggle();

            } else {
                ////console.log("Error al crear la unidad de conversion...");
                validarSesion(history, respuesta.code, saveCreate);
            }
        }
    }

    const handleUpdate = (id_unit_conversion) => {
        let unit_conversion = unitsConversions.find(uc => uc.id_unit_conversion === id_unit_conversion);
        handleUpdateValues({
            id_unit_from: unit_conversion.id_unit_from,
            id_unit_to: unit_conversion.id_unit_to,
            conversion_factor: unit_conversion.conversion_factor
        });
        setUnitConversionId(id_unit_conversion);
        setMethod("update");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        let body = {
            id_unit_conversion: unitConversionId,
            ...{ ...formValues }
        }
        setLoading(true);
        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "UnitConversions");
        if (respuesta.code === 200) {
            let unit_from = units.find(u => u.id_unit === parseInt(id_unit_from));
            let unit_to = units.find(u => u.id_unit === parseInt(id_unit_to));
            let newUnitsConversions = unitsConversions.map(function (obj) {
                if (obj.id_unit_conversion === unitConversionId) {
                    obj.id_unit_from = id_unit_from;
                    obj.id_unit_to = id_unit_to;
                    obj.conversion_factor = conversion_factor;
                    obj.name_unit_from = unit_from.unit;
                    obj.abbreviation_unit_from = unit_from.abbreviation;
                    obj.abbreviation_unit_to = unit_to.abbreviation;
                    obj.name_unit_to = unit_to.unit;
                }
                return obj;
            });
            setUnitsConversions(newUnitsConversions);
            setLoading(false);
            createSweet("update", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveUpdate);
            ////console.log("Ocurrio un error");
        }
    }

    const handleDelete = (id_unit_conversion) => {

        let unit_conversion = unitsConversions.find(uc => uc.id_unit_conversion === id_unit_conversion);
        handleUpdateValues({
            id_unit_from: unit_conversion.id_unit_from,
            id_unit_to: unit_conversion.id_unit_to,
            conversion_factor: unit_conversion.conversion_factor
        });
        setUnitConversionId(id_unit_conversion);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow'
        };
        setLoading(true);
        const respuesta = await sendRequest(requestOptions, `UnitConversions/${unitConversionId}`);
        if (respuesta.code === 200) {
            let newUnitsConversions = unitsConversions.filter(u => u.id_unit_conversion !== unitConversionId);
            setUnitsConversions(newUnitsConversions);
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        } else {
            validarSesion(history, respuesta.code, saveDelete);
            ////console.log("Error");
        }
    }

    return {
        unitsConversions, units, method,
        handleInputChange, id_unit_from, id_unit_to, conversion_factor,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    };
}
