import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { sendRequest } from '../requests/useRequest';
import { usePantalla1 } from './usePantalla1';
import { useForm } from '../forms/useForm';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';



export const useEncuestaCliente = () => {


    const { fechaYHora } = usePantalla1();

    const history = useHistory();
    const { nimm } = useParams();
    let id = localStorage.getItem('id');

    const [respuestas, setRespuestas] = useState([]);

    //modal
    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [nameModal, setNameModal] = useState("");
    const [dateInicial, setDateInicial] = useState("");
    const [surnames, setSurnames] = useState("");

    const [counter, setCounter] = useState(0);
    const [isActive, setIsActive] = useState(true); //si lo declaro en true inicia automaticamente, si es false espera el click del button

    const [hour, setHour] = useState('00');
    const [second, setSecond] = useState('00');
    const [minute, setMinute] = useState('00');

    //modalCancell
    const [modal2, setModal2] = useState(false)
    const [method2, setMethod2] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [alterQuestion, setAlterQuestion] = useState({ tab: 0, response: "" });
    //question
    const [dinamica, setDinamica] = useState({});
    const [showAlternativeQ, setShowAlternativeQ] = useState(false);
    //cancell Sample
    const [typeModalCancellation, setTypeModalCancellation] = useState("cancellToma");
    const [modal5, setModal5] = useState(false);
    const [flebotomiaRefused, setFlebotomiaRefused] = useState("FlebotomiaRefused");
    const [modal6, setModal6] = useState(false);


    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();

    useEffect(() => {
        getSatisfaction();
        handleReason();
    }, [])


    const [formValuesComment, handleInputChangeComment, resetcomment, handleUpdateValuesComment] = useForm({
        comments: "",
    });

    const { comments } = formValuesComment;


    const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
        id_cancellation_reazon_sample: 0,
    });

    const { id_cancellation_reazon_sample, } = formR;

    const [reason, setReason] = useState([]);



    //#region SAVE TIME
    useEffect(() => {
        let _interValid;

        if (isActive) {

            _interValid = setInterval(() => {

                let actual = new Date();
                let prueba = new Date(localStorage.getItem('timeInit'));

                let tiempoPasado = (actual - prueba);

                let segs = 1000;
                let mins = segs * 60;
                let hours = mins * 60;

                let horas = Math.floor(tiempoPasado / hours);

                tiempoPasado = tiempoPasado - (horas * hours);
                let minutos = Math.floor(tiempoPasado / mins);

                tiempoPasado = tiempoPasado - (minutos * mins);
                let segundos = Math.floor(tiempoPasado / segs);

                const computedSecond = String(segundos).length === 1 ? `0${segundos}` : segundos;
                const computedMinute = String(minutos).length === 1 ? `0${minutos}` : minutos;
                const computedHour = String(horas).length === 1 ? `0${horas}` : horas;

                setHour(computedHour);
                setMinute(computedMinute);
                setSecond(computedSecond);

                setCounter(counter => counter + 1);
            }, 1000);
        }
        return () => clearInterval(_interValid);

    }, [history.location, counter]);



    useEffect(() => {
        var hoy = new Date();
        var fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
        var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
        setDateInicial(fecha + ' ' + hora);

        let _copy = dateInicial

        if (_copy == "") {
            _copy = _copy
        }

    }, [dateInicial])
    //#endregion SAVE TIME

    //#region MODAL

    const toggle = () => {
        setModal(!modal)

    }
    const toggle2 = () => {
        setModal2(!modal2)
    }

    const handleEnd = () => {
        setMethod("listEnd");
        setModalTitle("Gracias");
        toggle();
    }

    const handleEndCancell = () => {
        setMethod2("listEnd");
        toggle2();
    }

    const toggle5 = () => {
        setModal5(!modal5);
    }

    const handleModalCancellToma = (e) => {
        toggle5();
        setTypeModalCancellation("cancellToma")
    }

    const toggle6 = () => {
        setModal6(!modal6);
    }

    const handleSample = () => {
        setFlebotomiaRefused("FlebotomiaRefused")
        toggle6();
    }

    //#endregion MODAL

    //#region SATISFACTION QUESTION DINAMICS

    const getSatisfaction = async () => {

        let listTmp = await handleRequest("GET", `SatisfactionQuestion/ListPaintQuestion`, 'Satisfaction');

        if (listTmp !== null) {
            setDinamica(listTmp)
        }
    }


    const navigatePrev = (indice) => {

        let _answer = dinamica;

        if (_answer[indice - 1] !== undefined) {
            _answer[indice - 1].visibility = true;
            _answer[indice].visibility = false;
        }
        setDinamica([..._answer])
    }


    const navigateNext = (indice) => {

        let _answer = dinamica;

        if (_answer[indice + 1] !== undefined) {
            _answer[indice + 1].visibility = true;
            _answer[indice].visibility = false;
        }
        setShowAlternativeQ(false);
        setDinamica([..._answer])
    }

    //paint question
    const saveQuestion = (idParent, idChildren, idP2 = "", idC2 = "", nextnavigate = 0) => {

        let copy = dinamica;

        if (idP2 === "" && idC2 === "") {
            if (copy[idParent].name_type == "multiple") {

                let r = copy[idParent].listReagents[idChildren]

                r.selected_response = !r.selected_response

                r.selected_response = r.selected_response;
                copy[idParent].listReagents[idChildren] = r;

            }
            else if (copy[idParent].name_type == "cerrada") {

                for (let i = 0; i < copy[idParent].listReagents.length; i++) {

                    if (i == idChildren) {
                        copy[idParent].listReagents[i].selected_response = true;

                        if (copy[idParent].listReagents[i].alternativeQuestions.length > 0) {
                            copy[idParent].listReagents[i].alternativeQuestions[0].visibility = true;
                            setShowAlternativeQ(true);
                        }
                    }
                    else {
                        copy[idParent].listReagents[i].selected_response = false;

                        if (copy[idParent].listReagents[i].alternativeQuestions.length > 0) {
                            copy[idParent].listReagents[i].alternativeQuestions[0].visibility = false;
                        }
                    }
                }
            }
        }
        else {
            if (copy[idParent].name_type == "cerrada") {
                for (let a = 0; a < copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents.length; a++) {

                    if (a == idC2) {
                        copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents[a].selected_response = true;
                    }
                    else {
                        copy[idParent].listReagents[idChildren].alternativeQuestions[idP2].listPaintAlternativeReagents[a].selected_response = false
                    }
                }
            }
        }


        if (nextnavigate == 1) {
            ////console.log("pasa aqui");
            setShowAlternativeQ(false);

            copy[idParent].visibility = false

            if (copy[idParent + 1] != undefined) {
                copy[idParent + 1].visibility = true
            }

        }

        setDinamica([...copy])
    }

    ////console.log("----");
    ////console.log(dinamica);

    //question close
    const navigateNextAbierta = (idParent, idChildren, idP2 = "", idC2 = "", nextnavigate = 0) => {

        let copy = dinamica;


        if (nextnavigate == 0) {//pregunta cerrada con botón de siguiente

            copy[idParent].visibility = false

            if (copy[idParent + 1] != undefined) {
                copy[idParent + 1].visibility = true
            }
        }
        setDinamica([...copy])
    }

    //question open
    const questionOpen = (idParent, e) => {
        let _copyOpen = dinamica

        if (idParent !== "") {

            _copyOpen[idParent].comments = e
        }
        setDinamica([..._copyOpen])
    }
    //#endregion SATISFACTION QUESTION DINAMICS

    //#region SAVE ANSWER SURVEY
    const getEncuesta = (e) => {
        e.preventDefault();
        setLoading(true);

        saveEncuestaFinal()
        setLoading(false);
    }

    //save question
    const saveEncuestaFinal = async () => {

        let _newForm = {
            nim: nimm.substring(0, 14),
            date_ini: dateInicial,
            date_end: fechaYHora,
            date_time_end: fechaYHora,
            listPaintQuestions: dinamica
        };
        ////console.log(_newForm);

        let listTmp = await handleRequest("POST", "SatisfactionQuestion/SaveAnswerSurvey", "Sample", _newForm);
        if (listTmp !== null) {
            navigateInicio();
        }
    }


    //save cancell toma
    const getEncuestaCancell = (e) => {
        e.preventDefault();
        setLoading2(true);

        saveEncuestaFinalCancell()
        setLoading2(false);
    }

    const saveEncuestaFinalCancell = async (e) => {

        let _newForm = {
            nim: nimm.substring(0, 14),
            id_user: id,
            time_init: null,
            time_end: null,
            time_end_sample: fechaYHora,
            cancellation: 0,
            sampleQuiz: []
        }

        let listTmp = await handleRequest("POST", "Sample/UpdateESample", "Sample", _newForm);
        if (listTmp !== null) {
            navigateInicio();
        }
    }
    //#endregion SAVE ANSWER SURVEY

    //#region Save Comment flebotomista
    const saveComment = async () => {

        let _newForm = {
            comments: formValuesComment.comments,
            nim: nimm.substring(0, 14),
        };

        let listTmp = await handleRequest("POST", "Sample/SampleUpdateData", "Sample", _newForm);
        if (listTmp !== null) {

        }
    }
    //#endregion Save Comment flebotomista

    //#region saveTomaComplete
    const saveUpdateTmSample = async () => {

        let _newForm = {
            nim: nimm.substring(0, 14),
            id_user: id,
            time_init: dateInicial,
            time_end: fechaYHora,
            time_end_sample: "",
            cancellation: "",
            testCI: [],
            imageFirm: ""
        }
        let listTmp = await handleRequest("POST", "Sample/UpdateTmSample", "Sample", _newForm);
        if (listTmp !== null) {
            saveComment();
            //  history.push('../encuesta/' + nimm); //verificar esta parte
            history.push('../instrucciones/' + nimm);
        }
    }
    //#endregion saveTomaComplete

    //#region Cancell sample patient
    const handleReason = async () => {
        let _listTmp = await handleRequest("GET", `CancellationReazonSample/List`, 'sample');

        if (_listTmp !== null) {
            let _list = [];

            _listTmp.forEach((_find, i) => {
                _list.push({
                    value: _find.id_cancellation_reazon_sample,
                    label: _find.cancellation_reazon
                });
            });
            setReason([..._list]);
        }
    }

    const saveUpdateTmSampleCancell = async (e) => {

        if (validationRefused()) {
            return;
        }
        let id_return_reason2 = formR.id_cancellation_reazon_sample.value

        let _newForm = {
            nim: nimm.substring(0, 14),
            time_init: null,
            time_end: null,
            cancellation: id_return_reason2,
            testCI: [],
            imageFirm: "",
        }

        let listTmp = await handleRequest("POST", "Sample/UpdateTmSample", "Sample", _newForm);
        if (listTmp !== null) {
            navigateInicio();
            saveComment();
        }
    }

    const handleSendEncuesta = () => {
        history.push('../encuesta/' + nimm); //verificar esta parte
    }

    const [validationsSG, setvalidationsSG] = useState({
        id_return_reasonV: false
    });

    const validationRefused = () => {
        let _statusValidation = false;
        let _newValidations = {};
        let _id_return_reason2 = formR.id_cancellation_reazon_sample.value

        if (_id_return_reason2 === undefined) {
            _newValidations = {
                ..._newValidations,
                id_return_reasonV: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_return_reasonV: false,
            };
        }
        setvalidationsSG(_newValidations);
        return _statusValidation;
    }
    //#endregion Cancell sample patient

    const navigateInicio = () => {
        history.push('/dashboard/defaultTomaDeMuestra');

    }


    const [rating, setRating] = useState(0)

    // Catch Rating value
    const handleRating = (rate) => {
        ////console.log("que lleva");
        ////console.log(rate);
      setRating(rate)
  
      // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = () => console.log("valor")


    ////console.log(rating);

    



    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }


    return {
        handleEnd, method, modalTitle, toggle, modal, loading,
        navigateInicio, nameModal, getEncuesta, hour, minute, second, surnames,
        saveUpdateTmSampleCancell, getEncuestaCancell,
        handleEndCancell, method2, toggle2, modal2, loading2,
        alterQuestion, typeModalCancellation, modal5, toggle5, handleModalCancellToma,
        //encuesta dinamica
        dinamica, navigateNext, navigatePrev, saveQuestion, questionOpen, showAlternativeQ,
        comments, handleInputChangeComment, saveComment, saveUpdateTmSample,
        //refused sample patient
        id_cancellation_reazon_sample, handleSelectValuesR, reason, validationsSG,
        //sample
        handleSample, toggle6, flebotomiaRefused, modal6, handleSendEncuesta,
        rating,handleRating,onPointerEnter,onPointerLeave,onPointerMove
    }
}
