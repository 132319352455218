import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateBranchMachine, ModalUpdateBranchMachine, ModalDeleteBranchMachine, LostConnection } from '../../constant'; 
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { usePagination} from '../pagination/usePagination';


export const useBranchMachine = () => {

    const history = useHistory();
    
    const [branchMachine, setBranchMachine] = useState([]);
    const [branchMachineId, setBranchMachineId] = useState(0);

    const [machines, setMachines] = useState([]);
    const [branches, setBranches] = useState([]);
    const [sections, setSections] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_machine_model: 0,
        id_branch: 0,
        id_section: 0,
        serial_number: ""
    });

    const { id_machine_model, id_branch, id_section, serial_number } = formValues;

    const [validaciones, setValidaciones] = useState({
        id_machine_model_valid: false,
        id_branch_valid: false,
        id_section_valid: false,
        serial_number_valid: false
    });

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(branchMachine.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listbranchMachine = [];
        branchMachine.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listbranchMachine.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setBranchMachine(listbranchMachine);
    }, [totalPageCount, currentPage])

    const toggle = () => {
        setModal(!modal);
    }

    const getBranchMachine = useCallback( async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Machine/List");

        if (respuesta.code === 200) {

            let listBranchMachine = [];

            if (respuesta.data.length >= 1) {

                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;

                endItems = aux;
                startItems = endItems - numberItems;

                respuesta.data.forEach( (data,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listBranchMachine.push({
                        id_machine: data.id_machine,
                        id_machine_model: data.id_machine_model,
                        id_branch: data.id_branch,
                        id_section: data.id_section,
                        serial_number: data.serial_number,
                        name_machine_model: data.name_machine_model,
                        name_branch: data.name_branch,
                        name_section: data.name_section,
                        visibleTable: visibleTable

                    });
                });

                setBranchMachine(listBranchMachine);
                setMachineData(listBranchMachine)
            }
            else{
                createSweet("error", "warning", "Equipos", "No se encontraron datos");
                setBranchMachine([]);
            }
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getBranchMachine);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }, [history]);

    useEffect(() => {
        
        getBranchMachine();
        
    }, [getBranchMachine])

    const getMachine = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "MachineModel/List");
        if (respuesta.code === 200) {
            setMachines(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getMachine);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const getBranch = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Branch/List");
        if (respuesta.code === 200) {
            setBranches(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getBranch);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const getSections = async () => {
        let requestOptions = {
            method: 'GET'        
        };

        const respuesta = await sendRequest(requestOptions, "Section/List/false");
        if (respuesta.code === 200) {
            setSections(respuesta.data);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getSections);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const handleCreate = () => {
        handleUpdateValues({
            id_machine_model: 0,
            id_branch: 0,
            id_section: 0,
            serial_number: ""
        });

        setValidaciones({
            id_machine_model_valid: false,
            id_branch_valid: false,
            id_section_valid: false,
            serial_number_valid: false
        });

        setBranchMachineId(0);
        setMethod("create");
        setModalTitle(ModalCreateBranchMachine);
        toggle();
        getMachine();
        getBranch();
        getSections();
    }

    const saveCreate = async (e) => {

        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method:'POST',
            body: raw
        }

        const respuesta = await sendRequest(requestOptions, "Machine");

        if (respuesta.code === 200) {
            getBranchMachine();
            reset();
            createSweet("create", "success", "Exito!", "Asignación con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_machine) => {
        
        let machine = branchMachine.find(m => m.id_machine === parseInt(id_machine));

        handleUpdateValues({
            id_machine_model: machine.id_machine_model,
            id_branch: machine.id_branch,
            id_section: machine.id_section,
            serial_number: machine.serial_number
        });

        setValidaciones({
            id_machine_model_valid: false,
            id_branch_valid: false,
            id_section_valid: false,
            serial_number_valid: false
        });

        setBranchMachineId(id_machine);
        setMethod("update");
        setModalTitle(ModalUpdateBranchMachine);
        toggle();
        
        getMachine();
        getBranch();
        getSections();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_machine: branchMachineId,
            ...{...formValues}
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Machine/Update");

        if (respuesta.code === 200) {
            getBranchMachine();
            reset();
            createSweet("update", "info", "Exito!", "Se actualizo la asignación");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const handleDelete = (id_machine) => {

        let machine = branchMachine.find( m => m.id_machine === parseInt(id_machine) );

        handleUpdateValues({
            serial_number:  machine.name_machine_model+' '+machine.serial_number
        });

        setBranchMachineId(id_machine);
        setMethod("delete");
        setModalTitle(ModalDeleteBranchMachine);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Machine/${ branchMachineId }`);

        if (respuesta.code === 200) {
            getBranchMachine();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Asignación eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            setLoading(false);

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);

            createSweet("error", "error", "Equipos", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);

            createSweet("error", "warning", "Equipos", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.id_machine_model === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_machine_model_valid: true
            }
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                id_machine_model_valid: false
            }
        }

        if (formValues.id_branch === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_branch_valid: true
            }
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                id_branch_valid: false
            }
        }

        if (formValues.id_section === 0) {
            newValidaciones = {
                ...newValidaciones,
                id_section_valid: true
            }
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                id_section_valid: false
            }
        }

        if (formValues.serial_number.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                serial_number_valid: true
            }
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                serial_number_valid: false
            }
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    const [machineData, setMachineData] = useState([])


    const handleSearchMachine = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        machineData.forEach(element => {            

            let cadena = element.name_machine_model.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setBranchMachine(newMethod);
    }



    return {
        branchMachine, machines, branches, sections,
        method, handleInputChange, validaciones,
        id_machine_model, id_branch, id_section, serial_number,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchMachine

    }
}
