import React from 'react'
import { Tooltip } from 'reactstrap';
import { useState } from 'react';


export const TooltpTable = ({obj,idTool,isInvoice}) => {

  if (isInvoice !== undefined) {
    //////console.log(isInvoice);

  }



    const [tooltip3, setTooltip3] = useState(false);
    const [tooltipRelease3, setTooltipRelease3] = useState(false);

    const toggleTooltip3 = () => setTooltip3(!tooltip3);
    const toggleTooltipRelease3 = () => setTooltipRelease3(!tooltipRelease3);


  return (
    <Tooltip
          placement={"top"}
           isOpen={tooltipRelease3}
         target={"tooltip-"+obj.name + idTool}
           toggle={toggleTooltipRelease3}
         >
          {
            isInvoice === true ? (obj.tooltipInvoice) : (obj.tooltip)
          }


        
     </Tooltip>
  )
}
