import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';


export const RangeForm = (props) => {
    const {
        activeTab, isSimple, id_type_analyte, replace,
        handleInputChange, handleFullModal, modalFullRef
    } = props;
    return (
        <>
            <FormGroup className={`mb-3 ${isSimple ? "col-3" : "col-12"} ${(id_type_analyte === "4" || id_type_analyte === "5") ? "" : "d-none"}`}>
                <Label className="text-sm">Reemplazar</Label>
                <Input
                    className="form-control form-control-sm input-air-primary" 
                    disabled={activeTab === 2}
                    bsSize="sm"
                    type="select"
                    name="replace"
                    value={replace}
                    onChange={handleInputChange}
                    required={true}
                >
                    <option value={false}>No</option>
                    <option value={true}>Si</option>
                </Input>
            </FormGroup> <br /> <br />
            <FormGroup className={`text-right mb-3 col-12 ${(id_type_analyte === "1" || id_type_analyte === "2" || id_type_analyte === "3" || id_type_analyte === "4" || id_type_analyte === "5" || id_type_analyte === "6" || id_type_analyte === "7") ? "" : "d-none"}`}>
          <br /> <br />      <span style={{ cursor: "pointer" }} className="link text-sm text-primary badge badge-light-primary f-12" ref={modalFullRef} onClick={() => handleFullModal("modalRangos")}>Valores de referencia</span>
            </FormGroup>
        </>
    )
}
