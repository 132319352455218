import React, { useState } from 'react'
import { Button, ButtonGroup, Col, Form, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from 'reactstrap';
import DeniReactTreeView from 'deni-react-treeview';
import { CompleteInput } from '../../constant';
import classnames from "classnames";
import Select from 'react-select';

export const FormCommonDiscounts = (props) => {

    const [tabTest, setTabTest] = useState(1);

    const toggleTab = tab => {
        if (tabTest !== tab) setTabTest(tab);
    }

    const txtSection = localStorage.getItem("TextAS")

    const {
        method, toggleModal, loading,
        id_gender,  name, code, valid_from, valid_to, days_of_week,
        percentage, auto_redeem, accumulative, percentage_global, day_from,
        day_to, list_exams_includes, list_exams_excludes, list_profiles_includes,
        readOnly, button,

        treeViewRef,

        listBranchesTree, listSections, listExamsFiltered, listProfiles,
        
        handleInputChange, handleChangeSections, handleSelectExamsIncludes,
        handleChangePercentage, handleRemoveTest, handleSelectProfilesIncludes,
        handleCheckDaysOfWeek, saveCommonDiscount,

        validationsCommonDiscount
    } = props;

    
    const {
        name_valid,
        code_valid,
        valid_from_valid,
        valid_to_valid,
        days_of_week_valid,
        percentage_valid,
        day_from_valid,
        day_to_valid,
        branch_tree_valid,
    } = validationsCommonDiscount;

    return (
        <>
            <ModalBody>
                <Form role='form'>
                    <Row>
                        <Col sm="7" md="7" lg="7">
                            <FormGroup>
                                <Label className='col-form-label'>Nombre de la campaña <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='text' autoComplete='off' name='name' value={name} onChange={handleInputChange} className='form-control input-air-primary b-r-9' invalid={name_valid}/>
                                <FormFeedback>{ CompleteInput }</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Código <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='text' autoComplete='off' name='code' value={code} onChange={handleInputChange} className='form-control input-air-primary b-r-9' invalid={code_valid}/>
                                <FormFeedback>{ CompleteInput }</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="2" md="2" lg="2">
                            <FormGroup>
                                <Label className='col-form-label'>¿Campaña autoaplicable? </Label>
                                <br /> <br />
                                <Label className='switch'>
                                    <Input type='checkbox' name="auto_redeem" checked={auto_redeem} onChange={handleInputChange} />
                                    <span className='slider round'></span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Valida desde <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='date' autoComplete='off' name='valid_from' value={valid_from} onChange={handleInputChange} invalid={valid_from_valid} />
                                <FormFeedback>Selecciona una fecha</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Valida hasta <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input type='date' autoComplete='off' name='valid_to' value={valid_to} onChange={handleInputChange} className='form-control input-air-primary b-r-9' invalid={valid_to_valid} />
                                <FormFeedback>Selecciona un fecha</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col sm="3" md="3" lg="3">
                            <FormGroup>
                                <Label className='col-form-label'>Días de la semana valido <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br />
                                <ButtonGroup className='w-100'>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(1)} outline={ days_of_week.find(d => d === 1) !== undefined ? false : true  } >LU</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(2)} outline={ days_of_week.find(d => d === 2) !== undefined ? false : true  } >MA</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(3)} outline={ days_of_week.find(d => d === 3) !== undefined ? false : true  } >MI</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(4)} outline={ days_of_week.find(d => d === 4) !== undefined ? false : true  } >JU</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(5)} outline={ days_of_week.find(d => d === 5) !== undefined ? false : true  } >VI</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(6)} outline={ days_of_week.find(d => d === 6) !== undefined ? false : true  } >SA</Button>
                                    <Button type='button' onClick={() => handleCheckDaysOfWeek(0)} outline={ days_of_week.find(d => d === 0) !== undefined ? false : true  } >DO</Button>
                                </ButtonGroup>
                                <FormText color='danger' hidden={!days_of_week_valid}>Selecciona al menos un día</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <Row className='mt-1'>
                        <Col sm="6" md="6" lg="6">
                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Condiciones para la campaña</span>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Razón social y Sucursales</span>
                        </Col>
                    </Row>  
                    <Row className='mt-1'>
                        <Col sm="6" md="6" lg="6">
                            <Row>
                                <Col sm="4" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Genero <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Input type='select' name='id_gender' value={id_gender} onChange={handleInputChange} className='form-control input-air-primary b-r-9' readOnly={readOnly}>
                                            <option value={0}>Indistinto</option>
                                            <option value={1}>Femenino</option>
                                            <option value={2}>Masculino</option>
                                        </Input>
                                        <FormFeedback>{ CompleteInput }</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="4" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Edad inicial <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <InputGroup>
                                            <Input type='number' step={1} autoComplete='off' name='day_from' value={day_from} onChange={handleInputChange} className='form-control input-air-primary b-r-9' readOnly={readOnly} invalid={day_from_valid}/>
                                            <InputGroupAddon addonType='append'>
                                                <InputGroupText>{"año(s)"}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <FormFeedback>{ CompleteInput }</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="4" md="4" lg="4">
                                    <FormGroup>
                                        <Label className='col-form-label'>Edad final <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <InputGroup>
                                            <Input type='number' step={1} autoComplete='off' name='day_to' value={day_to} onChange={handleInputChange} className='form-control input-air-primary b-r-9' readOnly={readOnly} invalid={day_to_valid}/>
                                            <InputGroupAddon addonType='append'>
                                                <InputGroupText>{"año(s)"}</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <FormFeedback>{ CompleteInput }</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row className='mt-1'>
                                <Col sm="12" md="12" lg="12">
                                    <span className='link text-sm text-primary badge badge-light-primary f-12'>Condiciones de descuento</span>
                                </Col>
                            </Row>  
                            <Row>
                                <Col sm="5" md="5" lg="5">
                                    <FormGroup>
                                        <Label className='col-form-label'>¿Descuento individual por Examen/Perfil?</Label>
                                        <br /> <br />
                                        <Label className='switch'>
                                            <Input type='checkbox' name="percentage_global" checked={percentage_global} onChange={handleInputChange} />
                                            <span className='slider round'></span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                {
                                    percentage_global 
                                    ? 
                                        <Col sm="7" md="7" lg="7" className='p-2'>
                                            <span>Cada examen o perfil, debe tener su descuento, <b>debes seleccionar minímo un perfil o examen.</b></span>                                        
                                        </Col>
                                    :
                                        <>
                                            <Col sm="7" md="7" lg="7">
                                                <Row>
                                                    <Col sm="12" md="12" lg="12">
                                                        <span>Esté porcentaje se aplicara a todos los exámenes y perfiles seleccionados.</span>
                                                    </Col>
                                                    <Col sm="12" md="12" lg="12">
                                                        <FormGroup>
                                                            <Label className='col-form-label'>Porcentaje de descuento <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                                            <Input type='number' name='percentage' value={percentage} onChange={handleInputChange} className='form-control input-air-primary b-r-9' readOnly={readOnly} invalid={percentage_valid}/>
                                                            <FormFeedback>{ CompleteInput }</FormFeedback>
                                                        </FormGroup>
                                                    </Col> 
                                                </Row>
                                            </Col>                                           
                                        </>
                                }
                            </Row>
                        </Col>
                        <Col sm="6" md="6" lg="6">                            
                            <DeniReactTreeView
                                className="w-100"
                                ref={treeViewRef}
                                style={{ marginRight: '10px', marginBottom: '10px', height: '240px' }}
                                showCheckbox={readOnly ? false : true}
                                showIcon={false}
                                items={listBranchesTree}
                                selectedRow={false}
                            />
                            <FormText color='danger' className='f-14' hidden={!branch_tree_valid}>Selecciona al menos una sucursal</FormText>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col sm="12" md="12" lg="!2">
                            <div className='nav-wrapper'>
                                <Nav className='nav-fill flex-column flex-md-row' id='tabs-icon-text' pills role='tablist'>
                                    <NavItem>
                                        <NavLink   
                                            aria-selected={tabTest === 1}
                                            href='#' role='tab'
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: tabTest === 1
                                            })}
                                            onClick={() => toggleTab(1)}
                                        >
                                            Exámenes
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink   
                                            aria-selected={tabTest === 2}
                                            href='#' role='tab'
                                            className={classnames("mb-sm-3 mb-md-0", {
                                                active: tabTest === 2
                                            })}
                                            onClick={() => toggleTab(2)}
                                        >
                                            Perfiles
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={"tabs" + tabTest}>
                                <TabPane tabId={"tabs1"} className='col-md-12 p-0'>
                                    <Row className='mt-2'>
                                        <Col sm="12" md="12" lg="12">
                                            <span className='link text-sm text-primary badge badge-light-primary f-12'>{ txtSection } disponibles para agregar</span>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm="12" md="12" lg="12" className='checkbox-primary text-justify'>
                                            <Row className='pl-3 pr-3'>
                                                {
                                                    listSections.length > 0 && listSections.map(_section => {
                                                        return <>
                                                            <Col sm="3" md="3" lg="3" className={ _section.check ? "border-1 b-b-info rounded-bottom" : "" }>
                                                                <div key={_section.id_section} > 
                                                                    <Input type='checkbox' readOnly={readOnly} id={`checkbox-${_section.id_section}`} checked={_section.check}
                                                                        onChange={readOnly ? null : (e) => handleChangeSections(e, _section.id_section)}
                                                                    />
                                                                    <Label for={`checkbox-${_section.id_section}`}>{ _section.name }</Label>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className='mt-1'>
                                        <Col sm="6" md="6" lg="6">
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label f-16'>Exámenes aplicables para el descuento</Label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            options={listExamsFiltered}
                                                            placeholder="Selecciona un examen"
                                                            isClearable={false}
                                                            isDisabled={readOnly}
                                                            onChange={(e) => handleSelectExamsIncludes(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className='mt-1'>
                                                <Col sm="12" md="12" lg="!2">
                                                    {
                                                        list_exams_includes.length > 0
                                                        ? <h6>Listado de exámenes incluidos en la promoción</h6>
                                                        : (percentage_global)
                                                            ? <h6 className='text-danger'>Selecciona minímo una opción</h6>
                                                            : <h6>Todos los exámenes entran en la promoción</h6>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <Table striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Descripción</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list_exams_includes.length > 0 && list_exams_includes.map((_exam, _indexExam) => {
                                                                    return <tr key={ "trExam-" + _exam.id_exam}>
                                                                        <td><b> { (_indexExam + 1 ) + ".-   " + _exam.name }</b></td>
                                                                        <td className='text-right'>
                                                                            <InputGroup size='sm'>
                                                                                {
                                                                                    percentage_global && <Input
                                                                                        className='form-control input-air-primary b-r-9'
                                                                                        type='number'
                                                                                        readOnly={readOnly}
                                                                                        min={1}
                                                                                        max={20}
                                                                                        value={_exam.percentage}
                                                                                        onChange={(e) => handleChangePercentage(e, _exam.id_exam)}
                                                                                    />
                                                                                }
                                                                                <InputGroupAddon addonType='append'>
                                                                                    {
                                                                                        percentage_global && <InputGroupText>{"%"}</InputGroupText>
                                                                                    }
                                                                                    {
                                                                                        !readOnly && <InputGroupText
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() => handleRemoveTest(true, _exam.id_exam)}
                                                                                        >
                                                                                            <i className='fa fa-trash text-danger'></i>
                                                                                        </InputGroupText>
                                                                                    }
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Col>                                        
                                        <Col sm="6" md="6" lg="6">
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label f-16'>Exámenes que <b>NO</b> aplican para el descuento</Label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            options={listExamsFiltered}
                                                            placeholder="Selecciona un examen"
                                                            isClearable={false}
                                                            isDisabled={readOnly}
                                                            onChange={(e) => handleSelectExamsIncludes(e, false)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className='mt-1'>
                                                <Col sm="12" md="12" lg="!2">
                                                    {
                                                        list_exams_excludes.length > 0
                                                        ? <h6>Estos exámenes no se incluiran dentro del descuento</h6>
                                                        : <h6>Ningún examen esta fuera del descuento</h6>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <Table striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-center'>Descripción</th>
                                                                <th className='text-center'>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list_exams_excludes.length > 0 && list_exams_excludes.map((_examE, _indexExamE) => {
                                                                    return <tr key={ "trExam-" + _examE.id_exam}>
                                                                        <td><b> { (_indexExamE + 1 ) + ".-   " + _examE.name }</b></td>
                                                                        <td className='text-right'>
                                                                            <InputGroup size='sm'>                                                                                
                                                                                <InputGroupAddon addonType='append'>
                                                                                    {
                                                                                        !readOnly && <InputGroupText
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() => handleRemoveTest(false, _examE.id_exam)}
                                                                                        >
                                                                                            <i className='fa fa-trash text-danger'></i>
                                                                                        </InputGroupText>
                                                                                    }
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Col>                                        
                                    </Row>                                                                      
                                </TabPane>
                                <TabPane tabId={"tabs2"} className='col-md-12 p-0'>
                                    <Row className='mt-2'>
                                        <Col sm="12" md="12" lg="12">
                                            <span className='link text-sm text-primary badge badge-light-primary f-12'>Perfiles disponibles para agregar</span>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className='mt-1'>
                                        <Col sm="12" md="12" lg="12">
                                            <Row>
                                                <Col sm="12" md="12" lg="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label f-16'>Perfiles aplicables para el descuento</Label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            options={listProfiles}
                                                            placeholder="Selecciona un perfil"
                                                            isClearable={false}
                                                            isDisabled={readOnly}
                                                            onChange={(e) => handleSelectProfilesIncludes(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className='mt-1'>
                                                <Col sm="12" md="12" lg="12">
                                                    <Table bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Descripción</th>
                                                                <th style={{ width: "200px" }}>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                list_profiles_includes.length > 0 && list_profiles_includes.map((_profile, _indexProfile) => {
                                                                    return <>                                                                    
                                                                        <tr key={'trProfile-'+ _profile.id_profile}>
                                                                            <td>
                                                                                <b>{ (_indexProfile + 1) + ".- " + _profile.name }</b>
                                                                            </td>
                                                                            <td style={{ width: "200px" }}>
                                                                                <InputGroup size='sm'>
                                                                                    
                                                                                    {
                                                                                        (percentage_global && !_profile.is_curve) && <InputGroupAddon addonType='prepend'>
                                                                                             <InputGroupText>%</InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                    }                                                                                    
                                                                                    {
                                                                                        (percentage_global && !_profile.is_curve) && <Input
                                                                                            type='number' className="form-control input-air-primary" readOnly={readOnly}
                                                                                            min={1} max={20} value={_profile.percentage}
                                                                                            onChange={(e) => handleChangePercentage(e, null, _profile.id_profile)}
                                                                                        />
                                                                                    }
                                                                                    {
                                                                                        !readOnly && <InputGroupAddon addonType='prepend'>
                                                                                            <InputGroupText style={{ cursor: "pointer" }} onClick={() => handleRemoveTest(false, null, _profile.id_profile)}><i className='fa fa-trash text-danger'></i></InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                    }
                                                                                </InputGroup>                                                                              
                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                           ( _profile.is_curve && _profile.list_curves.length > 0) && _profile.list_curves.map((_curve, _indexCurve) => {
                                                                                return <tr key={'trProfile-curve-'+ _profile.id_profile}>
                                                                                    <td className='pl-5'> 
                                                                                        <b>{ (_indexCurve + 1) + ".-  Curva de " + _curve.number_sampling + " tiempos" }</b>
                                                                                    </td>
                                                                                    <td style={{ width: "200px" }}>
                                                                                        <InputGroup size='sm'>
                                                                                            {
                                                                                                percentage_global && <Input
                                                                                                    className='form-control input-air-primary'
                                                                                                    type='number'
                                                                                                    readOnly={readOnly}
                                                                                                    min={1}
                                                                                                    max={20}
                                                                                                    value={_curve.percentage}
                                                                                                    onChange={(e) => handleChangePercentage(e, null, _profile.id_profile, _indexCurve)}
                                                                                                />
                                                                                            }
                                                                                            <InputGroupAddon addonType='append'>
                                                                                                {
                                                                                                    percentage_global && <InputGroupText>{"%"}</InputGroupText>
                                                                                                }                                                                                                
                                                                                            </InputGroupAddon>
                                                                                        </InputGroup>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>  
                </Form>
            </ModalBody>
            <ModalFooter>
                {
                    !readOnly &&
                    <Button onClick={() => saveCommonDiscount(method)}
                        outline color='primary' type='button' className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                    >
                        {button}
                    </Button>
                }
                <Button outline color='danger' type='button' onClick={toggleModal}>
                    {
                        readOnly ? "Cerrar visualización" : "Cancelar"
                    }
                </Button>
            </ModalFooter>
        </>
    )
}
