import React from 'react'
import { Col, Form, FormGroup, FormText, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const FormRack = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, code, slots, button
    } = props;

    const {
        name_valid, code_valid, slots_valid,
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Nombre <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                autoComplete="off"
                                name="name" value={name}
                                invalid={name_valid}
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Código <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                autoComplete="off"
                                name="code" value={code}
                                invalid={code_valid}
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Debes llenar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label className="col-form-label">Espacios <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="number"
                                autoComplete="off"
                                name="slots" value={slots}
                                invalid={slots_valid}
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Debe ser mayor a 1</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
