import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';


export const usePowerBi = () => {

    const history = useHistory();

    const [dataLink, setDataLink] = useState([]);
    const [prueba, setPrueba] = useState([]);
    const [ createSweet ] = useSweetAlert();


    useEffect(() => {
        handleGetSpecialties();
    }, []);


    const handleGetSpecialties = async () => {
        let listTemp = await handleRequest("GET", `WorkOrder/DetailPbi`, 'Pbi');
        let listSpecial = [];

        if (listTemp.length >=1) {
            listTemp.forEach(element => {
                listSpecial.push({
                    link: element.link
                }) 
            });   
        }
        setDataLink(listSpecial)
        setPrueba(listSpecial)
    }

   

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    

    return {
        dataLink
    }
}
