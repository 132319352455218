import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteUnitConversion } from './DeleteUnitConversion';
import { FormUnitConversion } from './FormUnitConversion';


export const ModalUnitConversion = (props) => {

    const { modal, toggle, loading, method, units, id_unit_from, id_unit_to, conversion_factor, handleInputChange, saveCreate, saveUpdate, saveDelete } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary">
                {
                    method === "create" ?
                        "Creación de unidades"
                        : (method === "update") ?
                            "Actualización de unidad"
                            : "Eliminar unidad"
                }
            </ModalHeader>
            {
                method === "create" ?
                    <FormUnitConversion button="Crear nueva unidad" {...{ toggle, loading, units, id_unit_from, id_unit_to, conversion_factor, handleInputChange, methodAction: saveCreate }} />
                    : (method === "update") ?
                        <FormUnitConversion button="Actualizar unidad" {...{ toggle, loading, units, id_unit_from, id_unit_to, conversion_factor, handleInputChange, methodAction: saveUpdate }} />
                        : <DeleteUnitConversion {...{ toggle, loading, saveDelete }} />
            }
        </Modal>
    )
}
