import React from 'react'
import Select from 'react-select';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormSampling = (props) => {
    const {
        samplingItems,
        toggle, loading,
        name, code, items,
        handleInputChange, handleSelectValues,
        validaciones, methodAction, button
    } = props;
    const {
        name: validacionName,
        code: validacionCode,
        items: validacionItems
    } = validaciones;


    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <FormGroup className="mb-3 col-8">
                        <Label className="col-form-label" >Nombre <span style={{ color: "#ff0000" }}>*</span></Label>
                        <Input
                           // placeholder="Nombre"
                            type="text"
                            name={"name"}
                            value={name}
                            required={true}
                            autoComplete="nope"
                            onChange={handleInputChange}
                            invalid={validacionName}
                            className="form-control form-control-sm input-air-primary" 

                           // className="small-font-size"
                        />
                        <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-4">
                        <Label className="col-form-label" >Código <span style={{ color: "#ff0000" }}>*</span></Label>
                        <Input
                           //placeholder="Código"
                            type="text"
                            name={"code"}
                            value={code}
                            required={true}
                            autoComplete="nope"
                            onChange={handleInputChange}
                            invalid={validacionCode}
                            className="form-control form-control-sm input-air-primary" 
                        />
                        <FormFeedback>El código debe tener 3 o más caracteres</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mb-3 col-12">
                        <Label className="col-form-label" >Insumos <span style={{ color: "#ff0000" }}>*</span></Label>
                        <Select
                            // defaultValue={id_machine_models}
                            isMulti
                            name="items"
                            onChange={(e) => handleSelectValues(e, "items")}
                            options={samplingItems}
                            //className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />
                        <FormFeedback className='pt-2' style={{ display: (validacionItems ? 'block' : 'none') }}>Debes seleccionar minimo un insumo</FormFeedback>
                    </FormGroup>
                    <div>
                        {
                            items.map(element => {
                                return <div key={element.value} style={{ height: element.label.length * .3 }}>
                                    &nbsp;
                                </div>
                            })
                        }
                    </div>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    size="sm"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
