import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { usePantalla1 } from './usePantalla1';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import moment from 'moment';
import { useForm } from '../forms/useForm';



export const useConsentimiento = () => {

    const history = useHistory();
    const { nimm } = useParams();

    const [viewTab, setViewTab] = useState(1);
    const [respuestas, setRespuestas] = useState([]);
    const [saveImg, setsaveImg] = useState("");
    const [iniImg, setIniImg] = useState("");//guardar una copia de la firma del cliente
    const [dateInicial, setDateInicial] = useState("");
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate] = useSweetAlert();
    const [viewButtonsCreate, setViewButtonsCreate] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isActive, setIsActive] = useState(true); //si lo declaro en true inicia automaticamente, si es false espera el click del button
    const [hour, setHour] = useState('00');
    const [second, setSecond] = useState('00');
    const [minute, setMinute] = useState('00');
    const [difHourBrakfast, setDifHourBrakfast] = useState('00');
    const [difMinuteBrakfast, setDifMinuteBrakfast] = useState('00');
    const [viewButtonFirma, setViewButtonFirma] = useState(false);
    const [alterQuestion, setAlterQuestion] = useState({ tab: 0, response: "" });
    const [typeModalCancell, setTypeModalCancell] = useState("Cancell1");
    const [modalCancell, setModalCancell] = useState(false);
    const [reason, setReason] = useState([]);
    const [typeModalRefused, setTypeModalRefused] = useState("refusedSample");
    const [modalRefused, setModalRefused] = useState(false);

    const { fechaYHora } = usePantalla1();

    useEffect(() => {
        handleReason();
    }, []);

    //DETENER EL CRONOMETRO
    // const stopTimer = () => {
    //     setIsActive(false);
    //     setCounter(0);
    //     setSecond('00');
    //     setMinute('00');
    // }

    //#region TIME METHOD
    useEffect(() => {

        let interValid;

        if (isActive) {

            interValid = setInterval(() => {

                let actual = new Date();
                let prueba = new Date(localStorage.getItem('timeInit'));
                let tiempoPasado = (actual - prueba);

                let segs = 1000;
                let mins = segs * 60;
                let hours = mins * 60;

                let horas = Math.floor(tiempoPasado / hours);

                tiempoPasado = tiempoPasado - (horas * hours);
                let minutos = Math.floor(tiempoPasado / mins);

                tiempoPasado = tiempoPasado - (minutos * mins);
                let segundos = Math.floor(tiempoPasado / segs);

                const computedSecond = String(segundos).length === 1 ? `0${segundos}` : segundos;
                const computedMinute = String(minutos).length === 1 ? `0${minutos}` : minutos;
                const computedHour = String(horas).length === 1 ? `0${horas}` : horas;

                setHour(computedHour);
                setMinute(computedMinute);
                setSecond(computedSecond);
                setCounter(counter => counter + 1);
            }, 1000);
        }
        return () => clearInterval(interValid);

    }, [history.location, counter]);



    useEffect(() => {
        var hoy = new Date();
        var fecha = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
        var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
        setDateInicial(fecha + ' ' + hora);
        let copy = dateInicial

        if (copy == "") {
            copy = copy
        }

    }, [dateInicial])
    //#endregion TIME METHOD

    //#region NAVIGATE CONSENTIMIENTO
    const navigaConsentimientoInformado1 = (_nimm) => {
        //  ////console.log("pasa aqui");
        //  ////console.log(_nimm);

        let nim = _nimm.substring(0, 13)
        let _consent = localStorage.getItem("consentimientoInformado");

        if (_consent === "true") {
            history.push('../concentimiento/' + nim);
        }
        else {
            history.push('../sampling/' + nim);
        }
    }

    const navigateSampling = (_nimm) => {
        history.push('../sampling/' + _nimm);
    }

    const navigateInicio = () => {
        history.push('/dashboard/defaultTomaDeMuestra');
    }
    //#endregion NAVIGATE CONSENTIMIENTO

    //#region Modales
    const toggleCancell1 = () => {
        setModalCancell(!modalCancell);
    }

    const handleModalCancell1 = () => {
        setTypeModalCancell("Cancell1");
        toggleCancell1();
    }

    const toggleRefused = () => {
        setModalRefused(!modalRefused);
    }

    const handleRefusedSample = () => {
        setTypeModalRefused("refusedSample");
        toggleRefused()
    }
    //#endregion Modales

    //Form
    const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
        id_cancellation_reazon_sample: 0,
    });

    const { id_cancellation_reazon_sample, } = formR;

    const [validationsSG, setvalidationsSG] = useState({
        id_return_reasonV: false
    });
    //#region SAMPLE QUESTION
    const navigatePrev = (tabQuestion) => {
        let _tab = viewTab;

        _tab = _tab - 1;

        if (respuestas[_tab - 1] !== undefined) {
            let _responses = respuestas[_tab - 1];
            //5 y 8   anteriormete se encontraba la pregunta 4 || 7 
            if (_tab == 5 || _tab == 8) {
                setAlterQuestion({
                    tab: _tab,
                    response: _responses
                });
            }
            else {
                _responses = _responses.split('-');
                setAlterQuestion({
                    tab: _tab,
                    response: _responses[0]
                });
            }//datos mostrados
        }
        setViewTab(_tab);
    }

    const navigateNext = (tabQuestion) => {
        let _tab = viewTab;

        _tab = _tab + 1;

        if (respuestas[_tab - 1] !== undefined) {
            let _responses = respuestas[_tab - 1];

            if (_tab == 5 || _tab == 8) {
                setAlterQuestion({
                    tab: _tab,
                    response: _responses
                });
            }
            else {
                _responses = _responses.split('-');
                setAlterQuestion({
                    tab: _tab,
                    response: _responses[0]
                });
            }
        }
        setViewTab(_tab);
    }

    const saveResponseQuestion = (tabQuestion, response, multiple, nextNavigate, calculateTime = 0) => {

        if (calculateTime == 1) {

            let _fechaActual = moment().format("YYYY-MM-DD");
            let _arrResponse = response.split(':');
            let _diaAnterior = "";
            let h = parseInt(_arrResponse[0]);
            let intHour = parseInt(new Date().getHours());

            if (intHour >= h && 0 <= h) {
                _diaAnterior = _fechaActual + " " + (_arrResponse[0] == '00' ? "00" : _arrResponse[0]) + ":" + _arrResponse[1];
            }
            else {
                _diaAnterior = moment().add(-1, 'd').format('YYYY-MM-DD');
                _diaAnterior = _diaAnterior + ' ' + (_arrResponse[0] == '00' ? "00" : _arrResponse[0]) + ":" + _arrResponse[1];
            }

            let h1 = moment();
            let h2 = moment(_diaAnterior).format("YYYY-MM-DD kk:mm:ss");
            let dif = h1.diff(h2);
            let segs = 1000;
            let mins = segs * 60;
            let hours = mins * 60;
            let horas = Math.floor(dif / hours);

            dif = dif - (horas * hours);
            let minutos = Math.floor(dif / mins);
            const cMinute = String(minutos).length === 1 ? `0${minutos}` : minutos;
            const cHour = String(horas).length === 1 ? `0${horas}` : horas;
            setDifHourBrakfast(cHour);
            setDifMinuteBrakfast(cMinute);
        }
        //question

        let _copy = respuestas;

        if (_copy.length == 0) {
            setViewButtonsCreate(true);
            _copy.push(response);
        }
        else if (_copy[tabQuestion - 1] == undefined) {
            _copy.push(response);
        }
        else {
            let _responses = _copy[tabQuestion - 1];

            if (multiple == 1) {
                if ((tabQuestion === 5 || tabQuestion === 8) && response === "Ninguno") {
                    _responses = response;
                }
                else {
                    if (_responses === "Ninguno") {
                        _responses = "";
                    }
                    _responses = _responses + "-" + response;
                }
            }
            else {
                _responses = response;
            }
            _copy[tabQuestion - 1] = _responses;
        }
        setRespuestas([..._copy]);

        if (tabQuestion == 5 || tabQuestion == 8) {
            let responses = respuestas[tabQuestion - 1];

            setAlterQuestion({
                tab: tabQuestion,
                response: responses
            });
        }
        if (nextNavigate == 1) {
            navigateNext(tabQuestion);
        }
    }
    //#endregion SAMPLE QUESTION    


    //#region FIRM 
    const handleChange = (myRef) => {
        const Draw = myRef.current;
        const data = Draw.getSaveData(); //imagen guardada
        setIniImg(data);
        setsaveImg(Draw.getDataURL('png'))
    }

    const repeatFirm = (myRef) => {
        const Draw = myRef.current;
        Draw.eraseAll();
        setsaveImg("");
    }
    //#endregion FIRM


    //#region SAVE SAMPLE QUESTION
    const saveUpdateCiSample = async () => {

        let _newForm = {
            nim: nimm.substring(0, 14),
            time_init: dateInicial,
            time_end: fechaYHora,
            cancellation: "",
            testCI: respuestas,
            imageFirm: saveImg,
        }
        ////console.log(_newForm);

        let listTmp = await handleRequest("POST", "Sample/UpdateCiSample", "Sample", _newForm);
        if (listTmp !== null) {
            history.push('../sampling/' + nimm);
        }
    }

    const saveUpdateCancell = async () => {

        let _newForm = {
            nim: nimm.substring(0, 14),
            time_init: null,
            time_end: null,
            cancellation: 0,
            testCI: [],
            imageFirm: "",
        }

        let listTmp = await handleRequest("POST", "Sample/UpdateCiSample", "Sample", _newForm);
        if (listTmp !== null) {
            navigateInicio();
        }
    }
    //#endregion SAVE SAMPLE QUESTION

    //#regionCancell sample
    const handleReason = async () => {
        let _listTmp = await handleRequest("GET", `CancellationReazonSample/List`, 'sample');

        if (_listTmp !== null) {
            let _list = [];

            _listTmp.forEach((_find, i) => {
                _list.push({
                    value: _find.id_cancellation_reazon_sample,
                    label: _find.cancellation_reazon
                });
            });
            setReason([..._list]);
        }
    }

    const refusedPatient = async () => {

        if (validationRefused()) {
            return;
        }
        let id_return_reason2 = formR.id_cancellation_reazon_sample.value

        let _newForm = {
            nim: nimm.substring(0, 14),
            time_init: null,
            time_end: null,
            cancellation: id_return_reason2,
            testCI: [],
            imageFirm: "",
        }

        let listTmp = await handleRequest("POST", "Sample/UpdateCiSample", "Sample", _newForm);
        if (listTmp !== null) {
            navigateInicio();
        }
    }

    //#region Detail Patient
    const [typeModalPatient, setTypeModalPatient] = useState("Detail");
    const [modalPatient, setModalPatient] = useState(false);

    const toggleDetailPatient = () => {
        setModalPatient(!modalPatient);
    }

    const handlePatient = (nim) => {
        setTypeModalPatient("Detail");
        toggleDetailPatient()

    }


    //#endregion Detail Patient

    const validationRefused = () => {
        let _statusValidation = false;
        let _newValidations = {};
        let _id_return_reason2 = formR.id_cancellation_reazon_sample.value

        if (_id_return_reason2 === undefined) {
            _newValidations = {
                ..._newValidations,
                id_return_reasonV: true,
            };
            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_return_reasonV: false,
            };
        }
        setvalidationsSG(_newValidations);
        return _statusValidation;
    }


    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 409) {
            createSweett("", "Falto responder  una pregunta", "warning");
        }
        else {
        }
        return null;
    }

    return {
        navigaConsentimientoInformado1, navigateSampling, viewTab, navigatePrev, navigateNext,
        saveResponseQuestion, handleChange, saveUpdateCiSample, viewButtonsCreate,
        hour, minute, second, isActive, setIsActive, iniImg,
        difHourBrakfast, difMinuteBrakfast, saveUpdateCancell, viewButtonFirma, alterQuestion,
        typeModalCancell, modalCancell, toggleCancell1, handleModalCancell1, repeatFirm,
        //refused Sample
        handleSelectValuesR, id_cancellation_reazon_sample, reason, typeModalRefused, modalRefused,
        toggleRefused, handleRefusedSample, refusedPatient, validationsSG,
        //Detail Patient
        typeModalPatient, modalPatient, toggleDetailPatient, handlePatient
    }
}


