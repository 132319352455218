import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useMicTypeSample = () => {

    const [typeSamples, setTypeSamples] = useState([]);
    const [typeSampleId, setTypeSampleId] = useState(0);
    const [dataTypeSample, setDataTypeSample] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle,setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [formValues,handleInputChange, reset, handleUpdateValues,] = useForm({
        name:"",
        abbreviation:"",
    });
    const [validaciones, setValidaciones] = useState({
        name_valid:false,
        abbreviation_valid:false,
    })
    const {name, abbreviation, } = formValues;

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(typeSamples.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listTypeSample = [];
        typeSamples.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTypeSample.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setTypeSamples(listTypeSample);
    }, [totalPageCount, currentPage])

    
    const toggle = ()=>{
        setModal(!modal);
    }
   
    useEffect(()=>{
        getTypeSamples();
    },[history.location]);


    const getTypeSamples = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "TypeSamples/List");

        if (respuesta.code === 200) {
            let listTypeSample = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach( (data,index) => {
                    
                let posicion = index + 1;
                let visibleTable = false;
                
                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }

                listTypeSample.push({
                        id_mic_type_sample: data.id_mic_type_sample,
                        name: data.name,
                        abbreviation:data.abbreviation,
                        visibleTable: visibleTable
                    });
                });
                setTypeSamples(listTypeSample);
                setDataTypeSample(listTypeSample);
            }
        }
    }

    
    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation:"",
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid:false,
        });

        setTypeSampleId(0);
        setMethod("create");
        setModalTitle("Crear nuevo tipo de muestra");
        toggle();
    }   

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "TypeSamples/Create");
        if (respuesta.code === 200) {
            getTypeSamples();
            createSweet("create", "success", "Éxito!", "Tipo de muestra creado con éxito");
            setLoading(false);
            toggle();
        }
    }

    const handleUpdate = (id_mic_type_sample) => {
        let type = typeSamples.find(s => s.id_mic_type_sample === id_mic_type_sample);

        handleUpdateValues({
            name: type.name,
            abbreviation: type.abbreviation,
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false,
        });

        setTypeSampleId(id_mic_type_sample);
        setMethod("update");
        setModalTitle("Modificación del tipo");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let body = {
            id_mic_type_sample: typeSampleId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);
        
        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "TypeSamples/Update");

        if (respuesta.code === 200) {
            getTypeSamples();
            createSweet("create", "info", "Éxito!", "Tipo actualizado");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
    }

    const handleDelete = (id_mic_type_sample) => {
        
        let type = typeSamples.find(s => s.id_mic_type_sample === id_mic_type_sample);

        handleUpdateValues({
            name: type.name,
            abbreviation: type.abbreviation,
        });

        setTypeSampleId(id_mic_type_sample);
        setMethod("delete");
        setModalTitle("eliminar");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `TypeSamples/${typeSampleId}`);

        if (respuesta.code === 200) {
            getTypeSamples();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Tipo eliminado");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
       

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name.length < 3) {
            newValidations = {
                ...newValidations,
                name_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_valid: false
            }
        }
        if (formValues.abbreviation === "" || formValues.abbreviation.length < 2) {
            newValidations = {
                ...newValidations,
                abbreviation_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                abbreviation_valid: false
            }
        }
        setValidaciones(newValidations);

        return statusValidation;
    }

    const handleSearchType = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        dataTypeSample.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setTypeSamples(newMethod);
    }

    return {
        typeSamples, name, abbreviation, validaciones,
        method, 
        handleInputChange, handleSearchType, loading, 
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
    }

}




