import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { useForm } from '../forms/useForm';
import moment from 'moment';
import { useEffect, useState } from 'react';


export const useWorkSheetsDay = () => {
    const history = useHistory();
    const sectionsDefault = [];

    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');

    const [workOrderExams, setWorkOrderExams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [createSweet] = useSweetAlert();


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues, handlePickerValues, handleDinamicInput, handleDate] = useForm({
        exam_select: null,
        date_search: moment().format("MM-DD-YYYY"),
        date_search_end: moment().format("MM-DD-YYYY"),
        checkTypePrint: "list",
        nim: ""
    });

    const [filterRangeNims, setFilterRangeNims] = useState({
        date_range_nim: new moment().format('YYYY/MM/DD'),
        branch_range_nim: "",
        init_range_nim: "0",
        end_range_nim: "1"
    });


    const txtsection = localStorage.getItem('TextAS');
    const { exam_select, date_search, date_search_end, checkTypePrint, nim } = formValues;

    useEffect(() => {
        getSections();
        getBranches();
        getExams();
    }, [])

    const getExamsCheckSpecimen = async () => {
        let fecha = date_search;
        if (typeof (date_search) !== "string") {
            fecha = moment(date_search._d).format("MM-DD-YYYY");
        }
        let fecha2 = date_search_end;
        if (typeof (date_search_end) !== "string") {
            fecha2 = moment(date_search_end._d).format("MM-DD-YYYY");
        }
        let id_exam = 0;
        if (exam_select !== null) {
            id_exam = exam_select.value;
        }

        let body = {
            date: fecha,
            date2: fecha2,
            "init_range_nim": filterRangeNims.init_range_nim,
            "end_range_nim": filterRangeNims.end_range_nim,
            id_section: 5,
            list_sections: sections.filter(x => x.check === true),
            id_exam: id_exam,
            type_print: checkTypePrint,
            list_branches: branches.filter(x => x.check === true),
            nim: nim,

        }

        ////console.log(body);
        let raw = JSON.stringify(body);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "WorkOrder/GetExamsCheckSpecimen");
        console.log(respuesta);
        ////console.log(respuesta);

        if (respuesta.code === 200) {
            ////console.log(respuesta.data);
            var groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

            let arreglo = groupBy(respuesta.data, 'nim');
            Object.entries(arreglo).map((obj, key) => {
            })
            setWorkOrderExams(arreglo);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getExamsCheckSpecimen);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Ingreso", "Ocurrio un error en el servidor");
        }
        else {
            createSweet("error", "warning", "Ingreso", respuesta.data.msg);
        }
    }


    //#region  Sections

    const [sections, setSections] = useState([]);
    const [sections2, setSections2] = useState([]);

    const [selectedSections, setselectedSections] = useState(null);
    const [sectiondefault, setSectiondefault] = useState(null);


    const getSections = async () => {

        let listTemp = await handleRequest("GET", "Section/List/false", "Secciones");

        if (listTemp !== null && listTemp.length > 0) {

            let secciones = [];
            let sectionmultiselect = [];
            let secciones_vinculadas = JSON.parse(localStorage.getItem("sections"));
            let secciones_guardadas = localStorage.getItem("filter_sections_sheet");
            if (secciones_guardadas !== undefined && secciones_guardadas !== null) {
                secciones_guardadas = JSON.parse(secciones_guardadas);
            }

            listTemp.forEach(({ id_section, name }) => {
                secciones_vinculadas.forEach(function (element, index, secciones_vinculadas) {
                    if (element == id_section) {
                        let checkAux = true;
                        if (secciones_guardadas !== undefined && secciones_guardadas !== null) {
                            let validacion = secciones_guardadas.find(x => x.id_section === id_section);
                            if (validacion !== undefined) {
                                checkAux = true;
                            }
                        }
                        secciones.push({
                            id_section: id_section,
                            name: name,
                            check: true,
                            label: name,
                            value: id_section
                        })
                        sectionmultiselect.push(
                            { name: name, code: id_section },
                        )
                    }
                });
            });
            setSections(secciones);
            setSectiondefault(sectionmultiselect);
            setselectedSections(sectionmultiselect);
            setSections2(secciones);
        }
    }

    //#endregion Sections


    //#region Branch and Exam
    const [branches, setBranches] = useState([]);
    const [exams, setExams] = useState([]);


    const getBranches = async () => {

        let listTemp = await handleRequest("GET", "Branch/List", "Sucursal");

        if (listTemp !== null && listTemp.length > 0) {
            let newBranches = [];

            listTemp.forEach((element) => {
                newBranches.push({
                    id_branch: element.id_branch,
                    value: element.id_branch,
                    label: element.name,
                    check: false
                });
            });
            setBranches(newBranches);
        }
    }

    const getExams = async () => {

        let listTemp = await handleRequest("GET", "Exam/List", "Examen");

        if (listTemp !== null && listTemp.length > 0) {
            let newArray = [];
            listTemp.results.forEach(element => {
                newArray.push({
                    label: element.abbreviation + " (" + element.code + ")",
                    value: element.id_exam,
                    id_section: element.id_section
                })
            });
            setExams(newArray);
        }
    }

    //#endregion Branch


    //#region  Filter
    const FilterData = async () => {
        if (sections.filter(x => x.check === true).length <= 0) {
            return createSweet("error", "error", "Selecciona mínimo una " + txtsection);
        }

        if (branches.filter(x => x.check === true).length <= 0) {
            return createSweet("error", "error", "Selecciona mínimo una sucursal");
        }

        if (checkTypePrint === "") {
            return createSweet("error", "error", "Selecciona un tipo de impresión");
        }

        setLoading(true);
        await getExamsCheckSpecimen();
        setLoading(false);
    }

    const downloadSheetWorkDay = async () => {
        setLoadingPdf(true);

        let fecha = date_search;
        if (typeof (date_search) !== "string") {
            fecha = moment(date_search._d).format("MM-DD-YYYY");
        }
        let fecha2 = date_search_end;
        if (typeof (date_search_end) !== "string") {
            fecha2 = moment(date_search_end._d).format("MM-DD-YYYY");
        }
        let token = localStorage.getItem("token");

        let id_exam = 0;
        if (exam_select !== null) {
            id_exam = exam_select.value;
        }

        let body = {
            date: fecha,
            date2: fecha2,
            "init_range_nim": filterRangeNims.init_range_nim,
            "end_range_nim": filterRangeNims.end_range_nim,
            id_section: 5,
            list_sections: sections.filter(x => x.check === true),
            id_exam: id_exam,
            checkTypePrint: checkTypePrint,
            id_commercial_line: id_commercial_line,
            id_branch: id_branch,
            list_branches: branches.filter(x => x.check === true)
        }
        let raw = JSON.stringify(body);
        await fetch("/api/WorkOrder/DownloadSheetWorkDay", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var iframe = document.createElement('iframe');
                iframe.className = 'pdfIframe'
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        URL.revokeObjectURL(url)
                    }, 1);
                };
                iframe.src = url;
            });
        setLoadingPdf(false);
    }

    const changeCheckbox = (e) => {
        let array = sections;
        ////console.log(e.value);
        let sectionfind = [];
        array.map(obj => {
            if (e.some(x => x.code === obj.id_section)) {
                sectionfind.push(
                    { name: obj.name, code: obj.id_section },
                )
            }
            return obj;
        });
        let status = true;
        array.map(obj => {
            if (e.some(x => x.code === obj.id_section)) {
                obj.check = true;
            } else {
                obj.check = false;
            }
            return obj;
        });

        let filter_sections = array.filter(x => x.check === true);
        localStorage.setItem("sectionfind", JSON.stringify(filter_sections));
        setselectedSections(sectionfind);
        setSections(array);
    }

    const changeCheckboxBranch = (e) => {

        let array = branches;

        array.map(obj => {
            if (e.some(x => x.value === obj.value)) {
                obj.check = true;
            } else {
                obj.check = false;
            }
            return obj;
        });

        setBranches(array);
    }

    //#endregion Filter


    function navigateCaptureResults(nim) {
        history.push(`../captura-resultados/${nim}`);
    }

    //#region Range Nim

    const changeFiltersRangeNim = (value, type) => {
        let data = filterRangeNims;
        ////console.log(data);

        switch (type) {
            case "date_range_nim":
                data.date_range_nim = new moment(value).format('YYYY/MM/DD');
                break;
            case "branch_range_nim":
                data.branch_range_nim = value.value;
                break;
            case "init_range_nim":
                data.init_range_nim = value;
                break;
            case "end_range_nim":
                data.end_range_nim = value;
                break;
            default:
                break;
        }

        setFilterRangeNims(data);
    }


    const getNimsFromRange = async () => {

        ////console.log(filterRangeNims);

        // let array = sections2.filter(x => x.check === true)
        // ////console.log(array);
        let _body =
        {
            "date_range_nim": filterRangeNims.date_range_nim,
            "branch_range_nim": filterRangeNims.branch_range_nim,
            "init_range_nim": filterRangeNims.init_range_nim,
            "end_range_nim": 0,
            "list_sections": sections.filter(x => x.check === true),


        }



        // if (filterRangeNims.branch_range_nim === "" || filterRangeNims.init_range_nim === "" || filterRangeNims.end_range_nim === "" || array) {
        //     createSweet("warning", "warning", "Alerta", "Debes llenar todos los campos para poder filtrar por rango gato");
        //     return;
        // }

        // ////console.log(filterRangeNims);

        let formulario = JSON.stringify(_body);
        ////console.log(formulario);
        let requestOptions = {
            method: 'POST',
            body: formulario
        };

        var respuesta = await sendRequest(requestOptions, `WorkOrder/GetWorkOrdersFromRangeNim2`);
        ////console.log(respuesta);

        if (respuesta.code === 200) {
            ////console.log(respuesta.data);
            var groupBy = function (xs, key) {
                return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

            let arreglo = groupBy(respuesta.data, 'nim');
            Object.entries(arreglo).map((obj, key) => {
            })
            setWorkOrderExams(arreglo);
        }



    }
    //#endregion Range Nim

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        workOrderExams, sections, sectionsDefault, branches, nim,
        exam_select, date_search, date_search_end, checkTypePrint,
        handleInputChange, handlePickerValues, handleDate, handleSelectValues,
        FilterData, loading, downloadSheetWorkDay, loadingPdf,
        changeCheckbox, changeCheckboxBranch, navigateCaptureResults,
        selectedSections, sectiondefault, setselectedSections,
        getNimsFromRange, changeFiltersRangeNim
    }
}
