import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { usePagination } from '../pagination/usePagination';


export const useSpecialty = ({activeTab}) => {

    const history = useHistory();

    const [specialties, setSpecialties] = useState([]);
    const [specialtyId, setSpecialtyId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const [modal, setModal] = useState(false);
    const [method, setMethod] = useState("");

    const getSpecialties = useCallback(async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Specialties/List");

        if (respuesta.code === 200) {

            let listSpecial = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((element,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    listSpecial.push({
                        id_specialty: element.id_specialty,
                        name: element.name,
                        visibleTable: visibleTable
                    }); 
                });
            }
            setSpecialties(listSpecial);
            setDatSpecial(listSpecial);
        }

        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSpecialties);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", respuesta.data.msg);
            //setLoading(false);

        }
        // else {
        //     validarSesion(history, getSpecialties);
        // }
    }, [history]);

    useEffect(() => {
        getSpecialties()
    }, [getSpecialties]);

    

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(specialties.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listSpecial = [];
        specialties.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSpecial.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setSpecialties(listSpecial);

    }, [totalPageCount, currentPage])










    const toggle = () => {
        setModal(!modal);
    }

    const [formValues, handleInputChange, , handleUpdateValues] = useForm({
        name: "",
    });

    const { name } = formValues;

    const [validaciones, setValidaciones] = useState({
        name: false,
    });

    const handleCreate = () => {
        handleUpdateValues({
            name: "",
        });

        setValidaciones({
            name: false,
        });

        setSpecialtyId(0);
        setMethod("create");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: raw
        };

        const respuesta = await sendRequest(requestOptions, "Specialties");

        if (respuesta.code === 200) {
            createSweet("create", "success");
            setLoading(false);
            getSpecialties();
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", respuesta.data.msg);
            //setLoading(false);

        }



        // else {            
        //     validarSesion(history, saveCreate);
        // }
    }

    const handleUpdate = (id_specialty) => {
        let specialty = specialties.find(x => x.id_specialty === parseInt(id_specialty));

        handleUpdateValues({
            name: specialty.name
        });

        setValidaciones({
            name: false
        });

        setSpecialtyId(id_specialty);
        setMethod("update");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoading(true);

        let body = {
            id_specialty: specialtyId,
            ...{ ...formValues }
        };

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Specialties/Update");

        if (respuesta.code === 200) {
            getSpecialties();
            createSweet("update", "success");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", respuesta.data.msg);
            //setLoading(false);

        }
        // else {
        //     validarSesion(history, saveUpdate);
        //     ////console.log("Error al intentar actualizar");
        // }
    }

    const handleDelete = (id_specialty) => {
        let specialty = specialties.find(x => x.id_specialty === parseInt(id_specialty));

        handleUpdateValues({
            name: specialty.name,
        });

        setSpecialtyId(id_specialty);
        setMethod("delete");
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Specialties/${specialtyId}`);

        if (respuesta.code === 200) {
            getSpecialties();
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Doctores", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Doctores", respuesta.data.msg);

        }
    }

  //  const [specialData, setSpecialData] = useState([])

  const [datSpecial, setDatSpecial] = useState([]);

    const handleSearchSpecial = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        datSpecial.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setSpecialties(newMethod);
    }





    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.name.lenght <= 2) {
            newValidaciones = {
                ...newValidaciones,
                name: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    return {
        specialties, method, handleInputChange, validaciones,
        name,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchSpecial
    }
}
