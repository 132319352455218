import React from 'react'
import { Modal, ModalHeader,Media,ModalBody,ModalFooter,Col,Button,Row ,Form} from 'reactstrap';
import Chart from 'react-apexcharts'



export const ModalChartResult = (props) => {

    const { titleChart,typeModalChart ="viewChart" ,modalChart,toggleChart,series,chartOptions,nameChart } = props;

    ////console.log(nameChart);


  return (

    

    <Modal isOpen={modalChart} toggle={toggleChart}  backdrop='static' keyboard={false} centered={true} className="modal-xs" >
    <ModalHeader toggle={toggleChart} className="bg-primary">
    {nameChart}
    </ModalHeader>
        {
             typeModalChart ==="viewChart" ?
             <ModalBody>
                <Col sm="12">
                {
                        series.map((obj,i) =>{
                            return(
                                <div key={i}>
                                    {
                                        obj.data === 0 ? 
                                        <div className='col-md-12 p-0'>
                                            <Col md="12 p-0"> <br />
                                             <h6 className="sub-title mb-0 text-center txt-primary">{"Sin Información Previa"}</h6> <br /> <br />
                                            </Col>
                                        </div>
                                        :
                                         <Chart className="flot-chart-placeholder"  options={chartOptions} series={series} type="line" style={{ width: '100%'}}     />
                                    }
                                </div>
                            )
                        })
                    }
                </Col>
             </ModalBody>
             :""
       }       
    <ModalFooter>
        <Button size="sm" className='btn-air-light' outline color='primary' type='button' onClick={toggleChart}>
            Aceptar
        </Button>
    </ModalFooter> 
    
    
</Modal>
    
  )
}
