import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { RibbonSpecimenProvenances } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalSpecimens } from '../../components/specimens/ModalSpecimens';
import { useSpecimenProvenances } from '../../hooks/specimenProvenances/useSpecimenProvenances';
import { ModalSpecimenProvenances } from '../../components/specimenProvenances/ModalSpecimenProvenances';

export const IndexSpecimenProvenances = ({activeTab}) => {

    const {
        specimenProvenances, method,
        name, abbreviation,
        handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    } = useSpecimenProvenances({activeTab});

    return (
        <>
            <Card>
                <CardBody className="p-1">
                   <br />
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button>
                    </div>
                    <br />
                    <div className="table-responsive">
                        <TableStriped
                            methodsActions={true}
                            cabeceras={["Nombre", "Abreviatura"]}
                            items={specimenProvenances}
                            {...{ handleUpdate, handleDelete }}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalSpecimenProvenances
                {
                    ...{
                        modal, modalTitle, toggle, method, loading,
                        name, abbreviation, handleInputChange,
                        validaciones, saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
