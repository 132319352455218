import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateTransports, ModalUpdateTransports, ModalDeleteTransports } from '../../constant';
import { usePagination } from '../pagination/usePagination';

export const useTransport = () => {

    const history = useHistory();

    useEffect(() => {
        getTransports();
    }, [history.location]);

    const [transports, setTransports] = useState([]);
    const [transportId, setTransportId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name: "",
        abbreviation: "",
        code: "",

    });

    const { name, abbreviation, code } = formValues;

    const [validaciones, setValidaciones] = useState({
        name_valid: false,
        abbreviation_valid: false,
        code_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getTransports = async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Transport");
        if (respuesta.code === 200) {
            let listTransports = [];

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((data,index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
        
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    } 

                    listTransports.push({
                        id_transport: data.id_transport,
                        name: data.name,
                        abbreviation: data.abbreviation,
                        code: data.code,
                        visibleTable:visibleTable
                    });
                });

                setTransports(listTransports);
                setTransportData(listTransports);
   
            }
            else {
                setTransports([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getTransports);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Transportes", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Transportes", respuesta.data.msg);
        }

    };

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(transports.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listProcess = [];
        transports.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listProcess.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setTransports(listProcess);

    }, [totalPageCount, currentPage]);



    const handleCreate = () => {
        handleUpdateValues({
            name: "",
            abbreviation: "",
            code: "",
            inventory: 1
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false,
            code_valid: false
        });

        setTransportId(0);
        setMethod("create");
        setModalTitle(ModalCreateTransports);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }
        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Transport");

        if (respuesta.code === 200) {
            //console.log(respuesta.data);
            let newTransports = transports;
            newTransports.push({
                id_transport: 11,
                name: name,
                abbreviation: abbreviation,
                code: code,

            });

            setTransports(newTransports);
            reset();
            createSweet("create", "success");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Transportes", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Transportes", respuesta.data.msg);
        }

    }

    const handleUpdate = (id_transport) => {
        let transport = transports.find(t => t.id_transport === id_transport);

        handleUpdateValues({
            name: transport.name,
            abbreviation: transport.abbreviation,
            code: transport.code
        });

        setValidaciones({
            name_valid: false,
            abbreviation_valid: false,
            code_valid: false
        });

        setTransportId(id_transport);
        setMethod("update");
        setModalTitle(ModalUpdateTransports);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) return;
        setLoading(true);

        let body = {
            id_transport: transportId,
            ...{ ...formValues }
        }

        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "Transport");

        if (respuesta.code === 200) {
            let newTransports = transports.map(function (obj) {
                if (obj.id_transport === transportId) {
                    obj.name = name;
                    obj.abbreviation = abbreviation;
                    obj.code = code;
                }
                return obj;
            });
            setTransports(newTransports);
            createSweet("update", "success");
            setLoading(false);
            toggle();
        } 
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Transportes", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Transportes", respuesta.data.msg);
        }

    }

    const handleDelete = (id_transport) => {

        let transport = transports.find(t => t.id_transport === id_transport);

        handleUpdateValues({
            name: transport.name,
            abbreviation: transport.abbreviation,
            code: transport.code
        });

        setTransportId(id_transport);
        setMethod("delete");
        setModalTitle(ModalDeleteTransports);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);

        let requestOptions = {
            method: 'DELETE',
        };

        const respuesta = await sendRequest(requestOptions, `Transport/${transportId}`);

        if (respuesta.code === 200) {
            let newTransports = transports.filter(t => t.id_transport !== transportId);
            setTransports(newTransports);
            setLoading(false);
            createSweet("delete", "success");
            toggle();
        } 
        else if (respuesta.code === 401) {

            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false);
            createSweet("error", "error", "Transportes", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false);
            createSweet("error", "warning", "Transportes", respuesta.data.msg);
        }

    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};
        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            }
        }

        if (formValues.abbreviation.length < 1) {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: false
            }
        }

        if (formValues.code.length < 1) {
            newValidaciones = {
                ...newValidaciones,
                code_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                code_valid: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    const [transportData, setTransportData] = useState([]);


    const handleSearchTransport = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        transportData.forEach(element => {            

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setTransports(newMethod);
    }




    return {
        transports, method,
        name, abbreviation, code, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchTransport
    }
}
