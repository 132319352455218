import React from 'react'
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap'

export const TabConceptsEgrees = (props) => {

    const {
        typeMethodDiscount, amountDiscount, isGlobalDiscount,
        handleChangeTypeDiscount, handleChangeAmountDiscount,
        handleChangeGlobalDiscount, handleChangeConcept,
        listInvoices,
        subtotalInvoice, totalDiscountsInvoice, totalTaxInvoice, totalInvoice,
        disabledSaveButton
    } = props;



    return (
        <>

           <div className='theadInvoice'><br /></div> <br />
           {
            disabledSaveButton === true ? 
            <div>
            <Row className='mt-2'>
                <Col sm="12" md="12" lg="12" className='text-center'>
                    <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width:'130px' }} />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm="3" md="3" lg="3" className='text-center'></Col>
                <Col sm="3" md="3" lg="3" style={{textAlign: 'center'}} >
                    <p className='f-24 text-primary'><b>Generando</b></p>
                </Col>
                <Col sm="1" md="1" lg="1" className='text-center'>
                    <br />
                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                </Col>
                <Col sm="1" md="1" lg="1" className='text-center'>
                    <br />
                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p> 
                </Col>
                <Col sm="1" md="1" lg="1" className='text-center'>
                    <br />
                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>                        
                </Col>
            </Row>

        </div>
        :
        <div>
              <Row>
            <br />
                <Col md="3">
                    <FormGroup>
                        <Label>Aplicar descuento de forma individual </Label>
                        <Input type='select' size={'sm'} value={isGlobalDiscount} name="isGlobalDiscount" onChange={(e) => handleChangeGlobalDiscount(e)} disabled>
                            <option value={1}>Global</option> 
                            <option value={2}>Individual</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label>Método de descuento</Label>
                        <Input type='select' size={'sm'} value={typeMethodDiscount} name='typeMethodDiscount' onChange={(e) => handleChangeTypeDiscount(e)} disabled={parseInt(isGlobalDiscount) === 1 ? false : true}>
                            <option value={1}>Monto Parcial</option>
                            <option value={2}>Monto total</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label>Ingresa el </Label>
                        <Input type='number' size={'sm'} value={amountDiscount} name="amountDiscount" onChange={(e) => handleChangeAmountDiscount(e)} 
                            disabled={parseInt(isGlobalDiscount) === 1 ? (parseInt(typeMethodDiscount) == 2 ? true : false) : true}
                        />
                    </FormGroup>
                </Col>                
            </Row>
            <Row>
                

                <Col md="12" className='table-responsive'>
                <br />
                <br />
                <br />
                    <Table className='table table-sm table-stripped'>
                        <thead className="theadInvoice">
                            <th className='text-center labeltheadInvoice'>Clave de producto</th>
                            <th className='text-center labeltheadInvoice'>Clave unidad</th>
                            <th className='text-center labeltheadInvoice'>No. Identificación</th>
                            <th className='text-center labeltheadInvoice'>Descripción</th>
                            <th className='text-center labeltheadInvoice'>Valor unitario</th>
                            <th className='text-center labeltheadInvoice'>Descuento</th>

                        </thead>
                        <tbody>
                            {
                                listInvoices.length > 0 && listInvoices.map(_invoice => {
                                    return (
                                        <tr className='text-center' key={'concept-'+_invoice.id_invoice}>
                                            <td>{_invoice.productCode}</td>
                                            <td>{_invoice.unitCode}</td>
                                            <td>{_invoice.folio}</td>
                                            <td>
                                                <Input size={'sm'} value={_invoice.concept} onChange={(e) => handleChangeConcept(e, _invoice.id_invoice)} />
                                            </td>
                                            <td>{_invoice.total}</td>
                                            <td>{_invoice.discount}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col md="6" className='table-responsive'></Col>
                <Col md="6" className='table-responsive'>
                    <div className='table-responsive'>
                        <Table className='table table-sm table-stripped'> 
                            <tbody>
                                <tr>
                                    <td className='text-right'><b>Subtotal</b></td>
                                    <td><b>{ subtotalInvoice } </b></td>
                                </tr>                                
                                <tr>
                                    <td className='text-right'><b>IVA</b></td>
                                    <td><b>{ totalTaxInvoice }</b></td>
                                </tr>
                                <tr>
                                    <td className='text-right'><b>Total</b></td>
                                    <td><b>{ totalInvoice }</b></td>
                                </tr>
                                <tr>
                                    <td className='text-right text-danger'><b>Descuento</b></td>
                                    <td><b>{ totalDiscountsInvoice }</b></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>

        </div>

           }

          
        </>   
    )
}
