import React from 'react'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "moment/locale/es";
import './styleCalender.css';
import { Col, Row,Input } from 'reactstrap';


export const EventAmount = (props) => {

    const { start,end,setEnd,setStart,handleInputChange,title,loadingViews } = props;


    return (
        <div>
            <br /> 
            <hr />
            <Col sm="12"  >
                <Row>
                  
                    <Col sm="4">
                        <label className='link text-sm text-primary badge badge-light-primary f-12'>Titulo *</label>
                        <Input  type='text' name="title" value={title} onChange={handleInputChange} className={ loadingViews === true ? "border-0 mr-2" : `form-control form-control-sm input-air-primary border-primary b-r-9 f-14` }    />          
                    </Col>
                    <Col sm="4">
                        <label className='link text-sm text-primary badge badge-light-primary f-12'>Inicia-Termina *</label>
                        <DateRangePicker
                        initialSettings={{
                        locale: {
                        format: "YYYY-MM-DD",
                        applyLabel: "Aplicar",
                        cancelLabel: "Cancelar"
                        },
                        startDate: start,
                        endDate: end,
                        timePicker: false,
                        drops: 'up'
                        }}
                        onApply={(event, picker) => {
                        setStart(new Date(picker.startDate));
                        setEnd(new Date(picker.endDate));
                        }}
                        >
                    <input className={`form-control form-control-sm input-air-primary border-primary`} type="text" />
                    </DateRangePicker>
                    </Col>
                </Row>
            </Col>
             
        </div>
    )
        
}
