import React from 'react'
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';

export const FormAntibiotic = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, antibiotic, button
    } = props;

    const {
        antibiotic_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Antibiotico <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                className="form-control form-control-sm input-air-primary"
                                name="antibiotic"
                                onChange={handleInputChange}
                                value={antibiotic}
                                autoComplete="off"
                                invalid={antibiotic_valid}
                            />
                            <FormFeedback>Minimo 1 caracter</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
