import React from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

export const ModalRepeatProfile = (props) => {

    const {
        modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile
    } = props;

    
    const {
        repeat_profiles, repeat_exams, name_profile
    } = validationsRepeat;

    return (
        <Modal isOpen={modalRepeatProfile} toggle={toggleRepeatProfile} backdrop='static' keyboard={false} centered={true} size="lg">
            <ModalHeader className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12">
                        <p><b>El perfil {name_profile}, se compone de los elementos de la derecha y la izquierda </b></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Table>
                            <tbody>
                                {
                                    repeat_profiles.length > 0 && repeat_profiles.map((x, key) => {
                                        return (
                                            <>
                                                <tr key={"RepeatProfile"+key}>
                                                    <th className="text-center">{x.name_profile}</th>
                                                    <th className="text-center">{name_profile}</th>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">Coincidencias</td>
                                                    <td className="text-center">Sin coincidencias</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {
                                                            x.listExamsRepeat.length > 0 && x.listExamsRepeat.map((e, keyExam) => {
                                                                return (
                                                                    <li key={'subexam'+keyExam}>{e.name}</li>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            x.listExamsNotRepeat.length > 0 && x.listExamsNotRepeat.map((er, keyNot) => {
                                                                return (
                                                                    <li key={'notExist'+keyNot}>{er.name}</li>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                            
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="button" onClick={toggleRepeatProfile}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
