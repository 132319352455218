import React, { useState, Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import Select from "react-select";
import { DataConfigurations } from './DataConfigurations';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { Key } from 'react-feather';

export const CollapseDeterminations = (props) => {

  const {
    item,
    id_machine_sanitarytemp,
    handleAddLote,
    handleSelectMachineSanitary,
    id_lote_sanitarytemp,handleSelectLote,searchLoteSanitary,
    AddNewLote,id_index,list_determinations_save,AddNewMachine,
    changeInputCarac,
        changeInputElement,
        handleMachineValueChange,
        handleLoteValueChange,
        formValues,
        listProcessConfig_save,
        handleDeleteMachine,
        handleDeleteLote,
        idwsconfig,
        changeInputElementCheck
        

  } = props;
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 999
    }),
  }
//console.log(item,"item");
  return (
    <Row key={id_index}>
    <Col sm="12">
        <Card className='shadow'>
            <CardHeader className='bg-primary p-3'>
                <h6> <i className="icofont icofont-laboratory f-16"></i><b>{item.name}</b> &nbsp;</h6>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm="12" md="12" lg="12" xl="12" className='p-2'>
                        
                        {item != null && item!=undefined &&
                            <DataConfigurations
                                key={item.id_exam}
                                indexs={item}
                                {...{
                                    id_machine_sanitarytemp,
                                    handleAddLote,
                                    handleSelectMachineSanitary,
                                    id_lote_sanitarytemp,handleSelectLote,searchLoteSanitary,list_determinations_save,
                                    listProcessConfig_save,
                                    AddNewLote,id_index_deter:id_index,id_exam:item.id_exam,
                                    AddNewMachine,handleSelectMachineSanitary,
                                    changeInputCarac,
                                    changeInputElement,
                                    handleMachineValueChange,
                                    handleLoteValueChange,
                                    formValues,
                                    handleDeleteMachine,
                                    handleDeleteLote,
                                    idwsconfig,
                                    changeInputElementCheck
                                }}
                            />
                        }

                        
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Col>
</Row>
  )

}
