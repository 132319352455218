import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { CreateExam } from './CreateExam';
import { DeleteExam } from './DeleteExam';
import { UpdateExam } from './UpdateExam';

export const ModalExam = (props) => {

    const {

        formValues,
        method, handleInputChange, validaciones, validationSectionArea,
        sections, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports,
        id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, is_critic, print_new_page,
        toggle, modal, modalTitle, loading, listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory,
        changeSelect, handleSelectValues,
        saveCreate,
        saveUpdate,
        saveDelete,
        handleChangePrice,isImagenologyy,
           //*CONFIGURATION EXTERNAL LABORATORY
           dataLaboratory,handleChangePriceIni
    } = props;

    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    return (
        <Modal isOpen={modal} toggle={toggle} className={(method === "update") ? "modal-xl" : "modal-xl"} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary" close={closeBtn}>{modalTitle}</ModalHeader>
            {
                method === "create"
                    ? <CreateExam {...{ formValues, toggle, handleInputChange, handleChangePrice, handleSelectValues, validaciones, loading, sections, changeSelect, specimenOrigins, subSections, commercialLines, specimens, specimenProvenances, typeTransports, transports, id_section, id_subsection, id_specimen_origin, code, alias, name, abbreviation, individual_sale, cost, is_referenced, methodAction: saveCreate ,isImagenologyy, print_new_page, validationSectionArea,
                        //*CONFIGURATION EXTERNAL LABORATORY
           dataLaboratory,handleChangePriceIni}} />
                    : (method === "update")
                        ? <UpdateExam {...{ formValues, toggle, handleInputChange, validaciones, 
                            loading, sections, handleSelectValues, changeSelect, specimenOrigins, subSections, 
                            commercialLines, specimens, specimenProvenances, transports, id_section, id_subsection,
                            id_specimen_origin, code, alias, name, abbreviation, individual_sale, is_referenced, is_critic, 
                            listExternalLaboratories, examId, getUpdateDefaultExternalLaboratory, methodAction: saveUpdate,
                             print_new_page, validationSectionArea,     //*CONFIGURATION EXTERNAL LABORATORY
                             dataLaboratory,handleChangePriceIni
                                
                            
                            }  
                            }         />
                        : <DeleteExam  {...{ toggle, loading, code, alias, name, saveDelete }} />
            }
        </Modal>
    )
}
