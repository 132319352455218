import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteBranchMachine } from './DeleteBranchMachine';
import { FormBranchMachine } from './FormBranchMachine';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';

export const ModalBranchMachine = (props) => {

    const {
        method, handleInputChange, validaciones, loading,
        id_machine_model, id_branch, id_section, serial_number,
        machines, branches, sections,
        toggle, modal, modalTitle, 
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggle} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormBranchMachine
                            button={ModalButtonSave}
                            { ...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate, machines, branches, sections } }
                        />
                    : ( method === "update" )

                        ?
                            <FormBranchMachine
                                button={ModalButtonUpdate}
                                { ...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, id_machine_model, id_branch, id_section, serial_number, machines, branches, sections  } }
                            />
                        : 
                            <DeleteBranchMachine                                
                                {...{ toggle, loading, saveDelete, serial_number }}
                            />
            }
        </Modal>
    )
}
