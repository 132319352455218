import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useGlobalReferenceValue = (profileId) => {


    const [idProfile, setIdProfile] = useState(profileId);
    const history = useHistory();

    //SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();

    //#region CONFIG CKEDITOR
    const [configCkEditor, setConfigCkEditor] = useState({
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
            },
            {
                name: "alignment",
                groups: ["justify", "left", "center", "right"]
            },
            "/",
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "styles", groups: ["styles"] },
            { name: "colors", groups: ["colors"] },
            { name: "tools", groups: ["tools"] },
            "/",
            { name: "clipboard", groups: ["clipboard", "undo"] },
            { name: "others", groups: ["others"] },
            { name: "about", groups: ["about"] }
        ],
        removeButtons:
            "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
        fontSize_sizes: "6/6px;8/8px;10/10px;12/12px;14/14px;16/16px;24/24px;48/48px;",
        font_names:
            "Arial/Arial, Helvetica, sans-serif;" +
            "Times New Roman/Times New Roman, Times, serif;" +
            "Verdana",
       })



    const [formGlobalReference, handleInputChangeGlobalReference, resetGlobal, handleUpdateValuesGlobalReference, handleSelectValuesGlobalReference, handlePickerValuesGlobalReference] = useForm({
        globalReferenceValue: ""
    });

    const { globalReferenceValue } = formGlobalReference;

    useEffect(() => {
        if (idProfile > 0) {
            getGlobalReferenceValue(profileId);
        }
    }, []);

    const getGlobalReferenceValue = async (profileId) => {
        let listTmp = await handleRequest("GET", `Profiles/GlobalReferenceValue/${profileId}`, "Referencia global");

        if (listTmp !== null) {

            handleUpdateValuesGlobalReference({
                ...formGlobalReference,
                globalReferenceValue: listTmp.reference_value
            });
        }
    }

    const handleChangeGlobalReference = (e) => {
        let data = e.data.get();
        handlePickerValuesGlobalReference(data, "globalReferenceValue");
    }

    const saveGlobalReferenceValue = async () => {
        if (formGlobalReference.globalReferenceValue.length > 5) {
            let body = {
                id_profile: idProfile,
                reference_value: globalReferenceValue
            };
            let listTmp = await handleRequest("POST", "Profiles/SaveGlobalReferenceValue", "Referencia global", body);

            if (listTmp !== null) {
                createSweet("create", "success", "Exito!");
            }
        }
    }

    //GLOBAL REQUEST
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        globalReferenceValue, handleChangeGlobalReference, saveGlobalReferenceValue, 
        configCkEditor,
    }
}
