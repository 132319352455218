import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';

export const FormInvoiceSettings = (props) => {

    const {
        toggle, loading, button,
        id_type_document, code, serie, initial_folio, final_folio, is_default, validationInvoiceSettings,
        listTypeDocuments, 
        handleInputChange,
        methodAction
    } = props;

    const {
        id_type_document_valid,
        code_valid,
        serie_valid,
        initial_folio_valid,
        final_folio_valid,     
    } = validationInvoiceSettings;

    return (
        <Form className='form theme-form' role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label className='col-form-label'>Tipo de documento <span className='f-16' style={{color: "#0079C7"}}>*</span></Label>
                            <Input
                                type='select'
                                name='id_type_document'
                                value={id_type_document}
                                required={true}
                                onChange={handleInputChange}
                                invalid={id_type_document_valid}
                                className="form-control form-control-sm input-air-primary"
                            >
                                <option value={0}>Selecciona una opción</option>
                                {
                                    listTypeDocuments.length > 0 && listTypeDocuments.map(obj => {
                                        return <option value={obj.id_type_document} key={obj.id_type_document}>{ obj.name }</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className='col-form-label'>Código <span className='f-16' style={{color: "#0079C7"}}>*</span></Label>
                            <Input
                                type="text"
                                name="code"
                                value={code}
                                autoComplete="off"
                                onChange={handleInputChange}
                                invalid={code_valid}
                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Debes de completar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col md="12"> 
                        <FormGroup>
                            <Label className='col-form-label'>Serie <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type='text'
                                name='serie'
                                value={serie}
                                onChange={handleInputChange}
                                invalid={serie_valid}
                                className="form-control form-control-sm input-air-primary"
                                placeholder='Indica un valor alfanumerico'
                            />
                            <FormFeedback>Debes capturar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col md="6"> 
                        <FormGroup>
                            <Label className='col-form-label'>Folio inicial <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type='number'
                                name='initial_folio'
                                value={initial_folio}
                                onChange={handleInputChange}
                                invalid={initial_folio_valid}
                                className="form-control form-control-sm input-air-primary"
                                placeholder='Indica el número con el que se creara tu proxima factura'
                            />
                            <FormFeedback>Debes capturar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6"> 
                        <FormGroup>
                            <Label className='col-form-label'>Folio final </Label>
                            <Input
                                type='number'
                                name='final_folio'
                                value={final_folio}
                                onChange={handleInputChange}
                                invalid={final_folio_valid}
                                className="form-control form-control-sm input-air-primary"
                                placeholder='Campo informativa para indicar el limite al que se puede facturar'
                            />
                            <FormFeedback>Debes capturar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col md="6">
                        <Label>Establecer serie y folio por defecto</Label>
                        <br />
                        <Label className='switch'>
                            <Input type='checkbox' name='is_default' onChange={handleInputChange} checked={is_default} />
                            <span className='slider round'></span>
                        </Label>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color='primary' type='submit' className={loading ? 'disabled progress-bar-animated progress-bar-striped' : ""} disabled={loading}>
                    {button}
                </Button>
                <Button outline color='light' type='button' onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
