import React from 'react'
import { Fragment } from 'react';
import Select from "react-select";
import { Button, ButtonGroup, Input, Label, Table, Row, Col } from 'reactstrap';
import { TableWorkOrderItem } from './TableWorkOrderItem';

export const TableWorkOrderUpdate = (props) => {

    const {
        isEditWorkOrder, items, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
        changeValueTimeSampling, timesCurves, handleViewExamsProfile, enable_tracking_toma,  handleChangeExternalLaboratories,count
    } = props;

    let versionLayout = localStorage.getItem('layout_version');

    let wTest = [];
    let wTestReferenced = [];

    if (items.length > 0) {
        wTest = items.filter(x => x.is_campaign === false && x.is_referenced === false);
        wTestReferenced = items.filter(x => x.is_campaign == false && x.is_referenced == true);
    }

    return (
        <Table className='table-xs'>
            <thead>
                <tr className='border-bottom-info'>
                    <th className='text-center'><b>Código</b></th>
                    <th className='text-center'><b>Examen / Perfil</b></th>
                    <th className='text-center'><b>Abreviatura</b></th>
                    <th className='text-center'><b>Costo</b></th>
                    <th className='text-center'><b>¿Es urgente <br /> el examen?</b></th>
                    <th className={enable_tracking_toma==true || enable_tracking_toma=="True"?"d-none":"text-center"}><b>¿Cuenta con la muestra?</b></th>
                    <th className='text-center'><b>¿Remitida?</b></th>
                    {
                        checkTypeClient === 3 &&
                        <th className='text-center'>
                            <b>Convenio</b>
                        </th>
                    }
                    <th className='text-center'><b>Acciones</b></th>
                </tr>
            </thead>
            <tbody className='f-13 text-left border-bottom-info'>
                {
                    wTest.length > 0 && wTest.map((itemTest, index) => {
                        if (!itemTest.is_canceled) {
                            let tmp_key = Date.now() + 1.5;
                        
                            return (
                                <TableWorkOrderItem
                                    {
                                        ...{
                                            itemTest, index, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
                                            changeValueTimeSampling, times: timesCurves, handleViewExamsProfile, enable_tracking_toma, uniqueKey: tmp_key,
                                            isEdit: isEditWorkOrder, handleChangeExternalLaboratories,count
                                        }
                                    }
                                />
                            )
                        }
                    })
                }                
            </tbody>
            <tfoot>
                <tr className='border-bottom-info'>
                    <td colSpan={9}>Estudios de referencia</td>
                </tr>
                {
                    wTestReferenced.length > 0 && wTestReferenced.map((itemReferenced, indexRef) => {
                        let tmp_key_ref = Date.now() + 1.5;
                        return (
                            <TableWorkOrderItem key={tmp_key_ref + indexRef}
                                {
                                    ...{
                                        itemTest: itemReferenced, index: indexRef, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, handleCheckUrgent, changeSelectSamplings,
                                        changeValueTimeSampling, times: timesCurves, handleViewExamsProfile, enable_tracking_toma, uniqueKey: tmp_key_ref,
                                        isEdit: isEditWorkOrder, handleChangeExternalLaboratories,count
                                    }
                                }
                            />
                        )
                    })
                }
            </tfoot>
        </Table>
    )
}
