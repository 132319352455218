import React from 'react';
import { Button, Card, CardBody,Input } from 'reactstrap';
import { useMachine } from '../../hooks/machine/useMachine';
import { TabMachine } from '../../constant';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalMachine } from '../../components/machine/ModalMachine';

export const IndexMachine = ({activeTab}) => {

    const{
        machines, method, handleInputChange, validaciones,
        brand, model,
        toggle, modal, modalTitle, loading, 
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage,handleSearchMachine

    } = useMachine({activeTab});
// ////console.log(machines);

    return (
        <>
            <Card>
                <CardBody className="p-1">
                     <br />
                    <div className="text-right" style={ {margin: 5, padding:0} }>
                        {/* <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={ handleCreate }><span className="fa fa-plus-circle"></span></Button> */}
                    </div> <br /> 
                    <div className="row">
                        <div className="offset-8 col-4 pt-1 pb-2">
                            <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchMachine} />
                        </div>
                    </div>
                    <div className="table-responsive">
                        <TableStriped   
                            notMaped={["visibleTable","id_brand","id_external"]}
                            methodsActions={false}
                            cabeceras={["Marca", "Modelo"]}
                            items={machines}
                            {...{handleUpdate, handleDelete}}

                            nextPage={nextPage}
                            previousPage={previousPage}
                            totalPageCount={totalPageCount}
                            currentPage={currentPage}
                            goToPage={goToPage}
                        />
                    </div>
                </CardBody>
            </Card>
            <ModalMachine
                {
                    ...{
                        method, handleInputChange, validaciones, loading,
                        brand, model,
                        toggle, modal, modalTitle,
                        saveCreate, saveUpdate, saveDelete
                    }
                }
            />
        </>
    )
}
