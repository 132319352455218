import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Card, Button, Form ,Table} from 'reactstrap';
import './styleWorkDay.css';



export const CadenaMicrosanitaria = (props) => {

    const {typeModalMicro = "viewMicro",modalMicro,loadingMicro,toggleCadena,arrayMic,dataMic,exportToExcel} = props;


    return (
        <Modal isOpen={modalMicro} toggle={toggleCadena} backdrop='static' keyboard={false} centered={true} className="modal-xl" >
            <ModalHeader toggle={toggleCadena} className="bg-primary">
                Cadena de Custodia
            </ModalHeader>
            {
                typeModalMicro === "viewMicro" ?
                    <ModalBody>
                            <Row>
                                <Col sm="9">
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <b>Condiciones de recepción</b>
                                        </Col>
                                    </Row> <br /> <br />
                                    <Row>
                                        <Col sm="2" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>recepción <br /> Condiciones de </div>
                                        </Col>
                                        <Col sm="2" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>corregida <br /> Temperatura </div>

                                        </Col>
                                        <Col sm="2" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>temperatura <br /> Cumple </div>

                                        </Col>
                                        <Col sm="2" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>Identificación </div>

                                        </Col>
                                        <Col sm="1" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>Contenedor </div>

                                        </Col>
                                        <Col sm="1" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>Cantidad</div>

                                        </Col>
                                        <Col sm="1" className='b-1'>
                                        <div className='txt-secondary texto-vertical-2'>Folio</div>

                                        </Col>
                                    </Row>
                                   
                                    <Row>
                                        <Col sm="2" className='border-1'>
                                        <div className='txt-secondary '>°C</div>
                                        </Col>
                                        <Col sm="2" className='border-1'>
                                        <div className='txt-secondary '>°C</div>

                                        </Col>
                                        <Col sm="2" className='border-1'>
                                        <div className='txt-secondary '>SI&nbsp;/&nbsp;NO</div>

                                        </Col>
                                        <Col sm="2" className='border-1'>
                                        <div className='txt-secondary '>SI&nbsp;/&nbsp;NO </div>

                                        </Col>
                                        <Col sm="1" className='border-1'>
                                        <div className='txt-secondary '>SI&nbsp;/&nbsp;NO </div>

                                        </Col>
                                        <Col sm="1" className='border-1'>
                                        <div className='txt-secondary '>SI&nbsp;/&nbsp;NO</div>

                                        </Col>
                                        <Col sm="1" className='border-1'>
                                        <div className='txt-secondary '>{"----"}</div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            {
                                                dataMic?.map((_find) => {
                                                    return (
                                                        <Row>
                                                            <Col sm="2" >{_find.amount.temperature_send}</Col>
                                                            <Col sm="2"  >{_find.amount.temperature_corrected_send}</Col>
                                                            <Col sm="2"  >{_find.amount.comply_temperature_send}</Col>
                                                            <Col sm="2"  >{_find.amount.identification_send}</Col>
                                                            <Col sm="1"  >{_find.amount.container_send}</Col>
                                                            <Col sm="1"  >{_find.amount.quantity_send}</Col>
                                                            <Col sm="2"  >{_find.amount.codeMic}</Col>
                                                        </Row>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                    </Row>
                                </Col>
                            </Row>
                    </ModalBody>
                    : ""
            }
            <ModalFooter>
                <Button size='sm' outline color='success' type='button'  onClick={() => exportToExcel(dataMic)}  >
                    <i className='icofont icofont-file-excel'>&nbsp;Excel</i>
                    
                </Button>
                <Button size='sm' className='btn-air-light' outline color='danger' type='button' onClick={toggleCadena}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}