import React from 'react';
import { Modal, Col, Row, Label, Button, Input, Media, ModalBody, Table } from 'reactstrap';
import DeniReactTreeView from "deni-react-treeview"
import 'animate.css';



export const ModalInfo = (props) => {

    const {
        openModalInfo, closeModalInfo, handleInfo, typeInfo = "info", modalInfo, thermometers, dataMic,
        namepatien, age, curp, phone, gender, treeviewRef, itemsTree, listNims, flebo, codeMic,
        loadinginfo, changeTree, saveSendSample, varSample , typeMicro = "Ismicro", factorCorrection,
    } = props;




    const onRenderItem = (_find) => {


        return (
            <div className="treeview-item-example">
                {
                    _find.state === 1  && _find.check_forwarded === true?
                        <span>
                            <i className="icofont icofont-laboratory f-12"></i>{_find.text}&nbsp;
                            {
                                _find.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : 
                                _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> : 
                                _find.valueDontSend === 1 ?  <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                            {
                                _find.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> :
                                _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> : 
                                _find.valueDontSend === 1 ?  <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                            {
                                _find.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> :
                                _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> : 
                                _find.valueDontSend === 1 ?  <i className='icofont icofont-ui-block text-danger f-16' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                        </span>
                        :
                        _find.state === 1 && _find.check_forwarded === false ? 
                        <span className='text-success'>
                            <i className="icofont icofont-laboratory f-12"></i>{_find.text}&nbsp;
                            {
                                _find.is_send1 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                            {
                                _find.is_send2 === true ? <i className='fa fa-check txt-success f-18' ></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                            {
                                _find.is_send3 === true ? <i className='fa fa-check txt-success f-18'></i> : <i className='fa fa-times text-danger f-16' ></i>
                            }&nbsp;
                        </span>
                        :
                       _find.valueDontSend === 1 ? 
                        <span className='text-danger'>
                              <i className="icofont icofont-laboratory txt-danger f-12"></i>{_find.text}&nbsp;
                              { 
                              _find.valueDontSend === 1 ?  <i className='icofont icofont-ui-block text-danger f-16' ></i> : 
                              _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                              <i className="icofont icofont-laboratory txt-danger f-12"></i>
                              }&nbsp;
                        </span>
                        :
                        <span style={{color:'#FF8F00'}}>
                              <i className="icofont icofont-laboratory txt-danger f-12"></i>{_find.text}&nbsp;
                              { 
                              _find.valueDontSend === 1 ?  <i className='icofont icofont-ui-block text-danger f-16' ></i> : 
                              _find.valueDontSend === 2 ? <i className='icofont icofont-ui-clock text-danger f-16' > </i> :
                              <i className="icofont icofont-laboratory txt-danger f-12"></i>
                              }&nbsp;
                        </span>
                }
            </div>
        )
    }


    return (
        <Modal isOpen={modalInfo} toggle={closeModalInfo} backdrop='static' keyboard={false} centered={true} className="modal-lg" >
            {
                typeInfo === "info" ?
                    <>
                        <div className="TrakingmodalHeader" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2"></Col>
                                    <Col xs="8" sm="8" md="8" lg="8" xl="8" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Información Detallada"}</Label>
                                    </Col>
                                    <Col sm="2" className='p-2' >
                                        <img className=" pointer" src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "25%" }} alt="" onClick={closeModalInfo} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col sm="12" style={{ textAlign: "center" }} className='p-l-0 p-r-0' >
                            <ModalBody className="border-3 b-primary " >
                                {
                                    loadinginfo === false ?
                                        <>
                                            <Row className='mt-2'>
                                                <Col sm="12" md="12" lg="12" className='text-center'>
                                                    <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '150px' }} />
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                                <Col sm="3" md="3" lg="3" className='text-left'>
                                                    <p className='f-24 text-primary'><b>Cargando</b></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'></Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="1" md="1" lg="1" className='text-center'>
                                                    <br />
                                                    <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                                                </Col>
                                                <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                            </Row>
                                        </>
                                        :
                                        <Col sm="12" className='text-center'>

                                            <div className="user-image">
                                                <div className="avatar">
                                                    {
                                                        gender === 2 ?
                                                            <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserMen.svg")} />
                                                            :
                                                            <Media body style={{ width: "15%" }} src={require("../../../../assets/images/loginHelenLabs/User/UserWoman.svg")} />
                                                    }
                                                </div>
                                                <div className="icon-wrapper" data-intro="Change Profile image here">
                                                </div>
                                            </div>
                                            <div className="info">
                                                <Row>
                                                    <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                                        <Row >
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Sexo"}</a></div>
                                                                    <div className="desc  detailPatienModal f-14">
                                                                        {
                                                                            gender === 2 ? "Masculino" : "Femenino"
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Edad"}</a></div>
                                                                    <div className="desc detailPatienModal f-14">
                                                                        {age}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                                        <div className="user-designation">
                                                            <div className="title"><b target="_blank" >{"Nombre"}</b></div>
                                                            <div className="desc mt-2 detailPatienModal">{namepatien}</div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                                        <Row>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Curp"}</a></div>
                                                                    <div className="desc detailPatienModal f-12">
                                                                        {curp}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="6">
                                                                <div className="user-designation">
                                                                    <div className="title"><i class="fa fa-circle iconResults f-12"></i>&nbsp;<a className='txt-primary f-14'>{"Telefono"}</a></div>
                                                                    <div className="desc detailPatienModal f-12">
                                                                        {phone}
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <div className="follow">
                                                    <Row>
                                                        {/* <Col sm="5"> <br /> <br />
                                                                <b className='f-12 txt-secondary'>Recorrido de la Muestra</b>
                                                            </Col> */}
                                                        <Col sm="1">
                                                        </Col>
                                                        <Col sm="8" className='p-l-0' >
                                                            <div className='table-responsiv'>
                                                                <Table size='sm' striped bordered hover>
                                                                    <thead>
                                                                        <tr className='text-center'>
                                                                            <th  >Envio</th>
                                                                            <th>Preparación</th>
                                                                            <th>Recepción</th>
                                                                            <th>Pendiente</th>
                                                                            <th>Rechazo</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className='text-center'>
                                                                            <td >   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-times text-danger f-14' ></i> <i className='fa fa-times text-danger f-14' ></i>  </td>
                                                                            <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-times text-danger f-14' ></i> </td>
                                                                            <td>   <i className='fa fa-check txt-success f-14'></i> <i className='fa fa-check txt-success f-14'></i>  <i className='fa fa-check txt-success f-14'></i>  </td>
                                                                            <td><i className='icofont icofont-ui-clock text-danger f-16' > </i><i className='icofont icofont-ui-clock text-danger f-16' > </i><i className='icofont icofont-ui-clock text-danger f-16' > </i></td>
                                                                            <td> <i className='icofont icofont-ui-block text-danger f-16' ></i> <i className='icofont icofont-ui-block text-danger f-16' ></i> <i className='icofont icofont-ui-block text-danger f-16' ></i></td>
                                                                        </tr>

                                                                    </tbody>

                                                                </Table>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </div>
                                            <br /> <br />
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="9">
                                                    <ul class="list-group" style={{display:'-webkit-box'}}>
                                                    <li class="list-group-item list-group-item-success">Con muestra</li>
                                                        <li class="list-group-item list-group-item-primary">Remitida</li>
                                                        <li style={{backgroundColor:'#FF8F00'}} class="list-group-item">Sin muestra</li>
                                                        <li class="list-group-item list-group-item-danger">Rechazo</li>
                                                    </ul>
                                                        <DeniReactTreeView
                                                            ref={treeviewRef}
                                                            style={{ marginRight: '10px', marginBottom: '10px', height: "200px", textAlign: "center" }}
                                                            showCheckbox={false}
                                                            showIcon={false}
                                                            items={itemsTree}
                                                            selectRow={false}
                                                            onRenderItem={onRenderItem}
                                                            onCheckItem={(e) => changeTree(e)}
                                                            className="deni-react-treeview-container.green .deni-react-treeview-item-container.green .icon-and-text.selected"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                }
                            </ModalBody>
                        </Col>
                    </>
                    : 
                  ""
            }
        </Modal >
    )
}