import React from 'react'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row,Card } from 'reactstrap'
import Select from 'react-select';
import './Selectt.css'


export const ModalReturnTransaction = (props) => {

    const {
        modal, toggle, catReason,handleInputChange, handleChangeNimWorkOrder,
        id_return_reason,amount,saveCreate,
        nim,id_work_order,
        test,handleSelectValues,selectWorkOrderTest

         } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className='modal-lg'>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {"Devolución / Cancelación"}
            </ModalHeader>
            <ModalBody>
                <Col sm="12"  className='p-0'>
                    <Row>
                        <Col sm="8">
                        <Label>Motivos de devolución o cancelación </Label>
                        <Input type='select' name="id_return_reason" onChange={handleInputChange} value={id_return_reason} className="form-control form-control-sm input-air-primary" >
                            <option value={0}>{"Selecciona una opción"}</option>
                            {
                                catReason.length >= 1 && catReason.map( (obj, index) => {
                                    return <option value={obj.id_return_reason}>{obj.reason}</option>
                                }) 
                            }
                        </Input>
                        </Col>
                        <Col sm="4">
                        <Label>Cantidad a devolver</Label> 
                        <Input
                             placeholder="Ingresa la cantidad"
                             type="number"
                             value={amount}
                             onChange={handleInputChange}
                             autoComplete="nope"
                             name='amount'
                             className="form-control form-control-sm input-air-primary" 
                        />
                        </Col>
                    </Row>
                </Col>
                <br/>
            
                <Label></Label>                
                <Col sm="12">
                    <Row>
                        <Col sm="5 p-l-0 ">
                        <h7>NIM &nbsp;<h7 className="text-secondary">{"(No obligatorio)"}</h7></h7>
                        <br/>
                        <Input type='select' name="id_work_order" onChange={(e) => handleChangeNimWorkOrder(e)} value={id_work_order}                                  className="form-control form-control-sm input-air-primary" 
    >
                                    <option value={0}>{"Selecciona una opción"}</option>
                                    {
                                        nim.length >= 1 && nim.map( (obj, index) => {
                                            return <option value={obj.id_work_order}>{obj.nim_work_order}</option>
                                        })
                                    }
                                </Input>
                        </Col>
                        <Col sm="7">
                        <h7>Seleccionar Exámenes/Perfiles &nbsp;<h7 className="text-secondary">{"(No obligatorio)"}</h7></h7>
                        <br/>
                        <Select
                            defaultValue={selectWorkOrderTest}                            
                            className='Selectt'
                            name="selectWorkOrderTest"
                            onChange={(e) => handleSelectValues(e, "selectWorkOrderTest")}
                            options={test}
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            isSearchable={true}
                            styles={
                                {
                                    placeholder: () => ({
                                        color: "#b6bdc4"
                                    })
                                }
                            }
                        />
                        </Col>
                    </Row>
                </Col>
                <br/>
                <hr/>
                <Row>
                    <Col sm="12" className='text-right'>
                        <Button type='button' outline color='primary' onClick={ () => saveCreate() } >Guardar</Button>
                        <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}
