import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { sendRequest } from '../../hooks/requests/useRequest';
import { validarSesion } from '../../hooks/tokens/useToken';
import { useHistory, useParams } from 'react-router';
import { useForm } from '../../hooks/forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { LostConnection } from '../../constant';
import { useDebounce } from '../debounce/useDebounce';
import { queryAllByAltText } from '@testing-library/react';
import { usePagination } from '../pagination/usePagination';
import { Badge } from 'reactstrap';




export const useProfiles = () => {


    const history = useHistory();

    const { profilesId, methodd } = useParams();

    const [profiles, setProfiles] = useState([]);
    const [method, setMethod] = useState(methodd === undefined ? "" : methodd);

    const [listFirstPrice, setListFirstPrice] = useState([]);

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    const [modalTitle, setModalTitle] = useState("");
    const [modal, setModal] = useState(false);

    //SEARCH PRICE EXAMS 
    const [exam, setExam] = useState([]);
    const [searchPrice, setSearchPrice] = useState([]);

    //DEBOUNCEVALUE % Público
    const [rangeval, setRangeval] = useState(0);
    const debounceValue = useDebounce(rangeval, 3000);

    //DEBOUNCEVALUE % Máquila sin toma 
    const [rangevalM, setRangevalM] = useState(0);
    const debounceValueM = useDebounce(rangevalM, 3000);

    //DEBOUNCEVALUE % Máquila con toma
    const [rangevalMS, setRangevalMS] = useState(0);
    const debounceValueMS = useDebounce(rangevalMS, 3000);

    const [idTest, setIdTest] = useState(0);
    const [index, setIndex] = useState(-1);
    const [indexParent, setIndexParent] = useState(-1);

    const [idTestM, setIdTestM] = useState(0);
    const [indexM, setIndexM] = useState(-1);
    const [indexParentM, setIndexParentM] = useState(-1);

    const [idTestMS, setIdTestMS] = useState(0);
    const [indexMS, setIndexMS] = useState(-1);
    const [indexParentMS, setIndexParentMS] = useState(-1);

    const [totalCommercialLines, setTotalCommercialLines] = useState([]);

    //ID PROFILE
    const [profileId, setProfileId] = useState(0)

    //LIST  PRIOFILES TABLE
    const [listProfile, setListProfile] = useState([]);

    //LIST PROFILE COMERCIAL
    const [listCommercial, setListCommercial] = useState([]);

    //MODAL DELETE
    const [modal2, setModal2] = useState(false);

    //MODAL LIST COMMERCIAL LINE
    const [modal3, setModal3] = useState(false);

    const [sendKitSampling, setSendKitSampling] = useState(0);
    const [continueCreation, setContinueCreation] = useState(false);

    //MODAL DE ORDERBYEXAM
    const [methodOrder, setMethodOrder] = useState("");
    const [titleOrder, setTitleOrder] = useState("");
    const [modalOrder, setModalOrder] = useState(false);



    const toggle = () => {
        setModal(!modal);
    }


    const toggle2 = () => {
        setModal2(!modal2)

    }

    const toggle3 = () => {
        setModal3(!modal3)

    }
    //MODAL IMAGENOLOGIA

    const toggleOrderByExam = () => {
        setModalOrder(!modalOrder);
    }



    useEffect(() => {

        getProfiles();

    }, [history.location]);


    useEffect(() => {
        if (rangeval) {

            if (index !== -1) {

                let copySearchPrice = searchPrice;
                // ////console.log(copySearchPrice);

                let price = copySearchPrice[indexParent].exams[index].price;
                copySearchPrice[indexParent].exams[index].percentage = rangeval;

                let percentagePrice = ((price * rangeval) / 100);

                let total = price - percentagePrice;
                // ////console.log(total);

                copySearchPrice[indexParent].exams[index].total = total;

                setSearchPrice([...copySearchPrice]);
                //calcularPorcentajeDescuento();
            }
        }
    }, [debounceValue])





    useEffect(() => {
        if (rangevalM) {

            if (indexM !== -1) {

                let copySearchPrice = searchPrice;

                let price = copySearchPrice[indexParentM].exams[indexM].price;
                copySearchPrice[indexParentM].exams[indexM].percentage_with_toma = rangevalM;

                let percentagePrice = ((price * rangevalM) / 100);

                let total_with_toma = price - percentagePrice;

                copySearchPrice[indexParentM].exams[indexM].total_with_toma = total_with_toma;

                setSearchPrice([...copySearchPrice]);
            }
        }
    }, [debounceValueM])



    useEffect(() => {
        if (rangevalMS) {

            if (indexMS !== -1) {

                let copySearchPrice = searchPrice;

                let price = copySearchPrice[indexParentMS].exams[indexMS].price;
                copySearchPrice[indexParentMS].exams[indexMS].percentage_without_toma = rangevalMS;

                let percentagePrice = ((price * rangevalMS) / 100);

                let total_without_toma = price - percentagePrice;

                copySearchPrice[indexParentMS].exams[indexMS].total_without_toma = total_without_toma;

                setSearchPrice([...copySearchPrice]);
            }
        }
    }, [debounceValueMS])


    useEffect(() => {

        console.log(profilesId);

        console.log("wue llab");

        if (profilesId !== undefined) {
            prueba(profilesId);
            getListPriceByProfile(profilesId);
        }

    }, [profilesId])


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({
        name: "",
        abbreviation: "",
        code: "",
        is_critic: false,
        is_curve: false,
        id_profile: 0,
        sampling_cost: 0,
        porcentaje: 0,
    });

    const { name, abbreviation, code, profileExam, id_commercial_line, exams, id_exam, is_critic, is_curve, id_profile, sampling_cost, porcentaje } = formValues;

    console.log(formValues);

    const [validationsProfile, setValidationsProfile] = useState({
        nameValid: false,
        abbreviationValid: false,
        codeValid: false,

    });
    ///diseno/nuevo-perfil/:methodd`

    const navigateUpdate = () => {
        history.push(`../diseno/nuevo-perfil/create`);
    }


    const navigateUpdateP = (profileId) => {
        console.log(profileId);
        history.push(`../diseno/${profileId}/editar-perfil/update`);
    }

    const navigateCosts = (profileId) => {
        history.push(`../diseno/${profileId}/costs`);
    }

    const navigateGlobal = (profileId) => {
        history.push(`../diseno/${profileId}/global`);
    }

    useEffect(() => {

        if (searchPrice.length >= 1) {

            let copy = totalCommercialLines;

            if (copy.length >= 1) {

                copy.forEach(obj => {
                    console.log("aaaaaaaaaaaaaaaaaaaa");
                    console.log(obj);

                    let id_commercial_line = obj.id_commercial_line;
                    let id_rate = obj.id_rate;
                    console.log(id_rate);

                    let find = searchPrice.find(x => x.id_rate == id_rate);
                    console.log(find);


                    if (find) {
                        console.log("que llevas");

                        let sum = 0;
                        let sumPublico = 0;
                        let sumP = 0;
                        let sumTp = 0;
                        let sumTsM = 0;
                        let min = Infinity;
                        let max = 0;
                        let minimoPrecio = 0;


                        find.exams.forEach(o => {
                            sum += (o.total + o.total_with_toma + o.total_without_toma);
                            sumPublico += o.price
                            sumP += o.total
                            sumTp += o.total_without_toma
                            sumTsM += o.total_with_toma
                            minimoPrecio += o.production_price

                            if (sum > 0 && min > sum)
                                min = sum

                            if (sum > 0 && max < sum)
                                max = sum;

                        });
                        obj.total = sum
                        obj.total_venta_publico_general = sumPublico;
                        obj.descuento_Publico = sumP;
                        obj.descuento_Maquila_sin_toma = sumTp;
                        obj.descuento_Maquila_con_toma = sumTsM;
                        obj.minimoProduccion = minimoPrecio;

                        obj.minn = min;
                        obj.maxx = max;
                        if (!Number.isFinite(min)) {
                            min = 0
                        }
                    }
                    setTotalCommercialLines([...copy])

                });
            }
            else {
                searchPrice.forEach(obj => {
                    console.log("bbbbbbbbbbbbbbbbbbb");
                    console.log(obj);
                    let id_commercial_line = obj.id_rate;
                    let ttotal = 0;
                    let ttotalPublico = 0;
                    let ssumP = 0;
                    let ssumTp = 0;
                    let ssumTsM = 0;
                    let min = Infinity;
                    let max = 0;
                    let minimoPrecio = 0;
                    let id_rate = obj.id_rate;



                    obj.exams.forEach(o => {
                        ttotal += (o.total + o.total_with_toma + o.total_without_toma);
                        ttotalPublico += (o.price)
                        ssumP += o.total
                        ssumTp += o.total_without_toma
                        ssumTsM += o.total_with_toma
                        minimoPrecio += o.production_price


                        if (ttotal > 0 && min > ttotal)
                            min = ttotal

                        if (ttotal > 0 && max < ttotal)
                            max = ttotal;

                    });
                    if (!Number.isFinite(min)) {
                        min = 0
                    }
                    copy.push({
                        id_commercial_line: id_rate,
                        name: obj.name_commercial_line,
                        color_commercial_line: obj.color_commercial_line,
                        total: ttotal,
                        total_venta_publico_general: ttotalPublico,
                        descuento_Publico: ssumP,
                        descuento_Maquila_sin_toma: ssumTp,
                        descuento_Maquila_con_toma: ssumTsM,
                        minn: min,
                        maxx: max,
                        minimoProduccion: minimoPrecio,
                        id_rate: id_rate,
                        name_rate: obj.name_rate,



                    })
                    setTotalCommercialLines([...copy]);

                });
            }
        }
        else {
            let mArray = [];
            searchPrice.forEach(obj => {

                console.log("ccccccccccccccccccccccccc");

                console.log(obj);
                let id_commercial_line = obj.id_commercial_line;
                let ttotal = 0;
                let ttotalPublico = 0;
                let ssumP = 0;
                let ssumTp = 0;
                let ssumTsM = 0;
                let min = Infinity;
                let max = 0;
                let id_rate = obj.id_rate;


                obj.exams.forEach(o => {
                    ttotal += (o.total + o.total_with_toma + o.total_without_toma);
                    ttotalPublico += obj.price
                    ssumP += o.total
                    ssumTp += o.total_without_toma
                    ssumTsM += o.total_with_toma

                    if (ttotal > 0 && min > ttotal)
                        min = ttotal

                    if (ttotal > 0 && max < ttotal)
                        max = ttotal;

                });

                if (!Number.isFinite(min)) {
                    min = 0
                }

                mArray.push({
                    id_commercial_line: id_commercial_line,
                    name: obj.name_commercial_line,
                    color_commercial_line: obj.color_commercial_line,
                    total: ttotal,
                    total_venta_publico_general: ttotalPublico,
                    descuento_Publico: ssumP,
                    descuento_Maquila_sin_toma: ssumTp,
                    descuento_Maquila_con_toma: ssumTsM,
                    minn: min,
                    maxx: max,
                    id_rate: id_rate,
                    name_rate: obj.name_rate,
                })

                setTotalCommercialLines([...mArray])
            });
        }

    }, [searchPrice])

    const [updateProfile, setUpdateProfile] = useState([])


    const prueba = async (profilesId) => {
        let requestOptions = {
            method: 'GET',
        };
        let car = true
        const respuesta = await sendRequest(requestOptions, `Profiles/ListDetailProfile/${profilesId}`);
    console.log(respuesta);
        if (respuesta.code == 200) {

            setUpdateProfile(respuesta.data)
            handleUpdateValues({
                name: respuesta.data.name,
                abbreviation: respuesta.data.abbreviation,
                code: respuesta.data.code,
                is_critic: respuesta.data.is_critic,
                is_curve: respuesta.data.is_curve,
                id_profile: respuesta.data.id_profile,
                sampling_cost: respuesta.data.sampling_cost,
                porcentaje: 0,

                // REGRESAR
            });

            setSearchPrice([
                ...respuesta.data.listProfileCommercialLines
            ]);
        }
    }

    const getListPriceByProfile = async (profile_id) => {

        console.log(profile_id);
        let requestOptions = {
            method: 'GET'
        }

        const respuesta = await sendRequest(requestOptions, `Profiles/getPriceFromRates/${profile_id}`);
        console.log(respuesta);
        console.log("que lleva aqui");

        if (respuesta.code === 200) {
            setListFirstPrice(respuesta.data);
        }

    }

    const [dataSeleccionado, setDataSeleccionado] = useState({});

    const handleChange = (e, id_exam, idParent, idChildren) => {
        // handleInputChange(e)
        setDataSeleccionado({
            e, id_exam, idParent, idChildren
        });

        setIdTest(id_exam)

        setIndex(idChildren)

        setIndexParent(idParent)

        setRangeval(e.target.value)


        let copySearchPrice = searchPrice;

        copySearchPrice[idParent].exams[idChildren].percentage = e.target.value;

        let price = copySearchPrice[idParent].exams[idChildren].price;

        let percentagePrice = ((price * parseFloat(e.target.value)) / 100);

        let total = price - percentagePrice;

        copySearchPrice[idParent].exams[idChildren].total = total;

        setSearchPrice([...copySearchPrice]);
    }


    const handleChangeSinToma = (e, id_exam, idParent, idChildren) => {

        setIdTestMS(id_exam)

        setIndexMS(idChildren)

        setIndexParentMS(idParent)

        setRangevalMS(e.target.value)

        let copySearchPrice = searchPrice;

        copySearchPrice[idParent].exams[idChildren].percentage_without_toma = e.target.value;

        let price = copySearchPrice[idParent].exams[idChildren].price;

        let percentagePrice = ((price * parseFloat(e.target.value)) / 100);

        let total_without_toma = price - percentagePrice;

        copySearchPrice[idParent].exams[idChildren].total_without_toma = total_without_toma;

        setSearchPrice([...copySearchPrice]);
    }

    const handleChangeConToma = (e, id_exam, idParent, idChildren) => {

        setIdTestM(id_exam)

        setIndexM(idChildren)

        setIndexParentM(idParent)

        setRangevalM(e.target.value)

        let copySearchPrice = searchPrice;

        copySearchPrice[idParent].exams[idChildren].percentage_with_toma = e.target.value;

        let price = copySearchPrice[idParent].exams[idChildren].price;

        let percentagePrice = ((price * parseFloat(e.target.value)) / 100);

        let total_with_toma = price - percentagePrice;

        copySearchPrice[idParent].exams[idChildren].total_with_toma = total_with_toma;

        setSearchPrice([...copySearchPrice]);

    }

    const handleChangeExam = (e) => {
        handleInputChange(e)

        let examProfile = e.target.value;

        if (examProfile !== 0 && examProfile !== null) {

            examProfile = examProfile.split("-");

            let mIdExam = examProfile[0];
            let mTipo = examProfile[1];

            if (mTipo === "1") {
                createSweet("error", "error", "No se pueden agregar perfiles ");
            }
            else {
                getPriceExam(mIdExam)
            }
        }
    }

    const getPriceExam = async (mIdExam) => {

        let requestOptions = {
            method: 'GET'
        };

        let listPriceExams = searchPrice;
        let listSum = totalCommercialLines;

        const respuesta = await sendRequest(requestOptions, "TabPriceExam/SearchPriceExam/" + mIdExam);
        console.log(respuesta);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((value) => {

                    console.log("gatitos");

                    if (listPriceExams.length === 0) {

                        listPriceExams.push({
                            id_commercial_line: value.id_commercial_line,
                            name_commercial_line: value.name_commercial_line,
                            color_commercial_line: value.color_commercial_line,
                            name_rate: value.name_rate,
                            id_rate: value.id_rate,


                            exams: [
                                {
                                    id_exam: value.id_exam,
                                    name_exam: value.name_exam,
                                    code_exam: value.code_exam,
                                    id_price: value.id_price,
                                    price: value.price,
                                    percentage: 0,
                                    total: value.price,
                                    production_price: value.production_price,
                                    alertClass: "",
                                    max: 0,
                                    percentage_without_toma: 0,
                                    total_without_toma: value.price,
                                    percentage_with_toma: 0,
                                    total_with_toma: value.price,
                                    totales: listSum
                                }
                            ]
                        })
                    }
                    else {

                        console.log(listPriceExams);

                        let findCommercialLine = listPriceExams.find(c => c.id_rate == value.id_rate);
                        console.log(findCommercialLine);
                        let findIndexCommercialLine = listPriceExams.findIndex(c => c.id_rate == value.id_rate);
                        console.log(findIndexCommercialLine);


                        if (findCommercialLine) {

                            let findExam = findCommercialLine.exams.find(e => e.id_exam == value.id_exam)
                            console.log(findExam);
                            if (!findExam) {

                                findCommercialLine.exams.push({
                                    id_exam: value.id_exam,
                                    name_exam: value.name_exam,
                                    code_exam: value.code_exam,
                                    id_price: value.id_price,
                                    price: value.price,
                                    percentage: 0,
                                    total: value.price,
                                    production_price: value.production_price,
                                    alertClass: "",
                                    max: 0,
                                    percentage_without_toma: 0,
                                    total_without_toma: value.price,
                                    percentage_with_toma: 0,
                                    total_with_toma: value.price,
                                    totales: listSum
                                })
                                listPriceExams[findIndexCommercialLine] = findCommercialLine;
                            }
                        }
                        else {

                            console.log("aqui entro");

                            listPriceExams.push({
                                id_commercial_line: value.id_commercial_line,
                                name_commercial_line: value.name_commercial_line,
                                color_commercial_line: value.color_commercial_line,
                                name_rate: value.name_rate,
                                id_rate: value.id_rate,


                                exams: [
                                    {
                                        id_exam: value.id_exam,
                                        name_exam: value.name_exam,
                                        code_exam: value.code_exam,
                                        id_price: value.id_price,
                                        price: value.price,
                                        percentage: 0,
                                        total: value.price,
                                        production_price: value.production_price,
                                        alertClass: "",
                                        max: 0,
                                        percentage_without_toma: 0,
                                        total_without_toma: value.price,
                                        percentage_with_toma: 0,
                                        total_with_toma: value.price,
                                        totales: listSum
                                    }
                                ]
                            })
                        }
                    }
                });
                setSearchPrice([...listPriceExams]);
                changeOrder();
            }

            else {
                createSweet("error", "error", "Hubo un error!", "Sin información");
            }
        }
        else if (respuesta.code === 409) {
            createSweet("warning", "warning", "Aviso", respuesta.data.msg);

        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
            validarSesion(history, respuesta.code, getPriceExam);
        }
    }

    const getProfiles = async () => {

        let requestOptions = {
            method: 'GET',
        };

        let ListProfiles = [];


        const respuesta = await sendRequest(requestOptions, "Profiles");
        if (respuesta.code === 200) {

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            
            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {

                respuesta.data.forEach((element, index) => {
                    let indiceE = element.profileExams.length

                    let min = Infinity;
                    let max = 0;

                    element.priceProfileViewModel.forEach(sucursal => {

                        if (sucursal.price > 0 && min > sucursal.price)
                            min = sucursal.price;

                        if (sucursal.price > 0 && max < sucursal.price)
                            max = sucursal.price;
                    });

                    if (!Number.isFinite(min)) {
                        min = 0
                    }

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }

                    ListProfiles.push({
                        id_profile: element.id_profile,
                        name: element.name,
                        abbreviation: element.abbreviation,
                        code: element.code,
                        is_critic: element.is_critic,
                        profileExams: element.profileExams,
                        priceProfileViewModel: element.priceProfileViewModel,
                        labelExa: element.labelExa !== null ? <Badge color='primary' pill>{indiceE}&nbsp;Exámenes</Badge> : "",
                        labelExas: element.labelExas !== null ? <Badge color='info' pill> ${min.toFixed(2)} -  ${max.toFixed(2)}</Badge> : "",
                        visibleTable: visibleTable
                    })
                });
                setProfiles(ListProfiles);
                setProfilesData(ListProfiles);
                setListProfiles(ListProfiles)
            }
        }
        else {
            validarSesion(history, respuesta.code, getProfiles);
        }
    }

    const saveCreate = async () => {

        if (validarProfile()) {
            setLoading(false);
            return;
        }

        let body = {
            name: formValues.name,
            abbreviation: formValues.abbreviation,
            code: formValues.code,
            is_critic: formValues.is_critic,
            is_curve: formValues.is_curve,
            profileExam: searchPrice
        }


        console.log(body);

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Profiles");

        if (respuesta.code === 200) {

            setLoading(false);
            createSweet("success", "success", "Se creo correctamente el perfil");
            handleUpdateValues({
                searchPrice: [],
                name: "",
                abbreviation: " ",
                code: " ",
                is_critic: false,
                is_curve: false
            });

            setTimeout(() => {
                window.location.href = `${process.env.PUBLIC_URL}/diseno/perfiles`
            }, 1000);
            setSendKitSampling(respuesta.data.id_profile);

        }
        else {
            setLoading(false);
            createSweet("error", "warning", "Examen", respuesta.data.msg);
        }
    }

    const handleListProfile = (id_profile) => {

        let profile = profiles.find(a => a.id_profile === parseInt(id_profile));

        ////console.log(profile);

        if (profile != null) {
            if (profile.profileExams.length >= 1) {
                setListProfile(profile.profileExams)
            }
            else {
                setListProfile([]);
            }

            setMethod("list");
            setModalTitle("Examenes");
            toggle();

        }
        else {
            setListProfile([]);
            createSweet("error", "error", "No se encontraron los datos seleccionados");
        }
    }


    const handleListComercial = (id_profile) => {

        let profile = profiles.find(a => a.id_profile === parseInt(id_profile));

        if (profile != null) {
            if (profile.priceProfileViewModel.length >= 1) {
                setListCommercial(profile.priceProfileViewModel)
            }
            else {
                setListCommercial([]);
            }
            setMethod("list");
            setModalTitle("Precios");
            toggle3();
        }
        else {
            setListCommercial([]);
            createSweet("error", "error", "No se encontraron los datos seleccionados");
        }
    }

    const handleDelete = (id_profile) => {

        let profile = profiles.find(a => a.id_profile === parseInt(id_profile));

        handleUpdateValues({
            abbreviation: profile.abbreviation,
            code: profile.code,
            name: profile.name
        });

        setProfileId(id_profile)
        setMethod("delete");
        setModalTitle("Eliminar perfil");
        toggle2();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };
        const respuesta = await sendRequest(requestOptions, `Profiles/${profileId}`);

        if (respuesta.code === 200) {
            getProfiles();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Perfil Eliminado");
            toggle2();
        }
        else {
            createSweet("error", "error", "Hubo un error!", LostConnection);
            setLoading(false);
            validarSesion(history, respuesta.code, saveDelete);
        }
    }

    const saveUpdate = async () => {

        setLoading(true);

        console.log("form3",updateProfile);

        let body = {

            id_profile: profilesId,
            name: formValues.name,
            abbreviation: formValues.abbreviation,
            code: formValues.code,
            is_critic: formValues.is_critic,
            is_curve: formValues.is_curve,
            profileExam: searchPrice
        }

        console.log(body);

        let requestOptions = {
            method: 'PUT',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Profiles");

        if (respuesta.code === 200) {

            setLoading(false);
            createSweet("create", "info", "Exito!", "Examenes Actualizados");

            handleUpdateValues({
                searchPrice: [],
                name: "",
                abbreviation: " ",
                code: " ",
                is_critic: false,
                is_curve: false,
                sampling_cost: sampling_cost
            });
            history.goBack()

        }
        else {
            setLoading(false);
            createSweet("error", "error", "Hubo un error!");
        }
    }

    const handleDeleteExamGlobal = (id_profile_exam, id_exam, indice) => {

        let test = searchPrice;

        if (id_profile_exam === undefined) {
            //ELIMINAR SOLO DEL ARREGLO

            test.forEach((obj, i) => {

                if (obj.exams.length > 0) {

                    obj.exams.splice(indice, 1);
                }
            });

            handleUpdateValues({
                ...formValues,
                ...searchPrice,
                searchPrice: [...test]
            })

            setSearchPrice([...searchPrice])
        }
        else {
            test.forEach((obj, i) => {

                obj.exams.forEach(async (o, index) => {

                    let FindExamProfile = o.id_profile_exam

                    if (id_profile_exam === FindExamProfile) {

                        let requestOptions = {
                            method: 'DELETE'
                        }

                        const respuesta = await sendRequest(requestOptions, `Profiles/Delete/${profilesId},${id_profile_exam}`);
                        if (respuesta.code === 200) {

                            test[i].exams.splice(index, 1)

                            handleUpdateValues({
                                ...formValues,
                                ...searchPrice,
                                searchPrice: [...test]

                            })
                            setSearchPrice([...searchPrice])

                            createSweet("success", "success", "Se elimino correctamente el exámen")
                        }
                    }
                });
            });
        }
    }

    const validarProfile = () => {

        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "" || formValues.name === undefined) {
            newValidations = {
                ...newValidations,
                nameValid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                nameValid: false
            };
        }

        if (formValues.abbreviation === "" || formValues.abbreviation === undefined) {
            newValidations = {
                ...newValidations,
                abbreviationValid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                abbreviationValid: false
            };
        }

        if (formValues.code === "" || formValues.code === undefined) {
            newValidations = {
                ...newValidations,
                codeValid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                codeValid: false
            };
        }

        setValidationsProfile(newValidations);
        return statusValidation;

    }

    const changeContinueCreation = () => {
        setContinueCreation(!continueCreation);
    }

    useEffect(() => {
        if (continueCreation) {
            history.goBack();
        }
    }, [continueCreation])

    const numberItems = 10;

    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(profiles.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listProfiles = [];
        profiles.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listProfiles.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setProfiles(listProfiles);

    }, [totalPageCount, currentPage]);

    const [profilesData, setProfilesData] = useState([]);
    const [listProfiles, setListProfiles] = useState([]);

    const handleSearchProfile = (e) => { 

        let busqueda = e.target.value;
        let newMethod = [];

        profilesData.forEach(element => {
            //////console.log(element);
            let cadena = element.name.toUpperCase();
            let abbreviation = element.abbreviation.toUpperCase();
            let code = element.code.toUpperCase();

            if ((cadena.indexOf(busqueda.toUpperCase()) > -1) || (abbreviation.indexOf(busqueda.toUpperCase()) > -1) || (code.indexOf(busqueda.toUpperCase()) > -1)) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setProfiles(newMethod);
    }

    const handleOrderByExam = () => {

        toggleOrderByExam();
        setMethodOrder("OrderBy")
        setTitleOrder("Ordenar Exámenes");
        changeOrder();
    }

    const [data, setData] = useState([]);

    const changeOrder = () => {

        let copy = searchPrice;

        ////console.log("Se ejecuta despues de cargar los perfiles", copy);

        let arrayExams = [];

        copy.forEach(element => {

            let arrExams = element.exams;

            arrExams.forEach(e => {
                if (arrayExams.length > 0) {
                    let findExam = arrayExams.find(x => x.id_exam === parseInt(e.id_exam));

                    if (!findExam) {
                        arrayExams.push({
                            id_exam: e.id_exam,
                            title: e.name_exam
                        });
                    }
                }
                else {
                    arrayExams.push({
                        id_exam: e.id_exam,
                        title: e.name_exam
                    });
                }
            });
        });

        setData([...arrayExams]);
    }

    const cambiar = (info) => {
        setData(info);
    }

    const handleSaveOrderExamsAnalyte = (orderData) => {

        if (orderData.length > 0) {

            let tmpExams = searchPrice;

            tmpExams.forEach(element => {

                let arrExams = element.exams;

                let tmpArrayExams = [];

                orderData.forEach((e, i) => {

                    let findExam = arrExams.find(x => x.id_exam === parseInt(e.id_exam));

                    if (findExam) {
                        tmpArrayExams.push({
                            ...findExam,
                            order: (i + 1)
                        });
                    }
                });

                element.exams = tmpArrayExams;
            });

            setSearchPrice(tmpExams);
            toggleOrderByExam();
        }
    }

    const [findRate, setFindRate] = useState(null);

   


    const [resul, setResul] = useState(0);
    const [idrate, setIdrate] = useState(null)

 

    function calcularPorcentajeDescuento(PrecioTotal, PrecioDeseado,llave) {

        console.log(PrecioTotal);
        console.log(PrecioDeseado);

        let array = searchPrice;

        let pTotal = parseFloat(PrecioTotal === "" ? 0 : PrecioTotal);
        let pDeseado = parseFloat(PrecioDeseado === "" ? 0 : PrecioDeseado)


        if (pTotal <= 0) return 0;
        if (pDeseado <= 0) return 0;
        // if(pTotal < pDeseado) return 0;
        try {
            var porcentaje = ((pTotal - pDeseado) / pTotal) * 100;
            porcentaje *= 100;
            porcentaje = Math.floor(porcentaje) / 100;
            console.log(porcentaje);
            setResul(porcentaje)

            let otro = array.map(element => {
                console.log(element);

                if (element.id_rate === llave ) {
                    console.log("pasas");

                    let newExams = element.exams.map(a => {
                        a.percentage = porcentaje;
    
                        let percentagePrice = ((a.price * parseFloat(porcentaje)) / 100);
    
                        let total_with_toma = a.price - percentagePrice;
    
                        a.total = total_with_toma;
    
                        return a;
                    });
                    element.exams = [...newExams];   
                }

                
                if (element.id_rate === idrate ) {

                    let newExams = element.exams.map(a => {
                        a.percentage = porcentaje;
    
                        let percentagePrice = ((a.price * parseFloat(porcentaje)) / 100);
    
                        let total_with_toma = a.price - percentagePrice;
    
                        a.total = total_with_toma;
    
                        return a;
                    });
                    element.exams = [...newExams];   
                }
               
                return element;
            });

            console.log(otro);

            setSearchPrice([...otro])
            togglePrice();

        }
        catch (error) {
            return 0;

        }

        togglePrice();

    }


    //#region modalChart

    const [titlePrice, setTitlePrice] = useState("");
    const [typeprice, setTypeprice] = useState("viewPrices");
    const [modalPrice, setModalPrice] = useState(false);


    const [llavecard, setLlavecard] = useState("");
    const [arrayExams, setArrayExams] = useState([]);





    const togglePrice = () => {
        setModalPrice(!modalPrice)
    }


    const handleprices = (keyNim, array,_llave) => {
        console.log(keyNim);
        console.log(array);
        console.log(_llave);

        setTypeprice("viewPrices");
        setTitlePrice("Precio");
        setLlavecard(keyNim);
        setArrayExams(array);
        setIdrate(_llave)
        togglePrice();
        handleUpdateValues({
            ...formValues,
            porcentaje: 0,

        })
       
    }





    //#endregion modalChart







    return {
        //FORMVALUES
        name, abbreviation, code, profileExam, id_commercial_line, exams, id_exam, is_critic, is_curve, profiles, sampling_cost,
        handleInputChange, handleSelectValues,
        //  handleCreate,
        exam, searchPrice, handleChangeExam,
        //DEBOUNCE % EXAM
        handleChange, rangeval, index, idTest,
        // price,
        saveCreate, handleDeleteExamGlobal, navigateUpdate, totalCommercialLines,
        //UPDATE EXAM
        saveUpdate, id_profile,
        //profilesList
        handleListProfile, listProfile, modal, toggle, modalTitle, method,
        //DELETE PROFILE
        handleDelete, saveDelete, toggle2, modal2,
        //update
        navigateUpdateP,
        //profile list commercial
        handleListComercial, toggle3, listCommercial, modal3,
        //VALIDATIONS
        validationsProfile,
        handleChangeSinToma, handleChangeConToma,
        navigateCosts,
        idTestM, idTestMS, indexM, indexMS, rangevalM, rangevalMS,
        sendKitSampling, continueCreation, changeContinueCreation, id_profile,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,
        handleSearchProfile,
        listFirstPrice,
        //OrderByExam
        handleOrderByExam, toggleOrderByExam, methodOrder, titleOrder, modalOrder,
        data, changeOrder, cambiar, handleSaveOrderExamsAnalyte,
        //modal Prices
        titlePrice, typeprice, handleprices, togglePrice, modalPrice, calcularPorcentajeDescuento, resul, porcentaje, handleInputChange, arrayExams, llavecard,
        setFindRate,findRate,
        //Global
        navigateGlobal



    }
}