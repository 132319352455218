const formatPhone = (_phone) => {
    console.log(_phone);
    let statusValidation = false;
    let _message = "";

    let phoneString = _phone.toString();

    if (phoneString !== "") {

        const phoneRegex = /^\d{10}$/;

        let _phones = phoneString.split(',');

        for (let phone of _phones) {
            phone = phone.trim(); 
            
            if (!phoneRegex.test(phone)) {
                _message = `Advertencia: El teléfono '${phone}' no es válido.`;
                statusValidation = true;
            }
        }
    }
    
    console.log(_message);
    return {
        status: statusValidation,
        message: _message
    };
}

export default formatPhone;
