import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, Pagination, FormGroup, Label, Table, Input, InputGroupAddon, InputGroupText, InputGroup, FormFeedback, CardHeader, Nav, NavItem, FormText } from 'reactstrap';
import { examReference, codeadd, descriptionadd, costoadd, recepcion, accionesadd, profileExam, ButtonGoBack, save, update, PlaceHolderOption } from '../../../constant';
import { useUpdateLaboratories } from '../../../hooks/laboratoriesExternal/useUpdateLaboratories';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import './StyleLaboratoriesExternos.css';
import { ModalExternalLaboratories } from '../../../components/ExternalLaboratories/ModalExternalLaboratories';

export const IndexUpdateLaboratories = () => {

	const history = useHistory();

	const { 
		//MODALS
		modalTitle, modalNewPrice, toggleModalNewPrice, loading,

		//MODELS NEW PRICE
		id_exam, price, from_price, to_price,

		//MODELS
        externalTest,

        //LIST
        informationLaboratory , listExams,

        //HANDLE METHODS
		handleInputChangeNewPrice, handlePickerValues,

        handleSelectExamChange,

		//REQUEST METHODS
        saveCreatePrice,
		
		//VALIDATIONS 
        validationNewPrice,

		// *DEFAULT
		handleCheckboxChange,

		// * PAGINATION
		totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,handleSearch

	} = useUpdateLaboratories();

	console.log(externalTest);
	

	

	return (
		<Fragment>
			<Container fluid={true}>
			<Col sm="12" className='p-l-0 p-r-0' >
			<Row>
				<Col sm="10">
				<h5>Laboratorio de Referencia:{informationLaboratory?.name ?? "-------"} </h5>		
				</Col>
				<Col sm="2" style={{textAlign:"end"}} >
				<Button color='dark' size='sm' onClick={() => history.goBack()}>Regresar</Button>
				</Col>
			</Row>
			<Col sm="12" className='pt-3 p-l-0 p-r-0'  >
				<Select
				    className="input-air-primary input-xs"
					classNamePrefix="select"
					name="id_exam"
					value={id_exam}
					options={listExams}
					placeholder="Selecciona un estudio para este laboratorio"
					isClearable={true}
					onChange={(e) => handleSelectExamChange(e)}
				/>
			</Col>	
			<Col sm="12" className='p-l-0 p-r-0 pt-4'>
			<Card className='shadow'  >
				<Col sm="12" style={{padding : "20px"}}>
				<Row>
					<Col sm="8">
					<b className='f-14'>Estudios Asignados</b> 
					</Col>
					<Col sm="4">
					<Input placeholder='Búsqueda...' className='form-control' onChange={handleSearch} />
					</Col>
				</Row>
				<div className='hr-detail-patient pt-3' >
				</div> 
				<div className='pt-4'>
					<Table className='b-r-5 table table-xs'>
					<thead className='b-r-5' >
						<tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
						<th style={{textAlign:"center"}} >Código</th>
						<th style={{textAlign:"center"}} >Estudio</th>
						<th style={{textAlign:"center"}} >Costo</th>
						<th style={{textAlign:"center"}} >Precio de Venta</th>
						<th style={{textAlign:"center"}} >Valido apartir de  </th>
						<th style={{textAlign:"center"}} >Valido hasta </th>
						<th style={{textAlign:"center"}} >Default </th>
						</tr>
					</thead>
					<tbody>
						{
							externalTest.length === 0 &&
							<td colSpan={7} className=' text-center f-16'>
							<img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}  style={{ width: "20%" }} alt="" />   <br /> 
							<label className='txt-secondary'>No se a cargado ningún estudio</label>                                        
							</td>
						}
						{
							externalTest.length > 0 && loading && <tr>
							<td colSpan={7} className='bg-info text-center f-16'>
								<i className="fa fa-spin fa-spinner"></i> Cargando...
							</td>
						</tr>
						}
					{!loading &&externalTest.length > 0 && externalTest?.map((_find, _key) => {

						if (_find.visibleTable) {
							let _price2 = _find?.price.toFixed(2);
							let _price = _find?.cost === null ? "" : _find?.cost.toFixed(2);

							
							return (
								<tr key={_key}>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}>
									<Col sm="12" className='badge-detail-external' >
										<Row>
											<Col sm="4" className='p-r-0' >
											<i className="fa fa-angle-right f-12"></i>
											</Col>
											<Col sm="8" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
											{_find?.code_exam ?? "----"}
											</Col>
										</Row>
									</Col>	
									</td>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}><label style={{color:"#6c757d"}}>{_find?.name_exam ?? "----"}</label> </td>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}>$<label style={{color:"#6c757d"}}>{_price ?? "----"}</label> </td>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}>$<label style={{color:"#6c757d"}}>{_price2 ?? "----"}</label> </td>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}><label style={{color:"#6c757d"}}>{_find?.valid_from ?? "----"}</label> </td>
									<td style={{ verticalAlign: "middle", textAlign: "center" }}>
										{
										_find.valid_to === "" ?
										<Col sm="7" className='badge-detail-external-vigente' >
										<Row>
											<Col sm="4" className='p-r-0' >
											<i className="fa fa-circle-o f-12"></i>
											</Col>
											<Col sm="8" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
											 Vigente
											</Col>
										</Row>
										</Col>	
										:
										<label style={{color:"#6c757d"}}>{_find?.valid_to ?? "----"}</label>
										}
									</td>
									<td style={{ textAlign: "center" }}>
										<Input type='checkbox' name="is_default_laboratory" checked={_find.is_default_laboratory} onChange={(e) => handleCheckboxChange(e, _key)} />
									</td>
								</tr>
								);
						}
					
						})}
					</tbody>
					</Table>

				</div>
				<Table >
                    <tbody>
                        <tr>
                            <td>
                                <Pagination aria-label='...' style={{ justifyContent: "right" }}>
                                    <ul className='pagination pagination-primary'>
                                        {
                                            currentPage1 === 1 ?
                                                <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item' onClick={previousPage1} ><span className='page-link'>Anterior</span></li>
                                        }
                                        {
                                            currentPage1 > 1 &&
                                            <>
                                                <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => goToPage1(1)} ><span className='page-link'>1</span></li>
                                                {
                                                    currentPage1 > 2 &&
                                                    <>
                                                        <li className="page-item"><span className='page-link'>...</span></li>
                                                        <li style={{ cursor: "pointer" }} onClick={() => goToPage1(currentPage1 - 1)} className='page-item'><span className='page-link'>{currentPage1 - 1}</span></li>
                                                    </>
                                                }
                                            </>
                                        }
                                        <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage1}</span></li>
                                        {
                                            currentPage1 < totalPageCount1 &&
                                            <>
                                                {
                                                    currentPage1 < (totalPageCount1 - 1) &&
                                                    <>
                                                        <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(currentPage1 + 1)} className='page-item'><span className='page-link'>{currentPage1 + 1}</span></li>
                                                        <li className='page-item'><span className='page-link'>...</span></li>
                                                    </>
                                                }
                                                <li style={{ cursor: 'pointer' }} onClick={() => goToPage1(totalPageCount1)} className='page-item'><span className='page-link'>{totalPageCount1}</span></li>
                                            </>
                                        }
                                        {
                                            totalPageCount1 === currentPage1 ?
                                                <li style={{ cursor: 'pointer' }} className='page-item disabled primary' onClick={nextPage1}><span className='page-link primary'>Siguiente</span></li>
                                                : <li style={{ cursor: "pointer" }} className='page-item primary' onClick={nextPage1}><span className='page-link primary'>Siguiente</span></li>
                                        }
                                    </ul>
                                </Pagination>
                            </td>
                           
                        </tr>
                    </tbody>
                </Table>

				</Col>


			</Card>
			</Col>
		</Col>
				
				<ModalExternalLaboratories
					{
						... {
							modalNewPrice, toggleModalNewPrice, modalTitle, 

							id_exam, price, from_price, to_price,

							handleInputChangeNewPrice, handlePickerValues,

							saveCreatePrice, loading, validationNewPrice
						}
					}
				/>
			</Container>
		</Fragment>
	)
}
