import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { TabMachine, TabBranchMachine } from '../../../constant';
import { IndexMachine as ComponentMachine } from '../../machine/IndexMachine';
import { IndexBranchMachine } from '../../branchMachine/IndexBranchMachine';


export const IndexMachine = (props) => {

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }
    
    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title="Listado de equipos" /> */}
            {/* .nav-link.active, .show > .nav-link { */}
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <Nav className="nav-primary nav-fill flex-column flex-md-row round" tabs>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "1" ? 'active' : ''} onClick={() => toggleTab("1")}><i className="fa fa-cog"></i> { TabMachine }</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#javascript" role="tab" className={activeTab === "2" ? 'active' : ''} onClick={() => toggleTab("2")}><i className="fa fa-building-o"></i>{ TabBranchMachine }</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane className="fade show" tabId="1">
                                    <ComponentMachine
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                                <TabPane className="fade show" tabId="2">
                                    <IndexBranchMachine
                                        {...{...props, activeTab}}
                                    />
                                </TabPane>
                            </TabContent>
                        </Card>
                    </Col>
                </Row>
            </Container> 
        </Fragment>
    )
}
