import React, { useState } from 'react';
import classnames from "classnames";
import { Form, ModalBody, Col, Row, ModalFooter, Button, NavItem, Nav, NavLink, Card, TabContent, TabPane, CardBody, CardSubtitle, Input, Label, FormGroup, InputGroupAddon, InputGroupText, FormFeedback, Media, FormText } from 'reactstrap';
import preview from '../../assets/images/appointment/app-ent.jpg';
import './styleSucursales.css';


export const FormBranch = (props) => {

    const {
        toggle, loading,
        handleInputChange, validaciones, methodAction,
        id_commercial_line, name, code, number_phone, email, id_state, id_municipality,
        address, colony, cp, url, logo, name_responsible, certificate, image_firm, toma,
        imageLogo, imageFirm, imageBackground,
        button,
        states, municipalities, commercialLines,
        handleChangeState,
        previewLogo, previewFirm, previewBackground,
        imageHandler,roleUser_responsible
    } = props;

    const {
        id_commercial_line_valid,
        code_valid,
        name_valid,
        number_phone_valid,
        email_valid,
        id_state_valid,
        id_municipality_valid,
        address_valid,
        colony_valid,
        cp_valid,
        name_responsible_valid,
        certificate_valid,roleUser_responsible_valid
    } = validaciones;

    const [activeTab, setActiveTab] = useState(1);

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    return (
        <Form className="form theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <div>
                    <Nav
                        className="nav-fill flex-md-row nav-primary form theme-form"
                        id="tabs-icons-text"
                        tabs
                        role="tablist"
                    >
                        <NavItem className='form theme-form'>
                            <NavLink
                                aria-selected={activeTab === 1}
                                className={classnames("mb-sm-3 mb-md-0 form theme-form", {
                                    active: activeTab === 1
                                })}
                                onClick={() => { toggleTab(1); }}
                                href="#"
                                role="tab"
                            >
                                Datos generales
                            </NavLink>
                        </NavItem>
                        <NavItem className='form theme-form'>
                            <NavLink
                                aria-selected={activeTab === 2}
                                className={classnames("mb-sm-3 mb-md-0 form theme-form", {
                                    active: activeTab === 2
                                })}
                                onClick={() => { toggleTab(2); }}
                                href="#"
                                role="tab"
                            >
                                Responsable de resultados
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <Card>
                    <TabContent activeTab={"tabs" + activeTab}>
                        <TabPane tabId="tabs1">
                            <Card>
                                <br />
                                {/* //style={{ border: "#bbbcbd 1px solid"  }} */}
                                <Col sm="12" style={{ alignSelf: "center" }}>
                                    <Row>

                                        <Col sm="3"  >
                                            <Label htmlFor="logo" className="custom-card text-center">
                                                <Media body className="rounded-circle"
                                                    src={
                                                        ((imageLogo !== undefined && imageLogo !== null) && previewLogo === "")
                                                            ? "data:image/png;base64," + imageLogo
                                                            : (previewLogo === "")
                                                                ? require('../../assets/images/user/16.png')
                                                                : previewLogo
                                                    }
                                                    alt="#"
                                                    style={{ width: '120px', height: '120px' }}
                                                />
                                                <Input type="file" id="logo" name="logo" style={{ display: 'none' }} onChange={imageHandler} />
                                            </Label>
                                        </Col>
                                        <Col sm="9"> <br />
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="6">
                                                        <label >Nombre de la Sucursal</label><br />
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            onChange={handleInputChange}
                                                            //invalid={name_valid}
                                                            value={name}
                                                            className="form-control form-control-sm input-air-primary"
                                                            autoComplete='off' disabled
                                                        />
                                                        <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                    </Col>
                                                    <Col sm="6">
                                                        <Label>Código de la sucursal</Label>
                                                        <Input
                                                            className="form-control form-control-sm input-air-primary"
                                                            bsSize="sm"
                                                            autoComplete="off"
                                                            type="number"
                                                            name="code"
                                                            onChange={handleInputChange}
                                                            //invalid={code_valid}
                                                            value={code} />
                                                        <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                    </Col>
                                                </Row>
                                            </Col> <br /> <br />

                                            <Col sm="12">
                                                <Row>
                                                    <Col>
                                                        <label htmlFor="">Razón Social</label>
                                                        <Input disabled type="select"
                                                            className="form-control form-control-sm input-air-primary"
                                                            name="id_commercial_line"
                                                            value={id_commercial_line}
                                                            onChange={handleInputChange}
                                                            invalid={id_commercial_line_valid}>
                                                            <option value={0}>Selecciona una opción</option>
                                                            {
                                                                commercialLines.length >= 1 && commercialLines.map((obj, key) => {
                                                                    return (
                                                                        <option key={key} value={obj.id_commercial_line}>{obj.long_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                        <FormText color="danger" hidden={!id_commercial_line_valid}>Selecciona una opción</FormText>

                                                    </Col>
                                                    <Col>
                                                        <label>Télefonos de contacto (,)</label>
                                                        <Input
                                                            type="text"
                                                            bsSize="sm"
                                                            autoComplete="off"
                                                            name="number_phone"
                                                            onChange={handleInputChange}
                                                            //invalid={number_phone_valid}
                                                            value={number_phone}
                                                            className="form-control form-control-sm input-air-primary" disabled
                                                        />
                                                        <FormFeedback>Debes ingresar al menos un número telefonico</FormFeedback>

                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Col>

                                        <Col sm="12">
                                            <Row>
                                                <Col sm="4" className='p-l-0'>   <br />
                                                    <label>Estado</label>
                                                    <select disabled
                                                        value={id_state}
                                                        size="sm"
                                                        name="id_state"
                                                        className="form-control form-control-sm input-air-primary"
                                                        onChange={handleChangeState}>
                                                        <option value="-1">Selecciona una opción</option>
                                                        {
                                                            states.length > 0 && states.map((s, key) => {
                                                                return <option key={key} value={s.id_state} >{s.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <Input hidden invalid={id_state_valid} />
                                                    <FormFeedback>Debes seleccionar una opción</FormFeedback>
                                                    <br />
                                                    <Col sm="12" className='p-l-0 p-r-0'>
                                                        <label>Dirección</label>
                                                        <Input disabled
                                                            type="text"
                                                            bsSize="sm"
                                                            name="address"
                                                            autoComplete="off"
                                                            onChange={handleInputChange}
                                                            invalid={address_valid}
                                                            value={address}
                                                            className="form-control form-control-sm input-air-primary"
                                                        />
                                                        <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>

                                                    </Col>
                                                </Col>

                                                <Col sm="4"> <br />
                                                    <label>Municipio</label>
                                                    <select disabled
                                                        value={id_municipality}
                                                        size="sm"
                                                        name="id_municipality"
                                                        className="form-control form-control-sm input-air-primary"
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="-1">Selecciona una opción</option>
                                                        {
                                                            municipalities.length > 0 && municipalities.map((m, key) => {
                                                                return <option key={key} value={m.id_municipality} >{m.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <Input hidden invalid={id_municipality_valid} />
                                                    <FormFeedback>Debes seleccionar una opción</FormFeedback>
                                                    <br />
                                                    <Col sm="12" className='p-l-0 p-r-0'>
                                                        <label>Colonia</label>
                                                        <Input disabled
                                                            type="text"
                                                            bsSize="sm"
                                                            name="colony"
                                                            onChange={handleInputChange}
                                                            
                                                            value={colony}
                                                            className="form-control form-control-sm input-air-primary"
                                                            autoComplete='off'
                                                        />
                                                        <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                    </Col>
                                                </Col> <br /> <br /> <br />


                                                <Col sm="4" className="pointerCursor">
                                                    {/* <Label>Fondo para resultados electronicos</Label> */}
                                                    <Label htmlFor="background_image" className="custom-card text-center">
                                                        <Media body className="img-fluid pointerCursor"
                                                            src={
                                                                ((imageBackground !== undefined && imageBackground !== null) && previewBackground === "")
                                                                    ? "data:image/png;base64," + imageBackground
                                                                    : (previewBackground === "")
                                                                        ? require('../../assets/images/user/16.png')
                                                                        : previewBackground
                                                            }
                                                            alt="#"
                                                            style={{ width: '120px', height: '120px' }}
                                                        />
                                                        <Input type="file" id="background_image" name="background_image" style={{ display: 'none' }} accept="image/*" onChange={imageHandler} className="pointerCursor" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="12">
                                    <br />
                                    <Row>
                                        <Col sm="3" className='p-l-0'>
                                            <label>Código postal</label>
                                            <Input disabled
                                                type="number"
                                                bsSize="sm"
                                                name="cp"
                                                onChange={handleInputChange}
                                                invalid={cp_valid}
                                                value={cp}
                                                className="form-control form-control-sm input-air-primary"
                                                autoComplete='off'
                                            />
                                            <FormFeedback>Ingresa un código postal valido</FormFeedback>
                                            <br />
                                            <Col sm="12" className='p-l-0 p-r-0'>
                                                <Label className="col-form-label">¿Solo realiza toma de muestra?</Label>
                                                <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="toma" onChange={handleInputChange} checked={toma} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                        </Col>
                                        <Col sm="5">
                                            <label>Correo electrónico</label>
                                            <Input disabled
                                                type="email"
                                                bsSize="sm"
                                                name="email"
                                                onChange={handleInputChange}
                                                invalid={email_valid}
                                                value={email}
                                                className="form-control form-control-sm input-air-primary"
                                                autoComplete='off'
                                            />
                                            <FormFeedback>Ingresa un correo valido</FormFeedback>
                                        </Col>
                                        <Col sm="4">
                                            <label>Sitio web</label>
                                            <Input
                                                type="text"
                                                bsSize="sm"
                                                name="url"
                                                onChange={handleInputChange}
                                                value={url}
                                                className="form-control form-control-sm input-air-primary"
                                                autoComplete='off'
                                            />
                                        </Col>

                                    </Row>
                                </Col> <br /> <br />                                
                                <Col>
                                </Col>
                            </Card>
                        </TabPane>
                        <TabPane tabId="tabs2">
                            <Card>
                                <CardBody>
                                    <CardSubtitle tag="h5" style={{ textAlignLast: "center" }} className="mb-2 text-muted">Nombre y firma del responsable de resultados</CardSubtitle> <br />
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="1">
                                            </Col>
                                            <Col sm="10" > <br /> <br />
                                                <Col sm="12">
                                                    <Row>
                                                        <Col sm="4">
                                                            <Label>Nombre del responsable</Label>
                                                            <Input
                                                                className="form-control form-control-sm input-air-primary"
                                                                type="text"
                                                                name="name_responsible"
                                                                onChange={handleInputChange}
                                                                invalid={name_responsible_valid}
                                                                value={name_responsible}
                                                                autoComplete='off'
                                                            />
                                                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Label>Cédula profesional</Label>
                                                            <Input
                                                                className="form-control form-control-sm input-air-primary"
                                                                type="text"
                                                                name="certificate"
                                                                onChange={handleInputChange}
                                                                invalid={certificate_valid}
                                                                value={certificate}
                                                                autoComplete='off'
                                                            />
                                                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Label>Puesto que ocupa:</Label>
                                                            <Input
                                                                className="form-control form-control-sm input-air-primary"
                                                                type="text"
                                                                name="roleUser_responsible"
                                                                onChange={handleInputChange}
                                                                invalid={roleUser_responsible_valid}
                                                                value={roleUser_responsible}
                                                                autoComplete='off'
                                                            />
                                                            <FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
                                                        </Col>

                                                        <Col sm="12" style={{ textAlign: "-webkit-center" }}>  <br /> <br />
                                                            <Col sm="4" >
                                                                <Label htmlFor="image_firm" className="custom-card text-center">
                                                                    <Media body
                                                                        src={
                                                                            ((imageFirm !== undefined && imageFirm !== null) && previewFirm === "")
                                                                                ? "data:image/png;base64," + imageFirm
                                                                                : (previewFirm === "")
                                                                                    ? require('../../assets/images/user/16.png')
                                                                                    : previewFirm
                                                                        }
                                                                        alt="#"
                                                                        style={{ width: '120px', height: '120px' }}
                                                                    />
                                                                    <Input type="file" id="image_firm" name="image_firm" style={{ display: 'none' }} accept="image/*" onChange={imageHandler} />
                                                                </Label>

                                                            </Col> <br /> <br />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>                                            
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}

                </Button>
                <Button outline color='danger' type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>
    )
}
