import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteSection } from './DeleteSection';
import { FormSection } from './FormSection';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
import { ModalAddProcess } from './ModalAddProcess';


export const ModalSection = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        name, abbreviation, enable_reception, enable_racks,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete,
        processes, processesSelected, changeSelectProcesses, updateProccesInSection,is_imagenology,is_micro
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' size="lg" keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "addProcesses" ?
                    <ModalAddProcess
                        button="Actualizar procesos"
                        {...{ toggle, loading, handleInputChange, methodAction: updateProccesInSection, processes, processesSelected, changeSelectProcesses }}
                    />
                    :
                    method === "create"
                        ? <FormSection
                            button={ModalButtonSave}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                        />
                        : (method === "update")
                            ?
                            <FormSection
                                button={ModalButtonUpdate}
                                {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, name, abbreviation, enable_reception, enable_racks, is_imagenology,is_micro}} />
                            :
                            <DeleteSection {...{ toggle, loading, name, abbreviation, saveDelete }} />
            }
        </Modal>
    )
}
