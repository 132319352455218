import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalUnitConversion } from '../../components/unitConversions/ModalUnitConversion';
import { useUnitsConversions } from '../../hooks/unitConversions/useUnitConversions';

export const IndexUnitConversions = (props) => {

    const {
        unitsConversions, units, method,
        id_unit_from, id_unit_to, conversion_factor, handleInputChange,
        toggle, modal, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    } = useUnitsConversions({ ...props });

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de unidades de conversión
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["id_unit_from", "id_unit_to"]}
                                        methodsActions={true}
                                        cabeceras={["Unidad origen", "Unidad destino", "Abr origen", "Abr destino", "Unidad de conversión"]}
                                        items={unitsConversions}
                                        {...{ handleUpdate, handleDelete }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalUnitConversion
                {...{ modal, toggle, loading, method, units, id_unit_from, id_unit_to, conversion_factor, handleInputChange, saveCreate, saveUpdate, saveDelete }}
            />
        </Fragment>
    )
}
