import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useAntibiotic = () => {

    const history = useHistory();

    const [antibiotics, setAntibiotics] = useState([]);
    const [antibioticId, setAntibioticId] = useState(0);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        antibiotic: "",
    });

    const { antibiotic } = formValues;

    useEffect(() => {
        getAntibiotics();
    }, []);

   
    const [validaciones, setValidaciones] = useState({
        antibiotic_valid: false,
    });

    const getAntibiotics = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Antibiotic/List");

        if (respuesta.code === 200) {
            let listAntibiotics = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;
                    
                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
    
                    listAntibiotics.push({
                        id_mic_antibiotic: data.id_mic_antibiotic,
                        id_mic_antibiotic_group: data.id_mic_antibiotic_group,
                        antibiotic: data.antibiotic,
                        visibleTable: visibleTable
                    });
                });
                setAntibiotics(listAntibiotics);
                setAntibioticsData(listAntibiotics);
            }
            else {
                setAntibiotics([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAntibiotics);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibioticos", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Antibioticos", respuesta.data.msg);
        }
    }

   


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            antibiotic: "",
            id_mic_antibiotic_group: ""
        });

        setValidaciones({
            antibiotic_valid: false,
            id_mic_antibiotic_group_valid: false,
        });

        setAntibioticId(0);
        setMethod("create");
        setModalTitle("Crear antibiotico");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let mValues = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Antibiotic");

        if (respuesta.code === 200) {
            getAntibiotics();
            reset();
            createSweet("create", "success", "Exito!", "Antibiotico creado con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibioticos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibioticos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_antibiotic) => {
        let antibiotic = antibiotics.find(s => s.id_mic_antibiotic === parseInt(id_antibiotic));

        handleUpdateValues({
            antibiotic: antibiotic.antibiotic,
            id_mic_antibiotic_group: antibiotic.id_mic_antibiotic_group,
        });

        setValidaciones({
            antibiotic_valid: false,
            id_mic_antibiotic_group_valid: false,
        });

        setAntibioticId(id_antibiotic);
        setMethod("update");
        setModalTitle("Actualización de antibiotico");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            id_mic_antibiotic: antibioticId,
            ...{ ...formValues }
        };

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Antibiotic/Update");

        if (respuesta.code === 200) {
            getAntibiotics();
            createSweet("create", "info", "Exito!", "Antibiotico actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibioticos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibioticos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_antibiotic) => {
        let antibiotic = antibiotics.find(s => s.id_mic_antibiotic === id_antibiotic);

        handleUpdateValues({
            antibiotic: antibiotic.antibiotic,
            id_mic_antibiotic_group: antibiotic.id_mic_antibiotic_group,
        });

        setAntibioticId(id_antibiotic);
        setMethod("delete");
        setModalTitle("Eliminar antibiotico");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Antibiotic/${antibioticId}`);
        ////console.log(respuesta);
        if (respuesta.code === 200) {
            getAntibiotics();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Antibiotico eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibioticos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Antibioticos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};
        // antibiotic, gram, id_mic_antibiotic
        if (formValues.antibiotic === undefined || formValues.antibiotic.length < 1) {
            newValidations = {
                ...newValidations,
                antibiotic_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                antibiotic_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(antibiotics.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listAntibiotics = [];
        antibiotics.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listAntibiotics.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setAntibiotics(listAntibiotics);
    }, [totalPageCount, currentPage])


    const [antibioticsData, setAntibioticsData] = useState([]);
    const handleSearch = (e) => {

        let busqueda = e.target.value;
        let newAntibiotic = [];

        antibioticsData.forEach(element => {

            let cadena = element.antibiotic.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newAntibiotic.push(element);
            }
        });
        setAntibiotics(newAntibiotic);
    }

    return {
        antibiotics, method, validaciones,
        antibiotic,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch
    }

}
