import React, { Fragment, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FileText } from 'react-feather';
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap';
import Blur from 'react-css-blur';


export const TimeLinePatient = ({ items, method }) => {

    const style = {
        spaceDiv: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "space-between",
            textTransform: "uppercase"
        }
    }
    const [blurOn, setBlurOn] = useState(true);

    return (
        <Fragment>
            <VerticalTimeline layout={'1-column'}>
                {
                    items.length > 0 &&
                    items.map((obj, key) => {
                        return <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            animate={true}
                            date={obj.admision_date}
                            icon={<FileText />}
                            key={key}
                            iconClassName="bg-primary"
                           
                        >
                            <div style={style.spaceDiv}>
                                <h5 className="vertical-timeline-element-title">{`${obj.nim}`}</h5>
                                <span className='f-14 badge badge-light-primary' >{obj.income_type}</span>
                            </div>
                            <hr />
                            <Row>
                                <Col sm="6">
                                    <Blur radius={obj.send_results_pacient ? '0px' : '5px'} transition="400ms">
                                        <p className="mt-0">
                                            {
                                                obj.send_results_pacient ?
                                                    obj.listSections.map(function (elem) {
                                                        return elem.name_section
                                                    }).join(",")
                                                    :
                                                    <span onCopy={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}>SECCIONES NO DISPONIBLES</span>
                                            }
                                        </p>
                                    </Blur>
                                </Col>
                                <Col sm="6">
                                    <p className="mt-0 text-right">{obj.name_branch}</p>
                                </Col>
                            </Row>
                            {
                                !obj.send_results_pacient &&
                                <Blur cursor="pointer" radius={obj.send_results_pacient ? '0px' : '5px'} transition="400ms">
                                    <div onClick={() => method(obj.id_work_order)} onCopy={(e) => {
                                        e.preventDefault()
                                        return false;
                                    }}>
                                        <h6>Listado de examenes</h6>
                                        EXAMENES NO DISPONBILES
                                    </div>
                                </Blur>
                            }
                            {
                                obj.send_results_pacient &&
                                obj.listExam.length > 0 &&
                                <div>
                                    <h6>Listado de examenes</h6>
                                    {
                                        obj.listExam.map((exam, keyExam) => {
                                            return <li key={keyExam}>
                                                {exam.name} ({exam.abbreviation})
                                            </li>
                                        })
                                    }
                                </div>
                            }
                            {
                                obj.send_results_pacient &&
                                obj.listProfile.length > 0 &&
                                <div>
                                    <h6 >Listado de perfiles</h6>
                                    {
                                        obj.listProfile.map((profile, keyProfile) => {
                                            return <li key={keyProfile}>
                                                {profile.name_profile}
                                                <div className="ml-3">
                                                    {
                                                        profile.listExam.map((exam, keyExam2) => {
                                                            return <div key={keyExam2}>
                                                                - {exam.name} ({exam.abbreviation})
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </li>
                                        })
                                    }
                                </div>
                             
                            }
                        </VerticalTimelineElement>

                    })
                }
            </VerticalTimeline>
           
        </Fragment>
    )
}

TimeLinePatient.propTypes = {
    items: PropTypes.array.isRequired,
    method: PropTypes.func.isRequired
}