import React from 'react'
import MaskedInput from 'react-text-mask';
import { Button, Col, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

export const ModalCancelInvoice = (props) => {

    const {
        modalTitle, modalCancellation, toggleModalCancellation,
        loading, method,

        informationInvoice, ListTypeCancellations, 
        id_invoice, code_type_cancellation, code_reference_value, is_general_public,
        handleInputChange, SaveCancellationInvoice
    } = props;

    return (
        <Modal isOpen={modalCancellation} toggle={toggleModalCancellation} centered={true}>
            <ModalHeader toggle={toggleModalCancellation} className={method === "cancellation" ? 'bg-danger' : 'bg-primary'}>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Label color='primary'><b>Estas intentando eliminar el siguiente CFDI</b></Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        {
                            informationInvoice.rfcReceptor !== undefined && <>
                                <Row className='m-b-1'>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>RFC</b></Label>
                                        <p>{ informationInvoice.rfcReceptor }</p>
                                    </Col>
                                    <Col sm="8" md="8" lg="8">
                                        <Label><b>Razón social</b></Label>
                                        <p>{ informationInvoice.commercialName }</p>
                                    </Col>
                                </Row>
                                <Row className='m-b-1'>
                                    <Col sm="12" md="12" lg="12">
                                        <Label><b>Folio fiscal</b></Label>
                                        <p>{ informationInvoice.uuid }</p>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Serie y folio</b></Label>
                                        <p>{ informationInvoice.serieFolio }</p>
                                    </Col>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Fecha de emisión</b></Label>
                                        <p>{ informationInvoice.dateCreated }</p>
                                    </Col>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Total</b></Label>
                                        <p>{ informationInvoice.codeCoin + " " + informationInvoice.total  }</p>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label>Ingresa el método de cancelación <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='select' className='form-control form-control-sm input-air-primary b-r-9' name='code_type_cancellation' value={code_type_cancellation} onChange={handleInputChange}>
                                <option value={"0"}>Selecciona una opción</option>
                                {
                                    ListTypeCancellations.length > 0 && ListTypeCancellations.map(_cancellation => {
                                        return <option key={_cancellation.code} value={_cancellation.code}>{ _cancellation.description }</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    code_type_cancellation === "01" && <Row>
                        <Col sm="12" md="12" lg="12">
                            <Label>Ingresa el folio fiscal de la nueva factura relacionada <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <MaskedInput
                                mask={[
                                    /[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,'-',/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,'-',/[0-9 A-Z]/, /[0-9 A-Z]/, /[0-9 A-Z]/, /[0-9 A-Z]/,'-',/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,'-',/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/,/[0-9 A-Z]/, /[0-9 A-Z]/
                                ]}
                                className='form-control form-control-sm input-air-primary b-r-9'
                                placeholder='________-____-____-____-____________'
                                name="code_reference_value"
                                value={code_reference_value}
                                guide={true}
                                keepCharPositions={true}
                                onChange={(e) => handleInputChange(e)}
                            />
                            <FormText color='primary' className='f-b-700'>INGRESA SOLO NUMEROS Y LETRAS EN MAYUSCULAS</FormText>
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='danger' type='button' disabled={loading} className={ loading ? "disabled progress-bar-animated progress-bar-striped" : "" } onClick={SaveCancellationInvoice}> 
                    Cancelar factura
                </Button>
                <Button size='sm' className='btn-air-ligth' outline type='button' onClick={toggleModalCancellation}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}