import React from 'react'
import { Modal, Col, Form, Label, Row } from 'reactstrap';

export const ModalCancellation1 = (props) => {

    const {

        typeModalCancell, modalCancell, toggleCancell1, getCancelSample


    } = props;




    return (
        <Modal isOpen={modalCancell} toggle={toggleCancell1} backdrop='static' keyboard={false} centered={true} onClick={() => getCancelSample()}>
            {
                typeModalCancell === "Cancell1" ?
                    <>
                        <div className="colorModalIDmuestra" style={{ textAlignLast: "center" }}>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <Row>
                                    {/* <Col sm="2"></Col> */}
                                    <Col xs="10" sm="10" md="10" lg="10" xl="10" className='p-2'>
                                        <Label className="labelModal p-1"> &nbsp;{"Cancelar Toma"}</Label>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-3'>
                                        <img className=" pointer" src={require("../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "50%" }} alt="" onClick={toggleCancell1} />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Form className="theme-form" role="form" >
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ border: "#FF00AA 3px solid" }} >
                                <Row>

                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlignLast: "center" }} > <br />
                                        <label className='cancell-1'>¿Está seguro que quiere cancelar la toma?</label>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlignLast: "center" }}>  <br />
                                        <button className='cancell-button' >Aceptar</button>
                                    </Col>
                                </Row> <br />
                            </Col>
                        </Form>
                    </>
                    :
                    ""
            }
        </Modal>
    )
}
