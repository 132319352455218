import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteMorphology } from './DeleteMorphology';
import { FormMorphology } from './FormMorphology';

export const ModalMorphology = (props) => {

    const {
        modal, modalTitle, toggle, method,
        validaciones,
        morphology,
        handleInputChange, loading,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormMorphology
                        button={"Crear morfologia"}
                        {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormMorphology
                            button={"Actualizar morfologia"}
                            {...{ toggle, loading, handleInputChange, validaciones, methodAction: saveUpdate, morphology, }} />
                        :
                        <DeleteMorphology {...{ toggle, loading, morphology, saveDelete }} />
            }
        </Modal>
    )
}
