import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigServer } from '../../data/config';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';


export const useQuatation = () => {

    //USE HISTORY
    const history = useHistory();

    //USE LOCAL STORAGE VARIABLES
    let typeCommercialLine = localStorage.getItem('commercialLineId');
    let branch = localStorage.getItem('branchId')
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');
    let url = localStorage.getItem('url_helen')

    //#region DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, , , , , sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //#endregion

    //#region MODALS    
    //MODEL
    const [modalTitle, setModalTitle] = useState("");
    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);

    const [modal, setModal] = useState(false);
    const [modalRepeatProfile, setModalRepeatProfile] = useState(false);
    const [modalViewExamsP, setModalViewExamsP] = useState(false);

    //METHODS
    const toggle = () => {
        setModal(!modal);
    }

    const toggleRepeatProfile = () => {
        setModalRepeatProfile(!modalRepeatProfile);
    }

    const toggleModalViewExams = () => {
        setModalViewExamsP(!modalViewExamsP);
    }

    //#endregion MODALS

    //#region QUOTER

    //#region QUOTER MODEL
    //VIEW BUTTONS IN QUOTER
    const [viewButtons, setViewButtons] = useState(true);
    const [viewButtonsCreate, setViewButtonsCreate] = useState(false);

    //COUNTERS
    const [cardExam, setCardExam] = useState(0);
    const [cardProfile, setCardProfile] = useState(0);

    //SEARCH TEST
    const [examProfileState, setExamProfileState] = useState([]);

    //VALIDATIONS
    const [validationsTest, setValidationsTest] = useState({
        valid_repeat_test: false,
        valid_exist_exam_profile: false,
        valid_conteins_exam: false,
        message_validation: ""
    });

    const [validationsRepeat, setValidationsRepeat] = useState({
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    });

    //QUOTER
    const [idQuoter, setIdQuoter] = useState(0);
    const [times, setTimes] = useState([]);
    const [viewExamsProfile, setViewExamsProfile] = useState([]);
    const [totalW, setTotalW] = useState(0.00);
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);

    //URL
    const [lab, setLab] = useState(false);


    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        observations: "",
        listQuoterTest: [],
        id_profileExam: 0,
        name: "",
        paternal_surname: "",
        maternal_surname: "",
        arrayCurves: [],
        email: "",
        phone: "",
    });

    const { observations, listQuoterTest, id_profileExam, name, paternal_surname, maternal_surname, arrayCurves, email, phone } = formValues;

    //#endregion 

    //#region validations
    const [validationsSG, setvalidationsSG] = useState({
        nameV: false,
        paternal_surnameV: false,
        maternal_surnameV: false,
    });
    //#endregion validations

    //#region USE EFFECTS
    useEffect(() => {
        getTimes();
    }, []);

    useEffect(() => {
        urlLab();
    }, []);
    //#endregion

    //#region METHODS

    const urlLab = async () => {
        if(url === "https://semlab.helen-sw.com"){
            setLab(true)
            //console.log('2.-', setLab)
        }else{
            setLab(false)
            //console.log('1.-', setLab)
        }
    }

    //console.log(lab)

    const getTimes = async () => {

        let listTmp = await handleRequest("GET", "Price/GetTimes", "Tiempos");

        if (listTmp !== null && listTmp.length > 0) {
            setTimes(listTmp);
        }
    }

    const handleSelectExamChange = (e) => {
        let examProfile = e.target.value;

        if (examProfile !== 0 && examProfile !== null) {
            examProfile = examProfile.split('-');

            let idTest = examProfile[0];
            let typeTest = examProfile[1];
            let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

            if (nameTypeTest === "Profile") {
                handleGetProfile(idTest);
            }
            else {
                handleGetExam(idTest);
            }
        }
    }

    const handleGetProfile = async (id_profile, valid_exist = -1) => {
        let listTemp = await handleRequest("GET", `Profiles/FindProfileId/${id_profile},${branch},${typeCommercialLine},0,0,0?validation_strict=true&is_membership=false`, "BÃºscar perfles");

        let list = listQuoterTest;

        if (listTemp !== null) {

            let validTest = validationTest(null, id_profile, listTemp.listExams, listTemp.name);

            if (validTest !== -1 && validTest !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            else if (validTest === 2 && valid_exist === -1) {
                toggleRepeatProfile();
                setModalTitle("Perfiles con exámenes duplicados");
                // return;
            }

            let array_specimens = [];

            listTemp.exams_specimens.forEach(specimen => {
                if (!array_specimens.find(x => x.id_specimen === specimen.id_specimen_origin)) {

                    let valorCheck = true;

                    list.forEach(item => {
                        if (item.id_specimen === parseInt(specimen.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                        }
                    });

                    array_specimens.push({
                        specimen_name: specimen.name_specimen_origin,
                        id_specimen: specimen.id_specimen_origin,
                        check_specimen: valorCheck,
                    });
                }
            });

            list.push({
                id_profile: listTemp.id_profile,
                id_exam: null,
                id_price: listTemp.id_price,
                id_profile_price: listTemp.id_price,
                price: listTemp.price,
                name: listTemp.name,
                code: listTemp.code,
                listIndications: listTemp.listExamIndications,
                is_urgent: false,
                is_curve: listTemp.is_curve,
                curves: listTemp.listCurves,
                abbreviation: listTemp.abbreviation,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                urgent_price: 0,
                delivery_time: "",
                id_profile_curve_price: 0,
                listExams: listTemp.listExams,
                percentage: 0
            });

                //console.log(list)


            handleUpdateValues({
                ...formValues,
                listQuoterTest: [...list]
            });


            setExamProfileState({});
        }
    }

    const handleGetExam = async (id_exam) => {
        let list = listQuoterTest;

        let listTemp = await handleRequest("GET", `Exam/GetPriceFromExam/${id_exam},${branch},${typeCommercialLine},0,0,0,true?is_membership=false`, "ExÃ¡menes");

        if (listTemp !== null) {

            let validationExam = validationTest(id_exam);

            if (validationExam !== -1 && validationExam !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }

            let valorCheck = true;

            list.forEach(item => {
                if (item.id_specimen === parseInt(listTemp.id_specimen)) {
                    valorCheck = item.check_specimen;
                }
            });

            list.push({
                id_profile: null,
                id_exam: listTemp.id_exam,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_price: listTemp.id_price,
                price: listTemp.price,
                specimen_name: listTemp.name_specimen,
                id_specimen: listTemp.id_specimen,
                check_specimen: valorCheck,
                listIndications: listTemp.listIndications,
                is_urgent: false,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                listExams: null,
                urgent_price: 0,
                delivery_time: listTemp.delivery_time,
                percentage: 0
            });

            //console.log(list);

            handleUpdateValues({
                ...formValues,
                listQuoterTest: [...list]
            });

            setExamProfileState({});
        }
    }

    const changeSelectSamplings = (e, id_profileAux) => {
        let allRequisitionExams = listQuoterTest;

        let id_profile_curve_price = parseInt(e.target.value);
        let findRequestExam = allRequisitionExams.find(r => r.id_profile === parseInt(id_profileAux));

        let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {
                let curves_aux = [];

                r.price = curva_seleccionada.price;

                for (let index = 1; index <= curva_seleccionada.number_sampling; index++) {

                    curves_aux.push({
                        ...curva_seleccionada,
                        time: "",
                        number_sampling_aux: index
                    });
                }

                r.curves_aux = curves_aux;
            }

            return r;
        });

        handleUpdateValues({
            ...formValues,
            listQuoterTest: [...nuevos]
        });
    }

    const changeValueTimeSampling = (e, id_profileAux, id_profile_curve_price, number_sampling) => {
        let allRequisitionExams = listQuoterTest;

        let tiempo_axu = e;

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {
                let curves_aux = r.curves_aux;

                let nuevas_curvas = curves_aux.map(c => {
                    if (c.number_sampling_aux === number_sampling) {
                        c.time = tiempo_axu;
                    }

                    return c;
                });

                r.curves_aux = nuevas_curvas;
            }
            return r;
        });

        handleUpdateValues({
            ...formValues,
            listQuoterTest: [...nuevos]
        });
    }

    const deleteExamProfile = (e,id_exam, id_profile = null) => {

        let findTest = listQuoterTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

        if (findTest !== null) {
            let newListTest = listQuoterTest.filter(item => {
                if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                    return false;
                }
                else {
                    return true;
                }
            });

            handleUpdateValues({
                ...formValues,
                listQuoterTest: newListTest,
            });
        }
    }

    const handleCheckUrgent = (index, e) => {
        let listTmp = listQuoterTest;

        listTmp[index].is_urgent = e.target.checked;

        handleUpdateValues({
            ...formValues,
            listQuoterTest: [...listTmp]
        });
    }

    const handleCheckSpecimen = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listQuoterTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;

        if (id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_specimen;
        }
        else {
            valor = !findRequestExam.check_specimen;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(specimen => {
                    if (sub === null) {
                        if (specimen.id_specimen === findRequestExam.id_exam) {
                            specimen.check_specimen = valor;
                        }
                    }
                    else {
                        if (specimen.id_specimen === sub.id_specimen) {
                            specimen.check_specimen = valor;
                        }
                    }

                    return specimen;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_specimen === findRequestExam.id_specimen) {
                        item.check_specimen = valor;
                    }
                }
                else {
                    if (item.id_specimen === sub.id_specimen) {
                        item.check_specimen = valor;
                    }
                }
            }

            return item;
        });

        handleUpdateValues({
            ...formValues,
            listQuoterTest: allRequisitionExams
        });
    }

    const handleViewExamsProfile = (id_profile) => {
        let findExams = listQuoterTest.find(x => x.id_profile === parseInt(id_profile));
        ////console.log(findExams);
        if (findExams) {
            setViewExamsProfile(findExams.listExams);
            toggleModalViewExams();
        }
    }

    const handleCancellQuoter = () => {
        handleUpdateValues({
            observations: "",
            listQuoterTest: [],
            id_profileExam: 0,
            name: "",
            paternal_surname: "",
            maternal_surname: "",
            arrayCurves: [],
            email: ""
        });

        setCardExam(0);
        setCardProfile(0);
        setExamProfileState({});
    }

    const handleChangePercentage = (e, id_exam, id_profile = null) => {
        let _list = listQuoterTest;
        let _value = e.target.value;

        let findTest = _list.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));
    
        if (findTest !== undefined) {
            findTest.percentage = parseInt(_value);
        }
    
        handleUpdateValues({
            ...formValues,
            listQuoterTest: [..._list]
        });
    }

    const saveCreate = async () => {
        setLoading(true);

        if (validArrayCurva1()) {
            setLoading(false);
            createSweet("create", "warning", "Curvas", "Selecciona una opción de la toma");
            return;
        }

        if (validArrayCurva()) {
            setLoading(false);
            createSweet("create", "warning", "Curvas", "Selecciona una opciónn de la toma");
            return;
        }

        if (validationQuatation()) {
            return;
        }

        let body = {
            id_patient: 0,
            id_branch: branch,
            name: formValues.name,
            paternal_surname: formValues.paternal_surname,
            maternal_surname: formValues.maternal_surname,
            observations: formValues.observations,
            email: formValues.email,
            phone: formValues.phone,
            ListQuoterTests: listQuoterTest
        };

        let listTemp = await handleRequest('POST', "Quoter", "Cotizacion", body);

        if (listTemp !== null) {

            let idQuoter = listTemp.id_quoter;
            if (idQuoter > 0) {
                setIdQuoter(idQuoter);
                setViewButtons(false);
                setViewButtonsCreate(false);
            }

            setLoading(false);
            createSweet("success", "success", "Se creo correctamente la  cotizaciÃ³n");
        }
    }




    const printPdf = async (idQuoter = 0) => {
        let data = {
            id_quoter: idQuoter,
            print_result: true,
            send_email: false,
            email_client: ""
        };

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Quoter/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var iframe = document.createElement('iframe');
                iframe.className = 'pdfIframe'
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.onload = function () {
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        URL.revokeObjectURL(url)
                    }, 1);
                };
                iframe.src = url;
            })
    }

    const sentPdf = async (idQuoterr = 0) => {
        let data = {
            id_quoter: idQuoter,
            print_result: false,
            send_email: true,
            email_client: formValues.email
        };

        ////console.log(data);

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Quoter/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status === 200) {
                    createSweet("success", "success", "Correo enviado correctamente");
                }
                else {
                    createSweet("warning", "warning", "El envio de a correo no fue exitoso");
                }
            })
    }

    const downdloadpdf = async (idQuoterr = 0) => {

        let data = {
            "id_quoter": idQuoter,
            "print_result": true,
            "send_email": false,
            "email_client": ""
        }


        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Quoter/CreateDocumentQuoter", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {

                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Cotizacion.pdf")
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    //#endregion

    const format_trunc_number = (_quantity, _decimals) => {
        let _format_number = 0;
        const _base = 10;

        let _number_decimals = Math.pow(_base, _decimals);

        _format_number = Math.round(_quantity * _number_decimals) / _number_decimals;

        return _format_number;
    }

    useMemo(() => {
        let total = 0.0;
        let subtotal = 0.0;
        let total_iva = 0.0;
        let descuento = 0.0;


        let total_exams = 0;
        let total_profiles = 0;
        let _take_number_decimals = 2;


        if (formValues.listQuoterTest.length > 0) {
            formValues.listQuoterTest.forEach(data => {

                if (data.id_exam !== null) {
                    total_exams++;
                }

                if (data.id_profile !== null) {
                    total_profiles++;
                }
                let value_urgent = parseInt(percentage_urgent_work_order);
                let price = data.price;
                let urgency = 0.0;
                let taxIva = 0.0;
                let discount = 0.0;


                switch (data.config_iva) {
                    case true:
                        //REMOVEMOS EL IVA DEL PRECIO DE LA PRUEBA
                        let value_iva = 1 + (data.value_iva / 100);

                        price = (price / value_iva);
                        price = format_trunc_number(price, _take_number_decimals);

                        if (data.is_urgent) {
                            urgency = ((price * value_urgent) / 100);
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.price_urgent = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.price_urgent = 0;
                        }

                        if (data.percentage > 0) {
                            //console.log("insan");
                            
                        }
                        else {
                            //console.log("aqui pasan las otras ");

                        }

                        taxIva = (price * data.value_iva) / 100;
                        taxIva = format_trunc_number(taxIva, _take_number_decimals);

                        break;

                    case false:

                        if (data.is_urgent) {
                            urgency = ((price * value_urgent) / 100);
                            urgency = format_trunc_number(urgency, _take_number_decimals);

                            data.price_urgent = !isNaN(urgency) ? urgency : 0;

                            price = (price + urgency);
                            price = format_trunc_number(price, _take_number_decimals);
                        }
                        else {
                            data.price_urgent = 0;
                        }

                        if (data.percentage > 0) {
                            discount = (price * data.percentage) / 100;
                            discount = format_trunc_number(discount, _take_number_decimals);
                            //console.log(discount);


                            //console.log("insan");
                        }
                        else {
                            //console.log("aqui pasan las otras ");
                        }



                        //taxIva = (price * data.value_iva) / 100;
                        taxIva = ((price - discount) * data.value_iva) / 100;

                        taxIva = format_trunc_number(taxIva, _take_number_decimals);

                        break;
                }

                subtotal += price;
                total_iva += taxIva;
                descuento += discount;

            });
        }

      //  total = Math.round((subtotal + total_iva));
      total = Math.round((subtotal - descuento) + total_iva);

      descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;



        subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
        total_iva = !isNaN(Math.round(total_iva)) ? parseFloat(total_iva.toFixed(2)) : 0;
        total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;

        setTotalDiscountW(descuento)
        setSubTotalW(subtotal);
        setTotalTaxesW(total_iva);
        setTotalW(total);
        setCardExam(total_exams);
        setCardProfile(total_profiles);
    }, [formValues]);
    //#endregion

    //#region ALL VALIDATIONS
    const validationTest = (id_exam, id_profile = null, listExams = [], name_profile = "") => {
        let statusValidation = -1;
        let newValidations = {};
        let valid_exist_test = {
            name_profile: "",
            repeat_profiles: [],
            repeat_exams: []
        };

        if (formValues.listQuoterTest.length > 0) {
            let findTest = formValues.listQuoterTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

            if (findTest) {
                newValidations = {
                    ...newValidations,
                    valid_repeat_test: true,
                    message_validation: (id_exam !== null ? `El examen: ${findTest.name}, ya existe` : `El perfil: ${findTest.name}, ya existe`)
                };

                statusValidation = 0;
            }
            else {
                if (id_exam !== null) {
                    let listProfiles = formValues.listQuoterTest.filter(x => x.id_profile !== null);

                    listProfiles.forEach(p => {
                        if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {
                            newValidations = {
                                message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                            };
                            statusValidation = 1;

                            return true;
                        }
                    });
                }
                else if (id_exam === null && id_profile !== null) {
                    let filterProfiles = formValues.listQuoterTest.filter(x => x.id_profile !== null);

                    filterProfiles.forEach(p => {
                        let examsRepeat = [];
                        let examsNotRepeat = [];

                        p.listExams.forEach(e => {
                            if (listExams.find(x => x.id_exam === e.id_exam)) {
                                examsRepeat.push(e);
                            }
                        });

                        let notExist = [];
                        listExams.forEach(x => {
                            if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                notExist.push(x);
                            }
                        });

                        examsNotRepeat = notExist;

                        if (examsRepeat.length > 0) {
                            valid_exist_test.repeat_profiles.push({
                                name_profile: p.name,
                                listExamsRepeat: examsRepeat,
                                listExamsNotRepeat: examsNotRepeat
                            });
                        }

                        if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                            valid_exist_test.name_profile = name_profile;
                            newValidations = {
                                message_validation: "Hay exÃ¡menes o perfiles repetidos"
                            };

                            statusValidation = 2;
                        }
                    });

                    if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                        valid_exist_test.name_profile = name_profile;
                        newValidations = {
                            message_validation: "Hay exÃ¡menes o perfiles repetidos"
                        };

                        statusValidation = 2;
                    }

                    let listExamsW = formValues.listQuoterTest.filter(x => x.id_exam !== null);

                    listExamsW.forEach(e => {
                        if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {
                            newValidations = {
                                message_validation: `Tienes examenes duplicados: ${e.name}, al seleccionar el perfil`
                            };

                            statusValidation = 3;

                            return true;
                        }
                    })
                }
            }
        }

        setValidationsTest(newValidations);
        setValidationsRepeat({
            ...validationsRepeat,
            name_profile: valid_exist_test.name_profile,
            repeat_profiles: valid_exist_test.repeat_profiles,
            repeat_exams: valid_exist_test.repeat_exams
        });

        return statusValidation;
    }

    const validArrayCurva = () => {
        let statusValidation = false;

        if (formValues.listQuoterTest.length > 0) {
            formValues.listQuoterTest.forEach(obj => {
                if (obj.is_curve) {
                    obj.curves_aux.forEach(c => {
                        if (c.time === "") {
                            statusValidation = true;
                            return;
                        }
                    })
                }
            })
        }
        else {
            statusValidation = true;
        }

        return statusValidation;
    }

    const validArrayCurva1 = () => {
        let statusValidation = false;

        if (formValues.listQuoterTest.length > 0) {
            formValues.listQuoterTest.forEach(obj => {
                if (obj.is_curve) {
                    if (obj.price === 0) {
                        statusValidation = true;
                        return;
                    }
                }
            })
        }
        else {
            statusValidation = true;
        }

        return statusValidation;
    }

    //#endregion

    //#region validations
    const validationQuatation = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formValues.name === "") {
            newValidations = {
                ...newValidations,
                nameV: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                nameV: false,
            };
        }
        if (formValues.paternal_surname === "") {
            newValidations = {
                ...newValidations,
                paternal_surnameV: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                paternal_surnameV: false,
            };
        }
        if (formValues.maternal_surname === "") {
            newValidations = {
                ...newValidations,
                maternal_surnameV: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                maternal_surnameV: false,
            };
        }
        setvalidationsSG(newValidations);
        return statusValidation;
    }
    //#endregion validations

    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        //MODALS
        method, modalTitle, modal, modalRepeatProfile, modalViewExamsP,
        toggle, toggleModalViewExams, toggleRepeatProfile,

        //QUOTER VALUES
        observations, listQuoterTest, id_profileExam, name, paternal_surname, maternal_surname, email,
        handleInputChange,
        cardExam, cardProfile, totalW, subTotalW, totalTaxesW,
        examProfileState, viewExamsProfile, times,
        viewButtons, viewButtonsCreate, validationsRepeat, lab,


        //QUOTER METHODS
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        deleteExamProfile, handleCheckUrgent, handleCheckSpecimen,
        handleViewExamsProfile, handleCancellQuoter, saveCreate,
        printPdf, sentPdf, downdloadpdf, formValues, phone, validationsSG,
        handleChangePercentage,
        totalDiscountW
    }
}