import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormSection = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, abbreviation, enable_reception, enable_racks, button,is_imagenology,is_micro
    } = props;

    const {
        name_valid, abbreviation_valid
    } = validaciones;

    const txtsection = localStorage.getItem('TextAS');
    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Col sm="12" className='p-l-0 p-r-0'>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">{txtsection} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={handleInputChange}
                                    value={name}
                                    autoComplete="off"
                                    invalid={name_valid}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Minimo 3 caracteres</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Abreviatura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <Input
                                    type="text"
                                    name="abbreviation"
                                    onChange={handleInputChange}
                                    value={abbreviation}
                                    autoComplete="off"
                                    invalid={abbreviation_valid}
                                    className="form-control form-control-sm input-air-primary"

                                />
                                <FormFeedback>Minimo 3 caracteres</FormFeedback>
                            </FormGroup>
                        </Col>  <br /> <br />
                        <Col md="3" style={{textAlign : "center" }}>
                            <FormGroup>
                                <Label className="col-form-label">Tiene recepción de muestra? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="enable_reception" onChange={handleInputChange} checked={enable_reception} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{textAlign : "center" }}>
                            <FormGroup>
                                <Label className="col-form-label">¿Usa gradillas? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br /> <br /> 
                                <Label className="switch">
                                    <Input type="checkbox" name="enable_racks" onChange={handleInputChange} checked={enable_racks} />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{textAlign : "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Es de imagenología? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br /> <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_imagenology" onChange={handleInputChange} checked={is_imagenology}  />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="3" style={{textAlign : "center" }} className="p-l-0" >
                            <FormGroup>
                                <Label className="col-form-label">¿Es antibiograma? <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                <br /> <br />
                                <Label className="switch">
                                    <Input type="checkbox" name="is_imagenology" onChange={handleInputChange} checked={is_micro}  />
                                    <span className="slider round"></span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="submit" size="sm" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>
                    {ModalButtonCancel}
                </Button>
            </ModalFooter>
        </Form>
    )
}
