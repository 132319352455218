import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "../forms/useForm";
import { usePagination } from "../pagination/usePagination";
import { sendRequest } from "../requests/useRequest";
import { useSweetAlert } from "../sweetAlerts/useSweetAlert";
import { validarSesion } from "../tokens/useToken";

export const useCosts = (examId, profileId, sendKitSampling) => {
  const history = useHistory();

  const [samplings, setSamplings] = useState([]);
  const [samplingSelected, setSamplingSelected] = useState(null);
  const [samplingSelecteds, setSamplingSelecteds] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createSweet] = useSweetAlert();
  const [totalGlobal, setTotalGlobal] = useState(0.0);

  const getSamplings = async () => {
    let requestOptions = {
      method: "GET",
    };

    const respuesta = await sendRequest(
      requestOptions,
      "Sampling/ListSamplings"
    );
    if (respuesta.code === 200) {
      let newSamplings = [];
      respuesta.data.forEach((element) => {
        let obj = {
          id_sampling: element.id_sampling,
          name: element.name,
          code: element.code,
          label: element.name,
          value: element.id_sampling,
        };
        let array_items = [];
        let total = 0.0;

        element.items.forEach((item) => {
          total = total + parseFloat(item.price.replace("$", ""));
          array_items.push([
            item.name,
            item.code,
            parseFloat(item.price.replace("$", "")),
          ]);
        });
        obj.items = array_items;
        obj.total = total;

        newSamplings.push(obj);
      });
      //////console.log(newSamplings);
      setSamplings(newSamplings);
    } 
    else if (respuesta.code === 401) {

        validarSesion(history, respuesta.code, getSamplings);
    }
    else if(respuesta.code === 500){

        createSweet("error", "error", "Costos", "Ocurrio un problema en el servidor");
    }
    else{

        createSweet("error", "warning", "Costos", respuesta.data.msg);
    }
    // else {
    //   validarSesion(history, respuesta.code, getSamplings);
    // }
  };

  const handleSelect = (e) => {
    setSamplingSelected(e);
    setItems(e.items);
  };

  const handleSelectMultiple = (e) => {
    setSamplingSelecteds(e);
    let itemsAux = [];
    let total = 0.0;
    e.forEach(element => {
      total += element.total;
      itemsAux.push(element.items);
    });
    //////console.log(total);
    setTotalGlobal(total);
    // setItems(e.items);
  };


  useEffect(async () => {
    //////console.log(sendKitSampling);
    if (sendKitSampling > 0) {
      await saveSampling();
    }
  }, [sendKitSampling])


  const saveSampling = async () => {
    setLoading(true);

    let ids = [];

    samplingSelecteds.forEach(element => {
      ids.push(element.id_sampling)
    });

    let formValues = {
      id_exam: sendKitSampling > 0 ? sendKitSampling : (examId !== null ? examId : profileId),
      id_sampling: examId !== null ? samplingSelected.id_sampling : 0,
      samplings_id: ids
    };

    let mValues = JSON.stringify(formValues);

    let requestOptions = {
      method: 'POST',
      body: mValues
    };

    const respuesta = await sendRequest(requestOptions, "Sampling/SaveSamplingFromExam");

    if (respuesta.code === 200) {
      createSweet("create", "success", "Exito!", "Se asigno el kit de toma de muestra");
      setLoading(false);
      if (sendKitSampling > 0) {
        history.goBack();
      }
    }
    else if (respuesta.code === 401) {

      validarSesion(history, respuesta.code, saveSampling);
    }
    else if(respuesta.code === 500){

        createSweet("error", "error", "Costos", "Ocurrio un problema en el servidor");
    }
    else{

        createSweet("error", "warning", "Costos", respuesta.data.msg);
    }
    // else {
    //   setLoading(false);
    //   validarSesion(history, respuesta.code, saveSampling);
    // }
  };

  const getSamplingSelected = async () => {
    let requestOptions = {
      method: 'GET',
    };

    const respuesta = await sendRequest(requestOptions, "Sampling/GetSamplingFromExam/" + examId);

    if (respuesta.code === 200) {
      if (parseInt(examId) === respuesta.data.id_exam) {
        setSamplingSelected({
          label: respuesta.data.name_sampling,
          value: respuesta.data.id_sampling,
        })
        let array_items = [];
        let total = 0.0;

        respuesta.data.items.forEach((item) => {
          total = total + parseFloat(item.price.replace("$", ""));
          array_items.push([
            item.name,
            item.code,
            parseFloat(item.price.replace("$", "")),
          ]);
        });

        setItems(array_items)
      }
    }
    else if (respuesta.code === 401) {

      validarSesion(history, respuesta.code, getSamplingSelected);
    }
    else if(respuesta.code === 500){

        createSweet("error", "error", "Costos", "Ocurrio un problema en el servidor");
    }
    else{

        createSweet("error", "warning", "Costos", respuesta.data.msg);
    }
  }

  const getSamplingSelecteds = async () => {
    let requestOptions = {
      method: 'GET',
    };

    const respuesta = await sendRequest(requestOptions, "Sampling/GetSamplingFromProfile/" + profileId);
    // ////console.log(respuesta);
    if (respuesta.code === 200) {
      // setSamplingSelected({
      //     label: respuesta.data.name_sampling,
      //     value: respuesta.data.id_sampling,
      // })
      let info = [];
      respuesta.data.forEach(element => {
        let array_items = [];
        let total = 0.0;

        element.items.forEach((item) => {
          total = total + parseFloat(item.price.replace("$", ""));
          array_items.push([
            item.name,
            item.code,
            parseFloat(item.price.replace("$", "")),
          ]);
        });
        element.total = total;
        element.items = array_items;
        element.label = element.name_sampling;
        element.value = element.id_sampling;
        element.name = element.name_sampling;
        info.push(element)
      });
      handleSelectMultiple(info);
      setSamplingSelecteds(info);
    }
    else if (respuesta.code === 401) {

      validarSesion(history, respuesta.code, getSamplingSelecteds);
    }
    else if(respuesta.code === 500){

        createSweet("error", "error", "Costos", "Ocurrio un problema en el servidor");
    }
    else{

        createSweet("error", "warning", "Costos", respuesta.data.msg);
    }
  }

  useEffect(() => {
    getSamplings();
  }, []);

  useEffect(() => {
    if (examId > 0 && samplings.length > 0) {
      getSamplingSelected();
    }
  }, [examId, samplings])


  useEffect(() => {
    if (profileId > 0 && samplings.length > 0) {
      getSamplingSelecteds();
    }
  }, [profileId, samplings])


  return {
    samplings,
    samplingSelected,
    samplingSelecteds,
    items,
    handleSelect,
    handleSelectMultiple,
    loading,
    saveSampling,
    totalGlobal
  };
};
