import React from 'react';
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteDoctor = ({toggle, loading, name, saveDelete}) => {
    return (
        <Form role="form" onSubmit={saveDelete}>
            <ModalBody>
                <p className="h6 txt-danger">Estas a punto de eliminar el médico:</p>                
                <b>{ name }</b>
                <br />
                <p>¿Estás seguro de hacerlo?</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    color="danger"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    Si,eliminar médico
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Form>     
    )
}
