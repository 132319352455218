import { useCallback, useEffect, useState } from 'react';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { ModalCreateSpecimenOrigin, ModalUpdateSpecimenOrigin, ModalDeleteSpecimenOrigin, LostConnection } from '../../constant';
import { validarSesion } from '../tokens/useToken';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSpecimens } from '../specimens/useSpecimens';
import { useSpecimenProvenances } from '../specimenProvenances/useSpecimenProvenances';

export const useSpecimenOrigin = ({activeTab}) => {
    
    const history = useHistory();

    const [specimenOrigins, setSpecimenOrigins] = useState([]);
    const [specimenOriginId, setSpecimenOriginId] = useState(0);

    const { specimens } = useSpecimens({activeTab});
    const { specimenProvenances } = useSpecimenProvenances({activeTab});
    const [typeTransports, setTypeTransports] = useState([]);
    const [preparationTypeTransports, setPreparationTypeTransports] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        id_specimen: 0,
        id_specimen_provenance: 0,
        id_type_transport: 0,
        id_preparation_type_transport: 0,
        name: "",
        abbreviation: ""
    });

    const { id_specimen, id_specimen_provenance, id_type_transport, id_preparation_type_transport, name, abbreviation } = formValues;

    const [validaciones, setValidaciones] = useState({
        id_specimen_valid: false,
        id_specimen_provenance_valid: false,
        id_type_transport_valid: false,
        name_valid: false,
        abbreviation_valid: false,
        id_preparation_type_transport_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getSpecimenOrigin = useCallback(async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenOrigins");

        if (respuesta.code === 200) {

            let listSpecimenOrigin = [];

            if (respuesta.data.length >= 1) {                

                setSpecimenOrigins(respuesta.data);
            }
            else{
                createSweet("error", "error", "Hubo un error!", "No se encontraron datos");
                setSpecimenOrigins([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getSpecimenOrigin);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }, [history]);

    useEffect( () => {
        getSpecimenOrigin();
    }, [getSpecimenOrigin]);

    const getTypeTransports = async () => {
        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "TypeTransports");
        
        if (respuesta.code === 200) {
            setTypeTransports(respuesta.data);
        } 
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getTypeTransports);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }

    }

    const getPreparations = async (id) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "TypeTransports/GetPreparations/" + id);

        if (respuesta.code === 200) {
            setPreparationTypeTransports(respuesta.data);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getPreparations);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }

    const changeTypeTransport = (e) => {
        handleInputChange(e);

        let id = e.target.value;
        getPreparations(id);
    }

    const handleCreate = () => {

        handleUpdateValues({
            id_specimen: 0,
            id_specimen_provenance: 0,
            id_type_transport: 0,
            id_preparation_type_transport: 0,
            name: "",
            abbreviation: ""
        });

        setValidaciones({
            id_specimen_valid: false,
            id_specimen_provenance_valid: false,
            id_type_transport_valid: false,
            name_valid: false,
            abbreviation_valid: false,
            id_preparation_type_transport_valid: false
        });

        setSpecimenOriginId(0);
        setMethod("create");
        setModalTitle(ModalCreateSpecimenOrigin);
        getTypeTransports();
        setPreparationTypeTransports([]);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (validarForm()) {
            setLoading(false);
            return;
        }

        let newFormValues = formValues;

        if (formValues.id_preparation_type_transport <= 0) {
            delete newFormValues.id_preparation_type_transport
        }

        let raw = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: raw,
        };

        const respuesta = await sendRequest(requestOptions, "SpecimenOrigins");

        if (respuesta.code === 200) {

            let newSpecimenOrigins = specimenOrigins;
            let specimen = specimens.find(s => s.id_specimen === parseInt(id_specimen));
            let specimen_provenance = specimenProvenances.find(s => s.id_specimen_provenance === parseInt(id_specimen_provenance));
            let type_transport = typeTransports.find(s => s.id_type_transport === parseInt(id_type_transport));
            let preparation_type_transport = preparationTypeTransports.find(s => s.id_preparation_type_transport === parseInt(id_preparation_type_transport));
            newSpecimenOrigins.push({
                id_specimen_origin: respuesta.data.id_specimen_origin,
                ...{ ...formValues },
                name_especimen: specimen.name,
                name_specimen_provenances: specimen_provenance.name,
                name_type_transport: type_transport.name,
                name_preparation_type_transport: (preparation_type_transport === undefined ? "" : preparation_type_transport.name)
            });
            setSpecimenOrigins(newSpecimenOrigins);
            reset();
            createSweet("create", "success", "Exito!", "Origen creado con exito");
            setLoading(false);
            toggle();

        } 
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }

    const handleUpdate = (id_specimen_origin) => {
        let specimenOrigin = specimenOrigins.find(t => t.id_specimen_origin === parseInt(id_specimen_origin));

        getPreparations(specimenOrigin.id_type_transport);
        
        handleUpdateValues({
            id_specimen: specimenOrigin.id_specimen,
            id_specimen_provenance: specimenOrigin.id_specimen_provenance,
            id_type_transport: specimenOrigin.id_type_transport,
            id_preparation_type_transport: (specimenOrigin.id_preparation_type_transport === null ? 0 : specimenOrigin.id_preparation_type_transport),
            name: specimenOrigin.name,
            abbreviation: specimenOrigin.abbreviation
        });
        
        setValidaciones({
            id_specimen_valid: false,
            id_specimen_provenance_valid: false,
            id_type_transport_valid: false,
            name_valid: false,
            abbreviation_valid: false,
            id_preparation_type_transport_valid: false
        });
        
        setSpecimenOriginId(id_specimen_origin);
        setMethod("update");
        setModalTitle(ModalUpdateSpecimenOrigin);
        getTypeTransports();
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();
        if (validarForm()) return;
        setLoading(true);
        let newFormValues = formValues;
        if (formValues.id_preparation_type_transport <= 0) {
            delete newFormValues.id_preparation_type_transport
        }
        let body = {
            id_specimen_origin: specimenOriginId,
            ...{ ...newFormValues }
        }

        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'PUT',
            body: raw,
        };
        const respuesta = await sendRequest(requestOptions, "SpecimenOrigins");
        if (respuesta.code === 200) {
            let specimen = specimens.find(s => s.id_specimen === parseInt(id_specimen));
            let specimen_provenance = specimenProvenances.find(s => s.id_specimen_provenance === parseInt(id_specimen_provenance));
            let type_transport = typeTransports.find(s => s.id_type_transport === parseInt(id_type_transport));
            let preparation_type_transport = preparationTypeTransports.find(s => s.id_preparation_type_transport === parseInt(id_preparation_type_transport));
            let newSpecimenOrigins = specimenOrigins.map(function (obj) {
                if (obj.id_specimen_origin === specimenOriginId) {
                    obj.id_specimen = id_specimen;
                    obj.id_specimen_provenance = id_specimen_provenance;
                    obj.id_type_transport = id_type_transport;
                    obj.id_preparation_type_transport = id_preparation_type_transport;
                    obj.name = name;
                    obj.abbreviation = abbreviation;
                    obj.name_especimen = specimen.name;
                    obj.name_specimen_provenances = specimen_provenance.name;
                    obj.name_type_transport = type_transport.name;
                    obj.name_preparation_type_transport = (preparation_type_transport === undefined ? "" : preparation_type_transport.name);
                }
                return obj;
            });
            setSpecimenOrigins(newSpecimenOrigins);
            createSweet("update", "success");
            setLoading(false);
            toggle();
        } 
        else if (respuesta.code === 401) {
            setLoading(false)
            validarSesion(history, respuesta.code, handleUpdate);
            //validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){
            setLoading(false)
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false)
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }

    const handleDelete = (id_specimen_origin) => {
        let specimenOrigin = specimenOrigins.find(t => t.id_specimen_origin === parseInt(id_specimen_origin));
        handleUpdateValues({
            name: specimenOrigin.name,
        });
        setSpecimenOriginId(id_specimen_origin);
        setMethod("delete");
        setModalTitle(ModalDeleteSpecimenOrigin);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        let requestOptions = {
            method: 'DELETE',
        };

        const respuesta = await sendRequest(requestOptions, `SpecimenOrigins/${specimenOriginId}`);

        if (respuesta.code === 200) {
            let newSpecimenOrigins = specimenOrigins.filter(t => t.id_specimen_origin !== specimenOriginId);
            setSpecimenOrigins(newSpecimenOrigins);
            setLoading(false);
            createSweet("create", "info", "Exito!", "Origen eliminado");
            toggle();
        } 
        else if (respuesta.code === 401) {
            setLoading(false)
            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){
            setLoading(false)
            createSweet("error", "error", "Especimen", "Ocurrio un problema en el servidor");
        }
        else{
            setLoading(false)
            createSweet("error", "warning", "Especimen", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.id_specimen <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_valid: false
            }
        }

        if (formValues.id_specimen_provenance <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_provenance_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                id_specimen_provenance_valid: false
            }
        }

        if (formValues.id_type_transport <= 0) {
            newValidaciones = {
                ...newValidaciones,
                id_type_transport_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                id_type_transport_valid: false
            }
        }

        if (formValues.name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                name_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                name_valid: false
            }
        }

        if (formValues.abbreviation.length < 1) {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: true
            }
            statusValidacion = true;
        } else {
            newValidaciones = {
                ...newValidaciones,
                abbreviation_valid: false
            }
        }

        setValidaciones({
            ...validaciones,
            ...newValidaciones
        });

        return statusValidacion;
    }

    return {
        specimenOrigins, method, handleInputChange, validaciones, changeTypeTransport,
        specimens, specimenProvenances, typeTransports, preparationTypeTransports,
        id_specimen, id_specimen_provenance, id_type_transport, id_preparation_type_transport, name, abbreviation,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete
    }

}
