import React from 'react'
import { Col, Row } from 'reactstrap';


export const ComponentAntibitics = ({obj} ) => {
  return (
    <div>
        <Col sm="12">
            <Row>
                <Col sm="4">
                    <label className='fontSizeA'>{obj.antibiotic}</label>
                </Col>
                <Col sm="4">
                    <label className='fontSizeA'>{obj.interpretacion}</label>
                </Col>
                <Col sm="4">
                    {
                        (obj.cim ==="") ?
                        <label className='fontSizeA'>{"--"}</label>
                        :
                        <label className='fontSizeA'>{obj.cim}</label>
                    }
                </Col>
            </Row>
        </Col>



    </div>
  )
}
