import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import { usePagination } from '../pagination/usePagination';
import moment from 'moment';
import { Thermometer } from 'react-feather';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { UsbRounded } from '@mui/icons-material';


export const useSend = () => {
    const treeviewRef = useRef(null);
    const idUsers = localStorage.getItem("id");
    const flebo = localStorage.getItem("flebotomistaUsr");
    const idBranch = localStorage.getItem("branchIdSample");
    const sample = localStorage.getItem("sample");
    const branchIdSample = localStorage.getItem("branchIdSample");
    const maquila = localStorage.getItem("maquila");
    const [itemsDontSend, setItemsDontSend] = useState([]);



    const [setFocusNim, setSetFocusNim] = useState(true);
    const [typeSend, setTypeSend] = useState("send");
    const [modalSend, setModalSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewExams, setViewExams] = useState(false);
    const [createSweet, sweetConfirmation, sweetError, createSweett] = useSweetAlert();
    //detail Patient
    const [typeInfo, setTypeInfo] = useState("info");
    const [modalInfo, setModalInfo] = useState(false);
    //refused Sample
    const [typeModalRefused, setTypeModalRefused] = useState("refused");
    const [modalRefused, setModalRefused] = useState(false);
    //Info Patient
    const [namepatien, setNamepatien] = useState("");
    const [age, setAge] = useState("");
    const [curp, setCurp] = useState("")
    const [phone, setPhone] = useState("")
    const [gender, setGender] = useState(0);
    const [datanim, setDatanim] = useState(0);
    const [idWorkOrder, setIdWorkOrder] = useState(0);
    const [is_micro, setIs_micro] = useState("")
    //Info study
    const [itemsTree, setItemsTree] = useState([]);
    const [listNims, setListNims] = useState([]);
    const [loadinginfo, setLoadinginfo] = useState(false);
    //refused patiem
    const [reason, setReason] = useState([]);
    //pending send sample
    const [typePending, setTypePending] = useState("Pending");
    const [modalPending, setModalPending] = useState(false);
    const [dateInit, setDateInit] = useState(new moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(new moment().format('YYYY-MM-DD'));
    //refused study
    const [pendingSample, setPendingSample] = useState([]);
    //pending info
    const [historic, setHistoric] = useState([]);
    const [loadinPending, setLoadinPending] = useState(false);
    const [typeMicro, setTypeMicro] = useState("Ismicro")
    const [modalMicro, setModalMicro] = useState(false)

    const [completeAllExams, setCompleteAllExams] = useState(false);

    const numberItems = 10;

    const phlebotomist_name = localStorage.getItem('flebotomisaNombre');
  

    const [formNim, handleInputChangeNim, resetNim, handleUpdateValuesNim, handleSelectValuesNim] = useForm({
        nim: "",
    });

    const { nim } = formNim;

    const [formR, handleInputChangeR, , handleUpdateValuesR, handleSelectValuesR] = useForm({
        id_return_reason: 0,
    });

    const { id_return_reason } = formR;


    const [formPending,handleInputDontSend, handleUpdateDontSend] = useForm({
        valueDontSend: 0,
        list_analytes: [],
    });

    const {valueDontSend, list_analytes} = formPending;



    const [formMic, handleInputChangeMic, resetMic, handleUpdateValuesMic] = useForm({
        temperature_send: 0,
        id_thermometer_send: "",
        temperature_corrected_send: 0,
        comply_temperature_send: "",
        identification_send: "",
        container_send: "",
        quantity_send: "",
        mark_and_lot_send: "",
        expiration_send: "",
        list_mic_sample: [],

    });

    const {
        temperature_send, id_thermometer_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send, list_mic_sample, expiration_send, mark_and_lot_send,
    } = formMic;

    const [isToma, setIsToma] = useState('');

   

    //#region modales send
    const openModal = () => {
        setModalSend(true);
        setSetFocusNim(false);
    }

    const closeModal = () => {
        setModalSend(false);
        setSetFocusNim(true);
        handleUpdateValuesNim({
            nim: ""
        })
    }

    const handleSendSample = () => {
        setTypeSend("send");
        openModal();
        setSetFocusNim(false);
    }
    //info Patient
    const openModalInfo = () => {
        setModalInfo(true);
        setSetFocusNim(false);
    }

    const openModalMicro = () => {
        setModalMicro(true);
        setSetFocusNim(false);
    }

    const closeModalInfo = () => {
        setModalInfo(false);
        setSetFocusNim(true);
    }

    const closeModalMicro = () => {
        setModalMicro(false);
        setSetFocusNim(true);
    }


    const handleInfo = (_nim) => {
        setTypeInfo("info");
        openModalInfo();
        setSetFocusNim(false);
        getDataFromNim(_nim)
    }
    //cancell Sample
    const openModalCancell = () => {
        setModalRefused(true);
        setSetFocusNim(false);
    }

    const closeModalCancell = () => {
        setModalRefused(false);
        setSetFocusNim(true);
    }

    const handleCancell = (_nim) => {
        setTypeModalRefused("refused");
        openModalCancell();
        setSetFocusNim(false);
        getDataFromNim(_nim)

    }
    //pending Sample
    const openModalPending = () => {
        // setModalPending(true);
        setSetFocusNim(false);
        setViewExams(false)

    }

    const closeModalPending = () => {
        setModalPending(false);
        setSetFocusNim(true);
    }

    const handlePenndingSend = () => {
        setTypePending("pending");
        openModalPending();
        setSetFocusNim(false);
    }
    //#endregion Modales


    //#region automatic
    const nimAutoFocus = () => {
        if (setFocusNim) {
            if (document.getElementById("inputFocusNim") !== null) {
                document.getElementById("inputFocusNim").focus();
            }
        }
    }

    useEffect(() => {
        nimAutoFocus();
        //handleGetInformation();
    }, [setFocusNim]);


    function onKeyPressNim(_event) {
        if (_event.keyCode === 13) {
            let _mValue = _event.target.value;
            setLoading(true);

            if (_mValue.length > 0) {
                getDataFromNimPost();
            }
            else {
                createSweett("", "Debes de ingresar un nim valido", "warning");
                handleUpdateValuesNim({
                    nim: ""
                });
                setSetFocusNim(true);
                setLoading(false);
            }
        }
    }
    //#endregion automatic

    //#region Branch Sample
    const [varSample, setVarSample] = useState("");
    const [code, setCode] = useState("");

    const [pruebas, setPruebas] = useState("");

    useEffect(() => {
        getBranches()
        handleHistoricSend();
        handleReason();
        // handleUpdateValuesR();
    }, [])

    const getBranches = async () => {
        let _listTemp = await handleRequest("GET", "Branch/List", "Sucursal");
        if (_listTemp !== null) {
            let _sampleBranch = _listTemp.find(x => x.id_branch == (idBranch))
            setVarSample(_sampleBranch.toma)
            setCode(_sampleBranch.code)
        }
    }
    //#endregion Branch Sample


    //#region Patient
    const getDataNim = (e) => {
        e.preventDefault();
        setLoading(true);

        if (formNim.nim.length > 0) {
            getDataFromNimPost();
        }
        else {
            createSweet("", "Debes de ingresar un NIM válido", "warning");
            setLoading(false);
            return;
        }
        setLoading(false);
    }

    const [idw, setIdw] = useState(0);
    const [micDetail, setMicDetail] = useState([]);
    const [dataMic, setDataMic] = useState([])

    const getDataFromNimPost = async (activeModal = true) => {
    

        let _isToma = await handleRequest("GET", `Sample/DetailSample/${nim}`, 'sample');
        let is_toma = _isToma.length === 0  ? '' : _isToma;

        getThermometer();
        let _process = varSample === false ? 3 : 2
        let _validBranch = varSample === true ? "Sample" : "Matriz"

        if (_validBranch === "Sample") {

            let _foundMaquila = false;
            let _find = code[2]
            let _nim = nim[2]

            if (_find === _nim) {

                let _isMicro = "";
                let _newForm = {
                    nim: nim.substring(0, 13),
                    id_departament: 1
                }

              

                let maquilaExams = (maquila === true || maquila === "true") ? varSample === true ?  await handleRequest("GET", `ExamBranchesMaquila/List/${branchIdSample}`) : await handleRequest("GET", `ExamBranchesMaquila/ListDestination/${branchIdSample}`) : "";

                let _listTmp = await handleRequest("POST", "Sass/PatientSendSample", "Sample", _newForm);
                if (_listTmp !== null) {
                    let _namePatient = ""
                    let _idgender = 0
                    let _arrayNuevo = [];
                    let _curp = "";
                    let _phone = "";
                    let _age = "";
                    let _nim = 0;
                    let _idWorkOrder = 0;
                    let _isMicro = "";

                    _listTmp.lista_pruebas.forEach(_find => {
                        _namePatient = _find.name_patient
                        _idgender = _find.gender
                        _curp = _find.curp
                        _phone = _find.phone
                        _age = _find.age
                        _nim = _find.nim
                        _idWorkOrder = _find.id_work_order
                        _isMicro = _find.is_micro

                        _arrayNuevo.push({
                            "profiles": _find.listProfile,
                            "exams": _find.listExam
                        })

                        if(maquila === true || maquila === "true")
                        {
                   
        
                            if(maquilaExams !== null && maquilaExams !== '' && maquilaExams.length > 0)
                            {
                        

                            
                                _arrayNuevo.map((item, index) => {
                                    let exams = item.exams;
                                    let profiles = item.profiles;                                    ;
                                    let examsMaquila = []; 
                                    let profileMaquila = [];                                    ;
    
                                    //exams
                                    exams.forEach((exam, indexExam) => {
                                        let findMaquila = maquilaExams.find(x => x.id_exam === exam.id_exam && x.id_branch_origin === exam.id_branch)
                                        if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                        {
                                           
                                            examsMaquila.push(exam);
                                          
                                        }
                                    })
    
                                    item.exams = examsMaquila.slice();

                                    //profiles
                                    profiles.forEach((profile, indexProfile) => {
                                        profile.listExam.forEach((examProfile, i) => {
                                            let findMaquila = maquilaExams.find(x => x.id_exam === examProfile.id_exam && x.id_branch_origin === examProfile.id_branch)
                                            if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                            {
                                                profileMaquila.push(examProfile);
                                            }
                                        })

                                        profile.listExam = profileMaquila.slice();
                                    })


                                });



                             

                
                            }  
                            let tree = createTree(_arrayNuevo, is_toma);
                            setItemsTree(tree);
                        

                           
                            if(tree.length > 0)
                            {
                               _foundMaquila = true;
                            }
                        }
                        else
                        {
                            let tree = createTree(_arrayNuevo, is_toma);
                            setItemsTree(tree);
                            
                        }
                        
                    });
                    
                   
               
                        if((maquila === "true" || maquila === true) && _foundMaquila === false)
                        {
                            createSweet("warning", "warning", "Maquila", "No se encontraron exámenes de maquila");
                            closeModal();
                        }
                        else
                        {
                            setNamepatien(_namePatient);
                            setGender(_idgender);
                            setPhone(_phone);
                            setCurp(_curp);
                            setAge(_age);
                            setDatanim(_nim);
                            setIs_micro(_isMicro)
                            setIdw(_idWorkOrder)
                            closeModal();
                            saveRefusedGlobal(_nim, _idWorkOrder);
                           
                            setTimeout(() => {
                                handleGetInformation(idUsers, _process)
                            }, 1000);
                        }
                   
                   
                    
                }
                closeModal();
                setTimeout(() => {
                    handleGetInformation(idUsers, _process)
                }, 1000);
            }
            else {
                createSweet("info", "info", "Atención", "El nim no pertenece a esta sucursal, selecciona la sucursal donde pertene la toma de este nim");
                closeModal();
            }
        }
        else {

            let _foundMaquila = false;
            let _isMicro = "";
            let _newForm = {
                nim: nim.substring(0, 13),
                id_departament: 1
            }

            
            let _listTmp = await handleRequest("POST", "Sass/PatientSendSample", "Sample", _newForm);
            let _isToma = await handleRequest("GET", `Sample/DetailSample/${nim}`, 'sample');
            let is_toma = _isToma.length === 0  ? '' : _isToma;
            let maquilaExams = (maquila === true || maquila === "true") ? varSample === true ?  await handleRequest("GET", `ExamBranchesMaquila/List/${branchIdSample}`) : await handleRequest("GET", `ExamBranchesMaquila/ListDestination/${branchIdSample}`) : "";
            setDataMic([_listTmp])
            ////console.log('_listTmp: ',_listTmp);
            ////console.log("****************");

            if (_listTmp !== null) {
                let _namePatient = ""
                let _idgender = 0
                let _arrayNuevo = [];
                let _curp = "";
                let _phone = "";
                let _age = "";
                let _nim = 0;
                let _idWorkOrder = 0;
                let _validMic = "";

              
                _listTmp.lista_pruebas.forEach(_find => {
                    _namePatient = _find.name_patient
                    _idgender = _find.gender
                    _curp = _find.curp
                    _phone = _find.phone
                    _age = _find.age
                    _nim = _find.nim
                    _idWorkOrder = _find.id_work_order
                    _isMicro = _find.is_micro
                    _arrayNuevo.push({
                        "profiles": _find.listProfile,
                        "exams": _find.listExam
                    })
                    if(maquila === true || maquila === "true")
                    {
               
    
                        if(maquilaExams !== null && maquilaExams !== '' && maquilaExams.length > 0)
                        {
                    
                            
                        
                            _arrayNuevo.map((item, index) => {
                                let exams = item.exams;
                                let profiles = item.profiles;
                                let examsMaquila = [];
                                let profileMaquila = [];                                    ;

                                //exams
                                exams.forEach((exam, indexExam) => {
                                    let findMaquila = maquilaExams.find(x => x.id_exam === exam.id_exam && x.id_branch_origin === exam.id_branch)
                                    if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                    {
                                        
                                        examsMaquila.push(exam);
                                      
                                    }
                                })

                                item.exams = examsMaquila.slice();

                                //profiles
                                profiles.forEach((profile, indexProfile) => {
                                    profile.listExam.forEach((examProfile, i) => {
                                        //console.log(examProfile);
                                        let findMaquila = maquilaExams.find(x => x.id_exam === examProfile.id_exam && x.id_branch_origin === examProfile.id_branch)
                                        if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                        {
                                          profileMaquila.push(examProfile);
                                        }
                                    })
                                    profile.listExam = profileMaquila.slice();
                                })

                            });



                           

            
                        }  
                        let tree = createTree(_arrayNuevo, is_toma);
                        setItemsTree(tree);
                    
                        
                        if(tree.length > 0)
                        {
                            
                           _foundMaquila = true;
                            
                        }
                    }
                    else
                    {
                        let tree = createTree(_arrayNuevo, is_toma);
                        setItemsTree(tree);

                    }

                    _validMic = _find.is_micro
                });

              
                if((maquila === "true" || maquila === true) && _foundMaquila === false)
                {
                    createSweet("warning", "warning", "Maquila", "No se encontraron exámenes de maquila");
                    closeModal();
                }
                else
                {
                    setNamepatien(_namePatient);
                    setGender(_idgender);
                    setPhone(_phone);
                    setCurp(_curp);
                    setAge(_age);
                    setDatanim(_nim);
                    setIs_micro(_isMicro)
                    setIdw(_idWorkOrder)
                    closeModal();
                    saveRefusedGlobal(_nim, _idWorkOrder);
                    setTimeout(() => {
                        handleGetInformation(idUsers, _process)
                    }, 1000);
                }
            }

            closeModal();
            setTimeout(() => {
                handleGetInformation(idUsers, _process)
            }, 1000);

            setTimeout(() => {

                ////console.log(_isMicro);

                if (_isMicro == true) {

                    setTypeMicro("Ismicro")
                    setSetFocusNim(false);
                    setModalMicro(true);
                    setViewExams(false);

                    _listTmp.lista_pruebas.forEach(_find => {
                        ////console.log(list_mic_sample);

                        let _arrayMic = list_mic_sample;

                        _find.listExam
                            .filter(_mic => _mic.folio_mic !== null)
                            .forEach(_mic => {
                                ////console.log('_mic ',_mic);
                                _arrayMic.push({
                                    "folio_mic": _mic.folio_mic,
                                    "id_mic_detail_sample": _mic.id_mic_detail_sample,
                                    "id_mic_type_sample": _mic.id_mic_type_sample,
                                    "id_work_order": _find.id_work_order,
                                    "name_patient": _find.name_patient,
                                    "code": _mic.codeMic,
                                    "mark_and_lot_send": "",
                                    "expiration_send": "",
                                    "temperature_send": 0,
                                    "id_thermometer_send": "",
                                    "temperature_corrected_send": 0,
                                    "comply_temperature_send": "",
                                    "identification_send": "",
                                    "container_send": "",
                                    "quantity_send": "",
                                    "nameMic": _mic.nameMic
                                });
                                handleUpdateValuesMic({
                                    ...formMic,
                                    list_mic_sample: [..._arrayMic]
                                });

                            });
                    });
                }
            }, 100);
        }
    }

    const handleCloseViewSend = () => {
        setSetFocusNim(true);
        setViewExams(!viewExams);
    }

    const [dataArray, setDataArray] = useState([]);

    const createTree =  (_arrayNuevo, _isToma) => {
      
    
    //    handleFindSends(_arrayNuevo);
        let _tree = [];
        _arrayNuevo.forEach(_find => {

            if (_find.exams.length > 0) {
                _find.exams.forEach(_e => {
                    _tree.push({
                        "children": [],
                        "id": _e.id_work_order_exam,
                        "state": (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) ? 2 : _e.refused_check_specimen === false ? 1 : 2,
                        "text": _e.name,
                        "isLeaf": _e.check_specimen,
                        "refused_check_specimen": (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) ? true : _e.refused_check_specimen,
                        "is_send1": _e.is_send1,
                        "is_send2": _e.is_send2,
                        "is_send3": _e.is_send3,
                        "valueDontSend":  (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined))? 2 : _e.valueDontSend,
                        "id_return_reason": _e.id_return_reason,
                        "label_reason": _e.label_reason,
                        "check_forwarded": _e.check_forwarded
                    })
                });
            }

            if (_find.profiles.length > 0) {
                _find.profiles.forEach(_p => {
                    let examenes_aux = [];
                    let id = true;
                    let checy = 0;
                    let is_send1 = false;
                    let is_send2 = false;
                    let is_send3 = false;
                    let id_return_reason = 0;
                    let label_reason = '';
                    let check_forwarded = false;
                   

                    _p.listExam.forEach((_e) => {
                        examenes_aux.push({
                            "id": _e.id_work_order_exam,
                            "isLeaf": _e.check_specimen,
                            "state": (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) ? 2: _e.refused_check_specimen === false ? 1 : 2,
                            "text": _e.name,
                            "refused_check_specimen": (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) ? true : _e.refused_check_specimen,
                            "is_send1": _e.is_send1,
                            "is_send2": _e.is_send2,
                            "is_send3": _e.is_send3,
                            "valueDontSend": (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) && _e.pending === null ? 2:  _e.valueDontSend,
                            "id_return_reason": _e.id_return_reason,
                            "label_reason": _e.label_reason,
                            "check_forwarded": _e.check_forwarded
                        })
                        id = _e.check_specimen
                        checy = (_e.check_forwarded === false && (_isToma === '' || _isToma === undefined)) ? 2 : _e.refused_check_specimen === false ? 1 : 2
                        is_send1 = _e.is_send1
                        is_send2 = _e.is_send2
                        is_send3 = _e.is_send3
                        check_forwarded = _e.check_forwarded
                        id_return_reason=  _e.id_return_reason 
                        label_reason = _e.label_reason
                    });

                    
                    _tree.push({
                        "children": examenes_aux,
                        "state": checy,
                        "text": _p.name_profile,
                        "isLeaf": id,
                        "is_send1": is_send1,
                        "is_send2": is_send2,
                        "is_send3": is_send3,
                        "valueDontSend":  valueDontSend,
                        "id_return_reason": id_return_reason,
                        "label_reason": label_reason,
                        "check_forwarded": check_forwarded
                    })

                    examenes_aux.forEach((exam, item) =>{
                       
                        if(exam.id_return_reason !== null)
                        {
                            let _id_return_reason = exam.id_return_reason;
                            let _label_reason = exam.label_reason;
                          
                            handleUpdateValuesR({
                                id_return_reason: { value: _id_return_reason, label: _label_reason},
                            });

                            examenes_aux[item].id_return_reason = _id_return_reason;
                            examenes_aux[item].label_reason = _label_reason;
                        }
                    })
                    
                    _tree.forEach((exam, item) =>{
                        if(exam.id_return_reason !== null)
                        {
                            let _id_return_reason = exam.id_return_reason;
                            let _label_reason = exam.label_reason;
                          
                            handleUpdateValuesR({
                                id_return_reason: { value: _id_return_reason, label: _label_reason},
                            });

                            
                            if(_id_return_reason !== 0 && _label_reason !== '')
                            {
                                examenes_aux[item].id_return_reason = _id_return_reason;
                                examenes_aux[item].label_reason = _label_reason;
                            }
                        }
                    })
                        
                    
            
                    
                });
            }
        });
         handleFindSends(_tree);
        setDataArray(_tree);
        return _tree;
    }

    const changeTree = (e) => {
        changeStatus(e)
    }

    const changeStatus = async (e) => {

        const api = treeviewRef.current.api
        const items = api.getItems();
        // //console.log(api)
        // //console.log(items)

        let pending2 = [];

        items.forEach(_find => {
            if (_find.id !== undefined) {
                if (_find.state === 2) {
                    pending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": _find.state === 2 ? true : false,
                                "text": _find.text
                            }
                        ]
                    });
                }
                if (_find.state === 1) {
                    pending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "entra": 0,
                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": false,
                                "text": _find.text
                            }
                        ]
                    });
                }
            }

            //profiles
            else {
                _find.children.forEach(ex => {
                    if (ex.state === 2) {
                        pending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": ex.state === 2 ? true : false
                                }
                            ]
                        });
                    }

                    if (ex.state === 1) {
                        pending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "entra": 0,
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": false,
                                    "text": _find.text
                                }
                            ]
                        });
                    }
                })
            }
        });
        setPendingSample(pending2)
    }

    let validateSpecimen = async () => {

        let _unsubmittedSpecimens = dataArray;



        let examnsPending2 = [];
        _unsubmittedSpecimens.forEach(_find => {
            if (_find.id !== undefined) {
                if (_find.state === 2) {
                    examnsPending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "dont_send": _find.state === 2 ? true : false,
                                "text": _find.text,
                                "is_send1": _find.state == 2 ? true : false,
                            }
                        ]
                    });
                }
                if (_find.state === 1) {
                    examnsPending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "dont_send": false,//false
                                "is_send1": true,
                                "text": _find.text,                             
                                "is_send2": (_find.is_send1 === true || _find.is_send1 === true) ? true : false
                            }
                        ]
                    });
                }
            }

            //profiles
            else {
                _find.children.forEach(ex => {
                    if (ex.state === 2) {
                        examnsPending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "list_refusedTracking": [
                                {
                                    "dont_send": ex.state === 2 ? true : false,
                                    "is_send1": ex.state == 2 ? false : false,
                                }
                            ]
                        });
                    }

                    if (ex.state === 1) {
                        examnsPending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "list_refusedTracking": [
                                {
                                    "dont_send": false,
                                    "text": _find.text,
                                    "is_send1": true,
                                    "is_send2": (_find.is_send1 === true || _find.is_send1 === true) ? true : false

                                }
                            ]
                        });
                    }
                })
            }
        });
        //#endregion Send1

        //#region Send2
        let examnsPending3 = [];
        _unsubmittedSpecimens.forEach(_find => {
            if (_find.id !== undefined) {
                if (_find.state === 2) {
                    examnsPending3.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "dont_send": _find.state === 2 ? true : false,
                                "text": _find.text,
                                "is_send2": _find.state == 1 ? true : false,
                                "is_send1": _find.state == 1 ? true : false,
                            }
                        ]
                    });
                }
                if (_find.state === 1) {
                    examnsPending3.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "dont_send": false,
                                "is_send2": true,
                                "text": _find.text,
                                "is_send1": true,
                            }
                        ]
                    });
                }
            }

            //profiles
            else {
                _find.children.forEach(ex => {
                    if (ex.state === 2) {
                        examnsPending3.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "list_refusedTracking": [
                                {
                                    "dont_send": ex.state === 2 ? true : false,
                                    "is_send2": ex.state == 2 ? true : false,
                                    "is_send2": false,
                                    "is_send1": false,
                                }
                            ]
                        });
                    }

                    if (ex.state === 1) {
                        examnsPending3.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "list_refusedTracking": [
                                {
                                    "dont_send": false,
                                    "text": _find.text,
                                    "is_send2": true,
                                    "is_send1": true,
                                }
                            ]
                        });
                    }
                })
            }
        });

        let _array2 = examnsPending2;
        let _array3 = examnsPending3;

        let _pruebas = pruebas === false ? _array3 : _array2;


        setPruebas(pruebas);
    }

    

    useEffect(() => {
        
        if (pruebas !== '' && pruebas !== undefined && pruebas !== null) {
            setPruebas(pruebas);
             const interval = setInterval(() => {
                 validateSpecimen();
             }, 1200); 
    
            return () => clearInterval(interval); 
        }
      }, [pruebas]); 

    const saveSendSample = async () => {

  
        let _id_return_reason = ( formR.id_return_reason === null || formR.id_return_reason.value === null ||
                                formR.id_return_reason.value === undefined || 
                                formR.id_return_reason.value === '') ? '' : formR.id_return_reason.value;

        
        let _arrayNew = dataArray;

        
        //#region Send1
        let pending2 = [];
        _arrayNew.forEach(_find => {
            if (_find.id !== undefined) {
                if (_find.state === 2) {
                    pending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": _find.state === 2 ? true : false,
                                "text": _find.text,
                                "is_send1": _find.state == 2 ? true : false,
                                "valueDontSend": (_find.valueDontSend === undefined || '' || null) || _find.state === 1 ? 0 : _find.valueDontSend,
                                "id_return_reason": _id_return_reason
                            }
                        ]
                    });
                }
                if (_find.state === 1) {
                    pending2.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "entra": 0,
                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": false,//false
                                "is_send1": true,
                                "text": _find.text,
                                "aquiQuiteelSend": 0,//me causa duda                               
                                "wilson": _find.is_send1,
                                "wilson2": _find.is_send2,
                                // "is_send2": (_find.is_send1 === true || _find.is_send1 === true) ? true : false,
                                "valueDontSend" : ( _find.valueDontSend === undefined || '' || null) || (_find.state === 1) ? 0 : _find.valueDontSend,
                                "is_send2": false
                            }
                        ]
                    });
                }
            }

            //profiles
            else {
                
                _find.children.forEach(ex => {
                   
                    if (ex.state === 2) {
                        pending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": ex.state === 2 ? true : false,
                                    "is_send1": ex.state == 2 ? false : false,
                                    "valueDontSend": ex.valueDontSend === undefined || '' || null ? 0 : ex.valueDontSend,
                                    "id_return_reason": _id_return_reason
                                }
                            ]
                        });
                    }

                    if (ex.state === 1) {
                        pending2.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "entra": 0,
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": false,
                                    "text": _find.text,
                                    "is_send1": true,
                                    "is_send2": false,
                                    "gatoaquientrosiledirechazo": 0,//me causa duda 
                                    // "is_send2": (_find.is_send1 === true || _find.is_send1 === true) ? true : false,
                                    "valueDontSend" : _find.valueDontSend === undefined || '' || null ? 0 : _find.valueDontSend,
                                }
                            ]
                        });
                    }
                })
            }
        });
        //#endregion Send1

        //#region Send2
        let pending3 = [];
        _arrayNew.forEach(_find => {
            if (_find.id !== undefined) {
                if (_find.state === 2) {
                    pending3.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": _find.state === 2 ? true : false,
                                "text": _find.text,
                                "is_send2": _find.state == 1 ? true : false,
                                "is_send1": _find.state == 1 ? true : false,
                                "gatos": 0,
                                "aqui": 0,
                                "valueDontSend": _find.valueDontSend === undefined || '' || null ? 0 : _find.valueDontSend, 
                                "id_return_reason": _id_return_reason,
                            }
                        ]
                    });
                }
                if (_find.state === 1) {
                    pending3.push({
                        "id_work_order": idw,
                        "id_work_order_exam": _find.id,
                        "entra": 0,
                        "entra": 0,

                        "list_refusedTracking": [
                            {
                                "refused_check_specimen": false,
                                "is_send2": true,
                                "text": _find.text,
                                "gatos": 0,
                                "is_send1": true, 
                               
                            }
                        ]
                    });
                }
            }

            //profiles
            else {
                _find.children.forEach(ex => {
                    if (ex.state === 2) {
                      
                        pending3.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": ex.state === 2 ? true : false,
                                    "is_send2": ex.state == 2 ? true : false,
                                    "is_send2": false,
                                    "is_send1": false,
                                    "esteGatoentra": 0,
                                    "valueDontSend": ex.valueDontSend === undefined || '' || null ? 0 : ex.valueDontSend, 
                                    "id_return_reason": _id_return_reason,
                                }
                            ]
                        });
                    }

                    if (ex.state === 1) {
                        pending3.push({
                            "id_work_order": idw,
                            "id_work_order_exam": ex.id,
                            "entra": 0,
                            "list_refusedTracking": [
                                {
                                    "refused_check_specimen": false,
                                    "text": _find.text,
                                    "is_send2": true,
                                    "is_send1": true,
                                    "gato": 0,
                                    
                                }
                            ]
                        });
                    }
                })
            }
        });
        //#endregion Send2

        let _arraySen2 = pending2
        let _arraySen3 = pending3

        let _sendProcess = varSample === false ? _arraySen3 : _arraySen2




        if (_id_return_reason === '' && ((itemsDontSend.some(item => item.children.some(child => child.valueDontSend === 1)) && itemsDontSend.some(item => item.children.some(child => child.state === 2))) || (itemsDontSend.some(item => item.valueDontSend === 1)) && itemsDontSend.some(item => item.state ===2)))
        {
            createSweet("error", "warning", "Motivo de rechazo", "Por favor, selecciona un motivo de rechazo");
        }
        else
        {
          //  //console.log('Estás pasando por aca? ', _sendProcess);
           let listTmp = await handleRequest("POST", "Sass/RefusedSample", "Sample", _sendProcess);
            if (listTmp !== null) {
                createSweet("", "success", "Éxito", "Éxito");
            }

           getDataFromNim(datanim);
        }
    
    }

    useEffect(() => {
        let _process = varSample === false ? 3 : 2

        setTimeout(() => {
            handleGetInformation(idUsers, _process)
        }, 1000);
    }, [])

    useEffect(() => {
        let _process = varSample === false ? 3 : 2

        setTimeout(() => {
            handleGetInformation(idUsers, _process)
        }, 1000);
    }, [varSample])

    const saveRefusedGlobal = async (_nim, _idWorkOrder) => {

        let _proccess = varSample === false ? 3 : 2

        let _body = {
            "nim": _nim,
            "id_user": idUsers,
            "id_process": _proccess,
            "id_work_order": _idWorkOrder
        }

        const _response = await handleRequest("POST", "Tracking/SendSampligs", "refused", _body);
        if (_response !== null) {
            setViewExams(true);
        }
        else {
            createSweet("error", "error", "El NIM no cuenta con una toma de muestra");
        }
    }

    const handleGetInformation = async (_info) => {
        setLoading(true);

        let listNim = [];
        let _process = varSample === false ? 3 : 2

        let listTmp = await handleRequest("GET", `Tracking/DetailNimTracking/${idUsers}/${_process}`, 'tracking');
        ////console.log(listTmp);
        if (listTmp !== null) {

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            listTmp.trackingListNim.forEach((element, index) => {

                let posicion = index + 1;
                let visibleTable = false;

                if (posicion > startItems && posicion <= endItems) {
                    visibleTable = true;
                }
                listNim.unshift({
                    id_tracking: element.id_tracking,
                    id_user: element.id_user,
                    enviado: element.enviado,
                    complete: element.complete,
                    completeFlebotomia: element.completeFlebotomia,
                    nim: element.nim,
                    isMic: element.isMic,
                    visibleTable: visibleTable,
                })
            });
            setListNims(listNim);
            setSearchNimData(listNim);
            setListNim(listNim);
        }
        setLoading(false);
    }


    //#region Search
    const [nimSearchData, setSearchNimData] = useState([]);
    const [listNim, setListNim] = useState([]);

    const handleSearchNim = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        nimSearchData.forEach(element => {
            //////console.log(element);
            let cadena = element.nim.toUpperCase();

            if ((cadena.indexOf(busqueda.toUpperCase()) > -1)) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        //setSetFocusNim(false);
        setListNims(newMethod);
    }
    //#endregion Search

    //#region pagination
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listNims.length, numberItems);

    useEffect(() => {
        setLoading(true);
        let startItems = 0;
        let endItems = numberItems;
        let aux = numberItems * currentPage;

        endItems = aux;
        startItems = endItems - numberItems;

        let listSend = [];

        listNims.forEach((data, key) => {
            let posicion = key + 1;
            let visibleTable = false;
            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listSend.push({
                ...data,
                visibleTable: visibleTable
            })
        });
        setListNims(listSend);
        setLoading(false);
    }, [totalPageCount, currentPage]);

    //#endregion pagination


    const getDataFromNim = async (_nim) => {
        setLoadinginfo(true);
        
        let _newForm = {
            nim: _nim,
            id_departament: 1
        }

        let maquilaExams = (maquila === true || maquila === "true") ? varSample === true ?  await handleRequest("GET", `ExamBranchesMaquila/List/${branchIdSample}`) : await handleRequest("GET", `ExamBranchesMaquila/ListDestination/${branchIdSample}`) : "";
        let _isToma = await handleRequest("GET", `Sample/DetailSample/${_nim}`, 'sample');
        let is_toma = _isToma.length === 0  ? '' : _isToma;

        let _listTmp = await handleRequest("POST", "Sass/PatientSendSample", "Sample", _newForm);
       
        if (_listTmp !== null) {

            let _namePatient = ""
            let _idgender = 0
            let _arrayNuevo = [];
            let _curp = "";
            let _phone = "";
            let _age = "";
            let _nim = 0;
            let _idWorkOrder = 0;
           


            _listTmp.lista_pruebas.forEach(_find => {
                _namePatient = _find.name_patient
                _idgender = _find.gender
                _curp = _find.curp
                _phone = _find.phone
                _age = _find.age
                _nim = _find.nim
                _idWorkOrder = _find.id_work_order

                _arrayNuevo.push({
                    "profiles": _find.listProfile,
                    "exams": _find.listExam
                })
                
                if(maquila === true || maquila === "true")
                {
           

                    if(maquilaExams !== null && maquilaExams !== '' && maquilaExams.length > 0)
                    {
                

                    
                        _arrayNuevo.map((item, index) => {
                            let exams = item.exams;
                            let profiles = item.profiles;
                            let examsMaquila = [];
                            let profileMaquila = [];                                    ;

                            //exams
                            exams.forEach((exam, indexExam) => {
                                let findMaquila = maquilaExams.find(x => x.id_exam === exam.id_exam && x.id_branch_origin === exam.id_branch)
                                if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                {
                                    
                                    examsMaquila.push(exam);
                                }
                            })
                            item.exams = examsMaquila.slice();

                            //profiles
                            profiles.forEach((profile, indexProfile) => {
                                profile.listExam.forEach((examProfile, i) => {
                                    let findMaquila = maquilaExams.find(x => x.id_exam === examProfile.id_exam && x.id_branch_origin === examProfile.id_branch)
                                    if(findMaquila !== null && findMaquila !== '' && findMaquila !== undefined)
                                    {
                                        profileMaquila.push(examProfile);
                                    }
                                })
                                profile.listExam = profileMaquila.slice();
                            })
                            

                        });



                       

        
                    }  
                    let tree = createTree(_arrayNuevo, is_toma);
                    setItemsTree(tree);
                    
                
                }
                else
                {
                    let tree = createTree(_arrayNuevo, is_toma);
                    setItemsTree(tree);
                    
                  
                    
                }
            });
            setNamepatien(_namePatient);
            setGender(_idgender);
            setPhone(_phone);
            setCurp(_curp);
            setAge(_age);
            setDatanim(_nim);
            setIdw(_idWorkOrder)
            setIdWorkOrder(_idWorkOrder);
            
            //handleFindSends(pruebas12);
        }
    }
    //#endregion Patient

    //#region Refused Patient
    const handleReason = async () => {
        let _listTmp = await handleRequest("GET", `ReturnReason/List?is_sample=false&is_work_order=false&is_tracking=false&is_send=true`, 'tracking');

        if (_listTmp !== null) {
            let _list = [];

            _listTmp.forEach((_find, i) => {
                _list.push({
                    value: _find.id_return_reason,
                    label: _find.reason
                });
            });
            setReason([..._list]);
        }
    }

    const refusedPatient = async (_idWorkOrder) => {
        let _process = varSample === false ? 3 : 2
        let _id_return_reason2 = formR.id_return_reason.value
        let _id = _idWorkOrder

        let _newForm = [{
            id_work_order: _id,
            id_return_reason: _id_return_reason2,
        }];

        let _listTmp = await handleRequest("POST", "Tracking/RefusedNimPatient", "Trackin", _newForm);
        ////console.log('_listTmp ',_listTmp)
        if (_listTmp !== null) {
            createSweet("create", "success", "Se rechazó muestra exitosamente!");
            closeModalCancell();
            setTimeout(() => {
                handleGetInformation(idUsers, _process)
            }, 1000)
        }
        setTimeout(() => {
            handleGetInformation(idUsers, _process)
        }, 1000)
    }

    const handleHistoricSend = async (filters = false) => {

        setLoadinPending(false)

        let _body = {};
        if (filters === false) {
            _body = {
                "date_init": "",
                "date_end": "",
                "type": "all"
            }
        } else {
            _body = {
                "date_init": dateInit,
                "date_end": dateEnd,
                "type": "filter"
            }
        }
        let _listTemp = await handleRequest("POST", `Tracking/DetailSend?id_user=${idUsers}`, "Tracking", _body);

        if (_listTemp !== null) {
            setLoadinPending(true)
            setHistoric([..._listTemp]);
        }
    }

    const changeDate = (data, type) => {
        let _fecha = new moment(data._d).format('YYYY-MM-DD');
        if (type === "init") {
            setDateInit(_fecha)
        } else {
            setDateEnd(_fecha)
        }
    }
    //#endregion Refused Patient

    //#region FactorCorrection
    const [factorCorrection, setFactorCorrection] = useState(0);
    const factorCorrectionRef = useRef(factorCorrection);

    useEffect(() => {
        factorCorrectionRef.current = factorCorrection;
    }, [factorCorrection]);

    const calculateCorrectedTemperature = (temperature, correctionFactor) => {

        return (parseFloat(temperature) + parseFloat(correctionFactor)).toFixed(2);
    };

    // useEffect(() => {

    //     const getFactor = async () => {

    //         const dataMic = thermometers.find(m => m.id_thermometer === parseInt(formMic.id_thermometer_send));
            
    //         if (thermometers !== 0) {
    //            // let findT = Number(await fetchCorrectionFactor(dataMic.correction_factor));
    //             ////console.log(formMic.list_mic_sample[0].temperature_send)
    //             if (dataMic !== null) {

    //                 //let temperatureValue = formMic.list_mic_sample.map(mic => parseFloat(mic.temperature_send));

    //                 let temperatureValue = Number(formMic.list_mic_sample[0].temperature_send);
    //                 let sumTotal = (dataMic.correction_factor + temperatureValue);
    //                 sumTotal = Number(sumTotal.toFixed(2));
    //                 setFactorCorrection(sumTotal);

    //                 // let temperatureValue = formMic.list_mic_sample.map(mic => parseFloat(mic.temperature_send));
    //                 // let sumTotal = temperatureValue.reduce((total, value) => total + value, 0);
    //                 // sumTotal = Number(sumTotal.toFixed(2));
    //                 // setFactorCorrection(sumTotal);
    //             } else {
    //                 setFactorCorrection(0);
    //             }
    //         }
    //     }
    //     getFactor();
    // },);


    async function fetchCorrectionFactor(valueThermometer) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (valueThermometer && valueThermometer.correction_factor) {
                    resolve(valueThermometer.correction_factor);
                } else {
                    resolve(null);
                }
            },);
        });
    }
    //#endregion FactorCorrection

    //#region Thermomether
    const [thermometers, setThermometers] = useState([])

    const getThermometer = async () => {
        let requestOptions = {
            method: 'GET'
        };
        const response = await sendRequest(requestOptions, "Thermometers/List");
        if (response.code === 200) {
            let thermometer = [];
            response.data.forEach(s => {
                thermometer.push(
                    {
                        ...s,
                        value: s.id_thermometer,
                        label: s.name_thermometer
                    }
                );
            });
            setThermometers(thermometer);
        }
        else if (response.code === 500) {
            createSweet("error", "error", "Microbiología", response.data.msg);
        }
        else {
            createSweet("error", "warning", "Microbiología", response.data.msg);
        }
    }
    //#endregion

    //#region micDetail

    const handleChangeMic = (e, _id_mic_detail_sample) => {
        ////console.log(_id_mic_detail_sample);
        let _list = list_mic_sample;
        let _value = e.target.value;
        let _name_input = e.target.name;

        let _find_mic = _list.find(x => x.id_mic_detail_sample === _id_mic_detail_sample);
    
        if (_find_mic !== undefined) {
            switch (_name_input) {
                case "temperature_send":
                    _find_mic.temperature_send = _value;
                    break;
                case `id_thermometer_send${_id_mic_detail_sample}`:
                    _find_mic.id_thermometer_send = _value;
                    break;
                case "temperature_corrected_send":
                    _find_mic.temperature_corrected_send = _value;
                    break;
                case "identification_send":
                    _find_mic.identification_send = _value;
                    break;
                case "quantity_send":
                    _find_mic.quantity_send = _value;
                    break;
                case "container_send":
                    _find_mic.container_send = _value;
                    break;
                case "comments":
                    _find_mic.comments = _value;
                    break;
                case "mark_and_lot_send":
                    _find_mic.mark_and_lot_send = _value;
                    break;
                case "expiration_send":
                    _find_mic.expiration_send = _value;
                    break;
                default:
                    _find_mic.comply_temperature_send = _value;
                    break;
            }
        }
    
        handleUpdateValuesMic({
            ...formMic,
            list_mic_sample: [..._list]
        });
    }
    
    const getFactor = (e) => {

        let valor = e
        let _val = formMic.id_thermometer_send;
 
    
        const dataMic = thermometers.find(m => m.id_thermometer === parseInt(formMic.id_thermometer_send));
    
        if (thermometers !== 0) {
            if (dataMic !== null) {
                valor = parseFloat(valor);
                let sumTotal = (parseFloat(dataMic.correction_factor) + valor).toFixed(2);
    
                formMic.temperature_corrected_send = sumTotal;
                ////console.log('After Update:', formMic.id_thermometer_send, formMic.temperature_corrected_send);
            } else {
                // setFactorCorrection(0);
            }
        }
    }
    
    const saveMicDetailSend = async () => {
        let _arrayMic = list_mic_sample
        let _detailMic = [];

        _arrayMic.forEach(_find => {
            _detailMic.push({
                "id_mic_detail_sample": _find.id_mic_detail_sample,
                "id_work_order_exam": _find.id_work_order,
                "list_mic_detail": [
                    {
                        "temperature_send": _find.temperature_send,
                        "id_thermometer_send": _find.id_thermometer_send,
                        "temperature_corrected_send": _find.temperature_corrected_send,
                        "comply_temperature_send": _find.comply_temperature_send,
                        "identification_send": _find.identification_send,
                        "container_send": _find.container_send,
                        "quantity_send": _find.quantity_send,
                        "comments": _find.comments,
                        "mark_and_lot_send": _find.mark_and_lot_send,
                        "expiration_send": _find.expiration_send,
                    }
                ]
            })
        });

        let listTmp = await handleRequest("POST", "Sass/MicSample", "Sample", _detailMic);

        if (listTmp !== null) {
            createSweet("", "success", "Éxito", "Éxito.");
            closeModalMicro();
            handleCloseViewSend();
        }
    }
    //#endregion micDetail


    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {

            return response.data;

        }
        else if (response.code === 409) {
            setViewExams(false);

            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    //#region Search NIM

    const getNim = (e, search = true) => {
        e.preventDefault();

        if (search) {
            let query = "";
            if (nim !== "") {
                query += `&nim=${nim}`;
            }
            getAmount(true, query)
        }
    }

    useEffect(() => {
        if (listNims.length === 0) {
            getAmount();
        }
        // getUser();
    }, []);

    const getAmount = async (search = false, filter, first = false,) => {

        let requestOptions = {
            method: 'GET'
        };

        var respuesta = {};

        let alterUrl = "";

        if (search === true) {
            let _process = varSample === false ? 3 : 2
            respuesta = await sendRequest(requestOptions, `Tracking/DetailNimTracking/${idUsers}/${_process}`);
        }

        let tmpDetail = [];
        let tmpDetailExcel = [];

        if (respuesta.code === 200) {

            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;
            startItems = endItems - numberItems;

            if (respuesta.data.trackingListNim.length >= 1) {
                respuesta.data.trackingListNim.forEach((element, index) => {

                    if (element.nim === nim) {  // Aquí agregamos la condición de comprobar el NIM.

                        let posicion = index + 1;
                        let visibleTable = false;

                        if (posicion > startItems && posicion <= endItems) {
                            visibleTable = true;
                        }
                        tmpDetail.push({
                            id_tracking: element.id_tracking,
                            id_user: element.id_user,
                            enviado: element.enviado,
                            complete: element.complete,
                            completeFlebotomia: element.completeFlebotomia,
                            nim: element.nim,
                            isMic: element.isMic,
                            visibleTable: visibleTable,
                        })

                    }
                });
            }

            setListNims([...tmpDetail]);
            setDatanim(nim);

        }
    }
    //#endregion Search NIM

    //#region Clean
    const handleCleanFilter = () => {
        setLoading(true);
        handleUpdateValuesNim({
            nim: "",
            ids_users: 0
        })
        handleGetInformation()
        setLoading(false);
    }



    
    useEffect(() => {
        const timeout = setTimeout(() => {
            let copy = itemsTree;
            setItemsDontSend([...copy]);
        }, 1000); 
    
        return () => clearTimeout(timeout);
    }, [itemsDontSend]);


    
    const handleFindSends = (items) => {
       
        // //console.log(items);
     
       if(items !== undefined && items.length > 0) 
       {
        let _completeAllExams = items.every(x => x.is_send1 === true);
        setCompleteAllExams(_completeAllExams);
       }
    }

    const handleDontSend = (e, _id_analyte) => {
        let _valueDontSend = e.target.value;

            
            itemsDontSend.forEach((element, index) => {
                //invidual examns
                if (element.id === _id_analyte)
                {
                    element.valueDontSend = parseInt(_valueDontSend);
                }
                //profiles 
                element.children.forEach((_analyte, i) => {
                    if (_analyte.id === _id_analyte)
                    {
                        _analyte.valueDontSend = parseInt(_valueDontSend);
                    }
                })
            });
            
        setItemsTree(...[itemsDontSend]);
        
        
    }

    const handleDontSendAll = (e) =>
    {
        let isChecked = e.target.checked;
       

        itemsDontSend.forEach((element, index) => {
            //invidual examns
            if (element.state === 2)
            {
                element.valueDontSend = isChecked === true ? 1 : 0;
            }
            //profiles 
            element.children.forEach((_analyte, i) => {
                if (_analyte.state === 2)
                {
                    _analyte.valueDontSend = isChecked === true ? 1 : 0;
                }
            })
        });
        
       setItemsTree(...[itemsDontSend]);
    }

    const handlePendingAll = (e) => {
        let isChecked = e.target.checked;

        //Individual
        itemsDontSend.forEach((item, index) =>{
            if(item.state === 2)
            {
                item.valueDontSend = isChecked === true ? 2 : 0
            }


            item.children.forEach((element, key) => {
                if(element.state === 2)
                {
                    element.valueDontSend = isChecked === true ? 2 : 0;
                }
            })
        });

        setItemsTree(...[itemsDontSend]);
    }

   
    //#endregion clean












    return {
        itemsDontSend,handleDontSend,handleDontSendAll, handlePendingAll, valueDontSend,phlebotomist_name,list_analytes,
        //modal send
        typeSend, modalSend, openModal, closeModal, handleSendSample, nimAutoFocus, onKeyPressNim, getDataNim,
        handleSelectValuesNim, nim, setFocusNim, handleInputChangeNim, viewExams, handleCloseViewSend,
        thermometers, factorCorrection, dataMic,

        //info patient
        namepatien, age, curp, phone, gender, treeviewRef, itemsTree, listNims, flebo, idWorkOrder,
        openModalInfo, closeModalInfo, handleInfo, typeInfo, modalInfo, loadinginfo, datanim,
        //refused Sample
        typeModalRefused, modalRefused, closeModalCancell, handleCancell, reason, handleSelectValuesR, id_return_reason,
        refusedPatient, varSample,
        //pending Send Sample
        openModalPending, closeModalPending, handlePenndingSend, typePending, modalPending,
        //refused patient
        changeTree, saveSendSample,validateSpecimen, pruebas,
        //historic
        historic, varSample, loadinPending, dateInit, dateEnd, changeDate, handleHistoricSend,
        //pagination
        totalPageCount, currentPage, nextPage, previousPage, goToPage, getNim, handleSearchNim, handleCleanFilter,
        loading,
        //microsanitaria
        is_micro, typeMicro, modalMicro, closeModalMicro, micDetail, id_thermometer_send,
        temperature_send, temperature_corrected_send, comply_temperature_send, identification_send, container_send, quantity_send, expiration_send, mark_and_lot_send,
        handleInputChangeMic, list_mic_sample, handleChangeMic, saveMicDetailSend,

        //completeExams
        completeAllExams
    }

}



