import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useForm } from '../forms/useForm';
import { validarSesion } from '../tokens/useToken';
import { LostConnection } from '../../constant';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';

export const useDetailAgreementCompany = ()=> {

    
    const history = useHistory();

    const { companyId } = useParams();

    const [createSweet] = useSweetAlert();

    const [company, setCompany] = useState({});
    const [agreements, setAgreements] = useState([]);

    const [catReason, setCatReason] = useState([]);
    const [listAgreementPayments, setListAgreementPayments] = useState([]);

    const [modal, setModal] = useState(false); 
    const [method, setMethod] = useState("");   

    const [loading, setLoading] = useState(false);

    const [reloadInformation, setReloadInformation] = useState(false);

    
    const [nim, setNim] = useState([]);
    const [test, setTest] = useState([]);

   
    const toggle = () => {
        setModal(!modal);
    }

    const getData = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Agreement/ListAgreementsFromCompany/" + companyId);
        if (respuesta.code === 200) {
            setCompany(respuesta.data);

            let convenios = respuesta.data.agreementViews.map(element => {
                let percentage = 0;
                if (element.id_financing_methods === 3) {
                    percentage = (element.used * 100 / element.limit).toFixed(2);
                } else {
                    if (element.limit === 0) {
                        percentage = 100;
                    } else {
                        percentage = (element.used * 100 / element.limit).toFixed(2);
                    }
                }

                element.options = element.periods;
                element.selected = {
                    value: element.number_period,
                    label: "Periodo: " + element.from + " -- Al día de hoy"
                }

                element.graph = {
                    series: [percentage],
                    options: {
                        chart: {
                            height: 200,
                            type: 'radialBar',
                        },
                        plotOptions: {
                            radialBar: {
                                hollow: {
                                    margin: 15,
                                    size: "70%"
                                },

                                dataLabels: {
                                    showOn: "always",
                                    name: {
                                        offsetY: -10,
                                        show: true,
                                        color: "#888",
                                        fontSize: "13px"
                                    },
                                    value: {
                                        color: "#111",
                                        fontSize: "30px",
                                        show: true
                                    }
                                }
                            }
                        },
                        fill:{
                            type: 'gradient',
                             gradient: {
                              shade: 'dark',
                              type: 'horizontal',
                              shadeIntensity: 0.5,
                              gradientToColors: ['#ABE5A1'],
                              inverseColors: true,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 100]
                               }
                           },
                        stroke: {
                            lineCap: 'round'
                        },
                        labels: ['Gastado'],
                    }
                }
                element.used = element.used.toFixed(2)

                return element;
            });
            setAgreements(convenios)
        } 
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getData);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Actualizar convenio", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Actualizar convenio", respuesta.data.msg);
        }
    }

    const getReturnReason = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListReason = [];

        const respuesta = await sendRequest(requestOptions, "ReturnReason/List");

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListReason.push({
                        id_return_reason: value.id_return_reason,
                        reason: value.reason
                    });
                });
                setCatReason(ListReason);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se encontraron las razones");
                setCatReason([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getReturnReason);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Actualizar convenio", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Actualizar convenio", respuesta.data.msg);
        }
    }

    useEffect(() => {

        getData();    

    }, [])


    const [formValues, handleInputChange, reset, handleUpdateValues, handleSelectValues] = useForm({

        id_agreement: 0,
        id_agreement_period: 0,
        id_company: companyId,
        id_work_order: 0,
        selectWorkOrderTest: 0,
        id_work_order_exam: 0,
        id_work_order_profile: 0,
        id_user: 0,
        id_return_reason: 0,
        amount: 0, 
            
    });

    const {

        id_return_reason,amount,id_agreement_period,
        id_company,id_work_order,id_work_order_exam,
        id_work_order_profile,id_user,selectWorkOrderTest

    } = formValues;


    const handleCreateReturn = (number_period, id_agreement) => {

        handleUpdateValues({
      
        id_agreement: id_agreement,
        id_agreement_period: number_period,
        id_company: companyId,
        id_work_order: 0,
        selectWorkOrderTest: 0,
        id_work_order_exam: 0,
        id_work_order_profile: 0,
        id_user: 0,
        id_return_reason: 0,
        amount: 0, 

        });

        getReturnReason();
        getWorkOrder();
        getListAgreementPayment(id_agreement, number_period);
        toggle();
        setMethod("create");

    }


    const getListAgreementPayment = async (id_agreement, number_period) => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "AgreementPayment/List/"+id_agreement+','+number_period);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {                
                setListAgreementPayments(respuesta.data);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "No se han encontrado pagos ");
                setListAgreementPayments([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getListAgreementPayment);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Listado de convenio", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Listado de convenio", respuesta.data.msg);
        }
    }


    const  getWorkOrder = async () => {

        let requestOptions = {
            method: 'GET'
        };

        let ListNIM = [];

        const respuesta = await sendRequest(requestOptions, "WorkOrder/ListWorkOrdersByCompany/" + id_company);

        if (respuesta.code === 200) {

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    ListNIM.push({
                        id_work_order: value.id_work_order,
                        nim_work_order: value.nim_work_order
                    });
                });
                setNim(ListNIM);
            }
            else {
                createSweet("error", "error", "Hubo un error!", "Ningún NIM encontrado");
                setNim([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getWorkOrder);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Listado de convenio", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Listado de convenio", respuesta.data.msg);
        }
    }

    const handleChangeNimWorkOrder = (e) => {

        handleInputChange(e);
           getTest(e.target.value);

    }


    const  getTest = async (id_work_order) => {

        let requestOptions = {
            method: 'GET'
        };

        let LisTest = [];

        const respuesta = await sendRequest(requestOptions, "WorkOrder/LisDetailWorkOrderTest/" + id_work_order);

        if (respuesta.code === 200) {
            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((value) => {
                    LisTest.push({
                        value: value.id_work_order_exam !== null ? value.id_work_order_exam : value.id_work_order_profile,
                        label: "[ "+value.code_test+" ]"+" "+value.name_test,
                        type:  value.id_work_order_exam !== null ? "workOrderExam" : "workOrderProfile",
                    });
                });
                setTest(LisTest);
            }
            else {

                createSweet("error", "error", "Hubo un error!", "No se encontraron los exámenes");
                setTest([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getTest);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Listado de convenio", "Ocurrio un error en el servidor");
        }
        else{
            createSweet("error", "warning", "Listado de convenio", respuesta.data.msg);
        }
    }


    const saveCreate = async () => {

        setLoading(true);

        let selectTest = formValues.selectWorkOrderTest;
        
        let body = {

            id_agreement: formValues.id_agreement,
            id_agreement_period : formValues.id_agreement_period,
            id_company: formValues.id_company,
            id_work_order: formValues.id_work_order,
            id_work_order_exam: selectTest.type == "workOrderExam" ? selectTest.value : 0,
            id_work_order_profile:  selectTest.type == "workOrderProfile" ? selectTest.value : 0,
            id_user: formValues.id_user,
            id_return_reason:  formValues.id_return_reason,
            amount: formValues.amount

        }


        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };


        const respuesta = await sendRequest(requestOptions, "Agreement/CreateReturnTransaction");

        if (respuesta.code === 200) {

            getReturnReason();
            reset();
            createSweet("create", "success", "Exito!", "Se asigno una nueva Devolución");            
            setLoading(false);
            toggle();

            setReloadInformation(true);            

            handleUpdateValues({

                id_agreement: 0,
                id_agreement_period: 0,
                id_company: companyId,
                id_work_order: 0,
                id_work_order_exam: 0,
                id_work_order_profile: 0,
                id_user: 0,
                id_return_reason: 0,
                amount: 0, 

            })

            setTimeout(() => {
                setReloadInformation(false);
            }, 2000);
        }

        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if(respuesta.code === 500){
            createSweet("error", "error", "Listado de convenio", "Ocurrio un error en el servidor");
            setLoading(false);
        }
        else{
            createSweet("error", "warning", "Listado de convenio", respuesta.data.msg);
            setLoading(false);
        }
    }


    return {
        company, agreements, toggle, modal, catReason, listAgreementPayments,loading,
        //method
        handleCreateReturn, handleInputChange, method,saveCreate, handleSelectValues,
        handleChangeNimWorkOrder,
        //form return reason
        id_agreement_period,id_company, id_work_order,id_work_order_exam,
        id_work_order_profile,id_user, id_return_reason,amount,
        //info
        reloadInformation,nim,test,selectWorkOrderTest
    }
}
