import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeletePatient } from './DeletePatient';
import { FormPatient } from './FormPatient';
import { UpdatePatient } from './UpdatePatient';
import { FormUpdateBusinessName } from './FormUpdateBusinessName';
import './StyleModalPatients.css'

export const ModalPatient = (props) => {

    const {
        modal, toggle, modalTitle, method, loading,
        validationsPatient, validHopitalInterfaced,

        handleInputChange, handleSelectValues, handleDate, getAge, getBirthday, handleWheel,

        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender,
        tax_regime_patient, business_name, expedient_number,

        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,

        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
        savePatient, saveUpdate, saveDelete,

        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender, handleChangeTypePatient,
        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE,
        showClassINE, img64File, handleOnChangeINEU, codeP, edad, visibility, 
        modalPatient1, toggleTab, activeTab, isEditWorkOrder, handleAgeC, handleDateB, isEditPatient,
    } = props;
    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} className='modal modal-xl' scrollable={true}>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            {
                method === "create"
                    ?
                    <FormPatient
                        button="Guardar"
                        {
                        ...{
                            method, toggle, loading, validationsPatient, methodAction: savePatient,
                            handleInputChange, handleSelectValues, handleDate, getBirthday, getAge, handleWheel,
                            id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
                            id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
                            curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender, expedient_number,
                            listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,
                            handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender, getAge, getBirthday,
                            isInvoiceService, setIsInvoiceService, validHopitalInterfaced,
                            viewNewSocialGender, setViewNewSocialGender, 
                            scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
                            visibility, modalPatient1, toggleTab, activeTab, isEditWorkOrder, handleAgeC, handleDateB, isEditPatient,
                        }
                        }
                    />
                    : (method === "update")
                        ?
                        <FormPatient
                            button="Actualizar"
                            {
                            ...{
                                method, toggle, loading,
                                validationsPatient,
                                methodAction: saveUpdate,
                                handleInputChange, handleSelectValues, handleDate, handleWheel,
                                id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
                                id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
                                curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender, expedient_number,
                                listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,
                                handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
                                isInvoiceService, setIsInvoiceService, validHopitalInterfaced,
                                viewNewSocialGender, setViewNewSocialGender, toggleTab, activeTab, isEditWorkOrder, getAge, handleDateB,
                                isEditPatient,
                            }
                            }
                        />
                        : (method === "delete")
                            ?
                            <DeletePatient
                                {
                                ...{
                                    toggle, loading, paternal_surname, maternal_surname, name, saveDelete
                                }
                                }
                            />
                            : (method === "updateData")
                                ?

                                <FormPatient
                                    button="Actualizar datos"
                                    {
                                    ...{
                                        method, toggle, loading,
                                        validationsPatient,
                                        methodAction: saveUpdate,
                                        handleInputChange, handleSelectValues, handleDate, getBirthday, getAge, handleWheel,
                                        id_gender, id_state, id_municipality, id_blood_type, id_social_gender, id_tax_regime,
                                        id_use_cfdi, rfc, type_person, code, paternal_surname, maternal_surname, name, birthday, age,
                                        curp, address, cp, colony, phone, email, membership, cardnumber, new_social_gender, expedient_number,
                                        listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi, listSocialGender,
                                        handleChangeState, handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender, getAge, getBirthday,
                                        isInvoiceService, setIsInvoiceService, validHopitalInterfaced,
                                        viewNewSocialGender, setViewNewSocialGender,
                                        scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
                                        visibility, toggleTab, activeTab, isEditWorkOrder, getAge, handleDateB, isEditPatient,
                                    }
                                    }
                                />

                                // <UpdatePatient
                                //     button="Actualizar datos"
                                //     {
                                //     ...{
                                //         toggle, loading,
                                //         handleInputChange, handleSelectValues, handleChangeSocialGender, handleDate,
                                //         id_gender, phone, email, paternal_surname, maternal_surname, name, birthday, age, curp, membership, cardnumber,
                                //         id_social_gender, new_social_gender, expedient_number, 
                                //         validationsPatient, validHopitalInterfaced,
                                //         listSocialGender,
                                //         methodAction: saveUpdate,
                                //         viewNewSocialGender, setViewNewSocialGender,
                                //         //INE
                                //         scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE,
                                //         showClassINE, img64File, handleOnChangeINEU,
                                //         listBloodType, id_blood_type, cp, codeP,
                                //         address, colony,
                                //         id_state, id_municipality, listStates, listMunicipalities, handleChangeState,
                                //         edad
                                //     }
                                //     }
                                // />

                                : (method === "updateBusinessNamePatient")
                                    ?
                                    <FormUpdateBusinessName
                                        {
                                        ...{
                                            handleInputChange, loading, listTaxRegime, validHopitalInterfaced,
                                            rfc_patient: rfc, type_person_patient: type_person, id_tax_regime_patient: id_tax_regime,
                                            cp_patient: cp, validationsPatient, email, business_name, expedient_number,
                                            saveUpdatePatient: saveUpdate, tax_regime_patient, toggle, handleChangeTypePatient
                                        }
                                        }
                                    />
                                    : ""
            }
        </Modal>
    )
}
