import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate, ModalButtonDelete, ModalUpdateCommercial } from '../../constant';
import { DeleteCommercialLines } from './DeleteCommercialLines';
import { FormCommercialLines } from './FormCommercialLines';


export const ModalCommercialLines = (props) => {

    const {
        modal, modalTitle, toggle, method, loading,
        long_name, short_name, rfc, theme_color, handleInputChange, validaciones,
        saveCreate, saveUpdate, saveDelete
    } = props;

    return (
        <Modal isOpen = {modal} toggle={toggle} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={ toggle } className="bg-primary">
                { modalTitle }
            </ModalHeader>
            {
                method === "create"
                    ? <FormCommercialLines button={ModalButtonSave} { ...{ toggle, loading, handleInputChange, validaciones, methodAction:saveCreate, theme_color } } />
                    : (method === "update")

                        ? <FormCommercialLines button={ModalButtonUpdate} {...{toggle, loading, handleInputChange, validaciones, methodAction:saveUpdate, theme_color, long_name, short_name, rfc}} />
                        : <DeleteCommercialLines { ...{ toggle, loading, long_name, short_name, rfc, saveDelete } } />
            }
        </Modal>
    )
}
